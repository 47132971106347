import { makeAutoObservable } from 'mobx';
import { SerializationProfile } from '../../Util/Serialization/Serialization';
import { CouponMatch } from './Coupon/CouponMatch';
import { CouponMatchProfile } from './Coupon/CouponMatchProfile';
import { LoyaltyDiscountMatch } from './Loyalty/LoyaltyDiscountMatch';
import { OrderRestrictionReport, OrderRestrictionReportProfile } from './OrderRestrictionReport';

export class OrderCheckReport
{
    restrictions: OrderRestrictionReport[];
    couponMatches: CouponMatch[];
    loyaltyMatch: LoyaltyDiscountMatch | undefined;

    constructor()
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
    }
}

export const OrderCheckReportProfile =
    SerializationProfile.create(OrderCheckReport)
        .profile('restrictions', OrderRestrictionReportProfile)
        .profile('couponMatches', CouponMatchProfile);
