import { Box, makeStyles, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { FC, ReactNode, useMemo } from 'react';
import { OrderState, PaymentState } from '../../../../../../Api/Order/Order';
import { capitalizeFirst } from '../../../../../../Api/Util/capitalizeFirst';
import { useLocalizer } from '../../../../../../Bridge/Localization/useLocalizer';
import { GenericHistoricalItem } from '../GenericHistoricalItem';

const useStyles = makeStyles(theme => ({
	endAdornment: {
		alignItems: 'center',
		display: 'flex',
		gap: theme.spacing(1),
	},
}));

interface HistoricalHeaderItemProps
{
	creationDate: string | undefined;
	isPaymentTimingOut: boolean;
	paymentState: PaymentState | undefined;
	state: OrderState;
}

export const HistoricalHeaderItem: FC<HistoricalHeaderItemProps> =
	({
		creationDate,
		isPaymentTimingOut,
		paymentState,
		state,
	}) =>
	{
		const localizer = useLocalizer();

		const description = useMemo(
			() =>
				creationDate === undefined
					? ''
					: localizer.formatDateTimeFamiliar(new Date(creationDate)),
			[creationDate, localizer],
		);

		const icon = useMemo(
			() =>
			{
				if (paymentState === 'failed' || paymentState === 'unpaid' || state === 'voided')
				{
					return <CloseIcon />;
				}
				else if (paymentState === undefined)
				{
					return getStateIcon(state);
				}
				else
				{
					switch (paymentState)
					{
						case 'created':
						case 'negotiated':
							return isPaymentTimingOut
								? <CloseIcon />
								: <TimelapseIcon />;
						case 'paid':
						default:
							return getStateIcon(state);
					}
				}
			},
			[isPaymentTimingOut, paymentState, state],
		);

		const label = useMemo(
			() =>
			{
				if (paymentState === 'failed' || paymentState === 'unpaid')
				{
					return localizer.translate('Order-Payment-Failed');
				}
				else if (state === 'voided')
				{
					return localizer.translate('Order-State-Voided');
				}
				else if (paymentState === undefined)
				{
					return localizer.translate(getStateLanguageEntryId(state));
				}
				else
				{
					switch (paymentState)
					{
						case 'created':
						case 'negotiated':
							return localizer.translate(
								isPaymentTimingOut
									? 'Order-Payment-Expired-Short'
									: 'Order-State-Ordered-InPayment',
							);
						case 'paid':
							return localizer.translate('Generic-Paid');
						default:
							return localizer.translate(getStateLanguageEntryId(state));
					}
				}
			},
			[isPaymentTimingOut, localizer, paymentState, state],
		);

		const classes = useStyles();

		return <GenericHistoricalItem
			description={description}
			endAdornment={
				<Box
					className={classes.endAdornment}
				>
					{icon}
					<Typography>
						{label}
					</Typography>
				</Box>
			}
		/>;
	};

function getStateIcon(state: OrderState): ReactNode
{
	switch (state)
	{
		case 'scheduled':
		case 'ordered':
			return <TimelapseIcon />;
		case 'acknowledged':
			return <LocalShippingIcon />;
		case 'handled':
		case 'prepared':
		case 'pickedUp':
		case 'delivered':
		default:
			return <CheckIcon />;
	}
}


function getStateLanguageEntryId(state: OrderState): string
{
	return `Order-State-${capitalizeFirst(state)}`;
}
