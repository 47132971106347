import { useObserver } from 'mobx-react-lite';
import { useContext, useMemo } from 'react';
import { PlaceSessionProfile } from '../../../../Api/Business/PlaceSession';
import { CartUpdate, CartUpdateProfile } from '../../../../Api/Order/CartUpdate';
import { OrderMutationEvent } from '../../../../lib/event/order/OrderMutationEvent';
import { PlaceSessionEndDateMutationEvent } from '../../../../lib/event/place_session/PlaceSessionEndDateMutationEvent';
import { PlaceSessionMenuCardMutationEvent } from '../../../../lib/event/place_session/PlaceSessionMenuCardMutationEvent';
import { PlaceSessionNumberOfCoversMutationEvent } from '../../../../lib/event/place_session/PlaceSessionNumberOfCoversMutationEvent';
import { PlaceSessionStartDateMutationEvent } from '../../../../lib/event/place_session/PlaceSessionStartDateMutationEvent';
import { ProductPriceMutation } from '../../../../lib/event/product/mutation/update/ProductPriceMutation';
import { ProductQuantityMutation } from '../../../../lib/event/product/mutation/update/ProductQuantityMutation';
import { ProductFeatureVariantPriceMutation } from '../../../../lib/event/product_feature_variant/mutation/update/ProductFeatureVariantPriceMutation';
import { ProductFeatureVariantQuantityMutation } from '../../../../lib/event/product_feature_variant/mutation/update/ProductFeatureVariantQuantityMutation';
import { fetch } from '../../../../Util/Api';
import { EntranceContextRef } from '../../Entrance/EntranceContext';
import { BusinessContextRef } from '../BusinessContext';
import { useWebSocketQuery } from '../useWebSocketQuery';
import { useWebSocketQueryRxjs } from '../useWebSocketQueryRxjs';
import { BusinessQuery } from '../WebSocketService';

export function useBusinessQuery()
{
	const {businessStore, currentOrderService} = useContext(BusinessContextRef);
	const {orderService} = useContext(EntranceContextRef);

	const businessId = useObserver(() => businessStore.business.id);
	const mutateProduct = useObserver(() => businessStore.mutateProduct);
	const mutateProductFeatureVariant = useObserver(() => businessStore.mutateProductFeatureVariant);

	const businessQuery = useMemo(() => new BusinessQuery(businessId), [businessId]);

	const opennessForAllQueries = [
		// TODO: Listen to (small) specific events, fetch order if required
		useWebSocketQueryRxjs<OrderMutationEvent>(
			businessQuery,
			'OrderMutationEvent',
			event =>
			{
				if (event.clientId === currentOrderService.clientId)
					orderService.reloadOrder(event.orderId, event.order);

				if (event.order.dateHandled !== undefined)
					currentOrderService.checkOrder();
			},
		),
		useWebSocketQueryRxjs<PlaceSessionStartDateMutationEvent>(
			businessQuery,
			'PlaceSessionStartDateMutationEvent',
			async mutation =>
			{
				if (businessStore.place.id === mutation.placeId)
				{
					const placeSession = await fetch(
						`/client/business/place/sessions/${mutation.placeSessionId}`,
						undefined,
						PlaceSessionProfile,
					);

					if (placeSession.endDate === undefined || placeSession.endDate === null)
					{
						businessStore.setPlaceSession(placeSession);
					}
					else
					{
						businessStore.setPlaceSession(undefined);
					}
				}
			},
		),
		useWebSocketQueryRxjs<PlaceSessionNumberOfCoversMutationEvent>(
			businessQuery,
			'PlaceSessionNumberOfCoversMutationEvent',
			async mutation =>
			{
				if (businessStore.place.id === mutation.placeId)
				{
					const placeSession = await fetch(
						`/client/business/place/sessions/${mutation.placeSessionId}`,
						undefined,
						PlaceSessionProfile,
					);

					if (placeSession.endDate === undefined || placeSession.endDate === null)
					{
						businessStore.setPlaceSession(placeSession);
					}
					else
					{
						businessStore.setPlaceSession(undefined);
					}
				}
			},
		),
		useWebSocketQueryRxjs<PlaceSessionMenuCardMutationEvent>(
			businessQuery,
			'PlaceSessionMenuCardMutationEvent',
			async mutation =>
			{
				if (businessStore.place.id === mutation.placeId)
				{
					const placeSession = await fetch(
						`/client/business/place/sessions/${mutation.placeSessionId}`,
						undefined,
						PlaceSessionProfile,
					);

					if (placeSession.endDate === undefined || placeSession.endDate === null)
					{
						businessStore.setPlaceSession(placeSession);
					}
					else
					{
						businessStore.setPlaceSession(undefined);
					}
				}
			},
		),
		useWebSocketQueryRxjs<PlaceSessionEndDateMutationEvent>(
			businessQuery,
			'PlaceSessionEndDateMutationEvent',
			async mutation =>
			{
				if (businessStore.place.id === mutation.placeId)
				{
					const placeSession = businessStore.placeSession?.id === mutation.placeSessionId
						? businessStore.placeSession
						: await fetch(
							`/client/business/place/sessions/${mutation.placeSessionId}`,
							undefined,
							PlaceSessionProfile,
						);

					placeSession.endDate = mutation.toValue === undefined
						? undefined
						: new Date(mutation.toValue).getTime();

					if (placeSession.endDate === undefined || placeSession.endDate === null)
					{
						businessStore.setPlaceSession(placeSession);
					}
					else
					{
						businessStore.setPlaceSession(undefined);
					}
				}
			},
		),
		useWebSocketQueryRxjs<ProductPriceMutation>(
			new BusinessQuery(businessId),
			'ProductPriceMutation',
			mutateProduct,
		),
		useWebSocketQueryRxjs<ProductQuantityMutation>(
			new BusinessQuery(businessId),
			'ProductQuantityMutation',
			mutateProduct,
		),
		useWebSocketQueryRxjs<ProductFeatureVariantPriceMutation>(
			businessQuery,
			'ProductFeatureVariantPriceMutation',
			mutateProductFeatureVariant,
		),
		useWebSocketQueryRxjs<ProductFeatureVariantQuantityMutation>(
			businessQuery,
			'ProductFeatureVariantQuantityMutation',
			mutateProductFeatureVariant,
		),
		// TODO: Remove after removing CartUpdate#getNotificationType in Core
		useWebSocketQuery(
			businessQuery,
			'cart_update',
			notification =>
			{
				const cartUpdate = CartUpdateProfile.deserialize(notification) as CartUpdate;

				const cart = cartUpdate.cart;

				if (cart.clientId === businessStore.currentOrderService?.clientId)
				{
					if (cart.businessId === businessStore.business.id)
						currentOrderService?.processNewCartFromServer(cart, cartUpdate.timestamp);
					else
						window.location.reload();
				}
			}
		),
		useWebSocketQuery(
			businessQuery,
			'CartUpdate',
			notification =>
			{
				const cartUpdate = CartUpdateProfile.deserialize(notification) as CartUpdate;

				const cart = cartUpdate.cart;

				if (cart.clientId === businessStore.currentOrderService?.clientId)
				{
					if (cart.businessId === businessStore.business.id)
						currentOrderService?.processNewCartFromServer(cart, cartUpdate.timestamp);
					else
						window.location.reload();
				}
			}
		),
	];

	return opennessForAllQueries.every(b => b);
}