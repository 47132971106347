import { Box, createTheme, makeStyles, Theme, Typography } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { FC, ReactNode, useMemo } from 'react';
import { useIsLandscape } from '../../../hooks/screen/useIsLandscape';
import { useIsSmallOrSmallerScreen } from '../../../hooks/screen/useIsSmallOrSmallerScreen';
// @ts-ignore
import background from '../../../Resources/DefaultBranding/background.jpg';
import { useWebLocalizer } from '../../localizer/Localizer';
import { Button } from '../../UI/Button/Button';

const useStyles = makeStyles<Theme, { isLandscape: boolean, isSmallOrSmallerScreen: boolean }>(theme => ({
	button: {
		alignSelf: 'center',
		width: 'fit-content',
	},
	content:
		({isLandscape}) =>
			({
				alignItems: 'center',
				display: 'flex',
				flexDirection:
					isLandscape
						? 'row'
						: 'column',
				flexGrow: 1,
				justifyContent: 'space-around',
			}),
	contentBackground: {
		backgroundColor: 'rgba(0, 0, 0, 0.8)',
		position: 'fixed',
		bottom: 0,
		left: 0,
		right: 0,
		top: 0,
	},
	contentContainer: {
		display: 'flex',
		flexDirection: 'column', flexGrow: 1,
		maxWidth: theme.breakpoints.values.lg,
		padding: `${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(1)}px`,
	},
	contentRoot: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		paddingBottom: 'env(safe-area-inset-bottom)',
		paddingLeft: 'env(safe-area-inset-left)',
		paddingRight: 'env(safe-area-inset-right)',
		paddingTop: 'env(safe-area-inset-top)',
	},
	icon:
		({isLandscape}) =>
			({
				height: 'auto',
				marginBottom: isLandscape
					? 0
					: theme.spacing(0.5),
				marginRight: isLandscape
					? theme.spacing(0.5)
					: 0,
				maxWidth: 200,
				width: '75%',
				zIndex: 1,
			}),
	root: {
		backgroundAttachment: 'fixed',
		backgroundBlendMode: 'multiply',
		backgroundImage: `url(${background})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		display: 'flex',
		flexGrow: 1,
	},
	text: {
		color: theme.palette.common.white,
		zIndex: 1,
	},
}));

interface DefaultErrorPageProps
{
	action?: () => void | Promise<void>;
	error?: ReactNode;
}

export const DefaultErrorPage: FC<DefaultErrorPageProps> =
	({
		action,
		error,
	}) =>
	{
		const isSmallOrSmallerScreen = useIsSmallOrSmallerScreen();
		const isLandscape = useIsLandscape();
		const {translate} = useWebLocalizer();

		const classes = useStyles({
			isLandscape,
			isSmallOrSmallerScreen,
		});

		const theme = useMemo(
			() =>
				createTheme({
					palette: {
						primary: {
							main: 'rgb(255,186,18)',
							contrastText: 'rgba(255,255,255,0.9)',
						},
					},
				}),
			[],
		);

		return <MuiThemeProvider
			theme={theme}
		>
			<Box
				component="main"
				className={classes.root}
			>
				<Box
					className={classes.contentRoot}
				>
					<Box
						className={classes.contentContainer}
					>
						<Box
							className={classes.contentBackground}
						/>
						<Box
							className={classes.content}
						>
							<ErrorOutlineIcon
								className={classes.icon}
								color="error"
							/>
							<Typography
								align={
									isLandscape
										? 'left'
										: 'center'
								}
								className={classes.text}
								variant={
									isSmallOrSmallerScreen
										? 'h6'
										: 'h4'
								}
							>
								{error ?? translate('Generic-Error-Occurred')}
							</Typography>
						</Box>
						{
							action !== undefined &&
							<Button
								className={classes.button}
								color="primary"
								onClick={action}
								variant="contained"
							>
								{translate('Generic-Retry')}
							</Button>
						}
					</Box>
				</Box>
			</Box>
		</MuiThemeProvider>;
	};
