// @ts-nocheck
/* eslint-disable */
import { Card, Checkbox, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext } from 'react';
import { useLocalizer } from '../../../../../../Bridge/Localization/useLocalizer';
import { DefaultContractingEntity } from '../../../../../../Constants/DefaultContractingEntity';
import { BusinessContextRef } from '../../../BusinessContext';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '16px 16px',
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'column',
		justifyItems: 'stretch',
		alignItems: 'start',
	},
	mainContent: {
		flex: '0 0 auto',
		display: 'flex',
		flexDirection: 'row',
		justifyItems: 'stretch',
		alignItems: 'center',
		alignSelf: 'stretch',
	},
	consentText: {
		fontSize: '0.8rem',
		color: theme.palette.text.secondary,
		userSelect: 'none',
	},
	legalLink: {
		'&:hover': {
			color: theme.palette.primary.main,
			transition: '0.4s'
		},
	},
}));

interface ShopperLegalConsentProps
{
	showValidation: boolean
	legalConsent: boolean
	setLegalConsent: (legalConsent: boolean) => void
}

export const ShopperLegalConsent: FC<ShopperLegalConsentProps> =
	(
		{
			showValidation,
			legalConsent,
			setLegalConsent,
		},
	) =>
	{
		const localizer = useLocalizer();
		const {businessStore} = useContext(BusinessContextRef);
		const {
			consentText,
			legalLink,
			root,
			mainContent,
		} = useStyles();

		const legalInformation = useObserver(() => (businessStore.contractingEntity ?? DefaultContractingEntity).legal)

		return <Card
			className={root}
			style={{
				backgroundColor: (showValidation && !legalConsent) ? '#f001' : '#fff8',
			}}
			onClick={e => setLegalConsent(!legalConsent)}
		>
			<div className={mainContent}>
				<div
					style={{
						lineHeight: 0.8,
						textAlign: 'justify',
						flex: '1 1 auto',
					}}
				>
					{
						localizer
							.translateWithComponents(
								'Legal-Agree-Butlaroo',
								<a
									href={legalInformation.termsAndConditionsUrl}
									target="_blank"
									className={`${consentText} ${legalLink}`}
									onClick={e => {
										e.stopPropagation();
									}}
								>
									{localizer.translate('Legal-Agree-Butlaroo-TC')}
								</a>,
								<a
									href={legalInformation.privacyPolicyUrl}
									target="_blank"
									className={`${consentText} ${legalLink}`}
									onClick={e => {
										e.stopPropagation();
									}}
								>
									{localizer.translate('Legal-Agree-Butlaroo-PP')}
								</a>,
							)
							.map((segment, key) => typeof segment === 'string'
								?
								<Typography
									key={key}
									color="textSecondary"
									style={{display: 'inline'}}
									className={consentText}
								>
									{segment}
								</Typography>
								:
								React.cloneElement(segment, {key})
							)
					}
				</div>
				<Checkbox
					style={{
						marginLeft: 4,
						marginRight: -12,
					}}
					checked={legalConsent}
					onChange={(e, checked) => {
						setLegalConsent(!legalConsent);
					}}
				/>
			</div>
			{showValidation && !legalConsent && <Typography
				color="error"
				style={{
					fontSize: '0.75rem',
					lineHeight: 1.66,
				}}
			>
				{localizer.translate('Legal-Agree-Required')}
			</Typography>}
		</Card>;
	};