import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';
import { Color } from '../../../../Api/Other/Color';
import { MediaButtonBarHeight } from './MediaButtonBarHeight';
import { MediaButtonBarTextMode } from './MediaButtonBarTextMode';
import { useEffectiveBackgroundCss } from './useEffectiveBackgroundCss';
import { useEffectiveTextColorCss } from './useEffectiveTextColorCss';
import { useEffectiveTextShadowCss } from './useEffectiveTextShadowCss';

const useStyles = makeStyles(theme => ({
	root: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		height: MediaButtonBarHeight,
		overflow: 'hidden',
		padding: `0px ${theme.spacing(1)}px`,

		position: 'absolute',
		bottom: 0,
		left: 0,
		right: 0,
	},
	rootGradient: {
		paddingTop: theme.spacing(0.5),
	},
	secondaryTitle: {
		clear: 'right',
		float: 'right',
		fontSize: 14,
		marginLeft: theme.spacing(1),
		// Typing is incorrect, htmlFontSize property does exist within theme.typography
		// @ts-ignore
		marginTop: theme.typography.htmlFontSize - 14,
	},
	title: {
		flexGrow: 1,
	},
	description: {
		fontSize: 13,
		overflow: 'hidden',
		fontWeight: 100,
	},
	titleWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'left',
	},
}));

export interface MediaButtonBarProps
{
	captionHasShadow: boolean;
	secondaryTitle?: string;
	description?: string;
	textBackdropColor?: Color;
	textColor?: Color;
	textMode: MediaButtonBarTextMode;
	title: string;
	showDescriptionOnTile: boolean;
}

export const MediaButtonBar: FC<MediaButtonBarProps> =
	({
		captionHasShadow,
		secondaryTitle,
		description,
		textBackdropColor,
		textColor,
		textMode,
		title,
		showDescriptionOnTile
	}) =>
	{
		const classes = useStyles();

		const effectiveBackground = useEffectiveBackgroundCss(textMode, textBackdropColor);
		const effectiveTextColor = useEffectiveTextColorCss(textMode, textColor);
		const effectiveTextShadow = useEffectiveTextShadowCss(textMode, captionHasShadow, textBackdropColor);

		return <div
			className={
				clsx(
					classes.root,
					{
						[classes.rootGradient]: textBackdropColor === undefined,
					},
				)
			}
			style={{
				background: effectiveBackground,
				color: effectiveTextColor,
				textShadow: effectiveTextShadow,
			}}
		>
			{showDescriptionOnTile &&
				<div className={classes.titleWrapper}>
					<Typography
						className={classes.title}
						noWrap
						style={{
							textAlign: secondaryTitle === undefined
								? description === undefined ? 'center' : 'left'
								: 'left',
						}}
					>
						{title}
						{
							secondaryTitle !== undefined &&
							<span
								className={classes.secondaryTitle}
							>
						{secondaryTitle}
					</span>
						}
					</Typography>
					{description !== undefined &&
						<Typography
							className={classes.description}
							noWrap
							style={{
								textAlign: 'left',
							}}
						>
							{description}
						</Typography>
					}
				</div>
			}
			{!showDescriptionOnTile &&
				<Typography
					className={classes.title}
					noWrap
					style={{
						textAlign: secondaryTitle === undefined
							? 'center'
							: 'left',
					}}
				>
					{title}
					{
						secondaryTitle !== undefined &&
						<span
							className={classes.secondaryTitle}
						>
						{secondaryTitle}
					</span>
					}
				</Typography>
			}
		</div>;
	};
