import { createStyles, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import { FC, useState } from 'react';
import { useIsKioskMode } from '../../../../../Service/KioskService/Api/useIsKioskMode';
import { PageDialog } from '../../../../UI/PageDialog';
import { topBarHeight, topBarHeightMobile } from '../../../../UI/PageTopBar';
import { ComoRewardDialogActions } from './ComoRewardDialogActions';
import { ComoRewardDialogTab } from './ComoRewardDialogTab';
import { ComoRewardInformationDialogContent } from './ComoRewardInformationDialogContent';
import { ComoRewardItemsSelectionsDialogContent } from './ComoRewardItemsSelectionsDialogContent';
import { ComoRewardStore } from './ComoRewardStore';

const useStyles = makeStyles((theme: Theme) => createStyles({
	PaperFullScreen: {
		alignSelf: 'stretch',
	},
	TitleBar: {
		[theme.breakpoints.down('sm')]: {
			height: topBarHeightMobile,
		},
		[theme.breakpoints.up('md')]: {
			height: topBarHeight,
		},
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	TitleBarInfo: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		paddingTop: 24,
		paddingRight: 0,
		paddingBottom: 20,
		paddingLeft: 24,
	},
	TitleBarConfiguration: {
		paddingRight: 0,
	},
	TitleBarContent: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	CloseIconAbovePicture: {
		color: 'white',
		filter: 'drop-shadow(0px 0px 0.4rem black)',
	},
	BackdropFullScreen: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	},
}));

interface ComoRewardDialogProps
{
	store: ComoRewardStore;
}

export const ComoRewardDialog: FC<ComoRewardDialogProps> =
	({
		store,
	}) =>
	{
		const classes = useStyles();
		const isKioskMode = useIsKioskMode();

		const [activeTab, setActiveTab] = useState<ComoRewardDialogTab>('information');

		return <PageDialog
			forceScreenMode={isKioskMode ? 'fullScreen' : undefined}
			fullWidth
			classes={{
				paperFullScreen: classes.PaperFullScreen,
				closeIcon: classes.CloseIconAbovePicture,
				backdropFullScreen: classes.BackdropFullScreen,
			}}
			maxWidth="md"
			onClose={
				() =>
					activeTab === 'itemsSelection'
						? setActiveTab('information')
						: store.close()
			}
			dialogActions={
				<ComoRewardDialogActions
					activeTab={activeTab}
					setActiveTab={setActiveTab}
					store={store}
				/>
			}
			contentPaddingTop={0}
			contentPaddingBottom={0}
			contentPaddingLeft={0}
			contentPaddingRight={0}
			title={
				activeTab === 'itemsSelection'
					? store.reward.name
					: undefined
			}
		>
			{
				activeTab === 'information' &&
				<ComoRewardInformationDialogContent
					store={store}
				/>
			}
			{
				activeTab === 'itemsSelection' &&
				<ComoRewardItemsSelectionsDialogContent
					store={store}
				/>
			}
		</PageDialog>;
	};