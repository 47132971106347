import { ServiceWorkerConstants } from '../../Constants/ServiceWorkerConstants';
import { isLocalAccessDeniedError } from '../../Util/browser/isLocalAccessDeniedError';
import { getServiceWorkerRegistration } from './getServiceWorkerRegistration';

export async function registerServiceWorker(): Promise<ServiceWorkerRegistration | undefined>
{
	if ('serviceWorker' in window.navigator)
	{
		try
		{
			const registration = await getServiceWorkerRegistration();

			if (registration)
			{
				return Promise.resolve(registration);
			}
			else
			{
				return window.navigator.serviceWorker.register(ServiceWorkerConstants.OutputFile);
			}
		}
		catch (error)
		{
			if (isLocalAccessDeniedError(error))
				console.warn('Services workers are not supported. Please enable cookies to use service workers.');
		}
	}
	else
	{
		return Promise.resolve(undefined);
	}
}