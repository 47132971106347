import { ClockService } from '../Util/clock/ClockService';
import { Client } from './Client/Client';
import { Dialog } from './Dialog/Dialog';
import { Http } from './Http/Http';
import { Linking } from './Linking/Linking';
import { Localizer } from './Localization/Localizer';
import { Notification } from './Notification/Notification';
import { Storage } from './Storage/Storage';
import { Navigator } from './Navigator/Navigator';

export class Bridge
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    public readonly navigator: Navigator;
    readonly storage: Storage;
    readonly linking: Linking;
    readonly localizer: Localizer;
    readonly notification: Notification;
    public readonly dialog: Dialog;
    readonly client: Client;
    readonly http: Http;
    readonly clockService: ClockService;
    
    // ------------------------ Constructor -------------------------
    
    constructor(
        navigator: Navigator,
        storage: Storage,
        linking: Linking,
        localizer: Localizer,
        notification: Notification,
        dialog: Dialog,
        client: Client,
        http: Http,
        clockService: ClockService,
    )
    {
        this.navigator = navigator;
        this.storage = storage;
        this.linking = linking;
        this.localizer = localizer;
        this.notification = notification;
        this.dialog = dialog;
        this.client = client;
        this.http = http;
        this.clockService = clockService;
    }
}
