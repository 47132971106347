export const DietaryFlags = [
	'IsVegetarian',
	'IsVegan',
	'IsOrganic',
	'Nonalcoholic',
	'IsKosher',
	'IsHalal',
	'IsPregnancyFriendly',
	// TODO: To be removed if the NutritionInformation model is updated
	'IsBiological',
] as const;

export type DietaryFlag = typeof DietaryFlags[number];

export const DietaryFlagAliases = new Map<DietaryFlag, DietaryFlag>([
	['IsBiological', 'IsOrganic'],
]);
