const SearchTerms = [
	'iPad Simulator',
	'iPhone Simulator',
	'iPod Simulator',
	'iPad',
	'iPhone',
	'iPod',
];

export function isApple(): boolean
{
	// navigator.userAgentData is not widely implemented yet, but will be the future
	// navigator.platform will be replaced by it
	const platform = (navigator as any).userAgentData?.platform ?? navigator.platform;

	return SearchTerms.includes(platform)
		// iPad on iOS 13 detection
		|| (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
}
