import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import * as React from 'react';
import { FC, useState } from 'react';
import { ProductRecommendationList } from '../../../../../../../Api/Product/ProductRecommendationList';
import { ProductRecommendationList as ProductRecommendationListViewer } from '../../../../Product/ProductRecommendation/ProductRecommendationList';
import { OrderBuilderExpansionPanel } from '../../../core/OrderBuilderExpansionPanel';

interface ProductRecommendationOrderBuilderSegmentRecommendationProps
{
	list: ProductRecommendationList;
}

export const ProductRecommendationOrderBuilderSegmentRecommendation: FC<ProductRecommendationOrderBuilderSegmentRecommendationProps> =
	(
		{
			list,
		},
	) =>
	{
		const [isExpanded, setExpanded] = useState(true);

		return <Grid
			item
			xs={12}
			style={{
				flex: '0 0 auto',
				display: 'flex',
				flexDirection: 'column',
				overflowY: 'hidden',
			}}
		>
			<OrderBuilderExpansionPanel
				expanded={isExpanded}
				onExpandedChange={
					() =>
						setExpanded(!isExpanded)
				}
				summary={
					<Typography
						variant="body2"
					>
						{list.recommendationTextRaw}
					</Typography>
				}
			>
				<div
					style={{
						width: '100%',
					}}
				>
					<ProductRecommendationListViewer
						productRecommendationList={list}
						hideText
						gridItemType="LIST_ITEM"
					/>
				</div>
			</OrderBuilderExpansionPanel>
		</Grid>;
	};
