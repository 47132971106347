import { makeAutoObservable, observable } from 'mobx';

export interface NotificationListener
{
    onNotification(notification: any): void;
}

export class NotificationService
{
    listeners = observable.array<NotificationListener>();

    constructor()
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
    }

    registerListener(listener: NotificationListener)
    {
        this.listeners.push(listener);
    }

    unregisterListener(listener: NotificationListener)
    {
        this.listeners.remove(listener);
    }
}
