import { Storage } from '../../../Bridge/Storage/Storage';
import { StorageVars } from '../../../Constants/StorageConstants';
import { getAuthUrl } from '../../../Util/Api/Resources/getAuthUrl';
import { generateCodeChallenge } from '../../../Util/generateCodeChallenge';
import { generateRandomString } from '../../../Util/generateRandomString';
import { toQueryParams } from '../../../Util/toQueryParams';

export async function authorize(
	storage: Storage,
	recoveryMode: boolean,
	aid: string | undefined,
	includeAnonymous: boolean,
	partnerLogoUrl?: string,
	partnerTermsAndConditionsUrl?: string,
): Promise<void>
{
	const redirectUri = `${window.location.origin}/auth${recoveryMode ? '?recover' : ''}`;
	const state = generateRandomString(16);
	const codeVerifier = generateRandomString(128);
	const codeChallenge = await generateCodeChallenge(codeVerifier);

	storage.set(StorageVars.OidcCodeVerifier, codeVerifier);
	storage.set(StorageVars.OidcState, state);

	if (!recoveryMode)
		storage.set(StorageVars.OidcStateUrl, window.location.href);

	const params: Record<string, string> = {
		client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
		response_type: 'code',
		state,
		scope: includeAnonymous
			? process.env.REACT_APP_AUTH_SCOPES
			: process.env.REACT_APP_AUTH_SCOPES.split(' ').filter(scope => scope !== 'anonymous').join(' '),
		redirect_uri: redirectUri,
		code_challenge: codeChallenge,
		code_challenge_method: 'S256',
		aid,
	};

	if (aid !== undefined)
		params.aid = aid;

	if (partnerLogoUrl !== undefined)
		params.partnerLogoUrl = partnerLogoUrl;

	if (partnerTermsAndConditionsUrl !== undefined)
		params.partnerTermsAndConditionsUrl = partnerTermsAndConditionsUrl;

	// return a never resolving promise
	await new Promise(
		() =>
			window.location.assign(
				getAuthUrl(`/oauth2/authorize?${toQueryParams(params)}`),
			),
	);
}
