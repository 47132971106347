import { makeAutoObservable } from 'mobx';

export class ClockService
{
    /*---------------------------------------------------------------*
     *                          Properties                           *
     *---------------------------------------------------------------*/

    nowMillis: number = Date.now();

    /*---------------------------------------------------------------*
     *                          Constructor                          *
     *---------------------------------------------------------------*/

    constructor()
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
        setInterval(
            () => this.updateNow(),
            900
        );
    }

    /*---------------------------------------------------------------*
     *                        Initialization                         *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                           Computed                            *
     *---------------------------------------------------------------*/

    get currentMinute(): Date
    {
        return new Date(this.currentMinuteMillis);
    }

    get currentMinuteMillis(): number
    {
        let coeff = 1000 * 60;
        return Math.floor(this.nowMillis / coeff) * coeff;
    }

    get currentSecond(): Date
    {
        let coeff = 1000;
        return new Date(
            Math.floor(this.nowMillis / coeff) * coeff
        );
    }

    get currentQuarterOfAnHourMillis(): number
    {
        return getQuarterMillis(this.currentMinuteMillis);
    }

    /*---------------------------------------------------------------*
     *                            Actions                            *
     *---------------------------------------------------------------*/

    private updateNow(): void
    {
        this.nowMillis = Date.now();
    }

    /*---------------------------------------------------------------*
     *                          Public logic                         *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                         Private logic                         *
     *---------------------------------------------------------------*/
}

/**
 * Gets the milliseconds of the quarter of an UNIX timestamp in milliseconds.
 *
 * @param milliseconds UNIX timestamp in milliseconds
 * @param round the rounding direction
 */
export function getQuarterMillis(milliseconds: number, round: 'up' | 'down' = 'down'): number
{
    let coeff = 1000 * 60 * 15;
    const roundingFunction = round === 'up' ? Math.ceil : Math.floor;
    return roundingFunction(milliseconds / coeff) * coeff;
}
