import { Card, CardActionArea, CardContent, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import chroma from 'chroma-js';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useMemo } from 'react';
import { Announcement as AnnouncementModel } from '../../../../Api/Business/Announcement';
import { Color } from '../../../../Api/Other/Color';
import { Screens } from '../../../../Constants/ScreenConstants';
import { useRootContext } from '../../../../RootContext';
import { BrandingService } from '../../../../Service/BrandingInformation/BrandingService';
import { getFileUrl } from '../../../../Util/Api/Resources/getFileUrl';
import { AnnouncementActions } from './AnnouncementActions';
import { AnnouncementActionWrapper } from './AnnouncementActionWrapper';
import { AnnouncementStore } from './AnnouncementStore';

interface StyleProps
{
    brandingService: BrandingService
    colorIntention: 'primary' | 'secondary' | 'error' | undefined
    isAction: boolean
    backgroundImageUrl: string | undefined
    backgroundImageOpacity: number | undefined
    backgroundColor: Color | undefined
    backgroundContrastTextColor: Color | undefined
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
    card: (
        {
            colorIntention,
            brandingService,
            isAction,
            backgroundImageUrl,
            backgroundImageOpacity,
            backgroundColor,
        }
    ) =>
    {
        if (!colorIntention)
            return {};
        return {
            ...(backgroundImageUrl !== undefined ? {
                // Using a pseudo element so background image opacity
                //  can be controlled separately from content opacity
                position: 'relative',
                '& > *': {
                    position: 'relative',
                },
                '&:before': {
                    content: "' '",
                    display: 'block',
                    position: 'absolute',
                    backgroundImage: `url(${
                        backgroundImageUrl.startsWith('http')
                            ? backgroundImageUrl
                            : getFileUrl(backgroundImageUrl)
                    })`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    height: '100%',
                    opacity: backgroundImageOpacity ?? 1,
                },
            } : {}),
            backgroundColor: backgroundColor?.css ?? theme.palette[colorIntention].main,
            flex: '1 1 auto',
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
            '&:hover': isAction ? (() => {
                const color = brandingService.getColor(colorIntention);
                const whatToDoForHover = chroma.distance(color.chroma, chroma('white')) > chroma.distance(color.chroma, chroma('black'))
                    ? 'brighten'
                    : 'darken';
                const hoverColor = whatToDoForHover === 'darken'
                    ? color.chroma.darken(0.5)
                    : color.chroma.brighten(0.5);
                return {
                    backgroundColor: hoverColor.css(),
                    cursor: 'pointer',
                };
            })() : {},
        };
    },
    typography: ({colorIntention, backgroundContrastTextColor}) => ({
        color: backgroundContrastTextColor?.css
            ?? (colorIntention && theme.palette[colorIntention].contrastText),
    }),
}));

export interface AnnouncementProps
{
    announcement: AnnouncementModel;

    /**
     * If true, use secondary color instead of primary. Defaults to false.
     */
    useSecondaryColor?: boolean;
}

export const Announcement: FC<AnnouncementProps> =
    (
        {
            announcement,
            useSecondaryColor = false
        }
    ) =>
    {
        const colorIntention = announcement.colorIntention === 'primary'
            ?
            (
                useSecondaryColor
                    ?
                    'secondary'
                    :
                    'primary'
            )
            :
            announcement.colorIntention;
        const {brandingService, navigator} = useRootContext(true);
        const classes = useStyles({
            colorIntention,
            isAction: announcement.action !== undefined || announcement.linkUrl !== undefined,
            brandingService,
            backgroundImageUrl: announcement.backgroundImageUrl,
            backgroundColor: announcement.backgroundColor,
            backgroundContrastTextColor: announcement.backgroundContrastTextColor,
            backgroundImageOpacity: announcement.backgroundImageOpacity,
        })
        const {
            action,
            linkUrl,
            longContent,
        } = announcement;
        const elements = useObserver(() => {
            const elements: (JSX.Element | string)[] = [];
            const content = announcement.content;
            const contentLines = (content !== null && content !== undefined && content.length > 0)
                ? content.split('\n')
                : [];
            for (let i = 0; i < contentLines.length; i++) {
                let line = contentLines[i];
                if (i > 0) {
                    elements.push(<br key={i}/>);
                }
                elements.push(line);
            }
            return elements;
        });
        const card = useMemo(
            () => <Card
                className={classes.card}
            >
                <CardContent>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <div>
                            {
                                announcement.title &&
                                <Typography
                                    variant="h6"
                                    className={classes.typography}
                                >
                                    {announcement.title}
                                </Typography>
                            }
                            <Typography
                                variant="body1"
                                className={classes.typography}
                            >
                                {elements}
                            </Typography>
                        </div>
                        {
                            announcement.rightImageUrl !== undefined &&
                            <img
                                style={{
                                    height: '100%',
                                    maxHeight: 100,
                                    maxWidth: 100,
                                }}
                                alt="loyalty"
                                src={announcement.rightImageUrl}
                            />
                        }
                    </div>
                    <AnnouncementActions announcement={announcement} />
                </CardContent>
            </Card>,
            [announcement, classes.card, classes.typography, elements],
        );
        const readMoreCallback = useCallback(
            () => navigator.pushScreen(
                Screens.Announcement,
                new AnnouncementStore(announcement),
            ),
            [announcement, navigator],
        );

        if (linkUrl === undefined && action === undefined)
        {
            if (longContent === undefined)
            {
                return card;
            }
            else
            {
                return <CardActionArea
                    className={classes.button}
                    component="div"
                    onClick={readMoreCallback}
                >
                    {card}
                </CardActionArea>;
            }
        }
        else if (action === undefined)
        {
            return <AnnouncementActionWrapper
                action={{type: 'OpenURL', url: linkUrl}}
            >
                {card}
            </AnnouncementActionWrapper>;
        }
        else
        {
            return <AnnouncementActionWrapper
                action={action}
            >
                {card}
            </AnnouncementActionWrapper>;
        }
    };
