import { Typography } from '@material-ui/core';
import { isSameDay } from 'date-fns';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { useLocalizer } from '../../../../../Bridge/Localization/useLocalizer';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { useCurrentMinute } from '../../../../../Util/clock/useCurrentMinute';
import { useCurrentOrderService } from '../../BusinessContext';

export const SchedulerSummaryTime: FC =
	() =>
	{
		const localizer = useLocalizer();
		const translate = useTranslate();
		const currentMinute = useCurrentMinute();
		const currentOrderService = useCurrentOrderService(true);

		const effectiveHandoverDateSpec = useObserver(() => currentOrderService.effectiveHandoverDateSpec);
		const formatDateFamiliar = useObserver(() => localizer.formatDateFamiliar);

		const selectedScheduledTimeDescription = useMemo(
			() =>
			{
				const value = effectiveHandoverDateSpec;

				if (value !== 'ASAP' && value !== undefined)
				{
					let timeLabel = `00${value.getHours().toString()}`.slice(-2)
						+ ':'
						+ `00${value.getMinutes().toString()}`.slice(-2);

					return isSameDay(value, currentMinute)
						? timeLabel
						: `${formatDateFamiliar(value)}, ${timeLabel}`;
				}
				else if (value === 'ASAP')
				{
					return translate('Order-Time-AsSoonAsPossible');
				}
				else
				{
					return '';
				}
			},
			[currentMinute, effectiveHandoverDateSpec, formatDateFamiliar, translate],
		);

		return <Typography
			color="textSecondary"
		>
			{selectedScheduledTimeDescription}
		</Typography>;
	};
