import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext } from 'react';
import { ProductFeatureVariant } from '../../../../../../../Api/Product/ProductFeatureVariant';
import { EntranceContextRef } from '../../../../../Entrance/EntranceContext';
import { useCurrencySymbolsVisibility } from '../../useCurrencySymbolsVisibility';
import { FeatureConfigurationStore } from '../FeatureConfigurationStore';

interface FeatureConfigurationVariantPriceProps
{
    store: FeatureConfigurationStore;
    variant: ProductFeatureVariant;
}

export const FeatureConfigurationVariantPrice: FC<FeatureConfigurationVariantPriceProps> =
    (
        {
            store,
            variant,
        }
    ) =>
    {
        const {localizer} = useContext(EntranceContextRef);
        const hideCurrencySymbols = useCurrencySymbolsVisibility() === 'hidden';

        return useObserver(
            () =>
            {
                if (store.doShowPrice(variant))
                {
                    if (hideCurrencySymbols)
                    {
                        return <>
                            {localizer.formatCurrencyWithoutSymbol(variant.price, store.productCurrencyCode)}
                        </>;
                    }
                    else
                    {
                        return <>
                            {localizer.formatCurrency(variant.price, store.productCurrencyCode)}
                        </>;
                    }
                }
                else
                {
                    return null;
                }
            }
        );
    };
