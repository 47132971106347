import { SerializationProfile } from '../../Util/Serialization/Serialization';
import { Business, BusinessProfile } from './Business';
import { Place, PlaceProfile } from './Place';

export class BillBusinessEntrance
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	readonly business: Business;
	readonly place: Place;
}

export const BillBusinessEntranceProfile = SerializationProfile
	.create(BillBusinessEntrance)
	.profile('business', BusinessProfile)
	.profile('place', PlaceProfile);
