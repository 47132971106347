import Core from '@adyen/adyen-web/dist/types/core';
import { PaymentMethods } from '@adyen/adyen-web/dist/types/types';
import { useCallback, useState } from 'react';
import { useAsyncEffect } from '../../../../../Util/async/useAsyncEffect';
import { ADYEN_CHECKOUT_GOOGLE_PAY_TYPES } from './AdyenCheckoutContext';

export function useAdyenCheckoutPaymentMethodAvailability(
	client: Core | undefined,
	type: keyof PaymentMethods,
): boolean | undefined
{
	const [isAvailable, setIsAvailable] = useState<boolean | undefined>();

	const promiseGenerator = useCallback(() =>
	{
		try
		{
			if (!isCompatibleWithEnvironment(type))
				return Promise.resolve(false);

			const component = client?.create(type);

			if (component === undefined)
			{
				return undefined;
			}
			else if ('isAvailable' in component && typeof component.isAvailable === 'function')
			{
				const isAvailable = component.isAvailable();

				client.remove(component);

				return isAvailable;
			}
			else
			{
				client.remove(component);

				return Promise.resolve(true);
			}
		}
		catch (error)
		{
			console.warn(error.message);

			return Promise.reject();
		}
	}, [client, type]);

	useAsyncEffect(() => ({
		promise: promiseGenerator?.(),
		then: setIsAvailable,
		catch: () => setIsAvailable(false),
	}), [promiseGenerator]);

	return isAvailable;
}

function isCompatibleWithEnvironment(type: keyof PaymentMethods): boolean
{
	return ADYEN_CHECKOUT_GOOGLE_PAY_TYPES.includes(type)
		// The GooglePay component calls Google's Payment Request API after construction,
		//  which is not available in non-chromium based environments (electron, firefox, ...).
		// Therefore, construction will always result in an error and payments cannot be completed.
		? (window as any).PaymentRequest !== undefined
		: true;
}
