import { makeAutoObservable } from 'mobx';
import { ISO8601ToDateTransformer, SerializationProfile } from '../../../Util/Serialization/Serialization';
import { CartLine, CartLineProfile } from './CartLine';

export class Cart
{
    // ------------------------- Properties -------------------------

    id: number;
    dateLastChanged: Date;
    clientId: number;
    businessId: number;
    lines: CartLine[];

    // ------------------------ Constructor -------------------------

    constructor()
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}

export const CartProfile =
    SerializationProfile.create(Cart)
        .transform('dateLastChanged', ISO8601ToDateTransformer)
        .profile('lines', CartLineProfile);
