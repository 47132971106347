import { SerializationProfile } from '../../Util/Serialization/Serialization';

export class MenuCard
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	readonly id: number;
	readonly name: string;
}

export const MenuCardProfile = SerializationProfile.create(MenuCard);
