import { Grid } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { Announcement as AnnouncementModel } from '../../../../../Api/Business/Announcement';
import { useRootContext } from '../../../../../RootContext';
import { Announcement } from '../../Announcement/Announcement';
import { MenuStore } from '../MenuStore';

interface MenuAnnouncementsProps
{
	store: MenuStore;
}

export const MenuAnnouncements: FC<MenuAnnouncementsProps> =
	(
		{
			store,
		},
	) =>
	{
		const {brandingService, localizer} = useRootContext(true);

		const announcements = useObserver(() => store.announcementsForCategory);
		const isOrderBillSupported = useObserver(() => store.place.isOrderBillSupported);
		const isOrderWaiterSupported = useObserver(() => store.place.isOrderWaiterSupported);
		const hasNoProducts = useObserver(() => store.productById === undefined || store.productById.size === 0);

		const shouldUseSecondaryColorOnMenu = useObserver(() => brandingService.shouldUseSecondaryColorOnMenu);

		const showCannotOrderAnnouncement = useMemo(() => !isOrderBillSupported && !isOrderWaiterSupported && hasNoProducts, [hasNoProducts, isOrderBillSupported, isOrderWaiterSupported]);

		const menuAnnouncements = useMemo(() => {
			return announcements.map(
				announcement =>
					<Grid
						key={announcement.id}
						item
						xs={12}
					>
						<Announcement
							announcement={announcement}
							useSecondaryColor={shouldUseSecondaryColorOnMenu}
						/>
					</Grid>,
			);
		}, [announcements, shouldUseSecondaryColorOnMenu]);

		const cannotOrderAnnouncement = useMemo(() => {
			if (!showCannotOrderAnnouncement)
				return undefined;

			return <Grid
				item
				xs={12}
			>
				<Announcement
					announcement={
						new AnnouncementModel(
							undefined,
							'info',
							`${localizer.translate('Place-Menu-Cannot-Order')}.`,
							true,
							false,
							true,
						)
					}
					useSecondaryColor={shouldUseSecondaryColorOnMenu}
				/>
			</Grid>;
		}, [localizer, shouldUseSecondaryColorOnMenu, showCannotOrderAnnouncement]);

		return <>
			{menuAnnouncements}
			{cannotOrderAnnouncement}
		</>;
	};