import * as React from 'react';

export interface OrderLinesContext
{
    quantityColumnWidth: number;
    setOrderLineQuantityLabelWidth: (orderLineKey: string, width: number | undefined) => void;
}

export const OrderLinesContextRef = React
    .createContext<OrderLinesContext>({} as OrderLinesContext);

export const OrderLinesContextProvider = OrderLinesContextRef.Provider;
export const OrderLinesContextConsumer = OrderLinesContextRef.Consumer;
