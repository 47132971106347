import * as React from 'react';
import { Announcement } from '../../../../Api/Business/Announcement';

export interface AnnouncementScreenContext
{
	announcement: Announcement
}

export const AnnouncementScreenContextRef = React
	.createContext<AnnouncementScreenContext>({} as AnnouncementScreenContext);

export const AnnouncementScreenContextProvider = AnnouncementScreenContextRef.Provider;
export const AnnouncementScreenContextConsumer = AnnouncementScreenContextRef.Consumer;