const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export function generateRandomString(size: number): string
{
	const buffer = new Uint8Array(size);
	crypto.getRandomValues(buffer);

	const state = [];
	for (let i = 0; i < buffer.byteLength; ++i)
	{
		const index = buffer[i] % charset.length;
		state.push(charset[index]);
	}
	return state.join('');
}
