import { Event } from './Event';
import { UserRequestType } from './UserRequestType';

export class UserRequest extends Event
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	readonly type: UserRequestType;
	readonly clientInstanceUuid: string;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor(
		uuid: string,
		type: UserRequestType,
		clientInstanceUuid,
	)
	{
		super(uuid);
		this.type = type;
		this.clientInstanceUuid = clientInstanceUuid;
	}
}