import { OrderDescriptor } from '../../Api/Order/OrderDescriptor';
import { LocalizedPrice } from '../../Component/Page/Manager/order-handler/BillManager/Bill/LocalizedPrice';
import { getTotalPriceOfOrder } from './getTotalPriceOfOrder';

export function getTotalPriceOfOrders(orders: OrderDescriptor[]): LocalizedPrice[]
{
    let localizedPrices: LocalizedPrice[] = [];

    orders.forEach(
        order =>
        {
            const orderPrice = getTotalPriceOfOrder(order);

                const currencyCodeIdx = localizedPrices.findIndex(
                    peerLocalizedPrice =>
                        peerLocalizedPrice.currencyCode === order.currency);
                if (currencyCodeIdx > -1)
                {
                    const localizedPrice = localizedPrices[currencyCodeIdx];
                    localizedPrice.price = localizedPrice.price.add(orderPrice.price);
                    localizedPrices.splice(currencyCodeIdx, 1, localizedPrice);
                }
                else
                {
                    localizedPrices.push(orderPrice);
                }
        });

    return localizedPrices;
}