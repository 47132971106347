import { Localizer } from '../../../../Bridge/Localization/Localizer';
import { isValidEmailAddress } from './isValidEmailAddress';

export function validateEmailAddress(
	requireEmailAddress: boolean,
	emailAddress: string | undefined,
	localizer: Localizer,
): string[]
{
	const errors: string[] = [];

	const coalescedEmailAddress = (emailAddress ?? '').trim();
	const emailAddressIsEmpty = coalescedEmailAddress.length === 0;

	if (emailAddressIsEmpty)
	{
		if (requireEmailAddress)
		{
			errors.push(localizer.translate('Order-Notification-Email-Required'));
		}
	}
	else
	{
		if (!isValidEmailAddress(coalescedEmailAddress))
		{
			errors.push(
				localizer.translate('Client-Email-NotValid'),
			);
		}
	}

	return errors;
}