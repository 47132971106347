import * as React from 'react';
import { Typography } from '@material-ui/core';

interface HorizontalTextLineProps
{
    className?: string;
    color?: string;
    gradient?: boolean;
    height?: number | string;
    style?: React.CSSProperties;
    text: string;
    verticalMargin?: number | string;
}

export const HorizontalTextLine: React.FC<HorizontalTextLineProps> =
    ({
         className,
         color,
         gradient,
         height,
         style,
         text,
         verticalMargin,
     }) =>
    {
        return <div
            className={className}
            style={{
                alignItems: 'center',
                display: 'flex',
                marginBottom: verticalMargin,
                marginTop: verticalMargin,
                ...style,
            }}
        >
            <hr
                style={{
                    backgroundImage: `linear-gradient(270deg, ${color}, ${gradient ? 'transparent' : `${color}`})`,
                    borderWidth: 0,
                    flexGrow: 1,
                    height: height,
                    marginRight: 10,
                }}
            />
            <Typography
                style={{
                    color: color,
                }}
            >
                {text}
            </Typography>
            <hr
                style={{
                    backgroundImage: `linear-gradient(90deg, ${color}, ${gradient ? 'transparent' : `${color}`})`,
                    borderWidth: 0,
                    flexGrow: 1,
                    height: height,
                    marginLeft: 10,
                }}
            />
        </div>;
    };

HorizontalTextLine.defaultProps =
    {
        color: 'black',
        gradient: true,
        height: 1,
        verticalMargin: 20,
    };
