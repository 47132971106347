import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { OrderDescriptor } from '../../../../../Api/Order/OrderDescriptor';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { useTranslation } from '../../../../../Bridge/Localization/useTranslation';
import { PaymentIssuerAliases } from '../../../../../Constants/PaymentIssuerAliases';
import { PaymentMethodAliases } from '../../../../../Constants/PaymentMethodAliases';
import { PageDialog } from '../../../../UI/PageDialog';

const useStyles = makeStyles(() => ({
	listItem: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'stretch',
	},
	bullet: {
		width: 30,
		flex: '0 0 auto',
	},
}));

interface PaymentInfoDialogProps
{
	onClose: () => void;
	order: OrderDescriptor;
}

export const PaymentInfoDialog: FC<PaymentInfoDialogProps> =
	(
		{
			onClose,
			order,
		},
	) =>
	{
		const classes = useStyles();

		const [open, setOpen] = useState(order !== undefined);

		useEffect(() => setOpen(order !== undefined), [order]);

		const close = useCallback(() => setOpen(false), []);

		const paymentIssuerName = useMemo(() => PaymentIssuerAliases.get(order?.paymentIssuerId), [order]);
		const paymentMethodName = useMemo(() => PaymentMethodAliases.get(order?.paymentMethodId), [order]);
		const paymentDescriptor = useMemo(() => paymentIssuerName ?? paymentMethodName, [paymentIssuerName, paymentMethodName]);

		const translate = useTranslate();

		return <PageDialog
			fullWidth
			maxWidth="sm"
			onClose={close}
			onExited={onClose}
			open={open}
			title={useTranslation('Generic-Payment-Information')}
		>
			<Typography>
				{useTranslation('Order-Payment-Negotiated-Explanation-Start')}
			</Typography>
			<List>
				<ListItem className={classes.listItem}>
					<div className={classes.bullet}>
						&#9679;
					</div>
					<ListItemText>
						{
							paymentDescriptor === undefined
								? translate('Order-Payment-Negotiated-Explanation-CompleteOrUnknown-Indeterminate')
								: translate('Order-Payment-Negotiated-Explanation-CompleteOrUnknown', paymentDescriptor)
						}
					</ListItemText>
				</ListItem>
				<ListItem>
					<div className={classes.bullet}>
						&#9679;
					</div>
					<ListItemText>
						{useTranslation('Order-Payment-Negotiated-Explanation-Incomplete')}
					</ListItemText>
				</ListItem>
			</List>
		</PageDialog>;
	};
