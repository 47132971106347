import { v4 as uuid } from 'uuid';
import { evaluateTimeSchedule } from '../../time/evaluateTimeSchedule';
import {
	ConjunctionTimeSeriesPayload,
	DayWindowOffsetFromNowTimeSeriesPayload,
	DisjunctionTimeSeriesPayload,
	ExactWindowOffsetFromNowTimeSeriesPayload,
	RepeatDailyBooleanTimeSeriesPayload,
	RepeatWeeklyBooleanTimeSeriesPayload,
	SingleDayBooleanTimeSeriesPayload,
	SingleOnTimeBooleanTimeSeriesPayload,
	TimeScheduleSpecification,
} from '../../time/TimeScheduleSpecification';

interface ResultCache
{
	lastAccessDate: Date;
	isTrueCache: Map<string, boolean>;
}

const cacheIdByDataId = new Map<string, string>();

const cacheById = new Map<string, ResultCache>();
const maxInactivityTimeInMillis = 60 * 1000 * 5;

setInterval(
	() =>
	{
		const now = new Date().getTime();

		cacheById.forEach(
			(value, key) =>
			{
				if (value.lastAccessDate.getTime() - now > maxInactivityTimeInMillis)
				{
					cacheById.delete(key);
				}
			});
	},
	60000,
);

function getTimestampKey(timestamp: Date): string
{
	return `${timestamp.getFullYear()}-${timestamp.getMonth()}-${timestamp.getDate()}T${timestamp.getHours()}:${timestamp.getMinutes()}`;
}

function getOrSetCacheById(cacheId: string): ResultCache
{
	const now = new Date();

	if (!cacheById.has(cacheId))
		cacheById.set(
			cacheId,
			{
				lastAccessDate: now,
				isTrueCache: new Map(),
			},
		);

	const cache = cacheById.get(cacheId)!;

	cache.lastAccessDate = now;

	return cache;
}

function getOrSetInCache(
	cache: ResultCache,
	timeSeries: TimeScheduleSpecification,
	timestamp: Date,
): boolean
{
	const instantKey = getTimestampKey(timestamp);

	if (!cache.isTrueCache.has(instantKey))
		cache.isTrueCache.set(
			instantKey,
			evaluateTimeSchedule(new Date(), timestamp, timeSeries),
		);

	return cache.isTrueCache.get(instantKey)!;
}

function getOrSetCacheId(timeSeries: TimeScheduleSpecification)
{
	if (timeSeries.cacheId)
	{
		return timeSeries.cacheId;
	}
	else
	{
		const dataId = getId(timeSeries);

		if (!cacheIdByDataId.has(dataId))
		{
			const cacheId = uuid();
			timeSeries.cacheId = cacheId;
			cacheIdByDataId.set(dataId, cacheId);
		}

		return cacheIdByDataId.get(dataId)!;
	}
}

export function isTrueAtInCache(timeSeries: TimeScheduleSpecification, timestamp: Date): boolean
{
	const cacheId = getOrSetCacheId(timeSeries);
	const cache = getOrSetCacheById(cacheId);

	return getOrSetInCache(cache, timeSeries, timestamp);
}

function getId(timeSeries: TimeScheduleSpecification): string
{
	const type = timeSeries.type;

	switch (type)
	{
		case 'ALWAYS':
		case 'NEVER':
		{
			return type;
		}
		case 'CONJUNCTION':
		{
			const dataId = (timeSeries as ConjunctionTimeSeriesPayload)
				.terms
				.map(getId)
				.join(',');

			return `${type}(${dataId})`;
		}
		case 'DISJUNCTION':
		{
			const dataId = (timeSeries as DisjunctionTimeSeriesPayload)
				.terms
				.map(getId)
				.join(',');

			return `${type}(${dataId})`;
		}
		case 'DAY_WINDOW_OFFSET_FROM_NOW':
		{
			const series = (timeSeries as DayWindowOffsetFromNowTimeSeriesPayload);

			return `${type}(${series.timeZone},${series.fromInclusive},${series.toExclusive})`;
		}
		case 'EXACT_WINDOW_OFFSET_FROM_NOW':
		{
			const series = (timeSeries as ExactWindowOffsetFromNowTimeSeriesPayload);

			return `${type}(${series.fromInclusive},${series.toExclusive})`;
		}
		case 'REPEAT_DAILY':
		{
			const series = (timeSeries as RepeatDailyBooleanTimeSeriesPayload);

			return `${type}(${series.start},${series.end},${series.endOnNextDay},${series.timeZone})`;
		}
		case 'REPEAT_WEEKLY':
		{
			const series = (timeSeries as RepeatWeeklyBooleanTimeSeriesPayload);

			return `${type}(${series.startDay},${series.startTime},${series.endDay},${series.endTime},${series.endTimeInNextWeek},${series.timeZone})`;
		}
		case 'SINGLE_DAY':
		{
			const series = (timeSeries as SingleDayBooleanTimeSeriesPayload);

			return `${type}(${series.timeZone},${series.date})`;
		}
		case 'SINGLE_ON_TIME':
		{
			const series = (timeSeries as SingleOnTimeBooleanTimeSeriesPayload);

			return `${type}(${series.start},${series.end})`;
		}
	}
}
