import { Duration } from 'iso8601-duration';

export function toISODurationString(duration: Duration): string
{
	let string = 'P';

	if (duration.years !== undefined && duration.years !== 0)
		throw new Error(`Unsupported property "years" found`);

	if (duration.months !== undefined && duration.months !== 0)
		throw new Error(`Unsupported property "months" found`);

	if (duration.weeks !== undefined && duration.weeks !== 0)
		throw new Error(`Unsupported property "weeks" found`);

	if (duration.days !== undefined)
		string += `${duration.days}D`;

	string += 'T';

	if (duration.hours !== undefined)
		string += `${duration.hours}H`;

	if (duration.minutes !== undefined)
		string += `${duration.minutes}M`;

	if (duration.seconds !== undefined)
		string += `${duration.seconds}S`;

	return string;
}
