import { makeStyles } from '@material-ui/core';
import QrScanner from 'qr-scanner';
import * as React from 'react';
import { FC, useEffect, useRef } from 'react';

const useStyles = makeStyles(theme => ({
	root: {
		borderRadius: theme.shape.borderRadius,
		width: '100%',
	},
}));

export interface QrCodeScannerProps
{
	onScan: (value: string) => void;
}

export const QrCodeScanner: FC<QrCodeScannerProps> =
	(
		{
			onScan,
		},
	) =>
	{
		const classes = useStyles();
		const lastScanResult = useRef('');
		const scannerRef = useRef<HTMLVideoElement>(null);

		useEffect(() =>
		{
			if (scannerRef.current)
			{
				const qrScanner = new QrScanner(
					scannerRef.current,
					(result: QrScanner.ScanResult | string) =>
					{
						const data = typeof result === 'string'
							? result
							: result.data;

						// Avoid a loop calling the onScan function multiple times with the same result
						if (lastScanResult.current !== data)
						{
							lastScanResult.current = data;
							onScan(data);
						}
					},
					{
						calculateScanRegion: () =>
						{
							return {
								height: 600,
								width: 600,
							};
						},
						onDecodeError: error =>
						{
							if (error !== 'No QR code found')
								console.error(error);
						},
						preferredCamera: 'environment',
					},
				);

				qrScanner.start();

				return () => qrScanner.destroy();
			}
		}, [scannerRef, lastScanResult, onScan]);

		return <video
			className={classes.root}
			ref={scannerRef}
		/>;
	};
