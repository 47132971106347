import { Color } from '../../../../Api/Other/Color';
import { MediaButtonBarTextMode } from './MediaButtonBarTextMode';

export function useEffectiveTextShadowCss(
	textMode: MediaButtonBarTextMode,
	captionHasShadow: boolean,
	backdropColor: Color | undefined,
): string | undefined
{
	return backdropColor === undefined && captionHasShadow
		? textMode === 'dark'
			? '0px 0px 7px rgba(255,255,255,0.5), 0px 0px 2px rgba(255,255,255,0.94), 0px 0px 1px rgba(0, 0, 0, 1)'
			: '0px 0px 7px rgba(0,0,0,0.5), 0px 0px 2px rgba(0,0,0,0.94)'
		: undefined;
}
