import { Box, Card, Drawer, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { CSSProperties, FC, useCallback, useState } from 'react';
import { panelGradient } from '../../Page/Business/BusinessConstants';
import { CenteredPageContent, getCenteredPageContentWidthStyle } from '../CenteredPageContent';
import { MediaButtonV2BorderRadius, MediaButtonV2Shadow } from '../media_button_v2/MediaButtonV2';
import { KioskDrawerBackButton } from './back_button/KioskDrawerBackButton';

export const KioskDrawerMaxHeight = '90vh';

const useStyles =
	makeStyles(
		theme => ({
			drawerPaper: {
				background: panelGradient,
				borderTopLeftRadius: MediaButtonV2BorderRadius,
				borderTopRightRadius: MediaButtonV2BorderRadius,
				maxHeight: KioskDrawerMaxHeight,
				marginLeft: 'auto',
				marginRight: 'auto',
				display: 'flex',
				flexDirection: 'column',
				overflowY: 'auto',
				// to hide scroll bar for overflowing swiper control (in case variants have images)
				overflowX: 'hidden',
				...getCenteredPageContentWidthStyle(theme),
			},
			card: {
				boxShadow: MediaButtonV2Shadow,
				overflow: 'initial',
				flex: 1,
				maxHeight: '80vh',
				overflowY: 'auto',
			},
			configuration: {
				flex: 1,
			},
			title: {
				fontSize: '3rem',
				lineHeight: 1.2,
			},
			price: {
				fontSize: '1.3rem',
				fontWeight: 600,
				color: 'rgba(0,0,0,0.45)',
			},
			information: {
				columnWidth: 400,
				columnGap: 20,
			},
		}),
	);

interface KioskDrawerProps
{
	onClose: () => void;
	topContent?: (onClose: () => void) => React.ReactNode;
	bottomContent?: (onClose: () => void) => React.ReactNode;
	style?: CSSProperties;
}

export const KioskDrawer: FC<KioskDrawerProps> =
	(
		{
			topContent,
			bottomContent,
			onClose,
			style,
		},
	) =>
	{
		const [open, setOpen] = useState(true);

		const classes = useStyles();

		const handleClose = useCallback(() =>
		{
			setOpen(false);
		}, []);

		return <Drawer
			anchor="bottom"
			open={open}
			onClose={handleClose}
			SlideProps={{
				appear: true,
				onExited: onClose,
			}}
			classes={{
				paper: classes.drawerPaper,
			}}
			PaperProps={{
				style,
			}}
		>
			{
				topContent !== undefined &&
				<Card
					className={classes.card}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							width: '100%',
						}}
					>
						<Box
							sx={{
								position: 'sticky',
								top: 0,
								zIndex: 1,
							}}
						>
							<KioskDrawerBackButton
								onClick={handleClose}
							/>
						</Box>
						<Box
							sx={{
								width: '100%',
							}}
						>
							<CenteredPageContent>
								{topContent(handleClose)}
							</CenteredPageContent>
						</Box>
					</Box>
				</Card>
			}
			{
				bottomContent !== undefined &&
				<Box
					className={classes.configuration}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							width: '100%',
						}}
					>
						{
							topContent === undefined &&
							<Box>
								<KioskDrawerBackButton
									onClick={handleClose}
									card
								/>
							</Box>
						}
						<Box
							sx={{
								width: '100%',
							}}
						>
							{bottomContent(handleClose)}
						</Box>
					</Box>
				</Box>
			}
		</Drawer>;
	};
