import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import * as React from 'react';
import { FC, useMemo } from 'react';

const useStyle = makeStyles(() => ({
	date: {
		marginRight: 40,
	},
	row: {
		display: 'flex',
	},
}));

export const OrderEventsSkeleton: FC =
	() =>
	{
		const classes = useStyle();

		const threeOrderEvents = useMemo(() => [0, 1, 2], []);

		return <>
			{
				threeOrderEvents.map(id =>
					<div className={classes.row} key={id}>
						<Skeleton className={classes.date} height={40} width="8rem" />
						<Skeleton height={40} width="32rem" />
					</div>,
				)
			}
		</>;
	};
