import { createRemoteJWKSet, FlattenedJWSInput, JWSHeaderParameters, KeyLike } from 'jose';
import { getAuthUrl } from '../../../Util/Api/Resources/getAuthUrl';

let Jwks: JwkSet | undefined;

export type JwkSet = (protectedHeader?: JWSHeaderParameters, token?: FlattenedJWSInput) => Promise<KeyLike>;

export function getJwkSet(): JwkSet
{
	if (Jwks === undefined)
	{
		Jwks = createRemoteJWKSet(
			new URL(
				getAuthUrl('/oauth2/jwks'),
			),
		);
	}

	return Jwks;
}
