import { Divider, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext, useMemo } from 'react';
import { BusinessContextRef } from '../../BusinessContext';
import { FeatureConfiguration } from './FeatureConfiguration/FeatureConfiguration';
import { ProductConfigurationStore } from './ProductConfigurationStore';
import { RecommendedProductConfiguration } from './RecommendedProductConfiguration/RecommendedProductConfiguration';

interface ProductConfigurationProps
{
    store: ProductConfigurationStore;
    allowTwoColumnLayout?: boolean;
}

export const ProductConfiguration: FC<ProductConfigurationProps> =
    (
        {
            store,
            allowTwoColumnLayout,
        }
    ) =>
    {
        const {
            businessStore,
        } = useContext(BusinessContextRef);
        const product = useObserver(() => store.product);
        const recommendations = useObserver(
            () => businessStore.hasProductRecommendations(
                product.id,
                'AT_PRODUCT_OPTIONS',
            )
                ?
                businessStore.getProductRecommendationLists(product.id)
                    .filter(list => list.moment === 'AT_PRODUCT_OPTIONS')
                :
                []
        );
        const featureComponents = useObserver(() => store.visibleConfigurationStores
            .map(
                (configurationStore, idx) =>
                    <React.Fragment
                        key={configurationStore.featureAssignment.id}
                    >
                        <FeatureConfiguration
                            store={configurationStore}
                            productConfigurationStore={store}
                        />
                    </React.Fragment>
            )
        );
        const recommendedProductComponents = useObserver(() => {
            if (!store.recommendProducts)
                return [];
            else
                return recommendations
                    .filter(list => list.productRecommendations.some(recommendation => businessStore.productById.has(recommendation.recommendedProductId)))
                    .map(
                        productRecommendationList =>
                            <RecommendedProductConfiguration
                                key={productRecommendationList.uuid}
                                productConfigurationStore={store}
                                productConfigurationList={productRecommendationList}
                            />,
                    );
        });
        const theme = useTheme();
        const isLargerScreen = useMediaQuery(theme.breakpoints.up('sm'));
        const hasTwoColumnLayout = allowTwoColumnLayout && isLargerScreen;
        const components = useMemo(
            () => {
                const result: JSX.Element[] = [];
                const components = [...featureComponents, ...recommendedProductComponents];
                for (let i = 0; i < components.length; i++)
                {
                    const component = components[i];
                    if (i > 0 && !hasTwoColumnLayout)
                        result.push(<Divider key={i}/>);
                    result.push(component);
                }
                return result;
            },
            [featureComponents, recommendedProductComponents, hasTwoColumnLayout]
        );

        if (components.length > 0)
        {
            return <Grid
                container
                spacing={1}
            >
                {
                    components
                        .map(
                            component =>
                                <Grid
                                    key={component.key}
                                    item
                                    xs={
                                        hasTwoColumnLayout && components.length > 1
                                            ? 6
                                            : 12
                                    }
                                >
                                    {component}
                                </Grid>
                        )
                }
            </Grid>;
        }
        else
        {
            return null;
        }
    };
