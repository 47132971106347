import * as React from 'react';

export const CommaSeparatedList = ({items}: {items: string[]}) => {
    return <>
        {items
            .filter((v, i) => i < items.length - 1)
            .map(item => item + ', ')}
        {items.length > 0 ? items[items.length - 1] : undefined}
    </>;
};
