import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { OrderBuilderSegment } from '../../OrderBuilderSegment';
import { OrderBuilderStore } from '../../OrderBuilderStore';
import { CustomerCardForm } from './form/CustomerCardForm';

interface CustomerCardOrderBuilderSegmentProps
{
	store: OrderBuilderStore;
}

export const CustomerCardOrderBuilderSegment: FC<CustomerCardOrderBuilderSegmentProps> =
	(
		{
			store,
		},
	) =>
	{
		const customerCard = useObserver(() => store.customerCard);
		const hasError = useObserver(() => store.customerCardHasError);
		const setCustomerCard = useObserver(() => store.setCustomerCard);
		const showCustomerCardForm = useObserver(() => store.showCustomerCardForm);
		const showValidation = useObserver(() => store.showValidation);

		if (showCustomerCardForm)
		{
			return <OrderBuilderSegment>
				<CustomerCardForm
					hasError={hasError}
					onChange={setCustomerCard}
					showValidation={showValidation}
					value={customerCard}
				/>
			</OrderBuilderSegment>;
		}
		else
		{
			return null;
		}
	};
