import { useEffect } from 'react';
import { PaymentMethodDescriptor } from '../../../../../../Api/Payment/PaymentMethodDescriptor';

export function useSelectPaymentMethodIfOnlyOneExists(
	onSelect: (paymentMethodId?: string) => void,
	paymentMethods: PaymentMethodDescriptor[] | undefined = undefined,
	selectedPaymentMethod: PaymentMethodDescriptor | undefined,
	isInitialized: boolean,
): void
{
	useEffect(() =>
	{
		if (isInitialized)
		{
			if (paymentMethods !== undefined && paymentMethods.length === 1)
			{
				if (selectedPaymentMethod?.id !== paymentMethods[0].id)
					onSelect(paymentMethods[0].id);
			}
		}
	}, [isInitialized, onSelect, paymentMethods, selectedPaymentMethod?.id]);
}