import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { useRootContext } from '../../../../../RootContext';

const useStyle = makeStyles(() => ({
	grid: {
		overflowX: 'visible',
		overflowY: 'visible',
		height: '180px',
		width: '100%',
	},
	image: {
		maxHeight: 120,
		maxWidth: 'calc(100% - 100px)',
	},
	root: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		position: 'relative',
		height: '180px',
		width: '100%',
	},
}));


export const MenuLogo: FC =
	() =>
	{
		const {brandingService} = useRootContext(true);

		const classes = useStyle();

		const logoImageUrl = useObserver(() => brandingService.logoImageUrl);

		return <Grid className={classes.grid}>
			<div className={classes.root}>
				<img
					alt="logo"
					className={classes.image}
					id="top-menu-logo"
					src={logoImageUrl}
				/>
			</div>
		</Grid>;
	};