export enum DialogInputSpecType
{
    PIN,
}

export abstract class DialogInputSpec
{
    private _type: DialogInputSpecType;

    constructor(type: DialogInputSpecType)
    {
        this._type = type;
    }

    get type(): DialogInputSpecType
    {
        return this._type;
    }
}
