import { makeAutoObservable, reaction } from 'mobx';
import { IDisposer } from 'mobx-utils';
import { Business, BusinessProfile } from '../../Api/Business/Business';
import { OrderDestinationType, Place } from '../../Api/Business/Place';
import { Client } from '../../Bridge/Client/Client';
import { Storage } from '../../Bridge/Storage/Storage';
import { fetchList } from '../../Util/Api';
import { PeriodLogger } from '../../Util/PeriodLogger';
import { BoxedReaction } from '../../Util/Reaction/BoxedReaction';
import { BusinessProvider } from '../BrandingInformation/BusinessProvider';
import { LocationService } from '../Location/LocationService';

export class CurrentPlaceService implements BusinessProvider
{
    /*---------------------------------------------------------------*
     *                          Properties                           *
     *---------------------------------------------------------------*/

    private _initialized: boolean;
    public readonly onInitialized: Promise<void>;
    private resolveOnInitialized: () => void;

    private readonly client: Client;
    locationService: LocationService;
    businessByPosition: Business | undefined;
    scannedPlace: Place | undefined;
    scannedBusiness: Business | undefined;
    private static updateBusinessByPositionReaction = BoxedReaction.create();
    private static readonly LAST_IN_SCANIN_STORAGE_KEY = 'lastInPlace';
    private readonly inPlace: PeriodLogger;

    /*---------------------------------------------------------------*
     *                          Constructor                          *
     *---------------------------------------------------------------*/

    constructor(
        storage: Storage,
        client: Client,
        locationService: LocationService,
    )
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });

        this.client = client;
        this.locationService = locationService;

        CurrentPlaceService.updateBusinessByPositionReaction
            .define(
                () => this.locationService.position,
                position => this.onPositionChange(position)
            );
        this.inPlace = new PeriodLogger(
            storage,
            CurrentPlaceService.LAST_IN_SCANIN_STORAGE_KEY
        );

        this.onInitialized = new Promise<void>(resolve => {
            this.resolveOnInitialized = resolve;
        });
        Promise
            .all([
                this.inPlace.onInitialized
            ])
            .then(() => {
                this.setInitialized();
            });
    }

    /*---------------------------------------------------------------*
     *                        Initialization                         *
     *---------------------------------------------------------------*/

    public registerEventListeners(): IDisposer
    {
        return reaction(
            () => this.client.visibility === 'visible'
                && this.place !== undefined,
            inActiveScanIn => {
                if (inActiveScanIn)
                {
                    this.inPlace.startPeriod()
                }
                else
                {
                    this.inPlace.endPeriod();
                }
            },
            {
                fireImmediately: true,
            }
        );
    }

    /*---------------------------------------------------------------*
     *                           Computed                            *
     *---------------------------------------------------------------*/

    get place(): Place | undefined
    {
        return this.scannedPlace;
    }

    get business(): Business | undefined
    {
        return this.scannedBusiness !== undefined
            ?
            this.scannedBusiness
            :
            this.businessByPosition;
    }

    public get endOfLastInPlace(): Date | undefined
    {
        return this.inPlace.endOfLastPeriod;
    }

    public get supportedDestinationTypes(): OrderDestinationType[]
    {
        return this.place?.supportedDestinationTypes ?? [];
    }

    public get smsRemindersEnabled(): boolean
    {
        return this.place?.smsRemindersEnabled ?? false;
    }

    /*---------------------------------------------------------------*
     *                            Actions                            *
     *---------------------------------------------------------------*/

    private setBusinessByPosition(businessByPosition: Business): void
    {
        this.businessByPosition = businessByPosition;
    }

    setScannedBusinessAndPlace(business: Business, place: Place): void
    {
        this.scannedBusiness = business;
        this.scannedPlace = place;
    }

    /*---------------------------------------------------------------*
     *                          Public logic                         *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                         Private logic                         *
     *---------------------------------------------------------------*/

    private onPositionChange(position: GeolocationPosition | undefined): void
    {
        if (position !== undefined)
        {
            fetchList(
                '/public/business/gps',
                {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                },
                BusinessProfile)
                .then(businesses =>
                {
                    if (businesses.length > 0)
                    {
                        this.setBusinessByPosition(businesses[0]);
                    }
                });
        }
    }

    private setInitialized(): void
    {
        this._initialized = true;
        this.resolveOnInitialized();
    }
}
