import { HttpRequestException } from './HttpRequestException';
import { AxiosError } from 'axios';

export class HttpRequestTimeoutException extends HttpRequestException
{
	// ------------------------- Properties -------------------------

	readonly name = 'HttpRequestTimeoutException';

	// ------------------------ Constructor -------------------------

	constructor(cause: AxiosError, timeout: number)
	{
		super(
			cause,
			`HTTP request timed out after ${timeout}ms: "${cause.config.method?.toUpperCase()} ${cause.config.url}".`,
		);
	}

	// ----------------------- Initialization -----------------------

	// -------------------------- Computed --------------------------

	// -------------------------- Actions ---------------------------

	// ------------------------ Public logic ------------------------

	// ----------------------- Private logic ------------------------
}
