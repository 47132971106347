import { createStyles, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { useIsKioskMode } from '../../../../../Service/KioskService/Api/useIsKioskMode';
import { PageDialog } from '../../../../UI/PageDialog';
import { topBarHeight, topBarHeightMobile } from '../../../../UI/PageTopBar';
import { Product } from '../Product';
import { ProductContextProvider } from '../ProductContext';
import { ProductDrawer } from '../ProductDrawer/ProductDrawer';
import { ProductStore } from '../ProductStore';
import { ProductDialogActions } from './ProductDialogActions';

const useStyles = makeStyles((theme: Theme) => createStyles( {
    PaperFullScreen: {
        // height: '100vh',
        alignSelf: 'stretch',
    },
    TitleBar: {
        [theme.breakpoints.down('sm')]: {
            height: topBarHeightMobile,
        },
        [theme.breakpoints.up('md')]: {
            height: topBarHeight,
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    TitleBarInfo: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        paddingTop: 24,
        paddingRight: 0,
        paddingBottom: 20,
        paddingLeft: 24,
    },
    TitleBarConfiguration: {
        paddingRight: 0,
    },
    TitleBarContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    CloseIconAbovePicture: {
        color: 'white',
        filter: 'drop-shadow(0px 0px 0.4rem black)',
    },
    BackdropFullScreen: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
}));

interface ProductDialogProps
{
    store: ProductStore;
}

export const ProductDialog: FC<ProductDialogProps> =
    (
        {
            store,
        },
    ) =>
    {
        const classes = useStyles();
        const isKioskMode = useIsKioskMode();
        const inConfigurationScreen = useObserver(() => store.configurationStore);

        return useObserver(() => {
            if (isKioskMode)
            {
                return <ProductDrawer
                    productStore={store}
                    onClose={
                        async () =>
                            store.closeStore()
                    }
                />;
            }
            else
            {
                const hasImage = store.product.imageUrl !== undefined;
                const imageBackgroundIsWhite = store.product.imageBackgroundColor !== undefined
                    && store.product.imageBackgroundColor.r === 255
                    && store.product.imageBackgroundColor.g === 255
                    && store.product.imageBackgroundColor.b === 255;
                const makeBackgroundWhite = !inConfigurationScreen && hasImage && imageBackgroundIsWhite;

                return <ProductContextProvider
                    value={{
                        productStore: store,
                    }}
                >
                    <PageDialog
                        forceScreenMode={isKioskMode ? 'fullScreen' : undefined}
                        fullWidth
                        classes={{
                            paperFullScreen: classes.PaperFullScreen,
                            closeIcon: (!store.isInConfigurationMode && hasImage && !imageBackgroundIsWhite)
                                ? classes.CloseIconAbovePicture
                                : undefined,
                            backdropFullScreen: classes.BackdropFullScreen,
                        }}
                        maxWidth="md"
                        onClose={() => store.close()}
                        title={
                            store.isInConfigurationMode
                                ?
                                store.product.name
                                :
                                (
                                    hasImage
                                        ?
                                        undefined
                                        :
                                        store.product.name
                                )
                        }
                        dialogActions={<ProductDialogActions store={store}/>}
                        background={makeBackgroundWhite ? 'white' : undefined}
                        contentPaddingTop={0}
                        contentPaddingBottom={0}
                        contentPaddingLeft={0}
                        contentPaddingRight={0}
                    >
                        <Product
                            store={store}
                        />
                    </PageDialog>
                </ProductContextProvider>;
            }

        });
    };