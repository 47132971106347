import { makeAutoObservable } from 'mobx';
import { Storage } from '../../Bridge/Storage/Storage';
import { StorageVars } from '../../Constants/StorageConstants';
import { StoredVariable } from '../../Util/StoredVariable';

export class KioskService
{
	/*---------------------------------------------------------------*
     *                          Properties                           *
     *---------------------------------------------------------------*/

	readonly storage: Storage;
	public readonly isKioskMode: StoredVariable<boolean>;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor(
		storage: Storage
	)
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});

		this.storage = storage;
		this.isKioskMode = new StoredVariable<boolean>(
			storage,
			StorageVars.IsKioskMode,
			value => value === 'true' || value === '1',
			value => value ? 'true' : undefined
		);
		this.isKioskMode.syncWithStorage();
	}

	/*---------------------------------------------------------------*
	 *                        Initialization                         *
	 *---------------------------------------------------------------*/

	/*---------------------------------------------------------------*
	 *                            Actions                            *
	 *---------------------------------------------------------------*/

	/*---------------------------------------------------------------*
	 *                         Public logic                          *
	 *---------------------------------------------------------------*/

	/*---------------------------------------------------------------*
	 *                         Private logic                         *
	 *---------------------------------------------------------------*/
}