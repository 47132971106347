import { Grow, makeStyles, Typography } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { UnsupportedOperationException } from '../../../../../Util/Exception/UnsupportedOperationException';
import { CouponBadgeStore } from './CouponBadgeStore';

const useStyles = makeStyles(theme => ({
	couponBadge: {
		backgroundColor: theme.palette.primary.main,
		borderRadius: theme.shape.borderRadius,
		outlineStyle: 'dotted',
		outlineWidth: 1,
		outlineColor: 'rgba(255,255,255,0.8)',
		outlineOffset: -3,
		display: 'inline-block',
		paddingTop: 7,
		paddingBottom: 7,
		paddingLeft: 11,
		paddingRight: 11,
	},
	couponBadgeLabel: {
		color: theme.palette.primary.contrastText,
		fontSize: 'smaller',
	},
}));

export interface CouponBadgeProps
{
	store: CouponBadgeStore;
	style?: React.CSSProperties;
}

export const CouponBadge: FC<CouponBadgeProps> =
	({
		store,
		style,
	}) =>
	{
		const classes = useStyles();

		const badgeLabel = useObserver(() => store.badgeLabel);
		const couponType = useObserver(() => store.couponType);

		if (couponType === 'DISCOUNT_PRODUCT_TOTAL_RELATIVE'
			|| couponType === 'DISCOUNT_PRODUCT_TOTAL_FIXED'
			|| couponType === 'DISCOUNT_PRODUCT_TOTAL_QUANTITY')
		{
			return <Grow
				in
			>
				<div
					className={classes.couponBadge}
					style={style}
				>
					<Typography
						className={classes.couponBadgeLabel}
					>
						{badgeLabel}
					</Typography>
				</div>
			</Grow>;
		}

		throw new UnsupportedOperationException();
	};
