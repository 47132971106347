import * as React from 'react';
import { CSSProperties } from 'react';

export interface BrowserRootLayoutPolyfillContext
{
	lowPerformanceMode: boolean
	scrollTo: (scrollTop: number) => void
	reApplyCss: () => void
	setBackgroundCss: (cssProperties: CSSProperties | undefined) => void
}

export const BrowserRootLayoutPolyfillContextRef = React
	.createContext<BrowserRootLayoutPolyfillContext>({} as BrowserRootLayoutPolyfillContext);

export const BrowserRootLayoutPolyfillContextProvider = BrowserRootLayoutPolyfillContextRef.Provider;
export const BrowserRootLayoutPolyfillContextConsumer = BrowserRootLayoutPolyfillContextRef.Consumer;
