// @ts-nocheck
/* eslint-disable */
import { CardContent, FormGroup, FormHelperText, Typography } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { formatValidationMessagesToText } from '../../../../../../Util/Validation';
import { BoldTypography } from '../../../../../UI/BoldTypography';
import { ProductConfigurationStore } from '../ProductConfigurationStore';
import { FeatureConfigurationStore } from './FeatureConfigurationStore';
import { FeatureConfigurationWithImages } from './WithImages/FeatureConfigurationWithImages';
import { FeatureConfigurationWithoutImages } from './WithoutImages/FeatureConfigurationWithoutImages';

interface FeatureConfigurationProps
{
    store: FeatureConfigurationStore;
    productConfigurationStore: ProductConfigurationStore;
    hideTitleAndDescription?: boolean;
}

export const FeatureConfiguration: FC<FeatureConfigurationProps> =
    (
        {
            store,
            productConfigurationStore,
            hideTitleAndDescription,
        }
    ) =>
    {
        const initialize = useObserver(() => store.initialize);
        const deselectVariant = useObserver(() => store.deselectVariant);
        const productFeatureVariants = useObserver(() => store.productFeature.variants);

        useEffect(() =>
        {
            initialize().then();
        }, [store]);

        useEffect(() =>
        {
            return () =>
            {
                productFeatureVariants.forEach(variant => deselectVariant(variant));
            }
        } ,[deselectVariant, productFeatureVariants]);

        return useObserver(() => {
            const {
                featureAssignment,
                isValid,
                constraintMessages,
                initialized
            } = store;
            const {
                showValidation: showValidationInProduct
            } = productConfigurationStore;
            const {
                showValidation: showValidationInFeature
            } = store;
            const showValidation = showValidationInProduct || showValidationInFeature;
            const productFeature = featureAssignment.productFeature;
            if (!initialized)
            {
                return <></>;
            }
            return <CardContent
                style={{
                    backgroundColor:
                        showValidation && !isValid
                            ?
                            '#f001'
                            :
                            undefined,
                }}
            >
                {
                    !hideTitleAndDescription &&
                    <>
                        <BoldTypography
                            variant="body2"
                        >
                            {productFeature.name}
                            {constraintMessages.length > 0
                                ?
                                ` (${constraintMessages.map(msg => msg.slice(0, 1).toLowerCase() + msg.slice(1)).join(' - ')})`
                                :
                                <></>
                            }
                        </BoldTypography>
                        <Typography
                            variant="body1"
                            color="textSecondary"
                        >
                            {productFeature.description}
                        </Typography>
                    </>
                }
                <FormGroup
                    style={{
                        // this is to ensure that the SwiperControl in the FeatureConfigurationWithImages
                        // has a correct height in the kiosk product drawer
                        display:
                            store.hasAnyImage
                                ? 'block'
                                : undefined,
                    }}
                >
                    {
                        store.hasAnyImage
                            ? <FeatureConfigurationWithImages
                                store={store}
                            />
                            : <FeatureConfigurationWithoutImages
                                store={store}
                            />
                    }
                    {
                        (showValidation || showValidationInFeature) && !isValid
                            ? <FormHelperText
                                error
                            >
                                {formatValidationMessagesToText(store.validationErrors)}
                            </FormHelperText>
                            : <></>
                    }
                </FormGroup>
            </CardContent>;
        })
    };
