import { Locale as DateFnsLocale } from 'date-fns';
import { makeAutoObservable } from 'mobx';

export class Locale
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    id: string;
    countryCode: string;
    languageCode: string;
    dateFnsLocale: DateFnsLocale;

    // ------------------------ Constructor -------------------------

    constructor(
        id: string,
        countryCode: string,
        languageCode: string,
        dateFnsLocale: DateFnsLocale,
    )
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });

        this.id = id;
        this.countryCode = countryCode;
        this.languageCode = languageCode;
        this.dateFnsLocale = dateFnsLocale;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------
    
    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
