import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback } from 'react';
import { NavigationMenu } from '../NavigationMenu/NavigationMenu';
import { EntranceStore } from './EntranceStore';

interface EntranceNavigationMenuProps
{
    store: EntranceStore;
}

export const EntranceNavigationMenu: FC<EntranceNavigationMenuProps> =
    (
        {
            store,
        },
    ) =>
    {
        const userProfileHidden = useObserver(() => store.userProfileHidden.value);
        const hideOrderHistory = useObserver(() => store.hideOrderHistory);
        const openHistory = useObserver(() => store.openHistory);
        const openProfile = useObserver(() => store.openProfile);
        const openManagerPanel = useCallback(
            async () => {
                store.isMenuOpen.set(false);
                await store.attemptEnterManager();
            },
            [store],
        );
        const isMenuOpen = useObserver(() => store.isMenuOpen);
        const handleOpenHistory = useCallback(
            () =>
                openHistory(true),
            [openHistory],
        );
        
        return <NavigationMenu
            userProfileHidden={userProfileHidden}
            orderHistoryHidden={hideOrderHistory}
            openHistory={handleOpenHistory}
            openProfile={openProfile}
            openManagerPanel={openManagerPanel}
            isMenuOpen={isMenuOpen}
        />;
    };
