import { isWidthUp } from '@material-ui/core';
import * as React from 'react';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useScreenWidth } from '../../../../../Util/Hooks/useScreenWidth';
import { useScrollYPosition } from '../../../../../Util/Hooks/useScrollYPosition';
import { topBarHeight, topBarHeightMobile } from '../../../../UI/PageTopBar';
import { SearchContextRef } from '../../../../UI/Search/SearchContext';

interface MenuSearchSpacerProps
{
}

export const MenuSearchSpacer: FC<MenuSearchSpacerProps> =
	(
		{
			children,
		},
	) =>
	{
		const screenWidth = useScreenWidth();
		const isAtLeastMediumSizedWindow = useMemo(
			() => isWidthUp('md', screenWidth), [screenWidth]
		);
		const scrollY = useScrollYPosition();
		const {isSearching, query} = useContext(SearchContextRef);
		const display = useMemo(
			() => isSearching && query !== undefined
				? 'simple'
				: 'expandable',
			[isSearching, query]
		);
		const [expanded, setExpanded] = useState(false);
		useEffect(
			() => {
				if (isSearching)
				{
					if (scrollY === 0)
						setExpanded(true);
				}
				else
				{
					if (scrollY === 0)
						setExpanded(false);
				}
			},
			[isSearching, scrollY]
		);
		const searchBarHeight = useMemo(
			() => isAtLeastMediumSizedWindow ? topBarHeight : topBarHeightMobile,
			[isAtLeastMediumSizedWindow]
		)
		return display === 'simple'
			? <div
				style={{
					height: searchBarHeight,
				}}
			/>
			: <div
				style={{
					height: expanded ? searchBarHeight : 0,
					transitionTimingFunction: 'ease',
					transition: 'height 300ms',
				}}
			/>;
	};
