import { useObserver } from 'mobx-react-lite';
import { useContext, useMemo } from 'react';
import { BusinessContextRef } from '../BusinessContext';

export function useShowHistoryOpenBillSection()
{
	const {currentPlaceService} = useContext(BusinessContextRef);
	const place = useObserver(() => currentPlaceService?.place);
	const orderBillSupported = useObserver(() => place?.isOrderBillSupported ?? false);
	const payExternalBillLegacyMode = useObserver(() => place?.payExternalBillLegacyMode);
	return useMemo(
		() => place !== undefined && orderBillSupported && !payExternalBillLegacyMode,
		[orderBillSupported, payExternalBillLegacyMode, place]
	);
}