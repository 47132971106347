import { ISO8601ToDateTransformer, NumberToDecimalTransformer, SerializationProfile, StringToDurationTransformer } from '../../Util/Serialization/Serialization';
import { RoutingProfile } from '../Business/Routing/Routing';
import { ColorProfile } from '../Other/Color';
import { NutritionInformationProfile } from './NutritionInformation';
import { Product } from './Product';
import { ProductFeatureAssignmentProfile } from './ProductFeatureAssignment';
import { ProductRecommendationListProfile } from './ProductRecommendationList';

export const ProductProfile =
    SerializationProfile.create(Product)
        .profile('nutritionInformation', NutritionInformationProfile)
        .profile('featureAssignments', ProductFeatureAssignmentProfile)
		.profile('productRecommendationLists', ProductRecommendationListProfile)
        .profile('imageBackgroundColor', ColorProfile)
        .profile('thumbnailBackgroundColor', ColorProfile)
		.transform('todayAvailableFrom', ISO8601ToDateTransformer)
		.transform('todayAvailableUntil', ISO8601ToDateTransformer)
        .profile('menuEntryColor', ColorProfile)
		.profile('menuEntryTextColor', ColorProfile)
		.profile('imageTextBackdropColor', ColorProfile)
		.profile('imageTextColor', ColorProfile)
		.profile('routing', RoutingProfile)
        .rewrite('fees', 'feeIds')
        .transform('price', NumberToDecimalTransformer)
		.transform('preparationTime', StringToDurationTransformer);
