import { BaseStore } from '@intentic/ts-foundation';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import * as URI from 'urijs';
import { RoutingException } from '../../Util/Exception/RoutingException';
import { BoxedReaction } from '../../Util/Reaction/BoxedReaction';
import { Screen } from './Screen';
import { ScreenInstantiation } from './ScreenInstantiation';
import { ScreenStack } from './ScreenStack';

const pathMatch =
    require('path-match')({
        strict: false,
        end: false,
    });

const pathToRegexp = require('path-to-regexp');

export abstract class Navigator
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    private static BUSY_TIMEOUT = 1000;

    /**
     * The reaction that when the highest allowed screen according to
     * {@link ScreenStack#highestAllowedOpenScreenInstance} is not the same as the current {@link ScreenInstantiation},
     * we navigate down to the highest allowed one.
     */
    private static highestAllowedScreenReaction: BoxedReaction = BoxedReaction.create();

    /**
     * All root-level {@link Screen}s, i.e. the {@link Screen}s that have no parent {@link Screen}.
     */
    screens = observable.array<Screen>();

    /**
     * Same as {@link #screens}, but then for lookup by ID.
     */
    screenById = observable.map<string, Screen>();

    /**
     * Callbacks to perform before a screen transition is performed. Currently only "pop screen" transitions are
     * supported, hence the "uponLeavingScreenId" variable.
     */
    private readonly screenTransitionCallBacks = observable.map<string, () => Promise<boolean>>();

    isBusy: boolean;

    protected readonly screenStack = new ScreenStack();

    // ------------------------ Constructor -------------------------

    protected constructor()
    {
        makeObservable(this, {
            screens: observable,
            screenById: observable,
            isBusy: observable,
            previousScreenInstance: computed,
            currentScreenInstance: computed,
            currentBaseScreen: computed,
            currentContentScreen: computed,
            currentLeftDrawer: computed,
            currentRightDrawer: computed,
            currentDialogs: computed,
            route: computed,
            openScreens: computed,
            noScreenOpen: computed,
            setScreen: action.bound,
            addScreenTransitionCallback: action.bound,
            deleteScreenTransitionCallback: action.bound,
            setIsBusy: action.bound,
            pushScreen: action.bound,
            popScreen: action.bound,
            routeFromUrl: action.bound,
            routeToScreen: action.bound,
        });
    }

    // ----------------------- Initialization -----------------------

    public initialize(): void
    {
        this.screenStack.initialize();
        Navigator.highestAllowedScreenReaction.define(
            () => this.screenStack.currentScreenInstanceIsAllowedByParents,
            currentScreenAllowed => {
                if (!currentScreenAllowed) {
                    this
                        .popScreensUntil(
                            this.screenStack.highestAllowedOpenScreenInstance,
                            true)
                        .then(() => this.truncateFuture());
                }
            }
        );
    }

    // -------------------------- Computed --------------------------

    get previousScreenInstance(): ScreenInstantiation
    {
        return this.screenStack.getScreenByIdx(this.screenStack.currentIndex - 1);
    }

    get currentScreenInstance(): ScreenInstantiation | undefined
    {
        return this.screenStack.getScreenByIdx(this.screenStack.currentIndex);
    }

    /**
     * The {@link #currentBaseScreen} is the {@link ScreenInstantiation} that spawns the left-
     * and the right sidebar.
     * @todo According to the computation in this method it is also a screen that has no content, which is only true by happenstance in this application
     */
    get currentBaseScreen(): ScreenInstantiation
    {
        for (let idx = this.screenStack.currentIndex; idx >= 0; idx--)
        {
            const screen = this.screenStack.getScreenByIdx(idx);

            if (!screen.screen.isDialog && !screen.screen.isContent)
            {
                return screen;
            }
        }

        return undefined;
    }

    get currentContentScreen(): ScreenInstantiation
    {
        for (let idx = this.screenStack.currentIndex; idx >= 0; idx--)
        {
            const screen = this.screenStack.getScreenByIdx(idx);

            if (screen.screen.isContent || !screen.screen.isDialog)
            {
                return screen;
            }
        }

        return undefined;
    }

    get currentLeftDrawer(): ScreenInstantiation | undefined
    {
        if (this.currentBaseScreen && this.currentBaseScreen.screen.leftDrawer)
        {
            return new ScreenInstantiation(
                this.currentBaseScreen.screen.leftDrawer,
                this.currentBaseScreen.store,
                this.currentBaseScreen);
        }
        else
        {
            return undefined;
        }
    }

    get currentRightDrawer(): ScreenInstantiation | undefined
    {
        if (this.currentBaseScreen && this.currentBaseScreen.screen.rightDrawer)
        {
            return new ScreenInstantiation(
                this.currentBaseScreen.screen.rightDrawer,
                this.currentBaseScreen.store,
                this.currentBaseScreen);
        }
        else
        {
            return undefined;
        }
    }

    get currentDialogs(): ScreenInstantiation[]
    {
        const dialogs: ScreenInstantiation[] = [];

        for (let idx = this.screenStack.currentIndex; idx >= 0; idx--)
        {
            const screen = this.screenStack.getScreenByIdx(idx);

            if (screen?.screen.isDialog)
            {
                dialogs.push(screen);
            }
        }

        return dialogs;
    }

    /**
     * Generates the current URL path from the {@link Navigator} state
     */
    get route(): string
    {
        if (this.screenStack.currentIndex !== undefined)
        {
            return this.screenStack.openScreens
                .filter(screen => screen.screen.toRoute != null)
                .map(screen => screen.screen.toRoute(screen.store))
                .filter(route => route !== undefined && route.length > 0)
                .join('');
        }
        else
        {
            return undefined;
        }
    }

    get openScreens(): ScreenInstantiation[]
    {
        return this.screenStack.openScreens;
    }

    get noScreenOpen(): boolean
    {
        return this.screenStack.noScreenOpen;
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    setScreen(screenId: string, screen: Screen): void
    {
        this.screenById.set(
            screen.id,
            screen);
        this.screens.push(screen);
    }

    public addScreenTransitionCallback(uponLeavingScreenId: string, callback: () => Promise<boolean>)
    {
        this.screenTransitionCallBacks.set(uponLeavingScreenId, callback);
    }

    public deleteScreenTransitionCallback(uponLeavingScreenId: string)
    {
        this.screenTransitionCallBacks.delete(uponLeavingScreenId);
    }

    public setIsBusy(isBusy: boolean): void
    {
        this.isBusy = isBusy;

        // TODO: Create more elegant solution to stop pushing of multiple screens and handle busy timeout
        // Once the navigator is set to busy, time it out after a period
        if (this.isBusy)
        {
            setTimeout(
                () => this.setIsBusy(false),
                Navigator.BUSY_TIMEOUT);
        }
    }

    public registerScreen(screen: Screen): void
    {
        this.setScreen(screen.id, screen);
    }

    // ------------------------ Public logic ------------------------

    public getScreenById(id: string): Screen
    {
        const screenById = this.currentScreenInstance
            ?
            this.currentScreenInstance.screen.screenById
            :
            this.screenById;
        const match = screenById.get(id);
        if (match === undefined)
        {
            throw new RoutingException(`Screen with id ${id} not found ${this.currentScreenInstance ? 'on screen ' + this.currentScreenInstance?.screen.id : ''}!`);
        }
        return match;
    }

    /**
     * <p>Creates a {@link ScreenInstantiation} from the {@link Screen} with the given id and the given {@link BaseStore},
     * and pushes it to the screen stack.</p>
     * <p>This function is even used by {@link Navigator#routeToScreen}.</p>
     * @param screenId
     * @param store
     */
    public pushScreen(
        screenId: string,
        store: BaseStore
    ): Promise<ScreenInstantiation>
    {
        const newScreenInstance = this.instantiate(screenId, store, this.currentScreenInstance);

        this.screenStack.truncateFutureAndPush(newScreenInstance);

        return Promise.resolve(newScreenInstance);
    }

    /**
     * @param force
     * @return Promise<boolean> Note that it in most situations, one should not subscribe to this promise, as this
     * promise will only exist/fulfill when the pop screen transition is initiated from the user interface (i.e. when
     * this method is called), and not from a device/browser's 'back'-button. If you want a reaction to the pop screen
     * event that will also happen in those remaining cases, you should register a callback with
     * {@link #addScreenTransitionCallback}.
     */
    public popScreen(
        force: boolean = false,
        uuidOfScreenInstantiationToPop?: string,
    ): Promise<boolean>
    {
        const thiss = this;
        return flow(function * () {
            if (thiss.currentScreenInstance === undefined)
            {
                return false;
            }
            if (uuidOfScreenInstantiationToPop !== undefined
                && thiss.currentScreenInstance.uuid !== uuidOfScreenInstantiationToPop)
            {
                return true;
            }
            if (thiss.currentScreenInstance.markedForPop)
            {
                return true;
            }
            if (force)
            {
                if (thiss.screenTransitionCallBacks.has(thiss.currentScreenInstance.screen.id))
                {
                    thiss.deleteScreenTransitionCallback(thiss.currentScreenInstance.screen.id);
                }
                thiss.screenStack.pop();
                return true;
            } else {
                const screenInstanceToPop = thiss.currentScreenInstance;
                screenInstanceToPop.setMarkedForPop(Promise.resolve());
                const doPop: boolean = yield thiss.screenTransitionCallback(thiss.currentScreenInstance.screen.id);
                if (doPop) { // don't start this pop screen transition if it shouldn't be performed
                    thiss.screenStack.pop();
                }
                else
                {
                    screenInstanceToPop.setUnmarkedForPop();
                }
                return doPop;
            }
        })();
    }

    public popScreensUntil(screenInstance: ScreenInstantiation, force: boolean = false): Promise<boolean>
    {
        const recur: () => Promise<boolean> = () => {
            if (this.currentScreenInstance.isDirectOrIndirectChildOf(screenInstance)) {
                return this.popScreen(force)
                    .then(isPopped => {
                        if (isPopped) {
                            return recur();
                        } else {
                            return false;
                        }
                    });
            } else if (this.currentScreenInstance === screenInstance) {
                return Promise.resolve(true); // 'true' because screens did indeed pop until the specified instance
            } else {
                throw new RoutingException(`Current ScreenInstantiation (${this.currentScreenInstance?.screen.id})
                    is not equal to or (indirect) child of supplied ScreenInstantiation (${screenInstance.screen.id}`);
            }
        };
        return recur();
    }

    public truncateFuture(): Promise<void>
    {
        this.screenStack.truncateFuture();
        return Promise.resolve();
    }

    /**
     * @param screenID
     * @return The {@link ScreenInstantiation}s that have a {@link Screen} with the supplied ID.
     */
    public getOpenScreenInstances(screenID: string): ScreenInstantiation[]
    {
        return this.screenStack.openScreens
            .filter(screenInstance => screenInstance.screen.id === screenID);
    }

    /**
     * @param screenID
     * @return The only {@link ScreenInstantiation} that has a {@link Screen} with the supplied ID.
     * @throws Error if more than one {@link ScreenInstantiation} has the supplied {@link Screen} ID
     */
    public getSingleOpenScreenInstance(screenID: string): ScreenInstantiation | null
    {
        const results = this.getOpenScreenInstances(screenID);
        if (results.length > 1)
        {
            throw new RoutingException(`Not one open ScreenInstantiation found with given Screen ID, but ${results.length}.`);
        }
        return results.length === 0
            ?
            null
            :
            results[0];
    }

    public routeFromUrl(url: string): Promise<any>
    {
        const uri = URI.parse(url);

        return this.routeToScreen(uri.path);
    }

    public routeToScreen(route: string): Promise<void>
    {
        // let route select the next child screen, then recur

        const childScreens = this.currentScreenInstance
            ? this.currentScreenInstance.screen.childScreens
            : this.screens;
        const childScreenMatch = (childScreens as Screen[])
            .filter(childScreen => childScreen.hasRoute)
            .map(childScreen => ({
                screen: childScreen,
                match: pathMatch(childScreen.route)(route), // path variable values if match, false if no match
            }))
            .filter(result => {
                return this.currentScreenInstance
                    ?
                    this.currentScreenInstance.screen.allowRoutingToChildScreen(result.screen, this.currentScreenInstance.store)
                    :
                    Promise.resolve(true);
            })
            .find(result => result.match !== false);

        if (childScreenMatch)
        {
            const screen: Screen = childScreenMatch.screen;
            const pathVariableAssignments = childScreenMatch.match;

            return screen
                .fromRoute!(
                    route,
                    pathVariableAssignments,
                    this.currentScreenInstance
                        ? this.currentScreenInstance.store
                        : undefined,
                    this.currentScreenInstance
                        ? this.currentScreenInstance.inheritedContexts
                        : undefined,
                )
                .then(
                    async childStore =>
                    {
                        await childStore?.reinitializeStore(false);

                        return childStore;
                    },
                )
                .then(childStore => {
                    if (childStore === null) {
                        return;
                    }
                    let childScreenIsPushed: Promise<any>;

                    /*
                     * If no Screen has been pushed yet, which only happens if we #screen is the root Screen, then push
                     * a new Screen
                     */
                    if (!this.currentScreenInstance)
                    {
                        childScreenIsPushed = this.pushScreen(
                            screen.id,
                            childStore,
                        );
                    }
                    else
                    {
                        childScreenIsPushed = Promise.resolve();
                    }

                    /*
                     * Recur for rest of route
                     */
                    return childScreenIsPushed
                        .then(() => {
                            let wholeRoute = route;
                            let routeRegexp: RegExp = pathToRegexp(
                                screen.route,
                                undefined,
                                {
                                    strict: false,
                                    end: false,
                                },
                            );
                            let currentRoute = wholeRoute.match(routeRegexp)![0]!;
                            let childRoute = wholeRoute.replace(currentRoute, '')
                                .replace(/^\//, '');

                            if (childRoute.length === 0)
                            {
                                return Promise.resolve();
                            }
                            else
                            {
                                return this.routeToScreen(childRoute);
                            }
                        });
                })
                .catch(e => {
                    throw new RoutingException(`Exception occurred while routing to Screen ${screen.id}`, e);
                });
        }
        else
        {
            return Promise.resolve();
        }
    }

    protected instantiate(
        screenId: string,
        store: BaseStore,
        parent: ScreenInstantiation | undefined
    ): ScreenInstantiation
    {
        const screen = this.getScreenById(screenId);

        return new ScreenInstantiation(
            screen,
            store,
            parent
        );
    }

    // ----------------------- Private logic ------------------------

    /**
     * @param leavingScreenId
     * @return A {@link Promise<boolean>} that has value {@code true} if the screen transition should be allowed to
     * happen, or {@code false} if not.
     */
    screenTransitionCallback(leavingScreenId: string): Promise<boolean>
    {
        let match = this.screenTransitionCallBacks.get(leavingScreenId);
        if (match) {
            return match()
                .then(doPop => {
                    if (doPop) {
                        this.deleteScreenTransitionCallback(leavingScreenId);
                    }
                    return doPop;
                });
        } else {
            return Promise.resolve(true);
        }
    }
}
