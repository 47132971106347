import { ProductCategory } from '../../Api/Product/ProductCategory';

export function getProductCategoryAndAllParentProductCategoryIds(category: ProductCategory, allCategories: ProductCategory[]): number[]
{
	return getIdOfCategoryAndThatOfParents(category, allCategories);
}

function getIdOfCategoryAndThatOfParents(category: ProductCategory, allCategories: ProductCategory[]): number[]
{
	const parentCategory = getParentCategory(category, allCategories);

	if (parentCategory === undefined)
		return [category.id];
	else
		return [category.id, ...getIdOfCategoryAndThatOfParents(parentCategory, allCategories)];
}

function getParentCategory(category: ProductCategory, allCategories: ProductCategory[]): ProductCategory | undefined
{
	for (const someCategory of allCategories)
	{
		for (const someChild of someCategory.categories)
		{
			if (someChild.id === category.id)
				return someCategory;
		}
	}
}