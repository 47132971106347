import { makeAutoObservable, observable } from 'mobx';
import { ScreenInstantiation } from './ScreenInstantiation';

export class ScreenStack
{
    // ------------------------- Properties -------------------------

    /**
     * Any time a stack of {@link Screen}s forms the GUI, with the top {@link Screen} one corresponding to the most
     * specific "position" of the client in the UI.
     */
    private readonly screenStack = observable.array<ScreenInstantiation>();

    /**
     * This index is the current position in the stack. When the current index does not point to the top of the stack
     * this means e.g. the browser's "forward" button is enabled
     */
    private index: number | undefined;

    // ------------------------ Constructor -------------------------

    constructor()
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
    }

    // ----------------------- Initialization -----------------------

    public initialize(): void
    {
    }

    // -------------------------- Computed --------------------------

    get currentIndex(): number | undefined
    {
        return this.index;
    }

    get currentScreenInstance(): ScreenInstantiation
    {
        return this.screenStack[this.index];
    }

    /**
     * The part of the stack that's in history plus the current, ordered from bottom to top of stack (current screen
     * being the top of the stack).
     */
    get openScreens(): ScreenInstantiation[]
    {
        return this.screenStack
            .slice(0, this.index + 1);
    }

    get noScreenOpen(): boolean
    {
        return this.index === undefined;
    }

    get highestAllowedOpenScreenInstance(): ScreenInstantiation | undefined
    {
        for (let i = 1; i <= this.index; i++) {
            let parentScreenInstance = this.screenStack[i - 1];
            let screenInstance = this.screenStack[i];
            if (screenInstance.screen !== undefined && !parentScreenInstance.allowAsChild(screenInstance.screen)) {
                return parentScreenInstance;
            }
        }
        return this.currentScreenInstance;
    }

    get currentScreenInstanceIsAllowedByParents(): boolean
    {
        return this.highestAllowedOpenScreenInstance === this.currentScreenInstance;
    }

    // -------------------------- Actions ---------------------------

    /**
     * Pops the top open {@link ScreenInstantiation}. This does not let the application forget about the popped
     * {@link ScreenInstantiation}, as it stays on the future stack, for e.g. the browser's "forward" button.
     */
    public pop(): void
    {
        this.decrementIndex();
    }

    /**
     * Remove all elements above {@link #currentIndex} and push new {@link ScreenInstantiation} onto the stack.
     * @param screenInstantiation
     */
    public truncateFutureAndPush(screenInstantiation: ScreenInstantiation): void
    {
        this.incrementIndex();

        /*
         * If we push a new screen while we are not at the top of the screen stack, we have to remove all screens
         * above the one we are now
         */
        this.truncateFrom(this.index);

        this.screenStack.push(screenInstantiation);
    }

    /**
     * Remove all {@link ScreenInstantiation}s of the stack that have an index *greater or equal to* the current index.
     * Not that this means {@link #currentIndex} will be out of bounds after calling this method.
     */
    public truncateFuture(): void
    {
        this.truncateFrom(this.index + 1);
    }

    public moveTo(screenStackIndex: number)
    {
        this.index = screenStackIndex;
    }

    // ------------------------ Public logic ------------------------

    public getScreenByIdx(screenStackIdx?: number): ScreenInstantiation | undefined
    {
        if (this.screenStack.length === 0
            || screenStackIdx === undefined
            || screenStackIdx >= this.screenStack.length)
        {
            return undefined;
        }
        else
        {
            return this.screenStack[screenStackIdx];
        }
    }

    // ----------------------- Private logic ------------------------

    private incrementIndex(): void
    {
        if (this.index === undefined)
        {
            this.moveTo(0);
        }
        else
        {
            this.moveTo(this.index + 1);
        }
    }

    private decrementIndex(): void
    {
        if (this.index === 0 || this.index === undefined)
        {
            this.moveTo(undefined);
        }
        else
        {
            this.moveTo(this.index - 1);
        }
    }

    /**
     * Remove all {@link ScreenInstantiation}s of the stack that have an index *greater or equal to* the provided index.
     */
    private truncateFrom(index: number): void
    {

        /*
         * If we push a new screen while we are not at the top of the screen stack, we have to remove all screens
         * above the one we are now
         */
        this.screenStack.spliceWithArray(
            index,
            this.screenStack.length - index,
            []);
    }
}
