import { useEffect, useState } from 'react';
import { OrderEvent } from '../../../../../../Api/Order/OrderEvent';
import { fetchOrderEvents } from '../../../../../../Service/OrderService/Api/Waiter/fetchOrderEvents';

export function useOrderEvents(orderId: number): OrderEvent[] | undefined
{
	const [events, setEvents] = useState();

	useEffect(() => {
		fetchOrderEvents(orderId).then(setEvents);
	}, [orderId]);

	return events;
}