import { Card, CardActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FC, useCallback } from 'react';
import { ProductConfiguration } from '../../../../../../Api/Product/ProductConfiguration';
import { useLocalizer } from '../../../../../../Bridge/Localization/useLocalizer';
import { ActionButton } from '../../../../../UI/action-button/ActionButton';
import { ProductConfiguration as ProductConfigurationComponent } from '../ProductConfiguration';
import { ProductConfigurationStore } from '../ProductConfigurationStore';

const useStyles = makeStyles(() => ({
	card: {
		margin: 8,
	},
	cardActions: {
		justifyContent: 'flex-end',
	},
}));

interface AddRecommendedProductEditorProps
{
	productConfigurationStore: ProductConfigurationStore
	onCommit: (productConfiguration: ProductConfiguration) => void
	onCancel: () => void
}

export const AddRecommendedProductEditor: FC<AddRecommendedProductEditorProps> =
	(
		{
			productConfigurationStore,
			onCommit,
			onCancel,
		},
	) =>
	{
		const onClickOk = useCallback(
			() => {
				const productConfiguration = productConfigurationStore
					.requestValidProductConfiguration();
				if (productConfiguration !== undefined)
					onCommit(productConfiguration);
			},
			[onCommit, productConfigurationStore]
		)
		const localizer = useLocalizer();
		const {card, cardActions} = useStyles();
		return <Card className={card}>
			<ProductConfigurationComponent
				store={productConfigurationStore}
			/>
			<CardActions className={cardActions}>
				<ActionButton
					onClick={onCancel}
					text={localizer.translate('Generic-Cancel')}
					variant="outlined"
				/>
				<ActionButton
					onClick={onClickOk}
					text={localizer.translate('Generic-Add')}
				/>
			</CardActions>
		</Card>;
	};