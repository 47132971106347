import { trimLeadingSlashes } from '../../trimLeadingSlashes';
import { trimTrailingSlashes } from '../../trimTrailingSlashes';

/**
 * Gets the complete URL of an API resource
 *
 * @param resource the relative URL of the resource
 * @return compelete URL for the resource
 */
export function getApiUrl(resource: string): string
{
	return `${trimTrailingSlashes(process.env.REACT_APP_API_BASE_URL)}/${trimLeadingSlashes(resource)}`;
}
