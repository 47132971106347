// @ts-nocheck
/* eslint-disable */
import { makeAutoObservable } from 'mobx';
import { SerializationProfile } from '../../Util/Serialization/Serialization';
import { Color, ColorProfile } from '../Other/Color';

export class BrandingInformation
{
    // ------------------------- Properties -------------------------

    public backgroundImageUrl: string | undefined;
    public backgroundImageBlur: number | undefined;
    public backgroundColor: Color | undefined;
    public backgroundContrastTextColor: Color | undefined;
    public tintColor: Color | undefined;
    public tintContrastTextColor: Color | undefined;
    public secondaryColor: Color | undefined;
    public secondaryContrastTextColor: Color | undefined;
    public errorColor: Color | undefined;
    public errorContrastTextColor: Color | undefined;
    public appBarOpacity: number | undefined;
    public topBarColor!: Color;
    public logoImageUrl: string | undefined;
    public logoSubtitle: string | undefined;
    public logoInlineImageUrl: string | undefined;
    public body1Color: Color | undefined;
    public body2Color: Color | undefined;

    private averageColorOfBackgroundImage: Color | undefined;

    // ------------------------ Constructor -------------------------

    constructor(
        backgroundImageUrl?: string,
        backgroundImageBlur?: number,
        backgroundColor?: Color,
        backgroundContrastTextColor?: Color,
        tintColor?: Color,
        tintContrastTextColor?: Color,
        secondaryColor?: Color,
        secondaryContrastTextColor?: Color,
        logoImageUrl?: string,
        logoInlineImageUrl?: string,
        logoSubtitle?: string
    )
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
        this.backgroundImageUrl = backgroundImageUrl;
        this.backgroundImageBlur = backgroundImageBlur;
        this.backgroundColor = backgroundColor;
        this.tintColor = tintColor;
        this.tintContrastTextColor = tintContrastTextColor;
        this.secondaryColor = secondaryColor;
        this.secondaryContrastTextColor = secondaryContrastTextColor;
        this.logoImageUrl = logoImageUrl;
        this.logoSubtitle = logoSubtitle;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

    // -------------------- Getters and setters ---------------------
}

export const BrandingInformationProfile =
    SerializationProfile.create(BrandingInformation)
        .profile('backgroundColor', ColorProfile)
        .profile('backgroundContrastTextColor', ColorProfile)
        .profile('tintColor', ColorProfile)
        .profile('tintContrastTextColor', ColorProfile)
        .profile('secondaryColor', ColorProfile)
        .profile('secondaryContrastTextColor', ColorProfile)
        .profile('errorColor', ColorProfile)
        .profile('errorContrastTextColor', ColorProfile)
        .profile('body1Color', ColorProfile)
        .profile('body2Color', ColorProfile);
