import { useCallback, useEffect, useState } from 'react';
import { PaymentMethodDescriptor } from '../../../../../../Api/Payment/PaymentMethodDescriptor';
import { useAdyenCheckoutContext } from '../../../adyen/checkout/AdyenCheckoutContext';
import { adyenCheckoutPaymentMethodRequiresUserInput } from '../../form/editor/settings/adyenCheckoutPaymentMethodRequiresUserInput';

export function useSelectedPaymentMethod(
	paymentMethodById: Map<string, PaymentMethodDescriptor> | undefined = undefined,
	onChange: (paymentMethod: PaymentMethodDescriptor | undefined) => void,
): [PaymentMethodDescriptor | undefined, (paymentMethodId?: string) => void]
{
	const {setPaymentSettingsHasError} = useAdyenCheckoutContext();

	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodDescriptor | undefined>();

	const updateSelectedPaymentMethod = useCallback(
		(paymentMethod: PaymentMethodDescriptor | undefined) =>
		{
			setSelectedPaymentMethod(paymentMethod);
			onChange(paymentMethod);
		},
		[onChange],
	);

	useEffect(
		() =>
		{
			if (selectedPaymentMethod !== undefined)
			{
				const newPaymentMethod = paymentMethodById.get(selectedPaymentMethod.id);

				if (newPaymentMethod === undefined)
					updateSelectedPaymentMethod(undefined);
				else if (selectedPaymentMethod.fee !== newPaymentMethod.fee)
					updateSelectedPaymentMethod(newPaymentMethod);
			}
		},
		[paymentMethodById, selectedPaymentMethod, updateSelectedPaymentMethod],
	);

	const handleSelectPaymentMethod = useCallback((paymentMethodId?: string) =>
	{
		if (selectedPaymentMethod?.id !== paymentMethodId)
		{
			if (paymentMethodId === undefined || paymentMethodById === undefined)
			{
				updateSelectedPaymentMethod(undefined);
			}
			else if (paymentMethodById.has(paymentMethodId))
			{
				const newPaymentMethod = getPaymentMethodOrDefault(paymentMethodById, paymentMethodId);

				if (selectedPaymentMethod?.id !== newPaymentMethod?.id)
				{
					updateSelectedPaymentMethod(newPaymentMethod);

					setPaymentSettingsHasError(
						newPaymentMethod.isIssuerRequired
						|| adyenCheckoutPaymentMethodRequiresUserInput(newPaymentMethod),
					);
				}
			}
			else
			{
				updateSelectedPaymentMethod(undefined);
			}
		}
	}, [paymentMethodById, selectedPaymentMethod?.id, setPaymentSettingsHasError, updateSelectedPaymentMethod]);

	return [selectedPaymentMethod, handleSelectPaymentMethod];
}

function getPaymentMethodOrDefault(paymentMethodById: Map<string, PaymentMethodDescriptor>, id: string): PaymentMethodDescriptor
{
	if (paymentMethodById.has(id))
		return paymentMethodById.get(id)!;
	else
		return Array.from(paymentMethodById.values())[0];
}