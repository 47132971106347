import { alpha, Button, Collapse, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ToggleButton } from '@material-ui/lab';
import { Decimal } from 'decimal.js';
import * as React from 'react';
import { Dispatch, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalizer } from '../../../../../../Bridge/Localization/useLocalizer';
import PriceTag from '../../../../../UI/PriceTag/PriceTag';
import { CustomTipControl } from './CustomTipControl';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
	rootContainer: {
		textAlign: 'end',
	},
	roundingContainer: {
		paddingBlock: theme.spacing(0.5),
		flexWrap: 'wrap',
		display: 'flex',
		justifyContent: 'flex-end',
		gap: theme.spacing(2),
		marginBottom: theme.spacing(1),
	},
	root: {
		'&$selected': {
			color: theme.palette.primary.main,
			backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
			'&:hover': {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
				),
				// Reset on touch devices, it doesn't add specificity
				'@media (hover: none)': {
					backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
				},
			},
		},
	},
	selected: {},
	customControl: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
}));

interface TipControlProps
{
	value?: Decimal;
	onChange: Dispatch<Decimal | undefined>;
	currencyCode: string;
	subTotalAmount: Decimal;
}

const RoundOptions = ['1.05', '1.1', '1.15', '1.2', '1.25'] as const;

export const TipControl: FC<TipControlProps> =
	({
		value,
		onChange,
		currencyCode,
		subTotalAmount,
	}) =>
	{
		const classes = useStyles();
		const localizer = useLocalizer();

		const [roundValue, setRoundValue] = useState<Decimal | undefined>();
		const [openCustomOption, setOpenCustomOption] = useState(false);

		const label = useMemo(
			() => <Typography
			>
				{`${localizer.translate('Order-Tip-Round-To')}:`}
			</Typography>,
			[localizer],
		);
		const otherAmountLabel = useMemo(
			() => localizer.translate('Order-Tip-Choose-Amount'),
			[localizer],
		);

		const roundingValues = useMemo<Decimal[]>(() =>
		{
			return RoundOptions
				.map<Decimal>(option => convertToRoundNumber(subTotalAmount, option))
				.filter((value, index, array) => !value.eq(array[index - 1] ?? subTotalAmount))
				.slice(0, 3);
		}, [subTotalAmount]);

		const handleChange = useCallback((value: Decimal | undefined) =>
		{
			setRoundValue(value);
			if (value !== undefined)
			{
				setOpenCustomOption(false);
				onChange(value.sub(subTotalAmount));
			}
			else
			{
				onChange(undefined);
			}
		}, [onChange, subTotalAmount]);

		const handleChangeValue = useCallback((value: Decimal | undefined) =>
		{
			setRoundValue(undefined);
			onChange(value);
		}, [onChange]);

		useEffect(() =>
		{
			if (roundValue !== undefined && roundingValues.some(value => value.eq(roundValue)))
			{
				handleChange(roundValue);
			}
		}, [handleChange, roundValue, roundingValues]);

		const onOpenCustomOption = useCallback(() =>
		{
			setOpenCustomOption(true);
			setRoundValue(undefined);
		}, []);

		return <div
			className={classes.rootContainer}
		>
			{label}
			<div
				className={classes.roundingContainer}
			>
				{
					roundingValues
						.map(value =>
							<ToggleButton
								classes={{root: classes.root, selected: classes.selected}}
								key={value.toString()}
								value={value}
								onChange={() => handleChange(roundValue?.eq(value) ? undefined : value)}
								selected={roundValue?.eq(value) ?? false}
							>
								<PriceTag
									price={value}
									localizer={localizer}
									currencyCode={currencyCode}
									variant="button"
									color="inherit"
								/>
							</ToggleButton>
						)
				}
			</div>
			<Button
				color="primary"
				onClick={onOpenCustomOption}
			>
				{otherAmountLabel}
			</Button>
			<Collapse in={openCustomOption} unmountOnExit mountOnEnter>
				<div className={classes.customControl}>
					<CustomTipControl
						minimum={0}
						setValue={handleChangeValue}
						value={value}
						currencyCode={currencyCode}
					/>
				</div>
			</Collapse>
		</div>;
	};

function convertToRoundNumber(price: Decimal, percentage: Decimal.Value): Decimal
{
	const priceTimesPercentage = price.mul(percentage);

	const step = priceTimesPercentage.greaterThanOrEqualTo('150') ? 10 : 5;

	const roundingFactor = Decimal.min(
		Decimal.max(
			price.div(10).div(step).round().mul(step),
			priceTimesPercentage.greaterThanOrEqualTo('12.5') ? '2.5' : price.eq(1) ? 2 : 1,
		),
		50,
	);

	return Decimal.max(
		priceTimesPercentage.div(roundingFactor).round().mul(roundingFactor),
		price.div(roundingFactor).ceil().mul(roundingFactor),
	);
}
