import { PaymentMethod } from '@adyen/adyen-web/dist/types/types';
import Decimal from 'decimal.js';
import { ISO4217ToCurrencyTransformer, NumberToDecimalTransformer, SerializationProfile } from '../../Util/Serialization/Serialization';
import { Currency } from '../Other/Currency';
import { PaymentIssuer, PaymentIssuerProfile } from './PaymentIssuer';

export const TRADITIONAL_PAYMENT_METHOD_ID = 'traditional';
export class PaymentMethodDescriptor
{
	readonly id: string;
	readonly name: string;
	readonly imageUrl?: string;
	readonly isIssuerRequired: boolean;
	readonly issuers: PaymentIssuer[] = [];
	readonly butlarooPayPaymentMethod: PaymentMethod | undefined;
	readonly currency: Currency;
	readonly fee: Decimal;

	get hasFee()
	{
		return this.fee.gt(0);
	}
}

export const PaymentMethodDescriptorProfile =
	SerializationProfile
		.create(PaymentMethodDescriptor)
		.transform('currency', ISO4217ToCurrencyTransformer)
		.transform('fee', NumberToDecimalTransformer)
		.profile('issuers', PaymentIssuerProfile);