import { Account } from '../../../Api/Account/Account';
import { getAuthUrl } from '../../../Util/Api/Resources/getAuthUrl';

export async function loginLegacy(account: Account): Promise<string | undefined>
{
	const response = await fetch(
		getAuthUrl('/login/legacy'),
		{
			method: 'POST',
			headers: {
				'Authorization-Key': account.key.toString(),
				'Authorization-Phrase': account.token,
			},
		},
	);

	return response.ok
		? await response.text()
		: undefined;
}
