import { OrderDescriptor } from '../../../../Api/Order/OrderDescriptor';
import { postAny } from '../../../../Util/Api';
import { hasPayment } from '../../../../Util/Orders/hasPayment';
import { isPaid } from '../../../../Util/Orders/isPaid';

export function canClaimLoyaltyPoints(order: OrderDescriptor): boolean
{
	return order.loyaltyIntegrationId !== undefined
		&& hasPayment(order)
		&& isPaid(order);
}

export async function claimLoyaltyPoints(order: OrderDescriptor): Promise<void>
{
	const redirectUrl = await postAny(`/order/${order.uuid}/claim`);

	window.open(redirectUrl, '_blank');
}
