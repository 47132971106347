import { computeContrastTextColor } from '../../../../Service/BrandingInformation/computeContrastTextColor';
import { ButtonColor } from '../ButtonColor';
import { Color as ButlColor } from '../../../../Api/Other/Color';
import chroma from 'chroma-js';

export function defaultSecondButtonColor(buttonFirstColor: ButtonColor): ButtonColor
{
	switch (buttonFirstColor)
	{
		case 'primary':
			return 'primary-contrast-text';
		case 'secondary':
			return 'secondary-contrast-text';
		case 'primary-contrast-text':
			return 'primary';
		case 'secondary-contrast-text':
			return 'secondary';
		case 'error':
			return 'error-contrast-text';
		case 'error-contrast-text':
			return 'error';
		default:
		{
			if (buttonFirstColor instanceof ButlColor)
				return computeContrastTextColor(buttonFirstColor.chroma).css();
			else
			{
				return computeContrastTextColor(chroma(buttonFirstColor)).css();
			}
		}
	}
}