import { Box, Hidden } from '@material-ui/core';
import * as React from 'react';
import { FC } from 'react';
import { useBrandingService } from '../../../../../../../branding-service/BrandingServiceProvider';

interface MenuCarouselBackgroundFadeProps
{

}

export const MenuCarouselBackgroundFade: FC<MenuCarouselBackgroundFadeProps> =
	({
		children,
	}) =>
	{
		const brandingService = useBrandingService();
		const doShowFadeIntoBackground = brandingService.backgroundColor.a === 1;

		return <Box
			sx={{
				position: 'relative',
			}}
		>
			{children}
			{
				doShowFadeIntoBackground &&
				<Hidden
					smDown
				>
					<Box
						sx={{
							position: 'absolute',
							top: 0,
							left: -8,
							bottom: 0,
							width: 5,
							zIndex: 1,
						}}
						style={{
							background: `linear-gradient(to right, ${brandingService.backgroundColor.css}, ${brandingService.backgroundColor.withAlpha(0).css})`,
						}}
					/>
					<Box
						sx={{
							position: 'absolute',
							top: 0,
							right: -8,
							bottom: 0,
							width: 8,
							zIndex: 1,
						}}
						style={{
							background: `linear-gradient(to left, ${brandingService.backgroundColor.css}, ${brandingService.backgroundColor.withAlpha(0).css})`,
						}}
					/>
				</Hidden>
			}
		</Box>;
	};
