import { DependencyList, useCallback, useContext, useLayoutEffect, useMemo, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { ValidationBoundaryContextRef } from './ValidationBoundary';

/**
 * @param computeIfValid When this function returns undefined,
 * no validity is reported and this hook will be ignored as part
 * of the total validity of the ValidationBoundary
 * @param deps The dependency array for the function provided in
 * the first argument
 */
export function useSetValidity(
	computeIfValid: () => boolean,
	deps: DependencyList,
): boolean
export function useSetValidity(
	computeIfValid: () =>  undefined,
	deps: DependencyList,
): undefined
export function useSetValidity(
	computeIfValid: () => boolean | undefined,
	deps: DependencyList,
): boolean | undefined
{
	const componentId = useMemo(() => uuid(), []);

	const {
		setComponentValidity = () => {},
		exitComponent = () => {},
	} = useContext(ValidationBoundaryContextRef);
	const setComponentValidityRef = useRef(setComponentValidity);
	setComponentValidityRef.current = setComponentValidity;
	const exitComponentRef = useRef(exitComponent);
	exitComponentRef.current = exitComponent;

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const computeIfValidCallback = useCallback(
		computeIfValid,
		deps
	);
	useLayoutEffect(
		() => {
			setComponentValidityRef.current(
				componentId,
				computeIfValidCallback
			);
		},
		[componentId, computeIfValidCallback]
	);

	useLayoutEffect(
		() => () => exitComponentRef.current(componentId),
		[componentId]
	);

	return useMemo(
		() => computeIfValidCallback(),
		[computeIfValidCallback]
	);
}