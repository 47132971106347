import tlds from 'tlds';

export function isValidEmailAddress(arg: string): boolean
{
	if (hasErrorThatCanBeFoundCheaply(arg))
		return false;

	const matches = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*\.(\w+)$/.exec(arg);
	if (matches !== null)
	{
		const tld = matches[3];
		if (tlds.indexOf(tld) !== -1)
		{
			return true;
		}
	}
	return false;
}

/**
 * Regex matching a whole email address is expensive to run for long strings,
 * even potentially crashing the browser, so find low-hanging fruit errors with
 * this function
 */
function hasErrorThatCanBeFoundCheaply(emailString: string): boolean
{
	if (/@/.exec(emailString) === null)
		return true;
	if (/\./.exec(emailString) === null)
		return true;
	return false;
}