import { Tooltip } from '@material-ui/core';
import * as React from 'react';
import { FC } from 'react';

interface PossibleTooltipProps
{
	tooltip: string | undefined
}

export const ButtonTooltip: FC<PossibleTooltipProps> =
	(
		{
			children,
			tooltip,
		},
	) =>
	{
		return tooltip !== undefined
			?
			<Tooltip title={tooltip} arrow>
				<div>
					{children}
				</div>
			</Tooltip>
			:
			<>
				{children}
			</>;
	};