import { IReactionDisposer, IReactionOptions, IReactionPublic, reaction } from 'mobx';

/**
 * Wrapper for a mobx {@link reaction}, making sure a {@link reaction} is not instantiated
 * more than once. Should be included as a static property on client classes.
 */
export class BoxedReaction
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    private dispose: IReactionDisposer | undefined;

    // ------------------------ Constructor -------------------------

    private constructor()
    {}

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    public static create(): BoxedReaction
    {
        return new BoxedReaction();
    }

    public define<T>(
        expression: (r: IReactionPublic) => T,
        effect: (arg: T, r: IReactionPublic) => void,
        useOnce: boolean = false,
        opts?: IReactionOptions
    ): void
    {
        this.disposeOfOldReaction();
        this.dispose = reaction(
            expression,
            (arg, r) => {
                if (useOnce)
                {
                    this.clear();
                }
                effect(arg, r);
            },
            opts);
    }

    public clear(): void
    {
        this.disposeOfOldReaction();
        this.dispose = undefined;
    }

    // ----------------------- Private logic ------------------------

    private disposeOfOldReaction(): void
    {
        if (this.dispose !== undefined) {
            this.dispose();
            this.dispose = undefined;
        }
    }
}
