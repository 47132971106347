import { Client } from '../../Bridge/Client/Client';
import { AppType } from '../../Constants/AppType';
import { UnsupportedOperationException } from '../Exception/UnsupportedOperationException';

/**
 * Gets OS value set in the backend for the current value in the front end
 *
 * @param client used {@link Client}
 * @return backend OS value
 */
export function getBackendOSValue(client: Client): string
{
	if (client.type === AppType.Native)
	{
		switch (client.operatingSystem)
		{
			case 'iOS':
				return 'iOS';
			case 'Android OS':
				return 'android';
			default:
				console.warn('No suitable match for backend OS value, defaulting to \'web\'');
				return 'web';
		}
	}
	else if (client.type === AppType.Web)
	{
		return 'web';
	}
	else
	{
		throw new UnsupportedOperationException();
	}
}