import { OrderDescriptor } from '../../Api/Order/OrderDescriptor';
import { isProductOrderLine } from '../order_line/isProductOrderLine';

export function orderIsInCompleteColumn(order: OrderDescriptor): boolean
{
	return order.state === order.finalState
		||
		(
			order.finalState === 'prepared'
			&& (
				order
					.orderLines
					.some(
						line =>
							isProductOrderLine(line) &&
							line.state === 'PREPARED',
					) ?? false
			)
		)
		||
		(
			order.finalState === 'pickedUp'
			&& (
				order
					.orderLines
					.some(
						line =>
							isProductOrderLine(line) &&
							line.state === 'PICKED_UP',
					) ?? false
			)
		);
}
