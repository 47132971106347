import { useContext } from 'react';
import { useAsyncEffect } from '../../Util/async/useAsyncEffect';
import { RoutingStateContext } from './RoutingStateContext';

export function useIsRoutingByIdLive(
	routingId: number | undefined
): boolean | undefined
{
	const controller = useContext(RoutingStateContext);
	
	useAsyncEffect(
		() => ({
			promise:
				(async () =>
				{
					if (routingId !== undefined)
					{
						await controller.loadStateByRoutingId(routingId);
					}
				})(),
			then: () => {},
		}),
		[controller, routingId]
	);

	return controller.cache.get(routingId)?.isLive;
}