import { useObserver } from 'mobx-react-lite';
import { useMemo } from 'react';
import { WebClient } from '../../Bridge/Client/WebClient';
import { useRootContext } from '../../RootContext';

export function useScrollYPosition()
{
	const {
		client,
	} = useRootContext(true);
	const webClient = useMemo(() => client as WebClient, [client]);
	return useObserver(() => webClient.scrollYOffset);
}