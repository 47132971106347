import { ReducerAction } from './ReducerAction';

interface EntityWithId
{
	id: number
}

export function constructEntityWithIdRecordReducer<T extends EntityWithId>()
	: (state: Record<number, T> | undefined, reducerAction: ReducerAction<T>) => Record<number, T> | undefined
{
	return (
		state: Record<number, T> | undefined,
		reducerAction: ReducerAction<T>,
	) => {
		switch (reducerAction.action)
		{
			case 'delete':
			{
				const entitiesToDelete = reducerAction.objects ?? [reducerAction.object];

				const newState = deleteEntitiesFromRecord(state, entitiesToDelete);

				if (newState === undefined)
					return undefined;
				else
					return {...newState};
			}
			case 'replace':
			{
				return reducerAction
					.objects
					.reduce<Record<number, T>>((result, entity) => {
						result[entity.id] = entity;

						return result;
					}, {});
			}
			case 'unload':
			{
				return undefined;
			}
			case 'update':
			{
				const entitiesToUpdate = reducerAction.objects ?? [reducerAction.object];

				const newState = updateEntitiesInRecord(state, entitiesToUpdate);

				if (newState === undefined)
					return undefined;
				else
					return {...newState};
			}
		}
	};
}

function updateEntitiesInRecord<T extends EntityWithId>(record: Record<number, T> | undefined, entities: T[]): Record<number, T> | undefined
{
	if (record === undefined)
		return undefined;

	entities.forEach(entity => record[entity.id] = entity);

	return record;
}

function deleteEntitiesFromRecord<T extends EntityWithId>(record: Record<number, T> | undefined, entities: T[]): Record<number, T> | undefined
{
	if (record === undefined)
		return undefined;

	entities.forEach(entity => {

		const foundEntity = record[entity.id];

		if (foundEntity !== undefined)
			delete record[entity.id];
	});

	return record;
}