import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { OrderDescriptor } from '../../../../../../Api/Order/OrderDescriptor';
import { OrderLineDescriptor } from '../../../../../../Api/Order/OrderLineDescriptor';
import { useRoutingWaiterMode } from '../../../../../../hooks/routing-waiter-mode/useRoutingWaiterMode';
import { useWaiter } from '../../../../../../Service/UserService/useWaiter';
import { IllegalStateException } from '../../../../../../Util/Exception/IllegalStateException';
import { useQueryOrders } from '../../../../../../Util/Hooks/Orders/useQueryOrders';
import { isProductOrderLine } from '../../../../../../Util/order_line/isProductOrderLine';
import { hasOpenPayment } from '../../../../../../Util/Orders/hasOpenPayment';
import { isComplete } from '../../../../../../Util/Orders/isComplete';
import { isFree } from '../../../../../../Util/Orders/isFree';
import { orderIsInCompleteColumn } from '../../../../../../Util/Orders/isInCompleteColumn';
import { isPaid } from '../../../../../../Util/Orders/isPaid';
import { useManagerFilterRouting } from '../../../context/ManagerFilterRoutingContextProvider';
import { useManagerBusinessId } from '../../../useManagerBusinessId';
import { useOrderHandlerNotifyOfOrder } from '../notification/OrderHandlerNotificationProvider';
import { OrderListOrder } from './OrderListOrder';
import { useEventQueryForOrderLineStateMutations } from './useEventQueryForOrderLineStateMutations';
import { useOrderMutationEventQuery } from './useOrderMutationEventQuery';

interface Context
{
	scheduledOrders: OrderListOrder[] | undefined
	orderedOrders: OrderListOrder[] | undefined
	inPreparationOrders: OrderListOrder[] | undefined
	preparedOrders: OrderListOrder[] | undefined
	pickedUpOrders: OrderListOrder[] | undefined
	deliveredOrders: OrderListOrder[] | undefined
	paidCompleteOrders: OrderListOrder[] | undefined
	unpaidCompleteOrders: OrderListOrder[] | undefined
	paidVoidedOrders: OrderListOrder[] | undefined
	unpaidVoidedOrders: OrderListOrder[] | undefined
	reloadOrder: (order: OrderDescriptor) => void
}

const ContextRef = createContext<Context | undefined>(undefined);

export const OrderHandlerOrderProvider: FC =
	(
		{
			children,
		},
	) =>
	{
		const businessId = useManagerBusinessId(true);
		const waiter = useWaiter(true);
		const waiterRoutingId = useObserver(() => waiter.routingId);
		const onNewOrder = useOrderHandlerNotifyOfOrder();
		const [orders, reloadOrder, mutateOrderByUuid] = useQueryOrders(onNewOrder);
		const filteredOrders = useOrdersFilteredByFilterRouting(orders);

		const incompleteOrders = useMemo(
			() => filteredOrders
				?.filter(({order}) => order.state !== order.finalState)
				?.filter(({order, visibleLines}) =>
				{
					if (
						order?.finalState === 'prepared' &&
						visibleLines
							.every(
								line =>
									isProductOrderLine(line) &&
									line.state === 'PREPARED'
							)
					)
						return false;
					else
						return !(
							order?.finalState === 'pickedUp' &&
							visibleLines
								.every(
									line =>
										isProductOrderLine(line) &&
										line.state === 'PICKED_UP'
								)
						);
				}),
			[filteredOrders],
		);

		const orderedOrders = useMemo(
			() => incompleteOrders
				?.filter(({order}) => order.state === 'ordered')
				.map(({order, visibleLines}) => ({
					order,
					linesSplitOverMoreLists: false,
					visibleLinesInThisList: visibleLines,
				} as OrderListOrder))
				.filter(({visibleLinesInThisList}) => shouldShowOrderWithVisibleLines(visibleLinesInThisList, waiterRoutingId)),
			[incompleteOrders, waiterRoutingId],
		);

		const orderHandlerSupportsOrderStateScheduled = useObserver(() => waiter.orderHandlerSupportsOrderStateScheduled);
		const orderHandlerSupportsOrderStatePrepared = useObserver(() => waiter.orderHandlerSupportsOrderStatePrepared);
		const orderHandlerSupportsOrderStatePickedUp = useObserver(() => waiter.orderHandlerSupportsOrderStatePickedUp);
		const orderHandlerSupportsOrderStateDelivered = useObserver(() => waiter.orderHandlerSupportsOrderStateDelivered);

		const inPreparationOrders = useMemo(
			() => incompleteOrders
				?.filter(({order}) => order.state === 'handled')
				.map(({order, visibleLines}) => ({
					order,
					visibleLines,
					visibleLinesInThisList: visibleLines.filter(
						line =>
							isProductOrderLine(line) &&
							line.state === 'ORDERED',
					),
				}))
				.filter(({visibleLinesInThisList}) => shouldShowOrderWithVisibleLines(visibleLinesInThisList, waiterRoutingId))
				.map(({order, visibleLines, visibleLinesInThisList}) => ({
					order,
					linesSplitOverMoreLists: visibleLines.length > visibleLinesInThisList.length,
					visibleLinesInThisList,
				} as OrderListOrder)),
			[incompleteOrders, waiterRoutingId],
		);

		const scheduledOrders = useMemo(
			() =>
				orderHandlerSupportsOrderStateScheduled
					?
					incompleteOrders
						?.filter(({order}) => order.state === 'scheduled')
						.map(({order, visibleLines}) => ({
							order,
							linesSplitOverMoreLists: false,
							visibleLinesInThisList: visibleLines,
						} as OrderListOrder))
						.filter(({visibleLinesInThisList}) => shouldShowOrderWithVisibleLines(visibleLinesInThisList, waiterRoutingId))
					: undefined,
			[incompleteOrders, orderHandlerSupportsOrderStateScheduled, waiterRoutingId],
		);

		const preparedOrders = useMemo(
			() =>
				orderHandlerSupportsOrderStatePrepared
					?
					incompleteOrders
						?.filter(({order}) => order.state === 'prepared' || order.state === 'handled' || order.state === 'ordered')
						?.map(({order, visibleLines}) =>
						{
							if (waiterRoutingId === undefined)
								return {
									order,
									visibleLines,
									visibleLinesInThisList: order.finalState === 'prepared'
										? []
										: visibleLines.filter(
											line =>
												isProductOrderLine(line) &&
												line.state === 'PREPARED',
										),
								};
							else
								return {
									order,
									visibleLines,
									visibleLinesInThisList: visibleLines.filter(
										line =>
											isProductOrderLine(line) &&
											line.state === 'PREPARED',
									),
								};
						})
						.filter(({visibleLinesInThisList}) => shouldShowOrderWithVisibleLines(visibleLinesInThisList, waiterRoutingId))
						.map(({order, visibleLines, visibleLinesInThisList}) => ({
							order,
							linesSplitOverMoreLists: visibleLines.length > visibleLinesInThisList.length,
							visibleLinesInThisList,
						} as OrderListOrder))
					:
					undefined,
			[incompleteOrders, orderHandlerSupportsOrderStatePrepared, waiterRoutingId],
		);

		const pickedUpOrders = useMemo(
			() => orderHandlerSupportsOrderStatePickedUp
				?
				incompleteOrders
					?.filter(({order}) => order.state === 'pickedUp' || order.state === 'prepared' || order.state === 'handled' || order.state === 'ordered')
					.map(({order, visibleLines}) =>
					{
						if (waiterRoutingId === undefined)
							return {
								order,
								visibleLines,
								visibleLinesInThisList: order.finalState === 'pickedUp'
									? []
									: visibleLines.filter(
										line =>
											isProductOrderLine(line) &&
											line.state === 'PICKED_UP',
									),
							};
						else
							return {
								order,
								visibleLines,
								visibleLinesInThisList: visibleLines.filter(
									line =>
										isProductOrderLine(line) &&
										line.state === 'PICKED_UP',
								),
							};
					})
					.filter(({visibleLinesInThisList}) => shouldShowOrderWithVisibleLines(visibleLinesInThisList, waiterRoutingId))
					.map(({order, visibleLines, visibleLinesInThisList}) => ({
						order,
						linesSplitOverMoreLists: visibleLines.length > visibleLinesInThisList.length,
						visibleLinesInThisList,
					} as OrderListOrder))
				:
				undefined,
			[incompleteOrders, orderHandlerSupportsOrderStatePickedUp, waiterRoutingId],
		);

		const deliveredOrders = useMemo(
			() => orderHandlerSupportsOrderStateDelivered
				?
				incompleteOrders
					?.filter(({order}) => order.state === 'delivered')
					.map(({order, visibleLines}) => ({
						order,
						visibleLinesInThisList: visibleLines,
					} as OrderListOrder))
					.filter(({visibleLinesInThisList}) => !waiterRoutingId || visibleLinesInThisList.length > 0)
				:
				undefined,
			[incompleteOrders, orderHandlerSupportsOrderStateDelivered, waiterRoutingId],
		);

		const ordersInCompleteColumn = useMemo(
			() => filteredOrders
				?.filter(({order}) => orderIsInCompleteColumn(order))
				.map(({order, visibleLines}) => ({
					order,
					visibleLines,
					visibleLinesInThisList: isComplete(order)
						? visibleLines
						: visibleLines.filter(
							line =>
								isProductOrderLine(line) &&
								(
									(order.finalState === 'prepared' && line.state === 'PREPARED')
									||
									(order.finalState === 'pickedUp' && line.state === 'PICKED_UP')
								),
						),
				})),
			[filteredOrders],
		);

		const paidCompleteOrders = useMemo(
			() => ordersInCompleteColumn
				?.filter(({order}) => isFree(order) || isPaid(order))
				?.filter(({order, visibleLines}) =>
				{
					if(waiterRoutingId === undefined)
						return true;
					else if (
						order?.finalState === 'prepared' &&
						visibleLines.every(
							line =>
								isProductOrderLine(line) &&
								line.state === 'PREPARED',
						)
					)
						return true;
					else
						return order?.finalState === 'pickedUp'
							&& visibleLines.every(
								line =>
									isProductOrderLine(line) &&
									line.state === 'PICKED_UP',
							);
				})
				.map(({order, visibleLines, visibleLinesInThisList}) => ({
					order,
					linesSplitOverMoreLists: visibleLines.length > visibleLinesInThisList.length,
					visibleLinesInThisList,
				} as OrderListOrder))
				.filter(({visibleLinesInThisList}) => shouldShowOrderWithVisibleLines(visibleLinesInThisList, waiterRoutingId)),
			[ordersInCompleteColumn, waiterRoutingId],
		);
		const unpaidCompleteOrders = useMemo(
			() => ordersInCompleteColumn
				?.filter(({order}) => hasOpenPayment(order))
				?.filter(({order, visibleLines}) =>
				{
					if(waiterRoutingId === undefined)
						return true;
					else if (
						order?.finalState === 'prepared' &&
						visibleLines.every(
							line =>
								isProductOrderLine(line) &&
								line.state === 'PREPARED',
						)
					)
						return true;
					else
						return order?.finalState === 'pickedUp'
							&& visibleLines.every(
								line =>
									isProductOrderLine(line) &&
									line.state === 'PICKED_UP',
							);
				})
				.map(({order, visibleLinesInThisList, visibleLines}) => ({
					order,
					linesSplitOverMoreLists: visibleLines.length > visibleLinesInThisList.length,
					visibleLinesInThisList,
				} as OrderListOrder))
				.filter(({visibleLinesInThisList}) => shouldShowOrderWithVisibleLines(visibleLinesInThisList, waiterRoutingId)),
			[ordersInCompleteColumn, waiterRoutingId],
		);

		const voidedOrders = useMemo(
			() => filteredOrders
				?.filter(({order}) => order.state === 'voided')
				.map(({order, visibleLines}) => ({
					order,
					visibleLines,
				})),
			[filteredOrders],
		);
		const paidVoidedOrders = useMemo(
			() => voidedOrders
				?.filter(({order}) => isFree(order) || isPaid(order))
				.map(({order, visibleLines}) => ({
					order,
					visibleLinesInThisList: visibleLines,
				} as OrderListOrder))
				.filter(({visibleLinesInThisList}) => shouldShowOrderWithVisibleLines(visibleLinesInThisList, waiterRoutingId)),
			[waiterRoutingId, voidedOrders],
		);
		const unpaidVoidedOrders = useMemo(
			() => voidedOrders
				?.filter(({order}) => hasOpenPayment(order))
				.map(({order, visibleLines}) => ({
					order,
					visibleLinesInThisList: visibleLines,
				} as OrderListOrder))
				.filter(({visibleLinesInThisList}) => shouldShowOrderWithVisibleLines(visibleLinesInThisList, waiterRoutingId)),
			[waiterRoutingId, voidedOrders],
		);
		useOrderMutationEventQuery(businessId, reloadOrder);
		useEventQueryForOrderLineStateMutations(businessId, mutateOrderByUuid);

		return <ContextRef.Provider
			value={useMemo(() => ({
				scheduledOrders,
				orderedOrders,
				inPreparationOrders,
				preparedOrders,
				pickedUpOrders,
				deliveredOrders,
				paidCompleteOrders,
				unpaidCompleteOrders,
				paidVoidedOrders,
				unpaidVoidedOrders,
				reloadOrder,
			}), [deliveredOrders, inPreparationOrders, orderedOrders, paidCompleteOrders, paidVoidedOrders, pickedUpOrders, preparedOrders, reloadOrder, scheduledOrders, unpaidCompleteOrders, unpaidVoidedOrders])}
		>
			{children}
		</ContextRef.Provider>;
	};

/**
 * @todo filter orders in API
 */
function useOrdersFilteredByFilterRouting(
	waiterOrders: OrderDescriptor[] | undefined,
): { order: OrderDescriptor, visibleLines: OrderLineDescriptor[] }[] | undefined
function useOrdersFilteredByFilterRouting(
	waiterOrders: OrderDescriptor[] | undefined,
): { order: OrderDescriptor, visibleLines: OrderLineDescriptor[] }[] | undefined
{
	const [externalIdOfFilterRouting] = useManagerFilterRouting();
	const orders = useMemo(
		() =>
		{
			if (waiterOrders !== undefined)
			{
				return externalIdOfFilterRouting === undefined
					? waiterOrders
					: waiterOrders
						.filter(order => order.orderLines !== undefined)
						.filter(
							order =>
							{
								const numberOfRoutings = order.orderLines
									?.filter(isProductOrderLine)
									.filter(orderLine => orderLine.routingId !== undefined)
									.filter(orderLine => orderLine.externalId !== undefined && orderLine.externalId === externalIdOfFilterRouting)
									.length;

								return numberOfRoutings !== undefined && numberOfRoutings > 0;
							},
						);
			}
		},
		[waiterOrders, externalIdOfFilterRouting],
	);

	const waiter = useWaiter(true);
	const routingWaiterMode = useRoutingWaiterMode(true);
	const waiterRoutingId = useObserver(() => waiter.routingId);
	const visibleLines = useCallback(
		(order: OrderDescriptor) => order
			.orderLines
			?.filter(({routingId}) => !routingWaiterMode || waiterRoutingId === routingId) ?? [],
		[routingWaiterMode, waiterRoutingId],
	);

	return useMemo(
		() => orders
			?.map(order => ({
				order,
				visibleLines: visibleLines(order),
			})),
		[orders, visibleLines],
	);
}

function useAndRequireContext()
{
	const context = useContext(ContextRef);
	return useMemo(
		() =>
		{
			if (context === undefined)
				throw new IllegalStateException(
					'Attempting to use orders outside OrderHandlerOrderProvider',
				);
			return context;
		},
		[context],
	);
}

function shouldShowOrderWithVisibleLines(visibleLines: OrderLineDescriptor[], waiterRoutingId?: number): boolean
{
	if (waiterRoutingId === undefined)
	{
		return visibleLines.length > 0;
	}
	else
	{
		return visibleLines
			.filter(line => line.routingId === waiterRoutingId)
			.length > 0;
	}
}

export function useScheduledOrders()
{
	return useAndRequireContext().scheduledOrders;
}

export function useOrderedOrders()
{
	return useAndRequireContext().orderedOrders;
}

export function useInPreparationOrders()
{
	return useAndRequireContext().inPreparationOrders;
}

export function usePreparedOrders()
{
	return useAndRequireContext().preparedOrders;
}

export function usePickedUpOrders()
{
	return useAndRequireContext().pickedUpOrders;
}

export function useDeliveredOrders()
{
	return useAndRequireContext().deliveredOrders;
}

export function usePaidCompleteOrders()
{
	return useAndRequireContext().paidCompleteOrders;
}

export function useUnpaidCompleteOrders()
{
	return useAndRequireContext().unpaidCompleteOrders;
}

export function usePaidVoidedOrders()
{
	return useAndRequireContext().paidVoidedOrders;
}

export function useUnpaidVoidedOrders()
{
	return useAndRequireContext().unpaidVoidedOrders;
}

export function useOrderHandlerReloadOrder(): (order: OrderDescriptor) => void
{
	return useAndRequireContext().reloadOrder;
}
