// @ts-nocheck
/* eslint-disable */
import { Divider, Drawer, Fade, List, ListItem, ListItemIcon, ListItemText, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import Close from '@material-ui/icons/Close';
import Fastfood from '@material-ui/icons/Fastfood';
import Receipt from '@material-ui/icons/Receipt';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import CellphoneCog from 'mdi-material-ui/CellphoneCog';
import LinkVariant from 'mdi-material-ui/LinkVariant';
import MapMarker from 'mdi-material-ui/MapMarker';
import QrcodeScan from 'mdi-material-ui/QrcodeScan';
import * as React from 'react';
import { ComponentType, createElement, FC, MouseEvent, useCallback } from 'react';
import { UIColors } from '../../../Constants/UIColors';
import { useIsPageAccessibleCallback } from './Hooks/useIsPageAccessibleCallback';
import { ManagerLargeSidebarWidth, ManagerSmallSidebarWidth, ManagerToolbarHeight } from './ManagerStore';
import { ManagerView } from './ManagerView';

const useStyles = makeStyles(
	(theme: Theme) =>
		({
			drawerPaper:
				{
					backgroundColor: UIColors.greyLight,
					flexDirection: 'column',
					justifyContent: 'space-between',
					overflowX: 'hidden',
					paddingTop: ManagerToolbarHeight,
					transition: theme.transitions.create(
						'width',
						{
							easing: theme.transitions.easing.sharp,
							duration: theme.transitions.duration.enteringScreen,
						}),
					whiteSpace: 'nowrap',
					width: ManagerSmallSidebarWidth,
				},
			drawerPaperExpanded:
				{
					[theme.breakpoints.up('sm')]:
						{
							width: ManagerLargeSidebarWidth,
						},
					[theme.breakpoints.down('xs')]:
						{
							width: ManagerSmallSidebarWidth,
						},
				},
			icon:
				{
					marginRight: theme.spacing(2),
					minWidth: 0,
				},
			item:
				{
					height: 48,
					paddingLeft: Math.min(ManagerSmallSidebarWidth / 2 - 12, theme.spacing(2)),
				},
			itemText:
				{
				},
		}));

interface ManagerSidebarProps
{
	isExpanded: boolean;
	onClose: () => void
	navigateTo: (view: ManagerView) => void;
}

export const ManagerSidebar: FC<ManagerSidebarProps> =
	({
		 isExpanded,
		 onClose,
		 navigateTo,
	 }) =>
	{
		const classes = useStyles();
		const renderListItem = useCallback(
			(icon: ComponentType, text: string, onClick: (event: MouseEvent<HTMLDivElement>) => void) =>
			{
				return <ListItem
					button
					classes={{
						root: classes.item,
					}}
					dense
					onClick={onClick}
					id={`manager-sidebar-${text.toLowerCase().replace(' ', '-')}`}
				>
					<ListItemIcon
						classes={{
							root: classes.icon,
						}}
					>
						{createElement(icon)}
					</ListItemIcon>
					<Fade
						in={isExpanded}
						unmountOnExit
					>
						<ListItemText
							classes={{
								root: classes.itemText,
							}}
							primary={text}
						/>
					</Fade>
				</ListItem>;
			},
			[classes, isExpanded]
		);
		const isPageAccessible = useIsPageAccessibleCallback();

		return <Drawer
			classes={{
				paper: `${classes.drawerPaper} ${isExpanded ? ` ${classes.drawerPaperExpanded}` : ''}`,
			}}
			variant="permanent"
		>
			<List
				disablePadding
				id="manager-sidebar-list-upper"
			>
				{isPageAccessible('places')               && renderListItem(MapMarker, 'Places', () => navigateTo('places'))}
				{isPageAccessible('order-handler')        && renderListItem(Fastfood, 'Order handler', () => navigateTo('order-handler'))}
				{isPageAccessible('reports')              && renderListItem(Receipt, 'Reports', () => window.open(`${process.env.REACT_APP_DASHBOARD_ROOT_URL}/reports`))}
				{isPageAccessible('menu-editor')          && renderListItem(RestaurantMenuIcon, 'Menu editor', () => window.open(`${process.env.REACT_APP_DASHBOARD_ROOT_URL}/configuration/menuCards`))}
				{isPageAccessible('device-settings')      && renderListItem(CellphoneCog, 'Device Settings', () => navigateTo('device-settings'))}
				{isPageAccessible('code-info')            && renderListItem(QrcodeScan, 'Code Info', () => navigateTo('code-info'))}
				{isPageAccessible('routings')             && renderListItem(LinkVariant, 'Routings', () => window.open(`${process.env.REACT_APP_DASHBOARD_ROOT_URL}/configuration/routings`))}
				{isPageAccessible('payment-recipients')   && renderListItem(AccountBalanceWalletIcon, 'Recipients', () => window.open(`${process.env.REACT_APP_DASHBOARD_ROOT_URL}/configuration/payments/ButlarooPay`))}
			</List>
			<List
				disablePadding
				id="manager-sidebar-list-lower"
			>
				<Divider />
				{renderListItem(Close, 'Log out', onClose)}
			</List>
		</Drawer>;
	};
