import * as React from 'react';

interface VerticalDividerProps
{
    className?: string;
    color?: string;
    gradient?: boolean;
    horizontalMargin?: number | string;
    style?: React.CSSProperties;
    width?: number | string;
}

export const VerticalDivider: React.FC<VerticalDividerProps> =
    ({
         className,
         color,
         gradient,
         style,
         horizontalMargin,
         width,
     }) =>
    {
        return <div
            className={className}
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                marginLeft: horizontalMargin,
                marginRight: horizontalMargin,
                ...style,
            }}
        >
            <hr
                style={{
                    backgroundImage: `linear-gradient(0deg, ${color}, ${gradient ? 'transparent' : `${color}`})`,
                    borderWidth: 0,
                    flexGrow: 1,
                    marginBottom: 0,
                    width: width,
                }}
            />
            <hr
                style={{
                    backgroundImage: `linear-gradient(180deg, ${color}, ${gradient ? 'transparent' : `${color}`})`,
                    borderWidth: 0,
                    flexGrow: 1,
                    marginTop: 0,
                    width: width,
                }}
            />
        </div>;
    };

VerticalDivider.defaultProps =
    {
        color: 'black',
        gradient: true,
        horizontalMargin: 20,
        width: 1,
    };
