// @ts-nocheck
/* eslint-disable */
import FormHelperText from '@material-ui/core/FormHelperText';
import MuiPhoneNumber from 'material-ui-phone-number';
import * as React from 'react';
import { FocusEventHandler } from 'react';
import { PropTypes, WithTheme, withTheme } from '@material-ui/core';
import { AsYouTypeFormatter } from 'google-libphonenumber';

interface PhoneNumberInputProps
{
    defaultCountry?: string;
    error?: boolean;
    helperText?: string;
    fullWidth?: boolean;
    onBlur?: FocusEventHandler<HTMLElement>;
    placeHolder?: string;
    margin?: PropTypes.Margin;
    style?: React.CSSProperties;
    rootStyle?: React.CSSProperties;
    label?: string;
    onKeyPress?: (ev: React.KeyboardEvent) => void;
    value?: string;
    inputRef?: React.RefObject<any>;
    onChange?: (value: string) => void;
}

export const PhoneNumberInput = withTheme((props: PhoneNumberInputProps & WithTheme) => {
    const {
        defaultCountry = 'nl',
        error,
        value,
        onChange = (value: string) => {},
        helperText,
        fullWidth = true,
        onKeyPress = () => {},
        onBlur = () => {},
        placeHolder,
        margin = "dense",
        label,
        inputRef,
        style,
        rootStyle,
        theme
    } = props;

    const onInputChangeFunction = (value: string | undefined, countryObject: {countryCode: string, dialCode: string, name: string}) => {
        if (value !== undefined && countryObject !== undefined && value.trim() !== '')
            onChange(value);
    };
    //
    // const [onInputChange] = useState(() => onInputChangeFunction);

    // phoneNumberUtil.and

    const muiPhoneNumber = <MuiPhoneNumber
        defaultCountry={defaultCountry.toLowerCase()}
        onChange={onInputChangeFunction}
        error={error}
        variant="outlined"
        autoFormat={false}
        fullWidth={fullWidth}
        onKeyPress={onKeyPress}
        onBlur={e => onBlur(e)}
        disableAreaCodes
        style={style}
        label={label}
        margin={margin}
        value={value}
        inputProps={(() => {
            const props = {};
            if (value !== undefined)
            {
                props['value'] = value;
            }
            if (placeHolder !== undefined)
            {
                props['placeholder'] = placeHolder;
            }
            return props;
        })()}
    />;

    return <div
        style={rootStyle}
    >
        {muiPhoneNumber}
        {
            helperText === undefined ? <></> :
            <FormHelperText
                style={{
                    color: error ? theme.palette.error.main : undefined,
                }}
            >
                {helperText}
            </FormHelperText>
        }
    </div>
});
