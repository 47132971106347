import { ButtonBase, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { Product } from '../../../../../../Api/Product/Product';
import { ProductCategory } from '../../../../../../Api/Product/ProductCategory';
import { useTranslate } from '../../../../../../Bridge/Localization/useTranslate';
import { useIsKioskMode } from '../../../../../../Service/KioskService/Api/useIsKioskMode';
import { useCurrentMinute } from '../../../../../../Util/clock/useCurrentMinute';
import { BusinessContextRef } from '../../../BusinessContext';
import { ProductDrawer } from '../../../Product/ProductDrawer/ProductDrawer';
import { ProductStore } from '../../../Product/ProductStore';
import { shouldShowOnMenu } from '../../Api/shouldShowOnMenu';
import { MenuStore } from '../../MenuStore';
import { MenuCarouselTrack } from './Track/MenuCarouselTrack';

const useStyles = makeStyles(() => ({
	grid: {
		flex: '1 0 auto !important',
		display: 'flex',
		flexDirection: 'column',
	},
	mixedGrid: {
		flex: '1 0 auto',
	},
	categoryTitleGroup: {
		display: 'flex',
		alignItems: 'baseline',
		cursor: 'pointer',
		marginBottom: 5,
		padding: '0 10px',
	},
	categoryTitle: {
		flex: 1,
		fontSize: '1.3rem',
	},
	showAllButton: {
		marginRight: -5,
		cursor: 'pointer',
	},
	showAll: {
		fontSize: '1rem',
		padding: '5px 5px',
	},
}));

interface MenuCategoryCarouselProps
{
	nrOfColumns: number;
	store: MenuStore;
	categoryStore: MenuStore;
	excludeNestedCategories?: boolean;
	excludeHeader?: boolean;
	previewSwipe?: boolean;
}

export const MenuCarouselCategory: FC<MenuCategoryCarouselProps> =
	(
		{
			nrOfColumns,
			store,
			categoryStore,
			excludeNestedCategories,
			excludeHeader,
			previewSwipe,
		},
	) =>
	{
		const classes = useStyles();
		const {currentPlaceService} = useContext(BusinessContextRef);
		const translate = useTranslate();
		const now = useCurrentMinute();
		const ignoreTimeSchedules = useObserver(() => currentPlaceService.place?.timeSchedulesIgnored ?? false);
		const isKioskMode = useIsKioskMode();

		const products =
			useObserver(
				() => excludeNestedCategories
					? categoryStore.category.products
					: getAllProducts(categoryStore.category),
			);
		const productsToPreview =
			useMemo(
				() =>
				{
					if (ignoreTimeSchedules)
					{
						return products;
					}
					else
					{
						return products.filter(
							product =>
								shouldShowOnMenu(product, now),
						);
					}
				},
				[ignoreTimeSchedules, now, products],
			);
		const openMenu =
			useCallback(
				() =>
					store.openMenu(categoryStore),
				[store, categoryStore],
			);
		const [openedProductStore, setOpenedProductStore] = useState<ProductStore | undefined>(undefined);
		const openProduct =
			useCallback(
				(product: Product) =>
				{
					if (isKioskMode)
					{
						return store.openProduct(product, false)
							.then(setOpenedProductStore);
					}
					else
					{
						return store.openProduct(product);
					}
				},
				[
					isKioskMode,
					store,
				],
			);

		return <div
			key={categoryStore.category.uuid}
		>
			{
				!excludeHeader &&
				<div
					className={classes.categoryTitleGroup}
					style={{
						color: categoryStore.brandingService.backgroundContrastTextColor.css,
					}}
				>
					<Typography
						variant="h6"
						className={classes.categoryTitle}
						onClick={openMenu}
					>
						{categoryStore.category.name}
					</Typography>
					<ButtonBase
						className={classes.showAllButton}
						onClick={openMenu}
					>
						<Typography
							variant="h1"
							className={classes.showAll}
						>
							{translate('Generic-ShowAll')}
						</Typography>
					</ButtonBase>
				</div>
			}
			<MenuCarouselTrack
				highlighted={categoryStore.category.menuEntryIsHighlighted}
				nrOfColumns={nrOfColumns}
				products={productsToPreview}
				onOpen={openProduct}
				tintColor={store.brandingService.tintColor}
				previewSwipe={previewSwipe}
			/>
			{
				openedProductStore &&
				<ProductDrawer
					productStore={openedProductStore}
					onClose={
						() =>
						{
							setOpenedProductStore(undefined);
						}
					}
				/>
			}
		</div>;
	};

function getAllProducts(category: ProductCategory)
{
	return [
		...category.products,
		...category.categories
			.map(
				childCategory =>
					getAllProducts(childCategory))
			.reduce((a, b) => a.concat(b), []),
	];
}
