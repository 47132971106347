const sanitize = require('sanitize-html');

const modifiers = {
	allowedTags: sanitize.defaults.allowedTags.concat([ 'img', 'iframe' ]),
	allowedAttributes: {
		...sanitize.defaults.allowedAttributes,
		'*': [ 'style', 'width', 'height' ],
		'iframe': [ '*' ]
	}
};

export default function sanitizeHtml(html: string)
{
	return sanitize(html, modifiers);
}
