export class Currency
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	readonly code: string;
	readonly decimalPlaces: number;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor(code: string)
	{
		const options = new Intl
			.NumberFormat(
				'nl-NL',
				{
					currency: code,
					style: 'currency',
				},
			)
			.resolvedOptions();

		this.code = code;
		this.decimalPlaces = options.maximumFractionDigits;
	}
}
