import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useContext, useMemo } from 'react';
import { Order } from '../../../../../Api/Order/Order';
import { PaymentIssuer } from '../../../../../Api/Payment/PaymentIssuer';
import { PaymentMethodDescriptor } from '../../../../../Api/Payment/PaymentMethodDescriptor';
import { useLocalizer } from '../../../../../Bridge/Localization/useLocalizer';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { AdyenCheckoutData } from '../../../../../lib/adyen/AdyenCheckoutData';
import { ActionButton } from '../../../../UI/action-button/ActionButton';
import { ValidationBoundaryContextRef } from '../../../../UI/Form/Core/ValidationBoundary';
import { useAdyenCheckoutContext } from '../../../../UI/payment/adyen/checkout/AdyenCheckoutContext';
import { usePaymentIssuerContext } from '../../../../UI/payment/issuer/context/PaymentIssuerContext';
import { usePaymentMethodContext } from '../../../../UI/payment/method/context/PaymentMethodContext';
import { usePaymentPriceContext } from '../../../../UI/payment/price/context/PaymentPriceContext';
import { useProcessingOrderContext } from '../../../../UI/processing_order_context/ProcessingOrderContext';
import { useValidationContext } from '../../../../UI/validation/context/ValidationContext';
import { BusinessContextRef } from '../../BusinessContext';
import { useExternalOrdersContext } from './context/external-orders/ExternalOrdersContext';

const useStyles = makeStyles(theme => ({
	payButtonRootError: {
		backgroundColor: theme.palette.error.main,
		'&:hover': {
			backgroundColor: theme.palette.error.dark,
		},
	},
}));

interface BillSettlerOrderButtonProps
{
	hasError: boolean;
	loading?: boolean;
	onOrder: (
		paymentMethod?: PaymentMethodDescriptor,
		paymentIssuer?: PaymentIssuer,
		adyenCheckoutData?: AdyenCheckoutData,
	) => Promise<Order | undefined>;
	paymentIssuer?: PaymentIssuer;
	paymentMethod?: PaymentMethodDescriptor;
}

export const BillSettlerOrderButton: FC<BillSettlerOrderButtonProps> =
	({
		hasError,
		loading,
		onOrder,
		paymentIssuer,
		paymentMethod,
	}) =>
	{
		const classes = useStyles();

		const {businessStore} = useContext(BusinessContextRef);
		const showValidation = useValidationContext();
		const {currency, total} = usePaymentPriceContext();
		const {activeComponent, data: adyenCheckoutData} = useAdyenCheckoutContext();
		const {selectedPaymentMethod} = usePaymentMethodContext();
		const {selectedPaymentIssuer} = usePaymentIssuerContext();
		const localizer = useLocalizer();
		const translate = useTranslate();
		const externalOrders = useExternalOrdersContext();
		const {isValid} = useContext(ValidationBoundaryContextRef);
		const {setProcessingOrder} = useProcessingOrderContext();

		const initiatePayment = useObserver(() => businessStore.initiatePayment)
		const openHistory = useObserver(() => businessStore.openHistory)
		
		const priceLabel = useMemo(() =>
		{
			if (
				externalOrders === 'error'
				|| externalOrders === 'timeout'
				|| externalOrders === undefined
			)
				return undefined;
			else
				return localizer.formatCurrency(total, currency.code) ?? undefined;
		}, [currency.code, externalOrders, localizer, total]);

		const isClickable = useMemo(() => {
			if (total.lessThanOrEqualTo(0) || hasError)
				return false;

			if (paymentMethod !== undefined)
				return paymentMethod.isIssuerRequired
					? paymentIssuer !== undefined
					: true;

			return false;
		}, [hasError, paymentIssuer, paymentMethod, total]);

		const rootClassName = clsx({
			[classes.payButtonRootError]: isValid === false && showValidation,
		});

		const handleClick = useCallback(async () =>
		{
			const data = adyenCheckoutData ?? activeComponent?.data;

			const order = await onOrder(
				selectedPaymentMethod,
				selectedPaymentIssuer,
				data
			);

			setProcessingOrder(order);
			
			if (order !== undefined)
			{
				try
				{
					await initiatePayment?.(order, activeComponent);
				}
				catch (error)
				{
					console.error(error);

					alert(translate('Order-Payment-Failed'));
				}
				
				setProcessingOrder(undefined);

				await openHistory();
			}
		}, [activeComponent, adyenCheckoutData, initiatePayment, onOrder, openHistory, selectedPaymentIssuer, selectedPaymentMethod, setProcessingOrder, translate]);

		return useMemo(() =>
		{
			return <ActionButton
				classes={{
					root: rootClassName,
				}}
				disabled={!isClickable}
				loading={loading}
				onClick={handleClick}
				text={
					priceLabel === undefined
						? translate('Client-OrderHistory-Order-Pay')
						: translate('Generic-Pay-Value', priceLabel)
				}
			/>;
		}, [handleClick, isClickable, loading, priceLabel, rootClassName, translate]);
	};