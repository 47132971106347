import { Card, CardContent, Grid, makeStyles, Typography } from '@material-ui/core';
import chroma from 'chroma-js';
import clsx from 'clsx';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, ReactNode, useMemo } from 'react';
import { MenuStore } from '../MenuStore';

const useStyles = makeStyles(theme => ({
	clickableCard: {
		cursor: 'pointer',

		transition: theme.transitions.create('background-color', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.standard,
		}),

		'& *': {
			cursor: 'pointer',
		},

		'&:hover': {
			backgroundColor: chroma('rgba(255,255,255,1)').darken(0.5).css(),
		},
	},
	link: {
		textDecoration: 'none',
	},
}));

interface MenuBusinessInformationProps
{
	store: MenuStore;
}

export const MenuBusinessInformation: FC<MenuBusinessInformationProps> =
	(
		{
			store,
		},
	) =>
	{
		const classes = useStyles();

		const businessInformationTitle = useObserver(() => store.business.informationTitle);
		const businessInformation = useObserver(() => store.business.information);
		const businessInformationUrl = useObserver(() => store.business.informationUrl);

		const businessInformationElements = useMemo(() =>
		{
			if (businessInformation !== undefined && businessInformation.trim().length > 0)
				return businessInformation
					.split('\n')
					.reduce(
						(array, line, idx) =>
						{
							if (idx === 0)
								return [line];

							return array.concat(<br key={idx} />, line);
						},
						[] as ReactNode[],
					);
			else
				return [];
		}, [businessInformation]);

		const informationTitleComponent = useMemo(() =>
		{
			if (businessInformationTitle !== undefined && businessInformationTitle.trim().length > 0)
				return <Typography variant="h6">
					{businessInformationTitle}
				</Typography>;
		}, [businessInformationTitle]);

		const informationComponent = useMemo(() =>
		{
			if (businessInformationElements.length > 0)
				return <Typography variant="body1">
					{businessInformationElements}
				</Typography>;
		}, [businessInformationElements]);

		const showComponent = useMemo(
			() => informationTitleComponent !== undefined || informationComponent !== undefined,
			[informationComponent, informationTitleComponent],
		);

		const card = useMemo(() =>
		{
			const className = clsx({
				[classes.clickableCard]: businessInformationUrl !== undefined,
			});

			return <Card className={className}>
				<CardContent>
					{informationTitleComponent}
					{informationComponent}
				</CardContent>
			</Card>;
		}, [classes.clickableCard, informationTitleComponent, informationComponent, businessInformationUrl]);

		if (showComponent)
			return <Grid
				item
				xs={12}
			>
				{
					businessInformationUrl === undefined
						? card
						: <a className={classes.link} href={businessInformationUrl} target="_blank" rel="noopener noreferrer">
							{card}
						</a>
				}
			</Grid>;
		else
			return null;
	};
