import { PaymentMethods } from '@adyen/adyen-web/dist/types/types';

const PaymentMethodAliases: Record<string, keyof PaymentMethods> = {
	'Bancontact': 'bcmc_mobile',
};

export function getAdyenCheckoutPaymentMethodAlias(name: string): keyof PaymentMethods | undefined
{
	return PaymentMethodAliases[name];
}
