import { IconButton, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import clsx from 'clsx';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useMemo, useState } from 'react';
import { A11y, FreeMode, Navigation, Pagination, Scrollbar, Virtual } from 'swiper';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { Swiper as SwiperClass } from 'swiper/types';
import { Color } from '../../../Api/Other/Color';
import { useWebClient } from '../../../Bridge/Client/WebClientProvider';
import { useIsLargeScreen } from '../../../Util/Hooks/useIsLargeScreen';
import { useIsSmallScreen } from '../../../Util/Hooks/useIsSmallScreen';
import { SwiperControlItem } from './Item/SwiperControlItem';
import { SwiperControlDataItem } from './Model/SwiperControlDataItem';

const useStyles = makeStyles<Theme, { numberOfItems: number }>(() => ({
	root: {
		'@global': {
			'.swiper-button-next, .swiper-button-prev': {
				color: 'white',
				// in production builds the swiper-icons font family is not loaded,
				// so for now we hide the icons
				display: 'none'
			}
		},
		marginLeft: -8,
		marginRight: -8,
		position: 'relative',
	},
	swiperButtonContainer: {
		position: 'absolute',
		top: 'calc(50% - 23px)',
		zIndex: 1,
	},
	swiperButtonLeft: {
		left: 6,
	},
	swiperButtonRight: {
		right: 8,
	},
	swiperButton: {
		textShadow:
			'rgba(0, 0, 0, 0.1) 0px 0px 6px, ' +
			'rgba(0, 0, 0, 0.1) 0px 0px 6px, ' +
			'rgba(0, 0, 0, 0.1) 0px 0px 6px',
	},
}));

interface SwiperControlProps
{
	numberOfColumns: number;
	items: SwiperControlDataItem<any>[];
	onClick: (value: any) => void;
	tintColor: Color;
	overflow?: boolean;
	previewSwipe?: boolean;
	centerInsufficientSlides?: boolean;
}

export const SwiperControl: FC<SwiperControlProps> =
	(
		{
			numberOfColumns,
			items,
			onClick,
			tintColor,
			overflow,
			previewSwipe,
			centerInsufficientSlides,
		},
	) =>
	{
		const webClient = useWebClient();
		const isSingleColumnScreen =
			useObserver(
				() =>
					webClient.viewportWidth < 360
			);
		const adjustedNrOfColumns =
			useMemo(
				() =>
					isSingleColumnScreen
						? 1
						: numberOfColumns,
				[
					isSingleColumnScreen,
					numberOfColumns,
				]
			);
		const classes =
			useStyles({
				numberOfItems: adjustedNrOfColumns,
			});
		const [isBeginning, setBeginning] = useState(true);
		const [isEnd, setEnd] = useState(false);
		const modules =
			useMemo(
				() => [
					Navigation,
					Pagination,
					Scrollbar,
					A11y,
					Virtual,
					FreeMode,
				],
				[]
			);
		const onReachBeginning =
			useCallback(
				() =>
					setBeginning(true),
				[]
			);
		const onReachEnd =
			useCallback(
				() =>
					setEnd(true),
				[]
			);
		const onFromEdge =
			useCallback(
				() =>
				{
					setBeginning(false);
					setEnd(false);
				},
				[]
			);
		const isSmallScreen = useIsSmallScreen();
		const styles =
			useMemo(
				() => ({
					padding: '8px 8px 16px 8px', // to make sure the shadows are shown
					overflow:
						overflow
							? 'visible'
							: undefined
				}),
				[
					overflow,
				]
			);
		const [swiper, setSwiper] = useState<SwiperClass | undefined>(undefined);
		const onSwiper =
			useCallback(
				(swiper: SwiperClass) =>
				{
					setSwiper(swiper);

					if (previewSwipe)
					{
						const timeout = 1000;

						swiper.slideTo(1, timeout);

						setTimeout(
							() =>
								swiper.slideTo(0, timeout),
							timeout
						);
					}
				},
				[previewSwipe]
			);
		const gotoPreviousSlide =
			useCallback(
				() =>
					swiper?.slidePrev(),
				[swiper]
			);
		const gotoNextSlide =
			useCallback(
				() =>
					swiper?.slideNext(),
				[swiper]
			);
		const isLargeScreen = useIsLargeScreen();

		return <div
			className={classes.root}
		>
			<Swiper
				modules={modules}
				spaceBetween={
					isSmallScreen
						? 8
						: 16
				}
				slidesPerView={adjustedNrOfColumns}
				navigation
				freeMode={{
					enabled: true,
					sticky: !isLargeScreen,
				}}
				onReachBeginning={onReachBeginning}
				onReachEnd={onReachEnd}
				onFromEdge={onFromEdge}
				style={styles}
				onSwiper={onSwiper}
				centerInsufficientSlides={centerInsufficientSlides}
				onClick={
					swiper =>
					{
						const item = items[swiper.clickedIndex];

						if (item)
						{
							// In case of opening a drawer, the click event comes after this (mouse up) event
							// Which means that any drawer displayed by this event will immediately be closed again
							// by the future click event (on the drawer backdrop)
							// To fix this, we time out this event so it happens after the click event
							setTimeout(
								() =>
								{
									onClick(item.value);
								},
								10
							);
						}
					}
				}
			>
				{
					items.map(
						(item, idx) =>
							<SwiperSlide
								key={item.id}
							>
								<SwiperControlItem
									item={item}
									first={idx === 0}
									last={idx === items.length - 1}
									smallScreen={isSmallScreen}
								/>
							</SwiperSlide>
					)
				}
			</Swiper>
			<>
				<div
					className={clsx(classes.swiperButtonContainer, classes.swiperButtonLeft)}
				>
					<IconButton
						className={classes.swiperButton}
						onClick={gotoPreviousSlide}
						style={{
							color:
								isBeginning
									? undefined
									: tintColor.css,
						}}
						size="small"
						disabled={isBeginning}
					>
						<ChevronLeft
							fontSize="large"
							style={{
								fontSize: '3rem',
							}}
						/>
					</IconButton>
				</div>
				<div
					className={clsx(classes.swiperButtonContainer, classes.swiperButtonRight)}
				>
					<IconButton
						className={classes.swiperButton}
						onClick={gotoNextSlide}
						style={{
							color:
								isEnd
									? undefined
									: tintColor.css,
						}}
						size="small"
						disabled={isEnd}
					>
						<ChevronRight
							fontSize="large"
							style={{
								fontSize: '3rem',
							}}
						/>
					</IconButton>
				</div>
			</>
		</div>;
	};
