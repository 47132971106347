import { useObserver } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { useWaiter } from '../../../../Service/UserService/useWaiter';
import { useManagerContext } from '../manager-context';
import { ManagerView } from '../ManagerView';

export function useWhetherUserRolePermitsPageAccess(): (pageName: ManagerView) => boolean
{
	const waiter = useWaiter(false);
	const {waiterService} = useManagerContext();

	const isOwner = useMemo(() => waiterService.role === 'Owner', [waiterService.role]);
	const isManager = useMemo(() => waiterService.role === 'Manager', [waiterService.role]);

	const isRoot = useObserver(() => waiter?.isRoot ?? false);
	
	return useCallback(
		(pageName: ManagerView) => {
			switch (pageName)
			{
				case 'card-payment-actions':
					return isOwner;
				case 'code-info':
					return isOwner;
				case 'device-settings':
					return true;
				case 'reports':
					return isOwner;
				case 'payment-recipients':
					return isOwner || isRoot;
				case 'menu-editor':
					return isOwner;
				case 'places':
					return isOwner || isManager;
				case 'routings':
					return isOwner || isManager;
				case 'settings':
					return true;
				case 'order-handler':
					return true;
				default:
					return true;
			}
		},
		[isManager, isOwner, isRoot]
	);
}