import * as React from 'react';
import { CSSProperties, FC, useMemo } from 'react';
import { useElementCanScroll } from '../../../../Util/Hooks/useElementCanScroll';

interface CloudyScrollContainerProps
{
	style?: CSSProperties;
}

export const CloudyScrollContainer: FC<CloudyScrollContainerProps> =
	(
		{
			children,
			style,
		},
	) =>
	{
		const [
			containerRef,
			contentRef,
			canScrollDown,
			canScrollUp,
		] = useElementCanScroll();

		const topFadeHeight = Math.min(canScrollUp, 30);
		const bottomFadeHeight = Math.min(canScrollDown, 30);
		const topGradientPoints = useMemo(
			() => getGradientPoints()
				.reverse()
				.map(({x, y}) => ({x: 1 - x, y})),
			[],
		);
		const bottomGradientPoints = useMemo(
			() => getGradientPoints()
				.map(({x, y}) => ({x: 1 - x, y})),
			[],
		);
		const maskImageCss = useMemo(
			() => `linear-gradient(
						to bottom,
						${topGradientPoints
				.map(({x, y}) => `rgba(0,0,0,${y}) ${x * topFadeHeight}px`)
				.join(',')
			},
						${bottomGradientPoints
				.map(({x, y}) => `rgba(0,0,0,${y}) calc(100% - ${x * bottomFadeHeight + 4}px)`)
				.join(',')
			}
					`,
			[bottomFadeHeight, bottomGradientPoints, topFadeHeight, topGradientPoints],
		);

		return <div
			ref={containerRef}
			style={{
				flex: '1 1 auto',
				display: 'flex',
				flexDirection: 'column',
				overflowY: 'auto',
				overflowX: 'hidden',
				maskImage: maskImageCss,
				WebkitMaskImage: maskImageCss,
				msOverflowStyle: 'none',
				...style,
			}}
		>
			<div
				ref={contentRef}
				style={{
					flex: '1 1 auto',
					display: 'flex',
					flexDirection: 'column',
					paddingBottom: 16,
				}}
			>
				{children}
			</div>
		</div>;
	};

type GradientPoint = {
	y: number
	x: number
}

function getGradientPoints(): GradientPoint[]
{
	return [
		{x: 0, y: 1},
		{x: 0.19, y: 0.738},
		{x: 0.34, y: 0.541},
		{x: 0.47, y: 0.382},
		{x: 0.565, y: 0.278},
		{x: 0.65, y: 0.194},
		{x: 0.73, y: 0.126},
		{x: 0.802, y: 0.075},
		{x: 0.861, y: 0.042},
		{x: 0.91, y: 0.021},
		{x: 0.952, y: 0.008},
		{x: 0.982, y: 0.002},
		{x: 1, y: 0},
	];
}