import { Account, AccountProfile } from '../../Api/Account/Account';
import { Storage } from '../../Bridge/Storage/Storage';
import { StorageVars } from '../../Constants/StorageConstants';

export function getAccountFromStorage(storage: Storage): Account | undefined
{
	const accountDescriptor = storage.get(StorageVars.Account);

	return accountDescriptor === undefined
		? undefined
		: AccountProfile.deserializeSingular(JSON.parse(accountDescriptor));
}
