import { ListItem, makeStyles } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { OrderDescriptor } from '../../../../../Api/Order/OrderDescriptor';
import { useWebClient } from '../../../../../Bridge/Client/WebClientProvider';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { useGetFile } from '../../../../../Util/Api/Hooks/useGetFile';
import { openFile } from '../../../../../Util/Api/openFile';
import { hasPayment } from '../../../../../Util/Orders/hasPayment';
import { OrderAction } from '../OrderAction';
import { OrderActionButton } from './OrderActionButton';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'flex-end',
		paddingTop: 8,
		paddingLeft: 8,
		paddingRight: 8,
		paddingBottom: 0,
		'& > *': {
			marginBottom: 8,
		},
		'& > :not(:first-child)': {
			marginLeft: 8,
		},
	},
});

interface OrderActionsProps
{
	actions: OrderAction[]
	order: OrderDescriptor
}

export const OrderActions: FC<OrderActionsProps> =
	(
		{
			order,
			actions,
		},
	) =>
	{
		const orderUuid = useObserver(() => order.uuid);
		const client = useWebClient(true);
		const downloadReceipt = useGetFile(
			`/client/order/receipt/pdf/${orderUuid}`,
			(blob, filename) => openFile(blob, filename, client),
		);
		const translate = useTranslate();
		const classes = useStyles();
		return useObserver(() => {
			const visibleActions = [
				new OrderAction(
					'Download',
					() => translate('Order-Receipt-NonOfficial'),
					order => hasPayment(order) && order.paymentState === 'paid',
					downloadReceipt,
					'major',
					CloudDownloadIcon,
				),
				...actions,
			].filter(action => action.isEnabled(order))
				.sort((l, r) =>
					l.importance === r.importance
						? 0
						: (r.importance === 'major' ? -1 : 1),
				);
			return <ListItem
				divider
				className={classes.root}
			>
				{
					visibleActions.map(action => <OrderActionButton
						key={action.id}
						order={order}
						action={action}
					/>)
				}
			</ListItem>;
		});
	};