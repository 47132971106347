import Decimal from 'decimal.js';
import * as React from 'react';
import { createContext, Dispatch, FC, SetStateAction, useContext, useMemo, useState } from 'react';
import { OrderFormFieldSupport, Place } from '../../../../../../../Api/Business/Place';

interface Context
{
	setTip: Dispatch<SetStateAction<Decimal | undefined>>;
	support: OrderFormFieldSupport;
	tip?: Decimal;
}

const ContextRef = createContext<Context>(undefined as never);

interface TipContextProviderProps
{
	place: Place;
}

export const TipContextProvider: FC<TipContextProviderProps> =
	(
		{
			children,
			place,
		},
	) =>
	{
		const [tip, setTip] = useState<Decimal | undefined>();

		const support = useMemo(() => place.tipField, [place.tipField]);

		const contextValue = useMemo(
			() => ({
				setTip,
				support,
				tip,
			}),
			[support, tip],
		);

		return <ContextRef.Provider value={contextValue}>
			{children}
		</ContextRef.Provider>;
	};

export function useTipContext(): Context
{
	return useContext(ContextRef);
}