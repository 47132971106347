export class SerializationConverter<DESERIALIZED, SERIALIZED>
{
	public readonly deserializer: ((string: SERIALIZED) => DESERIALIZED) | undefined
	public readonly serializer: ((value: DESERIALIZED) => SERIALIZED) | undefined

	constructor(
		fromString: ((string: SERIALIZED) => DESERIALIZED) | undefined,
		serializer: ((value: DESERIALIZED) => SERIALIZED) | undefined
	)
	{
		this.deserializer = fromString;
		this.serializer = serializer;
	}
}