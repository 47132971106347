import { computed, makeObservable, observable } from 'mobx';
import { Localizer } from '../../../Bridge/Localization/Localizer';
import { CouponType } from './CouponDescriptor';

export abstract class Coupon
{
    /*---------------------------------------------------------------*
     *                          Properties                           *
     *---------------------------------------------------------------*/

    id: number | undefined;
    businessId: number;
    externalId: string | undefined;
    displayName: string;
    private _type: CouponType;

    /*---------------------------------------------------------------*
     *                          Constructor                          *
     *---------------------------------------------------------------*/

    constructor(
        type: CouponType,
        displayName: string,
        businessId: number,
        id?: number,
        externalId?: string,
    )
    {
        makeObservable<Coupon, '_type'>(
            this,
            {
                id: observable,
                businessId: observable,
                externalId: observable,
                displayName: observable,
                _type: observable,
                type: computed,
            },
        );

        this._type = type;
        this.id = id;
        this.displayName = displayName;
        this.businessId = businessId;
        this.externalId = externalId;
    }

    /*---------------------------------------------------------------*
     *                        Initialization                         *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                           Computed                            *
     *---------------------------------------------------------------*/

    get type(): CouponType
    {
        return this._type;
    }

    /*---------------------------------------------------------------*
     *                            Actions                            *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                          Public logic                         *
     *---------------------------------------------------------------*/

    abstract effectDescriptionShort(
        localizer: Localizer,
        discount: number
    ): string;

    abstract effectDescriptionLong(
        localizer: Localizer,
        discount: number
    ): string;

    /*---------------------------------------------------------------*
     *                         Private logic                         *
     *---------------------------------------------------------------*/
}
