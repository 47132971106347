import { AddressDescriptor } from '../../Api/Util/address/AddressDescriptor';
import { nonNullish } from '../nonNullish';

export function getSingleLineValue(address: AddressDescriptor): string
{
	const streetAndNumber = [
		address.street,
		address.number,
	]
		.filter(nonNullish)
		.join(' ');

	const postalCodeAndCity = [
		address.postalCode,
		address.city,
	]
		.filter(nonNullish)
		.join(' ');

	return [
		streetAndNumber,
		postalCodeAndCity,
	]
		.filter(
			value =>
				value.length > 0,
		)
		.join(', ');
}
