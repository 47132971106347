import * as React from 'react';
import { FC } from 'react';
import { ContractingEntity } from '../../Api/contracting_entity/ContractingEntity';
import { useTranslate } from '../../Bridge/Localization/useTranslate';
import { PageDialog } from '../UI/PageDialog';
import { ContractingEntityContactInformationView } from './ContractingEntityContactInformationView';

interface ContractingEntityContactInformationDialogProps
{
	contractingEntity: ContractingEntity;
	onClose: () => void;
	open: boolean;
}

export const ContractingEntityContactInformationDialog: FC<ContractingEntityContactInformationDialogProps> =
	({
		contractingEntity,
		onClose,
		open,
	}) =>
	{
		const translate = useTranslate();

		return <PageDialog
			forceScreenMode="modal"
			maxWidth="xs"
			title={translate('Contact-Dialog-Title')}
			onClose={onClose}
			open={open}
		>
			<ContractingEntityContactInformationView
				contractingEntity={contractingEntity}
			/>
		</PageDialog>;
	};
