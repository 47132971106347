import { Exception } from './Exception';

export class IllegalArgumentException extends Exception
{
    /*---------------------------------------------------------------*
     *                          Properties                           *
     *---------------------------------------------------------------*/

    readonly name = 'IllegalStateException';

    /*---------------------------------------------------------------*
     *                          Constructor                          *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                        Initialization                         *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                           Computed                            *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                            Actions                            *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                          Public logic                         *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                         Private logic                         *
     *---------------------------------------------------------------*/
}
