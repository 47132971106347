import { Fade, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { Place } from '../../../../Api/Business/Place';
import { PlaceCode } from '../../../../Api/Business/PlaceCode';
import { useWebClient } from '../../../../Bridge/Client/WebClientProvider';
import { useLocalizer } from '../../../../Bridge/Localization/useLocalizer';
import { UIColors } from '../../../../Constants/UIColors';
import { useRootContext } from '../../../../RootContext';
import { useComputed } from '../../../../Util/Hooks/useComputed';
import { PlaceCodeScanner } from '../../../UI/PlaceCodeScanner/PlaceCodeScanner';
import { PlaceCodeScannerState } from '../../../UI/PlaceCodeScanner/PlaceCodeScannerState';
import { PlaceCodeScannerStore } from '../../../UI/PlaceCodeScanner/PlaceCodeScannerStore';
import { useManagerPlaceCodesContext } from '../context/place-code/ManagerPlaceCodesContext';
import { ManagerPlaceCodeInfoAssignedCodeContent } from './ManagerPlaceCodeInfoAssignedCodeContent';
import { ManagerPlaceCodeInfoNoCodeContent } from './ManagerPlaceCodeInfoNoCodeContent';
import { ManagerPlaceCodeInfoUnassignedCodeContent } from './ManagerPlaceCodeInfoUnassignedCodeContent';
import { PlaceSelectDialog } from './PlaceSelect/Dialog/PlaceSelectDialog';

const useStyles = makeStyles((theme: Theme) => ({
	placeCodeScanner: {
		height: '100%',
		objectFit: 'cover',
		width: '100%',
	},
	placeCodeScannerRoot: {
		flexGrow: 1,
	},
	root: {
		backgroundColor: UIColors.black,
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'center',
	},
	rootNoCode: {
		alignItems: 'center',
		backgroundColor: UIColors.greyLight,
		display: 'flex',
		height: 48,
		justifyContent: 'center',
		position: 'absolute',
		[theme.breakpoints.down('xs')]: {
			bottom: 0,
			width: '100%',
		},
		[theme.breakpoints.up('sm')]: {
			bottom: theme.spacing(2),
			borderRadius: theme.shape.borderRadius,
			width: 500,
		},
	},
	textNoCode: {
		marginLeft: theme.spacing(6),
		marginRight: theme.spacing(6),
	},
	textCard: {
		alignItems: 'center',
		alignSelf: 'center',
		background: 'white',
		bottom: 20,
		borderRadius: theme.shape.borderRadius,
		display: 'flex',
		justifyContent: 'center',
		marginLeft: 'auto',
		marginRight: 'auto',
		minWidth: 200,
		position: 'absolute',
		padding: theme.spacing(2),
		width: 'fit-content',
		zIndex: 0,
	},
}));

export const ManagerPlaceCodeInfo: FC =
	() =>
	{
		const classes = useStyles();

		const {notification} = useRootContext(true);
		const client = useWebClient(true);
		const localizer = useLocalizer();
		const {assignPlaceCode, fetchPlaceCodeByHash, getAssignedPlace, unassignPlaceCode} = useManagerPlaceCodesContext();

		const [currentPlaceCode, setCurrentPlaceCode] = useState<PlaceCode | undefined>();
		const [showPlaceSelectDialog, setShowPlaceSelectDialog] = useState(false);

		// TODO: Currently does an API call for each found code. Fix if place code scanner has been updated to a Functional Component
		const onPlaceCodeScan = useCallback(
			async (hash: string) =>
			{
				const scannedPlaceCode = await fetchPlaceCodeByHash(hash);

				if (!scannedPlaceCode)
				{
					notification.notify({
						content: localizer.translate('Qr-Code-Invalid'),
					});
				}

				return Promise.resolve(scannedPlaceCode);
			},
			[fetchPlaceCodeByHash, localizer, notification]
		);

		const currentPlace = useMemo(() => {
			if (currentPlaceCode !== undefined)
				return getAssignedPlace(currentPlaceCode.id);
		}, [currentPlaceCode, getAssignedPlace]);

		const onPlaceCodeFound = useCallback(
			(placeCode: PlaceCode) => {
				if (placeCode)
				{
					if (!currentPlaceCode || (currentPlaceCode && currentPlaceCode.hash === placeCode.hash))
					{
						setCurrentPlaceCode(placeCode);

						return Promise.resolve(placeCode);
					}
					else
					{
						return Promise.resolve(undefined);
					}
				}
				else
				{
					return Promise.resolve(undefined);
				}
			},
			[currentPlaceCode]
		);

		const onPlaceCodeFoundRef = useRef(onPlaceCodeFound);
		const onPlaceCodeScanRef = useRef(onPlaceCodeScan);

		const placeCodeScannerStore = useMemo(
			() => new PlaceCodeScannerStore<PlaceCode>(
				localizer,
				client,
				onPlaceCodeScanRef.current,
				onPlaceCodeFoundRef.current,
			),
			[client, localizer],
		);

		const showNoCodeInfo = useComputed(() => placeCodeScannerStore.type === 'stream' && currentPlaceCode === undefined && currentPlace === undefined, [currentPlace, currentPlaceCode, placeCodeScannerStore.type]);
		const showAssignedCodeInfo = useMemo(() => currentPlaceCode !== undefined && currentPlace !== undefined, [currentPlace, currentPlaceCode]);
		const showUnassignedCodeInfo = useMemo(() => currentPlaceCode !== undefined && currentPlace === undefined, [currentPlace, currentPlaceCode]);

		const reset = useCallback(
			() => {
				setShowPlaceSelectDialog(false);
				setCurrentPlaceCode(undefined);
			},
			[]
		);

		const onStartAssigningPlaceCode = useCallback(() => setShowPlaceSelectDialog(true), []);
		const onPlaceSelectDialogClose = useCallback(() => setShowPlaceSelectDialog(false), []);

		useObserver(
			() => {
				if (placeCodeScannerStore.scannerState === PlaceCodeScannerState.invalidQrCode)
				{
					notification.notify({
						content: localizer.translate('Qr-Code-Invalid'),
					});

					if (currentPlaceCode)
					{
						setCurrentPlaceCode(undefined);
					}
				}
				else if (placeCodeScannerStore.scannerState === PlaceCodeScannerState.unknownQrCode)
				{
					notification.notify({
						content: localizer.translate('Qr-Code-Unknown'),
					});

					if (currentPlaceCode)
					{
						setCurrentPlaceCode(undefined);
					}
				}
			}
			);

		const onPlaceSelect = useCallback(
			(place: Place) => {
				if (currentPlaceCode)
				{
					return fetchPlaceCodeByHash(currentPlaceCode.hash)
						.then(receivedPlaceCode =>
							receivedPlaceCode
								? assignPlaceCode(receivedPlaceCode, place.id)
								: Promise.resolve(undefined),
						)
						.then(placeCode => {
							if (placeCode)
							{
								reset();
								return notification.notify({
									content: localizer.translate('Place-Code-Assigned', placeCode.hash, place.name),
								});
							}
							else
							{
								return notification.notify({
									content: localizer.translate('Generic-Error-Occurred'),
								});
							}
						})
						.catch(() => notification.notify({
							content: localizer.translate('Generic-Error-Occurred'),
						}));
				}
				else
				{
					return Promise.resolve();
				}
			},
			[assignPlaceCode, currentPlaceCode, fetchPlaceCodeByHash, localizer, notification, reset]
		);

		const onUnassignPlaceCode = useCallback(
			() => {
				if (currentPlace && currentPlaceCode)
				{
					unassignPlaceCode(currentPlaceCode)
						.then(placeCode => {
							if (placeCode)
							{
								reset();
								return notification.notify({
									content: localizer.translate('Place-Code-Unassigned', placeCode.hash, currentPlace.name),
								});
							}
							else
							{
								return notification.notify({
									content: localizer.translate('Generic-Error-Occurred'),
								});
							}
						})
						.catch(() => notification.notify({
							content: localizer.translate('Generic-Error-Occurred'),
						}));
				}
			},
			[currentPlace, currentPlaceCode, unassignPlaceCode, reset, notification, localizer]
		);

		return <>
			<PlaceSelectDialog
				onClose={onPlaceSelectDialogClose}
				onPlaceSelect={onPlaceSelect}
				placeCode={currentPlaceCode}
				show={showPlaceSelectDialog}
			/>
			<div
				className={classes.root}
			>
				<Fade
					in
					unmountOnExit
				>
					<PlaceCodeScanner
						buttonDividerColor="white"
						classes={{
							root: classes.placeCodeScannerRoot,
							scanner: classes.placeCodeScanner,
						}}
						store={placeCodeScannerStore}
					/>
				</Fade>
				<ManagerPlaceCodeInfoNoCodeContent
					show={showNoCodeInfo}
				/>
				{
					currentPlace &&
					currentPlaceCode &&
					<ManagerPlaceCodeInfoAssignedCodeContent
						hash={currentPlaceCode.hash}
						onUnassignPlaceCode={onUnassignPlaceCode}
						placeName={currentPlace.name}
						show={showAssignedCodeInfo}
					/>
				}
				{
					currentPlaceCode &&
					<ManagerPlaceCodeInfoUnassignedCodeContent
						hash={currentPlaceCode.hash}
						onStartAssigningPlaceCode={onStartAssigningPlaceCode}
						show={showUnassignedCodeInfo}
					/>
				}
			</div>
		</>;
	};
