import { PlaceCode, PlaceCodeProfile } from '../../../../../Api/Business/PlaceCode';
import { fetch } from '../../../../../Util/Api';

export function getPlaceCodeByHash(params: GetPlaceCodeByHashParams): Promise<PlaceCode | undefined>
{
	return fetch('/owner/business/place/code/hash', params, PlaceCodeProfile);
}

interface GetPlaceCodeByHashParams
{
	hash: string
}