import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { OrderCommentForm } from '../../../OrderComment/OrderCommentForm';
import { OrderBuilderStore } from '../../OrderBuilderStore';

interface CommentOrderBuilderSegmentProps
{
	store: OrderBuilderStore
}

export const CommentOrderBuilderSegment: FC<CommentOrderBuilderSegmentProps> =
	(
		{
			store,
		},
	) =>
	{
		const orderCommentStore = useObserver(() => store.orderCommentStore);
		const commentFormHasFocus = useObserver(() => store.commentFormHasFocus);
		const setCommentFormHasFocus = useObserver(() => store.setCommentFormHasFocus);
		const showValidation = useObserver(() => store.showValidation);

		if (orderCommentStore === undefined)
			return null;
		return <OrderCommentForm
			inFocus={commentFormHasFocus}
			setCommentFormHasFocus={setCommentFormHasFocus}
			store={orderCommentStore}
			showValidation={showValidation}
		/>;
	};