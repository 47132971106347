import makeStyles from '@material-ui/core/styles/makeStyles';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { OrderDescriptor } from '../../../../../../../Api/Order/OrderDescriptor';
import { useLocalizer } from '../../../../../../../Bridge/Localization/useLocalizer';
import { updateOrderPaymentState } from '../../../../../../../Service/OrderService/Api/Waiter/updateOrderPaymentState';
import { voidOrders } from '../../../../../../../Service/OrderService/Api/Waiter/voidOrders';
import { getOrderIdArray } from '../../../../../../../Util/Orders/getOrderIdArray';
import { PageDialog } from '../../../../../../UI/PageDialog';
import { useManagerContext } from '../../../../manager-context';
import { Bill } from '../Bill';
import { LocalizedPrice } from '../LocalizedPrice';
import { BillDialogActions } from './BillDialogActions';

const useStyles = makeStyles(
	() =>
		({
			bill:
				{
					flexGrow: 1,
				},
			content:
				{
					display: 'flex',
					flexGrow: 1,
					marginTop: '0px !important',
				},
			dialogContent:
				{
					display: 'flex',

					'&::-webkit-scrollbar':
						{
							display: 'none',
						},
				},
			paper:
				{
					height: 'inherit',
				},
		}));

export interface BillDialogProps
{
	onClose: () => void;
	orders?: OrderDescriptor[];
	placeName?: string;
}

export const BillDialog: FC<BillDialogProps> =
	({
		onClose,
		orders,
		placeName,
	}) =>
	{
		const classes = useStyles();

		const localizer = useLocalizer();

		const {
			waiterService,
		} = useManagerContext();

		const [open, setOpen] = useState(orders!== undefined && orders.length > 0 && placeName !== undefined);
		const [cost, setCost] = useState<LocalizedPrice>();
		const [selectedOrders, setSelectedOrders] = useState<OrderDescriptor[]>();

		useEffect(() => setOpen(orders!== undefined && orders.length > 0 && placeName !== undefined), [orders, placeName]);
		const close = useCallback(() => setOpen(false), []);

		const businessId = useObserver(() => waiterService.business?.id);

		const onPayOrders = useCallback(
			() => updateOrderPaymentState(getOrderIdArray(selectedOrders), 'paid', businessId),
			[businessId, selectedOrders]
		);
		const onVoidOrders = useCallback(() => voidOrders(getOrderIdArray(selectedOrders)), [selectedOrders]);

		const actions = useMemo(
			() =>
				<BillDialogActions
					cost={cost}
					onPaid={onPayOrders}
					onVoid={onVoidOrders}
					selectedOrders={selectedOrders}
				/>,
			[cost, onPayOrders, onVoidOrders, selectedOrders]);

		return <PageDialog
			classes={{
				content: classes.content,
				DialogContent: classes.dialogContent,
				Paper: classes.paper,
			}}
			contentPaddingBottom={0}
			contentPaddingLeft={0}
			contentPaddingRight={0}
			dialogActions={actions}
			fullWidth
			maxWidth="md"
			onClose={close}
			onExited={onClose}
			open={open}
			title={
				placeName === undefined
					? undefined
					: `${localizer.translate('Place')} ${placeName}`
			}
		>
			<Bill
				className={classes.bill}
				onCostUpdate={setCost}
				onSelectedOrdersUpdate={setSelectedOrders}
				orders={orders}
			/>
		</PageDialog>;
	};