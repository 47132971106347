import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { Event, EventHint } from '@sentry/types';
import { Exception } from '../../Util/Exception/Exception';
import { HttpRequestException } from '../../Util/Exception/HttpRequestException';

export function initializeSentry()
{
	Sentry.init({
		environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
		release: formatReleaseVersion(process.env.REACT_APP_BUTLAROO_RELEASE),
		dsn: process.env.REACT_APP_SENTRY_DSN,
		integrations: [
			new Integrations.CaptureConsole({
				levels: ['error'],
			}),
		],
		beforeSend(event: Event, hint?: EventHint): PromiseLike<Event | null> | Event | null
		{
			const exception = hint?.originalException;

			if (exception)
			{
				event.extra = {...event.extra, Exception: exception};
				event.tags = {
					...event.tags,
					'exception.id': (exception as Exception)?.id,
				};

				if (exception instanceof HttpRequestException && exception.url)
				{
					event.tags = {...event.tags, 'request.url': exception.url.split('?')[0]};
				}
			}

			return event;
		},
	});
}

function formatReleaseVersion(releaseVersion?: string): string | undefined
{
	if (releaseVersion === undefined || releaseVersion.trim().length === 0)
	{
		return undefined;
	}
	else if (releaseVersion.startsWith('v'))
	{
		return releaseVersion.slice(1);
	}
	else
	{
		return releaseVersion;
	}
}