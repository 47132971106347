import * as React from 'react';
import { FC, useMemo } from 'react';
import { useLocalizer } from '../../../../../../../../Bridge/Localization/useLocalizer';
import { getOrderEventPropertyLanguageEntry } from '../../localization';
import { OrderEventComponent } from '../OrderEventComponent';
import { OrderEventProps } from '../OrderEventProps';

export const OrderRefundRequestEvent: FC<OrderEventProps> =
	(
		{
			orderEvent,
		},
	) =>
	{
		const localizer = useLocalizer();

		const refundRequestIdText = useMemo(
			() => `${localizer.translate(getOrderEventPropertyLanguageEntry('refundRequest'))} ${orderEvent.refundRequestId}`,
			[localizer, orderEvent.refundRequestId],
		);

		return <OrderEventComponent
			orderEvent={orderEvent}
			summary={refundRequestIdText}
		/>;
	};