import { makeAutoObservable } from 'mobx';
import { SerializationProfile } from '../../Util/Serialization/Serialization';

export class OrderLineVariant
{
	id: number;
	variantId: number;
	assignmentId: number;

	constructor()
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});
	}
}

export const OrderLineVariantProfile =
	SerializationProfile.create(OrderLineVariant);