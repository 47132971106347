import { Duration } from 'iso8601-duration';
import { makeAutoObservable } from 'mobx';
import { SerializationProfile, StringToDurationTransformer } from '../../Util/Serialization/Serialization';

export class OrderRestrictionConfiguration
{
	public readonly timeWindow: Duration;
	perCover: boolean | undefined;
	maxQuantity: number;
	maxQuantityPerSession: number | undefined;
	includedMenuCardIds: number[] | undefined;
	excludedMenuCardIds: number[] | undefined;
	includedCategoryIds: number[] | undefined;
	excludedCategoryIds: number[] | undefined;
	includedPlaceIds: number[] | undefined;
	excludedPlaceIds: number[] | undefined;

	constructor()
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});
	}
}

export const OrderRestrictionConfigurationProfile = SerializationProfile
	.create(OrderRestrictionConfiguration)
	.transform('timeWindow', StringToDurationTransformer);