import { toJS } from 'mobx';
import { useEffect, useMemo, useRef, useState } from 'react';
import { PaymentIssuer } from '../../../../../../Api/Payment/PaymentIssuer';
import { usePaymentMethodContext } from '../../../method/context/PaymentMethodContext';
import { getPaymentIssuers } from '../../api/getPaymentIssuers';

export function useAvailablePaymentIssuers(): [PaymentIssuer[], boolean]
{
	const {isInitialized: arePaymentMethodsInitialized, selectedPaymentMethod} = usePaymentMethodContext();

	const [isInitialized, setIsInitialized] = useState(false);
	const [paymentIssuers, setPaymentIssuers] = useState<PaymentIssuer[]>([]);

	const didUnmount = useRef(false);

	useEffect(() =>
	{
		return () => {
			didUnmount.current = true;
		};
	}, []);

	useEffect(() =>
	{
		if (arePaymentMethodsInitialized)
		{
			if (selectedPaymentMethod?.isIssuerRequired)
			{
				if (selectedPaymentMethod.issuers.length > 0)
				{
					setPaymentIssuers(selectedPaymentMethod.issuers);
					setIsInitialized(true);
				}
				else
				{
					getPaymentIssuers({
						payment_method: selectedPaymentMethod.id,
					})
						.then(newPaymentIssuers =>
						{
							if (!didUnmount.current)
							{
								const issuers = toJS(newPaymentIssuers);
								
								mergeIssuers(selectedPaymentMethod.issuers, issuers)
								setPaymentIssuers(issuers);
								setIsInitialized(true);
							}
						});
				}
			}
			else
			{
				setPaymentIssuers([]);
				setIsInitialized(true);
			}
		}
	}, [arePaymentMethodsInitialized, selectedPaymentMethod?.id, selectedPaymentMethod?.isIssuerRequired, selectedPaymentMethod?.issuers]);

	return useMemo(() => [paymentIssuers, isInitialized], [isInitialized, paymentIssuers]);
}

function mergeIssuers(issuers: PaymentIssuer[], issuersToMerge: PaymentIssuer[]): void
{
	issuersToMerge.forEach(issuer =>
	{
		const issuerIdx = issuers.findIndex(peer => peer.id === issuer.id);

		if (issuerIdx > -1)
			issuers.splice(issuerIdx, 1, issuer);
		else
			issuers.push(issuer);
	})
}