import * as React from 'react';
import { FC, useMemo } from 'react';
import { TimeSchedulesContextProvider } from '../../../../context/time-schedule/TimeSchedulesContext';
import { ManagerView } from '../../ManagerView';

interface ManagerTimeSchedulesContextProps
{
	businessId: number;
	view: ManagerView;
}

export const ManagerTimeSchedulesContextProvider: FC<ManagerTimeSchedulesContextProps> =
	(
		{
			businessId,
			children,
			view,
		},
	) =>
	{
		const viewRequiresTimeSchedules = useMemo(
			() => view === 'places',
			[view],
		);

		return <TimeSchedulesContextProvider businessId={businessId} doInitialize={viewRequiresTimeSchedules} intent="manager">
			{children}
		</TimeSchedulesContextProvider>;
	};