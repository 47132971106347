import { PaymentMethods } from '@adyen/adyen-web/dist/types/types';
import * as React from 'react';
import { createContext, FC, useContext, useEffect, useMemo } from 'react';
import { PaymentMethodDescriptor, TRADITIONAL_PAYMENT_METHOD_ID } from '../../../../../Api/Payment/PaymentMethodDescriptor';
import { nonNullish } from '../../../../../Util/nonNullish';
import { useAdyenCheckoutContext } from '../../adyen/checkout/AdyenCheckoutContext';
import { useUnavailablePaymentMethodContext } from '../../adyen/checkout/context/UnavailablePaymentMethodProvider';
import { PaymentTiming } from '../../model/PaymentTiming';
import { useRequestAvailablePaymentMethods } from './useRequestAvailablePaymentMethods';

type Context = PaymentMethodDescriptor[] | undefined

const ContextRef = createContext<Context>(undefined as never);

export function usePaymentMethodAvailabilityContext(): Context
{
	return useContext(ContextRef);
}

interface PaymentMethodAvailabilityContextProviderProps
{
	allowPayAfterwards?: boolean;
	timing: PaymentTiming;
}

export const PaymentMethodAvailabilityContextProvider: FC<PaymentMethodAvailabilityContextProviderProps> =
	({
		allowPayAfterwards,
		children,
		timing,
	}) =>
	{
		const {client} = useAdyenCheckoutContext();

		const paymentMethods = useRequestAvailablePaymentMethods(timing);

		useEffect(
			() =>
			{
				if (client !== undefined)
				{
					const butlarooPayPaymentMethods = paymentMethods
						?.map(({butlarooPayPaymentMethod}) => butlarooPayPaymentMethod)
						.filter(nonNullish) ?? [];

					// noinspection JSIgnoredPromiseFromCall
					client.update({
						paymentMethodsResponse:
							butlarooPayPaymentMethods.length > 0
								? {
									paymentMethods: butlarooPayPaymentMethods,
								}
								: undefined,
					});
				}
			},
			[client, paymentMethods],
		);

		const unavailablePaymentMethods = useUnavailablePaymentMethodContext();

		const availablePaymentMethods = useMemo(() =>
		{
			return paymentMethods
				?.filter(paymentMethod =>
				{
					if (paymentMethod.butlarooPayPaymentMethod === undefined)
						return allowPayAfterwards
							|| paymentMethod.id !== TRADITIONAL_PAYMENT_METHOD_ID;
					else
						return !unavailablePaymentMethods.includes(paymentMethod.butlarooPayPaymentMethod.type as keyof PaymentMethods);
				});
		}, [allowPayAfterwards, paymentMethods, unavailablePaymentMethods]);

		return <ContextRef.Provider value={availablePaymentMethods}>
			{children}
		</ContextRef.Provider>;
	};
