// @ts-nocheck
import { createStyles, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import { ThemeStyle } from '@material-ui/core/styles/createTypography';
import Decimal from 'decimal.js';
import { observer } from 'mobx-react';
/* eslint-disable */
import * as React from 'react';
import { Localizer } from '../../../Bridge/Localization/Localizer';

const styles = (theme: Theme) => createStyles({
	DisplayBase: {
		textAlign: 'right',
	},
	ZeroPriceDisplay: {
		color: '#70b370',
		fontWeight: 500,
		textTransform: 'lowercase',
	},
	NonZeroPriceDisplay: {
	},
} as any);

export interface PriceTagProps
{
	hideCurrencySymbols?: boolean;
	price: Decimal;
	localizer: Localizer;
	currencyCode: string;
	discounted?: boolean;
	className?: string;
	style?: React.CSSProperties;
	textPrepended?: string;
	textAppended?: string;
	variant?: ThemeStyle | 'srOnly';
	component?: React.ElementType<React.HTMLAttributes<HTMLElement>>;
	showFreeWhenZero?: boolean;
	color?: typeof Typography.defaultProps.color;
}

@observer
class PriceTag extends React.Component<PriceTagProps & WithStyles<typeof styles>>
{
	render()
	{
		return this.renderComponent(this.props.price, this.props.localizer, this.props.currencyCode);
	}

	renderComponent(price: Decimal, localizer: Localizer, currencyCode: string)
	{
		const showFreeWhenZero = this.props.showFreeWhenZero !== undefined
			?
				this.props.showFreeWhenZero
			:
				false;
		const discounted = this.props.discounted !== undefined
			?
			this.props.discounted
			:
			false;
		const discountedStyle = discounted
			?
			{
				textDecoration: 'line-through',
			}
			:
			{};
		const color = this.props.color !== undefined
			?
			this.props.color
			:
			'textPrimary';

		const isZero = !price.greaterThan(0);
		const showFree = isZero && showFreeWhenZero;
		const priceCssClass = showFree
			?
				this.props.classes.ZeroPriceDisplay
			:
				this.props.classes.NonZeroPriceDisplay;
		const hideCurrencySymbols = this.props.hideCurrencySymbols ?? false;

		const baseCssClass = this.props.classes.DisplayBase;
		const customCssClass = this.props.className !== undefined ? this.props.className : '';
		const inLineStyle = this.props.style !== undefined ? this.props.style : {};
		const variant = this.props.variant !== undefined ? this.props.variant : 'body1';
		return <Typography
			variant={variant}
			color={color}
			className={`${baseCssClass} ${customCssClass}`}
			style={{
				...inLineStyle,
				...discountedStyle,
			}}
			component={this.props.component}
		>
			{this.props.textPrepended !== undefined ? this.props.textPrepended : ''}
			<span className={priceCssClass}>
			{
				showFree
					?
					localizer.translate('Payment-Free')
					:
					(
						hideCurrencySymbols
							? localizer.formatCurrencyWithoutSymbol(price, currencyCode)
							: localizer.formatCurrency(price, currencyCode)
					)
			}
			</span>
			{this.props.textAppended !== undefined ? this.props.textAppended : ''}
		</Typography>;
	}
}

export default withStyles(styles)(PriceTag);
