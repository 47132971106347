import { Hidden } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import * as React from 'react';
import { FC } from 'react';

export interface PageContentProps
{
    xs?: boolean;
    xl?: boolean;
    insetTop?: boolean;
    insetHorizontal?: boolean;
    style?: CSSProperties;
}

export const PageContent: FC<PageContentProps> =
    props =>
    {
        const {
            style,
            children,
        } = props;
        return <div
            style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'stretch',
                justifyContent: 'center',
                ...style,
            }}
        >
            <Hidden
                mdUp
            >
                <Content maxWidth="97%" {...props}>
                    {children}
                </Content>
            </Hidden>
            <Hidden
                smDown
                lgUp
            >
                <Content maxWidth="90%" {...props}>
                    {children}
                </Content>
            </Hidden>
            <Hidden
                mdDown
                xlUp
            >
                <Content maxWidth="75%" {...props}>
                    {children}
                </Content>
            </Hidden>
            <Hidden
                lgDown
            >
                <Content maxWidth="50%" {...props}>
                    {children}
                </Content>
            </Hidden>
        </div>;
    };

interface ContentProps extends PageContentProps
{
    maxWidth: string
}

const Content: FC<ContentProps> =
    (
        {
            insetTop,
            insetHorizontal,
            maxWidth,
            children,
        }
    ) =>
    {
        return <div
            style={{
                flex: '1 1 auto',
                position: 'relative',
                maxWidth:
                    insetHorizontal === undefined || insetHorizontal === true
                        ?
                        maxWidth
                        :
                        '100%',
                paddingTop:
                    insetTop ? 10 : undefined,
            }}
        >
            {children}
        </div>;
    }
