import { BaseStore } from '@intentic/ts-foundation';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { action, computed, makeObservable } from 'mobx';
import { Localizer } from '../../../../Bridge/Localization/Localizer';
import { CurrentOrderService } from '../../../../Service/CurrentOrder/CurrentOrderService';
import { CurrentPlaceService } from '../../../../Service/CurrentPlace/CurrentPlaceService';
import { phoneValidationErrors } from '../../../UI/Form/PhoneNumberInput/validatePhoneNumber';

export class SchedulerStore extends BaseStore
{
    /*---------------------------------------------------------------*
     *                          Properties                           *
     *---------------------------------------------------------------*/

    private readonly currentPlaceService: CurrentPlaceService;
    private readonly currentOrderService: CurrentOrderService;
    private readonly localizer: Localizer;

    private readonly phoneNumberUtil: PhoneNumberUtil;

    private timeChosen: boolean;

    /*---------------------------------------------------------------*
     *                          Constructor                          *
     *---------------------------------------------------------------*/

    constructor(
        currentPlaceService: CurrentPlaceService,
        currentOrderService: CurrentOrderService,
        localizer: Localizer,
    )
    {
        super();

        makeObservable(
            this,
            {
                clientPhoneNumber: computed,
                validation: computed,
                hasValidationErrors: computed,
                validationErrors: computed,
                timeValidationErrors: computed,
                requirePhoneNumber: computed,
                phoneValidationErrors: computed,
                setClientPhoneNumber: action.bound,
                onChangeScheduledTime: action.bound,
            },
        );

        this.currentPlaceService = currentPlaceService;
        this.currentOrderService = currentOrderService;
        this.localizer = localizer;
        this.phoneNumberUtil = new PhoneNumberUtil();
        this.timeChosen = false;
    }

    /*---------------------------------------------------------------*
     *                        Initialization                         *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                           Computed                            *
     *---------------------------------------------------------------*/

    get clientPhoneNumber(): string | undefined
    {
        return this.currentOrderService.clientPhoneNumber.value;
    }

    get validation(): string[]
    {
        return this.currentPlaceService.place?.orderInFutureSupport === 'REQUIRED' &&
        !this.timeChosen
            ?
            [
                this.localizer.translate('Order-Time-Required'),
            ]
            :
            [];
    }

    get hasValidationErrors(): boolean
    {
        return this.validationErrors.length > 0;
    }

    get validationErrors(): string[]
    {
        return [...this.timeValidationErrors, ...this.phoneValidationErrors];
    }

    get timeValidationErrors(): string[]
    {
        return this.currentPlaceService.place?.orderInFutureSupport === 'REQUIRED' &&
        this.currentOrderService.effectiveHandoverDateSpec === undefined
            ?
            [
                this.localizer.translate('Order-Time-Required'),
            ]
            :
            [];
    }

    get requirePhoneNumber(): boolean
    {

        // TODO: Update to depend on Place.phoneNumberField if notifications for ASAP are supported
        return this.currentOrderService.requiresPhoneNumberInSchedulerForm;
    }

    get phoneValidationErrors(): string[]
    {
        return phoneValidationErrors(
            this.requirePhoneNumber,
            this.clientPhoneNumber,
            this.localizer,
            this.phoneNumberUtil
        );
    }

    /*---------------------------------------------------------------*
     *                            Actions                            *
     *---------------------------------------------------------------*/

    setClientPhoneNumber(clientPhoneNumber: string | undefined): void
    {
        this.currentOrderService.setClientPhoneNumber(clientPhoneNumber);
    }

    onChangeScheduledTime(scheduledTime: Date | 'ASAP' | undefined): void
    {
        this.currentOrderService.setScheduledTime(scheduledTime);
        this.timeChosen = true;
    }

    /*---------------------------------------------------------------*
     *                          Public logic                         *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                         Private logic                         *
     *---------------------------------------------------------------*/
}
