export function getDifference(date1: Date, date2: Date, interval: 'days' | 'hours' | 'minutes' | 'milliseconds' | 'seconds')
{
    let diffMillis = date1.getTime() - date2.getTime();

    let factor = 1;

    switch (interval)
    {
        case 'days':
            factor = 86400000;      // 1000 * 60 * 60 * 24
            break;
        case 'hours':
            factor = 3600000;       // 1000 * 60 * 60
            break;
        case 'minutes':
            factor = 60000;         // 1000 * 60
            break;
        case 'milliseconds':
            factor = 1;             // 1
            break;
        case 'seconds':
            factor = 1000;          // 1000
            break;
    }

    return Math.abs(diffMillis / factor);
}