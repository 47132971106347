import { TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { CSSProperties, FC, useEffect, useMemo, useState } from 'react';
import { Business, BusinessProfile } from '../../../Api/Business/Business';
import { fetchList } from '../../../Util/Api';
import { useComputed } from '../../../Util/Hooks/useComputed';
import { useManagerContext } from './manager-context';

interface StyleProps
{
	color: string
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200,
	},
	cssLabel: ({color}) => ({
		color: `${color} !important`,
	}),
	cssOutlinedInput: ({color}) => ({
		color: color,
		height: 36,
		// '&$cssFocused $notchedOutline': {
		// 	borderColor: `${theme.palette.primary.main} !important`,
		// }
	}),
	cssFocused: {},
	notchedOutline: ({color}) => ({
		borderWidth: '1px',
		borderColor: `${color} !important`,
	}),
	clearIndicator: ({color}) => ({
		color: color,
	}),
	popupIndicator: ({color}) => ({
		color: color,
	}),
}))

interface BusinessSelectorProps
{
	value: Business
	onChange: (business: Business) => void
	style?: CSSProperties
}

export const BusinessSelector: FC<BusinessSelectorProps> =
	(
		{
			value,
			onChange,
			style,
		},
	) =>
	{
		const {waiterService} = useManagerContext();
		const currentUserIsOwner = useComputed(
			() => waiterService.user?.type === 'Owner',
			[]
		);
		if (!currentUserIsOwner)
			return null;
		else
		{
			return <Inner
				value={value}
				onChange={onChange}
				style={style}
			/>;
		}
	};

const Inner: FC<BusinessSelectorProps> =
	(
		{
			value,
			onChange,
			style,
		}
	) =>
	{
		const {brandingService} = useManagerContext();

		const [inputValue, setInputValue] = useState('');
		const [inputUnchanged, setInputUnchanged] = useState(true);
		const [initialLoading, setInitialLoading] = useState(true);
		const [open, setOpen] = useState(false);
		const [loading, setLoading] = useState(false);

		const topBarContrastTextColor = useObserver(() => brandingService.topBarContrastTextColor.css);

		const classes = useStyles({color: topBarContrastTextColor});

		useEffect(
			() => {
				setInputValue(value.name);
			},
			[value]
		)
		const [options, setOptions] = useState<Business[]>([]);
		const optionNames = useMemo(
			() => {
				return options.map(business => business.name);
			},
			[options]
		);
		useEffect(
			() => {
				let thisLoadActive = true;
				const searchValue = inputUnchanged ? '' : inputValue;
				setLoading(true);
				fetchList(
					`/owner/business/search?partOfName=${searchValue ?? ''}`,
					{},
					BusinessProfile
				)
					.then(businesses => {
						if (thisLoadActive)
						{
							setOptions(businesses);
							setInitialLoading(false);
							setLoading(false);
						}
					})
				return () => {
					thisLoadActive = false;
				};
			},
			[inputUnchanged, inputValue]
		);
		if (initialLoading || (!open && !loading && options.length === 1))
		{
			return null;
		}
		return <>
			<Autocomplete
				size="small"
				options={optionNames}
				value={value.name}
				onChange={(event, newValue) => {
					if (newValue !== null)
					{
						setInputValue(newValue);
						setInputUnchanged(true);
						const option = options.filter(option => option.name === newValue)[0];
						onChange(option);
					}
				}}
				disableClearable
				blurOnSelect
				inputValue={inputValue}
				onOpen={() => setOpen(true)}
				onClose={() => setOpen(false)}
				onBlur={() => {
					setInputValue(value.name);
					setInputUnchanged(true);
				}}
				filterOptions={options => options}
				style={{
					minWidth: 200,
					height: 35,
					...style,
				}}
				classes={{
					loading: classes.popupIndicator,
					popupIndicator: classes.popupIndicator,
					clearIndicator: classes.clearIndicator,
				}}
				renderInput={params => <TextField
					{...params}
					// label="Combo box"
					variant="outlined"
					onChange={(event) => {
						const newValue = event.target?.value;
						setInputValue(newValue ?? '');
						setInputUnchanged(false);
					}}
					InputLabelProps={{
						classes: {
							root: classes.cssLabel,
							focused: classes.cssFocused,
						},
						// ...params.InputLabelProps
					}}
					inputProps={{
						style: {
							cursor: !open ? 'pointer' : undefined,
							...(params.inputProps as any)?.style,
						},
						...params.inputProps
					}}
					InputProps={{
						classes: {
							root: classes.cssOutlinedInput,
							focused: classes.cssFocused,
							notchedOutline: classes.notchedOutline,
						},
						style: {
							cursor: !open ? 'pointer' : undefined,
							...(params.inputProps as any)?.style,
						},
						...params.InputProps
					}}
				/>}
			/>
		</>;
	}