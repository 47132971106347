export const NutritionFlags = [
	'IsVegetarian',
	'IsVegan',
	'IsOrganic',
	'ContainsAlcohol',
	'IsKosher',
	'IsHalal',
	'IsPregnancyFriendly',
	// TODO: To be removed if the NutritionInformation model is updated
	'IsBiological',
] as const;

export type NutritionFlag = typeof NutritionFlags[number];

export const NutritionFlagAliases = new Map<NutritionFlag, NutritionFlag>([
	['IsBiological', 'IsOrganic'],
]);
