import { isLocalAccessDeniedError } from '../../../Util/browser/isLocalAccessDeniedError';

export function setInLocalStorage(key: string, value?: any): void
{
	try
	{
		if (value == null)
			window.localStorage.removeItem(key);
		else
			window.localStorage.setItem(key, value);
	}
	catch (error)
	{
		if (!isLocalAccessDeniedError(error))
			throw error;
	}
}
