import { Popover, Theme, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ChevronDoubleRightIcon from 'mdi-material-ui/ChevronDoubleRight';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { CSSProperties, FC, useCallback, useMemo } from 'react';
import { OrderState } from '../../../../../../Api/Order/Order';
import { OrderDescriptor } from '../../../../../../Api/Order/OrderDescriptor';
import { useTranslate } from '../../../../../../Bridge/Localization/useTranslate';
import { UIColors } from '../../../../../../Constants/UIColors';
import { useOrderLinesThatWaiterCanSee } from '../../../../../../hooks/order-lines-that-waiter-can-see/useOrderLinesThatWaiterCanSee';
import { useRoutingWaiterMode } from '../../../../../../hooks/routing-waiter-mode/useRoutingWaiterMode';
import { pickUpOrderLines } from '../../../../../../lib/api/pickUpOrderLines';
import { prepareOrderLines } from '../../../../../../lib/api/prepareOrderLines';
import { updateOrderState } from '../../../../../../Service/OrderService/Api/Waiter/updateOrderState';
import { voidOrders } from '../../../../../../Service/OrderService/Api/Waiter/voidOrders';
import { isProductOrderLine } from '../../../../../../Util/order_line/isProductOrderLine';
import { ActionButton } from '../../../../../UI/action-button/ActionButton';
import { Button } from '../../../../../UI/Button/Button';
import { HorizontalDivider } from '../../../../../UI/HorizontalDivider';
import { useManagerContext } from '../../../manager-context';
import { ScreenPosition } from '../../ScreenPosition';

const useStyles = makeStyles((theme: Theme) => ({
	button: {
		justifyContent: 'space-between',
		margin: theme.spacing(1),
	},
	buttonTextLeft: {
		marginRight: theme.spacing(1),
	},
	buttonTextRight: {
		marginLeft: theme.spacing(1),
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(1),
	},
	test: {
		zIndex: -230,
	},
}));

interface OrderListActionPopoverProps
{
	anchorPosition: ScreenPosition
	className?: string
	onClose: () => void
	onOpenOrder?: (order: OrderDescriptor) => void
	order: OrderDescriptor
	style?: CSSProperties
}

export const OrderListActionPopover: FC<OrderListActionPopoverProps> =
	(
		{
			anchorPosition,
			onClose,
			onOpenOrder,
			order,
			style,
		},
	) =>
	{
		const classes = useStyles();

		const translate = useTranslate();

		const {
			waiterService,
		} = useManagerContext();

		const supportsPrepared = useMemo(() => waiterService.user?.orderHandlerSupportsOrderStatePrepared, [waiterService.user]);
		const supportsPickedUp = useMemo(() => waiterService.user?.orderHandlerSupportsOrderStatePickedUp, [waiterService.user]);
		const supportsDelivered = useMemo(() => waiterService.user?.orderHandlerSupportsOrderStateDelivered, [waiterService.user]);
		
		const lastSupportedState = useMemo(
			() =>
			{
				if (supportsDelivered) return 'delivered'
				else if (supportsPickedUp) return 'pickedUp'
				else if (supportsPrepared) return 'prepared'
				else return 'handled'
			},
			[supportsDelivered, supportsPickedUp, supportsPrepared]
		)

		const waiterRoutingId = useObserver(() => waiterService.user?.routingId);
		const routingWaiterMode = useRoutingWaiterMode(true);
		const orderLinesWaiterCanSee = useOrderLinesThatWaiterCanSee(order);
		const onStateUpdate = useCallback(
			async (orderState: OrderState) => {
				if (routingWaiterMode)
				{
					if (orderState === 'prepared')
					{
						if (orderLinesWaiterCanSee !== undefined && orderLinesWaiterCanSee.length > 0)
						{
							await prepareOrderLines({
								orderLineUuids: orderLinesWaiterCanSee.map(({uuid}) => uuid),
							});
						}
					}
					else if (orderState === 'pickedUp')
					{
						if (orderLinesWaiterCanSee !== undefined && orderLinesWaiterCanSee.length > 0)
						{
							await pickUpOrderLines({
								orderLineUuids: orderLinesWaiterCanSee?.map(({uuid}) => uuid),
							});
						}
					}
					else
					{
						throw new Error('Operation not allowed');
					}
				}
				else
				{
					await updateOrderState({uuid: order.uuid, state: orderState});
				}
				onClose();
			},
			[onClose, order.uuid, orderLinesWaiterCanSee, routingWaiterMode]
		)
		
		const openOrder = useCallback(
			() => {
				if (onOpenOrder)
				{
					onOpenOrder(order);
				}

				onClose();
			},
			[onClose, onOpenOrder, order]
		);

		const onVoidOrder = useCallback(
			async () => {
				await voidOrders(order.id);
				onClose();
			},
			[onClose, order]
		);

		const divider = useMemo(
			() =>
				<HorizontalDivider
					verticalMargin={0}
				/>,
			[]);

		const backButton = useMemo(
			() =>
				<Button
					classes={{
						root: classes.button,
					}}
					color="default"
					key="manager-order-back-button"
					onClick={onClose}
					variant="outlined"
				>
					<ArrowBackIcon />
					<Typography
						classes={{
							root: classes.buttonTextRight,
						}}
						noWrap
					>
						{translate('Generic-Back')}
					</Typography>

				</Button>,
			[classes.button, classes.buttonTextRight, onClose, translate]
		);

		const infoButton = useMemo(
			() =>
				<Button
					classes={{
						root: classes.button,
					}}
					color="default"
					key="manager-order-info-button"
					id="manager-order-info-button"
					onClick={openOrder}
				>
					<InfoOutlinedIcon />
					<Typography
						classes={{
							root: classes.buttonTextRight,
						}}
						noWrap
					>
						{translate('Generic-Info')}
					</Typography>

				</Button>,
			[classes.button, classes.buttonTextRight, openOrder, translate]
		);

		const handleButton = useMemo(
			() =>
				<ActionButton
					classes={{
						root: `${classes.button}`,
					}}
					color={{
						buttonColor: lastSupportedState === 'handled' || order.finalState === 'handled'
							? UIColors.positiveDark
							: UIColors.warningDark,
						contentColor: 'white',
					}}
					key="manager-order-handle-button"
					icon={lastSupportedState === 'handled' || order.finalState === 'handled' ? ChevronDoubleRightIcon : NavigateNextIcon}
					id="manager-order-handle-button"
					onClick={() => onStateUpdate('handled')}
					text={translate(order.finalState === 'handled' ? 'Order-Handle' : 'Order-Acknowledge')}

				/>,
			[classes.button, lastSupportedState, onStateUpdate, order, translate],
		);

		const preparedButton = useMemo(
			() =>
				lastSupportedState !== 'handled' &&
				order.finalState !== 'handled'
					? <ActionButton
						classes={{
							root: classes.button,
						}}
						color={{
							buttonColor: lastSupportedState === 'prepared' || order.finalState === 'prepared'
								? UIColors.positiveDark
								: UIColors.warningDark,
							contentColor: 'white',
						}}
						key="manager-order-prepared-button"
						icon={lastSupportedState === 'prepared' || order.finalState === 'prepared' ? ChevronDoubleRightIcon : NavigateNextIcon}
						id="manager-order-prepared-button"
						onClick={() => onStateUpdate('prepared')}
						text={translate('Order-State-Prepared')}
					/>
					: undefined,
			[classes.button, lastSupportedState, onStateUpdate, order, translate],
		);

		const pickedUpButton = useMemo(
			() =>
				lastSupportedState !== 'handled' && lastSupportedState !== 'prepared' &&
				order.finalState !== 'handled' && order.finalState !== 'prepared'
					? <ActionButton
						classes={{
							root: classes.button,
						}}
						color={{
							buttonColor: lastSupportedState === 'pickedUp' || order.finalState === 'pickedUp'
								? UIColors.positiveDark
								: UIColors.warningDark,
							contentColor: 'white',
						}}
						key="manager-order-picked-up-button"
						icon={lastSupportedState === 'pickedUp' || order.finalState === 'pickedUp' ? ChevronDoubleRightIcon : NavigateNextIcon}
						id="manager-order-picked-up-button"
						onClick={() => onStateUpdate('pickedUp')}
						text={translate('Order-State-PickedUp')}
					/>
					: undefined,
			[classes.button, lastSupportedState, onStateUpdate, order.finalState, translate],
		);

		const deliveredButton = useMemo(
			() =>
				lastSupportedState !== 'handled' && lastSupportedState !== 'prepared' && lastSupportedState !== 'pickedUp' &&
				order.finalState !== 'handled' && order.finalState !== 'prepared' && order.finalState !== 'pickedUp'
					? <ActionButton
						classes={{
							root: classes.button,
						}}
						color={{
							buttonColor: UIColors.positiveDark,
							contentColor: 'white',
						}}
						key="manager-order-delivered-button"
						icon={ChevronDoubleRightIcon}
						id="manager-order-delivered-button"
						onClick={() => onStateUpdate('delivered')}
						text={translate('Order-State-Delivered')}
					/>
					: null,
			[classes.button, lastSupportedState, onStateUpdate, order.finalState, translate],
		);

		const voidButton = useMemo(
			() =>
				<ActionButton
					classes={{
						root: classes.button,
					}}
					color={{
						buttonColor: UIColors.negativeDark,
						contentColor: 'white',
					}}
					key="manager-order-void-button"
					icon={DeleteOutlinedIcon}
					id="manager-order-void-button"
					onClick={onVoidOrder}
					text={translate('Order-Void')}
				/>,
			[classes.button, onVoidOrder, translate]
		);

		const buttons = useMemo(
			() =>
			{
				if (routingWaiterMode)
				{
					const unPreparedVisibleOrderLines = waiterRoutingId === undefined
						? order.orderLines.filter(
						line =>
							isProductOrderLine(line) &&
							line.state === 'ORDERED',
					) ?? []
						: order.orderLines.filter(
						line =>
							isProductOrderLine(line) &&
							line.state === 'ORDERED' &&
							line.routingId === waiterRoutingId,
					) ?? [];

					const notPickedUpVisibleOrderLines = waiterRoutingId === undefined
						? order.orderLines.filter(
						line =>
							isProductOrderLine(line) &&
							line.state !== 'PICKED_UP',
					) ?? []
						: order.orderLines.filter(
						line =>
							isProductOrderLine(line) &&
							line.state !== 'PICKED_UP' &&
							line.routingId === waiterRoutingId,
					) ?? [];

					const buttons: JSX.Element[] = [];

					if (
						order.state === 'handled' &&
						unPreparedVisibleOrderLines.length > 0 &&
						preparedButton !== undefined
					)
					{
						buttons.push(preparedButton);
					}

					if (
						(
							(order.state === 'handled' && unPreparedVisibleOrderLines.length === 0)
							||
							order.state === 'prepared'
						) &&
						notPickedUpVisibleOrderLines.length > 0 &&
						pickedUpButton !== undefined
					)
					{
						buttons.push(pickedUpButton);
					}

					buttons.push(infoButton);
					buttons.push(backButton);

					return <>
						{buttons}
					</>;
				}
				else
				{
					switch (order.state)
					{
						case 'ordered':
							return <>
								{deliveredButton}
								{pickedUpButton}
								{preparedButton}
								{handleButton}
								{voidButton}
								{divider}
								{infoButton}
								{backButton}
							</>;
						case 'handled':
							return <>
								{deliveredButton}
								{pickedUpButton}
								{preparedButton}
								{
									(preparedButton !== null || pickedUpButton !== null || deliveredButton !== null) &&
									<>
										{voidButton}
										{divider}
									</>
								}
								{infoButton}
								{backButton}
							</>;
						case 'prepared':
							return <>
								{deliveredButton}
								{pickedUpButton}
								{
									(pickedUpButton !== null || deliveredButton !== null) &&
									<>
										{voidButton}
										{divider}
									</>
								}
								{infoButton}
								{backButton}
							</>;
						case 'pickedUp':
							return <>
								{deliveredButton}
								{
									deliveredButton !== null &&
									<>
										{voidButton}
										{divider}
									</>
								}
								{infoButton}
								{backButton}
							</>;
						default:
							return <>
								{infoButton}
								{backButton}
							</>;
					}
				}
			},
			[backButton, deliveredButton, divider, handleButton, infoButton, order.orderLines, order.state, pickedUpButton, preparedButton, routingWaiterMode, voidButton, waiterRoutingId]
		);

		return <Popover
			anchorReference="anchorPosition"
			anchorPosition={anchorPosition && {
				left: anchorPosition.x,
				top: anchorPosition.y,
			}}
			marginThreshold={8}
			onClose={onClose}
			open={anchorPosition !== undefined}
			transformOrigin={{
				horizontal: 'center',
				vertical: 'top',
			}}
		>
			<div
				className={classes.root}
				style={style}
			>
				{buttons}
			</div>
		</Popover>;
	};