import { Avatar, Checkbox, Theme, Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FC, useCallback, useContext } from 'react';
import { useLocalizer } from '../../../../../../../../../Bridge/Localization/useLocalizer';
import { getFileUrl } from '../../../../../../../../../Util/Api/Resources/getFileUrl';
import { CommaSeparatedList } from '../../../../../../../../UI/CommaSeparatedList';
import PriceTag from '../../../../../../../../UI/PriceTag/PriceTag';
import { BusinessContextRef } from '../../../../../../BusinessContext';
import { InitializedCartLine } from '../../../model/InitializedCartLine';

interface StylesProps
{
	leftMargin: number
	rightMargin: number
	thumbnailHorizontalMargins: number
}

const useStyles =
	makeStyles(
		(theme: Theme) => ({
			Checkbox: {
				marginRight: 8,
			},
			ShoppingCartConfiguration:
				({leftMargin, rightMargin}: StylesProps) => ({
					width: '100%',
					zIndex: 1500,
					paddingTop: '0 !important',
					paddingBottom: '0 !important',
					paddingLeft: `${leftMargin}px !important`,
					paddingRight: `${rightMargin}px !important`,
					cursor: 'pointer',

					transition: theme.transitions.create('background-color', {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.standard,
					}),

					'& *': {
						cursor: 'pointer',
					},

					'&:hover': {
						backgroundColor: 'rgba(0,0,0,0.05)',
					},
				}),
			AvatarContainer:
				({thumbnailHorizontalMargins, leftMargin}: StylesProps) => ({
					flex: '0 0 auto',
					paddingTop: 8,
					paddingBottom: 8,
					marginLeft: -leftMargin,
					paddingLeft: thumbnailHorizontalMargins,
					paddingRight: thumbnailHorizontalMargins,
					verticalAlign: 'middle',
				}),
			DescriptionContainer: {
				flex: '1 1 auto',
				verticalAlign: 'middle',
				paddingTop: 8,
				paddingBottom: 8,
				paddingRight: 16,
			},
			ShoppingCartConfigurationAvatar: {},
			OptionGridContainer: {},
			OptionGridItem: {},
			helperText: {
				color: theme.palette.error.main,
				fontSize: 'smaller !important',
			},
		})
	);

interface PeerShoppingCartLineProps
{
	line: InitializedCartLine;
	selected?: boolean;
	onSelect: (line: InitializedCartLine) => void;
	onDeselect: (line: InitializedCartLine) => void;
}

export const PeerShoppingCartLine: FC<PeerShoppingCartLineProps> =
	({
		line,
		selected,
		onSelect,
		onDeselect,
	}) =>
	{
		const classes =
			useStyles({
				leftMargin: 8,
				rightMargin: 8,
				thumbnailHorizontalMargins: 12
			});
		const {businessStore} = useContext(BusinessContextRef);
		const localizer = useLocalizer();
		const toggle =
			useCallback(
				(event: React.MouseEvent) =>
				{
					event.stopPropagation();

					if (selected)
					{
						onDeselect(line);
					}
					else
					{
						onSelect(line);
					}
				},
				[
					selected,
					onDeselect,
					onSelect,
					line,
				]
			);

		return <ListItem
			dense
			divider
			className={classes.ShoppingCartConfiguration}
			onClick={toggle}
		>
			<Checkbox
				className={classes.Checkbox}
				checked={selected}
			/>
			{
				line.product.imageUrl &&
				<div
					className={classes.AvatarContainer}
				>
					<Avatar
						src={getFileUrl(line.product.imageUrl)}
						className={classes.ShoppingCartConfigurationAvatar}
					/>
				</div>
			}
			<div
				className={classes.DescriptionContainer}
			>
				<Typography
					variant="body1"
					color="textPrimary"
				>
					{line.quantity}× {line.product.name}
				</Typography>
				<Typography
					variant="body1"
					color="textSecondary"
				>
					<CommaSeparatedList
						items={
							line.variants.map(
								variant =>
									variant.variant.name
							)
						}
					/>
				</Typography>
			</div>
			<PriceTag
				price={line.totalPrice}
				localizer={localizer}
				currencyCode={businessStore.business.productCurrencyCode}
				style={{
					display: 'inline-block',
				}}
				color="textSecondary"
				component="span"
			/>
		</ListItem>;
	};