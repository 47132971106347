import { makeAutoObservable } from 'mobx';
import { SerializationProfile } from '../../Util/Serialization/Serialization';

export class PostalCodeRange
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	fromIncl: number;
	toIncl: number;

	constructor()
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});
	}

	/*---------------------------------------------------------------*
	 *                         Business Logic                        *
	 *---------------------------------------------------------------*/

	contains(postalCode: number): boolean
	{
		return postalCode >= this.fromIncl && postalCode <= this.toIncl;
	}
}

export const PostalCodeRangeProfile = SerializationProfile.create(PostalCodeRange);