import * as React from 'react';
import { CSSProperties, FC, useMemo } from 'react';
import { Color } from '../../../Api/Other/Color';
import { Image } from '../Image/Image';
import { ImageFit } from '../Image/ImageFit';
import { normalizeMediaButtonHeight } from './normalizeMediaButtonHeight';

const DefaultHeight = window.devicePixelRatio * 768;
const DefaultWidth = window.devicePixelRatio * 1024;

interface MediaButtonImageProps
{
	className?: string;
	fit: ImageFit;
	image: string;
	minImageHeight?: number;
	minImageWidth?: number;
	style?: CSSProperties;
	textBackdropColor?: Color;
	onStartLoading?: () => void;
	onEndLoading?: () => void;
}

export const MediaButtonImage: FC<MediaButtonImageProps> =
	({
		image,
		minImageHeight,
		minImageWidth,
		style,
		...otherProps
	}) =>
	{
		const normalizedWidth = useMemo(
			() =>
				normalizeMediaButtonHeight(minImageWidth ?? DefaultWidth),
			[minImageWidth],
		);

		const normalizedHeight = useMemo(
			() =>
				normalizeMediaButtonHeight(minImageHeight ?? DefaultHeight),
			[minImageHeight],
		);

		return <Image
			compression="Medium"
			height={normalizedHeight}
			path={image}
			style={{
				flexGrow: 1,
				position: 'relative',
				...style,
			}}
			width={normalizedWidth}
			{...otherProps}
		/>;
	};
