import '@adyen/adyen-web/dist/adyen.css';
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { FC, useEffect, useMemo, useRef } from 'react';
import { usePaymentIssuerContext } from '../../../issuer/context/PaymentIssuerContext';
import { usePaymentMethodContext } from '../../../method/context/PaymentMethodContext';
import { adyenCheckoutPaymentMethodRequiresUserInput } from '../../../method/form/editor/settings/adyenCheckoutPaymentMethodRequiresUserInput';
import { useAdyenCheckoutContext } from '../AdyenCheckoutContext';
import { useAdyenCheckoutSettingsComponent } from './useAdyenCheckoutSettingsComponent';

const useStyles = makeStyles(theme => ({
	root: {
		'& .adyen-checkout__input, & .adyen-checkout__dropdown__button': {
			borderRadius: theme.shape.borderRadius,
			borderColor: `rgba(0, 0, 0, 0.12) !important`,
			backgroundColor: 'transparent',
			height: 62,
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
			fontSize: '1rem !important',
		},
		'& .adyen-checkout__input--focus': {
			border: `1px solid ${theme.palette.primary.main}`,
			borderColor: theme.palette.primary.main,
			boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
		},
		'& .adyen-checkout__dropdown__button:focus-within, & .adyen-checkout__dropdown__button:active': {
			// height: '0px !important',
			boxShadow: 'none !important',
			border: '1px solid rgba(0, 0, 0, 0.12) !important',
		},
		'& .adyen-checkout__dropdown__list': {
			position: 'relative !important',
			maxHeight: 'none !important',
			boxShadow: 'none !important',
			marginLeft: '-16px !important',
			marginRight: '-16px !important',
			width: 'auto !important',
			backgroundColor: '#fafafa',
			marginTop: '-62px !important',
			marginBottom: '-14px !important',
			'& .adyen-checkout__dropdown__element': {
				borderTop: '1px solid rgba(0, 0, 0, 0.12) !important',
			},
		},
		'& .adyen-checkout__dropdown__element': {
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
			fontSize: '1rem !important',
			color: 'rgba(0, 0, 0, 0.87) !important',
		},
		'& .adyen-checkout__label__text': {
			paddingBottom: theme.spacing(2),
		},
		'& .adyen-checkout__label--focused': {
			'& span': {
				color: `${theme.palette.primary.main} !important`,
			},
		},
		'& .adyen-checkout__label__text, & .adyen-checkout__checkbox__label': {
			color: 'rgba(0, 0, 0, 0.54) !important',
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
			fontSize: '1rem',
		},
		'& .adyen-checkout__checkbox__input:checked + .adyen-checkout__checkbox__label::after': {
			backgroundColor: `${theme.palette.primary.main} !important`,
			borderWidth: '0px !important',
			boxShadow: 'none !important',
		},
		'& .adyen-checkout__checkbox__input:checked:hover + .adyen-checkout__checkbox__label::after, & .adyen-checkout__checkbox__input:checked:focus + .adyen-checkout__checkbox__label::after, & .adyen-checkout__checkbox__input:checked:active + .adyen-checkout__checkbox__label::after': {
			borderWidth: '0px !important',
			boxShadow: 'none !important',
		},
		'& .adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label::after, & .adyen-checkout__checkbox__input:hover + .adyen-checkout__checkbox__label::after, & .adyen-checkout__checkbox__input:focus + .adyen-checkout__checkbox__label::after, & .adyen-checkout__checkbox__input:active + .adyen-checkout__checkbox__label::after': {
			border: '2px solid rgba(0, 0, 0, 0.54) !important',
			borderRadius: '2px !important',
			boxShadow: 'none !important',
		},
	},
}));

interface AdyenCheckoutPaymentSettingsProps
{
	className?: string;
}

export const AdyenCheckoutPaymentSettings: FC<AdyenCheckoutPaymentSettingsProps> =
	({
		className,
	}) =>
	{
		const {activeComponent, setPaymentSettingsHasError} = useAdyenCheckoutContext();
		const {selectedPaymentMethod} = usePaymentMethodContext();
		const {selectedPaymentIssuer} = usePaymentIssuerContext();
		const classes = useStyles();

		const requiresUserInput = useMemo(
			() => adyenCheckoutPaymentMethodRequiresUserInput(selectedPaymentMethod),
			[selectedPaymentMethod],
		);

		const containerRef = useRef<HTMLDivElement>(null);

		useAdyenCheckoutSettingsComponent(containerRef);

		useEffect(() =>
		{
			if (
				activeComponent?.props.issuers !== undefined
				&& selectedPaymentIssuer !== undefined
				&& activeComponent.props.issuer !== selectedPaymentIssuer?.id
			)
				activeComponent.update({issuer: selectedPaymentIssuer?.id});
		}, [activeComponent, selectedPaymentIssuer]);

		useEffect(() =>
		{
			if (!requiresUserInput)
				setPaymentSettingsHasError(false);
		}, [requiresUserInput, setPaymentSettingsHasError]);

		const rootClassName = useMemo(() =>
		{
			if (requiresUserInput)
			{
				if (className === undefined)
					return classes.root;
				else
					return `${classes.root} ${className}`;
			}
		}, [className, classes.root, requiresUserInput]);

		return <div
			className={rootClassName}
			ref={containerRef}
		/>;
	};