import { Card, Divider, isWidthUp, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FC, ReactNode, useMemo } from 'react';
import { OrderEvent } from '../../../../../../../Api/Order/OrderEvent';
import { useLocalizer } from '../../../../../../../Bridge/Localization/useLocalizer';
import { useScreenWidth } from '../../../../../../../Util/Hooks/useScreenWidth';
import { getOrderEventTypeLanguageEntry } from '../localization';
import { OrderEventDetails } from './OrderEventDetails';

export const ORDER_EVENT_LONG_VALUE_THRESHOLD = 80;

const useStyle = makeStyles((theme: Theme) => ({
	card: {
		flex: '1 1 auto',
	},
	cardSegment: {
		margin: theme.spacing(2),
	},
	header: {
		flex: '1 1 auto',
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
	},
	cardHeaderVerticalDivider: {
		marginBottom: -8,
		marginTop: -8,
		marginLeft: 0,
		marginRight: 'auto',
	},
	primaryContent: {
		display: 'flex',
	},
	summary: {
		fontStyle: 'italic',
	},
}));

interface OrderEventComponentProps
{
	orderEvent: OrderEvent;
	summary?: ReactNode | string;
}

export const OrderEventComponent: FC<OrderEventComponentProps> =
	(
		{
			orderEvent,
			summary,
		},
	) =>
	{
		const localizer = useLocalizer();

		const classes = useStyle();

		const screenWidth = useScreenWidth();

		const isMediumScreen = useMemo(() => isWidthUp('md', screenWidth), [screenWidth]);

		const typeComponent = useMemo(
			() => <Typography>{localizer.translate(getOrderEventTypeLanguageEntry(orderEvent.type))}</Typography>,
			[localizer, orderEvent.type],
		);

		const eventSpecificContent = useMemo(
			() => isMediumScreen &&
				(
					typeof summary === 'string'
						? <Typography className={classes.summary} color="textSecondary">{summary}</Typography>
						: summary
				),
			[classes.summary, isMediumScreen, summary],
		);

		return <Card className={classes.card}>
			<div className={`${classes.cardSegment} ${classes.header}`}>
				{typeComponent}
				{eventSpecificContent}
			</div>
			<Divider/>
			<div className={classes.cardSegment}>
				<OrderEventDetails orderEvent={orderEvent} />
			</div>
		</Card>;
	};