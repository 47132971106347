import Decimal from 'decimal.js';
import { OrderDescriptor } from '../../../../Api/Order/OrderDescriptor';
import { hasPayment } from '../../../../Util/Orders/hasPayment';
import { isFree } from '../../../../Util/Orders/isFree';

export function canShareOrderPaymentWithTikkie(order: OrderDescriptor): boolean
{
	const orderHasPayment = hasPayment(order);

	return order.paymentGateway === 'Tikkie'
		&& order.isSharePaymentSupported
		&& (!orderHasPayment || (orderHasPayment && order.paymentState === 'paid'))
		// Tikkie only allows EUR
		&& order.paymentCurrency === 'EUR'
		&& !isFree(order);
}

export async function getShareOrderPaymentWithTikkieUrl(
	order: OrderDescriptor,
	message: string,
	numberOfPersons: number,
): Promise<string>
{
	if (process.env.REACT_APP_TIKKIE_CLIENT_ID === undefined)
	{
		throw new Error('App is not configured to share payments with Tikkie');
	}
	else if (order.paymentPrice !== undefined && order.paymentPrice > 0)
	{
		const params = new URLSearchParams({
			clientId: process.env.REACT_APP_TIKKIE_CLIENT_ID,
			description: encodeURIComponent(
				message
					// Tikkie allows 35 characters in the description (including unicode)
					.slice(0, 35),
			),
			numberOfPersons: numberOfPersons
				.toString(),
			totalAmountInCents: new Decimal(order.paymentPrice)
				.div(numberOfPersons + 1)
				.mul(numberOfPersons)
				.mul(100)
				.round()
				.toString(),
		});

		return `tikkie://payment_request?${params}`;
	}
	else
	{
		throw new Error(`Order ${order.uuid} has no valid payment to share`);
	}
}
