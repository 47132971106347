import { Typography, useTheme } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { ChangeEvent, FC, useEffect } from 'react';
import { PinCodeInputStore } from './PinCodeInputStore';

interface PinCodeInputProps
{
	store: PinCodeInputStore;
}

export const PinCodeInput: FC<PinCodeInputProps> =
	({
		store,
	}) =>
	{
		const theme = useTheme();

		const changeDigit = useObserver(() => store.changeDigit);
		const entersUI = useObserver(() => store.entersUI);
		const focussed = useObserver(() => store.focussed);
		const inputValues = useObserver(() => store.inputValues);
		const length = useObserver(() => store.length);
		const onInputBlur = useObserver(() => store.onInputBlur);
		const onInputClick = useObserver(() => store.onInputClick);
		const onInputFocus = useObserver(() => store.onInputFocus);
		const textInputs = useObserver(() => store.textInputs);
		const values = useObserver(() => store.values);

		useEffect(() =>
		{
			entersUI(true);
		}, [entersUI]);

		return <div
			style={{
				position: 'relative',
				display: 'inline-block',
				width: 31 * length + 10 * (length - 1),
				height: 49,
			}}
		>
			{
				values
					.map(
						(value, i) =>
							<span key={i}>
								<div
									style={{
										borderColor: i === focussed ? theme.palette.primary.main : '#e0e0e0',
										borderRadius: 3,
										borderStyle: 'solid',
										borderWidth: i === focussed ? 2 : 1,
										height: 49,
										left: i * (31 + 10),
										paddingTop: i === focussed ? 0 : 1,
										position: 'absolute',
										top: 1,
										width: 31,
									}}
								>
									<Typography
										variant="body1"
										color="textSecondary"
										style={{
											lineHeight: '49px',
										}}
									>
										{values[i]}
									</Typography>
								</div>
								<input
									className="PinCode"
									ref={textInputs[i]}
									// error={store.showValidation && store.validation.length > 0}
									// onBlur={() => store.setShowValidation(true)}
									value={inputValues[i]}
									onClick={() => onInputClick(i)}
									onFocus={() => onInputFocus(i)}
									onBlur={() => onInputBlur(i)}
									style={{
										width: 31 + (length - 1 - i) * (31 + 10),
										height: 49,
										borderRadius: 2,
										position: 'absolute',
										top: 0,
										left: i * (31 + 10),
										backgroundColor: '#0000',
										zIndex: 2000 + i,
										color: '#0000',
										caretColor: 'black',
										borderStyle: 'none',
										borderWidth: 0,
										paddingLeft: 11,
										paddingRight: 8,
										paddingTop: 16,
										paddingBottom: 16,
										fontFamily: 'monospace',
										letterSpacing: 31.7,
										lineHeight: '17px',
										fontSize: 17,
										outline: 'none',
										// fontFamily: this.props.theme.typography.body1.fontFamily,
									}}
									onChange={() => {}}
									onInput={(event: ChangeEvent<HTMLInputElement>) => changeDigit(i, event.target.value)}
									type="tel"
								/>
							</span>,
					)
			}
		</div>;
	};
