import { makeStyles, Typography } from '@material-ui/core';
import * as  React from 'react';
import { FC, useMemo } from 'react';
import { useTranslate } from '../../../../../../Bridge/Localization/useTranslate';
import { AppleAppStoreBadge } from '../../../../../../Util/app_store/AppleAppStoreBadge';
import { GooglePlayStoreBadge } from '../../../../../../Util/app_store/GooglePlayStoreBadge';
import { isAndroid } from '../../../../../../Util/environment/isAndroid';
import { isApple } from '../../../../../../Util/environment/isApple';
import { PaymentSharingProvider } from '../PaymentSharingProviders';

const useStyles = makeStyles(theme => ({
	appleButton: {
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
	},
	googleButton: {
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
	},
	label: {
		fontSize: `calc(${theme.typography.body1.fontSize} - 0.2rem)`,
	},
	root: {
		alignItems: 'center',
		display: 'flex',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
}));

interface PaymentSharingDialogDownloadSectionProps
{
	appName: string;
	appleAppStoreUrl: string;
	googlePlayStoreUrl: string;
	provider?: PaymentSharingProvider;
}

export const PaymentSharingDialogDownloadSection: FC<PaymentSharingDialogDownloadSectionProps> =
	({
		appName,
		appleAppStoreUrl,
		googlePlayStoreUrl,
	}) =>
	{
		const translate = useTranslate();

		const classes = useStyles();

		const appStoreBadge = useMemo(
			() =>
				isApple()
					?
					<AppleAppStoreBadge
						className={classes.appleButton}
						link={appleAppStoreUrl}
					/>
					:
					isAndroid()
						?
						<GooglePlayStoreBadge
							className={classes.googleButton}
							link={googlePlayStoreUrl}
						/>
						:
						undefined,
			[appleAppStoreUrl, classes.appleButton, classes.googleButton, googlePlayStoreUrl],
		);

		if (appStoreBadge === undefined)
		{
			return null;
		}
		else
		{
			return <>
				<Typography
					className={classes.label}
					color="textSecondary"
				>
					{translate('Client-OrderHistory-Share-Order-Payment-Download-App-Label', appName)}
				</Typography>
				{appStoreBadge}
			</>;
		}
	};
