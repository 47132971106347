import { QRCode } from '@intentic/qr-reader-web';
import * as URI from 'urijs';

let validHosts: Array<string> =
    [
        'butl.nl'
    ];

export default function getPlaceCodeHashFromQrCode(code: QRCode): string | undefined
{
    if (code)
    {
        const url = URI.parse(code.data);

        if (url.hostname && validHosts.indexOf(url.hostname) > -1)
        {
            if (url.path)
            {
                return url.path.slice(1);
            }
            else
            {
                return undefined;
            }
        }
        else
        {
            return undefined;
        }
    }
    else
    {
        return undefined;
    }
}