import { makeAutoObservable } from 'mobx';
import { CouponDescriptor } from './CouponDescriptor';
import { CouponLineMatch } from './CouponLineMatch';

export class CouponMatch
{
    code: string;
    coupon: CouponDescriptor;
    discount: number;
    readonly lines: CouponLineMatch[];

    constructor()
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
    }
}
