import { Color } from '../../../../Api/Other/Color';
import { MediaButtonBarTextMode } from './MediaButtonBarTextMode';
import { useEffectiveTextColor } from './useEffectiveTextColor';

export function useEffectiveTextColorCss(
	textMode: MediaButtonBarTextMode,
	textColor?: Color
): string
{
	const effectiveTextColor =
		useEffectiveTextColor(
			textMode,
			textColor
		);

	return effectiveTextColor.css;
}
