import Decimal from 'decimal.js';
import { FC, useMemo } from 'react';
import { OrderLineDescriptor } from '../../../../../../Api/Order/OrderLineDescriptor';
import { GenericHistoricalItem } from '../GenericHistoricalItem';

interface HistoricalOrderLineItemProps
{
	currency: string;
	line: OrderLineDescriptor;
}

export const HistoricalOrderLineItem: FC<HistoricalOrderLineItemProps> =
	({
		currency,
		line,
	}) =>
	{
		const amount = useMemo(() =>
		{
			return new Decimal(line.price)
				.mul(line.quantity)
				.toNumber();
		}, [line.price, line.quantity]);

		switch (line.type)
		{
			case 'external_bill':
				return <GenericHistoricalItem
					description={line.description}
					descriptionSecondary={line.descriptionSecondary}
					quantity={line.quantity}
					price={{
						amount,
						currency,
					}}
				/>;
			case 'fee':
			{
				if (line.feeType === 'DELIVERY')
				{
					return <GenericHistoricalItem
						description={line.description}
						quantity={line.quantity}
						price={{
							amount,
							currency,
						}}
						translateZeroPriceAmount
					/>;
				}
				else
				{
					return <GenericHistoricalItem
						description={line.description}
						price={{
							amount,
							currency,
						}}
					/>;
				}
			}
			case 'product':
			{
				return <GenericHistoricalItem
					description={line.description}
					descriptionSecondary={
						line.variants.length === 0
							? undefined
							: line
								.variants
								.map(
									variant =>
										variant.description,
								)
								.join(', ')
					}
					imageUrl={line.imageUrl}
					quantity={line.quantity}
					price={{
						amount,
						currency,
					}}
				/>;
			}
			case 'request_bill':
			case 'request_waiter':
			case 'tip':
			case 'tax':
				return <GenericHistoricalItem
					description={line.description}
					price={{
						amount,
						currency,
					}}
				/>;
			case 'product_fee':
			default:
				return <GenericHistoricalItem
					description={line.description}
					quantity={line.quantity}
					price={{
						amount,
						currency,
					}}
				/>;
		}
	};
