import { Box } from '@material-ui/core';
import { runInAction } from 'mobx';
import { observer, useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext, useState } from 'react';
import { useComputed } from '../../../../../../Util/Hooks/useComputed';
import { CenteredPageContent } from '../../../../../UI/CenteredPageContent';
import { BusinessContextRef } from '../../../BusinessContext';
import { useCallbackOnClickAddToCart } from '../../Dialog/useCallbackOnClickAddToCart';
import { ProductConfigurationStore } from '../../ProductConfiguration/ProductConfigurationStore';
import { RecommendedProductConfiguration } from '../../ProductConfiguration/RecommendedProductConfiguration/RecommendedProductConfiguration';
import { ProductStore } from '../../ProductStore';
import { ProductDrawerFeatureConfiguration } from './Feature/ProductDrawerFeatureConfiguration';
import { ProductDrawerConfigurationPrice } from './Price/ProductDrawerConfigurationPrice';
import { ProductDrawerConfigurationStepBackwardButton } from './StepBackwardButton/ProductDrawerConfigurationStepBackwardButton';
import { ProductDrawerConfigurationStepForwardButton } from './StepForwardButton/ProductDrawerConfigurationStepForwardButton';
import { ProductDrawerConfigurationStepStatus } from './StepStatus/ProductDrawerConfigurationStepStatus';

interface MenuCarouselProductConfigurationProps
{
	productStore: ProductStore;
	onClose: () => void;
	configurationStore?: ProductConfigurationStore;
}

export interface ProductDrawerConfigurationProps
{
	id: string | number;
	component: () => React.ReactNode;
	onBeforeNext: () => boolean;
}

export const ProductDrawerConfiguration: FC<MenuCarouselProductConfigurationProps> =
	observer(
		(
			{
				productStore,
				onClose,
				configurationStore,
			},
		) =>
		{
			const {businessStore} = useContext(BusinessContextRef);
			const [stepIdx, setStepIdx] = useState(0);
			const recommendationLists =
				useObserver(
					() =>
					{
						if (configurationStore?.recommendProducts
							&& businessStore.hasProductRecommendations(productStore.product.id, 'AT_PRODUCT_OPTIONS'))
						{
							return businessStore.getProductRecommendationLists(productStore.product.id)
								.filter(
									list =>
										list.moment === 'AT_PRODUCT_OPTIONS'
								);
						}
						else
						{
							return [];
						}
					}
				);
			const steps =
				useComputed<ProductDrawerConfigurationProps[]>(
					() => [
						...(configurationStore?.visibleConfigurationStores ?? []).map(
							featureStore => ({
								id: featureStore.productFeature.id,
								component:
									() =>
										<ProductDrawerFeatureConfiguration
											configurationStore={configurationStore}
											featureStore={featureStore}
										/>,
								onBeforeNext:
									() =>
									{
										runInAction(
											() =>
												featureStore.showValidation = true
										);

										return featureStore.isValid;
									}
							})
						),
						...recommendationLists.map(
							list => ({
								id: list.uuid,
								component:
									() =>
										<RecommendedProductConfiguration
											productConfigurationStore={configurationStore!}
											productConfigurationList={list}
										/>,
								onBeforeNext: () => true,
							})
						)
					],
					[
						configurationStore,
						recommendationLists,
					]
				);
			const onClickAddToCart =
				useCallbackOnClickAddToCart(
					productStore,
					onClose
				);
			const isFinalStep = steps.length === 0 || stepIdx === steps.length - 1;

			return <Box
				paddingY={7}
				paddingX={4}
				sx={{
					minWidth: 'min(500px, 100vw)',
				}}
			>
				{
					steps.map(
						(step, idx) =>
							<Box
								key={step.id}
								sx={{
									display:
										stepIdx === idx
											? undefined
											: 'none',
								}}
							>
								{step.component()}
							</Box>
					)
				}
				<CenteredPageContent>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						{
							steps.length > 1 &&
							<Box
								paddingTop={2}
								paddingBottom={6}
							>
								<ProductDrawerConfigurationStepStatus
									numberOfSteps={steps.length}
									stepIdx={stepIdx}
								/>
							</Box>
						}
						<Box>
							{
								!(isFinalStep && !productStore.showOrderButton) &&
								<>
									<Box
										sx={{
											position: 'relative',
										}}
									>
										{
											stepIdx > 0 &&
											<Box
												sx={{
													position: 'absolute',
													top: 0,
													left: -140,
													width: 125,
													height: 53,
												}}
											>
												<ProductDrawerConfigurationStepBackwardButton
													onClick={
														() =>
															setStepIdx(
																idx =>
																	idx - 1
															)
													}
												/>
											</Box>
										}
										<ProductDrawerConfigurationStepForwardButton
											onClick={
												async () =>
												{
													if (steps.length === 0 || steps[stepIdx].onBeforeNext())
													{
														const nextStepIdx = stepIdx + 1;

														if (nextStepIdx >= steps.length)
														{
															await onClickAddToCart();
														}
														else
														{
															setStepIdx(nextStepIdx);
														}
													}
												}
											}
											finalStep={isFinalStep}
										/>
									</Box>
								</>
							}
						</Box>
						{
							productStore.showPriceDisplay && configurationStore &&
							<Box
								paddingTop={4}
							>
								<ProductDrawerConfigurationPrice
									productStore={productStore}
									configurationStore={configurationStore}
								/>
							</Box>
						}
					</Box>
				</CenteredPageContent>
			</Box>;
		}
	);
