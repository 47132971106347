import { makeStyles } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as  React from 'react';
import { FC, useEffect, useState } from 'react';
import { OrderDescriptor } from '../../../../../Api/Order/OrderDescriptor';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { PageDialog, PageDialogProps } from '../../../../UI/PageDialog';
import { PaymentSharingDialogActions } from './actions/PaymentSharingDialogActions';
import { TikkiePaymentSharingContent } from './content/TikkiePaymentSharingContent';
import { PaymentSharingProvider, PaymentSharingProviders } from './PaymentSharingProviders';

const useStyles = makeStyles(theme => ({
	content: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		height: '100%',
	},
	lowerContent: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		padding: theme.spacing(2),
	},
}));

interface PaymentSharingDialogProps extends Omit<PageDialogProps, 'open' | 'title'>
{
	onClose: () => void;
	order?: OrderDescriptor;
}

export const PaymentSharingDialog: FC<PaymentSharingDialogProps> =
	({
		order,
		...otherProps
	}) =>
	{
		const translate = useTranslate();

		const [provider] = useState<PaymentSharingProvider | undefined>(PaymentSharingProviders[0]);

		const orderPlaceSessionNumberOfCovers = useObserver(() => order?.numberOfCovers);

		const [numberOfPersons, setNumberOfPersons] = useState(
			() =>
				orderPlaceSessionNumberOfCovers === undefined
					? 1
					: orderPlaceSessionNumberOfCovers - 1,
		);

		useEffect(
			() =>
			{
				if (order !== undefined)
				{
					setNumberOfPersons(
						orderPlaceSessionNumberOfCovers === undefined
							? 1
							: orderPlaceSessionNumberOfCovers - 1,
					);
				}
			},
			[order, orderPlaceSessionNumberOfCovers],
		);

		const classes = useStyles();

		return <PageDialog
			dialogActions={
				order !== undefined &&
				<PaymentSharingDialogActions
					numberOfPersons={numberOfPersons}
					order={order}
					provider={provider}
				/>
			}
			classes={{
				content: classes.content,
			}}
			contentPaddingBottom={0}
			contentPaddingLeft={0}
			contentPaddingRight={0}
			contentPaddingTop={0}
			open={order !== undefined}
			title={translate('Client-OrderHistory-Share-Order-Payment')}
			{...otherProps}
		>
			{
				provider === 'Tikkie' &&
				<TikkiePaymentSharingContent
					numberOfPersons={numberOfPersons}
					onNumberOfPersonsChange={setNumberOfPersons}
					order={order}
				/>
			}
		</PageDialog>;
	};
