import { useCallback, useContext } from 'react';
import { CurrentOrderService } from '../../../../../Service/CurrentOrder/CurrentOrderService';
import { BusinessContextRef } from '../../BusinessContext';
import { BusinessStore } from '../../BusinessStore';
import { ProductStore } from '../ProductStore';
import { doesProductHaveConfigurationScreenBeforeAddingItToCart } from './useDoesProductHaveConfigurationScreenBeforeAddingItToCart';

export function useCallbackOnClickAddToCart(
	store: ProductStore,
	onClose?: () => Promise<void>
)
{
	const {
		businessStore,
		currentOrderService,
	} = useContext(BusinessContextRef);

	return useCallback(
		() => 
			onClickAddToCart(
				store,
				businessStore,
				currentOrderService,
				onClose
			), 
		[
			businessStore,
			currentOrderService,
			onClose,
			store
		]
	);
}

export async function onClickAddToCart(
	store: ProductStore,
	businessStore: BusinessStore,
	currentOrderService: CurrentOrderService,
	onClose?: () => Promise<void>
): Promise<void>
{
	const productId = store.product.id;
	const configurationStore = store.configurationStore;
	const isConfigurable = doesProductHaveConfigurationScreenBeforeAddingItToCart(store.product, businessStore);
	const hasAfterProductAddRecommendations = businessStore.hasProductRecommendations(
		productId,
		'AFTER_PRODUCT_ADD',
	);
	let shouldOpenRecommendations = true;

	if (configurationStore)
	{
		const resultingShoppingCartLines = configurationStore.requestValidShoppingCartLines(store.amountToAdd);
		if (resultingShoppingCartLines !== undefined)
		{
			resultingShoppingCartLines
				.forEach(({productConfiguration, quantity}) => currentOrderService.add(
					productConfiguration,
					quantity
				));

			if (onClose)
			{
				await onClose();
			}
			else
			{
				await store.closeStore();
			}
		}
	}
	else
	{
		if (isConfigurable)
		{
			shouldOpenRecommendations = false;
			store.openConfigurationMode();
		}
		else
		{
			await Promise.all([
				store.orderWithoutConfiguration(store.amountToAdd),
				onClose
					? onClose()
					: store.close(),
			]);
		}
	}

	if (hasAfterProductAddRecommendations && shouldOpenRecommendations)
	{
		await businessStore.openProductRecommendations(productId);
	}
}
