import { Card, CardActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FC, useCallback } from 'react';
import { ProductConfiguration } from '../../../../../../../Api/Product/ProductConfiguration';
import { useTranslate } from '../../../../../../../Bridge/Localization/useTranslate';
import { ActionButton } from '../../../../../../UI/action-button/ActionButton';
import { ProductConfiguration as ProductConfigurationComponent } from '../../../../Product/ProductConfiguration/ProductConfiguration';
import { ProductConfigurationStore } from '../../../../Product/ProductConfiguration/ProductConfigurationStore';

const useStyles = makeStyles(() => ({
	card: {
		margin: 8,
	},
	cardActions: {
		justifyContent: 'flex-end',
	},
}));

interface ComoBasketRequirementsProductConfigurationEditorProps
{
	productConfigurationStore: ProductConfigurationStore;
	onCommit: (productConfiguration: ProductConfiguration) => void;
	onCancel: () => void;
}

export const ComoBasketRequirementsProductConfigurationEditor: FC<ComoBasketRequirementsProductConfigurationEditorProps> =
	({
		productConfigurationStore,
		onCommit,
		onCancel,
	}) =>
	{
		const translate = useTranslate();

		const onAdd = useCallback(
			() =>
			{
				const productConfiguration = productConfigurationStore.requestValidProductConfiguration();

				if (productConfiguration !== undefined)
					onCommit(productConfiguration);
			},
			[onCommit, productConfigurationStore],
		);

		const classes = useStyles();

		return <Card
			className={classes.card}
		>
			<ProductConfigurationComponent
				store={productConfigurationStore}
			/>
			<CardActions className={classes.cardActions}>
				<ActionButton
					onClick={onCancel}
					text={translate('Generic-Cancel')}
					variant="outlined"
				/>
				<ActionButton
					onClick={onAdd}
					text={translate('Generic-Add')}
				/>
			</CardActions>
		</Card>;
	};
