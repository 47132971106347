import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { useLocalizer } from '../../../../../../../Bridge/Localization/useLocalizer';
import PriceTag from '../../../../../../UI/PriceTag/PriceTag';
import { ProductConfigurationStore } from '../../../ProductConfiguration/ProductConfigurationStore';
import { ProductStore } from '../../../ProductStore';
import { ProductDrawerPrice } from '../../Price/ProductDrawerPrice';

interface ProductDrawerConfigurationPriceProps
{
	productStore: ProductStore;
	configurationStore: ProductConfigurationStore;
}

export const ProductDrawerConfigurationPrice: FC<ProductDrawerConfigurationPriceProps> =
	observer(
		(
			{
				productStore,
				configurationStore,
			},
		) =>
		{
			const localizer = useLocalizer();

			return <ProductDrawerPrice>
				<PriceTag
					hideCurrencySymbols={productStore.place.productCurrencySymbolsHidden}
					price={configurationStore.price}
					localizer={localizer}
					currencyCode={productStore.business.productCurrencyCode}
					variant="body1"
					textPrepended={productStore.priceLabel!}
					style={{
						textAlign: 'center',
						fontWeight: 'inherit',
						fontSize: 'inherit',
						fontFamily: 'inherit',
						color: 'inherit',
					}}
				/>
			</ProductDrawerPrice>;
		}
	);
