import { useCallback } from 'react';
import { OrderPaymentProcessingResult } from '../model/OrderPaymentProcessingResult';

type ProcessPayPalOrderPaymentHandler = () => Promise<OrderPaymentProcessingResult>

export function useProcessPayPalOrderPayment(): ProcessPayPalOrderPaymentHandler
{
	return useCallback(
		async () =>
			({
				requiresExtraUserInteraction: true,
				state: 'Pending',
			}),
		[],
	);
}
