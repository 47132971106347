import { makeStyles, Typography, TypographyProps } from '@material-ui/core';
import clsx from 'clsx';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { OrderDescriptor } from '../../../../../Api/Order/OrderDescriptor';
import { subtractFrom } from '../../../../../Api/Util/time/Duration';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { useRootContext } from '../../../../../RootContext';
import { isPaymentTimingOut } from '../../../../../Util/Orders/isPaymentTimingOut';

const useStyles = makeStyles({
	text: {
		marginLeft: 10,
	},
});

export interface CollapsedHistoryTextProps extends TypographyProps
{
	compact: boolean;
	mostRecentOrder: OrderDescriptor;
	numberOfOrderPlaceToday: number;
	screenExtraNarrow: boolean;
}

export const CollapsedHistoryText: FC<CollapsedHistoryTextProps> =
	({
		className,
		compact,
		mostRecentOrder,
		numberOfOrderPlaceToday,
		screenExtraNarrow,
		...otherProps
	}) =>
	{
		const {clockService} = useRootContext(true);
		const translate = useTranslate();

		const currentMinute = useObserver(() => clockService.currentMinute);

		const orderStateLabel = useMemo(
			() =>
			{
				const clientFacingState = mostRecentOrder.state === 'ordered' || mostRecentOrder.state === 'scheduled'
					? 'ordered'
					: mostRecentOrder.state;


				if (mostRecentOrder.paymentState === 'failed')
				{
					return translate('Menu-Order-Payment-Failed');
				}
				else if (clientFacingState !== 'voided' && (mostRecentOrder.paymentState === 'created' || mostRecentOrder.paymentState === 'negotiated'))
				{
					const paymentIsTimingOut = isPaymentTimingOut(
						mostRecentOrder,
						subtractFrom(
							{
								seconds: 60,
								nanos: 0,
							},
							currentMinute,
						),
					);

					return translate(paymentIsTimingOut ? 'Menu-Order-Payment-Expired-Short' : 'Menu-Order-Ordered-InPayment');
				}
				else if (numberOfOrderPlaceToday === 1)
				{
					return compact
						? screenExtraNarrow
							? translate('Client-OrderHistory-Order', numberOfOrderPlaceToday.toString())
							: translate('Client-OrderHistory-OrderPlaced', numberOfOrderPlaceToday.toString())
						: translate('Client-OrderHistory-OrderPlaced', numberOfOrderPlaceToday.toString());
				}
				else
				{
					return compact
						? screenExtraNarrow
							? translate('Client-OrderHistory-Orders', numberOfOrderPlaceToday.toString())
							: translate('Client-OrderHistory-OrdersPlaced', numberOfOrderPlaceToday.toString())
						: translate('Client-OrderHistory-OrdersPlaced', numberOfOrderPlaceToday.toString());
				}
			},
			[compact, currentMinute, mostRecentOrder, numberOfOrderPlaceToday, screenExtraNarrow, translate],
		);

		const classes = useStyles();

		return <Typography
			className={clsx(classes.text, className)}
			{...otherProps}
		>
			{orderStateLabel}
		</Typography>;
	};
