import { CartLine } from '../../../Api/Order/Cart/CartLine';
import { CartLineVariant } from '../../../Api/Order/Cart/CartLineVariant';
import { groupBy } from '../../../Util/map/groupBy';

export function getDeduplicatedCartLines(
	lines: CartLine[]
)
{
	const linesBySignature =
		groupBy(
			lines,
			line =>
				getCartLineSignature(line)
		);

	return Array.from(linesBySignature.values())
		.map(
			lines =>
				combineLines(lines)
		);
}

function getCartLineSignature(
	line: CartLine
)
{
	return `${line.productId}.[${line.variants.map(variant => getCartLineVariantSignature(variant)).sort().join(';')}]`;
}

function getCartLineVariantSignature(
	variant: CartLineVariant
)
{
	return `${variant.assignmentId}.${variant.variantId}`;
}

function combineLines(
	lines: CartLine[]
)
{
	return new CartLine(
		lines[0].id,
		lines[0].productId,
		lines[0].variants,
		lines.reduce(
			(a, b) =>
				a + b.quantity,
			0
		)
	);
}