import { Button, makeStyles } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { useResizeObserver } from '../../../../../Util/Hooks/useResizeObserver';
import { useBottomBarFontSize, useBottomBarHeight } from '../../../../UI/session-status-bar/BottomBarConstants';
import { useAndRequireSessionStatusBarStore } from '../../../../UI/session-status-bar/SessionStatusBarStoreProvider';
import { CollapsedHistoryIcon } from './CollapsedHistoryIcon';
import { CollapsedHistoryText } from './CollapsedHistoryText';

const useStyles = makeStyles({
	button: {
		alignItems: 'center',
		cursor: 'pointer',
		display: 'flex',
		flex: '1 0 50%',
		paddingBottom: 0,
		paddingLeft: 18,
		paddingRight: 10,
		paddingTop: 0,
		textAlign: 'left',
		textTransform: 'none',
	},
});

export interface CollapsedHistoryProps
{
	compact?: boolean;
}

export const CollapsedHistory: FC<CollapsedHistoryProps> =
	({
		compact = false,
	}) =>
	{
		const store = useAndRequireSessionStatusBarStore();
		const [elementRef, element] = useResizeObserver();
		const bottomBarHeight = useBottomBarHeight();
		const screenNarrow = useObserver(() => (element?.contentRect.width ?? store.viewportWidth) < 400);
		const screenExtraNarrow = useObserver(() => (element?.contentRect.width ?? store.viewportWidth) < 200);
		const numberOfOrderPlaceToday = useObserver(() => store.orderService.ordersCreatedToday.length);
		const mostRecentOrder = useObserver(() => store.orderService.mostRecentOrder);
		const fontSize = useBottomBarFontSize(screenNarrow);
		const classes = useStyles();

		return <Button
			className={classes.button}
			color="inherit"
			onClick={store.onOpenOpenOrders}
			ref={elementRef}
			style={{
				justifyContent: compact
					? 'left'
					: 'center',
				height: bottomBarHeight,
			}}
		>
			{
				mostRecentOrder !== undefined &&
				<>
					<CollapsedHistoryIcon
						fontSize={screenNarrow
							? 'medium'
							: 'large'
						}
						mostRecentOrder={mostRecentOrder}
					/>
					<CollapsedHistoryText
						compact={compact}
						mostRecentOrder={mostRecentOrder}
						numberOfOrderPlaceToday={numberOfOrderPlaceToday}
						screenExtraNarrow={screenExtraNarrow}
						style={{
							fontSize,
						}}
					/>
				</>
			}
		</Button>;
	};
