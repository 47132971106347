// @ts-nocheck
/* eslint-disable */
import { Http } from './Http';
import { WebClient } from '../Client/WebClient';
import * as urlencode from 'urlencode';

export class WebHttp extends Http
{
    // ------------------------ Dependencies ------------------------

    private client: WebClient;

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    constructor(client: WebClient)
    {
        super();
        this.client = client;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

    protected encodeUrlParams(params: any): URLSearchParams | any[]
    {
        if (this.client.supportsURLSearchParams) {
            const encodedParams = new URLSearchParams();
            for (let key in params) {
                if (params.hasOwnProperty(key) && params[key] !== undefined) {
                    let value = params[key];
                    value = value instanceof Object ? JSON.stringify(value) : value;
                    encodedParams.append(key, value);
                }
            }
            return encodedParams;

        } else {
            const encodedParams = [];
            for (let key in params) {
                if (params.hasOwnProperty(key) && params[key] !== undefined) {
                    let value = params[key];
                    value = value instanceof Object ? JSON.stringify(value) : value;
                    let encodedValue = urlencode(value);
                    encodedParams.push(key, value);
                }
            }
            return encodedParams;
        }
    }
}
