import * as React from 'react';
import { CSSProperties, FC, useCallback, useMemo } from 'react';
import { Paper, Theme, Typography } from '@material-ui/core';
import { Place } from '../../../../../Api/Business/Place';
import { PlaceSession } from '../../../../../Api/Business/PlaceSession';
import { UIColors } from '../../../../../Constants/UIColors';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useManagerPlaceSessionsContext } from '../../context/place-session/ManagerPlaceSessionsContext';
import { PlaceButtonSessionOverlay } from './PlaceButtonSessionOverlay';
import { PlaceButtonSettingsButton } from './PlaceButtonSettingsButton';

const PlacePaperSize = 120;

const useStyles = makeStyles(
	(theme: Theme) =>
		({
			paper:
				{
					alignItems: 'center',
					display: 'flex',
					float: 'left',
					height: PlacePaperSize,
					justifyContent: 'center',
					position: 'relative',
					transition: theme.transitions.create(
						'background-color',
						{
							easing: theme.transitions.easing.sharp,
							duration: theme.transitions.duration.leavingScreen,
						}),
					width: PlacePaperSize,
					cursor: 'pointer',
				},
			paperClosed:
				{
					backgroundColor: UIColors.negative,

					'&:hover':
						{
							backgroundColor: UIColors.negativeHighlight,
						},
				},
			paperOpened:
				{
					backgroundColor: UIColors.positive,

					'&:hover':
						{
							backgroundColor: UIColors.positiveHighlight,
						},
				},
			paperClosedSelected:
				{
					backgroundColor: UIColors.negativeHighlight,
				},
			paperOpenedSelected:
				{
					backgroundColor: UIColors.positiveHighlight,
				},
			text:
				{
					fontWeight: 'lighter',
					margin: theme.spacing(1),
					wordBreak: 'break-all',
				},
		}));

interface PlaceButtonProps
{
	isShown?: boolean;
	onClick: (place: Place, session?: PlaceSession) => void;
	onClickSettings: (place: Place, session?: PlaceSession) => void;
	place: Place;
	showDetails?: boolean;
	style?: CSSProperties;
	allowedToEdit: boolean;
}

export const PlaceButton: FC<PlaceButtonProps> =
	({
		 isShown,
		 onClick,
		 onClickSettings,
		 place,
		 showDetails,
		 style,
		 allowedToEdit,
	 }) =>
	{
		const {getPlaceSessionByPlaceId} = useManagerPlaceSessionsContext();

		const classes = useStyles();

		const placeSession = useMemo(() => getPlaceSessionByPlaceId(place.id), [getPlaceSessionByPlaceId, place.id]);

		const onButtonClick = useCallback(() => onClick(place, placeSession), [onClick, place, placeSession]);
		const onSettingsClick = useCallback(() => onClickSettings(place, placeSession), [onClickSettings, place, placeSession]);

		const rootClassesString = useMemo(
			() =>
			{
				let string = `${classes.paper} ${placeSession ? classes.paperOpened : classes.paperClosed}`;

				if (isShown)
				{
					string = `${string} ${placeSession ? classes.paperOpenedSelected : classes.paperClosedSelected}`;
				}

				return string;
			},
			[classes.paper, classes.paperClosed, classes.paperClosedSelected, classes.paperOpened, classes.paperOpenedSelected, isShown, placeSession],
		);

		return <Paper
			classes={{
				root: rootClassesString,
			}}
			id={`place-${place.id}-button`}
			onClick={onButtonClick}
			style={style}
		>
			{
				placeSession &&
				<PlaceButtonSessionOverlay
					placeSession={placeSession}
					show={showDetails}
				/>
			}
			<Typography
				classes={{
					root: classes.text,
				}}
			>
				{place.name}
			</Typography>
			{
				allowedToEdit &&
				<PlaceButtonSettingsButton
					onClick={onSettingsClick}
				/>
			}
		</Paper>;
	};
