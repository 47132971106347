import { OrderDescriptor } from '../../../Api/Order/OrderDescriptor';
import { getDateCreated } from '../../Orders/getDateCreated';

export function updateOrderInArray(orderArray: OrderDescriptor[], order: OrderDescriptor): OrderDescriptor[]
{
	const orderIdx = orderArray.findIndex(peerOrder => peerOrder.id === order.id);

	if (orderIdx > -1)
	{
		orderArray.splice(orderIdx, 1, order);
	}
	else
	{
		if (orderArray.length > 0)
		{
			const dateCreated = getDateCreated(order);

			if (dateCreated !== undefined)
			{
				for (let i = 0; i < orderArray.length; i++)
				{
					const peerOrder = orderArray[i];
					const peerDateCreated = getDateCreated(peerOrder);

					if (peerDateCreated !== undefined)
					{
						if (dateCreated.getTime() < peerDateCreated.getTime())
						{
							orderArray.splice(i, 0, order);
							return orderArray;
						}
					}
				}

				// Append order if date ordered unknown
				orderArray.push(order);
			}
		}
		else
		{
			orderArray.push(order);
		}
	}

	return orderArray;
}