import cryptoRandomString from 'crypto-random-string';
import { ExceptionMode } from './ExceptionMode';

export class Exception extends Error
{
	// ------------------------- Properties -------------------------

	private readonly _cause: Error | Exception | undefined;
	public readonly id: string;
	public readonly name: string;
	public readonly message: string;
	public readonly stack: string | undefined;

	// ------------------------ Constructor -------------------------

	public constructor(
		message: string = 'An unknown exception occurred',
		cause?: Error
	)
	{
		super(message); // breaks inheritance chain, only when extending Error
		Object.setPrototypeOf(this, new.target.prototype); // restores inheritance chain
		this.id = cryptoRandomString({
			length: 6,
			characters: 'abcdefghijkmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ23456789',
		});
		this._cause = cause;
		const exceptionMode = Exception.mode;
		const stackOfThisException = (() => {
			const result = (new Error()).stack
				?.split('\n') ?? [];

			// remove first line saying "Error", and trace lines within this Exception logic
			result.splice(0, 3);

			return `${this.name}: ${this.message}\n${result.join('\n')}`;
		})();
		if (exceptionMode === 'root')
		{
			if (cause !== undefined)
			{
				this.name = cause.name ?? 'Exception';
				this.message = cause.message;
				this.stack = cause.stack;
			}
			else
			{
				this.name = 'Exception';
				this.message = message;
				this.stack = stackOfThisException;
			}
		}
		else // exceptionMode === 'chain'
		{
			this.name = 'Exception';
			this.message = message;
			this.stack = cause !== undefined
				?
				`${stackOfThisException}\n\nCaused by ${cause.stack}`
				:
				stackOfThisException;
		}
	}

	// ----------------------- Initialization -----------------------

	// -------------------------- Computed --------------------------

	// -------------------------- Actions ---------------------------

	// ------------------------ Public logic ------------------------

	// ----------------------- Private logic ------------------------

	private static get mode(): ExceptionMode
	{
		const env = process.env.REACT_APP_EXCEPTION_MODE;
		switch (env)
		{
			case undefined:
			case 'chain':
				return 'chain'
			case 'root':
				return 'root'
			default:
				throw new Error(`Env variable EXCEPTION_MODE has illegal value '${env}'`);
		}
	}
}
