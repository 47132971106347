import { Loader } from '@intentic/ts-foundation';
import * as React from 'react';
import { FC } from 'react';
import { Page } from '../UI/Page';

interface LoaderPageProps
{
}

export const LoaderPage: FC<LoaderPageProps> =
	() =>
	{
		return <Page
			style={{
				flex: '1 1 auto',
				display: 'flex',
				flexFlow: 'column nowrap',
				justifyContent: 'center',
				backgroundColor: 'white',
			}}
		>
			<Loader/>
		</Page>;
	};