import { Accordion, AccordionDetails, AccordionSummary, FormGroup as MuiFormGroup, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as React from 'react';
import { FC, useMemo } from 'react';

const useStyles = makeStyles(() => ({
	accordionDetails: {
		padding: 0,
	},
	accordionRoot: {
		backgroundColor: 'transparent',
		boxShadow: 'none',

		'&::before': {
			opacity: 0,
		},
	},
	formGroupRoot: {
		flexGrow: 1,
	},
}));

interface FormGroupProps
{
	defaultExpanded?: boolean;
	label?: string;
}

export const FormGroup: FC<FormGroupProps> =
	(
		{
			children,
			defaultExpanded,
			label,
		},
	) =>
	{
		const classes = useStyles();

		const labelComponent = useMemo(
			() =>
				label !== undefined
					? <Typography
						variant="h5"
					>
						{label}
					</Typography>
					: null,
			[label],
		);

		const formComponents = useMemo(
			() =>
				<MuiFormGroup
					classes={{
						root: classes.formGroupRoot,
					}}
				>
					{children}
				</MuiFormGroup>
			,
			[children, classes.formGroupRoot],
		);

		return <Accordion
			classes={{
				root: classes.accordionRoot,
			}}
			defaultExpanded={defaultExpanded}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
			>
				{labelComponent}
			</AccordionSummary>
			<AccordionDetails
				classes={{
					root: classes.accordionDetails
				}}
			>
				{formComponents}
			</AccordionDetails>
		</Accordion>;
	};