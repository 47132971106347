import { useCallback, useEffect, useRef } from 'react';

/**
 * https://github.com/mui/material-ui/blob/master/packages/mui-utils/src/useEventCallback.ts
 * (typing in mui 4's version is invalid)
 */
export function useEventCallback<Args extends unknown[], Return>(
	fn: (...args: Args) => Return,
): (...args: Args) => Return
{
	const ref = useRef(fn);
	useEffect(() =>
	{
		ref.current = fn;
	});
	// noinspection CommaExpressionJS
	return useCallback(
		(...args: Args) =>
			// @ts-expect-error hide `this`
			// tslint:disable-next-line:ban-comma-operator
			(0, ref.current!)(...args),
		[],
	);
}