import { Collapse, makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import Decimal from 'decimal.js';
import { useObserver } from 'mobx-react-lite';
import { FC, useCallback, useContext, useMemo } from 'react';
import { BasketRequirements } from '../../../../../../Api/Order/Loyalty/Como/BasketRequirements';
import { ProductConfiguration } from '../../../../../../Api/Product/ProductConfiguration';
import { useRootContext } from '../../../../../../RootContext';
import { isDefined } from '../../../../../../Util/isDefined';
import { BusinessContextRef } from '../../../BusinessContext';
import { AdditionalShoppingCartLine } from '../../../Product/ProductConfiguration/RecommendedProductConfiguration/AdditionalShoppingCartLine';
import { ComoRewardStore } from '../ComoRewardStore';
import { ComoBasketRequirementsItem } from './ComoBasketRequirementsItem';
import { ComoBasketRequirementsText } from './ComoBasketRequirementsText';

const useStyles = makeStyles({
	alreadyAddedProductsCollapseWrapperInner: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	list: {
		flexGrow: 1,
	},
});

interface ComoBasketRequirementsProps
{
	basketRequirements: BasketRequirements;
	store: ComoRewardStore;
}

export const ComoBasketRequirements: FC<ComoBasketRequirementsProps> =
	({
		basketRequirements,
		store,
	}) =>
	{
		const {localizer} = useRootContext(true);
		const {businessStore} = useContext(BusinessContextRef);

		const addProductConfiguration = useObserver(() => store.addProductConfiguration);
		const alreadyAddedProducts = useObserver(() => store.getProductConfigurations(basketRequirements));
		const productCurrencyCode = useObserver(() => businessStore.business.productCurrencyCode);
		const productByExternalId = useObserver(() => businessStore.productByExternalId);
		const removeProductConfiguration = useObserver(() => store.removeProductConfiguration);

		const externalIds = useMemo(
			() =>
				basketRequirements.ids ?? [],
			[basketRequirements.ids],
		);

		const addProduct = useCallback(
			(productConfiguration: ProductConfiguration) =>
				addProductConfiguration(basketRequirements, productConfiguration),
			[addProductConfiguration, basketRequirements],
		);

		const totalOrdered = useObserver(
			() =>
				alreadyAddedProducts.reduce(
					(result, current) =>
						result + current.quantity,
					0,
				),
		);

		const totalSpend = useObserver(
			() =>
				alreadyAddedProducts.reduce(
					(result, current) =>
						result.add(current.price),
					new Decimal(0),
				),
		);

		const totalSpendMinor = useMemo(
			() =>
				totalSpend.mul(
					new Decimal(10)
						.pow(localizer.getCurrencyFractionDigits(productCurrencyCode)),
				),
			[localizer, productCurrencyCode, totalSpend],
		);

		const allowSelection = useMemo(
			() =>
			{
				const minimumQuantity = basketRequirements.conditions?.quantity?.minimum;
				const minimumSpend = basketRequirements.conditions?.spend?.minimum;

				if (minimumQuantity === undefined)
				{
					return minimumSpend === undefined
						? true
						: totalSpendMinor.comparedTo(minimumSpend) < 0;
				}
				else if (minimumSpend === undefined)
				{
					return totalOrdered < minimumQuantity;
				}
				else
				{
					return totalOrdered < minimumQuantity ||
						totalSpendMinor.comparedTo(minimumSpend) < 0;
				}
			},
			[basketRequirements.conditions?.quantity?.minimum, basketRequirements.conditions?.spend?.minimum, totalOrdered, totalSpendMinor],
		);

		const products = useMemo(
			() =>
				externalIds
					.map(
						externalId =>
							productByExternalId.get(externalId),
					)
					.filter(isDefined),
			[externalIds, productByExternalId]
		)

		const classes = useStyles();

		return <List
			className={classes.list}
			dense
			disablePadding
		>
			<ListItem>
				<ComoBasketRequirementsText
					basketRequirements={basketRequirements}
					store={store}
					totalOrdered={totalOrdered}
					totalSpend={totalSpend}
				/>
			</ListItem>
			<Collapse
				classes={{
					wrapperInner: classes.alreadyAddedProductsCollapseWrapperInner,
				}}
				in={alreadyAddedProducts.length > 0}
			>
				{
					alreadyAddedProducts.map(
						value =>
							<AdditionalShoppingCartLine
								disableIncrement={!allowSelection}
								key={value.productConfiguration.id}
								shoppingCartLine={value}
								onRemove={
									line =>
										removeProductConfiguration(line.productConfiguration)
								}
							/>,
					)
				}
			</Collapse>
			<Collapse
				in={allowSelection}
			>
				{
					products.map(
						product =>
							<ComoBasketRequirementsItem
								key={product.externalId}
								onAdd={addProduct}
								product={product}
							/>,
					)
				}
			</Collapse>
		</List>;
	};
