export const PaymentIssuerAliases = new Map<string, string>([
	['ABNANL2A', 'ABN AMRO'],
	['ASNBNL21', 'ASN'],
	['BUNQNL2A', 'Bunq'],
	['FVLBNL22', 'Lanschot Bankiers'],
	['HANDNL2A', 'Handelsbanken'],
	['INGBNL2A', 'ING'],
	['KNABNL2H', 'Knab'],
	['MOYONL21', 'Moneyou'],
	['RABONL2U', 'Rabobank'],
	['RBRBNL21', 'Regiobank'],
	['SNSBNL2A', 'SNS'],
	['TRIONL2U', 'Triodos']
]);