import Decimal from 'decimal.js';
import { makeAutoObservable } from 'mobx';
import { OrderLineState } from '../../lib/api/model/OrderLineState';
import { NumberToDecimalTransformer, SerializationProfile } from '../../Util/Serialization/Serialization';
import { Routing, RoutingProfile } from '../Business/Routing/Routing';
import { LanguageEntryTranslations } from '../Other/LanguageEntryTranslations';
import { LanguageEntryTranslationsProfile } from '../Other/LanguageEntryTranslationsProfile';
import { Product } from '../Product/Product';
import { ProductConfigurationVariant } from '../Product/ProductConfigurationVariant';
import { ProductFeatureVariant, ProductFeatureVariantProfile } from '../Product/ProductFeatureVariant';
import { ProductFee, ProductFeeProfile } from '../Product/ProductFee';
import { ProductProfile } from '../Product/ProductProfile';
import { FeeType } from './FeeType';
import { OrderLineType } from './OrderLineType';
import { OrderLineVariant, OrderLineVariantProfile } from './OrderLineVariant';

export class OrderLine
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	readonly id: number;
	readonly uuid: string;
	type: OrderLineType;
	readonly routing?: Routing;
	readonly price: Decimal;
	state?: OrderLineState;

	// TODO: The Properties below can always be undefined depending on the type of OrderLine.

	// ProductOrderLine properties (OrderProfile cannot properly deserialize subclasses)
	readonly product?: Product;
	readonly quantity: number;
	readonly productVariants: ProductFeatureVariant[];
	readonly orderLineVariants: OrderLineVariant[];
	readonly productFee?: ProductFee;
	public readonly description?: string;
	public readonly descriptionSecondary?: LanguageEntryTranslations;

	// ExternalBillOrderLine properties (OrderProfile cannot properly deserialize subclasses)
	readonly posId?: string;

	// FeeOrderLine properties (OrderProfile cannot properly deserialize subclasses)
	readonly feeType?: FeeType;

	readonly embeddableHtmlAfterOrder?: string;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor(price: Decimal, feeType: FeeType)
	constructor(price: Decimal, routing: Routing, posId?: string)
	constructor(product: Product, productVariants: ProductConfigurationVariant[], quantity?: number)
	constructor(...args: any[])
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});

		if (args[0] instanceof Product)
		{
			this.product = args[0];
			this.productVariants = args[1];
			this.quantity = args[2];
		}
		else
		{
			this.price = args[0];

			if (args[1] !== 'DELIVERY')
			{
				this.routing = args[1];
				this.posId = args[2];
			}
			else
			{
				this.feeType = args[1];
			}
		}
	}

	/*---------------------------------------------------------------*
	 *                         Business Logic                        *
	 *---------------------------------------------------------------*/

	get totalPrice()
	{
		return this.price.mul(this.quantity);
	}

	public setState(state: OrderLineState)
	{
		this.state = state;
	}
}

export const OrderLineProfile =
	SerializationProfile.create(OrderLine)
		.profile('product', ProductProfile)
		.profile('routing', RoutingProfile)
		.profile('productVariants', ProductFeatureVariantProfile)
		.profile('orderLineVariants', OrderLineVariantProfile)
		.profile('productFee', ProductFeeProfile)
		.profile('descriptionSecondary', LanguageEntryTranslationsProfile)
		.transform('price', NumberToDecimalTransformer);
