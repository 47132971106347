import { useObserver } from 'mobx-react-lite';
import { Waiter } from '../../Api/User/Waiter';
import { useManagerContext } from '../../Component/Page/Manager/manager-context';
import { IllegalStateException } from '../../Util/Exception/IllegalStateException';

export function useWaiter(require?: boolean): Waiter | undefined
export function useWaiter(require: true): Waiter
export function useWaiter(require: boolean = false): Waiter | undefined
{
	const {
		waiterService,
	} = useManagerContext();
	return useObserver(() => {
		const user = waiterService?.user;
		if (require && user === undefined)
			throw new IllegalStateException('Waiter required but is not set');
		return user;
	});
}