import { action, makeObservable } from 'mobx';
import { WebClient } from '../Client/WebClient';
import { WebNavigator } from '../Navigator/WebNavigator';
import { Dialog } from './Dialog';
import { DialogInstance } from './DialogInstance';
import { DialogProps } from './DialogProps';

export class WebDialog extends Dialog
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

	public readonly client: WebClient;
	public readonly navigator: WebNavigator;

    // ------------------------ Constructor -------------------------

	constructor(
		client: WebClient,
		navigator: WebNavigator,
	)
	{
		super();
		makeObservable(
			this,
			{
				present: action.bound,
			},
		)
		this.client = client;
		this.navigator = navigator;
	}

	// ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

	present(props: DialogProps): Promise<DialogInstance>
	{
		this.navigator.currentScreenInstance!.scrollTop = this.client.scrollYOffset;
		const newInstance = this.pushNewInstance(props);
		return Promise.resolve(newInstance);
	}

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
