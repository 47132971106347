import { Card, CardContent, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { DietaryFlag, DietaryFlagAliases, DietaryFlags } from '../../../Api/Product/DietaryFlag';
import { NutritionFlag } from '../../../Api/Product/NutritionFlag';
import { allergens } from '../../../Api/Product/Product';
import { useLocalizer } from '../../../Bridge/Localization/useLocalizer';
import { useTranslate } from '../../../Bridge/Localization/useTranslate';
import { useTranslation } from '../../../Bridge/Localization/useTranslation';
import Allergen from '../Business/Product/Allergen/Allergen';
import { DietaryFlagBadge } from './DietaryFlagBadge';
import { ProfileStore } from './ProfileStore';

export function dietaryFlagTranslation(
    nutritionFlag: DietaryFlag
)
{
    switch (nutritionFlag)
    {
        case 'IsVegetarian':
        case 'IsVegan':
        case 'IsOrganic':
        case 'IsPregnancyFriendly':
        case 'IsKosher':
        case 'IsHalal':
            return `Product-Nutrition-${nutritionFlag}`
        case 'Nonalcoholic':
            return `Product-Nutrition-Is${nutritionFlag}`
    }
}

export function nutritionFlagTranslation(
    nutritionFlag: NutritionFlag
)
{
    switch (nutritionFlag)
    {
        case 'IsVegetarian':
        case 'IsVegan':
        case 'IsOrganic':
        case 'IsPregnancyFriendly':
        case 'IsKosher':
        case 'IsHalal':
            return `Product-Nutrition-${nutritionFlag}`
        case 'ContainsAlcohol':
            return `Product-Nutrition-ContainsAlcohol`
    }
}

interface AllergensEditorProps
{
    store: ProfileStore;
    allergens: string[];
}

const AllergensEditor: FC<AllergensEditorProps> = (
    {
        store,
        allergens,
    }) =>
{
    const localizer = useLocalizer();
    return useObserver(() => <>
        {allergens.map(
            allergen =>
                <Grid
                    item
                    key={allergen}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={store.hasAllergen(allergen)}
                                onChange={() => store.toggleAllergen(allergen)}
                                color="primary"
                            />
                        }
                        label={
                            <Allergen
                                allergen={allergen}
                                localizer={localizer}
                            />}
                    />
                </Grid>
        )}
    </>);
};

const DietaryRequirementEditor: FC<{store: ProfileStore}> = ({store}) => {
    const translate = useTranslate();
    return useObserver(() => <>
        {
            DietaryFlags
                .filter(flag => !DietaryFlagAliases.has(flag))
                .map(
                    flag =>
                    {
                        return <Grid
                            item
                            key={flag}
                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={store.hasChecked(flag)}
                                        onChange={() => store.toggleNutritionFlag(flag)}
                                        color="primary"
                                    />
                                }
                                label={
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexWrap: 'nowrap',
                                            alignItems: 'center',
                                            padding: 8,
                                        }}
                                    >
                                        <DietaryFlagBadge flag={flag} />
                                        <Typography
                                            variant="body1"
                                            color="textSecondary"
                                            style={{
                                                display: 'flex',
                                                marginLeft: 16,
                                            }}
                                        >
                                            {translate(dietaryFlagTranslation(flag))}
                                        </Typography>
                                    </div>}
                            />
                        </Grid>;
                    },
                )
        }
    </>);
};

export interface Props
{
    store: ProfileStore;
}

export const Profile: FC<Props> =
    (
        {
            store,
        },
    ) =>
    {
        useEffect(
            () => {
                store.initialize();
            },
            [store],
        );
        return <Grid
            container
            spacing={2}
        >
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <FormGroup>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            marginBottom: 4,
                                        }}
                                    >
                                        {useTranslation('Product-MyNutrition')}
                                    </Typography>
                                </Grid>
                                <DietaryRequirementEditor store={store} />
                            </Grid>
                        </FormGroup>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <FormGroup>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            marginBottom: 4,
                                        }}
                                    >
                                        {useTranslation('Allergens-Mine')}
                                    </Typography>
                                </Grid>
                                <AllergensEditor store={store} allergens={allergens} />
                            </Grid>
                        </FormGroup>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>;
    };
