import { Button, Card, CardContent, List, ListItem, makeStyles, TextField } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { LoginCardStore } from './LoginCardStore';

const useStyles = makeStyles(() => ({
    emailAddressItem: {
        paddingTop: 0,
    },
}));

export interface LoginCardProps
{
    width?: number | string;
    store: LoginCardStore
}

export const LoginCard: FC<LoginCardProps> =
    (
        {
            store,
            width,
        }
    ) =>
    {
        const classes = useStyles();
        return useObserver(() => {
            return <Card
                raised
                style={{
                    width,
                }}
            >
                <CardContent>
                    <List
                        disablePadding
                    >
                        <ListItem
                            disableGutters
                            classes={{
                                root: classes.emailAddressItem
                            }}
                        >
                            <TextField
                                fullWidth
                                label="E-mail adres"
                                value={store.emailAddress}
                                onChange={
                                    (event: React.ChangeEvent<HTMLInputElement>) =>
                                    {
                                        store.setEmailAddress(event.target.value);
                                    }
                                }
                                onKeyPress={
                                    (event: React.KeyboardEvent<HTMLDivElement>) =>
                                    {
                                        if (event.key === 'Enter')
                                        {
                                            store.attemptLogin();
                                        }
                                    }
                                }
                            />
                        </ListItem>
                        <ListItem
                            disableGutters
                        >
                            <TextField
                                fullWidth
                                label="Wachtwoord"
                                type="password"
                                value={store.password}
                                onChange={
                                    (event: React.ChangeEvent<HTMLInputElement>) =>
                                        store.setPassword(event.target.value)
                                }
                                onKeyPress={
                                    (event: React.KeyboardEvent<HTMLDivElement>) =>
                                    {
                                        if (event.key === 'Enter')
                                        {
                                            store.attemptLogin();
                                        }
                                    }
                                }
                            />
                        </ListItem>
                        <ListItem
                            disableGutters
                        >
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={!store.isLoginAllowed}
                                onClick={store.attemptLogin}
                            >
                                Login
                            </Button>
                        </ListItem>
                    </List>
                </CardContent>
            </Card>;
        });
    }