import * as React from 'react';
import { FC, useMemo } from 'react';
import { OrderEvent } from '../../../../../../Api/Order/OrderEvent';
import { OrderEventsList } from './OrderEventsList';
import { OrderEventsNone } from './OrderEventsNone';
import { OrderEventsSkeleton } from './OrderEventsSkeleton';
import { useOrderEvents } from './useOrderEvents';

interface OrderEventsProps
{
	orderId: number;
}

export const OrderEvents: FC<OrderEventsProps> =
	(
		{
			orderId,
		},
	) =>
	{
		const orderEvents = useOrderEvents(orderId);

		const sortedOrderEvents = useMemo(() => {
			if (orderEvents === undefined)
				return undefined;

			return sortOrderEvents(orderEvents);
		}, [orderEvents]);

		if (sortedOrderEvents === undefined)
			return <OrderEventsSkeleton />;
		else if (sortedOrderEvents.length === 0)
			return <OrderEventsNone />;
		else
			return <OrderEventsList orderEvents={sortedOrderEvents} />;
	};

function sortOrderEvents(events: OrderEvent[]): OrderEvent[]
{
	return events.sort((a, b) => a.date.getTime() - b.date.getTime());
}