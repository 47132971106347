import { Avatar, ButtonBase, ButtonClassKey, makeStyles, Paper, SvgIconProps, useTheme } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import RefreshIcon from '@material-ui/icons/Refresh';
import clsx from 'clsx';
import * as React from 'react';
import { ComponentType, CSSProperties, FC, MouseEvent, useMemo } from 'react';
import { ButtonAppearance } from '../../ButtonAppearance';
import { ButtonSize } from '../../ButtonSize';
import { HorizontalAlignment } from '../../HorizontalAlignment';
import { ButtonIcon } from '../../icon/ButtonIcon';
import { ButtonLoadingIdicator } from '../../loader/ButtonLoadingIdicator';
import { ButtonText } from '../../text/ButtonText';
import { useSpacingBetweenButtonLabelElements } from '../../useSpacingBetweenButtonLabelElements';
import { usePaperButtonColors } from './usePaperButtonColors';

interface StyleProps
{
	disabled: boolean
	iconBackgroundColorCss: string
	spacingBetweenButtonLabelElements: number
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
	buttonLabel: ({spacingBetweenButtonLabelElements}) => ({
		justifyContent: 'space-between',
		'& > :not(:first-child)': {
			marginLeft: spacingBetweenButtonLabelElements,
		},
	}),
	buttonRootConst: {
		'& *': {
			cursor: 'pointer',
		},
	},
	paperTextRoot: {
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	avatar: {
		transition: theme.transitions.create('background-color', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
}));

interface PaperButtonProps
{
	disabled: boolean
	onClick: (event: MouseEvent<HTMLElement>) => void
	onMouseOver: ((event: MouseEvent<HTMLElement>) => void) | undefined
	onMouseLeave: ((event: MouseEvent<HTMLElement>) => void) | undefined
	color: ButtonAppearance<'paper'>
	error: boolean
	loading: boolean
	classes: (Partial<Record<ButtonClassKey, string>> & {
		icon?: string
	}) | undefined
	className: string | undefined
	textAlign: HorizontalAlignment
	fullWidth: boolean
	style: CSSProperties | undefined
	textStyle: CSSProperties | undefined
	iconStyle: CSSProperties | undefined
	paperStyle: CSSProperties | undefined
	text: JSX.Element | string | undefined
	icon: ComponentType<SvgIconProps> | string | undefined
	moreThanZeroFailedAttempts: boolean
	size: ButtonSize
	id: string | undefined
}

export const PaperButton: FC<PaperButtonProps> =
	(
		{
			children,
			disabled,
			onClick,
			onMouseOver,
			onMouseLeave,
			color,
			error,
			loading,
			textAlign,
			classes: classesProp = {icon: undefined, label: undefined, root: undefined},
			className,
			fullWidth,
			style,
			textStyle,
			paperStyle,
			iconStyle,
			text,
			icon,
			moreThanZeroFailedAttempts,
			size,
			id,
		},
	) =>
	{
		const theme = useTheme();
		
		const {
			iconBackgroundColorCss,
			iconColorCss,
			backgroundColorCss,
			textColorCss,
		} = usePaperButtonColors(error, disabled, color, theme);

		const spacingBetweenButtonLabelElements = useSpacingBetweenButtonLabelElements(size)
		const defaultClasses = useStyles({spacingBetweenButtonLabelElements, iconBackgroundColorCss, disabled});

		const loaderElement = useMemo(
			() => <ButtonLoadingIdicator
				marginLeft={0}
				marginRight={0}
				color={textColorCss}
			/>,
			[textColorCss],
		);

		const textElement = useMemo(
			() => typeof text === 'string' || text === undefined
				?
				<ButtonText
					style={textStyle}
					text={text}
					align={textAlign}
					moreThanZeroFailedAttempts={moreThanZeroFailedAttempts}
					marginLeft={textAlign === 'center' ? 'auto' : undefined}
					marginRight={textAlign === 'center' ? 'auto' : undefined}
					flexStretch={false}
					color={textColorCss}
				/>
				:
				text,
			[moreThanZeroFailedAttempts, text, textAlign, textColorCss, textStyle],
		);

		const iconElement = useMemo(
			() => <ButtonIcon
				icon={icon}
				iconStyle={iconStyle}
				className={classesProp.icon}
				replaceWithRetryIcon={false}
				color={iconColorCss}
				marginLeft={0}
				marginRight={0}
			/>,
			[icon, iconStyle, classesProp.icon, iconColorCss]
		);

		return <Paper
			elevation={1}
			className={clsx(className, defaultClasses.buttonRootConst)}
			style={{
				width: fullWidth ? '100%' : 'auto',
				backgroundColor: backgroundColorCss,
				...style,
				...paperStyle,
			}}
			onMouseOver={onMouseOver}
			onMouseLeave={onMouseLeave}
			id={id}
		>
			<ButtonBase
				onClick={onClick}
				style={{
					alignItems: 'center',
					borderRadius: theme.shape.borderRadius,
					display: 'flex',
					justifyContent: 'space-between',
					width: '100%',
					height: 70,
					padding: 15,
					pointerEvents: disabled ? 'none' : undefined,
				}}
			>
				<Avatar
					className={defaultClasses.avatar}
					style={{
						backgroundColor: iconBackgroundColorCss,
					}}
				>
					{iconElement}
				</Avatar>
				<div
					className={defaultClasses.paperTextRoot}
					style={{
						flex: '0 1 auto',
						display: 'flex',
						flexDirection: 'row',
						alignContent: 'center',
						alignItems: 'center',
						minHeight: 24,
						color: textColorCss,
					}}
				>
					{textElement}
				</div>
				{
					loading
						? loaderElement
						: (
							moreThanZeroFailedAttempts
								?
								<RefreshIcon
									style={{
										color: textColorCss,
									}}
								/>
								:
								<ArrowForwardIosIcon
									style={{
										color: textColorCss,
									}}
								/>
						)
				}
			</ButtonBase>
			{children}
		</Paper>;
	};