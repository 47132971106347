import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import * as React from 'react';
import { FC, ReactElement, useMemo } from 'react';

const useStyles = makeStyles(theme => ({
	root: {
		flex: '0 1 auto',
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
		overflowX: 'hidden',
		marginTop: `0 !important`,
	},
	rootNormal: {
		backgroundColor: '#fff8',
	},
	rootError: {
		backgroundColor: '#f001',
	},
	passOnHorizontalWidthLimit: {
		flex: '1 1 auto',
		overflowX: 'hidden',
		overflowY: 'hidden',
		display: 'flex',
		flexDirection: 'row',
	},
	details: {
		paddingBottom: 8,
	},
}));

interface OrderBuilderExpansionPanelProps
{
	expanded?: boolean
	onExpandedChange: (expanded: boolean) => void
	error?: boolean
	summary: ReactElement
}

export const OrderBuilderExpansionPanel: FC<OrderBuilderExpansionPanelProps> =
	(
		{
			expanded,
			onExpandedChange,
			error = false,
			summary,
			children,
		},
	) =>
	{
		const classes = useStyles();
		const expandIcon = useMemo(
			() => <ExpandMoreIcon/>,
			[]
		);
		return <Accordion
			expanded={expanded}
			onChange={(e, expanded) => onExpandedChange(expanded)}
			className={useMemo(() => clsx(
				classes.root,
				error ? classes.rootError : classes.rootNormal
			), [classes.root, classes.rootError, classes.rootNormal, error])}
		>
			<AccordionSummary
				expandIcon={expandIcon}
				classes={useMemo(() => ({
					root: classes.passOnHorizontalWidthLimit,
					content: classes.passOnHorizontalWidthLimit,
				}), [classes.passOnHorizontalWidthLimit])}
			>
				{summary}
			</AccordionSummary>
			<AccordionDetails className={classes.details}>
				{children}
			</AccordionDetails>
		</Accordion>;
	};
