import { Place } from '../../../../../Api/Business/Place';
import { fetchAny } from '../../../../../Util/Api';

export function getPlaceSessions(params: GetPlaceSessionsParams): Promise<GetPlaceSessionsResponse>
{
	return fetchAny('/waiter/business/place/sessions/open', params);
}

type GetPlaceSessionsResponse = {
	id: number
	external_id?: string
	place: Place
	start_date: number
	end_date?: number
	number_of_covers: number
	menu_card_id?: number
}[]

interface GetPlaceSessionsParams
{
	business_id: number
}