import * as React from 'react';
import { CSSProperties, FC, useCallback, useState } from 'react';
import { OrderLineDescriptor } from '../../../../../../Api/Order/OrderLineDescriptor';
import { IllegalStateException } from '../../../../../../Util/Exception/IllegalStateException';
import { isExternalBillOrderLine } from '../../../../../../Util/order_line/isExternalBillOrderLine';
import { isFeeOrderLine } from '../../../../../../Util/order_line/isFeeOrderLine';
import { isProductFeeOrderLine } from '../../../../../../Util/order_line/isProductFeeOrderLine';
import { isProductOrderLine } from '../../../../../../Util/order_line/isProductOrderLine';
import { isRequestBillOrderLine } from '../../../../../../Util/order_line/isRequestBillOrderLine';
import { isRequestWaiterOrderLine } from '../../../../../../Util/order_line/isRequestWaiterOrderLine';
import { isTaxOrderLine } from '../../../../../../Util/order_line/isTaxOrderLine';
import { isTipOrderLine } from '../../../../../../Util/order_line/isTipOrderLine';
import { ExternalBillOrderLine } from './ExternalBillOrderLine';
import { FeeOrderLine } from './FeeOrderLine';
import { ProductOrderLineCollapsed } from './ProductOrderLineCollapsed';
import { ProductOrderLineExpanded } from './ProductOrderLineExpanded';
import { RequestBillOrderLine } from './RequestBillOrderLine';
import { RequestWaiterOrderLine } from './RequestWaiterOrderLine';
import { TaxOrderLine } from './TaxOrderLine';
import { TipOrderLine } from './TipOrderLine';

export interface OrderLineProps
{
	alignWithAvatars?: boolean;
	clickable?: boolean;
	collapsed?: boolean;
	orderLine: OrderLineDescriptor;
	showPriceAsCurrency?: string;
	style?: CSSProperties;
}

export const OrderLine: FC<OrderLineProps> =
	(
		{
			alignWithAvatars,
			clickable,
			collapsed,
			orderLine,
			showPriceAsCurrency,
			style,
		},
	) =>
	{
		const [showCollapsed, setShowCollapsed] = useState(collapsed);

		const toggleCollapsed = useCallback(() => setShowCollapsed(!showCollapsed), [showCollapsed]);

		if (isProductOrderLine(orderLine))
		{
			if (showCollapsed)
			{
				return <ProductOrderLineCollapsed
					alignWithAvatars={alignWithAvatars}
					onClick={clickable ? toggleCollapsed : undefined}
					orderLine={orderLine}
					showPriceAsCurrency={showPriceAsCurrency}
					style={{
						...style,
						flexGrow: 1,
					}}
				/>;
			}
			else
			{
				return <ProductOrderLineExpanded
					alignWithAvatars={alignWithAvatars}
					onClick={clickable ? toggleCollapsed : undefined}
					orderLine={orderLine}
					showPriceAsCurrency={showPriceAsCurrency}
					style={{
						...style,
						flexGrow: 1,
					}}
				/>;
			}
		}
		else if (isFeeOrderLine(orderLine) || isProductFeeOrderLine(orderLine))
		{
			return <FeeOrderLine
				alignWithAvatars={alignWithAvatars}
				orderLine={orderLine}
				showPriceAsCurrency={showPriceAsCurrency}
				style={style}
			/>;
		}
		else if (isTipOrderLine(orderLine))
		{
			return <TipOrderLine
				alignWithAvatars={alignWithAvatars}
				orderLine={orderLine}
				showPriceAsCurrency={showPriceAsCurrency}
				style={style}
			/>;
		}
		else if (isTaxOrderLine(orderLine))
		{
			return <TaxOrderLine
				alignWithAvatars={alignWithAvatars}
				orderLine={orderLine}
				showPriceAsCurrency={showPriceAsCurrency}
				style={style}
			/>;
		}
		else if (isExternalBillOrderLine(orderLine))
		{
			return <ExternalBillOrderLine
				alignWithAvatars={alignWithAvatars}
				orderLine={orderLine}
				showPriceAsCurrency={showPriceAsCurrency}
				style={style}
			/>;
		}
		else if (isRequestBillOrderLine(orderLine))
		{
			return <RequestBillOrderLine
				alignWithAvatars={alignWithAvatars}
				style={style}
			/>;
		}
		else if (isRequestWaiterOrderLine(orderLine))
		{
			return <RequestWaiterOrderLine
				alignWithAvatars={alignWithAvatars}
				style={style}
			/>;
		}
		else
		{
			throw new IllegalStateException();
		}
	};