// @ts-nocheck
/* eslint-disable */
import * as React from 'react';
import { FC, ReactNode, useContext, useMemo } from 'react';
import { Theme, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { UIColors } from '../../../../../Constants/UIColors';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import EventNote from '@material-ui/icons/EventNote';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { EntranceContextRef } from '../../../Entrance/EntranceContext';

const useStyles = makeStyles(
    (theme: Theme) =>
        ({
            billsIcon:
                {
                    marginRight: theme.spacing(1),
                },
            billsText:
                {
                },
            billsTextNone:
                {
                    flexGrow: 1,
                },
            button:
                {
                    margin: theme.spacing(1),
                },
            buttonText:
                {
                    marginRight: theme.spacing(1),
                },
            containerLeft:
                {
                    alignItems: 'center',
                    display: 'flex',
                    flexGrow: 1,
                    padding: theme.spacing(1),
                },
            containerRight:
                {
                    alignItems: 'center',
                    display: 'flex',
                    margin: theme.spacing(1),
                    width: 'fit-content',
                },
            numberOfOpenBillsContainer:
                {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: theme.shape.borderRadius,
                    display: 'flex',
                    padding: theme.spacing(0.5),
                },
            numberOfOpenBillsIcon:
                {
                    color: theme.palette.primary.contrastText,
                    marginLeft: theme.spacing(1),
                },
            numberOfOpenBillsText:
                {
                    color: theme.palette.primary.contrastText,
                    marginLeft: theme.spacing(1),
                    marginRight: theme.spacing(1),
                },
            root:
                {
                    backgroundColor: UIColors.greyLight,
                    display: 'flex',
                    justifyContent: 'space-between',
                },
        }));

interface OrderHandlerToolbarProps
{
    className?: string;
    height?: number | string;
    isBillManagerOpen?: boolean;
    numberOfOpenBills: number;
    onOpenBillManager?: () => void;
    style?: CSSProperties;
}

export const OrderHandlerToolbar: FC<OrderHandlerToolbarProps> =
    ({
         className,
         height = 48,
         isBillManagerOpen,
         numberOfOpenBills,
         onOpenBillManager,
         style,
     }) =>
    {

        const classes = useStyles();

        const {
            localizer,
        } = useContext(EntranceContextRef);

        const leftItem = useMemo(
            () =>
            {
                let icon: ReactNode;
                let text: ReactNode;

                if (numberOfOpenBills > 0)
                {
                    text = <Typography
                        classes={{
                        root: classes.billsText
                        }}
                    >
                        {localizer.translate('Bill-Open')}
                    </Typography>;

                    if (isBillManagerOpen)
                    {
                        icon = <ExpandMore
                            classes={{
                                root: classes.billsIcon,
                            }}
                        />;
                    }
                    else
                    {
                        icon = <ExpandLess
                            classes={{
                                root: classes.billsIcon,
                            }}
                        />;
                    }
                }
                else
                {
                    text = <Typography
                        align="center"
                        classes={{
                            root: classes.billsTextNone,
                        }}
                    >
                        {localizer.translate('Bill-None')}
                    </Typography>;
                }

                return <>
                    {icon}
                    {text}
                </>;
            },
            [classes, isBillManagerOpen, numberOfOpenBills]);

        const leftContainer = <div
            className={classes.containerLeft}
        >
            {leftItem}
        </div>;

        const rightContainer = numberOfOpenBills > 0 &&
            <div
                className={classes.containerRight}
            >
                <div
                    className={classes.numberOfOpenBillsContainer}
                >
                    <EventNote
                        classes={{
                            root: classes.numberOfOpenBillsIcon,
                        }}
                    />
                    <Typography
                        classes={{
                            root: classes.numberOfOpenBillsText,
                        }}
                    >
                        {numberOfOpenBills || 0}
                    </Typography>
                </div>
            </div>;

        return <div
            className={`${classes.root}${className ? ` ${className}` : ''}`}
            onClick={onOpenBillManager}
            style={{
                ...style,
                height: height,
                pointerEvents: numberOfOpenBills > 0 ? 'auto' : 'none',
            }}
        >
            {leftContainer}
            {rightContainer}
        </div>;
    };