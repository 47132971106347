import { Collapse, makeStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import clsx from 'clsx';
import * as React from 'react';
import { FC, MouseEvent, useCallback, useMemo } from 'react';
import { TRADITIONAL_PAYMENT_METHOD_ID } from '../../../../../../../Api/Payment/PaymentMethodDescriptor';
import { useTranslation } from '../../../../../../../Bridge/Localization/useTranslation';
import { useValidationContext } from '../../../../../validation/context/ValidationContext';
import { usePaymentMethodAvailabilityContext } from '../../../../availability/context/PaymentMethodAvailabilityContext';
import { usePaymentIssuerContext } from '../../../../issuer/context/PaymentIssuerContext';
import { PaymentTiming } from '../../../../model/PaymentTiming';
import { usePaymentMethodContext } from '../../../context/PaymentMethodContext';

const useStyles = makeStyles(theme => ({
	button: {
		flex: '1 1 auto',

		transition: theme.transitions.create('background-color', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.complex,
		}),
	},
	buttonErratic: {
		borderColor: 'rgba(244,67,54,1)',
		color: 'rgba(244,67,54,1)',
	},
	buttonGroup: {
		display: 'flex',
		justifyContent: 'stretch',
	},
	buttonGroupErratic: {
		backgroundColor: 'rgba(244,67,54,0)',
	},
	root: {
		marginBottom: theme.spacing(2),
	},
}));

interface PaymentTimingSelectorProps
{
	className?: string;
	onChange: (paymentTiming: PaymentTiming | undefined) => void;
	onFocusChange?: (hasFocus: boolean) => void;
	value?: PaymentTiming;
}

export const PaymentTimingSelector: FC<PaymentTimingSelectorProps> =
	({
		className,
		onChange,
		onFocusChange,
		value,
	}) =>
	{
		const showValidation = useValidationContext();
		const paymentMethods = usePaymentMethodAvailabilityContext();
		const {selectPaymentMethod, validation: paymentMethodValidation} = usePaymentMethodContext();
		const {validation: paymentIssuerValidation} = usePaymentIssuerContext();

		const classes = useStyles();

		const allValidation = useMemo(
			() => [...paymentMethodValidation, ...paymentIssuerValidation],
			[paymentIssuerValidation, paymentMethodValidation],
		);

		const appearErratic = useMemo(
			() => showValidation && allValidation.length > 0,
			[allValidation.length, showValidation],
		);

		const supportsPayNow = useMemo(
			() => paymentMethods?.some(paymentMethod => paymentMethod.id !== TRADITIONAL_PAYMENT_METHOD_ID),
			[paymentMethods],
		);

		const supportsPayLater = useMemo(
			() => paymentMethods?.some(paymentMethod => paymentMethod.id === TRADITIONAL_PAYMENT_METHOD_ID),
			[paymentMethods],
		);

		const handleChange = useCallback((event: MouseEvent, timing: PaymentTiming) =>
		{
			onChange(timing);

			if (timing === 'afterwards')
			{
				selectPaymentMethod(TRADITIONAL_PAYMENT_METHOD_ID);
				onFocusChange?.(false);
			}
		}, [onChange, onFocusChange, selectPaymentMethod]);

		const buttonClassName = clsx(
			classes.button,
			{
				[classes.buttonErratic]: appearErratic,
			},
		);

		const buttonGroupClassName = clsx(
			classes.buttonGroup,
			{
				[classes.buttonGroupErratic]: appearErratic,
			},
		);

		const rootClassName = clsx(classes.root, className);

		const payBeforeLabel = useTranslation('Order-Pay-Now');
		const payAfterLabel = useTranslation('Order-Pay-After');

		return <Collapse in={supportsPayLater} unmountOnExit>
			<div className={rootClassName}>
				<ToggleButtonGroup
					className={buttonGroupClassName}
					exclusive
					onChange={handleChange}
					value={value}
				>
					{
						supportsPayNow &&
						<ToggleButton
							className={buttonClassName}
							key={1}
							style={{
								pointerEvents: value === 'beforehand' ? 'none' : undefined,
							}}
							value="beforehand"
						>
							{payBeforeLabel}
						</ToggleButton>
					}
					{
						supportsPayLater &&
						<ToggleButton
							className={buttonClassName}
							key={2}
							style={{
								pointerEvents: value === 'afterwards' ? 'none' : undefined,
							}}
							value="afterwards"
						>
							{payAfterLabel}
						</ToggleButton>
					}
				</ToggleButtonGroup>
			</div>
		</Collapse>;
	};