import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { TRADITIONAL_PAYMENT_METHOD_ID } from '../../../../../../../Api/Payment/PaymentMethodDescriptor';
import { usePaymentMethodAvailabilityContext } from '../../../../availability/context/PaymentMethodAvailabilityContext';
import { PaymentMethodImage } from './PaymentMethodImage';
import { PaymentMethodLabel } from './PaymentMethodLabel';

const useStyles = makeStyles(theme => ({
	arrow: {
		color: 'rgba(0, 0, 0, 0.54)',
	},
	button: {
		height: 56,

		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',

		borderStyle: 'solid',
		borderWidth: 1,
		borderRadius: theme.shape.borderRadius,
		borderColor: 'rgba(0, 0, 0, 0.12)',

		paddingLeft: 14,
		paddingRight: 7,
	},
	image: {
		marginLeft: 'auto',
		marginRight: 1,
	},
	menuItemRoot: {
		alignItems: 'center',
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'space-between',
	},
	selectBox: {
		minHeight: 62,
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	selectBoxSelect: {
		paddingBottom: theme.spacing(2),
		paddingTop: theme.spacing(2),
	},
}));

interface BeforehandPaymentMethodSelectBoxProps
{
	className?: string;
	onChange: (paymentMethodId: string) => void;
	value?: string;
}

export const BeforehandPaymentMethodSelectBox: FC<BeforehandPaymentMethodSelectBoxProps> =
	({
		className,
		onChange,
		value,
	}) =>
	{
		const paymentMethods = usePaymentMethodAvailabilityContext();

		const classes = useStyles();

		const options = useMemo(() =>
		{
			return paymentMethods?.filter(paymentMethod => paymentMethod.id !== TRADITIONAL_PAYMENT_METHOD_ID) ?? [];
		}, [paymentMethods]);

		const selectedBeforehandPaymentMethod = useMemo(() =>
		{
			if (value === undefined)
				return undefined;
			else
				return paymentMethods?.find(peer => peer.id === value)?.id;
		}, [paymentMethods, value]);

		const handleSelectChange = useCallback((event: ChangeEvent<{ value: string }>) =>
		{
			onChange(event.target.value);
		}, [onChange]);

		const selectBoxClassname = clsx(classes.selectBox, className);

		if (value === undefined)
			return null;
		else
			return <FormControl fullWidth variant="outlined">
				<Select
					className={selectBoxClassname}
					classes={{
						select: classes.selectBoxSelect,
					}}
					onChange={handleSelectChange}
					value={selectedBeforehandPaymentMethod}
				>

					{
						options.map(paymentMethod =>
						{
							return <MenuItem key={paymentMethod.id} value={paymentMethod.id}>
								<div className={classes.menuItemRoot}>
									<PaymentMethodLabel paymentMethod={paymentMethod} />
									<PaymentMethodImage paymentMethod={paymentMethod} />
								</div>
							</MenuItem>;
						})
					}
				</Select>
			</FormControl>;
	};