import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext } from 'react';
import { useCurrentMinute } from '../../../../../../../Util/clock/useCurrentMinute';
import MenuEntry from '../../../../../../UI/menu_entry/MenuEntry';
import { BusinessContextRef } from '../../../../BusinessContext';
import { MenuStore } from '../../../MenuStore';
import { MixedGridItemType } from '../../MixedGrid/MixedGridItem';

interface ProductMenuEntryProps
{
	menuStore: MenuStore
	minImageHeight?: number
	minImageWidth?: number
	forceGridItemType?: MixedGridItemType
	onClick?: (menuStore: MenuStore) => void
}

export const CategoryMenuEntry: FC<ProductMenuEntryProps> =
	(
		{
			menuStore,
			minImageHeight,
			minImageWidth,
			forceGridItemType,
			onClick,
		}
	) =>
	{
		const {currentPlaceService} = useContext(BusinessContextRef);

		const now = useCurrentMinute();
		const timeSchedulesIgnored = useObserver(() => currentPlaceService.place?.timeSchedulesIgnored ?? false);

		const name = useObserver(() => menuStore.category.name);

		const imageUrl = useObserver(() => menuStore.getImageUrl(now, timeSchedulesIgnored));
		const imageBackgroundColor = useObserver(() => menuStore.getImageBackgroundColor(now, timeSchedulesIgnored));
		const imageHasTextBackdrop = useObserver(() => menuStore.getImageHasTextBackdrop(now, timeSchedulesIgnored));
		const imageIsTextContrastColorDark = useObserver(() => menuStore.getImageIsTextContrastColorDark(now, timeSchedulesIgnored));
		const imageDoContain = useObserver(() => menuStore.getImageDoContain(now, timeSchedulesIgnored));

		return <MenuEntry
			image={
				imageUrl !== undefined
					? {
						imageURL: imageUrl,
						backgroundColor: imageBackgroundColor,
						imageHasTextBackdrop: imageHasTextBackdrop,
						imageIsTextContrastColorDark: imageIsTextContrastColorDark,
						containImage: imageDoContain,
					}
					: undefined
			}
			textHorizontallyCenteredWhenNoPictureAndTile
			type={forceGridItemType ?? 'TILE'}
			title={name}
			backgroundColor={menuStore.category.menuEntryColor}
			textColor={menuStore.category.menuEntryTextColor}
			minImageHeight={minImageHeight}
			minImageWidth={minImageWidth}
			onClick={() => onClick?.(menuStore)}
		/>;
	}
