import { CheckoutSessionSetupResponse } from '@adyen/adyen-web/dist/types/types';
import { makeStyles } from '@material-ui/core/styles';
import Decimal from 'decimal.js';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { Business } from '../../../../../Api/Business/Business';
import { Place } from '../../../../../Api/Business/Place';
import { ExternalBill } from '../../../../../Api/ExternalBill/ExternalBill';
import { PaymentIssuer } from '../../../../../Api/Payment/PaymentIssuer';
import { PaymentMethodDescriptor } from '../../../../../Api/Payment/PaymentMethodDescriptor';
import { extractRelevantTaxGroupSpecifications } from '../../../../../Api/vat_group/util/extractRelevantTaxGroupSpecifications';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { AdyenCheckoutData } from '../../../../../lib/adyen/AdyenCheckoutData';
import { useCurrentPlaceService } from '../../../../current-place-service/CurrentPlaceService';
import { DialogVisibilityContextProvider, useDialogVisibilityContext } from '../../../../UI/dialog/context/visibility/DialogVisibilityContext';
import { FormFocusContextProvider } from '../../../../UI/focus/context/FormFocusContext';
import { ValidationBoundary } from '../../../../UI/Form/Core/ValidationBoundary';
import { PageDialog } from '../../../../UI/PageDialog';
import { PlacePaymentContextProvider } from '../../../../UI/payment/composite/place/PlacePaymentContextProvider';
import { PaymentData } from '../../../../UI/payment/model/PaymentData';
import { AdditiveTaxGroupKey } from '../../../../UI/payment/price/context/AdditiveTaxGroupKey';
import { ProcessingOrderContextProvider } from '../../../../UI/processing_order_context/ProcessingOrderContext';
import { ValidationContextProvider } from '../../../../UI/validation/context/ValidationContext';
import { BusinessContextRef } from '../../BusinessContext';
import { BillSettler } from './BillSettler';
import { BillSettlerOrderButton } from './BillSettlerOrderButton';
import { EmailContextProvider } from './context/email/EmailContext';
import { ExternalBillContextProvider } from './context/external-orders/ExternalOrderLinessContext';
import { ExternalOrdersContextProvider, useExternalOrdersContext } from './context/external-orders/ExternalOrdersContext';
import { TipContextProvider, useTipContext } from './context/tip/TipContextProvider';
import { useExternalBill } from './hooks/external-bill/useExternalBill';
import { useExternalBillSettlerPriceAmount } from './hooks/external-bill/useExternalBillSettlerPriceAmount';
import { usePayExternalBill } from './hooks/external-bill/usePayExternalBill';
import { useExternalOrdersBillSettlerPriceAmount } from './hooks/external-orders/useExternalOrdersBillSettlerPriceAmount';
import { usePayExternalOrders } from './hooks/external-orders/usePayExternalOrders';

const useStyles = makeStyles(theme => ({
	content: {
		alignItems: 'stretch',
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		'& > :not(:last-child)': {
			marginBottom: 16,
		},
	},
	payButtonRootError: {
		backgroundColor: theme.palette.error.main,
		'&:hover': {
			backgroundColor: theme.palette.error.dark,
		},
	},
}));

interface BillSettlerDialogProps
{
	onClose: () => void;
}

export const BillSettlerDialog: FC<BillSettlerDialogProps> =
	({
		onClose,
	}) =>
	{
		const currentPlaceService = useCurrentPlaceService();

		const [showValidation, setShowValidation] = useState(false);

		const business = useObserver(() => currentPlaceService.business!);
		const place = useObserver(() => currentPlaceService.place!);

		const forms = useMemo(
			() => ['external-orders', 'payment-method', 'email'],
			[],
		);

		const startShowingValidation = useCallback(() =>
		{
			setShowValidation(true);
		}, []);

		return <DialogVisibilityContextProvider onExited={onClose}>
			<ValidationContextProvider showValidation={showValidation}>
				<ValidationBoundary>
					<FormFocusContextProvider defaultForm={'external-orders'} forms={forms}>
						<EmailContextProvider place={place}>
							<TipContextProvider
								place={place}
							>
								<ExternalBillOrOrdersViewer
									business={business}
									place={place}
									startShowingValidation={startShowingValidation}
								/>
							</TipContextProvider>
						</EmailContextProvider>
					</FormFocusContextProvider>
				</ValidationBoundary>
			</ValidationContextProvider>
		</DialogVisibilityContextProvider>;
	};

interface ViewerProps
{
	business: Business;
	place: Place;
	startShowingValidation: () => void;
}

const ExternalBillOrOrdersViewer: FC<ViewerProps> =
	props =>
	{
		const {externalBill} = useExternalBill();

		if (!externalBill)
		{
			return null;
		}
		else if (externalBill.state === 'Unsupported')
		{
			return <ExternalOrdersContextProvider>
				<ExternalOrdersViewer
					{...props}
				/>
			</ExternalOrdersContextProvider>;
		}
		else
		{
			return <ExternalBillContextProvider
				externalBill={externalBill}
			>
				<ExternalBillViewer
					{...props}
					externalBill={externalBill}
				/>
			</ExternalBillContextProvider>;
		}
	};

interface ExternalBillViewerProps extends ViewerProps
{
	externalBill: ExternalBill;
}

export const ExternalBillViewer: FC<ExternalBillViewerProps> =
	({
		externalBill,
		startShowingValidation,
	}) =>
	{
		const {businessStore} = useContext(BusinessContextRef);
		const currentPlaceService = useCurrentPlaceService();
		const translate = useTranslate();
		const {close, isOpen, onExited} = useDialogVisibilityContext();
		const classes = useStyles();
		const [isLoading, setIsLoading] = useState(false);
		const [paymentMethodHasError, setPaymentMethodHasError] = useState(false);
		const [paymentSessionHasError, setPaymentSessionHasError] = useState(false);
		const [paymentData, setPaymentData] = useState<PaymentData>({});
		const business = useObserver(() => currentPlaceService.business!);
		const place = useObserver(() => currentPlaceService.place!);
		const timeScheduleById = useObserver(() => businessStore.timeScheduleById);
		const {tip} = useTipContext();
		const baseAmount = useExternalBillSettlerPriceAmount(externalBill);
		const payExternalBill = usePayExternalBill(startShowingValidation, externalBill);

		const onOrder = useCallback((
			paymentMethod?: PaymentMethodDescriptor,
			paymentIssuer?: PaymentIssuer,
			adyenCheckoutData?: AdyenCheckoutData,
			paymentSessionSetupResponse?: CheckoutSessionSetupResponse,
		) =>
		{
			setIsLoading(true);

			return payExternalBill(paymentMethod, paymentIssuer, adyenCheckoutData, paymentSessionSetupResponse);
		}, [payExternalBill]);

		const handlePaymentMethodChange = useCallback(
			(
				paymentMethod?: PaymentMethodDescriptor,
				paymentIssuer?: PaymentIssuer,
				adyenCheckoutData?: AdyenCheckoutData,
			) =>
			{
				setPaymentData({adyenCheckoutData, paymentIssuer, paymentMethod});
			}, []);

		const showActions = useMemo(() =>
		{
			return externalBill.state === 'Open'
				|| baseAmount.gt(0)
				|| !place.paymentAfterEnabled
				|| paymentData.paymentMethod !== undefined;
		}, [baseAmount, externalBill.state, paymentData.paymentMethod, place.paymentAfterEnabled]);

		const dialogTitle = useMemo(
			() => place.paymentAfterEnabled
				? translate('Client-Order-PayBill')
				: translate('Client-Order-Bill'),
			[place.paymentAfterEnabled, translate],
		);

		const rawAdditiveTaxAmountPerTaxGroupId = useMemo(() =>
		{
			const rawAdditiveTaxAmountPerTaxGroupId = new Map<AdditiveTaxGroupKey, Decimal>();

			// external bill tax amount is always inclusive for now, so we only have to compute the additive tax on the tip amount
			if (tip !== undefined && business.tipVatGroup?.type === 'Additive')
			{
				const routingId = business.tipRouting?.id ?? place.requestBillRoutingId;
				extractRelevantTaxGroupSpecifications(business.tipVatGroup)
					.forEach(({id, percentage}) =>
					{
						const tipAdditiveTaxAmount = tip.mul(percentage);

						const key: AdditiveTaxGroupKey = `r${routingId}_t${id}`;

						if (!rawAdditiveTaxAmountPerTaxGroupId.has(key))
						{
							rawAdditiveTaxAmountPerTaxGroupId.set(key, new Decimal(0));
						}

						rawAdditiveTaxAmountPerTaxGroupId.set(
							key,
							rawAdditiveTaxAmountPerTaxGroupId.get(key)!.add(tipAdditiveTaxAmount),
						);
					});
			}

			return rawAdditiveTaxAmountPerTaxGroupId;
		}, [business.tipRouting, business.tipVatGroup, place.requestBillRoutingId, tip]);

		const baseLineRoutingIds = useMemo(() =>
		{
			return [place.requestBillRoutingId!];
		}, [place.requestBillRoutingId]);

		return <ProcessingOrderContextProvider>
			<PlacePaymentContextProvider
				business={business}
				place={place}
				baseAmount={baseAmount}
				tipAmount={tip}
				rawAdditiveTaxAmountPerTaxGroupId={rawAdditiveTaxAmountPerTaxGroupId}
				baseLineRoutingIds={baseLineRoutingIds}
				timing="afterwards"
				onPaymentSessionHasErrorChange={setPaymentSessionHasError}
				timeScheduleById={timeScheduleById}
			>
				<PageDialog
					classes={{
						content: classes.content,
					}}
					dialogActions={
						showActions &&
						<BillSettlerOrderButton
							hasError={paymentMethodHasError || paymentSessionHasError}
							loading={isLoading}
							onOrder={onOrder}
							paymentIssuer={paymentData.paymentIssuer}
							paymentMethod={paymentData.paymentMethod}
						/>
					}
					fullWidth
					maxWidth="sm"
					onClose={close}
					onExited={onExited}
					open={isOpen}
					title={dialogTitle}
				>
					<BillSettler
						externalBill={externalBill}
						isInPayment={isLoading}
						onPaymentMethodChange={handlePaymentMethodChange}
						onPaymentMethodHasErrorChange={setPaymentMethodHasError}
						paymentSessionHasError={paymentSessionHasError}
					/>
				</PageDialog>
			</PlacePaymentContextProvider>
		</ProcessingOrderContextProvider>;
	};


const ExternalOrdersViewer: FC<ViewerProps> =
	(
		{
			startShowingValidation,
		},
	) =>
	{
		const {businessStore} = useContext(BusinessContextRef);
		const currentPlaceService = useCurrentPlaceService();
		const externalOrders = useExternalOrdersContext();
		const translate = useTranslate();
		const {close, isOpen, onExited} = useDialogVisibilityContext();
		const classes = useStyles();
		const [isLoading, setIsLoading] = useState(false);
		const [paymentMethodHasError, setPaymentMethodHasError] = useState(false);
		const [paymentSessionHasError, setPaymentSessionHasError] = useState(false);
		const [paymentData, setPaymentData] = useState<PaymentData>({});
		const business = useObserver(() => currentPlaceService.business!);
		const place = useObserver(() => currentPlaceService.place!);
		const timeScheduleById = useObserver(() => businessStore.timeScheduleById);
		const {tip} = useTipContext();
		const baseAmount = useExternalOrdersBillSettlerPriceAmount();

		const payExternalOrders = usePayExternalOrders(startShowingValidation);

		const onOrder = useCallback((
			paymentMethod?: PaymentMethodDescriptor,
			paymentIssuer?: PaymentIssuer,
			adyenCheckoutData?: AdyenCheckoutData,
			paymentSessionSetupResponse?: CheckoutSessionSetupResponse,
		) =>
		{
			setIsLoading(true);

			return payExternalOrders(paymentMethod, paymentIssuer, adyenCheckoutData, paymentSessionSetupResponse);
		}, [payExternalOrders]);


		const handlePaymentMethodChange = useCallback(
			(
				paymentMethod?: PaymentMethodDescriptor,
				paymentIssuer?: PaymentIssuer,
				adyenCheckoutData?: AdyenCheckoutData,
			) =>
			{
				setPaymentData({adyenCheckoutData, paymentIssuer, paymentMethod});
			}, []);

		const showActions = useMemo(() =>
		{
			return externalOrders !== undefined
				|| baseAmount?.gt(0)
				|| !place.paymentAfterEnabled
				|| paymentData.paymentMethod !== undefined;
		}, [baseAmount, externalOrders, paymentData.paymentMethod, place.paymentAfterEnabled]);

		const dialogTitle = useMemo(
			() => place.paymentAfterEnabled
				? translate('Client-Order-PayBill')
				: translate('Client-Order-Bill'),
			[place.paymentAfterEnabled, translate],
		);

		const rawAdditiveTaxAmountPerTaxGroupId = useMemo(() =>
		{
			const rawAdditiveTaxAmountPerTaxGroupId = new Map<AdditiveTaxGroupKey, Decimal>();

			// external bill tax amount is always inclusive for now, so we only have to compute the additive tax on the tip amount
			if (tip !== undefined && business.tipVatGroup?.type === 'Additive')
			{
				const routingId = business.tipRouting?.id ?? place.requestBillRoutingId;
				extractRelevantTaxGroupSpecifications(business.tipVatGroup)
					.forEach(({id, percentage}) =>
					{
						const tipAdditiveTaxAmount = tip.mul(percentage);

						const key: AdditiveTaxGroupKey = `r${routingId}_t${id}`;

						if (!rawAdditiveTaxAmountPerTaxGroupId.has(key))
						{
							rawAdditiveTaxAmountPerTaxGroupId.set(key, new Decimal(0));
						}

						rawAdditiveTaxAmountPerTaxGroupId.set(
							key,
							rawAdditiveTaxAmountPerTaxGroupId.get(key)!.add(tipAdditiveTaxAmount),
						);
					});
			}

			return rawAdditiveTaxAmountPerTaxGroupId;
		}, [business.tipRouting, business.tipVatGroup, place.requestBillRoutingId, tip]);

		const baseLineRoutingIds = useMemo(() =>
		{
			return [place.requestBillRoutingId!];
		}, [place.requestBillRoutingId]);

		return <ProcessingOrderContextProvider>
			<PlacePaymentContextProvider
				business={business}
				place={place}
				baseAmount={baseAmount}
				tipAmount={tip}
				rawAdditiveTaxAmountPerTaxGroupId={rawAdditiveTaxAmountPerTaxGroupId}
				baseLineRoutingIds={baseLineRoutingIds}
				timing="afterwards"
				onPaymentSessionHasErrorChange={setPaymentSessionHasError}
				timeScheduleById={timeScheduleById}
			>
				<PageDialog
					classes={{
						content: classes.content,
					}}
					dialogActions={
						showActions &&
						<BillSettlerOrderButton
							hasError={paymentMethodHasError || paymentSessionHasError}
							loading={isLoading}
							onOrder={onOrder}
							paymentIssuer={paymentData.paymentIssuer}
							paymentMethod={paymentData.paymentMethod}
						/>
					}
					fullWidth
					maxWidth="sm"
					onClose={close}
					onExited={onExited}
					open={isOpen}
					title={dialogTitle}
				>
					<BillSettler
						isInPayment={isLoading}
						onPaymentMethodChange={handlePaymentMethodChange}
						onPaymentMethodHasErrorChange={setPaymentMethodHasError}
						paymentSessionHasError={paymentSessionHasError}
					/>
				</PageDialog>
			</PlacePaymentContextProvider>
		</ProcessingOrderContextProvider>;
	};
