import { useMemo } from 'react';

export function useSpacingBetweenButtonLabelElements(size: 'small' | 'medium' | 'large')
{
	return useMemo(
		() => {
			switch (size)
			{
				case 'small':
					return 8;
				case 'medium':
					return 16;
				case 'large':
					return 22;
			}
		},
		[size],
	);
}
