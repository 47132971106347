import { makeStyles, useTheme } from '@material-ui/core';
import Decimal from 'decimal.js';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext, useEffect } from 'react';
import { setInApplicationInterface } from '../../../../Util/application_interface/setInApplicationInterface';
import { Page } from '../../../UI/Page';
import { BusinessContextRef } from '../BusinessContext';
import { OrderPaymentInProgressPageCancelButton } from './cancel_button/OrderPaymentInProgressPageCancelButton';
import { OrderPaymentInProgressPageIcon } from './icon/OrderPaymentInProgressPageIcon';
import { OrderPaymentInProgressPageInformation } from './information/OrderPaymentInProgressPageInformation';
import { useMostRecentOrder } from './useMostRecentOrder';

const OrderPollingIntervalInMs = 10000;

const useStyles = makeStyles({
	content: {
		width: '50%',
		display: 'flex',
		flex: '1 1 auto',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	page: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		margin: 0,
		padding: 0,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
});

export const OrderPaymentInProgressPage: FC =
	() =>
	{
		const {businessStore} = useContext(BusinessContextRef);

		const theme = useTheme();
		const mostRecentOrder = useMostRecentOrder();
		const reloadOrder = useObserver(() => businessStore.orderService.reloadOrder);
		const paymentMethod = useObserver(() => mostRecentOrder?.paymentMethodId);
		const paymentCurrencyCode = useObserver(() => mostRecentOrder?.paymentCurrency ?? businessStore.business?.productCurrencyCode);

		const paymentAmountRemaining = useObserver(
			() =>
				mostRecentOrder?.paymentAmountRemaining === undefined
					? undefined
					: new Decimal(mostRecentOrder.paymentAmountRemaining),
		);

		const paymentAmountTotal = useObserver(
			() =>
				mostRecentOrder?.paymentPrice === undefined
					? undefined
					: new Decimal(mostRecentOrder.paymentPrice),
		);

		const classes = useStyles();

		useEffect(
			() =>
			{
				setInApplicationInterface(
					'paymentState',
					'Negotiated',
				);

				setInApplicationInterface(
					'appState',
					'InPayment',
				);
			},
			[],
		);

		useEffect(
			() =>
			{
				if (mostRecentOrder !== undefined)
				{
					const handle = setInterval(
						() =>
							reloadOrder(mostRecentOrder.id),
						OrderPollingIntervalInMs,
					);

					return () =>
						clearInterval(handle);
				}
			},
			[mostRecentOrder, reloadOrder],
		);

		return <Page
			backgroundOverlayColor={theme.palette.primary.main}
			className={classes.page}
		>
			<div
				className={classes.content}
			>
				<OrderPaymentInProgressPageIcon
					paymentMethod={paymentMethod}
				/>
				<OrderPaymentInProgressPageInformation
					paymentAmountRemaining={paymentAmountRemaining}
					paymentAmountTotal={paymentAmountTotal}
					paymentCurrencyCode={paymentCurrencyCode}
					paymentMethod={paymentMethod}
				/>
				<OrderPaymentInProgressPageCancelButton
					paymentAmountRemaining={paymentAmountRemaining}
				/>
			</div>
		</Page>;
	};
