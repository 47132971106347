import { makeObservable, observable } from 'mobx';
import { Client } from '../../Bridge/Client/Client';
import { Storage } from '../../Bridge/Storage/Storage';
import { StoredVariable } from '../../Util/StoredVariable';
import { Scanin } from './Scanin';

export class ScaninService
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	private readonly client: Client;
	private _initialized: boolean;
	public readonly onInitialized: Promise<void>;
	private resolveOnInitialized: () => void;
	private static readonly MOST_RECENT_SCANIN_STORAGE_KEY = 'mostRecentScanin';
	public readonly currentScanin: StoredVariable<Scanin | undefined>;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor(
		storage: Storage,
		client: Client,
	)
	{
		makeObservable<ScaninService, '_initialized'>(
			this,
			{
				_initialized: observable,
			},
		);
		this.client = client;
		this.currentScanin = new StoredVariable<Scanin | undefined>(
			storage,
			ScaninService.MOST_RECENT_SCANIN_STORAGE_KEY,
			stringValue => stringValue !== undefined
				?
				JSON.parse(stringValue)
				:
				undefined,
			deserializedValue => deserializedValue !== undefined
				?
				JSON.stringify(deserializedValue)
				:
				undefined
		);
		this.onInitialized = new Promise<void>(resolve => {
			this.resolveOnInitialized = resolve;
		});
		this.currentScanin.syncWithStorage();
		this.setInitialized();

		window.addEventListener(
			'storage',
			event =>
			{
				if (event.key === ScaninService.MOST_RECENT_SCANIN_STORAGE_KEY && document.location.pathname.startsWith('/place'))
				{
					if (this.currentScanin.value !== undefined && event.newValue == null)
					{
						document.location.replace('/');
					}
					else
					{
						const newValue: Scanin = JSON.parse(event.newValue);

						if (this.currentScanin.value?.placeHash !== newValue.placeHash)
							document.location.replace(`/place/${newValue.placeHash}`);
					}
				}
			},
		);
	}

	/*---------------------------------------------------------------*
	 *                         Business Logic                        *
	 *---------------------------------------------------------------*/

	private setInitialized(): void
	{
		this._initialized = true;
		this.resolveOnInitialized();
	}

	/*---------------------------------------------------------------*
	 *                      Getters and setters                      *
	 *---------------------------------------------------------------*/

	/*---------------------------------------------------------------*
	 *                        Nested classes                         *
	 *---------------------------------------------------------------*/
}