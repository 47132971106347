import { ButtonBase, createStyles, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { ReactNode, useMemo } from 'react';
import { Color } from '../../../../Api/Other/Color';
import { MenuEntryType } from '../../../../Api/Product/MenuEntryType';
import { BrandingService } from '../../../../Service/BrandingInformation/BrandingService';
import { getParagraphStylesWithoutTopAndBottomMargin } from '../../../../Util/Html/getParagraphStylesWithoutTopAndBottomMargin';
import { Image } from '../../Image/Image';
import { ImageProps } from '../MenuEntry';
import { ListItemPrimaryBadges } from './badges/ListItemPrimaryBadges';
import { ListItemSecondaryBadges } from './badges/ListItemSecondaryBadges';

// spect ratio: 1:1
const ImageWidth = 38;
const ImageHeight = 38;

const useStyles = makeStyles(() => createStyles({
    root: {
        height: '100%',
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        minHeight: 55,
    },
    textOnlyTile: {
        height: '100%',
        width: '100%',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        textAlign: 'left',
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    textOnlyListItem: {
        height: '100%',
        width: '100%',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        textAlign: 'left',
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    imageListItem: {
        height: '100%',
        width: '100%',
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'stretch',
    },
    imageListItemImageContainer: {
        width: 44,
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 6,
        paddingRight: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'middle',
    },
    imageListItemImage: {
        flex: '0 0 auto',
        width: ImageWidth,
        height: ImageHeight,
        borderRadius: 19,
        position: 'relative',
    },
    imageListItemText: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 6,
        paddingRight: 16,
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'left',
        overflow: 'hidden',
    },
    tileBarRoot: {},
    tileBarTitleWrap: {},
    tileBarTitle: {},
    title: {
        flex: '0 1 auto',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    description: {
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        ...getParagraphStylesWithoutTopAndBottomMargin(),
    },
    price: {
        flex: '0 0 auto',
    },
}));

export interface ListItemWithImageProps
{
    className?: string;
    minHeight?: number | string;
    backgroundColor: Color;
    textColor?: Color;
    image: ImageProps;
    type?: MenuEntryType;
    primaryBadges?: ReactNode[];
    secondaryBadges?: ReactNode[];
    title: string;
    description?: string;
    price?: string;
    onClick?: () => void;
    descriptionInHtml?: string;
    disabled?: boolean;
}

export const ListItemWithImage = (props: ListItemWithImageProps) => {
    const {
        className,
        image,
        backgroundColor,
        textColor,
        minHeight,
        onClick,
        description,
        primaryBadges = [],
        secondaryBadges = [],
        title,
        price,
        descriptionInHtml,
        disabled,
    } = props;
    const classes = useStyles();
    const finalTextColor = textColor ?? BrandingService.computeContrastTextColorFor(backgroundColor);

    const rootClassName = useMemo(() => clsx(classes.root, className), [className, classes.root]);

    return <ButtonBase
        focusRipple
        className={rootClassName}
        style={{
            minHeight,
            backgroundColor: backgroundColor.css,
        }}
        onClick={onClick}
        disabled={disabled}
    >
        <span className={classes.imageListItem}>
            <div className={classes.imageListItemImageContainer}>
                {
                    image === undefined
                        ? null
                        : <Image
                            compression="High"
                            path={image.imageURL}
                            height={ImageHeight}
                            width={ImageWidth}
                            className={classes.imageListItemImage}
                            fit="Cover"
                        />
                }
            </div>
            <div className={classes.imageListItemText}>
                <div
                    style={{
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        marginRight: 7,
                        flex: '1 1 auto',
                    }}
                >
                    <div>
                        <Typography
                            variant="body1"
                            className={classes.title}
                            style={{
                                color: finalTextColor.css,
                                fontWeight: 700,
                                fontFamily: '\'Raleway\', sans-serif',
                            }}
                        >
                            {title}
                        </Typography>
                    </div>
                    {
                        description !== undefined && description.length > 0 &&
                        (
                            descriptionInHtml !== undefined
                                ?
                                <Typography
                                    variant="body1"
                                    className={classes.description}
                                    style={{
                                        color: finalTextColor.css,
                                        fontWeight: 100,
                                    }}
                                    dangerouslySetInnerHTML={{__html: descriptionInHtml}}
                                />
                                :
                                <Typography
                                    variant="body1"
                                    className={classes.description}
                                    style={{
                                        color: finalTextColor.css,
                                        fontWeight: 100,
                                    }}
                                >
                                    {description}
                                </Typography>
                        )
                    }
                    <ListItemSecondaryBadges
                        secondaryBadges={secondaryBadges}
                    />
                </div>
                <Typography
                    variant="body1"
                    className={classes.price}
                    style={{
                        color: finalTextColor.css,
                    }}
                >
                    {price}
                </Typography>
                <ListItemPrimaryBadges
                    primaryBadges={primaryBadges}
                />
            </div>
        </span>
    </ButtonBase>;
};
