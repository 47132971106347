import { ButtonClassKey, SvgIconProps } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/es/styles/withStyles';
import * as React from 'react';
import { ComponentType, MouseEvent, PropsWithChildren, useMemo } from 'react';
import { ActionButtonVariant } from './ActionButtonVariant';
import { ContainedButton } from './button/contained/ContainedButton';
import { OutlinedButton } from './button/outlined/OutlinedButton';
import { PaperButton } from './button/paper/PaperButton';
import { TextButton } from './button/text/TextButton';
import { ButtonAppearance } from './ButtonAppearance';
import { ButtonSize } from './ButtonSize';
import { HorizontalAlignment } from './HorizontalAlignment';
import { useButtonOnClick } from './onclick/useButtonOnClick';
import { ButtonTooltip } from './tooltip/ButtonTooltip';

export interface ActionButtonProps<T extends ActionButtonVariant = 'contained'>
{
	disabled?: boolean
	disabledWhenLoading?: boolean
	icon?: ComponentType<SvgIconProps> | string
	text?: string | JSX.Element
	textAlign?: HorizontalAlignment
	onCancel?: () => void
	cancelled?: boolean
	paperStyle?: CSSProperties
	onClick?: (event: MouseEvent<HTMLElement>) => any | Promise<any>
	onMouseOver?: (event: MouseEvent<HTMLElement>) => void
	onMouseLeave?: (event: MouseEvent<HTMLElement>) => void
	variant?: T
	color?: ButtonAppearance<T>
	error?: boolean
	textStyle?: CSSProperties
	tooltip?: string
	iconStyle?: CSSProperties
	loading?: boolean
	classes?: Partial<Record<ButtonClassKey, string>> & {
		icon?: string
	}
	size?: ButtonSize
	fullWidth?: boolean
	style?: CSSProperties
	className?: string
	id?: string
}

export const ActionButton =
	<T extends ActionButtonVariant = 'contained'>(
		{
			children,
			disabled = false,
			fullWidth = false,
			disabledWhenLoading = true,
			icon,
			onCancel,
			onClick = () => {},
			onMouseOver,
			onMouseLeave,
			paperStyle,
			style,
			text,
			size = 'medium',
			error = false,
			textAlign = 'right',
			textStyle,
			variant = 'contained' as T,
			tooltip,
			iconStyle,
			classes,
			loading: loadingArg = false,
			color = {} as ButtonAppearance<T>,
			className,
			id,
		}: PropsWithChildren<ActionButtonProps<T>>,
	) =>
	{
		const {
			onClickIsResolving,
			moreThanZeroFailedAttempts,
			onClickSynchronous,
		} = useButtonOnClick(onClick);
		const isLoadingEffective = useMemo(
			() => loadingArg || onClickIsResolving,
			[onClickIsResolving, loadingArg]
		);
		const isDisabledEffective = useMemo(
			() => disabledWhenLoading
				? (disabled || isLoadingEffective)
				: disabled,
			[disabled, disabledWhenLoading, isLoadingEffective]
		);
		const iconOnlyPadding = useMemo(
			() => text === undefined && icon !== undefined,
			[icon, text],
		);
		const button = useMemo(
			() => {
				if (variant === 'contained')
					return <ContainedButton
						disabled={isDisabledEffective}
						onClick={onClickSynchronous}
						onMouseOver={onMouseOver}
						onMouseLeave={onMouseLeave}
						color={color as ButtonAppearance<'contained'>}
						error={error}
						loading={isLoadingEffective}
						textAlign={textAlign}
						fullWidth={fullWidth}
						classes={classes}
						style={style}
						className={className}
						textStyle={textStyle}
						iconStyle={iconStyle}
						text={text}
						icon={icon}
						moreThanZeroFailedAttempts={moreThanZeroFailedAttempts}
						size={size}
						iconOnlyPadding={iconOnlyPadding}
						id={id}
					/>;
				else if (variant === 'outlined')
					return <OutlinedButton
						disabled={isDisabledEffective}
						onClick={onClickSynchronous}
						onMouseOver={onMouseOver}
						onMouseLeave={onMouseLeave}
						color={color as ButtonAppearance<'outlined'>}
						error={error}
						loading={isLoadingEffective}
						textAlign={textAlign}
						fullWidth={fullWidth}
						classes={classes}
						style={style}
						className={className}
						textStyle={textStyle}
						iconStyle={iconStyle}
						text={text}
						icon={icon}
						moreThanZeroFailedAttempts={moreThanZeroFailedAttempts}
						size={size}
						iconOnlyPadding={iconOnlyPadding}
						id={id}
					/>;
				else if (variant === 'text')
					return <TextButton
						disabled={isDisabledEffective}
						onClick={onClickSynchronous}
						onMouseOver={onMouseOver}
						onMouseLeave={onMouseLeave}
						color={color as ButtonAppearance<'text'>}
						error={error}
						loading={isLoadingEffective}
						textAlign={textAlign}
						fullWidth={fullWidth}
						classes={classes}
						style={style}
						className={className}
						textStyle={textStyle}
						iconStyle={iconStyle}
						text={text}
						icon={icon}
						moreThanZeroFailedAttempts={moreThanZeroFailedAttempts}
						size={size}
						iconOnlyPadding={iconOnlyPadding}
						id={id}
					/>;
				else if (variant === 'paper')
					return <PaperButton
						disabled={isDisabledEffective}
						onClick={onClickSynchronous}
						onMouseOver={onMouseOver}
						onMouseLeave={onMouseLeave}
						color={color as ButtonAppearance<'paper'>}
						error={error}
						loading={isLoadingEffective}
						textAlign={textAlign}
						fullWidth={fullWidth}
						classes={classes}
						style={style}
						className={className}
						textStyle={textStyle}
						iconStyle={iconStyle}
						paperStyle={paperStyle}
						text={text}
						icon={icon}
						moreThanZeroFailedAttempts={moreThanZeroFailedAttempts}
						size={size}
						id={id}
					>
						{children}
					</PaperButton>;
				else
					return undefined;
			},
			[children, className, classes, color, error, fullWidth, icon, iconOnlyPadding, iconStyle, id, isDisabledEffective, isLoadingEffective, moreThanZeroFailedAttempts, onClickSynchronous, onMouseLeave, onMouseOver, paperStyle, size, style, text, textAlign, textStyle, variant]
		);
		if (button === undefined)
			return null;
		return <ButtonTooltip tooltip={tooltip}>
			{button}
		</ButtonTooltip>;
	};