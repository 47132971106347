export class Event
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	readonly uuid: string;
	readonly timestamp: Date;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor(
		uuid: string,
	)
	{
		this.uuid = uuid;
		this.timestamp = new Date();
	}
}