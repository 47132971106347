import * as React from 'react';
import { FC } from 'react';
import { InitializedCartLine } from '../../model/InitializedCartLine';
import { PeerShoppingCartLineSummary } from './line/PeerShoppingCartLineSummary';

interface PeerShoppingCartLinesSummaryProps
{
	lines: InitializedCartLine[];
}

export const PeerShoppingCartLinesSummary: FC<PeerShoppingCartLinesSummaryProps> =
	({
		lines,
	}) =>
	{
		return <>
			{
				lines.map(
					(line, idx) =>
						<React.Fragment
							key={line.id}
						>
							{
								idx > 0 && `, `
							}
							<PeerShoppingCartLineSummary
								line={line}
							/>
						</React.Fragment>
				)
			}
		</>;
	};