import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider/Divider';
import Grid from '@material-ui/core/Grid/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { MaxQuantityInOrderRestrictionMetadata } from '../../../../../../Api/Order/OrderRestriction/MaxQuantityInOrderRestrictionMetadata';
import { OrderThroughputOrderRestrictionMetadata } from '../../../../../../Api/Order/OrderRestriction/OrderThroughputOrderRestrictionMetadata';
import { useTranslate } from '../../../../../../Bridge/Localization/useTranslate';
import { CardBorderRadius } from '../../../../../../Util/Theme';
import { ShoppingCart } from '../../../ShoppingCart/ShoppingCart';
import { OrderBuilderStore } from '../../OrderBuilderStore';
import { ShoppingCartPriceTag } from './ShoppingCartPriceTag';

interface ShoppingCartOrderBuilderSegmentProps
{
	store: OrderBuilderStore;
	topLeftEdgeRadius?: number;
}

export const ShoppingCartOrderBuilderSegment: FC<ShoppingCartOrderBuilderSegmentProps> =
	({
		store,
		topLeftEdgeRadius,
	}) =>
	{
		const translate = useTranslate();

		const hasValidation = useObserver(() => store.showValidation && store.shoppingCartStore.validation.length > 0);
		const shoppingCartStore = useObserver(() => store.shoppingCartStore);
		const messages = useObserver(() => shoppingCartStore.messages);
		const setShoppingCartFocusHasFocus = useObserver(() => store.setShoppingCartFocusHasFocus);
		const shoppingCartHasFocus = useObserver(() => store.shoppingCartHasFocus);

		const restrictionText = useObserver(
			() =>
			{
				let nrOfAllowedProducts = undefined;
				let nrOfRelevantProducts = 0;
				const throughputReport = store.shoppingCartStore.currentOrderService.getOrderRestrictionReport('Throughput');
				if (throughputReport === undefined)
				{
					const maxQuantityInOrderReport = store.shoppingCartStore.currentOrderService.getOrderRestrictionReport('MaxQuantityInOrder');

					if (maxQuantityInOrderReport !== undefined)
					{
						const maxQuantityInOrderMetadata = maxQuantityInOrderReport.metadata as MaxQuantityInOrderRestrictionMetadata;

						nrOfAllowedProducts = Math.max(0, maxQuantityInOrderMetadata.quantityAllowed);
						nrOfRelevantProducts = store.shoppingCartStore.currentOrderService.getRelevantQuantity(maxQuantityInOrderReport.configuration);
					}
				}
				else
				{
					const throughputMetadata = throughputReport.metadata as OrderThroughputOrderRestrictionMetadata;
					nrOfAllowedProducts = Math.max(0, throughputMetadata.quantityAllowed - throughputMetadata.quantityOrdered);
					nrOfRelevantProducts = store.shoppingCartStore.currentOrderService.getRelevantQuantity(throughputReport.configuration);
				}

				if (nrOfRelevantProducts > 0)
					return `(${nrOfRelevantProducts}/${nrOfAllowedProducts})`;
				else
					return undefined;
			},
		);

		return <Grid
			item
			style={{
				flexGrow: 0,
				flexShrink: 0,
				flexBasis: 'auto',
				overflow: 'hidden',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Accordion
				expanded={shoppingCartHasFocus}
				onChange={
					(_, expanded) =>
						setShoppingCartFocusHasFocus(expanded)
				}
				style={{
					flex: '0 0 auto',
					display: 'flex',
					flexDirection: 'column',
					overflowY: 'auto',
					borderRadius: `${topLeftEdgeRadius ? topLeftEdgeRadius : CardBorderRadius}px ${CardBorderRadius}px ${CardBorderRadius}px ${CardBorderRadius}px`,
					backgroundColor: '#fff8',
				}}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					id="collapsed-shopping-cart-in-order-builder"
					style={{
						flex: '0 0 auto',
						backgroundColor: hasValidation
							? '#f001'
							: '#fff8',
						cursor: shoppingCartHasFocus ? 'initial' : undefined,
					}}
					IconButtonProps={{
						disabled: shoppingCartHasFocus,
						style: {
							width: shoppingCartHasFocus ? 0 : 48,
							paddingLeft: shoppingCartHasFocus ? 4 : 12,
							paddingRight: shoppingCartHasFocus ? 0 : 12,
							overflow: 'hidden',
							opacity: shoppingCartHasFocus ? 0 : 1,
							transitionTimingFunction: 'ease',
							transition: 'width 300ms, padding-left 300ms, padding-right 300ms, opacity 300ms',
						},
					}}
				>
					<div
						style={{
							width: '100%',
						}}
					>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<Typography variant={'body2'}>
								{translate('Client-ShoppingCart-Title')}
								{
									restrictionText &&
									` ${restrictionText}`
								}
							</Typography>
							<ShoppingCartPriceTag store={store} />
						</div>
						{
							messages.map(
								(message, idx) =>
									<Typography
										align="right"
										color={message.validation ? 'error' : 'textSecondary'}
										key={idx}
										variant="body2"
										style={{
											marginTop: 4,
											wordBreak: 'break-word',
										}}
									>
										{message.content}
									</Typography>,
							)
						}
					</div>
				</AccordionSummary>
				<Divider />
				<AccordionDetails
					style={{
						paddingTop: 0,
						paddingLeft: 0,
						paddingRight: 0,
						paddingBottom: 0,
						flex: '0 1 auto',
						display: 'flex',
						flexDirection: 'column',
						overflowY: 'hidden',
						backgroundColor: hasValidation && !shoppingCartHasFocus
							? '#f001'
							: '#fff8',
					}}
				>
					<ShoppingCart
						store={shoppingCartStore}
						style={{
							flex: '0 1 auto',
						}}
					/>
				</AccordionDetails>
			</Accordion>
		</Grid>;
	};