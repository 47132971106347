import { makeAutoObservable } from 'mobx';
import { v4 as uuid } from 'uuid';
import { ExternalOrderLineForDisplay } from '../../Component/Page/Business/ShoppingCart/Configuration/ExternalOrderLineForDisplay';
import { SerializationProfile } from '../../Util/Serialization/Serialization';
import { Money, MoneyProfile } from '../Other/Money';

export class ExternalOrderLine
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	readonly uuid: string;
	readonly quantity: number;
	readonly description: string;
	readonly descriptionSecondary?: string;
	readonly price: Money;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor(
		description: string,
		descriptionSecondary: string | undefined,
		price: Money,
		quantity: number,
	)
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});

		this.uuid = uuid();
		this.description = description;
		this.descriptionSecondary = descriptionSecondary;
		this.price = price;
		this.quantity = quantity;
	}

	/*---------------------------------------------------------------*
	 *                         Business Logic                        *
	 *---------------------------------------------------------------*/

	static combine<T extends ExternalOrderLine | ExternalOrderLineForDisplay>(lines: T[]): T[]
	{
		const lineByKey = new Map<string, T>();

		for (const line of lines)
		{
			const priceAmount = line instanceof ExternalOrderLineForDisplay
				? (line as ExternalOrderLineForDisplay).unitPriceAmount
				: (line as ExternalOrderLine).price.amount;
			const key = `${line.description}_${line.descriptionSecondary ?? 'null'}_${priceAmount.toString()}`;

			if (lineByKey.has(key))
			{
				const existingLine = lineByKey.get(key)!;
				const newQuantity = existingLine.quantity + line.quantity;

				lineByKey.set(
					key,
					(
						line instanceof ExternalOrderLineForDisplay
							?
							new ExternalOrderLineForDisplay(
								line.uuid,
								newQuantity,
								line.description,
								line.descriptionSecondary,
								line.unitPriceAmount,
							)
							:
							new ExternalOrderLine(
								existingLine.description,
								existingLine.descriptionSecondary,
								(existingLine as ExternalOrderLine).price,
								newQuantity,
							)
					) as T,
				);
			}
			else
			{
				lineByKey.set(
					key,
					(
						line instanceof ExternalOrderLineForDisplay
							?
							new ExternalOrderLineForDisplay(
								line.uuid,
								line.quantity,
								line.description,
								line.descriptionSecondary,
								line.unitPriceAmount,
							)
							:
							new ExternalOrderLine(
								line.description,
								line.descriptionSecondary,
								(line as ExternalOrderLine).price,
								line.quantity,
							)
					) as T,
				);
			}
		}

		return Array.from(lineByKey.values());
	}
}

export const ExternalOrderLineProfile = SerializationProfile.create(ExternalOrderLine)
	.profile('price', MoneyProfile);