import { useObserver } from 'mobx-react-lite';
import { useContext } from 'react';
import { BusinessContextRef } from '../../BusinessContext';

export type CurrencySymbolsVisibility = 'visible' | 'hidden';

export function useCurrencySymbolsVisibility(): CurrencySymbolsVisibility
{
	const {
		currentPlaceService,
	} = useContext(BusinessContextRef);
	const productCurrencySymbolsHidden = useObserver(
		() => currentPlaceService.place!.productCurrencySymbolsHidden
	);
	return productCurrencySymbolsHidden === true ? 'hidden' : 'visible';
}