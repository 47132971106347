import { ButtonBase, FormControl, FormControlLabel, TextField, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import { CSSProperties, FC, useMemo } from 'react';
import { useManagerFilterRouting } from '../../context/ManagerFilterRoutingContextProvider';

const useStyles = makeStyles((theme: Theme) => ({
			formPrefix: {
					[theme.breakpoints.down('xs')]:
						{
							marginRight: theme.spacing(1),
						},
					[theme.breakpoints.up('sm')]:
						{
							marginRight: theme.spacing(2),
						},
				},
			labelRoot: {
					[theme.breakpoints.down('xs')]:
						{
							padding: theme.spacing(1),
						},
					[theme.breakpoints.up('sm')]:
						{
							padding: theme.spacing(2),
						},

					justifyContent: 'space-between',
					marginLeft: 0,
					marginRight: 0,
				},
			root: {
					flexGrow: 1,
				},
		}));

interface FilterRoutingSettingProps
{
	className?: string;
	style?: CSSProperties;
}

export const FilterRoutingSetting: FC<FilterRoutingSettingProps> =
	({
		 className,
		 style,
	 }) =>
	{
		const classes = useStyles();

		const [
			filterRouting,
			setFilterRouting,
		] = useManagerFilterRouting();

		const formLabel = useMemo(
			() =>
		 <FormControlLabel
						classes={{
							root: classes.labelRoot,
						}}
						control={
							<TextField
								onChange={event => setFilterRouting(event.target.value === '' ? undefined : event.target.value)}
								value={filterRouting}
							/>
						}
						label="Filter routing:"
						labelPlacement="start"
					/>,
			[classes.labelRoot, filterRouting, setFilterRouting]);

		return <ButtonBase
			className={className}
			disableRipple
			style={style}
		>
			<FormControl
				classes={{
					root: classes.root,
				}}
			>
				{formLabel}
			</FormControl>
		</ButtonBase>;
	};