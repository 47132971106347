import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { PaymentMethodDescriptor } from '../../../../../../../Api/Payment/PaymentMethodDescriptor';
import { getFileUrl } from '../../../../../../../Util/Api/Resources/getFileUrl';

// Default Height of one-line list item content
const DefaultSingleLineHeight = 24;

// Practical number: And inset of seems to look well in single line list items
const ImageOffset = 4;

const useStyles = makeStyles({
	image: {
		marginLeft: 15,

		// Offset default List item padding (8px), maintaining an effective top / bottom padding of 4px
		marginTop: -ImageOffset,
		marginBottom: -ImageOffset,

		maxHeight: DefaultSingleLineHeight + 2 * ImageOffset,

		// Practical number: A width of 30px makes all the payment method logos in practice somewhat consistent
		maxWidth: 40,
	},
});

interface PaymentMethodImageProps
{
	className?: string;
	paymentMethod: PaymentMethodDescriptor;
}

export const PaymentMethodImage: FC<PaymentMethodImageProps> =
	({
		className,
		paymentMethod,
	}) =>
	{
		const classes = useStyles();

		const fileUrl = useMemo(() =>
		{
			const logoUrl = paymentMethod.imageUrl;

			return logoUrl?.match(/^http/) !== null ? logoUrl : getFileUrl(logoUrl);
		}, [paymentMethod.imageUrl]);

		return useMemo(() =>
		{
			if (fileUrl !== undefined && fileUrl?.trim()?.length > 0)
				return <img
					alt=""
					className={clsx(classes.image, className)}
					src={fileUrl}
				/>;
			else
				return null;
		}, [className, classes.image, fileUrl]);
	};