import { useMemo } from 'react';
import { useManagerContext } from './manager-context';

export function useManagerBusinessId(require: true): number
export function useManagerBusinessId(require?: false): number | undefined
export function useManagerBusinessId(require: boolean = false): number | undefined
{
	const {
		business,
	} = useManagerContext();
	return useMemo(
		() => {
			if (require && business === undefined)
				throw new Error(
					'Should not use OrderHandlerOrderProvider outside of ManagerContextProvider'
				);
			return business?.id;
		},
		[business, require]
	);
}