import { ButtonBase, Card, CardContent, Collapse, Dialog, IconButton, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import QrCodeScanIcon from 'mdi-material-ui/QrcodeScan';
import * as React from 'react';
import { FC, useCallback, useMemo, useState } from 'react';
import { CustomerCard } from '../../../../../../../Api/customer_card/CustomerCard';
import { CustomerCardScanner } from './scanner/CustomerCardScanner';

const useStyles = makeStyles(theme => ({
	contentRoot: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
	},
	removeButton: {
		marginRight: theme.spacing(-0.5),
	},
	root: {
		backgroundColor: '#fff8',
		width: '100%',
	},
	rootButton: {
		display: 'block',
		width: '100%',

		'&:hover': {
			backgroundColor: 'rgba(0,0,0,0.05)',
		},
	},
	rootButtonFocusVisible: {
		backgroundColor: 'rgba(0,0,0,0.05)',
	},
	rootError: {
		backgroundColor: '#f001',
	},
	scanButton: {
		backgroundColor: theme.palette.primary.main,
		borderRadius: theme.shape.borderRadius,
		color: theme.palette.primary.contrastText,

		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
}));

interface CustomerCardFormProps
{
	hasError: boolean;
	onChange: (value?: CustomerCard) => void;
	showValidation: boolean;
	value?: CustomerCard;
}

export const CustomerCardForm: FC<CustomerCardFormProps> =
	({
		hasError,
		onChange,
		showValidation,
		value,
	}) =>
	{
		const classes = useStyles();

		const [isDialogOpen, setIsDialogOpen] = useState(false);
		const openDialog = useCallback(() => setIsDialogOpen(true), []);
		const closeDialog = useCallback(() => setIsDialogOpen(false), []);

		const showError = useMemo(
			() => showValidation && hasError,
			[hasError, showValidation],
		);

		const rootClassName = clsx(
			classes.root,
			{
				[classes.rootError]: showError,
			},
		);

		const handleChange = useCallback((customerCard: CustomerCard | undefined) =>
		{
			closeDialog();
			onChange(customerCard);
		}, [closeDialog, onChange]);

		const clear = useCallback(() =>
		{
			handleChange(undefined);
		}, [handleChange]);

		return <>
			<Card
				className={rootClassName}
			>
				<ButtonBase
					onClick={openDialog}
					className={classes.rootButton}
					focusVisibleClassName={classes.rootButtonFocusVisible}
				>
					<CardContent>
						<Collapse
							in={value === undefined}
						>
							<div className={classes.contentRoot}>
								<Typography>
									Scan uw klantenkaart
								</Typography>
								<IconButton
									className={classes.scanButton}
									component="span"
									tabIndex={-1}
								>
									<QrCodeScanIcon />
								</IconButton>
							</div>
						</Collapse>
						<Collapse
							in={value !== undefined}
						>
							<div className={classes.contentRoot}>
								<Typography>
									{value?.name}
								</Typography>
								<IconButton
									className={classes.removeButton}
									component="span"
									onClick={clear}
									size="small"
									tabIndex={-1}
								>
									<CloseIcon />
								</IconButton>
							</div>
						</Collapse>
					</CardContent>
				</ButtonBase>
			</Card>
			<Dialog
				onClose={closeDialog}
				open={isDialogOpen}
			>
				<CustomerCardScanner
					onClose={closeDialog}
					onScan={handleChange}
				/>
			</Dialog>
		</>;
	};
