import UIElement from '@adyen/adyen-web/dist/types/components/UIElement';
import { PaymentAction } from '@adyen/adyen-web/dist/types/types';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { BusinessContextRef } from '../../../../Page/Business/BusinessContext';

const useStyles = makeStyles({
	loaderContainer: {
		display: 'none',

		'&:only-child': {
			display: 'initial',
		},
	},
});

interface PaymentDialogActionContentProps
{
	action: PaymentAction;
}

export const PaymentDialogActionContent: FC<PaymentDialogActionContentProps> =
	({
		action,
	}) =>
	{
		const {businessStore} = useContext(BusinessContextRef);

		const [component, setComponent] = useState<UIElement<any> | undefined>();

		const containerRef = useRef<HTMLDivElement>();

		const checkoutClient = useObserver(() => businessStore.checkoutClient);

		const classes = useStyles();

		useEffect(
			() =>
			{
				const container = containerRef.current;

				let newComponent: UIElement<any>;

				if (checkoutClient !== undefined && container !== null && component === undefined)
				{
					newComponent = checkoutClient.createFromAction(action);

					component?.unmount();

					newComponent.mount(container);

					setComponent(newComponent);

				}
			},
			[action, checkoutClient, component],
		);

		return <span
			ref={containerRef}
		>
			<div
				className={classes.loaderContainer}
			>
				<CircularProgress />
			</div>
		</span>;
	};
