import { makeAutoObservable } from 'mobx';
import { DialogProps } from './DialogProps';

export class DialogInstance
{
    // ------------------------- Properties -------------------------

    id: string;
    props: DialogProps;
    onDismiss: (instance: DialogInstance) => void;

    // ------------------------ Constructor -------------------------

    constructor(
        id: string,
        props: DialogProps,
        onDismiss: (instance: DialogInstance) => void
    )
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });

        this.id = id;
        this.props = props;
        this.onDismiss = onDismiss;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    dismiss()
    {
        this.onDismiss(this);
    }

    // ------------------------ Public logic ------------------------
    
    // ----------------------- Private logic ------------------------
}
