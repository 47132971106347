import { Button, ButtonClassKey, makeStyles, SvgIconProps, useTheme } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as React from 'react';
import { ComponentType, CSSProperties, FC, MouseEvent, useMemo } from 'react';
import { assertNoChildren } from '../../assertNoChildren';
import { ButtonAppearance } from '../../ButtonAppearance';
import { ButtonSize } from '../../ButtonSize';
import { HorizontalAlignment } from '../../HorizontalAlignment';
import { ButtonIcon } from '../../icon/ButtonIcon';
import { ButtonLoadingIdicator } from '../../loader/ButtonLoadingIdicator';
import { ButtonText } from '../../text/ButtonText';
import { useSpacingBetweenButtonLabelElements } from '../../useSpacingBetweenButtonLabelElements';
import { useTextButtonColors } from './useTextButtonColors';

interface StyleProps
{
	disabled: boolean
	buttonHoverColorCss: string
	spacingBetweenButtonLabelElements: number
	iconOnlyPadding: boolean
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
	buttonRoot: ({buttonHoverColorCss, disabled, iconOnlyPadding}) => ({
		pointerEvents: disabled ? 'none' : undefined,
		boxShadow: disabled ? 'none' : undefined,
		paddingLeft: iconOnlyPadding ? '6px !important' : undefined,
		paddingRight: iconOnlyPadding ? '6px !important' : undefined,
		minWidth: iconOnlyPadding ? '0px !important' : undefined,
		'&:hover': {
			backgroundColor: `${buttonHoverColorCss} !important`,
		},
	}),
	buttonRootConst: {
		'& *': {
			cursor: 'pointer',
		},
	},
	buttonLabel: ({spacingBetweenButtonLabelElements}) => ({
		justifyContent: 'space-between',
		'& > :not(:first-child)': {
			marginLeft: spacingBetweenButtonLabelElements,
		},
	}),
}));

interface TextButtonProps
{
	disabled: boolean
	onClick: (event: MouseEvent<HTMLElement>) => void
	onMouseOver: ((event: MouseEvent<HTMLElement>) => void) | undefined
	onMouseLeave: ((event: MouseEvent<HTMLElement>) => void) | undefined
	color: ButtonAppearance<'text'>
	error: boolean
	loading: boolean
	classes: (Partial<Record<ButtonClassKey, string>> & {
		icon?: string
	}) | undefined
	className: string | undefined
	textAlign: HorizontalAlignment
	fullWidth: boolean
	style: CSSProperties | undefined
	textStyle: CSSProperties | undefined
	iconStyle: CSSProperties | undefined
	text: JSX.Element | string | undefined
	icon: ComponentType<SvgIconProps> | string | undefined
	moreThanZeroFailedAttempts: boolean
	size: ButtonSize
	iconOnlyPadding: boolean
	id: string | undefined
}

export const TextButton: FC<TextButtonProps> =
	(
		{
			children,
			disabled,
			onClick,
			onMouseOver,
			onMouseLeave,
			color,
			error,
			loading,
			textAlign,
			classes: classesProp = {icon: undefined, label: undefined, root: undefined},
			className,
			fullWidth,
			style,
			textStyle,
			iconStyle,
			text,
			icon,
			moreThanZeroFailedAttempts,
			size,
			iconOnlyPadding,
			id,
		},
	) =>
	{
		assertNoChildren(children);

		const theme = useTheme();
		
		const {
			contentColorCss,
			buttonHoverColorCss,
			muiButtonColor,
		} = useTextButtonColors(error, disabled, color, theme);

		const spacingBetweenButtonLabelElements = useSpacingBetweenButtonLabelElements(size)
		const defaultClasses = useStyles({spacingBetweenButtonLabelElements, buttonHoverColorCss, disabled, iconOnlyPadding});

		const classes = useMemo(
			() => {
				const {icon, label, root, ...otherClasses} = classesProp;
				return {
					...otherClasses,
					label: clsx(defaultClasses.buttonLabel, label),
					root: clsx(defaultClasses.buttonRoot, defaultClasses.buttonRootConst, root),
				};
			},
			[classesProp, defaultClasses.buttonLabel, defaultClasses.buttonRoot, defaultClasses.buttonRootConst],
		);

		const loaderElement = useMemo(
			() => <ButtonLoadingIdicator
				marginLeft={0}
				marginRight={0}
				color={contentColorCss}
			/>,
			[contentColorCss],
		);

		const textElement = useMemo(
			() => typeof text === 'string' || text === undefined
				?
				<ButtonText
					style={textStyle}
					text={text}
					align={textAlign}
					moreThanZeroFailedAttempts={moreThanZeroFailedAttempts}
					marginLeft={textAlign === 'center' ? 'auto' : undefined}
					marginRight={textAlign === 'center' ? 'auto' : undefined}
					flexStretch={false}
					color={contentColorCss}
				/>
				:
				text,
			[contentColorCss, moreThanZeroFailedAttempts, text, textAlign, textStyle],
		);

		const iconElement = useMemo(
			() => <ButtonIcon
				icon={icon}
				iconStyle={iconStyle}
				className={classesProp.icon}
				replaceWithRetryIcon={moreThanZeroFailedAttempts}
				color={contentColorCss}
				marginLeft={0}
				marginRight={0}
			/>,
			[icon, iconStyle, classesProp.icon, moreThanZeroFailedAttempts, contentColorCss]
		);

		return <Button
			classes={classes}
			className={className}
			color={muiButtonColor}
			fullWidth={fullWidth}
			onClick={onClick}
			onMouseOver={onMouseOver}
			onMouseLeave={onMouseLeave}
			size={size}
			style={style}
			variant="text"
			id={id}
		>
			{
				textAlign === 'right'
					? <>
						{loading ? loaderElement : iconElement}
						{textElement}
					</>
					: <>
						{textElement}
						{loading ? loaderElement : iconElement}
					</>
			}
		</Button>;
	};
