import { useEffect, useState } from 'react';

export function useIsWindowFocused(): boolean
{
	const [isFocused, setFocused] =
		useState(
			() =>
				document.hasFocus()
		);

	useEffect(
		() =>
		{
			const target = window;
			const onFocus =
				() =>
					setFocused(true);
			const onBlur =
				() =>
					setFocused(false);

			target.addEventListener(
				'focus',
				onFocus
			);
			target.addEventListener(
				'blur',
				onBlur
			);

			return () =>
			{
				target.removeEventListener(
					'focus',
					onFocus
				);
				target.removeEventListener(
					'blur',
					onBlur
				);
			}
		},
		[]
	);

	return isFocused;
}