import { OrderDescriptor } from '../../Api/Order/OrderDescriptor';
import { isProductOrderLine } from '../order_line/isProductOrderLine';

export function hasAvatars(order: OrderDescriptor): boolean
{
	return order
		.orderLines
		.filter(
			line =>
				isProductOrderLine(line) &&
				line.imageUrl,
		)
		.length > 0;
}
