import { useMemo } from 'react';
import { PaymentMethodDescriptor } from '../../../../../../Api/Payment/PaymentMethodDescriptor';
import { useTranslate } from '../../../../../../Bridge/Localization/useTranslate';

export function usePaymentMethodValidation(selectedPaymentMethod?: PaymentMethodDescriptor): string[]
{
	const translate = useTranslate();

	return useMemo(() =>
	{
		if (selectedPaymentMethod === undefined)
			return [translate('Payment-Method-Required')];
		else
			return [];
	}, [selectedPaymentMethod, translate]);
}