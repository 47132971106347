import * as React from 'react';
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';

interface ValidityBoundaryProps
{
	onIsValidChange?: (valid: boolean) => void
}

export interface ValidationBoundaryContext
{
	setComponentValidity: (componentId: string, computeIfValid: () => boolean | undefined) => void
	exitComponent: (componentId: string) => void
	showValidation: boolean
	setShowValidation: (showValidation: boolean) => void
	isValid: boolean

	/**
	 * With this callback one can determine correctly if all validation
	 * in the validation boundary passes in the same render as
	 * the render in which said validation is registered by child components
	 */
	pollIsValid: () => boolean
}

export const ValidationBoundaryContextRef = createContext<ValidationBoundaryContext>({} as ValidationBoundaryContext);


export const ValidationBoundary: FC<ValidityBoundaryProps> =
	(
		{
			children,
			onIsValidChange = () => {},
		},
	) =>
	{
		const componentValidityRef = useRef(useMemo(() => new Map<string, () => boolean | undefined>(), []));
		const [componentValidity, setIsValid] = useReducer(
			(termsValidity: Map<string, () => boolean | undefined>, {key, computeIfValid}: {key: string, computeIfValid: (() => boolean | undefined) | undefined}) => {
				const newTermsValidity = new Map<string, () => boolean | undefined>();
				termsValidity.forEach((value, key) => newTermsValidity.set(key, value));
				if (computeIfValid !== undefined)
				{
					newTermsValidity.set(key, computeIfValid);
				}
				else
				{
					newTermsValidity.delete(key);
				}
				componentValidityRef.current = newTermsValidity;
				return newTermsValidity;
			},
			new Map<string, () => boolean | undefined>(),
		);
		const pollIsValid = useCallback(
			() => {
				const values = Array.from(componentValidityRef.current.values());
				return values
					.map(computeIfValid => computeIfValid())
					.filter(isValid => isValid !== undefined)
					.every(isValid => isValid);
			},
			[]
		);
		const allIsValid = useMemo(
			() => {
				const values = Array.from(componentValidity.values());
				return values
					.map(computeIfValid => computeIfValid())
					.filter(isValid => isValid !== undefined)
					.every(isValid => isValid);
			},
			[componentValidity]
		);

		const onIsValidChangeRef = useRef(onIsValidChange);
		onIsValidChangeRef.current = onIsValidChange;
		useEffect(
			() => {
				onIsValidChangeRef.current(allIsValid);
			},
			[allIsValid]
		)

		const setComponentValidity = useCallback(
			(componentId: string, computeIfValid: () => boolean | undefined) => {
				setIsValid({key: componentId, computeIfValid});
			},
			[]
		);
		
		const exitComponent = useCallback(
			(componentId: string) => {
				setIsValid({key: componentId, computeIfValid: undefined});
			},
			[]
		);

		const [showValidation, setShowValidation] = useState(false);

		return <ValidationBoundaryContextRef.Provider
			value={useMemo(() => ({
				setComponentValidity,
				exitComponent,
				showValidation,
				setShowValidation,
				isValid: allIsValid,
				pollIsValid,
			}), [allIsValid, exitComponent, pollIsValid, setComponentValidity, showValidation])}
		>
			{children}
		</ValidationBoundaryContextRef.Provider>;
	};

export function usePollIsValidationBoundaryValid()
{
	return useContext(ValidationBoundaryContextRef).pollIsValid;
}
