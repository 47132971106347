import { Button, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { FC } from 'react';
import { useTranslate } from '../../../../../../../Bridge/Localization/useTranslate';
import { useBrandingService } from '../../../../../../branding-service/BrandingServiceProvider';

export const ProductDrawerConfigurationStepForwardButtonHeight = 70;

const useStyles =
	makeStyles(
		theme => ({
			root: {
				paddingLeft: theme.spacing(12),
				paddingRight: theme.spacing(12),
				paddingTop: theme.spacing(1),
				paddingBottom: theme.spacing(1),
				borderRadius: theme.shape.borderRadius,
				height: ProductDrawerConfigurationStepForwardButtonHeight,
			},
			label: {
				fontSize: '1.5rem',
				textTransform: 'initial',
				fontWeight: 500,
			},
		})
	);

interface ProductDrawerConfigurationStepForwardButtonProps
{
	onClick: () => void;
	finalStep?: boolean;
}

export const ProductDrawerConfigurationStepForwardButton: FC<ProductDrawerConfigurationStepForwardButtonProps> =
	(
		{
			onClick,
			finalStep,
		},
	) =>
	{
		const classes = useStyles();
		const brandingService = useBrandingService();
		const translate = useTranslate();

		return <Button
			onClick={onClick}
			variant="contained"
			className={classes.root}
			style={{
				backgroundColor: brandingService.tintColor.css,
				color: brandingService.tintContrastTextColor.css,
			}}
		>
			<Typography
				variant="body1"
				className={classes.label}
			>
				{
					finalStep
						? translate('Generic-Order')
						: translate('Generic-Next')
				}
			</Typography>
		</Button>;
	};
