import { useCallback, useState } from 'react';
import { PaymentIssuer } from '../../../../../../Api/Payment/PaymentIssuer';
import { useAdyenCheckoutContext } from '../../../adyen/checkout/AdyenCheckoutContext';
import { usePaymentMethodContext } from '../../../method/context/PaymentMethodContext';

export function useSelectedPaymentIssuer(
	paymentIssuerById: Map<string, PaymentIssuer>,
	onChange: (paymentIssuer: PaymentIssuer | undefined) => void,
): [PaymentIssuer | undefined, (paymentIssuerId?: string) => void]
{
	const {setPaymentSettingsHasError} = useAdyenCheckoutContext();
	const {selectedPaymentMethod} = usePaymentMethodContext();

	const [selectedPaymentIssuer, setSelectedPaymentIssuer] = useState<PaymentIssuer | undefined>();

	const updateSelectedPaymentIssuer = useCallback(
		(paymentIssuer: PaymentIssuer | undefined) =>
		{
			setSelectedPaymentIssuer(paymentIssuer);
			onChange(paymentIssuer);
		},
		[onChange],
	);

	const handleSelectPaymentIssuer = useCallback((paymentIssuerId?: string) =>
	{
		if (selectedPaymentIssuer?.id !== paymentIssuerId)
		{
			if (paymentIssuerId === undefined)
			{
				updateSelectedPaymentIssuer(undefined);
			}
			else if (paymentIssuerById.has(paymentIssuerId))
			{
				const newPaymentIssuer = paymentIssuerById.get(paymentIssuerId);

				if (selectedPaymentIssuer?.id !== newPaymentIssuer?.id)
					updateSelectedPaymentIssuer(newPaymentIssuer);

				if (selectedPaymentMethod?.isIssuerRequired)
					setPaymentSettingsHasError(
						selectedPaymentMethod?.isIssuerRequired
						&& newPaymentIssuer === undefined,
					);
			}
			else
			{
				updateSelectedPaymentIssuer(undefined);
			}
		}
	}, [paymentIssuerById, selectedPaymentIssuer?.id, selectedPaymentMethod?.isIssuerRequired, setPaymentSettingsHasError, updateSelectedPaymentIssuer]);

	return [selectedPaymentIssuer, handleSelectPaymentIssuer];
}