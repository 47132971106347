import { OrderEvent, OrderEventProfile } from '../../../../Api/Order/OrderEvent';
import { getPlainList } from '../../../../Util/Api';

export function fetchOrderEvents(orderId: number): Promise<OrderEvent[]>
{
	return getPlainList(
		`/order/event/all`,
		{orderId},
		OrderEventProfile,
	);
}