import * as React from 'react';
import { ContextWithProvider } from '../../Bridge/Navigator/ContextWithProvider';
import { FunctionComponent } from 'react';

interface MultiProviderProps
{
    contexts: ContextWithProvider<any>[]
}

export const MultiProvider: FunctionComponent<MultiProviderProps> = (props) => {
    const {
        contexts,
        children,
    } = props;
    if (contexts.length > 0)
    {
        const context = contexts[0];
        const remainingContexts = contexts.slice(1);
        return <context.provider
            value={context.context}
        >
            <MultiProvider contexts={remainingContexts}>
                {children}
            </MultiProvider>
        </context.provider>
    }
    else
    {
        return <>
            {children}
        </>;
    }
};
