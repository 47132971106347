import { postJsonAny } from '../../Util/Api';

export async function pickUpOrderLines(
	pickupOrderLinesRequest: PickupOrderLinesRequest
): Promise<void>
{
	await postJsonAny(
		'/waiter/business/product-order-line/pick-up',
		{},
		pickupOrderLinesRequest
	);
}

export interface PickupOrderLinesRequest
{
	orderLineUuids: string[]
}