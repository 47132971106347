import * as React from 'react';
import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { capitalizeFirst } from '../../../../../../../Api/Util/capitalizeFirst';
import { IllegalStateException } from '../../../../../../../Util/Exception/IllegalStateException';
import { transformToCamelCase } from '../../../../../../../Util/Serialization/Transformers/transformToCamelCase';
import { useStoredVariable } from '../../../../../../../Util/useStoredVariable';
import { SortDirection } from './SortDirection';

interface Context
{
	sortDirection: SortDirection | undefined
	toggleSortDirection: () => void
}

const ContextRef = createContext<Context | undefined>(undefined);

interface SortDirectionProviderProps
{
	orderListId: string
}

export const OrderListSortDirectionProvider: FC<SortDirectionProviderProps> =
	(
		{
			orderListId,
			children,
		},
	) =>
	{
		const storageKey = useMemo(
			() => `orderHandlerSortDirection${capitalizeFirst(transformToCamelCase(orderListId))}`,
			[orderListId]
		);
		const [sortDirection, setSortDirection] = useStoredVariable<SortDirection>(
			storageKey,
			stringValue => stringValue === 'asc'
				? 'asc'
				: 'desc',
			sortDirection => sortDirection === 'asc'
				? 'asc'
				: undefined,
		);
		const toggleSortDirection = useCallback(
			() => setSortDirection(
				sortDirection === 'asc'
					? 'desc'
					: 'asc'
			),
			[setSortDirection, sortDirection]
		);
		return <ContextRef.Provider
			value={useMemo(() => ({
				sortDirection,
				toggleSortDirection,
			}), [sortDirection, toggleSortDirection])}
		>
			{children}
		</ContextRef.Provider>;
	};

export function useOrderListSortDirection()
{
	const context = useContext(ContextRef);
	return useMemo(
		() => requireNonNull(context).sortDirection,
		[context]
	);
}

export function useToggleOrderListSortDirection()
{
	const context = useContext(ContextRef);
	return useMemo(
		() => requireNonNull(context).toggleSortDirection,
		[context]
	);
}

function requireNonNull(context: Context | undefined): Context
{
	if (context === undefined)
		throw new IllegalStateException(
			'Attempting to use sort direction outside of SortDirectionProvider'
		);
	return context;
}
