import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import { useObserver } from 'mobx-react-lite';
import { FC } from 'react';
import { ComoBasketRequirements } from '../ComoBasketRequirements/ComoBasketRequirements';
import { ComoRewardStore } from '../ComoRewardStore';

interface ComoItemsSelectionsProps
{
	store: ComoRewardStore;
}

export const ComoItemsSelections: FC<ComoItemsSelectionsProps> =
	({
		store,
	}) =>
	{
		const basketRequirements = useObserver(() => store.basketRequirements);

		if (basketRequirements.length === 0)
		{
			throw new Error('Failed to render item selections: Reward has no basket requirements');
		}
		else
		{
			return <List
				disablePadding
			>
				{
					basketRequirements.map(
						(value, idx) =>
							<ListItem
								disableGutters
								divider={idx !== basketRequirements.length - 1}
								key={idx}
							>
								<ComoBasketRequirements
									basketRequirements={value}
									store={store}
								/>
							</ListItem>,
					)
				}
			</List>;
		}
	};
