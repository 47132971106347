import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import Decimal from 'decimal.js';
import * as React from 'react';
import { CSSProperties, FC, useMemo } from 'react';
import { OrderDescriptor } from '../../../../../../Api/Order/OrderDescriptor';
import { useLocalizer } from '../../../../../../Bridge/Localization/useLocalizer';
import { UIColors } from '../../../../../../Constants/UIColors';
import { getNameValue } from '../../../../../../Util/address/getNameValue';
import { getSingleLineValue } from '../../../../../../Util/address/getSingleLineValue';
import { hasAvatars } from '../../../../../../Util/Orders/hasAvatars';
import { OrderListOrder } from '../../context/order/OrderListOrder';
import { OrderLine } from './OrderLine';
import { OrderListItemHeader } from './OrderListItemHeader';

const useStyles = makeStyles(theme => ({
	actions: {
		[theme.breakpoints.up('sm')]: {
			flexDirection: 'column',
		},
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'row-reverse',
		},
		display: 'flex',
		justifyContent: 'flex-start',
	},
	button: {
		borderRadius: 0,
		flexGrow: 1,
		maxWidth: 64,
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		paddingTop: 4,
		paddingBottom: 8,
	},
	contentForSplitOrder: {
		backgroundColor: UIColors.grey,
		borderRadius: theme.shape.borderRadius,
		marginTop: 6,
		marginLeft: 8,
		marginRight: 8,
		marginBottom: 8,
	},
	orderDataLine: {
		paddingLeft: 8,
		paddingRight: 8,
	},
	orderLines: {
		padding: theme.spacing(1),
	},
	root: {
		backgroundColor: UIColors.grey,
		borderRadius: theme.shape.borderRadius,
		margin: theme.spacing(1),
		display: 'flex',
		flexDirection: 'column',
	},
}));

export interface Props
{
	onClick?: (event: any, order: OrderDescriptor) => void
	order: OrderListOrder
	style?: CSSProperties
}

export const OrderListItem: FC<Props> =
	(
		{
			onClick,
			order: {
				linesSplitOverMoreLists,
				order,
				visibleLinesInThisList,
			},
			style,
		},
	) =>
	{
		const classes = useStyles();
		const localizer = useLocalizer();

		const addressLabel = useMemo(
			() =>
			{
				if (order.address !== undefined)
				{
					const nameValue = getNameValue(order.address);
					const singleLineValue = getSingleLineValue(order.address);

					return nameValue === undefined
						? singleLineValue
						: `${nameValue}, ${singleLineValue}`;
				}
			},
			[order.address],
		);
		
		const scheduledForDate = useMemo(
			() =>
				order.state !== 'scheduled' && order.scheduledFor !== undefined
					? new Date(order.scheduledFor)
					: undefined,
			[order.scheduledFor, order.state],
		);

		const tipDecimal = useMemo(
			() =>
				order.tip !== undefined && order.tip > 0
					? new Decimal(order.tip)
					: undefined,
			[order.tip],
		);
		
		return <div
			className={classes.root}
			onClick={(event) => {
				if (onClick)
					onClick(event, order);
			}}
			style={{
				backgroundColor: linesSplitOverMoreLists ? '#535353FF' : undefined,
				...style,
			}}
		>
			<OrderListItemHeader
				order={order}
				linesSplitOverMoreLists={linesSplitOverMoreLists}
			/>
			{order.clientName && <div className={classes.orderDataLine}>
				{order.clientName && <>
					<Typography color="textSecondary" style={{display: 'inline', color: linesSplitOverMoreLists ? 'white' : undefined}}>
						{`${localizer.translate('Order-Handler-Client-Name')}: `}
					</Typography>
					<Typography color="textPrimary" style={{display: 'inline', color: linesSplitOverMoreLists ? 'white' : undefined}}>
						{order.clientName}
					</Typography>
				</>}
			</div>}
			{order.paymentReference && <div className={classes.orderDataLine}>
				<Typography color="textSecondary" style={{display: 'inline', color: linesSplitOverMoreLists ? 'white' : undefined}}>
					{`${localizer.translate('Order-Payment-Reference')}: `}
				</Typography>
				<Typography color="textPrimary" style={{display: 'inline', color: linesSplitOverMoreLists ? 'white' : undefined}}>
					{order.paymentReference}
				</Typography>
			</div>}
			{order.comment && <div className={classes.orderDataLine}>
				<Typography color="textSecondary" style={{display: 'inline', color: linesSplitOverMoreLists ? 'white' : undefined}}>
					{`${localizer.translate('Order-Handler-Order-Comment')}: `}
				</Typography>
				<Typography color="textPrimary" style={{display: 'inline', color: linesSplitOverMoreLists ? 'white' : undefined}}>
					{order.comment}
				</Typography>
			</div>}
			{order.clientPhoneNumber && <div className={classes.orderDataLine}>
				<Typography color="textSecondary" style={{display: 'inline', color: linesSplitOverMoreLists ? 'white' : undefined}}>
					{`${localizer.translate('Order-Handler-Client-Phone')}: `}
				</Typography>
				<Typography color="textPrimary" style={{display: 'inline', color: linesSplitOverMoreLists ? 'white' : undefined}}>
					{order.clientPhoneNumber}
				</Typography>
			</div>}
			{order.pickupCode && <div className={classes.orderDataLine}>
				<Typography color="textSecondary" style={{display: 'inline', color: linesSplitOverMoreLists ? 'white' : undefined}}>
					{`${localizer.translate('Order-Handler-Order-Pickup-Id')}: `}
				</Typography>
				<Typography color="textPrimary" style={{display: 'inline', color: linesSplitOverMoreLists ? 'white' : undefined}}>
					{order.pickupCode}
				</Typography>
			</div>}
			{addressLabel !== undefined && <div className={classes.orderDataLine}>
				<Typography color="textSecondary" style={{display: 'inline', color: linesSplitOverMoreLists ? 'white' : undefined}}>
					{`${localizer.translate('Order-Handler-Order-Delivery-Address')}: `}
				</Typography>
				<Typography color="textPrimary" style={{display: 'inline', color: linesSplitOverMoreLists ? 'white' : undefined}}>
					{addressLabel}
				</Typography>
			</div>}
			{scheduledForDate !== undefined && <div className={classes.orderDataLine}>
				<Typography color="textSecondary" style={{display: 'inline', color: linesSplitOverMoreLists ? 'white' : undefined}}>
					{`${localizer.translate(
						order.destinationType === 'ADDRESS' || order.destinationType === 'PLACE'
							?
							'Order-Handler-Order-Scheduled-Delivery-Time'
							:
							'Order-Handler-Order-Scheduled-Pickup-Time'
					)}: `}
				</Typography>
				<Typography color="textPrimary" style={{display: 'inline', color: linesSplitOverMoreLists ? 'white' : undefined}}>
					{
						scheduledForDate.getFullYear() === new Date().getFullYear()
						&& scheduledForDate.getMonth() === new Date().getMonth()
						&& scheduledForDate.getDay() === new Date().getDay()
							? undefined
							: <>{scheduledForDate.toLocaleDateString()}&nbsp;</>
					}
					{scheduledForDate.toLocaleTimeString([], {hour: 'numeric', minute: '2-digit'})}
				</Typography>
			</div>}
			{tipDecimal !== undefined && <div className={classes.orderDataLine}>
				<Typography color="textSecondary" style={{display: 'inline', color: linesSplitOverMoreLists ? 'white' : undefined}}>
					{`${localizer.translate('Order-Field-Tip')}: `}
				</Typography>
				<Typography color="textPrimary" style={{display: 'inline', color: linesSplitOverMoreLists ? 'white' : undefined}}>
					{localizer.formatCurrency(tipDecimal, order.currency)}
				</Typography>
			</div>}
			<div
				className={clsx(classes.content, linesSplitOverMoreLists && classes.contentForSplitOrder)}
			>
				{
					visibleLinesInThisList?.map((orderLine, i) => <OrderLine
						alignWithAvatars={hasAvatars(order)}
						collapsed
						key={orderLine.id}
						orderLine={orderLine}
						showPriceAsCurrency={order.currency
					}
						style={{
							paddingTop: i > 0 ? 8 : 0,
							paddingBottom: 0,
						}}
					/>)
				}
			</div>
		</div>;
	};