import Decimal from 'decimal.js';
import { OrderLineDescriptor } from '../../../../../Api/Order/OrderLineDescriptor';

export function mergeOrderLines(sortedOrderLines: OrderLineDescriptor[]): OrderLineDescriptor[]
{
	const mergedOrderLines: OrderLineDescriptor[] = [];

	sortedOrderLines
		.forEach(line =>
		{
			switch (line.type)
			{
				case 'fee':
				{
					const latestLine = mergedOrderLines.pop();

					if (latestLine !== undefined && latestLine.type === 'fee' && latestLine.feeType === line.feeType && latestLine.quantity === line.quantity)
					{
						mergedOrderLines.push(
							{
								...latestLine,
								discount: getCombinedDiscount(latestLine, line),
								price: new Decimal(latestLine.price).add(line.price).toNumber(),
								// vat amount cannot be determined reliably, due to rounding errors.
								vatAmount: undefined,
							}
						);
					}
					else
					{
						mergedOrderLines.push(latestLine, line);
					}

					break;
				}
				case 'tax':
				{
					const latestLine = mergedOrderLines.pop();

					if (latestLine !== undefined && latestLine.type === 'tax' && latestLine.vatGroupId === line.vatGroupId && latestLine.quantity === line.quantity)
					{
						mergedOrderLines.push(
							{
								...latestLine,
								discount: getCombinedDiscount(latestLine, line),
								price: new Decimal(latestLine.price).add(line.price).toNumber(),
								// vat amount cannot be determined reliably, due to rounding errors.
								vatAmount: undefined,
							}
						);
					}
					else
					{
						mergedOrderLines.push(latestLine, line);
					}

					break;
				}
				case 'tip':
				{
					const latestLine = mergedOrderLines.pop();

					if (latestLine !== undefined && latestLine.type === 'tip' && latestLine.quantity === line.quantity)
					{
						mergedOrderLines.push(
							{
								...latestLine,
								discount: getCombinedDiscount(latestLine, line),
								price: new Decimal(latestLine.price).add(line.price).toNumber(),
								// vat amount cannot be determined reliably, due to rounding errors.
								vatAmount: undefined,
							}
						);
					}
					else
					{
						mergedOrderLines.push(latestLine, line);
					}

					break;
				}
				default:
				{
					mergedOrderLines.push(line);
				}
			}
		});

	return mergedOrderLines;
}

function getCombinedDiscount(firstLine: OrderLineDescriptor, secondLine: OrderLineDescriptor)
{
	if (firstLine.discount === undefined && secondLine.discount === undefined)
		return undefined;
	else
		return new Decimal(firstLine.discount ?? 0).add(secondLine.discount ?? 0).toNumber();
}