import * as React from 'react';
import { FC } from 'react';
import { useInitializedPeerCartLines } from './api/useInitializedPeerCartLines';
import { PeerShoppingCart } from './cart/PeerShoppingCart';

interface PeerShoppingCartsProps
{

}

export const PeerShoppingCarts: FC<PeerShoppingCartsProps> =
	() =>
	{
		const lines = useInitializedPeerCartLines();

		if (lines.length > 0)
		{
			return <PeerShoppingCart
				lines={lines}
			/>;
		}
		else
		{
			return null;
		}
	};