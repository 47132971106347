import { Typography } from '@material-ui/core';
import { Plus } from 'mdi-material-ui';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext, useMemo } from 'react';
import { Product } from '../../../../../../Api/Product/Product';
import { ProductRecommendation } from '../../../../../../Api/Product/ProductRecommendation';
import { useLocalizer } from '../../../../../../Bridge/Localization/useLocalizer';
import { useCurrentPlaceService } from '../../../../../current-place-service/CurrentPlaceService';
import { ActionButton } from '../../../../../UI/action-button/ActionButton';
import { BusinessContextRef } from '../../../BusinessContext';
import { computePriceLabel } from '../../../Menu/Api/computePriceLabel';

interface RecommendedProductConfigurationOptionProps
{
	productRecommendation: ProductRecommendation;
	onClickAdd?: (product: Product) => void;
}

export const RecommendedProductConfigurationOption: FC<RecommendedProductConfigurationOptionProps> =
	({
		productRecommendation,
		onClickAdd,
	}) =>
	{
		const localizer = useLocalizer();
		const {businessStore} = useContext(BusinessContextRef);
		const currentPlaceService = useCurrentPlaceService();

		const business = useObserver(() => businessStore.business);
		const recommendedProduct = useObserver(() => businessStore.productById.get(productRecommendation.recommendedProductId)!);
		const place = useObserver(() => currentPlaceService.place);

		const priceLabel = useMemo(
			() =>
				computePriceLabel(recommendedProduct, business, place, localizer),
			[business, localizer, place, recommendedProduct],
		);

		return <>
			<div
				key={productRecommendation.uuid}
				style={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Typography
					variant="body1"
					style={{
						flex: '1 1 auto',
					}}
				>
					{recommendedProduct.name}
				</Typography>
				{
					priceLabel !== undefined &&
					<Typography
						variant="body1"
					>
						{priceLabel}
					</Typography>
				}
				<ActionButton
					style={{
						marginLeft: 8,
						marginTop: 8,
						marginBottom: 8,
					}}
					icon={Plus}
					onClick={() => onClickAdd?.(recommendedProduct)}
					variant="contained"
				/>
			</div>
		</>;
	};
