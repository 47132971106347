import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { FC } from 'react';
import { SchedulerSummaryLabel } from './SchedulerSummaryLabel';
import { SchedulerSummaryTime } from './SchedulerSummaryTime';

const useStyles = makeStyles({
	root: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		width: '100%',
	},
});

export const SchedulerSummary: FC =
	() =>
	{
		const classes = useStyles();

		return <div
			className={classes.root}
		>
			<SchedulerSummaryLabel />
			<SchedulerSummaryTime />
		</div>;
	};
