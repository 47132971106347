import { Exception } from './Exception';
import { AxiosError } from 'axios';

export class HttpRequestException extends Exception
{
	// ------------------------- Properties -------------------------

	readonly name: string = 'HttpRequestException';
	readonly url?: string;

	// ------------------------ Constructor -------------------------

	constructor(cause?: AxiosError, message?: string)
	{
		super(
			message !== undefined
				? message
				: `Received ${cause.response?.status ?? cause.response?.statusText ?? cause.code} requesting ${cause.config.method?.toUpperCase()} "${cause.config.url}"${cause.response?.data ? `, data: ${JSON.stringify(cause.response.data)}` : ''}`,
			cause,
		);
		this.url = cause?.config.url;
	}

	// ----------------------- Initialization -----------------------

	// -------------------------- Computed --------------------------

	// -------------------------- Actions ---------------------------

	// ------------------------ Public logic ------------------------

	// ----------------------- Private logic ------------------------
}