import { makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useTranslation } from '../../../../../../../Bridge/Localization/useTranslation';
import { useFormFocus } from '../../../../../../UI/focus/context/FormFocusContext';
import { EmailForm } from '../../../../../../UI/Form/EmailForm/EmailForm';
import { useValidationContext } from '../../../../../../UI/validation/context/ValidationContext';
import { OrderBuilderExpansionPanel } from '../../../../OrderBuilder/core/OrderBuilderExpansionPanel';
import { useEmailContext } from '../../context/email/EmailContext';

export const EmailBillSettlerSegment: FC =
	() =>
	{
		const {email, setEmail, support} = useEmailContext();

		const disallowed = useMemo(() => support === 'DISALLOWED', [support]);
		const required = useMemo(() => support === 'REQUIRED', [support]);

		if (disallowed)
			return null;
		else
			return <Inner email={email} required={required} setEmail={setEmail} />;
	};

const useStyles = makeStyles({
	emailForm: {
		marginBottom: '10px !important',
		marginTop: '10px !important',
	},
	summary: {
		display: 'flex',
		flex: '1 1 auto',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
});

interface InnerProps
{
	email?: string;
	required: boolean;
	setEmail: Dispatch<SetStateAction<string | undefined>>;
}

const Inner: FC<InnerProps> =
	({
		email,
		required,
		setEmail,
	}) =>
	{
		const showValidation = useValidationContext();

		const classes = useStyles();

		const [isValid, setIsValid] = useState(true);
		const [focused, setFocused] = useFormFocus('email');

		const emailAddressLabel = useTranslation('Generic-Email-Address');

		const summary = useMemo(() =>
		{
			return <div className={classes.summary}>
				<Typography variant="body1" color="textPrimary">
					{emailAddressLabel}
				</Typography>
				<Typography variant="body1" color="textSecondary">
					{email}
				</Typography>
			</div>;
		}, [classes.summary, email, emailAddressLabel]);

		const handleChange = useCallback((newEmail: string) =>
		{
			const trimmedNewEmail = newEmail.trim();

			setEmail(trimmedNewEmail.length === 0 ? undefined : trimmedNewEmail);
		}, [setEmail]);

		return <OrderBuilderExpansionPanel
			error={!isValid && showValidation}
			expanded={focused}
			onExpandedChange={setFocused}
			summary={summary}
		>
			<EmailForm
				className={classes.emailForm}
				fullWidth
				margin="dense"
				onChange={handleChange}
				onIsValidChange={setIsValid}
				required={required}
				showValidation={showValidation}
				value={email ?? ''}
			/>
		</OrderBuilderExpansionPanel>;
	};
