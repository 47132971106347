import { v4 as uuid } from 'uuid';

export abstract class CartChange
{
    // ------------------------- Properties -------------------------

    public type: string;
    public cartId: number;
    public uuid: string;

    // ------------------------ Constructor -------------------------

    constructor(type: string, cartId: number)
    {
        this.type = type;
        this.cartId = cartId;
        this.uuid = uuid();
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
