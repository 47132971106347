import { useObserver } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { useIsWindowFocused } from '../../../../Util/focus/useIsWindowFocused';
import { useCurrentOrderService } from '../BusinessContext';
import { useWebSocketService } from '../useWebSocketService';

interface ShoppingCartPulserProps
{

}

export const ShoppingCartPulser: FC<ShoppingCartPulserProps> =
	() =>
	{
		const websocketService = useWebSocketService(true);
		const currentOrderService = useCurrentOrderService(true);
		const isFocused = useIsWindowFocused();

		const cartId = useObserver(() => currentOrderService.ownCartId);
		const orderProcessing = useObserver(() => currentOrderService.orderProcessing);

		useEffect(
			() =>
			{
				if (cartId !== undefined && !orderProcessing && isFocused)
				{
					websocketService.sendCartPulse(cartId);

					const interval =
						setInterval(
							() =>
							{
								websocketService.sendCartPulse(cartId);
							},
							60000,
						);

					return () =>
						clearInterval(interval);
				}
			},
			[
				cartId,
				isFocused,
				orderProcessing,
				websocketService,
			],
		);

		return null;
	};
