import { trimLeadingSlashes } from '../../trimLeadingSlashes';
import { trimTrailingSlashes } from '../../trimTrailingSlashes';

/**
 * Gets the complete URL of an authorization server resource
 *
 * @param resource the relative URL of the resource
 * @return complete URL for the resource
 */
export function getAuthUrl(resource: string): string
{
	return `${trimTrailingSlashes(process.env.REACT_APP_AUTH_BASE_URL)}/${trimLeadingSlashes(resource)}`;
}
