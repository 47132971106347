import { action, makeObservable, observable } from 'mobx';
import { CartChange } from './CartChange';
import { ChangeQuantityCartChangeVariant } from './ChangeQuantityCartChangeVariant';

export class ChangeQuantityCartChange extends CartChange
{
    // ------------------------- Properties -------------------------

    /**
     * @deprecated
     */
    public productConfigurationId: string;

    public productId: number;

    public variants: ChangeQuantityCartChangeVariant[];

    public diff: number;

    // ------------------------ Constructor -------------------------

    constructor(
        cartId: number,
        productConfiguration: {productId: number, variants: ChangeQuantityCartChangeVariant[]},
        diff: number
    )
    {
        super('change_quantity', cartId);

        makeObservable(
            this,
            {
                diff: observable,
                setDiff: action.bound,
            },
        );

        this.productId = productConfiguration.productId;
        this.variants = productConfiguration.variants;
        this.diff = diff;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    public setDiff(diff: number)
    {
        this.diff = diff;
    }

    // ----------------------- Private logic ------------------------
}
