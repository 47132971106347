// Polyfill required for react-native
const URLSearchParams = require('url-search-params');

/**
 * Encode record of parameters to {@link URLSearchParams}.
 *
 * @param params parameters to encode
 * @return parameters encoded as {@link URLSearchParams}
 */
export function encodeUrlParams(params?: Record<string, any>): URLSearchParams
{
	const encodedParams = new URLSearchParams();

	for (let key in params)
	{
		if (params.hasOwnProperty(key) && params[key] !== undefined)
		{
			let value = params[key];
			value = value instanceof Object ? JSON.stringify(value) : value;
			encodedParams.append(key, value);
		}
	}

	return encodedParams;
}