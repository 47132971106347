import { Box, Typography, Zoom } from '@material-ui/core';
import Decimal from 'decimal.js';
import * as React from 'react';
import { FC, useContext } from 'react';
import { EntranceContextRef } from '../../../Entrance/EntranceContext';

interface ComoPointsBadgeProps
{
	points: number;
	decimalPlaces: number;
}


export const ComoPointBadge: FC<ComoPointsBadgeProps> =
	({
		points,
		decimalPlaces,
	}) =>
	{
		const {brandingService} = useContext(EntranceContextRef);

		const convertedPoints = new Decimal(points)
			.mul(
				new Decimal(10)
					.pow(-decimalPlaces),
			)
			.toString();

		return <Zoom
			in
		>
			<Box
				sx={{
					borderRadius: '50%',
					// Solved in newer versions of Material-UI:
					// @ts-ignore
					backgroundColor: brandingService.tintColor.css,
					color: brandingService.tintContrastTextColor.css,
					aspectRatio: '1 / 1',
					padding: '4px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Typography
					variant="body1"
					style={{
						fontSize: 13,
						lineHeight: 1,
					}}
				>
					{convertedPoints}
				</Typography>
			</Box>
		</Zoom>;
	};
