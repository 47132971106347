import Grid from '@material-ui/core/Grid/Grid';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { useCurrentPlaceService } from '../../../../../current-place-service/CurrentPlaceService';
import { useCurrentOrderService } from '../../../BusinessContext';
import { TipForm } from './TipForm';

export const TipOrderBuilderSegment: FC =
	() =>
	{
		const currentOrderService = useCurrentOrderService();
		const currentPlaceService = useCurrentPlaceService();

		const showTipForm = useObserver(() => currentOrderService.showTipForm);
		const tip = useObserver(() => currentOrderService.tip);
		const setTip = useObserver(() => currentOrderService.setTip);
		const currencyCode = useObserver(() => currentPlaceService.business!.productCurrencyCode);

		if (!showTipForm)
			return <></>;
		return <Grid
			item
			xs={12}
			style={{
				flex: '0 0 auto',
			}}
		>
			<Grid
				container
				spacing={2}
			>
				{
					<Grid item xs={12}>
						<TipForm
							value={tip}
							onChange={setTip}
							currencyCode={currencyCode}
						/>
					</Grid>
				}
			</Grid>
		</Grid>;
	};