import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as React from 'react';
import { CSSProperties, FC } from 'react';

export function getCenteredPageContentWidthStyle(
    theme: Theme
)
{
    return {
        [theme.breakpoints.up('md')]: {
            maxWidth: 'min(1200px, 90vw)',
        },
    };
}

const useStyles =
    makeStyles(
        theme => ({
            root: {
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 auto',
                alignItems: 'center',
                maxWidth: '100%',
            },
            content: {
                flex: '1 1 auto',
                width: '100%',
                ...getCenteredPageContentWidthStyle(theme),
            },
            stretchedHeight: {
                display: 'flex',
            },
            centeredContent: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            }
        })
    );

export interface CenteredPageContentProps
{
    className?: string;
    style?: CSSProperties;
    stretchedHeight?: boolean;
    centeredContent?: boolean;
}

export const CenteredPageContent: FC<CenteredPageContentProps> =
    ({
        className,
        style,
        children,
        stretchedHeight = true,
        centeredContent = true,
     }) =>
    {
        const classes = useStyles();

        return <div
            className={
                clsx(
                    classes.root,
                    className
                )
            }
            style={style}
        >
            <div
                className={
                    clsx(
                        classes.content,
                        {
                            [classes.stretchedHeight]: stretchedHeight,
                            [classes.centeredContent]: centeredContent,
                        }
                    )
                }
            >
                {children}
            </div>
        </div>;
    };
