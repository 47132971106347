// Assumption: Product drawer information image width is leading with a 4:3 aspect ratio
const NormalizedValues = [
	150,	// 200px width
	300,	// 400px width
	450,	// 600px width
];

export function normalizeProductDrawerImageHeight(height: number): number
{
	const roundedHeight = Math.round(height);

	return NormalizedValues.find(normalizedHeight => roundedHeight <= normalizedHeight)
		?? NormalizedValues[NormalizedValues.length - 1];
}
