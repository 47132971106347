import { OrderDescriptor } from '../../../../../../Api/Order/OrderDescriptor';
import { getShareOrderPaymentWithTikkieUrl } from '../../shareOrderPaymentWithTikkie';
import { PaymentSharingProvider } from '../PaymentSharingProviders';

export function getShareOrderPaymentUrl(
	order: OrderDescriptor,
	provider: PaymentSharingProvider,
	numberOfPersons: number,
): Promise<string | undefined>
{
	switch (provider)
	{
		case 'Tikkie':
			return getShareOrderPaymentWithTikkieUrl(
				order,
				`Butlaroo - ${order.businessName}`,
				numberOfPersons,
			);
		default:
			return undefined;
	}
}
