export function extractPostalCodeNumber(postalCodeString?: string): number | undefined
{
	if (postalCodeString === undefined)
		return undefined;

	const matches = postalCodeString.match('-?\\d+');

	return matches !== null && matches.length > 0
		? parseInt(matches[0])
		: undefined;
}