import { makeAutoObservable } from 'mobx';
import { Localizer } from '../../../../Bridge/Localization/Localizer';
import { CurrentOrderService } from '../../../../Service/CurrentOrder/CurrentOrderService';
import { CurrentPlaceService } from '../../../../Service/CurrentPlace/CurrentPlaceService';
import { formatValidationMessagesToText } from '../../../../Util/Validation';

/**
 * The maximum length of an Order comment
 */
export const COMMENT_MAX_LENGTH = 100;

export class OrderCommentStore
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	private readonly currentOrderService: CurrentOrderService;
	private readonly currentPlaceService: CurrentPlaceService;
	private readonly localizer: Localizer;
	public readonly MAX_LENGTH = 150;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor(
		currentOrderService: CurrentOrderService,
		currentPlaceService: CurrentPlaceService,
		localizer: Localizer,
	)
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});
		this.currentOrderService = currentOrderService;
		this.currentPlaceService = currentPlaceService;
		this.localizer = localizer;
	}

	/*---------------------------------------------------------------*
	 *                         Business Logic                        *
	 *---------------------------------------------------------------*/

	public get inputValue(): string
	{
		const comment = this.currentOrderService.comment;
		return comment !== undefined ? comment : '';
	}

	public get charactersRemainingText(): string
	{
		const charactersRemaining = this.charactersRemaining;
		return this.localizer.translate(
			charactersRemaining >= 0
				?
				'Generic-XCharactersRemaining'
				:
				'Generic-XCharactersOverLimit',
			Math.abs(charactersRemaining).toString()
		);
	}

	public get validationErrors(): string[]
	{
		const errors = Array.from(this.validationErrorsShownBelowInput);

		if (this.charactersRemaining < 0)
		{
			errors.push(this.charactersRemainingText);
		}

		return errors;
	}

	public get validationErrorsShownBelowInput(): string[]
	{
		const errors = [];

		if (this.currentPlaceService.place.commentField === 'REQUIRED'
			&& this.inputValue.length <= 0)
		{
			errors.push(this.localizer.translate('Order-Comment-Required'));
		}

		return errors;
	}

	public get validationErrorsMessage(): string
	{
		return formatValidationMessagesToText(this.validationErrorsShownBelowInput);
	}

	public onChangeInputValue(value: string): void
	{
		this.currentOrderService.setComment(value !== '' ? value : undefined);
	}

	private get charactersRemaining(): number
	{
		return COMMENT_MAX_LENGTH - this.inputValue.length;
	}
}