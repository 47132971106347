import * as React from 'react';
import { createContext, FC, useContext, useEffect, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { StorageVars } from '../../Constants/StorageConstants';
import { IllegalStateException } from '../../Util/Exception/IllegalStateException';
import { useStoredVariable } from '../../Util/useStoredVariable';

interface Context
{
	clientInstanceUuid: string | undefined
}

const ContextRef = createContext<Context | undefined>(undefined);

export const ClientInstanceUuidProvider: FC =
	(
		{
			children,
		},
	) =>
	{
		const clientInstanceUuid = useOrCreateClientInstanceUuid();
		return <ContextRef.Provider
			value={useMemo(() => ({
				clientInstanceUuid,
			}), [clientInstanceUuid])}
		>
			{children}
		</ContextRef.Provider>;
	};

export function useClientInstanceUuid(require?: false): string | undefined
export function useClientInstanceUuid(require: true): string
export function useClientInstanceUuid(require: boolean = false): string | undefined
{
	const context = useContext(ContextRef);
	return useMemo(
		() => {
			if (context === undefined)
				throw new IllegalStateException(
					'Attempting to use useClientInstanceUuid outside ClientInstanceUuidProvider'
				);
			if (require && context.clientInstanceUuid === undefined)
				throw new IllegalStateException(
					'clientInstanceUuid is required to be instantiated'
				);
			return context.clientInstanceUuid;
		},
		[context, require],
	);
}

function useOrCreateClientInstanceUuid(): string | undefined
{
	const [value, setValue] = useStoredVariable<string | null>(
		StorageVars.ClientInstanceUuid,
		storedString => storedString ?? null,
		value => value ?? undefined
	)
	useEffect(
		() => {
			if (value !== undefined)
			{
				if (value === null)
					setValue(uuid())
			}
		},
		[setValue, value]
	)
	return value === null ? undefined : value;
}