import { Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import chroma from 'chroma-js';
import clsx from 'clsx';
import * as React from 'react';
import { FC, ReactNode, useMemo } from 'react';
import { AnnouncementType, getThemeColor } from '../../Api/Business/Announcement';
import { AnnouncementAction } from '../../Api/Business/AnnouncementAction';
import { useRootContext } from '../../RootContext';
import { BrandingService } from '../../Service/BrandingInformation/BrandingService';
import { useComputed } from '../../Util/Hooks/useComputed';
import { AnnouncementActionWrapper } from '../Page/Business/Announcement/AnnouncementActionWrapper';

const useStyles = makeStyles<Theme, { brandingService: BrandingService, colorIntention: 'primary' | 'secondary' | 'error' }>(theme => ({
	clickableText: ({brandingService, colorIntention}) => {
		const color = brandingService.getColor(colorIntention);

		const whatToDoForHover = chroma.distance(color.chroma, chroma('white')) > chroma.distance(color.chroma, chroma('black'))
			? 'brighten'
			: 'darken';

		const hoverColor = whatToDoForHover === 'darken'
			? color.chroma.darken(0.5)
			: color.chroma.brighten(0.5);

		return {
			width: '100%',
			cursor: 'pointer',
			transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)',

			'&:hover': {
				backgroundColor: hoverColor.css(),
			},
		};
	},
	text: ({colorIntention}) => ({
		backgroundColor: theme.palette[colorIntention].main,
		borderRadius: theme.shape.borderRadius,
		color: theme.palette[colorIntention].contrastText,
		breakInside: 'avoid',
		paddingBottom: 4,
		paddingLeft: 12,
		paddingRight: 12,
		paddingTop: 4,


	}),
}));

export interface AnnouncementPillProps
{
	content: string;
	action?: AnnouncementAction;
	linkUrl?: string;
	severity: AnnouncementType;
	style?: React.CSSProperties;
}

export const AnnouncementPill: FC<AnnouncementPillProps> =
	(
		{
			content,
			action,
			linkUrl,
			severity,
			style,
		},
	) =>
	{
		const {brandingService} = useRootContext(true);

		const hasAction = action !== undefined || linkUrl !== undefined;
		const colorIntention = useComputed(() => getThemeColor(severity)!, [severity]);

		const classes = useStyles({brandingService, colorIntention});

		const lines = useMemo(() => {
			const contentLines = (content !== null && content !== undefined && content.length > 0)
				? content.split('\n')
				: [];

			return contentLines
				.reduce<ReactNode[]>((lines, line, idx) => {
					if (idx > 0)
						lines.push(<br />);

					lines.push(line);

					return lines;
				}, []);
		}, [content]);

		const textClasses = clsx(
			classes.text,
			{
				[classes.clickableText]: hasAction,
			},
		);

		const pill = <Typography
			className={textClasses}
			style={style}
		>
			{lines}
		</Typography>;

		if (linkUrl === undefined && action === undefined)
		{
			return pill;
		}
		else if (action === undefined)
		{
			return <AnnouncementActionWrapper
				action={{type: 'OpenURL', url: linkUrl}}
			>
				{pill}
			</AnnouncementActionWrapper>;
		}
		else
		{
			return <AnnouncementActionWrapper
				action={action}
			>
				{pill}
			</AnnouncementActionWrapper>;
		}
	};