// @ts-nocheck
/* eslint-disable */
export enum PlaceCodeScannerState
{
    error = 'Error',
    loaded = 'Loaded',
    loading = 'Loading',

    /**
     * The QR code is not a valid Butlaroo QR code (e.g. it is a qr code from another company)
     */
    invalidQrCode = 'InvalidQrCode',
    noQrCode = 'NoQrCode',
    notAuthorized = 'NotAuthorized',
    notSupported = 'NotSupported',
    pendingAuthorization = 'PendingAuthorization',
    timedOut = 'TimedOut',

    /**
     * The QR code is a Butlaroo QR code, but it does not match a Place
     */
    unknownQrCode = 'UnknownQrCode'
}
