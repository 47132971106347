import { OverflowXProperty } from 'csstype';
import * as React from 'react';
import { FC } from 'react';
import { Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { NamedOrderList } from '../NamedOrderList';
import { OrderListGridColumn } from './OrderListGridColumn';

const useStyles = makeStyles(() => ({
	container: {
		flexGrow: 1,
		flexWrap: 'nowrap',
		overflowX: 'overlay' as OverflowXProperty,
	},
}));

interface OrderListGridProps
{
	orderLists: (NamedOrderList | undefined)[]
}

export const OrderListGrid: FC<OrderListGridProps> =
	(
		{
			orderLists,
		},
	) =>
	{
		const classes = useStyles();

		return <Grid
			alignItems="stretch"
			classes={{
				container: classes.container,
			}}
			container
			direction="row"
		>
			{
				orderLists
					.filter(orderList => orderList !== undefined)
					.map((orderList, idx) => <OrderListGridColumn
						addLeftDivider={idx > 0}
						key={idx}
						orderList={orderList!}
					/>)
			}
		</Grid>;
	};