import { useMemo } from 'react';
import { useTranslate } from './useTranslate';

export function useTranslation(translationKey: undefined, ...args: string[]): undefined
export function useTranslation(translationKey: string, ...args: string[]): string
export function useTranslation(translationKey: string | undefined, ...args: string[]): string | undefined
export function useTranslation(translationKey: string | undefined, ...args: string[]): string | undefined
{
	const translate = useTranslate();
	return useMemo(
		() => {
			if (translationKey === undefined)
				return undefined;
			else
				return translate(translationKey, ...args);
		},
		[args, translate, translationKey]
	);
}