import { makeAutoObservable } from 'mobx';
import { SerializationProfile } from '../../Util/Serialization/Serialization';

export class PostalCodeRestriction
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	fromIncl: number;
	toIncl: number;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor()
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});
	}

	/*---------------------------------------------------------------*
	 *                         Business Logic                        *
	 *---------------------------------------------------------------*/

	static isPostalCodeValid(postalCode?: string, restrictedPostalCodes?: PostalCodeRestriction[]): boolean
	{
		// Create matches that extracts number of a string
		const postalCodeNumbers = postalCode?.match(/\d+/g)?.map(Number)?.[0];

		// Get first if found
		if (postalCodeNumbers)
		{
			// Check whether there are no restrictions or the portal code numbers fall within at least one restriction.
			return (restrictedPostalCodes == null || restrictedPostalCodes.length === 0) ||
				restrictedPostalCodes
					.some(postalCodeRestriction => postalCodeNumbers >= postalCodeRestriction.fromIncl && postalCodeNumbers <= postalCodeRestriction.toIncl);
		}
		else
		{
			return false;
		}
	}

	/*---------------------------------------------------------------*
	 *                      Getters and setters                      *
	 *---------------------------------------------------------------*/

	/*---------------------------------------------------------------*
	 *                        Nested classes                         *
	 *---------------------------------------------------------------*/
}

export const PostalCodeRestrictionProfile = SerializationProfile.create(PostalCodeRestriction);