import { isLocalAccessDeniedError } from '../../Util/browser/isLocalAccessDeniedError';
import { Storage as BaseStorage } from './Storage';

export class WebStorage extends BaseStorage
{
	// ------------------------ Dependencies ------------------------

	// ------------------------- Properties -------------------------

	private readonly storage?: Storage;

	// ------------------------ Constructor -------------------------

	constructor()
	{
		super();

		try
		{
			this.storage = localStorage;
		}
		catch (error)
		{
			if (isLocalAccessDeniedError(error))
				console.warn('Local storage is not supported. Please enable cookies to use local storage.');
		}
	}

	// ----------------------- Initialization -----------------------

	// -------------------------- Computed --------------------------

	// --------------------------- Stores ---------------------------

	// -------------------------- Actions ---------------------------

	// ------------------------ Public logic ------------------------

	get(key: string): string | undefined
	{
		return this.storage?.getItem(key) ?? undefined;
	}

	set(key: string, value?: string): void
	{
		value
			? this.storage?.setItem(key, value)
			: this.storage?.removeItem(key);
	}

	// ----------------------- Private logic ------------------------
}
