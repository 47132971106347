import { Divider, FormGroup, List, ListItem, Theme } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { TRADITIONAL_PAYMENT_METHOD_ID } from '../../../../../../../Api/Payment/PaymentMethodDescriptor';
import { useValidationContext } from '../../../../../validation/context/ValidationContext';
import { usePaymentMethodAvailabilityContext } from '../../../../availability/context/PaymentMethodAvailabilityContext';
import { usePaymentIssuerContext } from '../../../../issuer/context/PaymentIssuerContext';
import { usePaymentMethodContext } from '../../../context/PaymentMethodContext';
import { PaymentMethodOption } from './PaymentMethodOption';

const useStyles = makeStyles((theme: Theme) => ({
	formGroup: {
		flex: '0 1 auto',
		flexDirection: 'column',
		display: 'flex',
		overflowY: 'hidden',
	},
	formGroupContent: {
		display: 'flex',
		flex: '0 1 auto',
		flexDirection: 'column',
		overflowY: 'auto',
	},
	formHelperText: {
		paddingLeft: theme.spacing(3),
	},
	root: {
		paddingTop: 0,
		width: '100%',
	},
	paymentMethodList: {
		paddingBottom: 0,
		paddingTop: 0,
	},
}));

export interface PaymentMethodListFormProps
{
	className?: string;
	onChange: (paymentMethodId: string) => void;
	value?: string;
}

export const PaymentMethodSelector: FC<PaymentMethodListFormProps> =
	({
		className,
		onChange,
		value,
	}) =>
	{
		const showValidation = useValidationContext();
		const paymentMethods = usePaymentMethodAvailabilityContext();
		const {validation: paymentMethodValidation} = usePaymentMethodContext();
		const {validation: paymentIssuerValidation} = usePaymentIssuerContext();

		const classes = useStyles();

		const allValidation = useMemo(
			() => [...paymentMethodValidation, ...paymentIssuerValidation],
			[paymentIssuerValidation, paymentMethodValidation],
		);

		const isMethodError = useMemo(
			() => showValidation && allValidation.length > 0,
			[allValidation.length, showValidation],
		);

		const rootClassName = clsx(classes.root, className);

		const paymentMethodList = useMemo(() =>
		{
			const beforehandPaymentMethods = paymentMethods?.filter(paymentMethod => paymentMethod.id !== TRADITIONAL_PAYMENT_METHOD_ID);

			if (beforehandPaymentMethods !== undefined && beforehandPaymentMethods.length > 0)
				return <List disablePadding>
					{
						beforehandPaymentMethods
							.map(
								(descriptor, idx) =>
									<ListItem
										divider={idx !== beforehandPaymentMethods.length - 1}
										key={descriptor.id}
									>
										<PaymentMethodOption
											descriptor={descriptor}
											onSelect={descriptor => onChange(descriptor.id)}
											selected={descriptor.id === value}
										/>
									</ListItem>,
							)
					}
				</List>;
		}, [onChange, paymentMethods, value]);

		return <FormControl
			className={rootClassName}
			error={isMethodError}
		>
			<FormGroup className={classes.formGroup}>
				<div className={classes.formGroupContent}>
					<Divider />
					{paymentMethodList}
				</div>
			</FormGroup>
		</FormControl>;
	};
