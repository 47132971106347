import { TextField, Theme, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Decimal } from 'decimal.js';
import * as React from 'react';
import { ChangeEvent, Dispatch, FC, useCallback, useMemo, useRef, useState } from 'react';
import { useLocalizer } from '../../../../../../Bridge/Localization/useLocalizer';

const BUTTON_HEIGHT = 30;
const BUTTON_WIDTH = 30;

const useStyles = makeStyles<Theme>((theme: Theme) => ({
	buttonLabel: {
		color: 'white',
	},
	buttonRoot: {
		borderRadius: theme.shape.borderRadius,
		boxShadow: 'none',
		height: BUTTON_HEIGHT,
		minWidth: 0,
		padding: 0,
		textAlign: 'center',
		width: BUTTON_WIDTH,
		zIndex: 2000,
		'&:hover': {
			boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
		},
	},
	root: {
		position: 'relative',
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'flex-end',
	},
	valueInput: {
		paddingLeft: '8px !important',
		paddingRight: '8px !important',
	},
	valueInputInput: {
		// cursor: 'pointer',
	},
	valueInputRoot: {
		cursor: 'pointer',
	},
	valueRoot: {
		alignItems: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.01)',
		display: 'inherit',
		height: BUTTON_HEIGHT,
		justifyContent: 'center',
		marginLeft: -BUTTON_WIDTH / 2,
		marginRight: -BUTTON_WIDTH / 2,
		paddingLeft: BUTTON_WIDTH / 2,
		paddingRight: BUTTON_WIDTH / 2,

		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.05)',
		},
	},
}));

interface CustomTipControlProps
{
	minimum?: number
	setValue: Dispatch<Decimal | undefined>
	step?: number
	value?: Decimal
	currencyCode: string
}

export const CustomTipControl: FC<CustomTipControlProps> =
	(
		{
			minimum,
			setValue,
			step = 1,
			value = new Decimal(0),
			currencyCode,
		},
	) =>
	{
		const localizer = useLocalizer();

		const inputRef = useRef<HTMLInputElement | null>(null);

		const symbol = useMemo(() => localizer.getCurrencySymbol(currencyCode), [currencyCode, localizer]);

		const [selected, setSelected] = useState(false);

		const selectInput = useCallback(
			() => {
				if (!selected)
				{
					setTimeout(
						() =>
						{
							inputRef.current?.focus();
							inputRef.current?.select();
							inputRef.current?.setSelectionRange(0, inputRef.current?.value.length);
							setSelected(true);
						},
						10,
					)
				}
			},
			[selected],
		);

		const [stringValue, setStringValue] = useState(
			() => localizer.formatCurrency(value, currencyCode)
		);

		const classes = useStyles();

		const onBlur = useCallback(
			() =>
			{
				if (stringValue === undefined || stringValue === '')
				{
					setStringValue(`${symbol} ${localizer.formatCurrency(new Decimal(0), currencyCode)}`);
				}
				setSelected(false);
			},
			[currencyCode, localizer, stringValue, symbol],
		);

		const clampNumber = useCallback(
			(value: Decimal) =>
				minimum === undefined
					? value
					: Decimal.max(minimum, value),
			[minimum],
		);

		const parseTip = useCallback(
			(value?: Decimal) =>
			{
				let decimalValue = new Decimal(clampNumber(value));

				const fractionDigits = localizer.getCurrencyFractionDigits(currencyCode);

				return decimalValue.toDecimalPlaces(fractionDigits);
			},
			[clampNumber, currencyCode, localizer],
		);

		const updateStringValue = useCallback(
			(value?: string) =>
			{
				const isNegative = (value?.indexOf('-') ?? -1) > -1;

				const valueWithDecimalPoint = (() => {
					const matches = value.match(/[ ]*[+-]?[ ]*([0-9]+)([.,]?[0-9]*)?/);
					if (matches === null)
					{
						return '';
					}
					const decimalSeperatorAndNumbersAfter = matches[2] !== undefined
						? matches[2].replace(',', '.')
						: '';
					return matches[1] + decimalSeperatorAndNumbersAfter;
				})();
				
				const fractionDigits = localizer.getCurrencyFractionDigits(currencyCode);

				const newValue = valueWithDecimalPoint
					?.match(/[+-]?\d+([.]\d+)?/g)
					?.map(val => new Decimal(isNegative ? `-${val}` : val))
					?.map(val => val.mul(Math.pow(10, fractionDigits)).floor().div(Math.pow(10, fractionDigits)))
					?.map(clampNumber)
					?.[0];

				if (newValue === undefined)
				{
					setValue(new Decimal(0));
					setStringValue(`${symbol} `);
					return;
				}

				try
				{
					const newDecimalValue = parseTip(newValue);
					if (newDecimalValue === undefined || isNaN(newDecimalValue.toNumber()))
					{
						setValue(new Decimal(0));
						setStringValue(`${symbol} ${newValue}`);
					}
					else
					{
						const formattedAmount = localizer.formatCurrency(newDecimalValue, currencyCode)!;
						const takeSubstring = (value?.length ?? 0) > (symbol?.length ?? 0);
						setValue(newDecimalValue);
						setStringValue(takeSubstring ? formattedAmount.substr(0, value?.length) : formattedAmount);
					}
				}
				catch (e)
				{
					setValue(new Decimal(0));
					setStringValue(`${symbol} ${newValue}`);
				}
			},
			[clampNumber, currencyCode, localizer, parseTip, setValue, symbol],
		);

		const onChangeStringValue = useCallback(
			(event: ChangeEvent<{ value: string }>) => updateStringValue((event.target.value)),
			[updateStringValue],
		);

		const decreaseValue = useCallback(
			() =>
			{
				const newValue = new Decimal(value ?? 0).sub(step);
				updateStringValue(
					localizer.formatCurrency(
						newValue,
						currencyCode,
					)!,
				);
				setSelected(false);
			},
			[currencyCode, localizer, step, updateStringValue, value],
		);

		const increaseValue = useCallback(
			() =>
			{
				updateStringValue(
					localizer.formatCurrency(
						new Decimal(value ?? 0).add(step),
						currencyCode,
					)!,
				);
				setSelected(false);
			},
			[currencyCode, localizer, step, updateStringValue, value],
		);

		const decreaseButton = useMemo(
			() => <Button
				disabled={!(value?.greaterThan(minimum ?? 0) ?? false)}
				classes={{
					label: classes.buttonLabel,
					root: classes.buttonRoot,
				}}
				onClick={decreaseValue}
				variant="contained"
			>
				<Typography>&minus;</Typography>
			</Button>,
			[classes.buttonLabel, classes.buttonRoot, decreaseValue, minimum, value],
		);

		const increaseButton = useMemo(
			() =>
				<Button
					classes={{
						label: classes.buttonLabel,
						root: classes.buttonRoot,
					}}
					onClick={increaseValue}
					variant="contained"
				>
					<Typography>&#43;</Typography>
				</Button>,
			[classes.buttonLabel, classes.buttonRoot, increaseValue],
		);

		const valueElement = useMemo(
			() => <div
				className={classes.valueRoot}
			>
				<TextField
					classes={{
						root: classes.valueInput,
					}}
					InputProps={{
						classes: {
							root: classes.valueInputRoot,
							input: classes.valueInputInput,
						},
						disableUnderline: true,
					}}
					inputProps={{
						inputMode: 'decimal',
						pattern: '[0-9]*[.,]?[0-9]+',
						step: step,
						style: {
							cursor: 'pointer',
							minWidth: `4ch`,
							textAlign: 'center',
							width: `${stringValue?.length ?? 0}ch`,
						},
					}}
					inputRef={inputRef}
					onBlur={onBlur}
					onChange={onChangeStringValue}
					onClick={selectInput}
					value={stringValue}
				/>
			</div>,
			[classes.valueInput, classes.valueInputInput, classes.valueInputRoot, classes.valueRoot, onBlur, onChangeStringValue, selectInput, step, stringValue],
		);

		return <div className={classes.root}>
			{decreaseButton}
			{valueElement}
			{increaseButton}
		</div>;
	};
