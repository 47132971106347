import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { CouponBadge } from '../../../Coupon/CouponBadge/CouponBadge';
import { CouponForm } from '../../../Coupon/CouponForm/CouponForm';
import { LoyaltyCouponBadge } from '../../../Coupon/LoyaltyCouponBadge/LoyaltyCouponBadge';
import { OrderBuilderStore } from '../../OrderBuilderStore';

interface CouponFormOrderBuilderSegmentProps
{
    store: OrderBuilderStore;
}

export const CouponOrderBuilderSegment: FC<CouponFormOrderBuilderSegmentProps> =
    (
        {
            store,
        },
    ) =>
    {
        return useObserver(() => {
            if (!store.showCouponForm)
                return null;
            return <Grid
                item
                xs={12}
                style={{
                    flex: '0 0 auto',
                }}
            >
                <Accordion
                    expanded={store.couponHasFocus}
                    onChange={(e, expanded) => store.setCouponHasFocus(expanded)}
                    style={{
                        backgroundColor: store.showValidation && store.couponFormStore && store.couponFormStore.validation.length > 0
                            ? '#f001'
                            : '#fff8',
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                variant={'body2'}
                            >
                                {store.couponFormStore?.summaryLabel}
                            </Typography>
                            <div
                                style={{
                                    marginLeft: 'auto',
                                }}
                            >
                                {store.couponFormStore?.addedCouponBadgeStores.map((couponStore, key) =>
                                    <CouponBadge
                                        key={key}
                                        store={couponStore}
                                        style={{
                                            marginTop: -5,
                                            marginBottom: -5,
                                        }}
                                    />,
                                )}
                                {store.couponFormStore?.addedLoyaltyDiscountCoupons.length > 0 &&
                                    <LoyaltyCouponBadge
                                        discount={store.couponFormStore?.addedLoyaltyDiscountCoupons[0]}
                                        style={{
                                            marginTop: -5,
                                            marginBottom: -5,
                                        }}
                                    />
                                }
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails
                        style={{
                            paddingBottom: 8,
                        }}
                    >
                        {store.couponFormStore && <CouponForm store={store.couponFormStore} />}
                    </AccordionDetails>
                </Accordion>
            </Grid>;
        });
    };