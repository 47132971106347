import { Grow, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { FC } from 'react';
import { LoyaltyDiscount } from '../../../../../Api/Order/Loyalty/LoyaltyDiscount';

const useStyles = makeStyles(theme => ({
	couponBadge: {
		backgroundColor: theme.palette.primary.main,
		borderRadius: theme.shape.borderRadius,
		outlineStyle: 'dotted',
		outlineWidth: 1,
		outlineColor: 'rgba(255,255,255,0.8)',
		outlineOffset: -3,
		display: 'inline-block',
		paddingTop: 7,
		paddingBottom: 7,
		paddingLeft: 11,
		paddingRight: 11,
	},
	couponBadgeLabel: {
		color: theme.palette.primary.contrastText,
		fontSize: 'smaller',
	},
}));

export interface LoyaltyCouponBadgeProps
{
	discount: LoyaltyDiscount;
	style?: React.CSSProperties;
}

export const LoyaltyCouponBadge: FC<LoyaltyCouponBadgeProps> =
	({
		discount,
		style,
	}) =>
	{
		const classes = useStyles();

		return <Grow
			in
		>
			<div
				className={classes.couponBadge}
				style={style}
			>
				<Typography
					className={classes.couponBadgeLabel}
				>
					{discount.name}
				</Typography>
			</div>
		</Grow>;
	};
