import { useObserver } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useWebSocketConnection } from '../../Component/websocket-connection/WebSocketConnection';
import { fetchAny } from '../Api';

export type ApiReachabilityStatus = 'reachable' | 'unreachable' | 'initializing';

export type ApiConnectionStatus = 'connected' | 'disconnected' | 'unreachable';

let onApiReachable: {current: () => void} = {current: () => {}};
let onApiUnreachable: {current: () => void} = {current: () => {}};

export function setApiReachable(): void
{
	onApiReachable.current();
}

export function promptCheckIfApiIsReachable(): void
{
	fetchAny('public/identity', {})
		.then(() => {
			onApiReachable.current();
		})
		.catch((e) => {
			if (e.name === 'HttpRequestTimeoutException' || e.name === 'FailedHttpRequestException')
			{
				onApiUnreachable.current();
			}
		})
}

/**
 * Returns if the API is currently reachable or unreachable.
 */
export function useApiReachability(): ApiConnectionStatus
{
	const [reachability, setReachability] = useState<ApiReachabilityStatus>('initializing');

	const webSocketService = useWebSocketConnection();
	const webSocketIsRegistered = useObserver(() => webSocketService?.isRegistered ?? false);

	useEffect(
		() => {
			onApiReachable.current = () => setReachability(reachability);
			onApiUnreachable.current = () => setReachability(reachability);

			return () => {
				onApiReachable.current = () => {};
				onApiUnreachable.current = () => {};
			};
		},
		[reachability],
	);

	return useMemo(
		() => {
			if (webSocketIsRegistered)
				return 'connected';
			else if (reachability === 'unreachable')
			{
				return 'unreachable';
			}
			else
			{
				return 'disconnected';
			}

		},
		[reachability, webSocketIsRegistered],
	);
}