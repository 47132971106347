import { Collapse, makeStyles, Typography } from '@material-ui/core';
import Decimal from 'decimal.js';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { PaymentMethodId } from '../../../../../Api/Payment/PaymentMethodId';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { useRootContext } from '../../../../../RootContext';

const useStyles = makeStyles(theme => ({
	text: {
		color: theme.palette.primary.contrastText,
		fontSize: '2rem',
	},
	textSecondary: {
		color: theme.palette.primary.contrastText,
		fontSize: '3rem',
		marginTop: theme.spacing(6),
	},
}));

interface OrderPaymentInProgressPageInformationProps
{
	paymentAmountRemaining?: Decimal;
	paymentAmountTotal?: Decimal;
	paymentCurrencyCode: string;
	paymentMethod?: PaymentMethodId;
}

export const OrderPaymentInProgressPageInformation: FC<OrderPaymentInProgressPageInformationProps> =
	({
		paymentAmountRemaining,
		paymentAmountTotal,
		paymentCurrencyCode,
		paymentMethod,
	}) =>
	{
		const {localizer} = useRootContext(true);
		const translate = useTranslate();

		const formatCurrency = useObserver(() => localizer.formatCurrency);

		const classes = useStyles();

		const information = useMemo(
			() =>
			{
				if (paymentMethod === 'Cash')
				{
					if (paymentAmountRemaining === undefined || paymentAmountRemaining.gte(0))
					{
						return translate(
							'Client-Locked-For-Payment-Handler-Cash',
							formatCurrency(paymentAmountTotal, paymentCurrencyCode),
						);
					}
					else
					{
						return translate('Client-Locked-For-Payment-Handler-Cash-Eject');
					}
				}
				else
				{
					return translate('Client-Locked-For-Payment-Terminal');
				}
			},
			[formatCurrency, paymentAmountRemaining, paymentAmountTotal, paymentCurrencyCode, paymentMethod, translate],
		);

		const secondaryInformation = useMemo(
			() =>
			{
				if (paymentMethod === 'Cash')
				{
					if (paymentAmountRemaining !== undefined && paymentAmountRemaining.gte(0))
					{
						return translate(
							'Client-Locked-For-Payment-Handler-Cash-Remaining',
							formatCurrency(paymentAmountRemaining, paymentCurrencyCode),
						);
					}
				}
			},
			[formatCurrency, paymentAmountRemaining, paymentCurrencyCode, paymentMethod, translate],
		);

		return <div>
			<Typography
				align="center"
				className={classes.text}
			>
				{information}
			</Typography>
			<Collapse
				in={secondaryInformation !== undefined}
			>
				<Typography
					align="center"
					className={classes.textSecondary}
				>
					{secondaryInformation}
				</Typography>
			</Collapse>
		</div>;
	};
