import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext } from 'react';
import { BusinessContextRef } from '../../../BusinessContext';
import { OrderBuilderSegment } from '../../OrderBuilderSegment';
import { OrderBuilderStore } from '../../OrderBuilderStore';
import { AgeVerificationForm } from './form/AgeVerificationForm';

interface AgeVerificationOrderBuilderSegmentProps
{
	store: OrderBuilderStore;
}

export const AgeVerificationOrderBuilderSegment: FC<AgeVerificationOrderBuilderSegmentProps> =
	(
		{
			store,
		},
	) =>
	{
		const {currentAgeVerificationService} = useContext(BusinessContextRef);
		const needsAgeVerification = useObserver(() => currentAgeVerificationService.needsAgeVerification);
		const showValidation = useObserver(() => store.showValidation);

		if (needsAgeVerification)
		{
			return <OrderBuilderSegment>
				<AgeVerificationForm
					showValidation={showValidation}
				/>
			</OrderBuilderSegment>;
		}
		else
		{
			return null;
		}
	};
