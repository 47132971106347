import { Theme, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Decimal from 'decimal.js';
import * as React from 'react';
import { CSSProperties, FC, useContext, useMemo } from 'react';
import { ExternalBillOrderLineDescriptor } from '../../../../../../Api/Order/OrderLineDescriptor';
import { EntranceContextRef } from '../../../../Entrance/EntranceContext';

const AvatarSize = 32;

const useStyles = makeStyles(
	(theme: Theme) =>
		({
			descriptionContainer:
				{
					alignItems: 'center',
					display: 'flex',
					minHeight: AvatarSize,
				},
			descriptionText:
				{
					marginLeft: theme.spacing(1),
				},
			descriptionTextAligned:
				{
					marginLeft: AvatarSize + theme.spacing(1),
				},
			priceContainer:
				{
					alignItems: 'center',
					alignSelf: 'flex-start',
					display: 'flex',
					height: AvatarSize,
					marginLeft: theme.spacing(1),
				},
			priceText:
				{
				},
			root:
				{
					alignItems: 'center',
					display: 'flex',
					justifyContent: 'space-between',
					padding: theme.spacing(1),
				},
		}));

export interface OrderLineCollapsedProps
{
	alignWithAvatars?: boolean;
	orderLine: ExternalBillOrderLineDescriptor;
	showPriceAsCurrency?: string;
	style?: CSSProperties;
}

export const ExternalBillOrderLine: FC<OrderLineCollapsedProps> =
	({
		 alignWithAvatars,
		 orderLine,
		 showPriceAsCurrency,
		 style,
	 }) =>
	{
		const classes = useStyles();

		const {
			localizer,
		} = useContext(EntranceContextRef);

		const descriptionTextClassString = useMemo(
			() =>
			{
				if (alignWithAvatars)
				{
					return classes.descriptionTextAligned;
				}
				else
				{
					return classes.descriptionText;
				}
			},
			[alignWithAvatars, classes.descriptionText, classes.descriptionTextAligned]);

		return <div
			className={classes.root}
			style={style}
		>
			<div
				className={classes.descriptionContainer}
			>
				<Typography
					classes={{
						root: descriptionTextClassString,
					}}
				>
					{localizer.translate('Order-BillSettlement')}
				</Typography>
			</div>
			{
				showPriceAsCurrency &&
				orderLine.quantity > 0 &&
				orderLine.price > 0 &&
				<div
					className={classes.priceContainer}
				>
					<Typography
						classes={{
							root: classes.priceText,
						}}
					>
						{localizer.formatCurrency(new Decimal(orderLine.quantity * orderLine.price), showPriceAsCurrency)}
					</Typography>
				</div>
			}
		</div>;
	};
