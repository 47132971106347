import { Theme } from '@material-ui/core/styles';
import chroma from 'chroma-js';
import { useMemo } from 'react';
import { ButtonAppearance } from '../../ButtonAppearance';
import { extractMuiButtonColor, toCss } from '../../ButtonColor';

export function useTextButtonColors(error: boolean, disabled: boolean, color: ButtonAppearance<'text'>, theme: Theme)
{
	return useMemo(
		() => {
			const contentColor = error
				? 'error'
				: color.contentColor ?? theme.palette.text.primary;
			const originalContentAlpha = chroma(toCss(contentColor, theme)).alpha() as unknown as number;
			const contentAlpha = !disabled
				? originalContentAlpha
				: 0.4 * originalContentAlpha;
			const contentColorCss = chroma(toCss(contentColor, theme)).alpha(contentAlpha).css();
			const keepButtonHoverColorNeutral = color.keepButtonHoverColorNeutral ?? false;
			const buttonHoverColorCss = keepButtonHoverColorNeutral
				? chroma(theme.palette.text.primary).alpha(0.04).css()
				: chroma(contentColorCss).alpha(0.04).css();
			const muiButtonColor = extractMuiButtonColor(contentColor);

			return {contentColorCss, buttonHoverColorCss, muiButtonColor};
		},
		[color.contentColor, color.keepButtonHoverColorNeutral, disabled, error, theme],
	);
}