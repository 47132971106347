import axios from 'axios';

/**
 * Set a default API header
 *
 * @param header the header to set
 * @param value the value to set the header to
 */
export function setDefaultApiHeader(header: string, value: string): void
{
	axios.defaults.headers[header] = value;
}