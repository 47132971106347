import { Slide, Theme, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ChevronDoubleUp from 'mdi-material-ui/ChevronDoubleUp';
import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from '../../../../Bridge/Localization/useTranslation';
import { UIColors } from '../../../../Constants/UIColors';

const useStyles = makeStyles(
	(theme: Theme) =>
		({
			iconLeft:
				{
					marginLeft: theme.spacing(2),
					marginRight: theme.spacing(6),
				},
			iconRight:
				{
					marginLeft: theme.spacing(6),
					marginRight: theme.spacing(2),
				},
			root:
				{
					alignItems: 'center',
					backgroundColor: UIColors.greyLight,
					display: 'flex',
					height: 48,
					justifyContent: 'space-between',
					position: 'absolute',

					[theme.breakpoints.down('xs')]:
						{
							bottom: 0,
							width: '100%',
						},

					[theme.breakpoints.up('sm')]:
						{
							bottom: 80,
							borderRadius: theme.shape.borderRadius,
						},
				},
			text:
				{
				},

		}));

interface ManagerPlaceCodeInfoNoCodeContentProps
{
	show: boolean;
}

export const ManagerPlaceCodeInfoNoCodeContent: FC<ManagerPlaceCodeInfoNoCodeContentProps> =
	(
		{
			show,
		},
	) =>
	{
		const classes = useStyles();

		return <Slide
			direction="up"
			in={show}
			unmountOnExit
		>
			<div
				className={classes.root}
			>
				<ChevronDoubleUp
					classes={{
						root: classes.iconLeft,
					}}
				/>
				<Typography
					classes={{
						root: classes.text,
					}}
				>
					{useTranslation('Qr-Code-Scan')}
				</Typography>
				<ChevronDoubleUp
					classes={{
						root: classes.iconRight,
					}}
				/>
			</div>
		</Slide>;
	};
