import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { OrderDescriptor } from '../../../../Api/Order/OrderDescriptor';
import { parse } from '../../../../Api/Util/time/Duration';
import { useRootContext } from '../../../../RootContext';
import { nonNullish } from '../../../../Util/nonNullish';
import { isProductOrderLine } from '../../../../Util/order_line/isProductOrderLine';

interface OrderEmbeddablesProps
{
	order: OrderDescriptor
}

export const OrderEmbeddables: FC<OrderEmbeddablesProps> =
	(
		{
			order,
		},
	) =>
	{
		const {clockService} = useRootContext(true);

		const embeddables = useObserver(
			() =>
			{
				if (!hasCorrectOrderStateValue(order))
				{
					return [];
				}
				else if (!order.embeddableHtmlConsentObtained)
				{
					return [];
				}
				else
				{
					const timeoutAsMillis = parse(order.embeddableHtmlAfterOrderTimeout).seconds * 1000;
					const nowAsMillis = clockService.currentMinuteMillis;
					const orderHandledDateAsMillis = new Date(order.dateHandled!).getTime();

					return orderHandledDateAsMillis <= nowAsMillis - timeoutAsMillis
						? []
						: getUniqueEmbeddablesIn(order);
				}
			},
		);

		return <>
			{embeddables.map((embeddable, key) => <div
				key={key}
				dangerouslySetInnerHTML={{
					__html: embeddable,
				}}
			/>)}
		</>;
	};

function getUniqueEmbeddablesIn(order: OrderDescriptor): string[]
{
	return Array.from(
		order
			.orderLines
			.filter(isProductOrderLine)
			.map(line => line.embeddableHtmlAfterOrder)
			.filter(nonNullish)
			.reduce(
				(result, current) =>
				{
					result.add(current);

					return result;
				},
				new Set<string>(),
			),
	);
}

function hasCorrectOrderStateValue(order: OrderDescriptor): boolean
{
	return [
		'delivered',
		'handled',
		'prepared',
		'pickedUp',
	].includes(order.state);
}
