import FormControl from '@material-ui/core/FormControl';
import { InputLabel, OutlinedInput, PropTypes, Select, withTheme, WithTheme } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

interface OutlinedSelectProps
{
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    value?: unknown;
    onChange?: (
        event: React.ChangeEvent<{ name?: string; value: unknown }>,
        child: React.ReactNode,
    ) => void;
    label?: string;
    style?: React.CSSProperties;
    children?: React.ReactNode[];
    required?: boolean;
    margin?: PropTypes.Margin;
    size?: 'small' | 'medium';
    classes?: {
        root?: string
        select?: string
        input?: string
    }
}

export const OutlinedSelect = withTheme(
    (
        {
            disabled,
            error,
            helperText,
            onChange,
            value,
            size = 'medium',
            label,
            style,
            children,
            theme,
            margin,
            required = false,
            classes = {},
        }: OutlinedSelectProps & WithTheme,
    ) =>
    {

    // Concerning the label width
    const inputLabel = useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = useState(0);
    const labelWidthComp = inputLabel.current !== null ? inputLabel.current.offsetWidth : 0;
    useEffect(() => setLabelWidth(labelWidthComp), [labelWidthComp]);

    const {root, select, input} = classes;
    return <>
        <FormControl
            variant="outlined"
            style={{
                ...style,
            }}
            margin={margin}
            error={error}
            required={required}
            className={root}
        >
            <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
                {label}
            </InputLabel>
            <Select
                disabled={disabled}
                value={value}
                onChange={onChange}
                input={<OutlinedInput
                    labelWidth={labelWidth}
                    name="age"
                    id="outlined-age-simple"
                    classes={{
                        input,
                    }}
                />}
                style={{
                    height: size === 'small' ? 40 : undefined,
                }}
                classes={{
                    select,
                }}
            >
                {children}
            </Select>
            {
                helperText === undefined ? <></> :
                    <FormHelperText
                        style={{
                            marginLeft: 0,
                            color: error ? theme.palette.error.main : undefined,
                        }}
                    >
                        {helperText}
                    </FormHelperText>
            }
        </FormControl>
    </>;
});
