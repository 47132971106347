import { SerializationProfile } from '../../Util/Serialization/Serialization';
import { ColorProfile } from '../Other/Color';
import { LanguageEntryProfile } from '../Other/LanguageEntry';
import { ProductCategory } from './ProductCategory';
import { ProductFeatureProfile } from './ProductFeature';
import { ProductFeeProfile } from './ProductFee';
import { ProductProfile } from './ProductProfile';

export const ProductCategoryProfile =
    SerializationProfile.create(ProductCategory)
        .profile('products', ProductProfile)
        .profile('imageBackgroundColor', ColorProfile)
		.profile('menuEntryColor', ColorProfile)
		.profile('menuEntryTextColor', ColorProfile)
        .profile('fees', ProductFeeProfile)
        .profile('features', ProductFeatureProfile)
		.profile('langName', LanguageEntryProfile);

ProductCategoryProfile
    .profile('categories', ProductCategoryProfile);
