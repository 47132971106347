import { Account } from '../../../Api/Account/Account';
import { Storage } from '../../../Bridge/Storage/Storage';
import { StorageVars } from '../../../Constants/StorageConstants';
import { AuthenticationResultV2 } from '../AuthenticationResult';

export function persistAccountV2(storage: Storage, authenticationResult: AuthenticationResultV2): void
{
	storage.set(
		StorageVars.Account,
		JSON.stringify(
			new Account(
				authenticationResult.key,
				authenticationResult.token,
				null,
			),
		),
	);
}
