import { BaseStore } from '@intentic/ts-foundation';
import { action, computed, makeObservable, observable, ObservableMap, override } from 'mobx';
import { Product } from '../../../../../../Api/Product/Product';
import { ProductFeatureAssignment } from '../../../../../../Api/Product/ProductFeatureAssignment';
import { ProductFeatureVariant } from '../../../../../../Api/Product/ProductFeatureVariant';
import { Bridge } from '../../../../../../Bridge/Bridge';
import { Localizer } from '../../../../../../Bridge/Localization/Localizer';

export class FeatureConfigurationStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    private readonly localizer: Localizer;
    public readonly product: Product;
    featureAssignment: ProductFeatureAssignment;
    productCurrencyCode: string;
    onSelect: (variant: ProductFeatureVariant) => void;
    onDeselect: (variant: ProductFeatureVariant) => void;

    selectedVariants: ObservableMap<ProductFeatureVariant, boolean> = observable.map<ProductFeatureVariant, boolean>();

    public initialized: boolean;
    public showValidation?: boolean;

    // ------------------------ Constructor -------------------------

    constructor(
        bridge: Bridge,
        product: Product,
        featureAssignment: ProductFeatureAssignment,
        productCurrencyCode: string,
        onSelect: (variant: ProductFeatureVariant) => void,
        onDeselect: (variant: ProductFeatureVariant) => void,
    )
    {
        super();

        makeObservable<FeatureConfigurationStore, 'featureType'>(
            this,
            {
                featureAssignment: observable,
                productCurrencyCode: observable,
                onSelect: observable,
                onDeselect: observable,
                selectedVariants: observable,
                initialized: observable,
                showValidation: observable,
                isInStock: computed,
                nrOfSelectedVariants: computed,
                minAllowedVariants: computed,
                featureType: computed,
                effectiveMinAllowedVariants: computed,
                maxAllowedVariants: computed,
                effectiveMaxAllowedVariants: computed,
                productFeature: computed,
                constraintMessages: computed,
                selectorType: computed,
                validationErrors: computed,
                isValid: computed,
                hasAnyPrice: computed,
                hasAnyImage: computed,
                toggleSelection: action.bound,
                selectVariant: action.bound,
                deselectVariant: action.bound,
                setInitialized: override,
            },
        );

        this.localizer = bridge.localizer;
        this.product = product;
        this.featureAssignment = featureAssignment;
        this.productCurrencyCode = productCurrencyCode;
        this.onSelect = onSelect;
        this.onDeselect = onDeselect;
        this.initialized = false;
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<any>
    {
        this.featureAssignment.productFeature.variants
            .filter(
                variant =>
                    variant.isSelectedByDefault)
            .forEach(
                variant =>
                    this.selectVariant(variant));

        this.setInitialized();
        return Promise.resolve();
    }

    // -------------------------- Computed --------------------------

    get isInStock(): boolean
    {
        for (let variant of this.featureAssignment.productFeature.variants)
        {
            if (this.isVariantSelected(variant)
                && variant.quantity !== null
                && variant.quantity !== undefined
                && variant.quantity === 0)
            {
                return false;
            }
        }
        return true;
    }

    public get nrOfSelectedVariants(): number
    {
        return this.selectedVariants.size;
    }

    public get minAllowedVariants(): number | undefined
    {
        return this.productFeature.minAllowedVariants;
    }

    private get featureType(): 'alternative' | 'addition'
    {
        return this.productFeature.type;
    }

    public get effectiveMinAllowedVariants(): number | undefined
    {
        return this.featureType === 'alternative'
            ?
            1
            :
            this.productFeature.minAllowedVariants;
    }

    public get maxAllowedVariants(): number | undefined
    {
        return this.productFeature.maxAllowedVariants;
    }

    public get effectiveMaxAllowedVariants(): number | undefined
    {
        return this.featureType === 'alternative'
            ?
            1
            :
            this.productFeature.maxAllowedVariants;
    }

    public get productFeature()
    {
        return this.featureAssignment.productFeature;
    }

    public get constraintMessages(): string[]
    {
        const constraints: string[] = [];
        if (this.minAllowedVariants !== undefined)
        {
            constraints.push(`${this.localizer.translate('Generic-Min')} ${this.minAllowedVariants}`);
        }
        if (this.maxAllowedVariants !== undefined)
        {
            constraints.push(`${this.localizer.translate('Generic-Max')} ${this.maxAllowedVariants}`);
        }
        return constraints;
    }

    public get selectorType(): 'checkbox' | 'radio'
    {
        return (this.featureType === 'alternative'
            || (this.minAllowedVariants === 1 && this.maxAllowedVariants === 1)
        )
            ?
            'radio'
            :
            'checkbox';
    }

    public get validationErrors(): string[]
    {
        const errors: string[] = [];
        if (this.effectiveMinAllowedVariants !== undefined
            && this.nrOfSelectedVariants < this.effectiveMinAllowedVariants)
        {
            errors.push(
                this.effectiveMinAllowedVariants === 1
                    ?
                    this.localizer.translate('ProductFeature-MustHaveMinOneVariant')
                    :
                    this.localizer.translate('ProductFeature-MustHaveMinVariants', this.effectiveMinAllowedVariants.toString())
            )
        }
        if (this.effectiveMaxAllowedVariants !== undefined
            && this.nrOfSelectedVariants > this.effectiveMaxAllowedVariants)
        {
            errors.push(
                this.effectiveMaxAllowedVariants === 1
                    ?
                    this.localizer.translate('ProductFeature-MustHaveMaxOneVariant')
                    :
                    this.localizer.translate('ProductFeature-MustHaveMaxVariants', this.effectiveMaxAllowedVariants.toString())
            )
        }
        return errors;
    }

    public get isValid(): boolean
    {
        return this.validationErrors.length === 0;
    }

    public get hasAnyPrice(): boolean
    {
        return this.productFeature.variants
            .some(
                variant =>
                    this.doShowPrice(variant)
            );
    }

    public get hasAnyImage(): boolean
    {
        return this.productFeature.variants
            .some(
                variant =>
                    variant.imageUrl !== undefined
            );
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    toggleSelection(variant: ProductFeatureVariant)
    {
        if (this.isVariantSelected(variant)
            && this.featureAssignment.productFeature.type === 'addition')
        {
            this.deselectVariant(variant);
        }
        else
        {
            this.selectVariant(variant);
        }
    }

    selectVariant(variant: ProductFeatureVariant)
    {
        if (!this.isVariantSelected(variant))
        {
            if (this.featureAssignment.productFeature.type === 'alternative')
            {
                this.selectedVariants.forEach(
                    (isSelected, variant) =>
                        this.deselectVariant(variant));
            }
            this.selectedVariants.set(variant, true);
            this.onSelect(variant);
        }
    }

    deselectVariant(variant: ProductFeatureVariant)
    {
        if (this.isVariantSelected(variant))
        {
            this.selectedVariants.delete(variant);
            this.onDeselect(variant);
        }
    }

    // ------------------------ Public logic ------------------------

    doShowPrice(variant: ProductFeatureVariant): boolean
    {
        return variant.price.eq(0)
            ? !this.product.isPriceHiddenWhenZero
            : true;
    }

    isVariantSelected(variant: ProductFeatureVariant)
    {
        return this.selectedVariants.has(variant);
    }

    public setInitialized(): void
    {
        this.initialized = true;
    }

    // ----------------------- Private logic ------------------------
}
