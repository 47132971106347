import { createContext, FC, useContext, useMemo, useState } from 'react';
import { Order } from '../../../Api/Order/Order';

interface ProcessingOrderContext
{
	processingOrder: Order | undefined;
	setProcessingOrder: (value: Order | undefined) => void;
}

const ProcessingOrderContextRef = createContext<ProcessingOrderContext>(undefined!);

export function useProcessingOrderContext()
{
	return useContext(ProcessingOrderContextRef);
}

export const ProcessingOrderContextProvider: FC =
	({
		children,
	}) =>
	{
		const [processingOrder, setProcessingOrder] = useState<Order | undefined>();

		const contextValue = useMemo(
			() =>
				({
					processingOrder,
					setProcessingOrder,
				}),
			[processingOrder],
		);

		return <ProcessingOrderContextRef.Provider
			value={contextValue}
		>
			{children}
		</ProcessingOrderContextRef.Provider>;
	};
