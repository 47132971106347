import { TimeSchedule } from '../../../Api/Util/time-schedule/TimeSchedule';
import { TimeScheduleProfile } from '../../../Api/Util/time-schedule/TimeScheduleProfile';
import { getPlainListWithHeaders } from '../../../Util/Api';

export function getManagerTimeSchedules(headers: GetManagerTimeSchedulesHeaders): Promise<TimeSchedule[]>
{
	return getPlainListWithHeaders('/waiter/business/time-schedule/all', headers, {}, TimeScheduleProfile);
}

interface GetManagerTimeSchedulesHeaders extends Record<string, number | undefined>
{
	'Business-ID'?: number;
}