import { useMemo } from 'react';
import { useTranslate } from '../../../../Bridge/Localization/useTranslate';

export function useMaxLengthCheck(
	value: string | undefined,
	maxLength: number | undefined,
	showValidation: boolean = false
)
{
	const translate = useTranslate();
	const maxLengthError = useMemo(
		() => {
			const length = value?.length ?? 0;
			if (maxLength !== undefined && length > maxLength)
				return translate(
					'Generic-XCharactersOverLimit',
					(length - maxLength).toString()
				);
		},
		[maxLength, translate, value]
	);
	const maxLengthHelperText = useMemo(
		() => {
			const length = value?.length ?? 0;
			if (maxLength !== undefined)
			{
				if (length <= maxLength)
				{
					return translate(
						'Generic-XCharactersRemaining',
						(maxLength - length).toString(),
					);
				}
				else if (!showValidation)
				{
					return translate(
						'Generic-XCharactersOverLimit',
						(length - maxLength).toString(),
					);
				}
			}
		},
		[maxLength, showValidation, translate, value]
	);
	return {
		maxLengthError,
		maxLengthHelperText,
	};
}