import { Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import { FC } from 'react';
import { NamedOrderList } from '../NamedOrderList';
import { OrderListGridColumnDivider } from './OrderListGridColumnDivider';

const useStyles = makeStyles(theme => ({
	item: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		maxWidth: 'none',
	},
	listTitle: {
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1),
		},
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(2),
		},
		color: theme.palette.common.white,
	},
}));

interface OrderListGridItemProps
{
	addLeftDivider?: boolean;
	orderList: NamedOrderList;
}

export const OrderListGridColumn: FC<OrderListGridItemProps> =
	(
		{
			addLeftDivider,
			orderList,
		},
	) =>
	{
		const classes = useStyles();

		return <>
			{addLeftDivider && <OrderListGridColumnDivider />}
			<Grid
				classes={{
					item: classes.item,
				}}
				item
				xs={true}
			>
				{orderList.list}
			</Grid>
		</>;
	};