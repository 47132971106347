import { MixedGridItem } from './MixedGridItem';

export const preSortForMixedGridWithinOrderClass = (items: MixedGridItem[], nrOfColumns: number) => {
    const sorted: MixedGridItem[] = [];
    const toSort = items;
    let leftOverForNextChunk: MixedGridItem[] = [];
    while (toSort.length > 0 || leftOverForNextChunk.length > 0) {
        let chunk: MixedGridItem[] = [];
        leftOverForNextChunk.forEach(p => chunk.push(p));
        leftOverForNextChunk = [];
        while (toSort.length > 0) {
            let next = toSort[0];
            toSort.splice(0, 1);
            chunk.push(next);
            if (next.type === 'LIST_ITEM') {
                break;
            }
        }
        if (chunk.length > 0) {
            let last = chunk[chunk.length - 1];
            let tilesOnLastRowOfChunk = chunk.length % nrOfColumns > 0 ? chunk.length % nrOfColumns : nrOfColumns;
            if (last.type === 'LIST_ITEM' && tilesOnLastRowOfChunk !== 1) {
                let firstTileOnRowOfChunk = chunk[chunk.length - tilesOnLastRowOfChunk];
                chunk[chunk.length - tilesOnLastRowOfChunk] = last;
                chunk[chunk.length - 1] = firstTileOnRowOfChunk;
                for (let j = chunk.length - tilesOnLastRowOfChunk + 1; j < chunk.length; j++) {
                    leftOverForNextChunk.push(chunk[j]);
                }
                chunk = chunk.slice(0, chunk.length - tilesOnLastRowOfChunk + 1);
            }
        }
        for (let p of chunk) {
            sorted.push(p);
        }
    }
    return sorted;
};

export const preSortForMixedGrid = (items: MixedGridItem[], nrOfColumns: number) => {
    const itemsByOrderClass: MixedGridItem[][] = [];

    for (let item of items)
    {
        if (itemsByOrderClass[item.order] === undefined)
        {
            itemsByOrderClass[item.order] = [];
        }
        itemsByOrderClass[item.order].push(item);
    }

    const sortedItemsByOrderClass: MixedGridItem[][] = [];

    for (let orderClass of itemsByOrderClass)
    {
        if (orderClass !== undefined)
        {
            sortedItemsByOrderClass.push(preSortForMixedGridWithinOrderClass(orderClass, nrOfColumns));
        }
    }

    const allItemsSorted: MixedGridItem[] = [];

    for (let sortedOrderClass of sortedItemsByOrderClass)
    {
        allItemsSorted.push(...sortedOrderClass);
    }

    return allItemsSorted;
};
