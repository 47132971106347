import { makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import { FC, useCallback, useContext } from 'react';
import { useRootContext } from '../../../../../../../../RootContext';
import { BusinessContextRef } from '../../../../../BusinessContext';
import { QrCodeScanner } from '../../../../../../../UI/qr_code_scanner/QrCodeScanner';

const useStyles = makeStyles(theme => ({
	closeIcon: {
		color: theme.palette.common.white,
		position: 'absolute',
		right: theme.spacing(2),
		top: theme.spacing(2),
	},
	root: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		touchAction: 'none',
	},
}));

interface AgeVerificationVoucherScannerProps
{
	onClose: () => void;
}

export const AgeVerificationVoucherScanner: FC<AgeVerificationVoucherScannerProps> =
	({
		onClose,
	}) =>
	{
		const {notification} = useRootContext(true);
		const {currentAgeVerificationService} = useContext(BusinessContextRef);
		const classes = useStyles();
		const handleScan = 
			useCallback(
				async (data: string | undefined) =>
				{
					try
					{
						const trimmedData = data?.trim();

						if (trimmedData !== undefined && trimmedData.length > 0)
						{
							await currentAgeVerificationService.scanVoucher(
								trimmedData,
								notification
							);
						}
					}
					finally
					{
						onClose();
					}
				},
				[currentAgeVerificationService, notification, onClose]
			);
		
		return <div
			className={classes.root}
		>
			<QrCodeScanner
				onScan={handleScan}
			/>
			<CloseIcon
				className={classes.closeIcon}
				onClick={onClose}
			/>
		</div>;
	};
