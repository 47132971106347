import { useObserver } from 'mobx-react-lite';
import { DependencyList, useMemo } from 'react';
import { computed as mobxComputed} from 'mobx';

export function useComputed<T>(func: () => T, inputs: DependencyList): T
{
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const computed = useMemo(() => mobxComputed(func), inputs);
	return useObserver(() => computed.get());
}
