import Decimal from 'decimal.js';
import * as React from 'react';
import { FC, useMemo, useState } from 'react';
import { Business } from '../../../../../Api/Business/Business';
import { Place } from '../../../../../Api/Business/Place';
import { Currency } from '../../../../../Api/Other/Currency';
import { PaymentIssuer } from '../../../../../Api/Payment/PaymentIssuer';
import { PaymentMethodDescriptor } from '../../../../../Api/Payment/PaymentMethodDescriptor';
import { TimeSchedule } from '../../../../../Api/Util/time-schedule/TimeSchedule';
import { extractRelevantTaxGroupSpecifications } from '../../../../../Api/vat_group/util/extractRelevantTaxGroupSpecifications';
import { divideExact } from '../../../../../Util/decimal/divideExact';
import { AdyenCheckoutContextProvider } from '../../adyen/checkout/AdyenCheckoutContext';
import { UnavailablePaymentMethodContextProvider } from '../../adyen/checkout/context/UnavailablePaymentMethodProvider';
import { PaymentMethodAvailabilityContextProvider } from '../../availability/context/PaymentMethodAvailabilityContext';
import { PaymentIssuerContextProvider } from '../../issuer/context/PaymentIssuerContext';
import { PaymentMethodContextProvider } from '../../method/context/PaymentMethodContext';
import { PaymentTiming } from '../../model/PaymentTiming';
import { AdditiveTaxGroupKey } from '../../price/context/AdditiveTaxGroupKey';
import { PaymentPriceContextProvider } from '../../price/context/PaymentPriceContext';
import { ServiceFeeContextProvider } from '../../service_fee/context/ServiceFeeContext';
import { useUpFrontPaymentSupport } from './useUpFrontPaymentSupport';

interface PlacePaymentContextProviderProps
{
	business: Business;
	place: Place;
	baseAmount: Decimal;
	discountAmount?: Decimal;
	productFeesAmount?: Decimal;
	deliveryFeeAmount?: Decimal;
	tipAmount: Decimal | undefined;
	// additive tax amount per vat group id (not rounded)
	rawAdditiveTaxAmountPerTaxGroupId: Map<AdditiveTaxGroupKey, Decimal>;
	baseLineRoutingIds: number[];
	timing: PaymentTiming;
	resetAfterPayment?: boolean;
	onPaymentSessionHasErrorChange: (hasError: boolean) => void;
	timeScheduleById: Map<number, TimeSchedule>;
}

export const PlacePaymentContextProvider: FC<PlacePaymentContextProviderProps> =
	({
		business,
		place,
		baseAmount,
		discountAmount,
		productFeesAmount,
		deliveryFeeAmount,
		tipAmount,
		rawAdditiveTaxAmountPerTaxGroupId,
		baseLineRoutingIds,
		timing,
		resetAfterPayment = false,
		onPaymentSessionHasErrorChange,
		timeScheduleById,
		children,
	}) =>
	{
		const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodDescriptor | undefined>();
		const [selectedPaymentIssuer, setSelectedPaymentIssuer] = useState<PaymentIssuer | undefined>();

		// Place payment method restrictions are checked server-side
		const allowPayAfterwards = useMemo(() => timing === 'beforehand', [timing]);

		// Used as a key, to force re-renders, and consequently a new API request, whenever the support has changed
		// Actual updated results of the change (i.e. selection of payment methods) will be received from the API, which does the actual check
		const support = useUpFrontPaymentSupport(place, timeScheduleById);

		const hasNonZeroPaymentAmount = useMemo(() =>
		{
			return baseAmount
				.sub(discountAmount ?? 0)
				.add(productFeesAmount ?? 0)
				.add(deliveryFeeAmount ?? 0)
				.comparedTo(0) > 0;
		}, [baseAmount, deliveryFeeAmount, discountAmount, productFeesAmount]);

		const serviceFeeAmount = useMemo(
			() =>
			{
				const hasPaymentMethod = selectedPaymentMethod !== undefined;

				if (hasPaymentMethod && hasNonZeroPaymentAmount)
					return selectedPaymentMethod.fee;
				else
					return undefined;
			},
			[hasNonZeroPaymentAmount, selectedPaymentMethod],
		);

		const rawAdditiveTaxAmountPerTaxGroupIdIncludingServiceFee = useMemo(() =>
		{
			const rawAdditiveTaxAmountPerTaxGroupIdIncludingServiceFee = new Map(rawAdditiveTaxAmountPerTaxGroupId);

			if (serviceFeeAmount !== undefined && business.serviceFeeVatGroup?.type === 'Additive')
			{
				const relevantRoutingIds = business.serviceFeeRouting === undefined
					? baseLineRoutingIds.length === 0
						? [undefined]
						: baseLineRoutingIds
					: [business.serviceFeeRouting];

				const serviceFeeAmountPerRouting = divideExact(
					serviceFeeAmount,
					relevantRoutingIds.length,
					new Currency(business.productCurrencyCode).decimalPlaces,
				);

				extractRelevantTaxGroupSpecifications(business.serviceFeeVatGroup)
					.forEach(({id, percentage}) =>
					{
						relevantRoutingIds.forEach((routingId, index) =>
						{
							const additiveTaxAmount = serviceFeeAmountPerRouting[index].mul(percentage);

							const key: AdditiveTaxGroupKey = `r${routingId}_t${id}`;

							if (!rawAdditiveTaxAmountPerTaxGroupIdIncludingServiceFee.has(key))
							{
								rawAdditiveTaxAmountPerTaxGroupIdIncludingServiceFee.set(key, new Decimal(0));
							}

							rawAdditiveTaxAmountPerTaxGroupIdIncludingServiceFee.set(
								key,
								rawAdditiveTaxAmountPerTaxGroupIdIncludingServiceFee.get(key)!.add(additiveTaxAmount),
							);
						});
					});
			}

			return rawAdditiveTaxAmountPerTaxGroupIdIncludingServiceFee;
		}, [baseLineRoutingIds, business.productCurrencyCode, business.serviceFeeRouting, business.serviceFeeVatGroup, rawAdditiveTaxAmountPerTaxGroupId, serviceFeeAmount]);

		return <ServiceFeeContextProvider
			key={support}
			serviceFeeVatGroup={business.serviceFeeVatGroup}
			serviceFeeRouting={business.serviceFeeRouting}
			baseLineRoutingIds={baseLineRoutingIds}
		>
			<PaymentPriceContextProvider
				currencyCode={business.productCurrencyCode}
				baseAmount={baseAmount}
				discountAmount={discountAmount}
				productFeesAmount={productFeesAmount}
				deliveryFeeAmount={deliveryFeeAmount}
				serviceFeeAmount={serviceFeeAmount}
				tipAmount={tipAmount}
				rawAdditiveTaxAmountPerTaxGroupId={rawAdditiveTaxAmountPerTaxGroupIdIncludingServiceFee}
			>
				<AdyenCheckoutContextProvider
					enabled={business.butlarooPayEnvironment !== undefined && hasNonZeroPaymentAmount}
					environment={business.butlarooPayEnvironment ?? 'TEST'}
					onPaymentSessionHasErrorChange={onPaymentSessionHasErrorChange}
					paymentIssuer={selectedPaymentIssuer}
					paymentMethod={selectedPaymentMethod}
				>
					<UnavailablePaymentMethodContextProvider>
						<PaymentMethodAvailabilityContextProvider
							allowPayAfterwards={allowPayAfterwards}
							timing={timing}
						>
							<PaymentMethodContextProvider
								onChange={setSelectedPaymentMethod}
								resetAfterPayment={resetAfterPayment}
								timing={timing}
							>
								<PaymentIssuerContextProvider
									onChange={setSelectedPaymentIssuer}
									resetAfterPayment={resetAfterPayment}
								>
									{children}
								</PaymentIssuerContextProvider>
							</PaymentMethodContextProvider>
						</PaymentMethodAvailabilityContextProvider>
					</UnavailablePaymentMethodContextProvider>
				</AdyenCheckoutContextProvider>
			</PaymentPriceContextProvider>
		</ServiceFeeContextProvider>;
	};