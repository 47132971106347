import { postAny } from '../../../../../Util/Api';

export function postAssignPlaceCode(params: PostAssignPlaceCodeParams): Promise<PostAssignPlaceCodeResponse>
{
	return postAny('/owner/business/place/code/assign', params);
}

type PostAssignPlaceCodeResponse = 'false_not_authorized' | 'false_non_existent' | 'true'

interface PostAssignPlaceCodeParams
{
	place_code_id: number
	place_id: number
}