import { Loader } from '@intentic/ts-foundation';
import { CircularProgress, Fade, Theme, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import { CSSProperties, FC, MouseEvent, TouchEvent, useCallback, useMemo, useState } from 'react';
import { OrderDescriptor } from '../../../../../Api/Order/OrderDescriptor';
import { useTranslation } from '../../../../../Bridge/Localization/useTranslation';
import { getDateCreated } from '../../../../../Util/Orders/getDateCreated';
import { isComplete } from '../../../../../Util/Orders/isComplete';
import { InfiniteScroll } from '../../../../UI/InfiniteScroll/InfiniteScroll';
import { OrderListOrder } from '../context/order/OrderListOrder';
import { ScreenPosition } from '../ScreenPosition';
import { OrderListActionPopover } from './action-popover/OrderListActionPopover';
import { OrderListSortDirectionProvider, useOrderListSortDirection } from './context/sort-direction/OrderListSortDirectionProvider';
import { OrderEventsDialog } from './events/dialog/OrderEventsDialog';
import { OrderListHeader } from './header/OrderListHeader';
import { OrderInfoDialog } from './info/dialog/OrderInfoDialog';
import { OrderListItem } from './item/OrderListItem';

const LoadAmount = 10;

const useStyles = makeStyles((theme: Theme) => ({
	list: {
		alignItems: 'stretch',
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		height: 0, // Required to fix scrolling
		overflowY: 'auto',
		WebkitOverflowScrolling: 'touch',

		'&::-webkit-scrollbar':
			{
				display: 'none',
			},
	},
	listCentered: {
		justifyContent: 'center',
	},
	loader: {
		color: 'white',
	},
	loaderRoot: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
	},
	noOrdersText: {
		color: theme.palette.common.white,
		padding: theme.spacing(1),
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
	rootEmpty: {
		flexGrow: 1,
		justifyContent: 'center',
	},
}));

interface OrderListProps
{
	className?: string
	id: string
	listStyle?: CSSProperties
	orders: OrderListOrder[]
	style?: CSSProperties
	title?: string
}

export const OrderList: FC<OrderListProps> =
	props =>
	{
		return <OrderListSortDirectionProvider orderListId={props.id}>
			<Inner {...props} />
		</OrderListSortDirectionProvider>;
	};

export const Inner: FC<OrderListProps> =
	(
		{
			className,
			id,
			listStyle,
			orders = [],
			style,
			title,
		},
	) =>
	{
		const classes = useStyles();

		const [popoverAnchorPosition, setPopoverAnchorPosition] = useState<ScreenPosition | undefined>();
		const [popoverOrder, setPopoverOrder] = useState<OrderDescriptor | undefined>();
		const [openedOrder, setOpenedOrder] = useState<OrderDescriptor | undefined>();
		const [numberOfShownOrders, setNumberOfShownOrders] = useState(LoadAmount);
		const [orderEventsDialogOrderId, setOrderEventsDialogOrderId] = useState<number | undefined>();

		const onOrderClick = useCallback(
			(event: MouseEvent<HTMLDivElement> & TouchEvent<HTMLDivElement>, order: OrderDescriptor) =>
			{
				const x = event.clientX || event.touches?.[0]?.clientX;
				const y = event.clientY || event.touches?.[0]?.clientY;

				if (order.state === 'scheduled' || order.state === 'voided' || isComplete(order))
				{
					setOpenedOrder(order);
				}
				else if (x && y && order)
				{
					setPopoverAnchorPosition({x: x, y: y});
					setPopoverOrder(order);
				}
			},
			[],
		);

		const onActionPopoverClose = useCallback(
			() => {
				setPopoverAnchorPosition(undefined);
				setPopoverOrder(undefined);
			},
			[]
		);

		const onOrderInfoDialogClose = useCallback(() => setOpenedOrder(undefined), []);
		const onOrderEventsDialogClose = useCallback(() => setOrderEventsDialogOrderId(undefined), []);
		const onLoadMore = useCallback(() => setNumberOfShownOrders(numberOfShownOrders + LoadAmount), [numberOfShownOrders]);

		const sortDirection = useOrderListSortDirection();

		const sortedOrders = useMemo(() =>
		{
			const getSortProperty = (order: OrderDescriptor) =>
			{
				return order.state === 'scheduled'
					? new Date(order.scheduledFor!).getTime()
					: getDateCreated(order).getTime();
			};

			if (sortDirection === 'asc')
				return orders
					.slice()
					.sort((a, b) => getSortProperty(b.order) - getSortProperty(a.order));
			else
				return orders
					.slice()
					.sort((a, b) => getSortProperty(a.order) - getSortProperty(b.order));
		}, [orders, sortDirection])

		const displayedOrders = useMemo(
			() =>
			{
				let elements: OrderListOrder[] = [];

				const limit = Math.min(sortedOrders.length, numberOfShownOrders);
				for (let i = 0; i < limit; i++)
				{
					const order = sortedOrders[i];

					elements.push(order);
				}

				return elements;
			},
			[numberOfShownOrders, sortedOrders]
		);

		const loader = useMemo(
			() =>
				<div
					className={classes.loaderRoot}
					key={0}
				>
					<CircularProgress
						className={classes.loader}
						key="loader"
					/>
				</div>,
			[classes.loader, classes.loaderRoot]
		);

		const rootClassesString = useMemo(
			() =>
				sortedOrders.length === 0
					? `${classes.root} ${classes.rootEmpty}`
					: `${classes.root} ${className}`,
			[className, classes.root, classes.rootEmpty, sortedOrders]
		);

		const orderNoneTranslation = useTranslation('Order-None');

		if (sortDirection === undefined)
			return <Loader />;

		return <div
			className={rootClassesString}
			style={style}
		>
			<OrderListHeader title={title} />
			<div
				className={`${classes.list}${sortedOrders.length > 0 ? '' : ` ${classes.listCentered}`}`}
				id={id}
				style={listStyle}
			>
				{
					sortedOrders.length > 0
						?
						<InfiniteScroll
							hasMore={numberOfShownOrders < sortedOrders.length}
							loader={loader}
							loadMore={onLoadMore}
						>
							{displayedOrders.map((order, key) => <Fade
								in
								key={key}
							>
								<OrderListItem
									onClick={onOrderClick}
									order={order}
									style={{
										minWidth: 200,
									}}
								/>
							</Fade>)}
						</InfiniteScroll>
						:
						<Fade
							in
						>
							<Typography
								align="center"
								classes={{
									root: classes.noOrdersText,
								}}
							>
								{orderNoneTranslation}
							</Typography>
						</Fade>
				}
			</div>
			{
				popoverAnchorPosition &&
				popoverOrder &&
				<OrderListActionPopover
					anchorPosition={popoverAnchorPosition}
					onClose={onActionPopoverClose}
					onOpenOrder={setOpenedOrder}
					order={popoverOrder}
				/>
			}
			<OrderInfoDialog
				onClose={onOrderInfoDialogClose}
				onOpenOrderEvents={setOrderEventsDialogOrderId}
				order={openedOrder}
			/>
			<OrderEventsDialog
				onClose={onOrderEventsDialogClose}
				orderId={orderEventsDialogOrderId}
			/>
		</div>;
	};
