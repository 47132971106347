import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { Color } from '../../../../../../../Api/Other/Color';
import { Product } from '../../../../../../../Api/Product/Product';
import { SwiperControlDataItem } from '../../../../../../UI/SwiperControl/Model/SwiperControlDataItem';
import { SwiperControl } from '../../../../../../UI/SwiperControl/SwiperControl';
import { ProductMenuEntry } from '../../../Grid/MenuEntry/product/ProductMenuEntry';
import { MenuCarouselBackgroundFade } from './BackgroundFade/MenuCarouselBackgroundFade';

interface MenuCarouselTrackProps
{
	nrOfColumns: number;
	products: Product[];
	onOpen: (product: Product) => void;
	tintColor: Color;
	highlighted?: boolean;
	previewSwipe?: boolean;
}

export const MenuCarouselTrack: FC<MenuCarouselTrackProps> =
	(
		{
			nrOfColumns,
			products,
			onOpen,
			tintColor,
			highlighted,
			previewSwipe,
		},
	) =>
	{
		const items =
			useObserver<SwiperControlDataItem<Product>[]>(
				() =>
					products.map(
						product => ({
							id: product.uuid,
							getNode:
								product =>
									<ProductMenuEntry
										product={product}
										forceGridItemType="TILE_V2"
										onClick={() => {}}
									/>,
							value: product,
						})
					)
			);

		return <MenuCarouselBackgroundFade>
			<SwiperControl
				numberOfColumns={
					highlighted
						? Math.max(1, nrOfColumns - 1)
						: nrOfColumns
				}
				items={items}
				onClick={onOpen}
				tintColor={tintColor}
				previewSwipe={previewSwipe}
			/>
		</MenuCarouselBackgroundFade>;
	};
