// Assumption: MediaButtons appear in a grid of 1 - 4 columns with a max width of 1200px
const NormalizedMediaButtonWidthValues = [
	300,	// 4 columns
	400,	// 3 columns
	600,	// 2 columns
	1200,	// 1 column
];

export function normalizeMediaButtonWidth(width: number): number
{
	const roundedWidth = Math.round(width);

	return NormalizedMediaButtonWidthValues.find(normalizedWidth => roundedWidth <= normalizedWidth)
		?? NormalizedMediaButtonWidthValues[NormalizedMediaButtonWidthValues.length - 1];
}
