import { OrderDescriptor } from '../../Api/Order/OrderDescriptor';
import { hasPayment } from './hasPayment';
import { isPaid } from './isPaid';

export function isPaymentTimingOut(order: OrderDescriptor, softTimeoutThreshold: Date): boolean
{
	if (hasPayment(order) && !isPaid(order))
	{
		const date = order.dateScheduled ?? order.dateOrdered;

		return date === undefined
			? undefined
			: new Date(date) < softTimeoutThreshold;
	}
	else
	{
		return false;
	}
}
