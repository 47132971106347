import { CircularProgress, makeStyles } from '@material-ui/core';
import { green, orange, red, yellow } from '@material-ui/core/colors';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Decimal from 'decimal.js';
import { FC, useMemo } from 'react';
import { PaymentState } from '../../../../../../Api/Order/Order';
import { PaymentMethodId } from '../../../../../../Api/Payment/PaymentMethodId';
import { MoneyDescriptor } from '../../../../../../Api/Util/money/MoneyDescriptor';
import { useLocalizer } from '../../../../../../Bridge/Localization/useLocalizer';
import { PaymentIssuerAliases } from '../../../../../../Constants/PaymentIssuerAliases';
import { PaymentMethodAliases } from '../../../../../../Constants/PaymentMethodAliases';
import { GenericHistoricalItem } from '../GenericHistoricalItem';

const useStyles = makeStyles(theme => ({
	icon: {
		marginLeft: theme.spacing(1),
	},
	item: {
		transition: theme.transitions.create('background-color', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.complex,
		}),
	},
	spinner: {
		marginRight: theme.spacing(1),
	},
}));

interface HistoricalPaymentItemProps
{
	creationDate: string | undefined;
	isPaymentTimingOut: boolean;
	onClick: () => void;
	payment: MoneyDescriptor;
	paymentIssuerId: string | undefined;
	paymentMethodId: PaymentMethodId;
	paymentState: PaymentState;
}

export const HistoricalPaymentItem: FC<HistoricalPaymentItemProps> =
	({
		isPaymentTimingOut,
		onClick,
		payment,
		paymentIssuerId,
		paymentMethodId,
		paymentState,
	}) =>
	{
		const localizer = useLocalizer();

		const alias = useMemo(
			() =>
				PaymentIssuerAliases.get(paymentIssuerId) ??
				PaymentMethodAliases.get(paymentMethodId),
			[paymentIssuerId, paymentMethodId],
		);

		const description = useMemo(
			() =>
			{
				const formattedCurrency = localizer.formatCurrency(
					new Decimal(payment.amount),
					payment.currency,
				);

				switch (paymentState)
				{
					case 'failed':
					case 'unpaid':
						return localizer.translate('Order-Payment-Failed');
					case 'paid':
						return localizer.translate('Order-Payment-Paid', formattedCurrency);
					case 'created':
					case 'negotiated':
					default:
					{
						if (alias === undefined)
						{
							return localizer.translate(
								isPaymentTimingOut
									? 'Order-Payment-Expired-Indeterminate'
									: 'Order-Payment-Negotiated-Indeterminate',
								formattedCurrency,
							);
						}
						else
						{
							return localizer.translate(
								isPaymentTimingOut
									? 'Order-Payment-Expired'
									: 'Order-Payment-Negotiated',
								formattedCurrency,
								alias,
							);
						}
					}
				}
			},
			[alias, isPaymentTimingOut, localizer, payment.amount, payment.currency, paymentState],
		);

		const backgroundColor = useMemo(
			() =>
			{
				switch (paymentState)
				{
					case 'failed':
					case 'unpaid':
						return red[100];
					case 'paid':
						return green[100];
					case 'created':
					case 'negotiated':
					default:
						return isPaymentTimingOut
							? orange[200]
							: yellow[100];
				}
			},
			[isPaymentTimingOut, paymentState],
		);

		const classes = useStyles();

		return <GenericHistoricalItem
			className={classes.item}
			description={description}
			endAdornment={
				(paymentState === 'created' || paymentState === 'negotiated')
					? <HelpOutlineIcon
						className={classes.icon}
					/>
					: undefined
			}
			onClick={
				paymentState === 'created' || paymentState === 'negotiated'
					? onClick
					: undefined
			}
			startAdornment={
				(paymentState === 'created' || paymentState === 'negotiated') && !isPaymentTimingOut
					? <CircularProgress
						className={classes.spinner}
						size="1rem"
					/>
					: undefined
			}
			style={{
				backgroundColor,
			}}
		/>;
	};
