import { Typography } from '@material-ui/core';
import * as React from 'react';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useRootContext } from '../../../../RootContext';
import sanitizeHtml from '../../../../Util/Html/sanitizeHtml';
import { PageDialog } from '../../../UI/PageDialog';
import { AnnouncementScreenContextRef } from './AnnouncementScreenContext';

export const AnnouncementDialog: FC =
	() =>
	{
		const {navigator} = useRootContext(true);
		const popScreen = useCallback(
			() => navigator.popScreen(),
			[navigator]
		);
		const {
			announcement: {
				title,
				longContent,
				longContentInHtml,
				content
			}
		} = useContext(AnnouncementScreenContextRef);
		const sanitizedLongContentInHtml = useMemo(
			() => longContent && longContentInHtml ? sanitizeHtml(longContent) : undefined,
			[
				longContent,
				longContentInHtml
			]
		);
		return <PageDialog
			fullWidth
			maxWidth="md"
			onClose={popScreen}
			title={title}
		>
			{
				longContent !== undefined
					?
					(
						sanitizedLongContentInHtml
							?
							<Typography
								variant="body1"
								dangerouslySetInnerHTML={{__html: sanitizedLongContentInHtml}}
							/>
							:
							<Typography color="textSecondary">
								{longContent}
							</Typography>
					)
					:
					<Typography color="textSecondary">
						{content}
					</Typography>
			}
		</PageDialog>;
	};
