// Assumption: Product drawer information images appear in a grid of 2 columns with a max width of 1200px
const NormalizedValues = [
	200,	// 16.67% grid width
	400,	// 33.33% grid width
	600,	// 50% grid width
];

export function normalizeProductDrawerImageWidth(width: number): number
{
	const roundedWidth = Math.round(width);

	return NormalizedValues.find(normalizedWidth => roundedWidth <= normalizedWidth)
		?? NormalizedValues[NormalizedValues.length - 1];
}
