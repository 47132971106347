import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FC, useContext, useMemo } from 'react';
import { ProductRecommendationList as ProductRecommendationListModel } from '../../../../../Api/Product/ProductRecommendationList';
import { IllegalStateException } from '../../../../../Util/Exception/IllegalStateException';
import { useComputed } from '../../../../../Util/Hooks/useComputed';
import { useDimensions } from '../../../../../Util/Hooks/useDimensions';
import { BusinessContextRef } from '../../BusinessContext';
import { FixedGrid } from '../../Menu/Grid/FixedGrid/FixedGrid';
import { ProductMenuEntry } from '../../Menu/Grid/MenuEntry/product/ProductMenuEntry';
import { MENU_LIST_ITEM_MIN_HEIGHT, MENU_TILE_MIN_HEIGHT } from '../../Menu/Grid/MenuGrid';
import { Mixedgrid } from '../../Menu/Grid/MixedGrid/MixedGrid';
import { MixedGridItem, MixedGridItemType } from '../../Menu/Grid/MixedGrid/MixedGridItem';

const PRODUCT_TILE_MIN_WIDTH = 200;

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		marginBottom: theme.spacing(1),
		width: 'inherit',
	},
	recommendationText: {
		marginBottom: 9,
	},
}));

interface ProductRecommendationListProps
{
	hideText?: boolean;
	productRecommendationList: ProductRecommendationListModel;
	gridItemType: MixedGridItemType
}

export const ProductRecommendationList: FC<ProductRecommendationListProps> =
	(
		{
			hideText = false,
			productRecommendationList,
			gridItemType,
		},
	) =>
	{
		const {businessStore} = useContext(BusinessContextRef);

		const classes = useStyles();

		const text = useComputed(() => productRecommendationList.recommendationTextRaw, [productRecommendationList.recommendationTextRaw]);
		const recommendations = useComputed(
			() =>
				productRecommendationList
					.normalizedProductRecommendations
					.filter(recommendation => businessStore.productById.has(recommendation.recommendedProductId)),
			[productRecommendationList.productRecommendations],
		);

		const title = useMemo(
			() => !hideText && <Typography variant="h6" className={classes.recommendationText}>
				{text}
			</Typography>,
			[classes.recommendationText, hideText, text]
		);

		const [dimensionsRef, rootElementDimensions] = useDimensions();
		const nrOfColumns = useMemo(
			() => rootElementDimensions !== undefined
				? Math.max(
					Math.floor(rootElementDimensions.width / PRODUCT_TILE_MIN_WIDTH),
					2
				)
				: undefined,
			[rootElementDimensions]
		);

		const content: MixedGridItem[] = useMemo(
			() =>
				recommendations
					.map(
						recommendation => {
							const {
								recommendedProductId,
							} = recommendation;
							const product = businessStore.productById.get(recommendedProductId);

							if (product === undefined)
							{
								throw new IllegalStateException('Product recommended that is not visible');
							}

							return {
								key: recommendation.uuid,
								node:
									({rowLength}) =>
										<ProductMenuEntry
											product={product}
											minImageWidth={window.innerHeight / rowLength * window.devicePixelRatio}
											minImageHeight={window.devicePixelRatio * MENU_TILE_MIN_HEIGHT}
											forceGridItemType={gridItemType}
											key={recommendation.uuid}
										/>,
								order: 0,
								type: gridItemType,
							};
						}
				),
			[
				businessStore.productById,
				recommendations,
				gridItemType,
			]
		);

		return <div className={classes.root} ref={ref => dimensionsRef(ref!)}>
			{title}
			{
				nrOfColumns &&
				<>
					{
						gridItemType === 'TILE_V2'
							? <FixedGrid
								nrOfColumns={nrOfColumns}
								items={content}
							/>
							: <Mixedgrid
								nrOfColumns={nrOfColumns}
								items={content}
								listItemHeight={MENU_LIST_ITEM_MIN_HEIGHT}
								minTileHeight={MENU_TILE_MIN_HEIGHT}
							/>
					}
				</>
			}
		</div>;
	};
