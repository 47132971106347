import { Grid, makeStyles, Tab, Tabs, Theme } from '@material-ui/core';
import { BaseCSSProperties } from '@material-ui/styles/withStyles/withStyles';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useMemo } from 'react';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { useIsKioskMode } from '../../../../../Service/KioskService/Api/useIsKioskMode';
import { getTopBarHeight } from '../../../../UI/PageTopBar';
import { SessionStatusBarPlaceholder } from '../../../../UI/session-status-bar/SessionStatusBarPlaceholder';
import { useNumberOfFullscreenMenuGridColumns } from '../../Menu/Grid/Api/useNumberOfFullscreenMenuGridColumns';
import { MENU_LIST_ITEM_MIN_HEIGHT, MENU_TILE_MIN_HEIGHT } from '../../Menu/Grid/MenuGrid';
import { Mixedgrid } from '../../Menu/Grid/MixedGrid/MixedGrid';
import { MixedGridItem } from '../../Menu/Grid/MixedGrid/MixedGridItem';
import { ComoNoRewardsCard } from './ComoNoRewardsCard';
import { ComoPointShopBalance } from './ComoPointShopBalance';
import { ComoPointShopTile } from './ComoPointShopTile';
import { ComoRewardsStore } from './ComoRewardsStore';
import { ComoRewardTile } from './ComoRewardTile';

const useStyle = makeStyles<Theme, { isKioskMode: boolean }>(theme => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		marginRight: 0, // to make menu's top bar appear under sidebar's round edges
		overflow: 'hidden',
		paddingRight: 0, // to make menu's top bar appear under sidebar's round edges
	},
	menuGrid: {
		display: 'flex',
		flex: '1 0 auto',
		flexDirection: 'column',
		flexWrap: 'nowrap',
	},
	menuGridListContainer: (({isKioskMode}) =>
	{
		const base: BaseCSSProperties = {
			display: 'flex',
			flex: '1 0 auto',
			flexDirection: 'column',
		};

		const paddingValue = theme.spacing(1);

		const additional: BaseCSSProperties = {
			paddingLeft: paddingValue,
			paddingRight: paddingValue,
			paddingBottom: paddingValue,

			[theme.breakpoints.down('sm')]: {
				marginTop:
					getTopBarHeight(
						true,
						isKioskMode,
					) + paddingValue,
			},
			[theme.breakpoints.up('md')]: {
				marginTop:
					getTopBarHeight(
						false,
						isKioskMode,
					) + paddingValue,
			},
		};

		return {...base, ...additional};
	}),
	grid: {
		flex: '1 0 auto !important',
		display: 'flex',
		flexDirection: 'column',
	},
	mixedGrid: {
		flex: '1 0 auto',
	},
	root: {
		display: 'flex',
		flexGrow: 1,
	},
	tabs: {
		backgroundColor: 'rgba(0,0,0,0.04)',
		borderRadius: theme.shape.borderRadius,
	},
	tabsContainer: {
		flex: '0 0 auto !important',
		paddingLeft: 0,
		paddingRight: 0,
	},
	tab: {
		color: theme.palette.primary.main,
	},
	tabIndicator: {
		color: theme.palette.primary.main,
	},
}));

interface InitializedComoRewardsContentProps
{
	store: ComoRewardsStore;
}

export const InitializedComoRewardsContent: FC<InitializedComoRewardsContentProps> =
	({
		store,
	}) =>
	{
		const isKioskMode = useIsKioskMode();
		const classes = useStyle({isKioskMode});
		const numberOfGridColumns = useNumberOfFullscreenMenuGridColumns();
		const rewards = useObserver(() => store.rewards);
		const tabIndex = useObserver(() => store.tabIndex);
		const translate = useTranslate();

		const nrOfGridItems = rewards.length;

		const getMinImageWidth = useCallback((rowLength: number) => window.devicePixelRatio * window.innerWidth / rowLength, []);

		const rowHeight = useMemo(
			() => Math.max(
				MENU_TILE_MIN_HEIGHT,
				window.innerHeight / Math.ceil(nrOfGridItems / numberOfGridColumns),
			),
			[numberOfGridColumns, nrOfGridItems],
		);

		const minImageHeight = useMemo(() => window.devicePixelRatio * rowHeight, [rowHeight]);

		const comoGiftShopItems = rewards.filter(r => r.redeemable === undefined && r.points !== undefined);
		const comoRewards = rewards.filter(r => r.redeemable !== undefined && r.points === undefined);

		const rewardGridItems = useMemo<MixedGridItem[]>(() =>
		{
			return comoRewards.map(reward => ({
				type: 'TILE',
				order: 0,
				key: `Reward-${reward.id}`,
				node: ({rowLength}) => <ComoRewardTile
					store={store}
					reward={reward}
					minImageHeight={minImageHeight}
					minImageWidth={getMinImageWidth(rowLength)}
				/>,
			}));
		}, [comoRewards, getMinImageWidth, minImageHeight, store]);

		const giftShopGridItems = useMemo<MixedGridItem[]>(() =>
		{
			return comoGiftShopItems.map(reward => ({
				type: 'TILE',
				order: 0,
				key: `Point-Shop-${reward.id}`,
				node: ({rowLength}) => <ComoPointShopTile
					store={store}
					reward={reward}
					minImageHeight={minImageHeight}
					minImageWidth={getMinImageWidth(rowLength)}
				/>,
			}));
		}, [comoGiftShopItems, getMinImageWidth, minImageHeight, store]);

		return <div className={classes.container}>
			<div className={classes.menuGrid}>
				<div className={classes.menuGridListContainer}>
					<Grid
						classes={{
							container: classes.menuGrid,
						}}
						container
						spacing={1}
					>
						<ComoPointShopBalance store={store} />
						<Grid
							classes={{
								root: classes.tabsContainer,
							}}
							item
							xs={12}
						>
							<Tabs
								indicatorColor="primary"
								classes={{
									root: classes.tabs,
									indicator: classes.tabIndicator,
								}}
								onChange={
									(_, value) =>
										store.setTabIndex(value)
								}
								scrollButtons="on"
								value={tabIndex}
								variant="fullWidth"
							>
								<Tab
									classes={{
										root: classes.tab,
									}}
									className={classes.class}
									key={'reward'}
									label={translate('Client-Loyalty-Como-Redeem-Rewards')}
									value={0}
								>
								</Tab>
								<Tab
									classes={{
										root: classes.tab,
									}}
									key={'pointShop'}
									label={translate('Client-Loyalty-Como-Point-Shop')}
									value={1}
								>
								</Tab>
							</Tabs>
						</Grid>
						{tabIndex === 0 &&
							<Grid
								item
								xs={12}
								classes={{
									item: classes.grid,
								}}
							>
								{
									rewardGridItems.length > 0
										? <Mixedgrid
											className={classes.mixedGrid}
											nrOfColumns={numberOfGridColumns}
											items={rewardGridItems}
											listItemHeight={MENU_LIST_ITEM_MIN_HEIGHT}
											minTileHeight={MENU_TILE_MIN_HEIGHT}
										/>
										: <ComoNoRewardsCard />
								}
							</Grid>
						}
						{tabIndex === 1 &&
							<Grid
								item
								xs={12}
								classes={{
									item: classes.grid,
								}}
							>
								<Mixedgrid
									className={classes.mixedGrid}
									nrOfColumns={numberOfGridColumns}
									items={giftShopGridItems}
									listItemHeight={MENU_LIST_ITEM_MIN_HEIGHT}
									minTileHeight={MENU_TILE_MIN_HEIGHT}
								/>
							</Grid>
						}
					</Grid>
				</div>
			</div>
			<SessionStatusBarPlaceholder />
		</div>;
	};
