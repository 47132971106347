import { makeAutoObservable } from 'mobx';
import { SerializationProfile } from '../../Util/Serialization/Serialization';

export class Waiter
{
    id: number;

    /**
     * The uuid
     */
    guid: string;
    businessId: number;
    type: 'User' | 'Waiter' | 'Manager' | 'Owner';
    isRoot: boolean | undefined;
    orderHandlerSupportsOrderStateScheduled: boolean;
    orderHandlerSupportsOrderStateDelivered: boolean;
    orderHandlerSupportsOrderStatePickedUp: boolean;
    orderHandlerSupportsOrderStatePrepared: boolean;
    routingId: number | undefined;

    constructor()
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
    }
}

export const WaiterProfile =
    SerializationProfile.create(Waiter);
