export abstract class Linking
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------
    
    // ------------------------ Public logic ------------------------

    abstract canOpenUrl(url: string): Promise<boolean>;
    
    abstract openUrl(url: string): Promise<void>;
    
    abstract getInitialUrl(): Promise<string>;
    
    // ----------------------- Private logic ------------------------
}
