import { ApplicationInterface } from './ApplicationInterface';

export function setInApplicationInterface<T extends keyof ApplicationInterface>(
	key: T,
	value: ApplicationInterface[T],
): void
{
	const oldValue: ApplicationInterface = (window as any).Butlaroo ?? {};

	(window as any).Butlaroo = {
		...oldValue,
		[key]: value,
	};
}
