import Core from '@adyen/adyen-web/dist/types/core';
import { PaymentAmount } from '@adyen/adyen-web/dist/types/types';
import Decimal from 'decimal.js';
import { RefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { PaymentMethodDescriptor } from '../../../../../../Api/Payment/PaymentMethodDescriptor';
import { extractRelevantTaxGroupSpecifications } from '../../../../../../Api/vat_group/util/extractRelevantTaxGroupSpecifications';
import { divideExact } from '../../../../../../Util/decimal/divideExact';
import { useMemoizedDecimal } from '../../../../../../Util/decimal/useMemoizedDecimal';
import { usePaymentMethodContext } from '../../../method/context/PaymentMethodContext';
import { AdditiveTaxGroupKey } from '../../../price/context/AdditiveTaxGroupKey';
import { usePaymentPriceContext } from '../../../price/context/PaymentPriceContext';
import { useServiceFeeContext } from '../../../service_fee/context/ServiceFeeContext';
import { useAdyenCheckoutContext } from '../AdyenCheckoutContext';

export function useAdyenCheckoutSettingsComponent(
	containerRef: RefObject<HTMLDivElement>,
)
{
	const {activeComponent, client, setActiveComponent} = useAdyenCheckoutContext();
	const {routingIds, vatGroup} = useServiceFeeContext();
	const {currency, base, discount, productFees, deliveryFee, tip, serviceFee, rawAdditiveTaxPerTaxGroupId} = usePaymentPriceContext();
	const {selectedPaymentMethod} = usePaymentMethodContext();

	const activeComponentRef = useRef(activeComponent);

	useEffect(
		() =>
		{
			activeComponentRef.current = activeComponent;
		},
		[activeComponent],
	);

	const amountWithoutServiceFeeAndAdditiveTax = useMemoizedDecimal(
		base
			.sub(discount)
			.add(productFees)
			.add(deliveryFee)
			.add(tip),
	);

	const amountIncludingServiceFeeAndAdditiveTax = useMemo(
		() =>
		{
			const rawAdditiveTaxPerTaxGroupIdIncludingNewServiceFee = new Map(rawAdditiveTaxPerTaxGroupId);

			if (vatGroup?.type === 'Additive')
			{
				const oldServiceFeeAmountPerRouting = divideExact(
					serviceFee,
					routingIds.length,
					currency.decimalPlaces,
				);
				const newServiceFeeAmountPerRouting = divideExact(
					selectedPaymentMethod === undefined
						? new Decimal(0)
						: selectedPaymentMethod.fee,
					routingIds.length,
					currency.decimalPlaces,
				);

				extractRelevantTaxGroupSpecifications(vatGroup)
					.forEach(({id, percentage}) =>
					{
						routingIds.forEach((routingId, index) =>
						{
							const oldServiceFeeAdditiveTax = oldServiceFeeAmountPerRouting[index].mul(percentage);
							const newServiceFeeAdditiveTax = newServiceFeeAmountPerRouting[index].mul(percentage);

							const key: AdditiveTaxGroupKey = `r${routingId}_t${id}`;

							if (!rawAdditiveTaxPerTaxGroupIdIncludingNewServiceFee.has(key))
							{
								rawAdditiveTaxPerTaxGroupIdIncludingNewServiceFee.set(key, new Decimal(0));
							}

							rawAdditiveTaxPerTaxGroupIdIncludingNewServiceFee.set(
								key,
								rawAdditiveTaxPerTaxGroupIdIncludingNewServiceFee.get(key)!
									.sub(oldServiceFeeAdditiveTax)
									.add(newServiceFeeAdditiveTax),
							);
						});
					});
			}

			return Array
				.from(rawAdditiveTaxPerTaxGroupIdIncludingNewServiceFee.values())
				.reduce(
					(subTotal, taxAmount) =>
						subTotal.add(taxAmount.toDecimalPlaces(currency.decimalPlaces)),
					amountWithoutServiceFeeAndAdditiveTax
						.add(serviceFee),
				)
				.mul(new Decimal(10).pow(currency.decimalPlaces))
				.round()
				.toNumber();
		},
		[rawAdditiveTaxPerTaxGroupId, vatGroup, amountWithoutServiceFeeAndAdditiveTax, serviceFee, currency.decimalPlaces, routingIds, selectedPaymentMethod],
	);

	const onSelectedPaymentMethodChange = useCallback(
		async (
			client: Core,
			amount: number,
			paymentMethod: PaymentMethodDescriptor | undefined,
		) =>
		{
			if (paymentMethod?.butlarooPayPaymentMethod === undefined)
			{
				activeComponentRef.current?.unmount();
				setActiveComponent(undefined);
			}
			else
			{
				const newComponent = client.create(paymentMethod.butlarooPayPaymentMethod.type);

				if (activeComponentRef.current?.type === newComponent.type)
					newComponent.setState(activeComponentRef.current?.state ?? newComponent.state);

				activeComponentRef.current?.remove();

				setActiveComponent(newComponent);

				if (containerRef.current !== null)
				{
					newComponent.mount(containerRef.current);

					const newAmount: PaymentAmount = {
						currency: paymentMethod.currency.code,
						value: amount,
					};

					if (client.options.amount?.currency !== newAmount.currency || client.options.amount?.value !== newAmount.value)
					{
						await client.update({
							amount: newAmount,
						});
					}

					if (newComponent.props.amount?.currency !== newAmount.currency || newComponent.props.amount?.value !== newAmount.value)
					{
						newComponent.update({
							amount: newAmount,
						});
					}
				}
			}
		},
		[containerRef, setActiveComponent],
	);


	useEffect(
		() =>
		{
			if (client === undefined)
			{
				setActiveComponent(undefined);
			}
			else
			{
				// noinspection JSIgnoredPromiseFromCall
				onSelectedPaymentMethodChange(
					client,
					amountIncludingServiceFeeAndAdditiveTax,
					selectedPaymentMethod,
				);
			}
		},
		[amountIncludingServiceFeeAndAdditiveTax, client, onSelectedPaymentMethodChange, selectedPaymentMethod, setActiveComponent],
	);
}
