import * as React from 'react';
import { FC, useMemo } from 'react';
import { useStorage } from '../../../Component/Root/StorageContextProvider';
import { KioskServiceContext } from '../Context/KioskServiceContext';
import { KioskService } from '../KioskService';

export const KioskServiceProvider: FC =
	(
		{
			children,
		},
	) =>
	{
		const storage = useStorage();
		const kioskService =
			useMemo(
				() =>
					new KioskService(
						storage
					),
				[storage]
			);

		return <KioskServiceContext.Provider
			value={kioskService}
		>
			{children}
		</KioskServiceContext.Provider>;
	};
