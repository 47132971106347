import { AuthenticationResultV3 } from '../AuthenticationResult';

const GracePeriod = 30000;

export function getRefreshTimeoutPeriod(authenticationResult: AuthenticationResultV3): number
{
	const exp = Math.min(authenticationResult.accessTokenPayload.exp, authenticationResult.idTokenPayload.exp);

	return (exp * 1000) - new Date().getTime() - GracePeriod;
}
