import Decimal from 'decimal.js';
import { makeAutoObservable } from 'mobx';
import { ProductConfiguration } from '../../../../../Api/Product/ProductConfiguration';

export class ShoppingCartLine
{
	public productConfiguration: ProductConfiguration;
	public quantity: number;

	constructor(
		productConfiguration: ProductConfiguration,
		quantity: number,
	)
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});

		this.productConfiguration = productConfiguration;
		this.quantity = quantity;
	}

	public get price(): Decimal
	{
		return this.productConfiguration.price.mul(this.quantity);
	}

	public incrementQuantity()
	{
		this.quantity = this.quantity + 1;
	}

	public addQuantity(diff: number)
	{
		this.quantity = this.quantity + diff;
	}
}