import { BaseStore } from '@intentic/ts-foundation';
import { action, computed, makeObservable, observable } from 'mobx';
import * as React from 'react';
import { Coupon } from '../../../../../Api/Order/Coupon/Coupon';
import { CouponType } from '../../../../../Api/Order/Coupon/CouponDescriptor';
import { Localizer } from '../../../../../Bridge/Localization/Localizer';

export class CouponCardStore extends BaseStore
{
    /*---------------------------------------------------------------*
     *                          Properties                           *
     *---------------------------------------------------------------*/

    private readonly localizer: Localizer;
    readonly coupon: Coupon;
    readonly discount: number;
    contextMenuOpen: boolean;
    cardElementRef: React.RefObject<any>;
    onRemove: () => void;

    /*---------------------------------------------------------------*
     *                          Constructor                          *
     *---------------------------------------------------------------*/

    constructor(
        localizer: Localizer,
        coupon: Coupon,
        discount: number,
        onRemove: () => void,
    )
    {
        super();
        makeObservable(
            this,
            {
                contextMenuOpen: observable,
                cardElementRef: observable,
                onRemove: observable,
                couponType: computed,
                couponName: computed,
                couponEffectDescription: computed,
                removeCardLabel: computed,
                setContextMenuOpen: action.bound,
                setCardElementRef: action.bound,
                onClickRemove: action.bound,
            },
        );
        this.localizer = localizer;
        this.coupon = coupon;
        this.discount = discount;
        this.onRemove = onRemove;
        this.contextMenuOpen = false;
        this.cardElementRef = React.createRef();
    }

    /*---------------------------------------------------------------*
     *                        Initialization                         *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                           Computed                            *
     *---------------------------------------------------------------*/

    get couponType(): CouponType
    {
        return this.coupon.type;
    }

    get couponName(): string
    {
        return this.coupon.displayName;
    }

    get couponEffectDescription(): string
    {
        return this.coupon.effectDescriptionLong(
            this.localizer,
            this.discount
        );
    }

    get removeCardLabel(): string
    {
        return this.localizer.translate('Client-Coupon-RemoveFromOrder');
    }

    /*---------------------------------------------------------------*
     *                            Actions                            *
     *---------------------------------------------------------------*/

    setContextMenuOpen(contextMenuOpen: boolean)
    {
        this.contextMenuOpen = contextMenuOpen;
    }

    setCardElementRef(value: React.RefObject<any>)
    {
        this.cardElementRef = value;
    }

    onClickRemove(): void
    {
        this.setContextMenuOpen(false);
        this.onRemove();
    }

    /*---------------------------------------------------------------*
     *                          Public logic                         *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                         Private logic                         *
     *---------------------------------------------------------------*/
}
