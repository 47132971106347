import { CheckoutSessionSetupResponse } from '@adyen/adyen-web/dist/types/types';
import Decimal from 'decimal.js';
import { OrderDestinationAddress } from '../../../../Api/Business/OrderDestinationAddress';
import { OrderDestinationType } from '../../../../Api/Business/Place';
import { Order, OrderProfile } from '../../../../Api/Order/Order';
import { postJson } from '../../../../Util/Api';

export function placeProductOrder(params: PlaceProductOrderParams, request: PlaceProductOrderRequest): Promise<Order>
{
	return postJson('/client/business/product-order/place', params, OrderProfile, request);
}

interface PlaceProductOrderParams
{
	payment_issuer_id?: string;
	cart_id?: number;
	client_name?: string;
	coupon_codes?: string[];
	como_asset_keys?: string[];
	new_payment_methods: boolean;
	os: string;
	scheduled_for?: string | null;
	client_phone_number?: string;
	client_email?: string;
	longitude?: number;
	latitude?: number;
	externalShopperNotificationId?: string;
	externalShopperCardId?: string;
}

interface PlaceProductOrderRequest
{
	paymentMethodId: string;
	comment?: string;
	tip?: Decimal;
	lines: ProductOrderRequestLine[];
	uuid?: string,
	destination_type?: OrderDestinationType;
	address?: OrderDestinationAddress,
	embeddableHtmlConsentObtained?: boolean
	placeId: number
	password?: string
	customerCardId?: string
	ageVerificationChallengeId?: string
	adyenCheckoutPaymentInformation?: Record<string, string>
	paymentSession?: CheckoutSessionSetupResponse
	externalBillId?: string
}

export type ProductOrderRequestLine = ProductLine | ExternalBillLine | RequestBillLine | RequestWaiterLine;

interface ProductLine
{
	type: 'product'
	product_id?: number;
	product_variants: ProductLineVariant[];
	quantity?: number;
}

interface ProductLineVariant
{
	product_feature_assignment_id: number;
	product_feature_variant_id: number;
}

interface ExternalBillLine
{
	type: 'external_bill'
	external_order_line_uuid?: string;
	price: number;
	quantity: number;
	routing_id: number;
	pos_id?: string;
}

interface RequestBillLine
{
	type: 'request_bill';
}

interface RequestWaiterLine
{
	type: 'request_waiter';
}
