import { createContext, useContext, useMemo } from 'react';
import { Business } from '../../../Api/Business/Business';
import { BrandingService } from '../../../Service/BrandingInformation/BrandingService';
import { WaiterService } from '../../../Service/UserService/WaiterService';
import { IllegalStateException } from '../../../Util/Exception/IllegalStateException';

export interface Context
{
	brandingService: BrandingService;
	business: Business;
	businessWaiterWorksFor: Business;
	waiterService: WaiterService;
}

const ContextRef = createContext<Context | undefined>(undefined);

export const ManagerContextProvider = ContextRef.Provider;

export function useManagerContext()
{
	const context = useContext(ContextRef);
	return useMemo(
		() => {
			if (context === undefined)
				throw new IllegalStateException(
					'Attempting to use useManagerContext outside of ManagerContextProvider'
				);
			return context;
		},
		[context],
	);
}