import { makeStyles, Typography } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { useCurrentOrderService } from '../../BusinessContext';

const useStyles = makeStyles({
	label: {
		marginBottom: 8,
	},
});

export const SchedulerPhoneNumberLabel: FC =
	() =>
	{
		const translate = useTranslate();
		const currentOrderService = useCurrentOrderService(true);

		const classes = useStyles();

		const effectiveDestinationType = useObserver(() => currentOrderService.effectiveDestinationType);

		const label = useMemo(
			() =>
				translate(
					effectiveDestinationType === 'PICKUP_POINT'
						? 'Order-Pickup-Notification'
						: 'Order-Delivery-Notification',
					translate(
						'Generic-Phone-Number',
					)
						.toLowerCase(),
				),
			[effectiveDestinationType, translate],
		);

		return <Typography
			className={classes.label}
			color="textSecondary"
		>
			{label}
		</Typography>;
	};
