import { makeStyles, MenuItem, Theme } from '@material-ui/core';
import { getName as getCountryName, getNames as getCountryNames, registerLocale } from 'i18n-iso-countries';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { ChangeEvent, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { OrderDestinationAddress } from '../../../Api/Business/OrderDestinationAddress';
import { AddressRestriction } from '../../../Api/Other/AddressRestriction';
import { useLocalizer } from '../../../Bridge/Localization/useLocalizer';
import { useTranslation } from '../../../Bridge/Localization/useTranslation';
import { OutlinedSelect } from '../../UI/Form/OutlinedSelect/OutlinedSelect';
import { PostalCodeForm } from '../../UI/Form/PostalCodeForm/PostalCodeForm';
import { TextForm } from '../../UI/Form/TextForm/TextForm';
import { doesCountryHavePostalCode } from './Api/doesCountryHavePostalCode';
import { BusinessContextRef } from './BusinessContext';

const useStyle = makeStyles((theme: Theme) => ({
	formBottom: {
		marginTop: theme.spacing(1),
	},
	formCenter: {
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
	formTop: {
		marginBottom: theme.spacing(1),
	},
	sectionHeader: {
		fontSize: '1.25rem',
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(4),
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	subForm: {
		flex: '0 0 auto',
		display: 'flex',
		flexDirection: 'column',
	},
}));

interface OrderDestinationAddressFormProps
{
	addressRestrictions?: AddressRestriction[]
	value: OrderDestinationAddress
	onChange: (orderDestinationAddress: OrderDestinationAddress) => void
	onValidityUpdate: (isValid: boolean) => void
	showValidation?: boolean
}

export const OrderDestinationAddressForm: FC<OrderDestinationAddressFormProps> =
	(
		{
			addressRestrictions,
			value,
			onChange,
			onValidityUpdate,
			showValidation = true,
		},
	) =>
	{
		const {businessStore} = useContext(BusinessContextRef);

		const classes = useStyle({});

		const localizer = useLocalizer();

		const countries = useMemo(
			() =>
			{
				const countryNameLanguage = localizer.locale?.languageCode ?? 'en';

				registerLocale(require(`i18n-iso-countries/langs/${countryNameLanguage}.json`));

				if (addressRestrictions !== undefined && addressRestrictions.length > 0)
				{
					return  addressRestrictions
						.map(addressRestrictions => addressRestrictions.countryCode)
						.map(
							countryCode =>
								({
									code: countryCode.toUpperCase(),
									name: getCountryName(countryCode.toUpperCase(), countryNameLanguage),
								}),
						)
						.sort((a, b) => a.name.localeCompare(b.name));
				}
				else
				{
					const countriesObject = getCountryNames(localizer.locale?.languageCode ?? 'en');

					return Object
						.keys(countriesObject)
						.map(key => ({code: key, name: countriesObject[key]}))
						.sort((a, b) => a.name.localeCompare(b.name));
				}
			},
			[addressRestrictions, localizer.locale],
		);
		const [name, setName] = useState(value?.name);
		const [companyName, setCompanyName] = useState(value?.companyName);
		const [street, setStreet] = useState<string | undefined>(value?.street);
		const [number, setNumber] = useState<string | undefined>(value?.number);
		const [postalCode, setPostalCode] = useState<string | undefined>(value?.postalCode);
		const [city, setCity] = useState<string | undefined>(value?.city);
		const [country, setCountry] = useState(
			() =>
			{
				const selectedCountry = countries.some(({code}) => value?.country === undefined || value?.country === code)
					? value?.country
					: localizer.locale?.countryCode;

				return selectedCountry ?? businessStore.business?.address?.country ?? countries[0]?.code;
			},
		);

		const onCountryChange = useCallback(
			(event: ChangeEvent<HTMLSelectElement>) =>
			{
				const country = event.target.value;
				setCountry(country);
				setIsCountryError(country === undefined);
			},
			[],
		);

		useEffect(() => {
				onChange({
					street,
					number,
					name,
					companyName,
					postalCode,
					city,
					country,
				})
			},
			[city, companyName, country, name, number, onChange, postalCode, street],
		);

		const activePostalCodeRestrictions = useObserver(
			() =>
				 addressRestrictions?.slice()
					.filter(addressRestriction => addressRestriction.countryCode === country)
					.flatMap(addressRestriction => addressRestriction.postalCodes.slice())
				?? [],
		);

		const isPostalCodeSupported =
			useMemo(
				() =>
					country.length > 0
						? doesCountryHavePostalCode(country)
						: undefined,
				[country]
			);

		const [isNameError, setIsNameError] = useState(name === '');
		const [companyNameError, setIsLegalBusinessNameError] = useState(companyName === '');
		const [isStreetError, setIsStreetError] = useState(street === '');
		const [isHouseNumberOrNameError, setIsHouseNumberOrNameError] = useState(number === '');
		const [isPostalCodeError, setIsPostalCodeError] = useState(isPostalCodeSupported && postalCode === '');
		const [isCityError, setIsCityError] = useState(city === '');
		const [isCountryError, setIsCountryError] = useState(country === '');

		useEffect(
			() => onValidityUpdate(
				!(isNameError || companyNameError || isStreetError || isHouseNumberOrNameError || isPostalCodeError || isCityError || isCountryError)
			),
			[companyNameError, isCityError, isCountryError, isHouseNumberOrNameError, isNameError, isPostalCodeError, isStreetError, onValidityUpdate],
		);

		const postalCodeLabel = useTranslation('Address-PostalCode');
		const postalCodeRequiredLabel = useTranslation('Address-PostalCode-Required');
		const postalCodeRestrictedLabel = useTranslation('Address-PostalCode-Order-Delivery-Restricted');

		return <div
			className={classes.root}
		>
			<TextForm
				label={useTranslation('Generic-Name')}
				marginTop
				marginBottom
				margin="dense"
				onErrorUpdate={setIsNameError}
				required
				requiredLabel={'Please enter your name'}
				setValue={value => setName(value === '' ? undefined : value)}
				value={name ?? ''}
				showValidation={showValidation}
			/>
			<TextForm
				label={useTranslation('Client-CompanyName')}
				marginTop
				marginBottom
				margin="dense"
				onErrorUpdate={setIsLegalBusinessNameError}
				setValue={value => setCompanyName(value === '' ? undefined : value)}
				value={companyName ?? ''}
				showValidation={showValidation}
			/>
			<OutlinedSelect
				disabled={countries.length === 1}
				value={country ?? ''}
				onChange={onCountryChange}
				label={useTranslation('Generic-Country')}
				required
				margin="dense"
				error={showValidation && isCountryError}
				helperText={useTranslation(
					(showValidation && isCountryError)
						? 'Address-Country-Required'
						: undefined
				)}
				style={{
					marginTop: 8,
					marginBottom: 8,
				}}
			>
				{
					countries.map(
						countryDescriptor =>
							<MenuItem
								key={countryDescriptor.code}
								value={countryDescriptor.code}
							>
								{countryDescriptor.name}
							</MenuItem>,
					)
				}
			</OutlinedSelect>
			<TextForm
				label={useTranslation('Address-Street')}
				marginBottom
				marginTop
				margin="dense"
				onErrorUpdate={setIsStreetError}
				required
				requiredLabel={useTranslation('Address-Street-Required')}
				setValue={value => setStreet(value === '' ? undefined : value)}
				value={street ?? ''}
				showValidation={showValidation}
			/>
			<TextForm
				label={useTranslation('Address-HouseNumberOrName')}
				marginBottom
				marginTop
				margin="dense"
				onErrorUpdate={setIsHouseNumberOrNameError}
				required
				requiredLabel={useTranslation('Address-HouseNumberOrName-Required')}
				setValue={value => setNumber(value === '' ? undefined : value)}
				value={number ?? ''}
				showValidation={showValidation}
			/>
			{
				isPostalCodeSupported &&
				<PostalCodeForm
					label={postalCodeLabel}
					margin="dense"
					marginBottom
					marginTop
					onErrorUpdate={setIsPostalCodeError}
					postalCodeRestrictions={activePostalCodeRestrictions}
					required
					requiredLabel={postalCodeRequiredLabel}
					restrictedLabel={`${postalCodeRestrictedLabel}.`}
					setValue={value => setPostalCode(value === '' ? undefined : value)}
					showValidation={showValidation}
					value={postalCode ?? ''}
				/>
			}
			<TextForm
				label={useTranslation('Address-City')}
				marginBottom
				marginTop
				margin="dense"
				onErrorUpdate={setIsCityError}
				required
				requiredLabel={useTranslation('Address-City-Required')}
				setValue={value => setCity(value === '' ? undefined : value)}
				value={city ?? ''}
				showValidation={showValidation}
			/>
		</div>;
	};