import { Card, CircularProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from '../../../../../../Bridge/Localization/useTranslation';

const useStyles = makeStyles((theme: Theme) => ({
	card: {
		backgroundColor: '#fff8',
		padding: `${theme.spacing(1.5)}px ${theme.spacing(1.5)}px ${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
	},
	loader: {
		color: 'rgba(0, 0, 0, 0.54)',
	},
	root: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		width: '100%',
	},
	title: {
		marginRight: `${theme.spacing(0.5)}px !important`,
	},
}));

export interface PaymentMethodsLoadingCardProps
{
	className?: string;
}

export const PaymentMethodsLoadingCard: FC<PaymentMethodsLoadingCardProps> =
	({
		className,
	}) =>
	{
		const classes = useStyles();

		const cardClassName = clsx(classes.card, className);

		return <Card className={cardClassName}>
			<div className={classes.root}>
				<Typography className={classes.title} color="textPrimary" variant="body1">
					{useTranslation('Payment-Method')}
				</Typography>
				<CircularProgress className={classes.loader} size={20} variant="indeterminate" />
			</div>
		</Card>;
	};