import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { PaymentDialog } from '../../../UI/payment/dialog/PaymentDialog';
import { Menu } from './Menu';
import { MenuStore } from './MenuStore';

interface MenuWrapperProps
{
	onStartPayingBill: () => void;
	openNavigationMenu?: () => void;
	store: MenuStore;
}

export const MenuWrapper: FC<MenuWrapperProps> =
	(
		{
			onStartPayingBill,
			openNavigationMenu,
			store,
		},
	) =>
	{
		const openHistory = useObserver(() => store.openHistory);
		const openStore = useObserver(() => store.openSubMenu ?? store);

		return <>
			<Menu
				onStartPayingBill={onStartPayingBill}
				openNavigationMenu={openNavigationMenu}
				store={openStore}
			/>
			<PaymentDialog
				onClose={openHistory}
			/>
		</>;
	};