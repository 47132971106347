const NormalizedMediaButtonHeightValues = [
	300,	// Extra
	400,	// Extra
	600,	// Full height - iPhone SE
	800,	// Full height - iPhone
	1000,	// Full height - iPhone Pro Max
	1200,	// Full height - iPad
	1400,	// Full height - iPad Pro
];

export function normalizeMediaButtonHeight(height: number): number
{
	const roundedHeight = Math.round(height);

	return NormalizedMediaButtonHeightValues.find(normalizedHeight => roundedHeight <= normalizedHeight)
		?? NormalizedMediaButtonHeightValues[NormalizedMediaButtonHeightValues.length - 1];
}
