import { MenuItem, Select, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Timelapse from '@material-ui/icons/Timelapse';
import clsx from 'clsx';
import Decimal from 'decimal.js';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { CSSProperties, FC, useContext, useMemo } from 'react';
import { OrderDescriptor } from '../../../../../../Api/Order/OrderDescriptor';
import { moveOrder } from '../../../../../../Service/OrderService/Api/Waiter/moveOrder';
import { getDateCreated } from '../../../../../../Util/Orders/getDateCreated';
import { isComplete } from '../../../../../../Util/Orders/isComplete';
import { EntranceContextRef } from '../../../../Entrance/EntranceContext';
import { useSortedManagerPlaces } from '../../../context/place/ManagerPlacesContext';
import { useOrderHandlerReloadOrder } from '../../context/order/order-handler-order-context';
import { getDifference } from '../../getDifferenceInSeconds';

const useStyles = makeStyles(theme => ({
	contentContainer: {
		[theme.breakpoints.down('xs')]: {
			alignItems: 'center',
			justifyContent: 'space-between',
			paddingTop: theme.spacing(1),
		},
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'space-between',
	},
	contentContainerWhiteText: {
		'& *': {
			color: 'white',
		},
	},
	dateText: {
		fontWeight: 'bold',
		marginRight: theme.spacing(2),
		whiteSpace: 'nowrap',
	},
	dateTimeTextRoot: {
		display: 'flex',
	},
	orderPriceOrType: {
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(2),
		},
		marginRight: theme.spacing(2),
		whiteSpace: 'nowrap',
	},
	placeSelectRoot: {
		backgroundColor: theme.palette.primary.main,
		borderRadius: theme.shape.borderRadius,
		boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.05), 0px 3px 1px -2px rgba(0, 0, 0, 0.05)',
		minWidth: 35,
	},
	placeSelectSelect: {
		color: theme.palette.primary.contrastText,
		display: 'flex',
		justifyContent: 'center',
		minWidth: 20,
		padding: `${theme.spacing(1)}px !important`,
	},
	root: {
		[theme.breakpoints.up('sm')]: {
			alignItems: 'center',
		},
		[theme.breakpoints.down('xs')]: {
			alignItems: 'stretch',
			flexDirection: 'column',
		},
		display: 'flex',
		justifyContent: 'space-between',
		padding: theme.spacing(1),
	},
	timeText: {
		marginRight: theme.spacing(1),
		whiteSpace: 'nowrap',
	},
}));

export interface Props
{
	order: OrderDescriptor
	linesSplitOverMoreLists: boolean
	style?: CSSProperties
}

export const OrderListItemHeader: FC<Props> =
	(
		{
			order,
			linesSplitOverMoreLists,
			style,
		},
	) =>
	{
		const classes = useStyles();

		const {clockService, localizer} = useContext(EntranceContextRef);
		const reloadOrder = useOrderHandlerReloadOrder();
		const places = useSortedManagerPlaces();

		const elapsedTimeText = useObserver(() =>
		{
			if (order.state === 'scheduled' && order.scheduledFor !== undefined)
			{
				const scheduledFor =  new Date(order.scheduledFor);

				const timeString = scheduledFor
					.toLocaleTimeString([], {hour: 'numeric', minute: '2-digit'});

				const currentDate = new Date();

				const isScheduledForToday = scheduledFor.getFullYear() === currentDate.getFullYear()
					&& scheduledFor.getMonth() === currentDate.getMonth()
					&& scheduledFor.getDay() === currentDate.getDay();

				if (isScheduledForToday)
				{
					return <div className={classes.dateTimeTextRoot}>
						<Typography
							classes={{
								root: classes.dateText,
							}}
						>
							{localizer.translate('DateTime-Today')}
						</Typography>
						{timeString.length < 5 && <>&nbsp;</>}
						<Typography
							classes={{
								root: classes.timeText,
							}}
						>
							{timeString}
						</Typography>
						<Timelapse />
					</div>;
				}
				else
				{
					return <div className={classes.dateTimeTextRoot}>
						<Typography
							classes={{
								root: classes.dateText,
							}}
						>
							{scheduledFor.toLocaleDateString()}
						</Typography>
						{timeString.length < 5 && <>&nbsp;</>}
						<Typography
							classes={{
								root: classes.timeText,
							}}
						>
							{timeString}
						</Typography>
						<Timelapse />
					</div>;
				}
			}
			else
			{
				const differenceInSeconds = getDifference(clockService.currentSecond, getDateCreated(order)!, 'seconds');

				return <div className={classes.dateTimeTextRoot}>
					<Typography
						classes={{
							root: classes.timeText,
						}}
					>
						{localizer.formatShortTimeString(differenceInSeconds)}
					</Typography>
					<Timelapse />
				</div>;
			}
		});

		const placeSelect = useMemo(() =>
		{
			if (places !== undefined)
			{
				return <Select
					classes={{
						root: classes.placeSelectRoot,
						select: classes.placeSelectSelect,
					}}
					disabled={isComplete(order) || order.state === 'voided'}
					disableUnderline
					IconComponent={() => null}
					onChange={async event =>
					{
						const value = parseInt(event.target.value as string, 10);

						if (!isNaN(value))
						{
							const newOrder = await moveOrder(order, value);

							reloadOrder(newOrder);
						}
					}}
					onClose={event => event.stopPropagation()}
					onOpen={event => event.stopPropagation()}
					value={order.placeId}
				>
					{
						places?.map(place => <MenuItem
							key={place.id}
							value={place.id
							}
						>
							{place.name}
						</MenuItem>)
					}
				</Select>;
			}
		}, [classes.placeSelectRoot, classes.placeSelectSelect, order, places, reloadOrder]);

		const orderPriceOrType = useMemo(
			() =>
				<Typography
					classes={{
						root: classes.orderPriceOrType,
					}}
					variant="button"
				>
					{
						order.orderLines && order.orderLines.length > 0
							? (order.paymentPrice !== undefined && order.paymentPrice > 0)
								? localizer.formatCurrency(new Decimal(order.paymentPrice), order.currency)
								: localizer.translate('Payment-Free')
							: localizer.translate('Generic-None')
					}
				</Typography>,
			[classes, localizer, order],
		);

		return <div
			className={classes.root}
			style={style}
		>
			{placeSelect}
			<div className={clsx(classes.contentContainer, linesSplitOverMoreLists && classes.contentContainerWhiteText)}>
				{orderPriceOrType}
				{elapsedTimeText}
			</div>
		</div>;
	};