import { Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SubDirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import * as React from 'react';
import { FC } from 'react';
import { UIColors } from '../../../../Constants/UIColors';

const useStyles = makeStyles<Theme, FormLabelProps>(theme => ({
	icon: {
		color: UIColors.greyDark,
		marginTop: -6,
	},
	label: {
		alignItems: 'center',
		display: 'flex',
	},
	labelRoot: {
		flexGrow: 1,
		justifyContent: 'space-between',
		marginRight: 0,

		'& > div': {
			height: 38,
		},
	},
	root: {
		display: 'flex',
		flexDirecton: 'row',
		justifyContent: 'space-between',
		paddingBottom: theme.spacing(0.5),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(0.5),
		'&:hover': {
			backgroundColor: 'rgba(0,0,0,0.05)',
		},
	},
	labelRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		textAlign: 'left',
	},
	explanation: ({secondary}) => ({
		marginLeft: secondary ? 24 : undefined,
	}),
}))

interface FormLabelProps
{
	label: string
	explanation?: string
	secondary?: boolean
}

export const FormLabel: FC<FormLabelProps> =
	(
		{
			label,
			explanation,
			secondary = false,
		},
	) =>
	{
		const classes = useStyles({label, explanation, secondary});
		return secondary
			?
			<div>
				<div className={classes.labelRow}>
					<SubDirectoryArrowRightIcon
						classes={{
							root: classes.icon,
						}}
					/>
					<Typography>{label}</Typography>
				</div>
				{explanation && <div className={classes.labelRow}>
					<Typography
						color="textSecondary"
						className={classes.explanation}
					>
						{explanation}
					</Typography>
				</div>}
			</div>
			:
			<div>
				<div className={classes.labelRow}>
					<Typography>{label}</Typography>
				</div>
				{explanation && <div className={classes.labelRow}>
					<Typography
						color="textSecondary"
					>
						{explanation}
					</Typography>
				</div>}
			</div>;
	};