import * as React from 'react';
import { FC } from 'react';
import { SchedulerStore } from '../SchedulerStore';
import { SchedulerPhoneNumberInput } from './SchedulerPhoneNumberInput';
import { SchedulerPhoneNumberLabel } from './SchedulerPhoneNumberLabel';

export interface SchedulerPhoneNumberProps
{
	store: SchedulerStore;
	showValidation: boolean;
}

export const SchedulerPhoneNumber: FC<SchedulerPhoneNumberProps> =
	({
		showValidation,
		store,
	}) =>
	{
		return <>
			<SchedulerPhoneNumberLabel />
			<SchedulerPhoneNumberInput
				store={store}
				showValidation={showValidation}
			/>
		</>;
	};
