import { CardContent, FormGroup } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Product } from '../../../../../../Api/Product/Product';
import { ProductConfiguration } from '../../../../../../Api/Product/ProductConfiguration';
import { ProductRecommendationList } from '../../../../../../Api/Product/ProductRecommendationList';
import { useRootContext } from '../../../../../../RootContext';
import { ActionButton } from '../../../../../UI/action-button/ActionButton';
import { BoldTypography } from '../../../../../UI/BoldTypography';
import { EntranceContextRef } from '../../../../Entrance/EntranceContext';
import { BusinessContextRef } from '../../../BusinessContext';
import { ProductStore } from '../../ProductStore';
import { ProductConfigurationStore } from '../ProductConfigurationStore';
import { ShoppingCartLine } from '../ShoppingCartLine';
import { AdditionalShoppingCartLine } from './AdditionalShoppingCartLine';
import { AddRecommendedProductEditor } from './AddRecommendedProductEditor';
import { RecommendedProductConfigurationOption } from './RecommendedProductConfigurationOption';

interface RecommendedProductConfigurationProps
{
	productConfigurationStore: ProductConfigurationStore
	productConfigurationList: ProductRecommendationList
}

export const RecommendedProductConfiguration: FC<RecommendedProductConfigurationProps> =
	(
		{
			productConfigurationStore,
			productConfigurationList,
		},
	) =>
	{
		const [currentlyEditedProduct, setCurrentlyEditedProduct] = useState<Product>();
		const {
			bridge,
			localizer,
		} = useRootContext(true);
		const {
			profileService,
		} = useContext(EntranceContextRef);
		const {
			businessStore,
			currentPlaceService,
		} = useContext(BusinessContextRef);
		const currentlyEditedProductStore = useObserver(
			() => {
				if (currentlyEditedProduct !== undefined)
				{
					return new ProductStore(
						bridge,
						profileService,
						businessStore,
						businessStore.business,
						currentPlaceService.place!,
						currentlyEditedProduct,
						async () => {},
						businessStore.productCategoryById,
						businessStore.timeScheduleById,
						async () => {},
						() => bridge.navigator.popScreen().then(() => {}),
						false,
						true
					);
				}
			}
		);
		const currentlyEditedProductConfigurationStore = useObserver(
			() => {
				return currentlyEditedProductStore?.configurationStore
			},
		);
		const [selectingNew, setSelectingNew] = useState(true);
		const selectProduct = useCallback(
			(product: Product) => {
				setCurrentlyEditedProduct(product);
				setSelectingNew(false);
			},
			[]
		);
		const onEditorCommit = useCallback(
			(productConfiguration: ProductConfiguration) => {
				productConfigurationStore.addProductConfiguration(
					productConfigurationList,
					productConfiguration
				);
				setCurrentlyEditedProduct(undefined);
			},
			[productConfigurationList, productConfigurationStore]
		)
		useEffect(
			() => {
				if (currentlyEditedProductStore !== undefined
					&& !currentlyEditedProductStore.isConfigurable
					&& currentlyEditedProductConfigurationStore !== undefined
				)
				{
					const validProductConfiguration = currentlyEditedProductConfigurationStore
						.requestValidProductConfiguration();
					if (validProductConfiguration !== undefined)
						onEditorCommit(validProductConfiguration)
				}
			},
			[currentlyEditedProductConfigurationStore, currentlyEditedProductStore, onEditorCommit]
		)
		const alreadyAddedProducts = useObserver(
			() => productConfigurationStore.getProductConfigurations(productConfigurationList)
		);
		const onRemoveAdditionalShoppingCartLine = useCallback(
			(shoppingCartLine: ShoppingCartLine) => productConfigurationStore
				.removeProductConfiguration(shoppingCartLine.productConfiguration),
			[productConfigurationStore]
		);
		return useObserver(() => {
			return <CardContent>
				<BoldTypography
					variant="body2"
				>
					{productConfigurationList.recommendationText}
				</BoldTypography>
				{
					alreadyAddedProducts.length > 0 || currentlyEditedProductConfigurationStore
						?
						<div
							style={{
								display: 'flex',
								marginLeft: -8,
								marginRight: -8,
								flexDirection: 'column',
							}}
						>
							{
								alreadyAddedProducts.length > 0
									? <div
										style={{
											flex: '1 0 auto',
											display: 'flex',
											flexWrap: 'wrap',
										}}
									>
										{
											alreadyAddedProducts.map((shoppingCartLine, key) =>
												<AdditionalShoppingCartLine
													key={key}
													shoppingCartLine={shoppingCartLine}
													onRemove={onRemoveAdditionalShoppingCartLine}
												/>
											)
										}
									</div>
									: null
							}
							{
								currentlyEditedProductConfigurationStore
									? <AddRecommendedProductEditor
										productConfigurationStore={currentlyEditedProductConfigurationStore}
										onCommit={onEditorCommit}
										onCancel={() => setCurrentlyEditedProduct(undefined)}
									/>
									: null
							}
						</div>
						:
						null
				}
				{
					(alreadyAddedProducts.length > 0 && currentlyEditedProduct === undefined && !selectingNew)
						? <ActionButton
							onClick={() => setSelectingNew(true)}
							text={localizer.translate('Generic-Add-Another')}
							style={{
								marginTop: 8,
							}}
						/>
						: null
				}
				{
					((alreadyAddedProducts.length === 0 && currentlyEditedProduct === undefined) || selectingNew)
						? <FormGroup>
							{
								productConfigurationList
									.normalizedProductRecommendations
									.filter(recommendation =>
									{
										const id = recommendation.recommendedProductId;

										if (id === undefined)
											return false;
										else
											return businessStore.productById.has(id);
									})
									.map(
										productRecommendation =>
											<RecommendedProductConfigurationOption
												key={productRecommendation.uuid}
												productRecommendation={productRecommendation}
												onClickAdd={selectProduct}
											/>,
									)
							}
						</FormGroup>
						: null
				}
			</CardContent>;
		})
	};
