import { CardContent, Drawer, Hidden, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { CardBorderRadius } from '../../../../../Util/Theme';
import { drawerWidth, panelGradient } from '../../BusinessConstants';
import { useCurrentOrderService } from '../../BusinessContext';
import { BusinessStore } from '../../BusinessStore';
import { OrderBuilder } from '../OrderBuilder';

interface OrderBuilderSidebarProps
{
    store: BusinessStore
}

export const OrderBuilderSidebar: FC<OrderBuilderSidebarProps> =
    (
        {
            store
        }
    ) =>
    {
        const currentOrderService = useCurrentOrderService(false);
        const theme = useTheme();

        const useLargeMargins = useMediaQuery('(min-height: 600px)');

        const orderProcessing = useObserver(() => currentOrderService?.orderProcessing ?? false);

        return useObserver(() => {
            const {
                entranceStore,
                shouldShowLockedForPaymentTerminalScreen,
                shoppingCartStore,
                orderBuilderStore,
            } = store;

            if (entranceStore.isKioskMode.get() || shouldShowLockedForPaymentTerminalScreen)
                return <></>;

            if (shoppingCartStore === undefined || orderBuilderStore === undefined)
                return <></>;

            return <Hidden
                smDown
            >
                <Drawer
                    variant="persistent"
                    anchor="right"
                    open={
                        orderProcessing
                        ||
                        (
                            !shoppingCartStore.isEmpty
                            && !store.shouldShowNoOpenPlaceSessionScreen
                        )
                    }
                    style={{
                        flexShrink: 1,
                        display:
                            shoppingCartStore.isEmpty && !orderProcessing
                                ?
                                'none'
                                :
                                undefined
                    }}
                    PaperProps={{
                        style:
                            {
                                width: drawerWidth,
                                overflowX: 'hidden',
                                backgroundImage: panelGradient,
                                borderRadius: `${CardBorderRadius}px 0 0 ${CardBorderRadius}px`,
                                zIndex: theme.zIndex.drawer,
                                boxShadow: `-1px 1px 5px 0px rgba(0, 0, 0, 0.05),
                                -1px 2px 2px 0px rgba(0, 0, 0, 0.05),
                                -1px 3px 1px -2px rgba(0, 0, 0, 0.05)`
                            },
                        elevation: 1
                    }}
                    SlideProps={{
                        direction: 'left',
                        unmountOnExit: true,
                    }}
                >
                    <CardContent
                        style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            padding: useLargeMargins ? 15 : 13,
                        }}
                    >
                        <OrderBuilder
                            store={orderBuilderStore}
                            topLeftEdgeRadius={10}
                            bottomLeftEdgeRadius={10}
                            flexGrow
                        />
                    </CardContent>
                </Drawer>
            </Hidden>
        });
    };