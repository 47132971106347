import { MenuStore } from '../MenuStore';
import { shouldShowOnMenuSomeAnnouncementsIn } from './shouldShowOnMenuSomeAnnouncementsIn';
import { shouldShowOnMenuSomeChildCategoriesOf } from './shouldShowOnMenuSomeChildCategoriesOf';
import { shouldShowOnMenuSomeProductsIn } from './shouldShowOnMenuSomeProductsIn';

export function shouldShowCategoryOfStoreOnMenu(menuStore: MenuStore): boolean
{
	return (
		shouldShowOnMenuSomeProductsIn(menuStore) ||
		shouldShowOnMenuSomeChildCategoriesOf(menuStore) ||
		shouldShowOnMenuSomeAnnouncementsIn(menuStore)
	);
}
