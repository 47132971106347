import { action, makeObservable, observable } from 'mobx';
import { AppType } from '../../Constants/AppType';
import { ClientVisibility } from './Model/ClientVisibility';
import { OperatingSystemName } from './Model/OperatingSystemName';

export abstract class Client
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    type: AppType;
    operatingSystem: OperatingSystemName | null | undefined;
    visibility: ClientVisibility | undefined;
    lastSeen: Date | undefined;
    isRunningOnTv: boolean;

    // ------------------------ Constructor -------------------------

    constructor()
    {
        makeObservable(this, {
            type: observable,
            operatingSystem: observable,
            visibility: observable,
            lastSeen: observable,
            isRunningOnTv: observable,
            setOperatingSystem: action.bound,
            setRunningOnTv: action.bound,
        });
    }

    // -------------------------- Computed --------------------------

    abstract get platform(): string

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    setOperatingSystem(os: OperatingSystemName): void
    {
        this.operatingSystem = os;
    }

    setRunningOnTv(isRunningOnTv: boolean):void
    {
        this.isRunningOnTv = isRunningOnTv;
    }

    // ------------------------ Public logic ------------------------

    abstract get canStreamUserMedia(): boolean

    // ----------------------- Private logic ------------------------
}
