// @ts-nocheck
/* eslint-disable */
import * as React from 'react';
import { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';

const useStyles = makeStyles({
    root: ({size}: BadgeProps) => ({
        width: size,
        height: size,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'contain',
    }),
});

export interface BadgeProps
{
    backgroundColor?: string;
    backgroundImageUrl?: string;
    size?: number;
}

export const Badge: FC<BadgeProps> =
    ({
         backgroundColor,
         backgroundImageUrl,
         size = 24,
         children
    }) => {
        const classes = useStyles({size: size});
        return useObserver(() => <div
            className={classes.root}
            style={{
                backgroundImage:
                    backgroundImageUrl
                        ?
                        `url(${backgroundImageUrl})`
                        :
                        undefined,
                backgroundColor: backgroundColor,

            }}
        >
            {children}
        </div>);
    };
