import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { createContext, FC, useContext, useMemo } from 'react';
import { useRootContext } from '../../../RootContext';
import { IllegalStateException } from '../../../Util/Exception/IllegalStateException';
import { BusinessContextRef } from '../../Page/Business/BusinessContext';
import { EntranceContextRef } from '../../Page/Entrance/EntranceContext';
import { SessionStatusBarStore } from './SessionStatusBarStore';

interface Context
{
	sessionStatusBarStore: SessionStatusBarStore | undefined
	isSet: true
}

const ContextRef = createContext({} as Context);

interface SessionBarStatusStoreProviderProps
{
}

export const SessionStatusBarStoreProvider: FC<SessionBarStatusStoreProviderProps> =
	(
		{
			children,
		},
	) =>
	{
		const {businessStore} = useContext(BusinessContextRef);
		const shoppingCartStore = useObserver(() => businessStore.shoppingCartStore);
		const {bridge, brandingService} = useRootContext(true);
		const {entranceStore, orderService} = useContext(EntranceContextRef);
		const productCurrencyCode = useObserver(() => businessStore.business.productCurrencyCode);
		const openOrderBuilder = useObserver(() => businessStore.openOrderBuilder);
		const openHistory = useObserver(() => businessStore.openHistory);
		const sessionBarStatusStore = useMemo(
			() => {
				if (shoppingCartStore === undefined)
					return undefined;
				return new SessionStatusBarStore(
					bridge,
					brandingService,
					shoppingCartStore,
					entranceStore,
					orderService,
					productCurrencyCode,
					openHistory,
					() => openOrderBuilder(),
				);
			},
			[brandingService, bridge, entranceStore, openHistory, openOrderBuilder, orderService, productCurrencyCode, shoppingCartStore]
		);
		return <ContextRef.Provider
			value={useMemo(() => ({
				sessionStatusBarStore: sessionBarStatusStore,
				isSet: true,
			}), [sessionBarStatusStore])}
		>
			{children}
		</ContextRef.Provider>;
	};

export function useAndRequireSessionStatusBarStore()
{
	const {isSet, sessionStatusBarStore} = useContext(ContextRef);
	return useMemo(
		() => {
			if (!isSet)
				throw new IllegalStateException(
					'Attempting to use useAndRequireSessionBarStatusStore outside SessionStatusBarStoreProvider'
				);
			if (sessionStatusBarStore === undefined)
				throw new IllegalStateException(
					'Attempting to use SessionStatusBarStore before it is defined'
				);
			return sessionStatusBarStore;
		},
		[isSet, sessionStatusBarStore]
	);
}
