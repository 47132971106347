import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { useMemo } from 'react';
import { useRootContext } from '../../RootContext';
import { IllegalStateException } from '../Exception/IllegalStateException';

export function useScreenWidth(): Breakpoint
{
	const {screenWidth} = useRootContext(true);
	return useMemo(
		() => {
			if (screenWidth === undefined)
				throw new IllegalStateException(
					'Attempt to use useScreenWidth before screenWidth is initialized'
				);
			return screenWidth;
		},
		[screenWidth]
	);
}