import { Color } from '../../../../Api/Other/Color';
import { MediaButtonBarTextMode } from './MediaButtonBarTextMode';

export function useEffectiveBackgroundCss(textMode: MediaButtonBarTextMode, backdropColor?: Color): string
{
	return backdropColor?.css ?? (
		textMode === 'dark'
			? 'linear-gradient(0, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.4) 75%, rgba(255,255,255,0) 100%)'
			: 'linear-gradient(0, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.35) 75%, rgba(255,255,255,0) 100%)'
	);
}
