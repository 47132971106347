import { CheckoutSessionSetupResponse } from '@adyen/adyen-web/dist/types/types';
import { useObserver } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { ExternalBill } from '../../../../../../../Api/ExternalBill/ExternalBill';
import { Order } from '../../../../../../../Api/Order/Order';
import { PaymentIssuer } from '../../../../../../../Api/Payment/PaymentIssuer';
import { PaymentMethodDescriptor } from '../../../../../../../Api/Payment/PaymentMethodDescriptor';
import { AdyenCheckoutData } from '../../../../../../../lib/adyen/AdyenCheckoutData';
import { useRootContext } from '../../../../../../../RootContext';
import { placeProductOrder } from '../../../../../../../Service/OrderService/Api/Client/placeProductOrder';
import { getBackendOSValue } from '../../../../../../../Util/Api/getBackendOSValue';
import { IllegalStateException } from '../../../../../../../Util/Exception/IllegalStateException';
import { useCurrentPlaceService } from '../../../../../../current-place-service/CurrentPlaceService';
import { useDialogVisibilityContext } from '../../../../../../UI/dialog/context/visibility/DialogVisibilityContext';
import { usePollIsValidationBoundaryValid } from '../../../../../../UI/Form/Core/ValidationBoundary';
import { useEmailContext } from '../../context/email/EmailContext';
import { useTipContext } from '../../context/tip/TipContextProvider';

export function usePayExternalBill(
	startShowingValidation: () => void,
	externalBill: ExternalBill,
):
	(
		paymentMethod?: PaymentMethodDescriptor,
		paymentIssuer?: PaymentIssuer,
		adyenCheckoutData?: AdyenCheckoutData,
		paymentSessionSetupResponse?: CheckoutSessionSetupResponse,
	) => Promise<Order | undefined>
{
	const {client} = useRootContext(true);
	const currentPlaceService = useCurrentPlaceService();
	const pollIfValid = usePollIsValidationBoundaryValid();
	const {close: closeDialog} = useDialogVisibilityContext();
	const {email} = useEmailContext();
	const {tip} = useTipContext();
	const orderUuid = useMemo(() => uuid(), []);
	const place = useObserver(() => currentPlaceService.place!);
	const orderLongitude = useObserver(() => currentPlaceService.locationService.longitude);
	const orderLatitude = useObserver(() => currentPlaceService.locationService.latitude);

	return useCallback(
		async (
			paymentMethod?: PaymentMethodDescriptor,
			paymentIssuer?: PaymentIssuer,
			adyenCheckoutData?: AdyenCheckoutData,
			paymentSessionSetupResponse?: CheckoutSessionSetupResponse,
		) =>
		{
			startShowingValidation();

			if (paymentMethod === undefined || externalBill.state !== 'Open')
				throw new IllegalStateException();

			if (!pollIfValid())
				return;

			const order =
				await placeProductOrder(
					{
						client_email: email,
						payment_issuer_id: paymentIssuer?.id,
						new_payment_methods: true,
						os: getBackendOSValue(client),
						longitude: orderLongitude,
						latitude: orderLatitude,
					},
					{
						uuid: orderUuid,
						paymentMethodId: paymentMethod.id,
						lines: [],
						placeId: place.id,
						destination_type: 'PLACE',
						tip: tip,
						adyenCheckoutPaymentInformation: adyenCheckoutData?.paymentMethod,
						paymentSession: adyenCheckoutData === undefined
							? undefined
							: paymentSessionSetupResponse,
						externalBillId: externalBill.id,
					},
				);

			closeDialog();

			return order;
		},
		[
			client,
			closeDialog,
			email,
			externalBill,
			orderLatitude,
			orderLongitude,
			orderUuid,
			place.id,
			pollIfValid,
			startShowingValidation,
			tip,
		],
	);
}
