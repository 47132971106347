import * as React from 'react';
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { TimeSchedule } from '../../../Api/Util/time-schedule/TimeSchedule';
import { IllegalStateException } from '../../../Util/Exception/IllegalStateException';
import { constructEntityWithIdRecordReducer } from '../../../Util/reducer/constructEntityWithIdRecordReducer';
import { getClientVisitTimeSchedules } from './getClientVisitTimeSchedules';
import { getManagerTimeSchedules } from './getManagerTimeSchedules';

interface Context
{
	getTimeSchedule: (id: number) => TimeSchedule | undefined;
	timeSchedules?: TimeSchedule[];
}

const ContextRef = createContext<Context>(undefined as never);

type Intent = 'client-visit' | 'manager';

interface TimeSchedulesContextProviderProps
{
	businessId?: number;
	doInitialize?: boolean;
	intent: Intent;
}

export const TimeSchedulesContextProvider: FC<TimeSchedulesContextProviderProps> =
	(
		{
			businessId,
			children,
			doInitialize = true,
			intent,
		},
	) =>
	{
		const [timeScheduleById, timeScheduleDispatch] = useReducer(constructEntityWithIdRecordReducer<TimeSchedule>(), undefined);

		const [didInitialize, setDidInitialize] = useState<number | undefined>();

		const timeSchedules = useMemo(() => {
			if (timeScheduleById === undefined)
				return undefined;
			else
				return Object.values(timeScheduleById);
		}, [timeScheduleById]);

		useEffect(() => {
			return () => timeScheduleDispatch({
				action: 'unload',
			});
		}, []);

		useEffect(() => {
			if (doInitialize && didInitialize !== businessId)
			{
				getFetchingPromise(intent, businessId)
					.then(timeSchedules => {
						timeScheduleDispatch({
							action: 'replace',
							objects: timeSchedules,
						});

						setDidInitialize(businessId);
					});
			}
		}, [businessId, didInitialize, doInitialize, intent]);

		const getTimeSchedule = useCallback((id: number) => timeScheduleById?.[id], [timeScheduleById]);

		const contextValue = useMemo<Context>(
			() => ({getTimeSchedule, timeSchedules}),
			[getTimeSchedule, timeSchedules],
		);

		return <ContextRef.Provider value={contextValue}>
			{children}
		</ContextRef.Provider>;
	};

export function useTimeSchedulesContext(): Context
{
	return useContext(ContextRef);
}

function getFetchingPromise(intent: Intent, businessId?: number): Promise<TimeSchedule[]>
{
	switch (intent)
	{
		case 'manager':
			return getManagerTimeSchedules({'Business-ID': businessId});
		case 'client-visit':
			return getClientVisitTimeSchedules();
		default:
			throw new IllegalStateException(`Unknown intent "${intent}"`);
	}
}