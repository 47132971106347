import { Loader } from '@intentic/ts-foundation';
import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { useWaiter } from '../../../Service/UserService/useWaiter';
import { panelGradient } from '../Business/BusinessConstants';
import { ManagerBusinessAccessDenied } from './Access/ManagerBusinessAccessDenied';
import { ManagerDeviceSettings } from './DeviceSettings/ManagerDeviceSettings';
import { useIsPageAccessibleCallback } from './Hooks/useIsPageAccessibleCallback';
import { ManagerLargeSidebarWidth, ManagerSmallSidebarWidth, ManagerStore, ManagerToolbarHeight } from './ManagerStore';
import { ManagerOrderHandler } from './order-handler/ManagerOrderHandler';
import { ManagerPlaceCodeInfo } from './PlaceCodeInfo/ManagerPlaceCodeInfo';
import { ManagerPlaces } from './Places/ManagerPlaces';
import { ManagerSettings } from './Settings/ManagerSettings';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		bottom: 0,
		display: 'flex',
		flexDirection: 'column',
		position: 'fixed',
		right: 0,
		top: ManagerToolbarHeight,
		background: panelGradient,
		transition: theme.transitions.create(
			'left',
			{
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			},
		),
	},
	rootLarge: {
		left: ManagerSmallSidebarWidth,
	},
	rootSmall: {
		left: ManagerLargeSidebarWidth,
	},
	managerViewTitle: {
		marginLeft: theme.spacing(2),
		marginTop: theme.spacing(1),
	},
}));

interface ManagerViewProps
{
	isSidebarExpanded: boolean
	store: ManagerStore
}

export const ManagerContent: FC<ManagerViewProps> =
	(
		{
			isSidebarExpanded,
			store,
		}
	) =>
	{
		const waiter = useWaiter();
		if (waiter !== undefined)
			return <Inner
				isSidebarExpanded={isSidebarExpanded}
				store={store}
			/>;
		else
			return <Loader />;
	};

interface InnerProps
{
	isSidebarExpanded: boolean
	store: ManagerStore
}

const Inner: FC<InnerProps> =
	(
		{
			isSidebarExpanded,
			store,
		},
	) =>
	{
		const classes = useStyles();
		const isPageAccessible = useIsPageAccessibleCallback();
		const view = useObserver(
			() => {
				if (!isPageAccessible(store.view ?? 'order-handler'))
					return <ManagerBusinessAccessDenied />;

				switch (store.view)
				{
					case 'code-info':
						return <ManagerPlaceCodeInfo />;
					case 'device-settings':
						return <ManagerDeviceSettings />;
					case 'places':
						return <ManagerPlaces />;
					case 'settings':
						return <ManagerSettings />;
					default:
						store.setView('order-handler');
						return <ManagerOrderHandler />;
				}
			},
		);

		const rootClassName = useMemo(
			() => clsx(
				classes.root,
				isSidebarExpanded
					? classes.rootSmall
					: classes.rootLarge
			),
			[classes, isSidebarExpanded]
		);

		return <div className={rootClassName}>
			{view}
		</div>;
	};