import axios from 'axios';
import { Http } from '../Bridge/Http/Http';
import { encodeUrlParams } from './Api/encodeUrlParams';
import { onApiRequestError } from './Api/Handlers/onApiRequestError';
import { onApiResponseReceived } from './Api/Handlers/onApiResponseReceived';
import { getApiUrl } from './Api/Resources/getApiUrl';
import { setDefaultApiHeader } from './Api/setDefaultApiHeader';
import { SerializationProfile } from './Serialization/Serialization';

setDefaultApiHeader('Content-Type', 'application/json;charset=utf-8');
setDefaultApiHeader('OS-ID', 'web');

export function fetchAny<T>(resource: string, params: any): Promise<T>
{
    return axios({
        method: 'get',
        params: encodeUrlParams(params),
        url: getApiUrl(resource),
        timeout: Http.TIMEOUT_IN_MILLISECONDS,
    })
        .then(onApiResponseReceived)
        .then(
            response =>
                Promise.resolve(response.data))
        .catch(onApiRequestError);
}

export function fetch<T>(resource: string, params: any, profile: SerializationProfile<T>): Promise<T>
{
    return axios({
        method: 'get',
        params: encodeUrlParams(params),
        url: getApiUrl(resource),
        timeout: Http.TIMEOUT_IN_MILLISECONDS,
    })
        .then(onApiResponseReceived)
        .then(response => Promise.resolve(profile.deserializeSingular(response.data)))
        .catch(onApiRequestError);
}

export function fetchList<T>(resource: string, params: any, profile: SerializationProfile<T>): Promise<T[]>
{
    return axios({
        method: 'get',
        params: encodeUrlParams(params),
        url: getApiUrl(resource),
        timeout: Http.TIMEOUT_IN_MILLISECONDS,
    })
        .then(onApiResponseReceived)
        .then(
            response =>
                Promise.resolve(profile.deserializeArray(response.data)))
        .catch(onApiRequestError);
}

/**
 * Fetches a file
 *
 * @param resource resource's URL, relative from the host's root path
 * @param params GET parameters
 */
export async function fetchFile(resource: string, params?: Record<string, any>): Promise<void>
{
    const response = await axios({
        method: 'get',
        params: encodeUrlParams(params),
        responseType: 'blob',
        url: getApiUrl(resource),
        timeout: Http.TIMEOUT_IN_MILLISECONDS,
    })
        .then(onApiResponseReceived)
        .catch(onApiRequestError);

    // const filename = response.headers['content-disposition']?.match(/filename="(.+)"/)?.[1] ?? 'file';
    const type = response.headers['content-type'] ?? 'application/octet-stream';
    const blob = new Blob([response.data], { type });
    // const file = new File([blob], filename);
    const blobUrl = window.URL.createObjectURL(blob);

    // const fileReader = new FileReader();
    // fileReader.readAsArrayBuffer(blob);

    // TODO: Fix file names for iOS (or Safari in general?), files always have the name: 'Unknown.<extension>'.
    // isAndroid ? window.open(blobUrl, filename) : downloadFile(window.URL.createObjectURL(blob), filename);
    // downloadFile(fileUrl, filename);
    window.open(blobUrl, '_parent');

    return Promise.resolve();
}

export function postAny(resource: string, params: any = {}, formData?: FormData): Promise<any>
{
    return axios({
        data: formData,
        method: 'post',
        params: encodeUrlParams(params),
        url: getApiUrl(resource),
        timeout: Http.TIMEOUT_IN_MILLISECONDS,
    })
        .then(onApiResponseReceived)
        .then(
            response =>
                Promise.resolve(response.data))
        .catch(onApiRequestError);
}

export function postJson<T>(resource: string, params: any, profile: SerializationProfile<T>, body?: Object): Promise<T>
{
    return axios({
        data: body,
        method: 'post',
        params: encodeUrlParams(params),
        url: getApiUrl(resource),
        timeout: Http.TIMEOUT_IN_MILLISECONDS,
    })
        .then(onApiResponseReceived)
        .then(response => Promise.resolve(profile.deserializeSingular(response.data)))
        .catch(onApiRequestError);
}

export function postJsonAny<T>(resource: string, params: any, body?: Object): Promise<T>
{
    return axios({
        data: body,
        method: 'post',
        params: encodeUrlParams(params),
        url: getApiUrl(resource),
        timeout: Http.TIMEOUT_IN_MILLISECONDS,
    })
        .then(onApiResponseReceived)
        .then(response => response.data)
        .catch(onApiRequestError);
}

export function httpDelete(resource: string, params: any): Promise<any>
{
    return axios({
        method: 'delete',
        params: encodeUrlParams(params),
        url: getApiUrl(resource),
        timeout: Http.TIMEOUT_IN_MILLISECONDS,
    })
        .then(onApiResponseReceived)
        .then(response => response.data)
        .catch(onApiRequestError);
}

export function post<T>(resource: string, params: any, profile: SerializationProfile<T>, formData?: FormData): Promise<T>
{
    return axios({
        data: formData,
        method: 'post',
        params: encodeUrlParams(params),
        url: getApiUrl(resource),
        timeout: Http.TIMEOUT_IN_MILLISECONDS,
    })
        .then(onApiResponseReceived)
        .then(response => Promise.resolve(profile.deserializeSingular(response.data)))
        .catch(onApiRequestError);
}

export function postAndFetchList<T>(resource: string, params: any, profile: SerializationProfile<T>, body?: any): Promise<T[]>
{
    return axios({
        data: body,
        method: 'post',
        params: encodeUrlParams(params),
        url: getApiUrl(resource),
        timeout: Http.TIMEOUT_IN_MILLISECONDS,
    })
        .then(onApiResponseReceived)
        .then(response => Promise.resolve(profile.deserializeArray(response.data)))
        .catch(onApiRequestError);
}

/**
 * Posts and fetches a plain Javascript list of objects from an API resource
 *
 * @param resource the resource to get objects from
 * @param params optional HTTP request parameters
 * @param profile used {@link SerializationProfile}
 * @param formData {@link FormData} to post
 * @return list of requested objects
 */
export async function postAndFetchPlainList<T>(resource: string, params: any, profile: SerializationProfile<T>, formData?: FormData): Promise<T[]>
{
    const response = await axios({
        data: formData,
        method: 'post',
        params: encodeUrlParams(params),
        url: getApiUrl(resource),
        timeout: Http.TIMEOUT_IN_MILLISECONDS,
    })
        .then(onApiResponseReceived)
        .catch(onApiRequestError);

    return profile.deserializeJSArray(response.data);
}

/**
 * Gets a plain Javascript list of objects from an API resource
 *
 * @param resource the resource to get objects from
 * @param params optional HTTP request parameters
 * @param profile used {@link SerializationProfile}
 * @return list of requested objects
 */
export async function getPlainList<T>(resource: string, params: any, profile: SerializationProfile<T>): Promise<T[]>
{
    const response = await axios.get(
        getApiUrl(resource),
        {
            params: encodeUrlParams(params),
            timeout: Http.TIMEOUT_IN_MILLISECONDS,
        }
    )
        .then(onApiResponseReceived)
        .catch(onApiRequestError);

    return profile.deserializeJSArray(response.data);
}

/**
 * Gets a plain Javascript list of objects from an API resource
 *
 * @param resource the resource to get objects from
 * @param headers optional HTTP headers
 * @param params optional HTTP request parameters
 * @param profile used {@link SerializationProfile}
 * @return list of requested objects
 */
export async function getPlainListWithHeaders<T>(
    resource: string,
    headers: Record<string, number | string | undefined> | undefined,
    params: Record<string, any> | undefined,
    profile: SerializationProfile<T>,
): Promise<T[]>
{
    const response = await axios.get(
        getApiUrl(resource),
        {
            headers: headers,
            params: encodeUrlParams(params),
            timeout: Http.TIMEOUT_IN_MILLISECONDS,
        },
    )
        .then(onApiResponseReceived)
        .catch(onApiRequestError);

    return profile.deserializeJSArray(response.data);
}