import { makeStyles, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { CSSProperties, FC, useMemo } from 'react';
import { useTranslate } from '../../../../Bridge/Localization/useTranslate';
import { HorizontalAlignment } from '../HorizontalAlignment';

interface StyleProps
{
	marginLeft: string | number | undefined
	marginRight: string | number | undefined
	flexStretch: boolean
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
	text: ({marginLeft, marginRight, flexStretch}) => ({
		marginLeft: marginLeft,
		marginRight,
		flex: flexStretch ? '1 1 auto' : undefined,
		transition: theme.transitions.create('color', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	}),
}));

interface ButtonTextProps
{
	text: string | undefined
	align: HorizontalAlignment
	moreThanZeroFailedAttempts: boolean
	style: CSSProperties | undefined
	color: string
	marginLeft: string | number | undefined
	marginRight: string | number | undefined
	flexStretch: boolean
}

export const ButtonText: FC<ButtonTextProps> =
	(
		{
			text,
			moreThanZeroFailedAttempts,
			style,
			color,
			marginLeft,
			marginRight,
			flexStretch,
		},
	) =>
	{
		const classes = useStyles({marginLeft, marginRight, flexStretch});
		const translate = useTranslate();
		const effectiveText = useMemo(
			() => moreThanZeroFailedAttempts ? translate('Generic-Retry') : text,
			[moreThanZeroFailedAttempts, translate, text]
		);
		if (effectiveText === undefined)
			return null;
		return <Typography
			className={classes.text}
			style={{
				color,
				...style,
			}}
			variant="body2"
		>
			{effectiveText}
		</Typography>;
	};