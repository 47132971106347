import { postAny } from '../../../../../Util/Api';

export function postUnassignPlaceCode(params: PostUnassignPlaceCodeParams): Promise<PostUnassignPlaceCodeResponse>
{
	return postAny('/owner/business/place/code/unassign', params);
}

type PostUnassignPlaceCodeResponse = 'false_not_authorized' | 'false_non_existent' | 'true'

interface PostUnassignPlaceCodeParams
{
	place_code_id: number
}