import { makeAutoObservable } from 'mobx';
import { SerializationProfile } from '../../../Util/Serialization/Serialization';
import { CartLineVariant, CartLineVariantProfile } from './CartLineVariant';

export class CartLine
{
    // ------------------------- Properties -------------------------

    id: number;
    productId: number;
    variants: CartLineVariant[];
    quantity: number;

    // ------------------------ Constructor -------------------------

    constructor(
        id: number,
        productId: number,
        variants: CartLineVariant[],
        quantity: number
    )
    {
        this.id = id;
        this.productId = productId;
        this.variants = variants;
        this.quantity = quantity;

        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}

export const CartLineProfile =
    SerializationProfile.create(CartLine)
        .profile('variants', CartLineVariantProfile);
