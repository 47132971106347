export async function getPushNotificationPermission(): Promise<boolean>
{
    if ('Notification' in window)
    {
        const response = await Notification.requestPermission();

        if (response === 'granted')
        {
            return Promise.resolve(true);
        }
        else
        {
            return Promise.resolve(false);
        }
    }
    else
    {
        return Promise.resolve(false);
    }
}
