import { makeAutoObservable } from 'mobx';
import { WebClient } from '../../Bridge/Client/WebClient';

export class LocationService
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    readonly client: WebClient;
    readonly onPosition: (position: GeolocationPosition) => void;
    interval: any;
    position: GeolocationPosition | undefined;

    // ------------------------ Constructor -------------------------

    constructor(
        client: WebClient,
        onPosition: (position: GeolocationPosition) => void = () => {},
        autoStart: boolean
    )
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });

        this.client = client;
        this.onPosition = onPosition;

        this.interval = null;

        if (autoStart)
        {
            this.start();
        }
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    get isRunning(): boolean
    {
        return this.interval != null;
    }

    get longitude(): number | undefined
    {
        return this.position && this.position.coords && this.position.coords.longitude;
    }

    get latitude(): number | undefined
    {
        return this.position && this.position.coords && this.position.coords.latitude;
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    start()
    {
        if (!this.isRunning)
        {
            this.checkLocation();

            this.interval =
                setInterval(
                    this.checkLocation,
                    1000 * 60 * 15);
        }
    }

    stop()
    {
        if (this.isRunning)
        {
            clearTimeout(this.interval);
            this.interval = null;
        }
    }

    checkLocation()
    {
        if (navigator
            && navigator.geolocation
            && this.client.visibility === 'visible')
        {
            navigator.geolocation
                .getCurrentPosition(
                    position => {
                        this.onPosition(position);
                        this.setPosition(position);
                    },
                    this.stop
                );
        }
    }

    setPosition(position: GeolocationPosition): void
    {
        this.position = position;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
