import { makeAutoObservable } from 'mobx';
import { SerializationProfile } from '../../Util/Serialization/Serialization';
import { NutritionFlag } from './NutritionFlag';

export const Allergens =
    [ 'cel', 'cer', 'cru', 'egg', 'fis', 'lup', 'mil', 'mol', 'mus', 'nut', 'pea', 'ses', 'soy', 'sul'  ];

export class NutritionInformation
{
    // ------------------------- Properties -------------------------

    isVegetarian: boolean | undefined;
    isVegan: boolean | undefined;
    containsAlcohol: boolean | undefined;
    isBiological: boolean | undefined;
    isKosher: boolean | undefined;
    isHalal: boolean | undefined;
    isPregnancyFriendly: boolean | undefined;
    alcoholPercentage: number;
    energy: number;
    cholesterol: number;
    sodium: number;
    fat: number;
    saturatedFat: number;
    carbohydrates: number;
    fibre: number;
    sugar: number;
    protein: number;
    salt: number;

    // ------------------------ Constructor -------------------------

    constructor()
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    get nutritionTableRows(): {[key: string]: boolean | string | number}
    {
        return {
            alcoholPercentage: this.alcoholPercentage,
            energy: this.energy,
            cholesterol: this.cholesterol,
            sodium: this.sodium,
            fat: this.fat,
            saturatedFat: this.saturatedFat,
            carbohydrates: this.carbohydrates,
            fibre: this.fibre,
            sugar: this.sugar,
            protein: this.protein,
            salt: this.salt,
        };
    }

    get specifiedNutritionFlags(): NutritionFlag[]
    {
        const flags: NutritionFlag[] = [];

        if (this.isVegetarian !== undefined)
        {
            flags.push('IsVegetarian');
        }

        if (this.isVegan !== undefined)
        {
            flags.push('IsVegan');
        }

        if (this.containsAlcohol !== undefined)
        {
            flags.push('ContainsAlcohol');
        }

        if (this.isBiological !== undefined)
        {
            flags.push('IsBiological');
        }

        if (this.isKosher !== undefined)
        {
            flags.push('IsKosher');
        }

        if (this.isHalal !== undefined)
        {
            flags.push('IsHalal');
        }

        if (this.isPregnancyFriendly !== undefined)
        {
            flags.push('IsPregnancyFriendly');
        }

        return flags;
    }

    get nutritionFlags(): NutritionFlag[]
    {
        const flags: NutritionFlag[] = [];

        if (this.isVegetarian)
        {
            flags.push('IsVegetarian');
        }

        if (this.isVegan)
        {
            flags.push('IsVegan');
        }

        if (this.containsAlcohol)
        {
            flags.push('ContainsAlcohol');
        }

        if (this.isBiological)
        {
            flags.push('IsBiological');
        }

        if (this.isKosher)
        {
            flags.push('IsKosher');
        }

        if (this.isHalal)
        {
            flags.push('IsHalal');
        }

        if (this.isPregnancyFriendly)
        {
            flags.push('IsPregnancyFriendly');
        }

        return flags;
    }

    /**
     * Look up value of a {@link NutritionFlag}.
     * @param nutritionFlag
     */
    public holds(nutritionFlag: NutritionFlag): boolean | undefined
    {
        switch (nutritionFlag)
        {
            case 'IsBiological':
                return this.isBiological === true;
            case 'IsHalal':
                return this.isHalal === true;
            case 'IsKosher':
                return this.isKosher === true;
            case 'IsPregnancyFriendly':
                return this.isPregnancyFriendly === true;
            case 'ContainsAlcohol':
                return this.containsAlcohol === true;
            case 'IsVegan':
                return this.isVegan === true;
            case 'IsVegetarian':
                return this.isVegetarian === true;
        }
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getUnit(key: keyof NutritionInformation): string
    {
        switch (key)
        {
            case 'alcoholPercentage':
                return '%';

            case 'carbohydrates':
            case 'cholesterol':
            case 'fat':
            case 'fibre':
            case 'protein':
            case 'salt':
            case 'saturatedFat':
            case 'sodium':
            case 'sugar':
                return ' g';

            case 'energy':
                return 'kcal';
        }

        return '';
    }

    // ----------------------- Private logic ------------------------
}

export const NutritionInformationProfile =
    SerializationProfile.create(NutritionInformation);
