// @ts-nocheck
/* eslint-disable */
import * as React from 'react';
import { FC, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Color } from '../../../Api/Other/Color';

const useStyle = makeStyles((theme: Theme) => ({
	root: {
		bottom: 0,
		pointerEvents: 'none',
		position: 'absolute',
		width: '100%',
		zIndex: theme.zIndex.tooltip + 1,
	},
}));

interface BottomScrollIndicator
{
	color?: Color;
	elementRef?: RefObject<HTMLDivElement>;
	height?: number;
	updateOnWindowResize?: boolean;
}

export const BottomScrollIndicator: FC<BottomScrollIndicator> =
	(
		{
			color = new Color(0, 0, 0),
			elementRef,
			height = 30,
			updateOnWindowResize,
		},
	) =>
	{
		const classes = useStyle();

		const rootRef = useRef<HTMLDivElement>(null);

		const [distance, setDistance] = useState(0);

		const updateDistance = useCallback(
			(target: HTMLElement) =>
				setDistance(
					target.scrollTop > target.scrollHeight - target.clientHeight
						? 0
						: Math.min(Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight), height),
				),
			[height],
		);

		const onScroll = useCallback(
			event => updateDistance(event.target),
			[updateDistance],
		);

		useEffect(
			() =>
			{
				const targetElement = elementRef?.current ?? rootRef.current?.parentElement;
				const parentElement = elementRef?.current ? elementRef.current?.parentElement : rootRef.current?.parentElement;

				const refreshDistance = () => {
					updateDistance(targetElement);
				};

				if (parentElement && targetElement)
				{
					updateDistance(targetElement); // Run immediately.
					setTimeout(refreshDistance, 50); // Run when scrolleable content reaches final length. Todo: find right event


					parentElement.addEventListener('DOMSubtreeModified', refreshDistance);
					targetElement.addEventListener('scroll', onScroll);

					updateOnWindowResize && window.addEventListener('resize', refreshDistance);
				}
				else if (targetElement)
				{
					console.warn('No parent element found for scroll indicator.');
				}
				else
				{
					console.warn('No target element found for scroll indicator.');
				}

				return () => {
					if (parentElement && targetElement)
					{
						parentElement.removeEventListener('DOMSubtreeModified', refreshDistance);
						targetElement.removeEventListener('scroll', onScroll);

						updateOnWindowResize && window.removeEventListener('resize', refreshDistance);
					}
				};
			},
			[elementRef, onScroll, updateDistance, updateOnWindowResize],
		);

		const colorSpec = useMemo(
			() => `rgba(${color.r},${color.g},${color.b},0.07)`,
			[color]
		);
		const blur = useMemo(() => Math.floor(height * 0.4), [height]);
		const disappearHeight = 5;
		const spread = Math.round((distance > 0 ? Math.min(distance, disappearHeight) / disappearHeight * height : 0) - (1.5 * height));
		const shadowSpec = `inset 0px -${height}px ${blur}px ${spread}px ${colorSpec}`;
		return <div
			className={classes.root}
			ref={rootRef}
			style={{
				MozBoxShadow: shadowSpec,
				WebkitBoxShadow: shadowSpec,
				boxShadow: shadowSpec,
				height: height,
			}}
		/>;
	};