import { Slide, Theme, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from '../../../../Bridge/Localization/useTranslation';
import { UIColors } from '../../../../Constants/UIColors';
import { PositiveActionButton } from '../../../UI/action-button/type/PositiveActionButton';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		backgroundColor: UIColors.greyLight,
		borderRadius: theme.shape.borderRadius,
		bottom: 80,
		display: 'flex',
		justifyContent: 'space-between',
		padding: theme.spacing(2),
		position: 'absolute',

		[theme.breakpoints.down('xs')]:
			{
				flexDirection: 'column',
			},

		[theme.breakpoints.up('sm')]:
			{
				alignItems: 'center',
				borderRadius: theme.shape.borderRadius,
				width: 552,
			},
	},
	text: {

		[theme.breakpoints.down('xs')]:
			{
				marginBottom: theme.spacing(2),
			},
	},
}));

interface ManagerPlaceCodeInfoUnassignedCodeContentProps
{
	hash: string;
	onStartAssigningPlaceCode: () => void;
	show: boolean;
}

export const ManagerPlaceCodeInfoUnassignedCodeContent: FC<ManagerPlaceCodeInfoUnassignedCodeContentProps> =
	(
		{
			hash,
			onStartAssigningPlaceCode,
			show,
		},
	) =>
	{
		const classes = useStyles();

		return <Slide
			direction="up"
			in={show}
			unmountOnExit
		>
			<div
				className={classes.root}
			>
				<Typography
					classes={{
						root: classes.text,
					}}
				>
					{useTranslation('Place-Code-Scanned', hash)}
				</Typography>
				<PositiveActionButton
					icon={PlayArrowIcon}
					onClick={onStartAssigningPlaceCode}
					text={useTranslation('Place-Code-Assign')}
				/>
			</div>
		</Slide>;
	};
