import { Box } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext } from 'react';
import { ProductFeatureVariant } from '../../../../../../../Api/Product/ProductFeatureVariant';
import { useIsKioskMode } from '../../../../../../../Service/KioskService/Api/useIsKioskMode';
import { useIsSmallScreen } from '../../../../../../../Util/Hooks/useIsSmallScreen';
import { MediaButtonV2 } from '../../../../../../UI/media_button_v2/MediaButtonV2';
import { SwiperControlDataItem } from '../../../../../../UI/SwiperControl/Model/SwiperControlDataItem';
import { SwiperControl } from '../../../../../../UI/SwiperControl/SwiperControl';
import { EntranceContextRef } from '../../../../../Entrance/EntranceContext';
import { useNumberOfFullscreenMenuGridColumns } from '../../../../Menu/Grid/Api/useNumberOfFullscreenMenuGridColumns';
import { FeatureConfigurationStore } from '../FeatureConfigurationStore';
import { FeatureConfigurationVariantPrice } from '../VariantPrice/FeatureConfigurationVariantPrice';

interface FeatureConfigurationWithImagesProps
{
    store: FeatureConfigurationStore;
}

export const FeatureConfigurationWithImages: FC<FeatureConfigurationWithImagesProps> =
    (
        {
            store,
        }
    ) =>
    {
        const {brandingService} = useContext(EntranceContextRef);
        const numberOfVariantColumns = useNumberOfFullscreenMenuGridColumns();
        const isSmallScreen = useIsSmallScreen();
        const isKioskMode = useIsKioskMode();
        const items =
            useObserver<SwiperControlDataItem<ProductFeatureVariant>[]>(
                () =>
                    store.productFeature.variants.map(
                        variant => ({
                            id: variant.uuid,
                            getNode:
                                variant =>
                                    <MediaButtonV2
                                        image={variant.imageUrl}
                                        imageFit={
                                            variant.imageDoContain
                                                ? 'Contain'
                                                : 'Cover'
                                        }
                                        imageBackgroundColor={variant.imageBackgroundColor}
                                        isTextContrastColorDark={variant.imageIsTextContrastColorDark}
                                        textBackdropColor={
                                            store.selectedVariants.has(variant)
                                                ? brandingService.tintColor
                                                : variant.imageBackdropColor
                                        }
                                        textColor={
                                            store.selectedVariants.has(variant)
                                                ? brandingService.tintContrastTextColor
                                                : variant.imageTextColor
                                        }
                                        title={variant.name}
                                        secondaryTitle={
                                            <FeatureConfigurationVariantPrice
                                                store={store}
                                                variant={variant}
                                            />
                                        }
                                        primaryBadges={
                                            store.selectedVariants.has(variant)
                                                ? [
                                                    <CheckCircle
                                                        htmlColor={
                                                            variant.imageUrl === undefined
                                                                ? brandingService.tintContrastTextColor.css
                                                                : brandingService.tintColor.css
                                                        }
                                                    />
                                                ]
                                                : []
                                        }
                                    />,
                            getStyle:
                                variant => ({
                                    outline:
                                        store.selectedVariants.has(variant)
                                            ? `${isSmallScreen ? 2 : 3}px solid ${brandingService.tintColor.css}`
                                            : undefined,
                                }),
                            value: variant,
                        })
                    )
            );

        return <Box
            paddingTop={2}
        >
            <SwiperControl
                numberOfColumns={numberOfVariantColumns}
                items={items}
                onClick={variant => store.toggleSelection(variant)}
                tintColor={brandingService.tintColor}
                overflow
                centerInsufficientSlides={isKioskMode}
            />
        </Box>;
    };
