import * as React from 'react';
import { createContext, FC, useContext, useMemo, useState } from 'react';
import { WebLocalizer } from '../../Bridge/Localization/WebLocalizer';
import { useAsyncEffect } from '../../Util/async/useAsyncEffect';
import { IllegalStateException } from '../../Util/Exception/IllegalStateException';

interface Context
{
	localizer: WebLocalizer
}

const ContextRef = createContext<Context | undefined>(undefined);

export const LocalizerProvider: FC =
	(
		{
			children,
		},
	) =>
	{
		const [localizer] = useState(new WebLocalizer());
		const [didInitialize, setDidInitialize] = useState(false);

		useAsyncEffect(
			() => ({
				promise: localizer.initialize(),
				then: () => setDidInitialize(true),
			}),
			[localizer],
		);

		const contextValue = useMemo(
			() =>
				({
					localizer,
				}),
			[localizer],
		);

		if (didInitialize)
		{
			return <ContextRef.Provider
				value={contextValue}
			>
				{children}
			</ContextRef.Provider>;
		}
		else
		{
			return null;
		}
	};

export function useWebLocalizer(): WebLocalizer
{
	const context = useContext(ContextRef);
	return useMemo(
		() => {
			if (context === undefined)
				throw new IllegalStateException(
					'Attempting to use useWebLocalizer outside LocalizerProvider'
				);
			return context.localizer;
		},
		[context],
	);
}
