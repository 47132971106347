// @ts-nocheck
/* eslint-disable */
import * as React from 'react';
import { FC } from 'react';
import { NutritionFlag } from '../../../Api/Product/NutritionFlag';
import IsAlcoholicSvg from '../Business/Product/Allergen/Resource/IsAlcoholic.svg';
import IsHalalSvg from '../Business/Product/Allergen/Resource/IsHalal.svg';
import IsKosherSvg from '../Business/Product/Allergen/Resource/IsKosher.svg';
import IsOrganicSvg from '../Business/Product/Allergen/Resource/isOrganic.svg';
import IsPregnancyFriendlySvg from '../Business/Product/Allergen/Resource/IsPregnancyFriendly.svg';
import IsVeganSvg from '../Business/Product/Allergen/Resource/IsVegan.svg';
import IsVegetarianSvg from '../Business/Product/Allergen/Resource/IsVegetarian.svg';

const nutritionIcons = require.context('../Business/Product/Allergen/Resource', false);

export function nutritionIcon(
	nutritionFlag: NutritionFlag,
	size: number = 40,
	onClick: () => void = () => {},
)
{
	switch (nutritionFlag)
	{
		case 'IsHalal':
			// return <IsHalalSvg
			//     width={size}
			//     height={size}
			//     viewBox="0 0 24 24"
			//     onClick={onClick}
			// />;
			return <img
				src={IsHalalSvg}
				width={size}
				height={size}
				onClick={onClick}
			/>;
		case 'IsKosher':
			return <img
				src={IsKosherSvg}
				width={size}
				height={size}
				onClick={onClick}
			/>;
		case 'IsPregnancyFriendly':
			return <img
				src={IsPregnancyFriendlySvg}
				width={size}
				height={size}
				onClick={onClick}
			/>;
		case 'IsOrganic':
			return <img
				src={IsOrganicSvg}
				width={size}
				height={size}
				onClick={onClick}
			/>;
		case 'IsVegan':
			return <img
				src={IsVeganSvg}
				width={size}
				height={size}
				onClick={onClick}
			/>;
		case 'IsVegetarian':
			return <img
				src={IsVegetarianSvg}
				width={size}
				height={size}
				onClick={onClick}
			/>;
		case 'ContainsAlcohol':
			return <img
				src={IsAlcoholicSvg}
				width={size}
				height={size}
				onClick={onClick}
			/>;
	}
	return nutritionIcons(`./${nutritionFlag}.svg`);
}

interface NutritionFlagBadgeProps
{
	flag: NutritionFlag;
	size?: number;
	onClick?: () => void;
}

export const NutritionFlagBadge: FC<NutritionFlagBadgeProps> =
	({
		flag,
		size = 40,
		onClick = () => {}
	}: NutritionFlagBadgeProps) => {
		return <div
			style={{
				display: 'flex',
				width: size,
				height: size,
			}}
		>
			{nutritionIcon(flag, size, onClick)}
			<div
				style={{
					display: 'none',
				}}
			>
				{flag}
			</div>
		</div>
	};