import { HttpRequestException } from './HttpRequestException';

export class AbortedHttpRequestException extends HttpRequestException
{
    // ------------------------- Properties -------------------------

	readonly name = 'AbortedHttpRequestException';

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
