import { SerializationProfile } from '../../Util/Serialization/Serialization';
import { Money, MoneyProfile } from '../Other/Money';
import { ExternalOrderLine, ExternalOrderLineProfile } from './ExternalOrderLine';

export class ExternalOrder
{
	readonly uuid: string;
	readonly paymentPrice: Money;
	readonly routingId: number;
	readonly posId: string;
	readonly orderLines: ExternalOrderLine[];
}

export const ExternalOrderProfile = SerializationProfile.create(ExternalOrder)
	.profile('paymentPrice', MoneyProfile)
	.profile('orderLines', ExternalOrderLineProfile);