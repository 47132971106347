import { Box, Typography } from '@material-ui/core';
import Decimal from 'decimal.js';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useLocalizer } from '../../../../../../../../../Bridge/Localization/useLocalizer';
import { useTranslate } from '../../../../../../../../../Bridge/Localization/useTranslate';
import { postJson } from '../../../../../../../../../Util/Api';
import { serializePromises } from '../../../../../../../../../Util/promise/serializePromises';
import { SerializationProfile } from '../../../../../../../../../Util/Serialization/Serialization';
import { ActionButton } from '../../../../../../../../UI/action-button/ActionButton';
import PriceTag from '../../../../../../../../UI/PriceTag/PriceTag';
import { BusinessContextRef } from '../../../../../../BusinessContext';
import { ClaimPeerCartLineRequest } from '../../../model/ClaimPeerCartLineRequest';
import { InitializedCartLine } from '../../../model/InitializedCartLine';

interface PeerShoppingCartAddLinesToOrderButtonProps
{
	selectedLines: InitializedCartLine[];
}

export const PeerShoppingCartAddLinesToOrderButton: FC<PeerShoppingCartAddLinesToOrderButtonProps> =
	({
		selectedLines,
	}) =>
	{
		const translate = useTranslate();
		const {businessStore} = useContext(BusinessContextRef);
		const localizer = useLocalizer();
		const ownCartId =
			useObserver(
				() =>
					businessStore
						.shoppingCartStore
						.currentOrderService
						.ownCartId
			);
		const addLinesToOrder =
			useCallback(
				() =>
					serializePromises(
						selectedLines.map(
							line =>
								() =>
									postJson(
										'/client/business/cart/claimPeerCartLine',
										undefined,
										SerializationProfile.create(Boolean),
										{
											claimLineId: line.id,
											targetCartId: ownCartId,
										} as ClaimPeerCartLineRequest
									)
						)
					),
				[
					ownCartId,
					selectedLines,
				]
			);
		const totalSelectedPrice =
			useMemo(
				() =>
					new Decimal(
						selectedLines.reduce(
							(a, b) =>
								a.plus(b.totalPrice),
							new Decimal(0)
						)
					),
				[selectedLines]
			);

		return <>
			<Box
				paddingBottom={2}
			>
				<Typography
					variant="body1"
					color="textSecondary"
				>
					{translate('Client-PeerShoppingCart-AddToOrder-Justification')}
				</Typography>
			</Box>
			<ActionButton
				text={
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							width: '100%',
						}}
					>
						<Box
							sx={{
								flex: 1,
								textAlign: 'left',
							}}
						>
							{translate('Client-PeerShoppingCart-AddToOrder')}
						</Box>
						<PriceTag
							price={totalSelectedPrice}
							localizer={localizer}
							currencyCode={businessStore.business.productCurrencyCode}
							style={{
								display: 'inline-block',
								fontWeight: 500,
							}}
							color="inherit"
							component="span"
						/>
					</Box>
				}
				textAlign="left"
				size="large"
				fullWidth
				onClick={addLinesToOrder}
			/>
		</>;
	};