import { ImageInput } from '@intentic/qr-reader-web';
import { Typography } from '@material-ui/core';
import { observable } from 'mobx';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useEffect, useMemo } from 'react';
import { useTranslate } from '../../../Bridge/Localization/useTranslate';
import { useRootContext } from '../../../RootContext';
import { AbortedHttpRequestException } from '../../../Util/Exception/AbortedHttpRequestException';
import { FailedHttpRequestException } from '../../../Util/Exception/FailedHttpRequestException';
import { HttpRequestException } from '../../../Util/Exception/HttpRequestException';
import { HttpRequestTimeoutException } from '../../../Util/Exception/HttpRequestTimeoutException';
import { Page } from '../../UI/Page';
import { PageContent } from '../../UI/PageContent';
import { ErrorPage } from '../Exception/ErrorPage';
import { EntranceStore } from './EntranceStore';
import { EntranceTopBar } from './EntranceTopBar';
import { ScannerButton } from './ScannerButton/ScannerButton';

const pwaInstallationPrompt = observable.box<any>(null);

window.addEventListener(
    'beforeinstallprompt',
    event =>
    {
        event.preventDefault();
        pwaInstallationPrompt.set(event);
    },
);

interface EntranceProps
{
    store: EntranceStore;
}

export const Entrance: FC<EntranceProps> =
    (
        {
            store
        }
    ) =>
    {
        const {initializationError} = useRootContext(true);
        const translate = useTranslate();

        const navigateToLockedPlaceIfPresent = useObserver(() => store.navigateToLockedPlaceIfPresent);

        useEffect(() => {
            navigateToLockedPlaceIfPresent().then();
        }, [navigateToLockedPlaceIfPresent]);

        const topBar =
            useObserver(
                () =>
                    !store.isTopBarHidden.get() &&
                    <EntranceTopBar
                        onIconClick={() => store.isMenuOpen.set(true)}
                    />
            );

        const {
            errorPageTitle,
            errorPageMessage
        } = useMemo(
            () =>
            {
                if (initializationError)
                {
                    switch (initializationError.constructor)
                    {
                        case AbortedHttpRequestException:
                        case FailedHttpRequestException:
                            return {
                                errorPageTitle: translate('Exception-Title-Network-Error'),
                                errorPageMessage: translate('Exception-Message-Api-Unreachable'),
                            };
                        case HttpRequestTimeoutException:
                            return {
                                errorPageTitle:  translate('Exception-Title-Server-Error'),
                                errorPageMessage: translate('Exception-Message-Api-Timeout'),
                            };
                        default:
                            return {
                                errorPageTitle:  translate('Exception-Title-Server-Error'),
                                errorPageMessage: translate('Exception-Message-Api-Generic'),
                            };
                    }
                }
                else
                {
                    return {};
                }
            },
            [initializationError, translate]
        );

        const page = useObserver(
            () =>
                <Page
                    backgroundImageUrl={store.brandingService.backgroundImageUrl}
                    backgroundImageBlur={store.brandingService.backgroundImageBlur}
                    backgroundOverlayColor={store.brandingService.backgroundColor.css}
                    backgroundColor={store.brandingService.averageColorOfBackground?.css}
                    style={{
                        flex: '1 0 auto',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    {topBar}
                    {/*<video*/}
                    {/*autoPlay*/}
                    {/*loop*/}
                    {/*muted*/}
                    {/*style={{*/}
                    {/*position: 'fixed',*/}
                    {/*right: 0,*/}
                    {/*bottom: 0,*/}
                    {/*minWidth: '100%',*/}
                    {/*minHeight: '100%'*/}
                    {/*}}*/}
                    {/*>*/}
                    {/*<source*/}
                    {/*src={backgroundVideo}*/}
                    {/*type="video/mp4"*/}
                    {/*/>*/}
                    {/*<source*/}
                    {/*src="http://inserthtml.com/demos/javascript/background-videos/flowers.webm"*/}
                    {/*type="video/webm"*/}
                    {/*/>*/}
                    {/*</video>*/}
                    <PageContent
                        xs
                        style={{
                            height: '100%'
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                left: 30,
                                right: 30,
                                top: '30%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                height: 100,
                            }}
                        >
                            <div
                                id="entrance-logo"
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    backgroundImage: `url(${store.brandingService.logoImageUrl})`,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center'
                                }}
                            />
                            {
                                store.brandingService.logoSubtitle !== undefined
                                && store.brandingService.logoSubtitle.trim().length > 0 &&
                                <Typography
                                    style={{
                                        color: store.brandingService.backgroundContrastTextColor.css,
                                    }}
                                >
                                    {store.brandingService.logoSubtitle}
                                </Typography>
                            }
                        </div>

                        <div
                            style={{
                                position: 'absolute',
                                left: 20,
                                right: 20,
                                bottom: 50,
                                minWidth: 'min-content',
                                maxWidth: 'max-content',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                        >
                            {
                                store.scannerStore.placeCodeScannerStore.type === 'photo'
                                    ?
                                    <ImageInput
                                        onChange={store.parseImage}
                                    >
                                        <ScannerButton
                                            mode="photo"
                                            onStartScanning={store.startScanning}
                                        />
                                    </ImageInput>
                                    :
                                    <ScannerButton
                                        mode="stream"
                                        onStartScanning={store.startScanning}
                                        pointerEvents
                                    />
                            }
                        </div>
                    </PageContent>
                </Page>
        );

        if (initializationError instanceof HttpRequestException)
        {
            return <ErrorPage
                exceptionId={initializationError.id}
                message={errorPageMessage ?? ''}
                title={errorPageTitle ?? ''}
            />;
        }
        else
        {
            return <>
                {page}
            </>;
        }
};
