import * as React from 'react';
import { createContext, Dispatch, FC, SetStateAction, useContext, useMemo, useState } from 'react';
import { OrderFormFieldSupport, Place } from '../../../../../../../Api/Business/Place';

interface Context
{
	email?: string;
	setEmail: Dispatch<SetStateAction<string | undefined>>;
	support: OrderFormFieldSupport;
}

const ContextRef = createContext<Context>(undefined as never);

interface EmailContextProviderProps
{
	place: Place;
}

export const EmailContextProvider: FC<EmailContextProviderProps> =
	(
		{
			children,
			place,
		},
	) =>
	{
		const [email, setEmail] = useState<string | undefined>();

		const support = useMemo(() => place.emailField, [place.emailField]);

		const contextValue = useMemo(
			() => ({email, setEmail, support}),
			[email, support],
		);

		return <ContextRef.Provider value={contextValue}>
			{children}
		</ContextRef.Provider>;
	};

export function useEmailContext(): Context
{
	return useContext(ContextRef);
}