// @ts-nocheck
/* eslint-disable */
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { ClientBrowserName, WebClient } from '../../Bridge/Client/WebClient';
import { useWebClient } from '../../Bridge/Client/WebClientProvider';
import { StorageVars } from '../../Constants/StorageConstants';
import { useStoredVariable } from '../../Util/useStoredVariable';
import { BrowserRootLayoutPolyfillContextProvider } from './BrowserRootLayoutPolyfillContextProvider';

export const BrowserRootLayoutPolyfiller: FC =
	(
		{
			children,
		},
	) =>
	{
		const client = useWebClient();

		const [lowPerformanceMode] = useStoredVariable(
			StorageVars.LowPerformance,
			value => value === 'true' || value === '1',
			value => value ? 'true' : undefined
		);

		const [backgroundCss, setBackgroundCss] = useState<CSSProperties>();
		const [helmetRerenderHash, setHelmetRerenderHash] = useState(() => uuid());

		const activeRootElementSettings = useCssFragmentsForHtmlAndBodyElements(client, lowPerformanceMode);
		const watchScrollPositionCallbackSupplier = useCallback(
			activeRootElementSettings.watchScrollPositionCallbackSupplier
			?? ((client: WebClient) => {
				if (!lowPerformanceMode)
				{
					const callback = () =>
					{
						client.setScrollYOffset(window.scrollY);
					};

					callback();

					window.addEventListener('scroll', callback);
					window.addEventListener('resize', callback);

					return () =>
					{
						window.removeEventListener('scroll', callback);
						window.removeEventListener('resize', callback);
					}
				}
			}),
			[activeRootElementSettings.watchScrollPositionCallbackSupplier, lowPerformanceMode],
		);
		const setScrollPositionCallback = useCallback(
			activeRootElementSettings.setScrollPositionCallback
			?? ((scrollTop: number) =>
			{
				// On certain devices (e.g. iOS) the scroll position does not get reset to top when opening
				// certain product categories. The setTimeout(..., 0) fixes this.
				setTimeout(
					() =>
						window.scrollTo({
							left: 0,
							top: scrollTop,
							behavior: 'smooth'
						}),
					0
				);
			}),
			[activeRootElementSettings.setScrollPositionCallback]
		);
		useEffect(
			() => {
				return watchScrollPositionCallbackSupplier(client);
			},
			[watchScrollPositionCallbackSupplier, client]
		)

		useEffect(
			() => {
				Object.assign<CSSStyleDeclaration, CSSProperties>(document.documentElement.style, {
					margin: 0,
					display: 'flex',
					flexDirection: 'column',
					WebkitFontSmoothing: 'auto',
					...({
						scrollbarWidth: 'none',
					} as any as CSSProperties),
					...(activeRootElementSettings.htmlCss ?? {}),
					...(backgroundCss ?? {}),
					...(
						lowPerformanceMode
						? {backgroundAttachment: 'scroll'}
						: {}
					)
				});
				Object.assign<CSSStyleDeclaration, CSSProperties>(document.body.style, {
					backgroundColor: 'transparent',
					display: 'flex',
					flexDirection: 'column',
					margin: 0,
					...(activeRootElementSettings.bodyCss ?? {}),
				});
			},
			[helmetRerenderHash, activeRootElementSettings, backgroundCss]
		);

		const value =
			useMemo(
				() => ({
					lowPerformanceMode,
					scrollTo: setScrollPositionCallback,
					reApplyCss: () => setHelmetRerenderHash(uuid()),
					setBackgroundCss: setBackgroundCss,
				}),
				[
					lowPerformanceMode,
					setScrollPositionCallback,
					setHelmetRerenderHash,
					setBackgroundCss,
				]
			);

		return <>
			<BrowserRootLayoutPolyfillContextProvider
				value={value}
			>
				{children}
			</BrowserRootLayoutPolyfillContextProvider>
		</>;
	};

type RootElementSettingsForOnePlatform = {
	/**
	 * Fragment of CSS, ending in ';'
	 */
	htmlCss?: CSSProperties

	/**
	 * Fragment of CSS, ending in ';'
	 */
	bodyCss?: CSSProperties

	watchScrollPositionCallbackSupplier?: (webClient: WebClient) => (() => void)

	setScrollPositionCallback?: (scrollTop: number) => void
}

function useCssFragmentsForHtmlAndBodyElements(
	client: WebClient,
	lowPerformanceMode: boolean,
): RootElementSettingsForOnePlatform
{
	const isFacebookApp = useObserver(() => client.isFacebookApp);
	const os = useObserver(() => client.operatingSystem);
	const browser = useObserver(() => client.browserName);
	return useMemo(
		() => {
			if (isFacebookApp && os === 'Android OS')
				return { // free scrolling body, but always with a bit of scroll so one can scroll Facebook's buttom bar out of view
					htmlCss: {
						minHeight: '110%',
					},
					bodyCss: {
						flex: '1 0 auto',
					},
				};
			else if (os === 'Android OS' && isOneOf(browser, 'chrome', 'samsung'))
				return { // free scrolling body, but always with a bit of scroll so one can scroll Facebook's buttom bar out of view
					htmlCss: {
						minHeight: '100vh',
					},
					bodyCss: {
						flex: '1 0 auto',
					},
				};
			else if (os === 'Android OS' && browser === 'firefox')
				return { // body fixed to viewport, body contents scroll, because with a free-scrolling body, Firefox's address bar is drawn over the contents when it is in its default bottom position
					htmlCss: {
						height: '100%',
						overflowY: 'hidden',
					},
					bodyCss: {
						height: '100%',
						overflowY: 'scroll',
						flex: '1 0 auto',
					},
					watchScrollPositionCallbackSupplier: lowPerformanceMode
						? undefined
						: (webClient: WebClient) =>
						{
							const callback = () =>
							{
								const scrollYPosition = document.body.scrollTop;
								webClient.setScrollYOffset(scrollYPosition);
							};
							callback();
							document.body.addEventListener('scroll', callback);
							document.body.addEventListener('resize', callback);
							return () =>
							{
								document.body.removeEventListener('scroll', callback);
								document.body.removeEventListener('resize', callback);
							};
						},

					setScrollPositionCallback: (scrollTop: number) => {
						document.body.scrollTo(0, scrollTop);
					},
				};
			else
				return { // free scrolling body (so address bar will go away when scrolling down)
					htmlCss: {
						minHeight: '100%',
					},
					bodyCss: {
						flex: '1 0 auto',
					},
				};
		},
		[browser, isFacebookApp, os],
	);
}

function isOneOf(browser: ClientBrowserName, ...options: ClientBrowserName[])
{
	return options.indexOf(browser) !== -1;
}