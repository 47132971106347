import { Grid } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { isTrueAtInCache } from '../../../../../Api/Util/time-series/BooleanTimeSeries/BooleanTimeSeriesCache';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { useRootContext } from '../../../../../RootContext';
import { useTimeSchedulesContext } from '../../../../context/time-schedule/TimeSchedulesContext';
import { useCurrentPlaceService } from '../../../../current-place-service/CurrentPlaceService';
import { useIsRoutingByIdLive } from '../../../../routing-state-provider/useIsRoutingByIdLive';
import { ActionButton } from '../../../../UI/action-button/ActionButton';

interface RequestBillButtonProps
{
	item?: boolean;
	onClick: () => void;
	onClickLegacy: () => void;
	useLegacy: boolean;
	variant?: 'contained' | 'paper';
}

export const RequestBillButton: FC<RequestBillButtonProps> =
	(
		{
			item,
			onClick,
			onClickLegacy,
			useLegacy,
			variant = 'paper',
		},
	) =>
	{
		const {place} = useCurrentPlaceService();
		const {getTimeSchedule} = useTimeSchedulesContext();
		const {clockService} = useRootContext(true);
		const currentMinute = useObserver(() => clockService.currentMinute);
		const requestBillRoutingId = useObserver(() => place?.requestBillRoutingId);
		const isRoutingLive = useIsRoutingByIdLive(requestBillRoutingId);
		const isRoutingStatusIgnored = useObserver(() => place?.routingStatusIgnored);
		const hasNoRoutingOrRoutingIsLive = requestBillRoutingId === undefined || isRoutingLive || isRoutingStatusIgnored;
		const isVisibleInTimeSchedule =
			useMemo(
				() =>
				{
					if (place?.requestBillTimeScheduleId === undefined)
					{
						return true;
					}
					else
					{
						const timeSeries = getTimeSchedule(place.requestBillTimeScheduleId)?.booleanTimeSeries;

						if (timeSeries === undefined)
						{
							return true;
						}
						else
						{
							return isTrueAtInCache(timeSeries, currentMinute);
						}
					}
				},
				[currentMinute, getTimeSchedule, place.requestBillTimeScheduleId]
			);
		const show = hasNoRoutingOrRoutingIsLive && isVisibleInTimeSchedule;
		const translate = useTranslate();
		const button =
			useMemo(
				() =>
				{
					if (show)
					{
						return <ActionButton
							fullWidth
							icon={useLegacy ? AttachMoneyIcon : VisibilityIcon}
							text={translate(useLegacy ? 'Client-Order-RequestBill' : 'Client-Order-OpenBill')}
							onClick={useLegacy ? onClickLegacy : onClick}
							variant={variant}
						/>;
					}
					else
					{
						return undefined;
					}
				},
				[onClick, onClickLegacy, show, translate, useLegacy, variant]
			);

		return useMemo(
			() =>
			{
				if (button === undefined)
				{
					return null;
				}
				else
				{
					if (item)
					{
						return <Grid
							item
							xs={12}
						>
							{button}
						</Grid>;
					}
					else
					{
						return button;
					}
				}
			},
			[button, item]
		);
	};
