import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

const useStyles = makeStyles({
	image: {
		borderRadius: 13,
		height: 75,
		width: 200,
	},
	link: {
		borderRadius: 13,
		display: 'inline-block',
		height: 75,
		overflow: 'hidden',
		width: 200,
	},
});

interface GooglePlayStoreBadgeProps
{
	className?: string;
	link: string;
}

export const GooglePlayStoreBadge: FC<GooglePlayStoreBadgeProps> =
	({
		className,
		link,
	}) =>
	{
		const classes = useStyles();

		return <a
			className={
				clsx(
					classes.link,
					className,
				)
			}
			href={link}
		>
			<img
				alt="Get it on Google Play"
				className={classes.image}
				src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
			/>
		</a>;
	};
