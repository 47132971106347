import { useIsKioskMode } from '../../../Service/KioskService/Api/useIsKioskMode';

export const bottomBarColor = '#000000cc';
export const bottomBarContentColor = '#bbbbbb';
export const bottomBarBorderColor = '#555555';
const defaultBottomBarHeight = 65; // 56;
const defaultBottomBarFontSize = '1.3rem';
const narrowBottomBarFontSize = '1rem';
const kioskBottomBarHeight = 110;
const kioskBottomBarFontSize = '2.1rem';

export function useBottomBarHeight()
{
	const isKioskMode = useIsKioskMode();

	if (isKioskMode)
	{
		return kioskBottomBarHeight;
	}
	else
	{
		return defaultBottomBarHeight;
	}
}

export function useBottomBarFontSize(
	isNarrowScreen: boolean
)
{
	const isKioskMode = useIsKioskMode();

	if (isKioskMode)
	{
		return kioskBottomBarFontSize;
	}
	else
	{
		if (isNarrowScreen)
		{
			return narrowBottomBarFontSize;
		}
		else
		{
			return defaultBottomBarFontSize;
		}
	}
}