import { useObserver } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { Subject } from 'rxjs/internal/Subject';
import { Event } from '../../../Api/WebSocket/Event';
import { useRootContext } from '../../../RootContext';
import { QuerySubscription, WebSocketQuery } from './WebSocketService';

export function useWebSocketQueryRxjs<T extends Event = Event>(
	query: WebSocketQuery | undefined,
	type: string,
	handler: (event: T) => void,
)
{
	const {webSocketService} = useRootContext(true);

	const [querySubscription, setQuerySubscription] = useState<QuerySubscription>();
	const subject = useMemo(() => new Subject(), []);

	const [queryAsString, setQueryAsString] = useState<string>();
	const [stableQuery, setStableQuery] = useState<WebSocketQuery>();

	useEffect(() => {
		if (queryAsString !== query?.asString)
		{
			setQueryAsString(query?.asString);
			setStableQuery(query);
		}
	}, [query, queryAsString]);

	useEffect(() => {
		const subscription = subject.subscribe(handler);

		return () => subscription.unsubscribe();
	}, [handler, subject]);

	useEffect(() => {
		if (stableQuery !== undefined)
		{
			const querySubscription = webSocketService.startQuery(
				stableQuery,
				{
					type: type,
					handler: event => subject.next(event),
				},
			);

			setQuerySubscription(querySubscription);

			return () => querySubscription.dispose();
		}
	}, [stableQuery, subject, type, webSocketService]);

	return useObserver(() => querySubscription?.isOpen === true);
}