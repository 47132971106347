import { BaseStore } from '@intentic/ts-foundation';
import { action, makeObservable, observable } from 'mobx';
import * as React from 'react';
import { InfoSnackbarVariant } from './InfoSnackbarVariant';

export type InfoSnackbarContent = {
    action?: () => void;
    message: string;
    variant: InfoSnackbarVariant;
};

export class InfoSnackbarStore extends BaseStore
{
    public isOpen = false;
    private readonly messageQueue = observable.array<InfoSnackbarContent>();
    public currentMessage: InfoSnackbarContent;

    constructor()
    {
        super();
        makeObservable(
            this,
            {
                isOpen: observable,
                currentMessage: observable,
                close: action.bound,
                hide: action.bound,
                showMessage: action.bound,
                processMessageQueue: action.bound,
            }
        )
    }

    close(event: React.SyntheticEvent<any>, reason: string)
    {
        if (reason !== 'clickaway')
            this.hide();
    }

    hide()
    {
        this.isOpen = false;
    }

    showMessage(message: InfoSnackbarContent): void
    {
        this.messageQueue.push(message);

        if (this.isOpen)
        {
            this.isOpen = false;
        }
        else
        {
            this.processMessageQueue();
        }
    }

    processMessageQueue()
    {
        const infoSnackbarContent = this.messageQueue.shift();
        if (infoSnackbarContent)
        {
            this.currentMessage = infoSnackbarContent;
            this.isOpen = true;
        }
    }
}