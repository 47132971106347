import { OrderDescriptor } from '../../Api/Order/OrderDescriptor';

export function getDateCreated(order: OrderDescriptor): Date | undefined
{
	const date = order.dateScheduled ?? order.dateOrdered;

	return date === undefined
		? undefined
		: new Date(date);
}
