import { useMemo } from 'react';
import { useRootContext } from '../../../RootContext';
import { IllegalStateException } from '../../../Util/Exception/IllegalStateException';
import { WebSocketService } from './WebSocketService';

export function useWebSocketService(require?: false): WebSocketService | undefined
export function useWebSocketService(require: true): WebSocketService
export function useWebSocketService(require?: boolean): WebSocketService | undefined
{
	const context = useRootContext(require);
	return useMemo(
		() => {
			const webSocketService = context?.webSocketService;
			if (require && webSocketService === undefined)
				throw new IllegalStateException(
					'Attempting to use useWebSocketService before it is initialized',
				);
			return webSocketService;
		},
		[context?.webSocketService, require]
	);
}