import { Localizer } from '../../../../Bridge/Localization/Localizer';
import { PhoneNumberUtil } from 'google-libphonenumber';

export function phoneValidationErrors(
	requirePhoneNumber: boolean,
	clientPhoneNumber: string | undefined,
	localizer: Localizer,
	phoneNumberUtil: PhoneNumberUtil
): string[]
{
	let errors: string[] = [];

	if (!requirePhoneNumber)
	{
		return [];
	}

	const value = clientPhoneNumber;

	if (value === undefined)
	{
		errors.push(localizer.translate('Order-Notification-Phone-Required'));
	}
	else
	{
		try
		{
			const phoneNumberParsed = phoneNumberUtil.parseAndKeepRawInput(value);
			const isValid = phoneNumberUtil.isValidNumber(phoneNumberParsed);
			if (!isValid)
			{
				errors.push(
					localizer.translate('Client-PhoneNumber-NotValid')
				);
			}
		}
		catch (e)
		{
			errors.push(
				localizer.translate('Client-PhoneNumber-NotValid')
			);
		}
	}

	return errors;
}