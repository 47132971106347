import { Grow } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { useLocalizer } from '../../../../../../Bridge/Localization/useLocalizer';
import { useMemoizedDecimal } from '../../../../../../Util/decimal/useMemoizedDecimal';
import { usePaymentPriceContext } from '../../../../../UI/payment/price/context/PaymentPriceContext';
import PriceTag from '../../../../../UI/PriceTag/PriceTag';
import { OrderBuilderStore } from '../../OrderBuilderStore';

interface ShoppingCartPriceTagProps
{
	store: OrderBuilderStore;
}

export const ShoppingCartPriceTag: FC<ShoppingCartPriceTagProps> =
	({
		store,
	}) =>
	{
		const localizer = useLocalizer();
		const {discount, tip, total} = usePaymentPriceContext();

		const showValidation = useObserver(() => store.showValidation);
		const isValueSufficient = useObserver(() => store.shoppingCartStore.isValueSufficient);
		const productCurrencyCode = useObserver(() => store.business.productCurrencyCode);

		// TODO additive tax is based on the price - discount, so is this actually a total price excluding discount?
		const totalPriceExcludingDiscountAndTip = useMemoizedDecimal(
			total
				.add(discount)
				.sub(tip),
		);

		const discountedPriceExcludingTip = useMemoizedDecimal(
			totalPriceExcludingDiscountAndTip
				.sub(discount),
		);

		const color = !isValueSufficient && showValidation ? 'error' : 'textSecondary';

		return <>
			<PriceTag
				price={totalPriceExcludingDiscountAndTip}
				localizer={localizer}
				currencyCode={productCurrencyCode}
				color={color}
				discounted={!discount.isZero()}
				variant="body2"
				style={{
					marginLeft: 'auto',
				}}
			/>
			<Grow
				in={!discount.isZero()}
				mountOnEnter
				unmountOnExit
			>
				<PriceTag
					currencyCode={productCurrencyCode}
					color={color}
					localizer={localizer}
					price={discountedPriceExcludingTip}
					style={{
						marginLeft: 10,
					}}
					variant="body2"
				/>
			</Grow>
		</>;
	};