import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext, useMemo } from 'react';
import { useComputed } from '../../../../../Util/Hooks/useComputed';
import { useScreenWidth } from '../../../../../Util/Hooks/useScreenWidth';
import { BusinessContextRef } from '../../BusinessContext';
import { shouldShowCategoryOfStoreOnMenu } from '../Api/shouldShowCategoryOfStoreOnMenu';
import { MenuStore } from '../MenuStore';
import { MenuCarouselCategory } from './Category/MenuCarouselCategory';

const useStyles = makeStyles(() => ({
	grid: {
		flex: '1 0 auto !important',
		display: 'flex',
		flexDirection: 'column',
	},
	mixedGrid: {
		flex: '1 0 auto',
	},
	categoryTitleGroup: {
		display: 'flex',
		alignItems: 'baseline',
		cursor: 'pointer',
		color: 'white'
	},
	categoryTitle: {
		flex: 1,
		fontSize: '2rem',
		fontWeight: 300
	},
	showAll: {
		fontSize: '1.2rem'
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: '16px',
		// Equal to the gap
		marginTop: 16,
		marginBottom: 16,
	},
	smallScreenContainer: {
		gap: '8px',
		marginTop: 8,
		marginBottom: 8,
	},
}));

interface MenuCarouselProps
{
	nrOfColumns: number;
	store: MenuStore;
	childMenuStores: MenuStore[];
	excludeProducts?: boolean;
}

export const MenuCarousel: FC<MenuCarouselProps> =
	(
		{
			nrOfColumns,
			store,
			childMenuStores,
			excludeProducts,
		},
	) =>
	{
		const classes = useStyles();

		const {currentPlaceService, clockService} = useContext(BusinessContextRef);

		const products = useObserver(() => store.category.products);

		const shownChildMenuStores = useComputed(() =>
		{
			return childMenuStores.filter(childMenuStore => shouldShowCategoryOfStoreOnMenu(childMenuStore));
		}, [childMenuStores]);

		const shownProducts = useMemo(() => {
			if (excludeProducts)
				return [];

			return products.filter(product => product.isVisibleNow(currentPlaceService.place, clockService));
		}, [clockService, currentPlaceService.place, excludeProducts, products]);
		const screenWidth = useScreenWidth();

		return <Grid
			item
			xs={12}
			classes={{
				item: classes.grid,
			}}
		>
			<div
				className={
					clsx(
						classes.container,
						screenWidth === 'xs' && classes.smallScreenContainer
					)
				}
			>
				{
					shownProducts.length > 0 &&
						<MenuCarouselCategory
							nrOfColumns={nrOfColumns}
							store={store}
							categoryStore={store}
							excludeNestedCategories
							excludeHeader
							previewSwipe
						/>
				}
				{
					shownChildMenuStores.map(
						(childMenuStore, idx) =>
							<MenuCarouselCategory
								key={childMenuStore.category.uuid}
								nrOfColumns={nrOfColumns}
								store={store}
								categoryStore={childMenuStore}
								previewSwipe={shownProducts.length === 0 && idx === 0}
							/>)
				}
			</div>
		</Grid>;
	};
