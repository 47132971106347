import { ISO8601Duration } from './ISO8601Duration';

export function convertFromISO8601Duration(isoDurationString: string): ISO8601Duration
{
	let hours = 0;
	let minutes = 0;
	let seconds = 0;

	let partialString = isoDurationString.substr(2);

	let parts = partialString.split('H');

	if (parts.length > 1)
	{
		hours = parseInt(parts[0]);
		partialString = parts[1];
	}

	parts = partialString.split('M');

	if (parts.length > 1)
	{
		minutes = parseInt(parts[0]);
		partialString = parts[1];
	}

	parts = partialString.split('S');

	if (parts.length > 1)
	{
		seconds = parseInt(parts[0]);
	}

	return {
		hours: hours,
		minutes: minutes,
		seconds: seconds,
	};
}