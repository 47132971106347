import { ListItem } from '@material-ui/core';
import * as React from 'react';
import { FC, HTMLAttributes, ReactNode } from 'react';
import { MoneyDescriptor } from '../../../../../Api/Util/money/MoneyDescriptor';
import { GenericHistoricalItemContent } from './content/GenericHistoricalItemContent';

type GenericHistoricalItemProps =
	GenericHistoricalItemPropsWithContent |
	GenericHistoricalItemPropsWithDescription;

interface GenericHistoricalItemPropsWithContent extends HTMLAttributes<HTMLElement>
{
	content: ReactNode;
	description?: never;
	descriptionSecondary?: never;

	endAdornment?: ReactNode;
	imageUrl?: string;
	quantity?: number;
	price?: MoneyDescriptor;
	startAdornment?: ReactNode;
	translateZeroPriceAmount?: boolean;
}

interface GenericHistoricalItemPropsWithDescription extends HTMLAttributes<HTMLElement>
{
	content?: never;
	description: string;
	descriptionSecondary?: string;

	endAdornment?: ReactNode;
	imageUrl?: string;
	quantity?: number;
	price?: MoneyDescriptor;
	startAdornment?: ReactNode;
	translateZeroPriceAmount?: boolean;
}

export const GenericHistoricalItem: FC<GenericHistoricalItemProps> =
	({
		content,
		description,
		descriptionSecondary,
		endAdornment,
		imageUrl,
		onClick,
		quantity,
		price,
		startAdornment,
		translateZeroPriceAmount,
		...otherProps
	}) =>
	{
		if (onClick === undefined)
		{
			return <ListItem
				divider
				{...otherProps}
			>
				{startAdornment}
				{
					content ??
					<GenericHistoricalItemContent
						description={description}
						descriptionSecondary={descriptionSecondary}
						imageUrl={imageUrl}
						quantity={quantity}
						price={price}
						translateZeroPriceAmount={translateZeroPriceAmount}
					/>
				}
				{endAdornment}
			</ListItem>;
		}
		else
		{
			return <ListItem
				button
				divider
				onClick={onClick}
				{...otherProps}
			>
				{startAdornment}
				{
					content ??
					<GenericHistoricalItemContent
						description={description}
						descriptionSecondary={descriptionSecondary}
						imageUrl={imageUrl}
						quantity={quantity}
						price={price}
						translateZeroPriceAmount={translateZeroPriceAmount}
					/>
				}
				{endAdornment}
			</ListItem>;
		}
	};
