import { useObserver } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useWaiter } from '../../../../Service/UserService/useWaiter';
import { ManagerView } from '../ManagerView';
import { useManagerBusinessId } from '../useManagerBusinessId';
import { useWhetherPageIsAvailableWhenVisitingOtherBusinessWithBusinessSelector } from './useWhetherPageIsAvailableWhenVisitingOtherBusinessWithBusinessSelector';
import { useWhetherUserRolePermitsPageAccess } from './useWhetherUserRolePermitsPageAccess';

export function useIsPageAccessibleCallback(): (page: ManagerView) => boolean
{
	const businessId = useManagerBusinessId(true);
	const waiter = useWaiter();
	const userRolePermitsAccessTo = useWhetherUserRolePermitsPageAccess();
	const isAvailableWhenVisitingOtherBusinessWithBusinessSelector = useWhetherPageIsAvailableWhenVisitingOtherBusinessWithBusinessSelector();
	const inMainBusiness = useObserver(() => waiter?.businessId === businessId);
	return useCallback(
		(page: ManagerView) => {
			return userRolePermitsAccessTo(page)
				&& (inMainBusiness || isAvailableWhenVisitingOtherBusinessWithBusinessSelector(page));
		},
		[inMainBusiness, isAvailableWhenVisitingOtherBusinessWithBusinessSelector, userRolePermitsAccessTo],
	);
}