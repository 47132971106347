import { ImageFit } from '../../Image/ImageFit';

interface Rectangle
{
	x1: number;
	x2: number;
	y1: number;
	y2: number;
	scale: number;
}

export function getVisibleRectangleOnTargetContainer(
	imageWidth: number,
	imageHeight: number,
	targetWidth: number,
	targetHeight: number,
	fit: ImageFit
): Rectangle
{
	if (fit === 'Cover')
	{
		return getVisibleRectangleOnTargetContainerForCover(
			imageWidth,
			imageHeight,
			targetWidth,
			targetHeight
		);
	}
	else if (fit === 'Contain')
	{
		return getVisibleRectangleOnTargetContainerForContain(
			imageWidth,
			imageHeight,
			targetWidth,
			targetHeight
		);
	}
	else
	{
		throw new Error(`Fit ${fit} is not supported`);
	}
}

function getVisibleRectangleOnTargetContainerForCover(
	imageWidth: number,
	imageHeight: number,
	targetWidth: number,
	targetHeight: number
): Rectangle
{
	// The logic has been derived from the background-size: cover algorithm:
	// https://stackoverflow.com/a/10285523
	const imageRatio = imageHeight / imageWidth;
	const targetRatio = targetHeight / targetWidth;

	if (targetRatio > imageRatio)
	{
		const scale = targetHeight / imageHeight;
		const finalWidth = imageWidth * scale;
		const y1 = 0;
		const y2 = imageHeight;
		const x1 = (finalWidth - targetWidth) / 2 / scale;
		const x2 = imageWidth - x1;

		return {
			x1,
			x2,
			y1,
			y2,
			scale,
		};
	}
	else
	{
		const scale = targetWidth / imageWidth;
		const finalHeight = imageHeight * scale;
		const x1 = 0;
		const x2 = imageWidth;
		const y1 = (finalHeight - targetHeight) / 2 / scale;
		const y2 = imageHeight - y1;

		return {
			x1,
			x2,
			y1,
			y2,
			scale,
		};
	}
}

function getVisibleRectangleOnTargetContainerForContain(
	imageWidth: number,
	imageHeight: number,
	targetWidth: number,
	targetHeight: number
): Rectangle
{
	const widthScale = imageWidth / targetWidth;
	const heightScale = imageHeight / targetHeight;
	const targetAspectRatio = targetWidth / targetHeight;

	if (widthScale > heightScale)
	{
		const targetImageHeight = imageWidth / targetAspectRatio;
		const delta = (targetImageHeight - imageHeight) / 2;
		const x1 = 0;
		const x2 = imageWidth;
		const y1 = -1 * delta;
		const y2 = imageHeight + delta;
		const scale = widthScale;

		return {
			x1,
			x2,
			y1,
			y2,
			scale,
		};
	}
	else
	{
		const targetImageWidth = imageHeight * targetAspectRatio;
		const delta = (targetImageWidth - imageWidth) / 2;
		const x1 = -1 * delta;
		const x2 = imageWidth + delta;
		const y1 = 0;
		const y2 = imageHeight;
		const scale = heightScale;

		return {
			x1,
			x2,
			y1,
			y2,
			scale,
		};
	}
}