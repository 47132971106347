import { Link, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ContentPosition } from 'csstype';
import * as React from 'react';
import { FC } from 'react';
import { ContractingEntity } from '../../../Api/contracting_entity/ContractingEntity';
import { useIsKioskMode } from '../../../Service/KioskService/Api/useIsKioskMode';
import PoweredByButlarooLogo from './PoweredByButlarooLogo.png';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		alignItems: 'center',
	},
	logo: {
		marginLeft: 5,
		height: 22,
		verticalAlign: 'middle',
	},
	kioskLogo: {
		height: 26,
	}
});

export interface PoweredByButlarooProps
{
	contractingEntity: ContractingEntity;
	imageLink?: boolean;
	utmMedium?: string;
	justifyContent?: ContentPosition;
}

export const PoweredByButlaroo: FC<PoweredByButlarooProps> =
	({
		contractingEntity,
		imageLink,
		utmMedium,
		justifyContent,
	}) =>
	{
		const classes = useStyles();
		const isKioskMode = useIsKioskMode();

		const img =
			<img
				title="Butlaroo"
				alt="Butlaroo"
				src={PoweredByButlarooLogo}
				className={
					clsx(
						classes.logo,
						isKioskMode && classes.kioskLogo
					)
				}
			/>;

		return <div
			className={classes.root}
			style={{
				justifyContent,
			}}
		>
			<Typography
				variant="body1"
			>
				Powered by
			</Typography>
			{
				imageLink
					? <Link
						href={
							utmMedium === undefined
								? `${contractingEntity.url}?utm_source=app`
								: `${contractingEntity.url}?utm_source=app&utm_medium=${utmMedium}`
						}
						target="_blank"
					>
						{
							img
						}
					</Link>
					: img
			}
		</div>;
	};
