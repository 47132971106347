import * as React from 'react';
import { FC } from 'react';
import { Reward } from '../../../../../Api/Order/Loyalty/Como/Reward';
import MenuEntry from '../../../../UI/menu_entry/MenuEntry';
import { ComoPointBadge } from './ComoPointBadge';
import { ComoRewardsStore } from './ComoRewardsStore';

interface ComoRewardTileProps
{
	store: ComoRewardsStore;
	reward: Reward;
	minHeight?: number;
	minImageHeight?: number;
	minImageWidth?: number;
}

export const ComoPointShopTile: FC<ComoRewardTileProps> =
	({
		store,
		reward,
		minHeight,
		minImageHeight,
		minImageWidth,
	}) =>
	{
		return <div
			style={{
				position: 'relative',
				display: 'flex',
				flexGrow: 1,
			}}
		>
			<MenuEntry
				image={{
					imageURL: reward.imageUrl,
				}}
				title={reward.name}
				description={reward.description}
				showDescriptionOnTile
				type="TILE"
				primaryBadges={[<ComoPointBadge points={reward.points} decimalPlaces={store.shoppingCartStore.currentOrderService.currency.decimalPlaces} />]}
				minHeight={minHeight}
				minImageHeight={minImageHeight}
				minImageWidth={minImageWidth}
				disabled={reward.redeemable === false}
				onClick={() =>
				{
					store.openReward(reward);
				}}
			/>
			{
				reward.redeemable === false &&
				<div
					style={{
						position: 'absolute',
						top: 0,
						bottom: 0,
						right: 0,
						left: 0,
						background: 'rgba(0, 0, 0, 0.26)',
					}}
				/>
			}
		</div>;
	};
