import Decimal from 'decimal.js';
import { makeAutoObservable } from 'mobx';
import { NumberToDecimalTransformer, SerializationProfile } from '../../Util/Serialization/Serialization';
import { Color } from '../Other/Color';

export class ProductFeatureVariant
{
    // ------------------------- Properties -------------------------

    id: number;
    uuid: string;
    name: string;
    imageUrl?: string;
    imageDoContain?: boolean;
    imageHasTextBackdrop?: boolean;
    imageIsTextContrastColorDark?: boolean;
    imageBackgroundColor?: Color;
    imageBackdropColor?: Color;
    imageTextColor?: Color;
    price: Decimal;
    isSelectedByDefault: boolean;

    /**
     * The quantity that this {@link ProductFeatureVariant} is in stock
     */
    quantity: number | undefined;

    // ------------------------ Constructor -------------------------

    constructor()
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    public setQuantity(quantity: number | undefined): void
    {
        this.quantity = quantity;
    }

    public setPrice(price: Decimal): void
    {
        this.price = price;
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}

export const ProductFeatureVariantProfile =
    SerializationProfile.create(ProductFeatureVariant)
        .transform('price', NumberToDecimalTransformer);
