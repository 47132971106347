import { IconButton } from '@material-ui/core';
import { IconButtonProps } from '@material-ui/core/IconButton/IconButton';
import { ArrowBack } from '@material-ui/icons';
import { observer } from 'mobx-react';
import * as React from 'react';

@observer
export default class PageBackButton extends React.Component<any | IconButtonProps>
{
    render(): React.ReactNode
    {
        return <IconButton
            style={{
                position: 'absolute',
                top: 10,
                left: 10,
                color: 'white',
                backgroundColor: 'rgba(0,0,0,0.1)',
                zIndex: 1
            }}
            {... this.props}
        >
            <ArrowBack />
        </IconButton>;
    }
}
