import { makeAutoObservable, observable } from 'mobx';
import { DietaryFlag } from '../Api/Product/DietaryFlag';
import { NutritionFlag } from '../Api/Product/NutritionFlag';
import { Storage } from '../Bridge/Storage/Storage';
import { StorageVars } from '../Constants/StorageConstants';
import { IllegalArgumentException } from '../Util/Exception/IllegalArgumentException';

export class ProfileService
{
    /*---------------------------------------------------------------*
     *                          Properties                           *
     *---------------------------------------------------------------*/

    readonly storage: Storage;
    readonly dietaryFlags = observable.map<DietaryFlag, boolean>();
    readonly allergens = observable.map<string, boolean>();

    /*---------------------------------------------------------------*
     *                          Constructors                         *
     *---------------------------------------------------------------*/

    constructor(
        storage: Storage
    )
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });

        this.storage = storage;

        this.initialize();
    }

    /*---------------------------------------------------------------*
     *                        Initialization                         *
     *---------------------------------------------------------------*/

    initialize(): Promise<Promise<void>[]>
    {
        return Promise.all([
            this.initializeNutritionFlags(),
            this.initializeAllergens()
        ]);
    }

    async initializeNutritionFlags(): Promise<any>
    {
        this.dietaryFlags.clear();

        const dietaryFlagsRaw = this.storage.get(StorageVars.NutritionFlags);

        if (dietaryFlagsRaw)
        {
            for (const flag of (JSON.parse(dietaryFlagsRaw) || []) as DietaryFlag[])
            {
                await this.toggleDietaryFlag(flag, false);
            }
        }
    }

    async initializeAllergens(): Promise<any>
    {
        this.allergens.clear();

        const allergensRaw = this.storage.get(StorageVars.Allergens);

        if (allergensRaw)
        {
            for (const allergen of (JSON.parse(allergensRaw) || []) as string[])
            {
                await this.toggleAllergen(allergen, false);
            }
        }
    }

    /*---------------------------------------------------------------*
     *                            Actions                            *
     *---------------------------------------------------------------*/

    public async toggleDietaryFlag(flag: DietaryFlag, doPersist: boolean = true): Promise<void>
    {
        if (this.dietaryFlags.has(flag) && this.dietaryFlags.get(flag))
        {
            this.dietaryFlags.delete(flag);
        }
        else
        {
            this.dietaryFlags.set(flag, true);
        }

        if (doPersist)
            this.persistNutritionFlags();
    }

    public async toggleAllergen(allergen: string, doPersist: boolean = true): Promise<void>
    {
        if (this.allergens.has(allergen) && this.allergens.get(allergen))
        {
            this.allergens.delete(allergen);
        }
        else
        {
            this.allergens.set(allergen, true);
        }

        if (doPersist)
            this.persistAllergens();
    }

    /*---------------------------------------------------------------*
     *                         Public logic                          *
     *---------------------------------------------------------------*/

    hasDietaryFlag(flag: DietaryFlag): boolean
    {
        return this.dietaryFlags.has(flag);
    }

    /**
     * @return boolean Returns {@code true} if the specified {@link NutritionFlag} is relevant
     * in such a way to the user, that in an overview of menu items, this flag needs to be shown
     * on menu items for which this {@link NutritionFlag} applies
     */
    needsToSeeNutritionFlag(flag: NutritionFlag): boolean
    {
        switch (flag)
        {
            case 'IsPregnancyFriendly':
            case 'IsHalal':
            case 'IsKosher':
            case 'IsOrganic':
            case 'IsVegan':
            case 'IsVegetarian':
                return this.dietaryFlags.has(flag)
                    && this.dietaryFlags.get(flag);
            case 'ContainsAlcohol':
                return this.dietaryFlags.has('Nonalcoholic')
                    && this.dietaryFlags.get('Nonalcoholic');
            default:
                throw new IllegalArgumentException(`Nutrition flag ${flag} not found.`);
        }
    }

    hasAllergen(allergen: string): boolean
    {
        return this.allergens.has(allergen)
            && this.allergens.get(allergen);
    }

    /*---------------------------------------------------------------*
     *                         Private logic                         *
     *---------------------------------------------------------------*/

    private persistNutritionFlags(): void
    {
        this.storage.set(
            StorageVars.NutritionFlags,
            JSON.stringify(Array.from(this.dietaryFlags.keys())));
    }

    private persistAllergens(): void
    {
        this.storage.set(
            StorageVars.Allergens,
            JSON.stringify(Array.from(this.allergens.keys())));
    }
}
