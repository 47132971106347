import { OrderDescriptor } from '../../../../Api/Order/OrderDescriptor';
import { fetchAny } from '../../../../Util/Api';

export function getLastClientOrders(): Promise<OrderDescriptor[]>
{
	return fetchAny(
		`/client/business/orders/all`,
		undefined,
	);
}
