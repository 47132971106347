import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { reaction } from 'mobx';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useEffect } from 'react';
import { Scheduler } from '../../../Scheduler/Scheduler';
import { SchedulerSummary } from '../../../Scheduler/summary/SchedulerSummary';
import { OrderBuilderStore } from '../../OrderBuilderStore';

const useStyles = makeStyles(() => ({
    PaymentMethodExpansionPanel: {
        flex: '0 1 auto',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
    },
    ShrinksVerticallyWithParent: {
        flex: '0 1 auto',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
    },
}));

interface SchedulerOrderBuilderSegmentProps
{
    store: OrderBuilderStore
}

export const SchedulerOrderBuilderSegment: FC<SchedulerOrderBuilderSegmentProps> =
    (
        {
            store,
        },
    ) =>
    {
        const showScheduler = useObserver(() => store.showScheduler);

        if (!showScheduler)
            return null;
        return <Inner store={store} />;
    };

const Inner: FC<SchedulerOrderBuilderSegmentProps> =
    (
        {
            store,
        }
    ) =>
    {
        const classes = useStyles();
        const showValidation = useObserver(() => store.showValidation);
        const schedulerHasFocus = useObserver(() => store.schedulerHasFocus);
        const setSchedulerHasFocus = useObserver(() => store.setSchedulerHasFocus);
        const onValidationChange = useCallback(
            validationErrors => store
                .getValidationErrors('scheduler')
                .replace(validationErrors),
            [store]
        );

        const schedulerStore = store.schedulerStore!;
        const hasValidationErrors = useObserver(() => schedulerStore.hasValidationErrors);

        useEffect(() => reaction(
            () => schedulerStore.validationErrors,
            () => {
                onValidationChange(schedulerStore.validationErrors);
            },
            {
                fireImmediately: true,
            },
        ));

        return <Grid
            item
            xs={12}
            style={{
                flex: '0 0 auto',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'hidden',
            }}
        >
            <Accordion
                expanded={schedulerHasFocus}
                onChange={(e, expanded) => setSchedulerHasFocus(expanded)}
                className={classes.PaymentMethodExpansionPanel}
                style={{
                    backgroundColor: showValidation && hasValidationErrors
                        ? '#f001'
                        : '#fff8',
                    overflowY: 'auto',
                }}
                // CollapseProps={{
                //     classes: {
                //         container: classes.ShrinksVerticallyWithParent,
                //         wrapper: classes.ShrinksVerticallyWithParent,
                //         wrapperInner: classes.ShrinksVerticallyWithParent,
                //     },
                // }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{
                        flex: '0 0 auto',
                    }}
                >
                    <SchedulerSummary />
                </AccordionSummary>
                <AccordionDetails
                    style={{
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingBottom: 8,
                        flex: '0 1 auto',
                        display: 'flex',
                        flexDirection: 'column',
                        overflowY: 'hidden',
                    }}
                >
                    <Scheduler store={schedulerStore} showValidation={showValidation} />
                </AccordionDetails>
            </Accordion>
        </Grid>;
    }