import { makeStyles, SvgIconProps } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import clsx from 'clsx';
import { ComponentType, createElement, CSSProperties, FC, useMemo } from 'react';

interface StyleProps
{
	marginLeft: string | number | undefined
	marginRight: string | number | undefined
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
	buttonIcon: ({marginLeft, marginRight}) => ({
		marginLeft: marginLeft,
		marginRight: marginRight,
	}),
}))

interface ButtonIconProps
{
	icon: ComponentType<SvgIconProps> | string | undefined
	iconStyle: CSSProperties | undefined
	className: string | undefined
	replaceWithRetryIcon: boolean
	color: string
	marginLeft: string | number | undefined
	marginRight: string | number | undefined
}

export const ButtonIcon: FC<ButtonIconProps> =
	(
		{
			icon,
			iconStyle,
			className: classNameProp,
			color,
			marginLeft,
			marginRight,
			replaceWithRetryIcon,
		},
	) =>
	{
		const defaultClasses = useStyles({marginLeft, marginRight});

		const iconClasses = useMemo(
			() => clsx(defaultClasses.buttonIcon, classNameProp),
			[defaultClasses.buttonIcon, classNameProp],
		);

		if (icon === undefined)
			return null;

		else if (typeof icon === 'string')
			return <img
				alt="icon"
				className={iconClasses}
				src={icon}
				style={{
					color,
					// Default icon height
					height: 24,
					...iconStyle,
				}}
			/>;
		else
			return createElement<SvgIconProps>(
				replaceWithRetryIcon
					? RefreshIcon
					: icon,
				{
					className: iconClasses,
					style: {
						color,
						...iconStyle,
					},
				},
			);
	};
