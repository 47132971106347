import { useCallback, useEffect, useReducer } from 'react';
import { OrderDescriptor } from '../../../Api/Order/OrderDescriptor';
import { useManagerPanelBusiness } from '../../../hooks/manager-panel-business/useManagerPanelBusiness';
import { getWaiterOrders } from '../../../Service/OrderService/Api/Waiter/getWaiterOrders';
import { getDateCreated } from '../../Orders/getDateCreated';
import { updateOrderInArray } from './updateOrderInArray';

export function useQueryOrders(
	onNewOrder?: (order: OrderDescriptor) => void
): [
		OrderDescriptor[] | undefined,
	(order: OrderDescriptor) => void,
	(uuid: string, mutationFunction: (order: OrderDescriptor) => OrderDescriptor) => void
]
{
	const business = useManagerPanelBusiness(true);
	const [orders, orderDispatch] = useReducer<(state: OrderDescriptor[] | undefined, action: OrderDescriptor | OrderDescriptor[]) => OrderDescriptor[] | undefined>(
		(state: OrderDescriptor[] = [], orders: OrderDescriptor | OrderDescriptor[]) =>
		{
			if (Array.isArray(orders))
			{
				for (let i = 0; i < orders.length; i++)
				{
					const order = orders[i];

					state = updateOrderInArray(state, order);

					const dateCreated = getDateCreated(order);

					if (
						order.state === 'ordered' &&
						dateCreated !== undefined &&
						(Date.now() - dateCreated.getTime()) < 10000 &&
						onNewOrder !== undefined
					)
					{
						const foundOrder = state.find(peerOrder => peerOrder.id === order.id);

						if (!(foundOrder && foundOrder.state === 'ordered'))
						{
							onNewOrder(order);
						}
					}

				}

				return [...state];
			}
			else
			{
				const dateCreated = getDateCreated(orders);

				if (
					orders.state === 'ordered' &&
					dateCreated !== undefined &&
					onNewOrder !== undefined
				)
				{
					const foundOrder = state.find(peerOrder => peerOrder.id === orders.id);

					if (!(foundOrder && foundOrder.state === 'ordered'))
					{
						onNewOrder(orders);
					}
				}

				return [...updateOrderInArray(state, orders)];
			}
		},
		undefined
	);

	useEffect(
		() =>
		{
			if (business !== undefined)
			{
				getWaiterOrders(business.id)
					.then(orderDispatch);
			}
		},
		[business],
	);

	const reloadOrder = useCallback((order: OrderDescriptor) => orderDispatch(order), []);

	const mutateOrderByUuid = useCallback((uuid: string, mutationFunction: ((order: OrderDescriptor) => OrderDescriptor)) =>
	{
		const order = orders?.find(peer => peer.uuid === uuid);

		if (order !== undefined)
			orderDispatch(mutationFunction(order));
	}, [orders]);

	return [orders, reloadOrder, mutateOrderByUuid];
}
