import * as React from 'react';
import { ReactNode } from 'react';
import InfiniteScrollComponent from 'react-infinite-scroller';

export interface InfiniteScrollProps
{
	loader?: ReactNode;
	loadMore: () => void;
	hasMore: boolean;
}

export const InfiniteScroll: React.FC<InfiniteScrollProps> =
	(
		{
			children,
			hasMore,
			loader,
			loadMore,
		},
	) =>
	{
		return <InfiniteScrollComponent
			loader={loader}
			loadMore={loadMore}
			hasMore={hasMore}
			useWindow={false}
		>
			{children}
		</InfiniteScrollComponent>;
	};