import { getAuthUrl } from '../../../Util/Api/Resources/getAuthUrl';
import { toQueryParams } from '../../../Util/toQueryParams';
import { AuthenticationResultV3 } from '../AuthenticationResult';
import { getJwkSet } from './JwkSet';
import { validateToken } from './validateToken';

export async function refreshAccessToken(refreshToken: string): Promise<AuthenticationResultV3>
{
	const body = {
		grant_type: 'refresh_token',
		client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
		refresh_token: refreshToken,
	};

	const response = await fetch(
		getAuthUrl('/oauth2/token'),
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
			},
			body: toQueryParams(body),
		},
	);

	const tokens = await response.json();
	const jwkSet = getJwkSet();

	const accessTokenPayload = await validateToken(jwkSet, tokens.access_token);
	const idTokenPayload = await validateToken(jwkSet, tokens.id_token);

	return {
		version: 'V3',
		accessToken: tokens.access_token,
		refreshToken: tokens.refresh_token,
		accessTokenPayload,
		idTokenPayload,
	};
}
