import { ProfileStore } from '../ProfileStore';
import * as React from 'react';
import { FC, useContext } from 'react';
import { Profile } from '../Profile';
import { PageDialog } from '../../../UI/PageDialog';
import { EntranceContextRef } from '../../Entrance/EntranceContext';

interface ProfileDialogProps
{
    store: ProfileStore;
}

export const ProfileDialog: FC<ProfileDialogProps> =
    ({
        store,
    }) =>
    {
        const {
            localizer,
        } = useContext(EntranceContextRef);
        return <PageDialog
            title={localizer.translate('Profile')}
            onClose={store.close}
        >
            <Profile
                store={store}
            />
        </PageDialog>
    };