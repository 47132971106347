import { Grow, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { UnsupportedOperationException } from '../../../../../Util/Exception/UnsupportedOperationException';
import { CouponCardStore } from './CouponCardStore';

const useStyles = makeStyles(theme => ({
	closeIcon: {},
	couponCard: {
		backgroundColor: theme.palette.primary.main,
		borderRadius: theme.shape.borderRadius,
		outlineStyle: 'dotted',
		outlineWidth: 1,
		outlineColor: 'rgba(255,255,255,0.8)',
		outlineOffset: -3,
		display: 'inline-block',
		paddingTop: 8,
		paddingBottom: 8,
		paddingLeft: 11,
		paddingRight: 25,
		position: 'relative',
	},
	couponCardDescription: {
		color: theme.palette.primary.contrastText,
		fontSize: 'smaller',
	},
	couponCardTitle: {
		color: theme.palette.primary.contrastText,
		fontSize: 'smaller',
		fontWeight: 'bold',
	},
}));

export interface CouponCardProps
{
	store: CouponCardStore;
}

export const CouponCard: FC<CouponCardProps> =
	({
		store,
	}) =>
	{
		const classes = useStyles();

		const cardElementRef = useObserver(() => store.cardElementRef);
		const contextMenuOpen = useObserver(() => store.contextMenuOpen);
		const couponEffectDescription = useObserver(() => store.couponEffectDescription);
		const couponName = useObserver(() => store.couponName);
		const couponType = useObserver(() => store.couponType);
		const onClickRemove = useObserver(() => store.onClickRemove);
		const removeCardLabel = useObserver(() => store.removeCardLabel);
		const setContextMenuOpen = useObserver(() => store.setContextMenuOpen);

		if (couponType === 'DISCOUNT_PRODUCT_TOTAL_RELATIVE'
			|| couponType === 'DISCOUNT_PRODUCT_TOTAL_FIXED'
			|| couponType === 'DISCOUNT_PRODUCT_TOTAL_QUANTITY')
		{
			return <>
				<Grow
					in
				>
					<div
						className={classes.couponCard}
						onClick={() => setContextMenuOpen(true)}
						ref={cardElementRef}
					>
						<Typography
							className={classes.couponCardTitle}
						>
							{couponName}
						</Typography>
						<Typography
							className={classes.couponCardDescription}
						>
							{couponEffectDescription}
						</Typography>
					</div>
				</Grow>
				<Menu
					anchorEl={cardElementRef.current}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					onClose={() => setContextMenuOpen(false)}
					open={contextMenuOpen}
					transformOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
				>
					<MenuItem
						onClick={onClickRemove}
					>
						<Close
							className={classes.closeIcon}
						/>
						<Typography
						>
							{removeCardLabel}
						</Typography>
					</MenuItem>
				</Menu>
			</>;
		}

		throw new UnsupportedOperationException();
	};
