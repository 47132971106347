import { Account } from '../../../Api/Account/Account';
import { AccountRegistrationResponse } from '../../../Api/Account/AccountRegistrationResponse';
import { Client } from '../../../Bridge/Client/Client';
import { fetchAny } from '../../../Util/Api';
import { getBackendOSValue } from '../../../Util/Api/getBackendOSValue';
import { verifyAccountV2 } from './verifyAccountV2';

export async function registerAccountV2(client: Client): Promise<Account>
{
	const response: AccountRegistrationResponse = await fetchAny(
		'/business/client/register',
		{
			os: getBackendOSValue(client),
			osVersion: '1',
		},
	);

	const account = new Account(
		response.key,
		response.phrase,
		null,
	);

	return await verifyAccountV2(client, account)
		? Promise.resolve(account)
		: Promise.reject('Invalid account registered');
}
