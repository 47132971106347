import { useMemo } from 'react';
import { Business } from '../../Api/Business/Business';
import { useManagerContext } from '../../Component/Page/Manager/manager-context';
import { useComputed } from '../../Util/Hooks/useComputed';

export function useManagerPanelBusiness(require?: boolean): Business | undefined
export function useManagerPanelBusiness(require: true): Business
export function useManagerPanelBusiness(require: boolean = false): Business | undefined
{
	const {waiterService} = useManagerContext();
	const business = useComputed(() => waiterService.business, [waiterService.business]);
	return useMemo(
		() => {
			if (require && business === undefined)
				throw new Error('the manager panel\'s business is required here');
			return business;
		},
		[business, require],
	);
}