import * as React from 'react';
import { FC, useMemo } from 'react';
import { OrderEventComponent } from '../OrderEventComponent';
import { OrderEventProps } from '../OrderEventProps';
import { OrderEventStateTransition } from '../OrderEventStateTransition';

export const OrderPaymentEvent: FC<OrderEventProps> =
	(
		{
			orderEvent,
		},
	) =>
	{
		const stateTransitionComponent = useMemo(
			() => <OrderEventStateTransition fromState={orderEvent.fromState} toState={orderEvent.toState} orderEventType={orderEvent.type} secondary />,
			[orderEvent.fromState, orderEvent.toState, orderEvent.type],
		);

		return <OrderEventComponent
			orderEvent={orderEvent}
			summary={stateTransitionComponent}
		/>;
	};