import UIElement from '@adyen/adyen-web/dist/types/components/UIElement';
import { CheckoutSessionPaymentResponse } from '@adyen/adyen-web/dist/types/types';
import { useObserver } from 'mobx-react-lite';
import { useCallback, useContext } from 'react';
import { BusinessContextRef } from '../../../Page/Business/BusinessContext';

type ProcessAdyenCheckoutPaymentResponseHandler = (
	response: CheckoutSessionPaymentResponse,
	component: UIElement<any>,
) => void

export function useProcessAdyenCheckoutPaymentResponse(): ProcessAdyenCheckoutPaymentResponseHandler
{
	const {businessStore} = useContext(BusinessContextRef);

	const handlePaymentProcessingResult = useObserver(() => businessStore.handlePaymentProcessingResult);

	return useCallback(
		(
			response: CheckoutSessionPaymentResponse,
			component: UIElement<any>,
		) =>
		{
			// Possible values: https://docs.adyen.com/online-payments/payment-result-codes
			switch (response.resultCode)
			{
				case 'Authorised':
				{
					handlePaymentProcessingResult?.({
						state: 'Success',
					});

					break;
				}
				case 'IdentifyShopper':
				{
					if (response.action?.type === 'threeDS2')
					{
						handlePaymentProcessingResult?.({
							action: response.action,
							component,
							requiresExtraUserInteraction: true,
							state: 'Pending',
						});
					}
					else
					{
						handlePaymentProcessingResult?.({
							action: response.action,
							component,
							requiresExtraUserInteraction: false,
							state: 'Pending',
						});
					}

					break;
				}
				case 'PresentToShopper':
				{
					handlePaymentProcessingResult?.({
						action: response.action,
						component,
						requiresExtraUserInteraction: true,
						state: 'Pending',
					});

					break;
				}
				case 'Received':
				case 'RedirectShopper':
				{
					handlePaymentProcessingResult?.({
						action: response.action,
						component,
						requiresExtraUserInteraction: false,
						state: 'Pending',
					});

					break;
				}
				case 'Cancelled':
				case 'Error':
				case 'Refused':
				{
					handlePaymentProcessingResult?.({
						error: new Error('Payment refused'),
						state: 'Failed',
					});

					break;
				}
				default:
				{
					handlePaymentProcessingResult?.({
						action: response.action,
						component,
						requiresExtraUserInteraction: true,
						state: 'Pending',
					});

					break;
				}
			}
		},
		[handlePaymentProcessingResult],
	);
}
