import Grid from '@material-ui/core/Grid/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FC } from 'react';

const useStyle = makeStyles(() => ({
	section: {
		flex: '0 0 auto',
	},
}));

interface OrderBuilderSegmentProps
{
	containerClassName?: string;
}

export const OrderBuilderSegment: FC<OrderBuilderSegmentProps> =
	(
		{
			children,
			containerClassName,
		},
	) =>
	{
		const classes = useStyle();

		return <Grid className={classes.section} item xs={12}>
			<Grid className={containerClassName} container spacing={2}>
				<Grid item xs={12}>
					{children}
				</Grid>
			</Grid>
		</Grid>;
	};