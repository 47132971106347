import { Collapse, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import clsx from 'clsx';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { useValidationContext } from '../../../../validation/context/ValidationContext';
import { usePaymentIssuerContext } from '../../../issuer/context/PaymentIssuerContext';
import { usePaymentMethodContext } from '../../context/PaymentMethodContext';

const useStyles = makeStyles({
	validationText: {
		marginTop: 0,
		fontSize: '0.75rem !important',
	},
});

interface PaymentMethodValidationTextProps
{
	className?: string;
}

export const PaymentMethodValidationText: FC<PaymentMethodValidationTextProps> =
	({
		className,
	}) =>
	{
		const showValidation = useValidationContext();
		const {validation: paymentMethodValidation} = usePaymentMethodContext();
		const {validation: paymentIssuerValidation} = usePaymentIssuerContext();

		const classes = useStyles();

		const allValidation = useMemo(
			() => [...paymentMethodValidation, ...paymentIssuerValidation],
			[paymentIssuerValidation, paymentMethodValidation],
		);

		const showErrors = useMemo(
			() => showValidation && allValidation.length > 0,
			[allValidation.length, showValidation],
		);

		const validationTextClassName = clsx(classes.validationText, className);

		return <Collapse in={showErrors} unmountOnExit>
			<Typography className={validationTextClassName} color="error">
				{allValidation[0]}
			</Typography>
		</Collapse>;
	};