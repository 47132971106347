import { IllegalArgumentException } from '../../../../../../Util/Exception/IllegalArgumentException';
import { IllegalStateException } from '../../../../../../Util/Exception/IllegalStateException';

type Direction = 'row' | 'column';

export class Layout
{
	public readonly groupLengths: number[]
	public readonly groupDirection: Direction
	private _fractionOfBiggestSizeDifference: number | undefined;

	constructor(groupLengths: number[], groupDirections: Direction)
	{
		this.groupLengths = groupLengths;
		this.groupDirection = groupDirections;
	}

	public get fractionOfBiggestSizeDifference()
	{
		if (this._fractionOfBiggestSizeDifference === undefined)
		{
			const maxLength = Math.max(...this.groupLengths);
			const minLength = Math.min(...this.groupLengths);
			this._fractionOfBiggestSizeDifference = minLength / maxLength;
		}
		return this._fractionOfBiggestSizeDifference;
	}
}

/**
 * "Best" means a layout
 * <ul>
 *     <li>Of specified maximum width</li>
 * </ul>
 */
export function calculateBestLayoutWithWidth(maximumWidth: number, numberOfItems: number): Layout
{
	if (maximumWidth <= 0)
		throw new IllegalArgumentException(`Specified width '${maximumWidth}' must be an integer strictly greater than 0`);

	return getBestLayoutOfMaxWidth(maximumWidth, numberOfItems);
}



function getBestLayoutOfMaxWidth(maximumWidth: number, numberOfItems: number): Layout
{
	const layouts = getWidthsToTry(maximumWidth)
		.map(width => getBestLayoutOfWidth(width, numberOfItems))

		// order by descending Layout.fractionOfBiggestSizeDifference
		.sort((a, b) => b.fractionOfBiggestSizeDifference - a.fractionOfBiggestSizeDifference);

	const bestLayoutOfMaxWidth = layouts[0];
	if (bestLayoutOfMaxWidth.fractionOfBiggestSizeDifference <= 0.5)
	{
		if (maximumWidth <= 0)
			throw new IllegalStateException('Mathematically this state should never occur');
		return getBestLayoutOfMaxWidth(Math.floor(maximumWidth / 2), numberOfItems);
	}
	else
		return bestLayoutOfMaxWidth;
}

function getWidthsToTry(maximumWidth: number)
{
	const widthsToTry: number[] = [];
	for (let width = maximumWidth; width > (maximumWidth / 2); width--)
		widthsToTry.push(width);
	return widthsToTry;
}

function getBestLayoutOfWidth(width: number, numberOfItems: number): Layout
{
	const dividedIntoRows = makeRowLayout(width, numberOfItems);
	const dividedIntoColumns = makeColumnLayout(width, numberOfItems);

	return dividedIntoRows.fractionOfBiggestSizeDifference >= dividedIntoColumns.fractionOfBiggestSizeDifference
		? dividedIntoRows
		: dividedIntoColumns;
}

function makeRowLayout(lengthOfLongestGroup: number, numberOfItems: number)
{
	const numberOfItemsInRemainingGroups = numberOfItems - lengthOfLongestGroup;
	const minimumNumberOfRemainingGroups = Math.ceil(numberOfItemsInRemainingGroups / lengthOfLongestGroup);
	const numberOfRemainingGroups = minimumNumberOfRemainingGroups; // Minimize the difference between remaining groups and longest group
	const remainingGroupLengths = divideAsEvenlyAsPossibleIntoSetNumberOfGroups(numberOfItemsInRemainingGroups, numberOfRemainingGroups);
	return new Layout(
		[
			...remainingGroupLengths.slice(0, Math.floor(remainingGroupLengths.length / 2)),
			lengthOfLongestGroup,
			...remainingGroupLengths.slice(Math.floor(remainingGroupLengths.length / 2))
		],
		'row'
	)
}

function makeColumnLayout(numberOfGroups: number, numberOfItems: number)
{
	const groupLengths = divideAsEvenlyAsPossibleIntoSetNumberOfGroups(numberOfItems, numberOfGroups);
	return new Layout(groupLengths, 'column');
}

function divideAsEvenlyAsPossibleIntoSetNumberOfGroups(numberOfItems: number, numberOfGroups: number)
{
	const baseGroupLength = Math.floor(numberOfItems / numberOfGroups);
	const nrOfGroupsWithBaseGroupLengthPlusOne = numberOfItems % numberOfGroups;
	const nrOfGroupsWithBaseGroupLength = numberOfGroups - nrOfGroupsWithBaseGroupLengthPlusOne;
	return [
		...fillArray(Math.floor(nrOfGroupsWithBaseGroupLength / 2), baseGroupLength),
		...fillArray(nrOfGroupsWithBaseGroupLengthPlusOne, baseGroupLength + 1),
		...fillArray(Math.ceil(nrOfGroupsWithBaseGroupLength / 2), baseGroupLength),
	];
}

function fillArray(len: number, value: number)
{
	const arr: number[] = [];
	for (let i = 0; i < len; i++) {
		arr.push(value);
	}
	return arr;
}
