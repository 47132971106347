import { setDefaultApiHeader } from '../../Util/Api/setDefaultApiHeader';
import { AuthenticationResult } from './AuthenticationResult';

export function setAuthorizationHeaders(authenticationResult: AuthenticationResult): void
{
	if (authenticationResult.version === 'V3')
	{
		setDefaultApiHeader('Authorization', `Bearer ${authenticationResult.accessToken}`);
	}
	else
	{
		setDefaultApiHeader('Authorization-Key', authenticationResult.key);
		setDefaultApiHeader('Authorization-Phrase', authenticationResult.token);
	}
}
