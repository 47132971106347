export class ChangeQuantityCartChangeVariant
{
	// ------------------------- Properties -------------------------

	public variantId;

	public assignmentId;

	// ------------------------ Constructor -------------------------

	constructor(
		variantId: number,
		assignmentId: number
	)
	{
		this.variantId = variantId;
		this.assignmentId = assignmentId;
	}

	// ----------------------- Initialization -----------------------

	// -------------------------- Computed --------------------------

	// -------------------------- Actions ---------------------------

	// ------------------------ Public logic ------------------------

	// ----------------------- Private logic ------------------------
}
