import { makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { useTranslation } from '../../../Bridge/Localization/useTranslation';

const useStyles = makeStyles((theme: Theme) => ({
	text: {
		color: theme.palette.common.white,
		fontFamily: 'Raleway',
		fontWeight: 'lighter',
	},
	textPortrait: {
		textAlign: 'center',
	},
	textMessage: {
		marginBottom: 10,
		marginTop: 10,
	},
}));


interface ErrorPageTextProps
{
	exceptionId?: string
	isPortrait: boolean
	title: string
	message: string
}

export const ErrorPageText: FC<ErrorPageTextProps> =
	(
		{
			exceptionId,
			isPortrait,
			title,
			message,
		},
	) =>
	{
		const classes = useStyles({});

		const defaultTextClassesString = useMemo(
			() =>
			{
				let rootClasses = classes.text;

				if (isPortrait)
				{
					rootClasses = rootClasses + ' ' + classes.textPortrait;
				}

				return rootClasses;
			},
			[classes.text, classes.textPortrait, isPortrait]
		);

		const exceptionCodeLabel = useTranslation('Exception-Code');

		return <div>
			<Typography
				classes={{
					root: defaultTextClassesString,
				}}
				variant="h6"
			>
				{title}
			</Typography>
			<Typography
				classes={{
					root: `${defaultTextClassesString} ${classes.textMessage}`,
				}}
			>
				{message}
			</Typography>
			{
				exceptionId &&
				<Typography
					classes={{
						root: defaultTextClassesString,
					}}
				>
					{exceptionCodeLabel}: {exceptionId}
				</Typography>
			}
		</div>;
	};