import { Box, Typography, Zoom } from '@material-ui/core';
import * as React from 'react';
import { FC, useContext } from 'react';
import { EntranceContextRef } from '../../../../../../Entrance/EntranceContext';

interface ProductQuantityBadgeProps
{
	quantity: number;
}

export const ProductQuantityBadge: FC<ProductQuantityBadgeProps> =
	(
		{
			quantity,
		}
	) =>
	{
		const {
			brandingService,
		} = useContext(EntranceContextRef);

		return <Zoom
			in
		>
			<Box
				sx={{
					borderRadius: '50%',
					// Solved in newer versions of Material-UI:
					// @ts-ignore
					backgroundColor: brandingService.tintColor.css,
					color: brandingService.tintContrastTextColor.css,
					height: 25,
					width: 25,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Typography
					variant="body1"
					style={{
						fontSize: 13,
						lineHeight: 1,
					}}
				>
					{quantity}
				</Typography>
			</Box>
		</Zoom>;
	}
