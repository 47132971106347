import { makeAutoObservable } from 'mobx';
import { SerializationProfile } from '../../Util/Serialization/Serialization';
import { ProductFeatureVariant, ProductFeatureVariantProfile } from './ProductFeatureVariant';

export class ProductFeature
{
    // ------------------------- Properties -------------------------

    id: number;
    type: 'addition' | 'alternative';
    name: string;
    description: string;
    minAllowedVariants: number | undefined;
    maxAllowedVariants: number;
    variants: ProductFeatureVariant[];

    // ------------------------ Constructor -------------------------

    constructor()
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}

export const ProductFeatureProfile =
    SerializationProfile.create(ProductFeature)
        .profile('variants', ProductFeatureVariantProfile);
