import { Grow, IconButton, makeStyles, Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import * as React from 'react';
import { FC } from 'react';
import { LoyaltyDiscount } from '../../../../../Api/Order/Loyalty/LoyaltyDiscount';

const useStyles = makeStyles(theme => ({
	rewardCard: {
		backgroundColor: theme.palette.primary.main,
		borderRadius: theme.shape.borderRadius,
		outlineStyle: 'dotted',
		outlineWidth: 1,
		outlineColor: 'rgba(255,255,255,0.8)',
		outlineOffset: -3,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: theme.spacing(0.5),
		paddingTop: 8,
		paddingBottom: 8,
		paddingLeft: 11,
		paddingRight: 11,
		position: 'relative',
	},
	rewardCardDescription: {
		color: theme.palette.primary.contrastText,
		fontSize: 'small',
	},
	closeButton: {
		marginRight: -3,
	},
	closeIcon: {
		color: theme.palette.primary.contrastText,
		fontSize: 'small',
	},
}));

export interface AppliedRewardCardProps
{
	reward: LoyaltyDiscount;
	onRemove?: (reward: LoyaltyDiscount) => void;
}

export const AppliedRewardCard: FC<AppliedRewardCardProps> =
	({
		reward,
		onRemove,
	}) =>
	{
		const classes = useStyles();

		return <>
			<Grow
				in
			>
				<div
					className={classes.rewardCard}
				>
					<Typography
						className={classes.rewardCardDescription}
					>
						{reward.name}
					</Typography>
					{
						onRemove !== undefined &&
						<IconButton
							size="small"
							color="inherit"
							className={classes.closeButton}
							onClick={() => onRemove(reward)}
						>
							<Close
								className={classes.closeIcon}
							/>
						</IconButton>
					}
				</div>
			</Grow>
		</>;
	};
