import { useMemo } from 'react';
import { PaymentMethodDescriptor, TRADITIONAL_PAYMENT_METHOD_ID } from '../../../../../../Api/Payment/PaymentMethodDescriptor';

export function useIsPaymentMethodConfigurable(paymentMethods: PaymentMethodDescriptor[] | undefined = undefined, isBeforeOrder: boolean): boolean
{
	return useMemo(() =>
	{
		const availablePaymentMethods = isBeforeOrder
			? paymentMethods
			: paymentMethods?.filter(paymentMethod => paymentMethod.id !== TRADITIONAL_PAYMENT_METHOD_ID);

		if (availablePaymentMethods === undefined || availablePaymentMethods.length === 0)
			return false;
		else if (availablePaymentMethods.length === 1)
			return availablePaymentMethods[0].isIssuerRequired;
		else
			return true;
	}, [isBeforeOrder, paymentMethods]);
}