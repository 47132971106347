import { Button, Grid, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import { FC } from 'react';
import { Place } from '../../../../../Api/Business/Place';
import { useSortedManagerPlaces } from '../../context/place/ManagerPlacesContext';

const useStyles = makeStyles(() => ({
	button: {
		padding: 2,
        wordBreak: 'break-all',
	},
	gridItem: {
		display: 'flex',
		justifyContent: 'center',
	},
}));

interface PlaceSelectProps
{
	onPlaceSelect: (place: Place) => void
}

export const PlaceSelect: FC<PlaceSelectProps> =
	({
		 onPlaceSelect,
	 }) =>
	{
		const classes = useStyles();

		const places = useSortedManagerPlaces();

		return <Grid
			container
			justifyContent="center"
			spacing={1}
		>
			{
				places?.map(place => <Grid
					className={classes.gridItem}
					item
					key={place.id}
					xs={3}
					sm={2}
				>
					<Button
						classes={{
							root: classes.button,
						}}
						color="primary"
						fullWidth
						onClick={() => onPlaceSelect(place)}
						variant="contained"
					>
						<Typography>
							{place.name}
						</Typography>
					</Button>
				</Grid>)
			}
		</Grid>;
	};
