import { useObserver } from 'mobx-react-lite';
import { useContext, useMemo } from 'react';
import { OrderFormFieldSupport, Place } from '../../../../../Api/Business/Place';
import { TimeSchedule } from '../../../../../Api/Util/time-schedule/TimeSchedule';
import { isTrueAtInCache } from '../../../../../Api/Util/time-series/BooleanTimeSeries/BooleanTimeSeriesCache';
import { BusinessContextRef } from '../../../../Page/Business/BusinessContext';

export function useUpFrontPaymentSupport(
	place: Place,
	timeScheduleById: Map<number, TimeSchedule>,
): OrderFormFieldSupport
{
	const {clockService} = useContext(BusinessContextRef);
	const currentMinute = useObserver(() => clockService.currentMinute);

	const upFrontPaymentAllowedTimeSchedule = useMemo(() => {
		if (place.upFrontPaymentAllowedTimeScheduleId !== undefined)
			return timeScheduleById.get(place.upFrontPaymentAllowedTimeScheduleId);
	}, [place.upFrontPaymentAllowedTimeScheduleId, timeScheduleById]);

	const upFrontPaymentRequiredTimeSchedule = useMemo(() => {
		if (place.upFrontPaymentRequiredTimeScheduleId !== undefined)
			return timeScheduleById.get(place.upFrontPaymentRequiredTimeScheduleId);
	}, [place.upFrontPaymentRequiredTimeScheduleId, timeScheduleById]);

	return useMemo(() => {
		const allowedTimeSeries = upFrontPaymentAllowedTimeSchedule
			?.booleanTimeSeries;

		const isAllowed = allowedTimeSeries === undefined
			? place.paymentUpFrontSupport !== 'DISALLOWED'
			: isTrueAtInCache(allowedTimeSeries, currentMinute);

		if (isAllowed)
		{
			const requiredTimeSeries = upFrontPaymentRequiredTimeSchedule
				?.booleanTimeSeries;

			const isRequired = allowedTimeSeries === undefined
				? place.paymentUpFrontSupport === 'REQUIRED'
				: isTrueAtInCache(requiredTimeSeries, currentMinute);

			return isRequired
				? 'REQUIRED'
				: 'ALLOWED';
		}

		return 'DISALLOWED';
	}, [currentMinute, place.paymentUpFrontSupport, upFrontPaymentAllowedTimeSchedule?.booleanTimeSeries, upFrontPaymentRequiredTimeSchedule?.booleanTimeSeries]);
}