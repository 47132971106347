import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { useIsKioskMode } from '../../../../../Service/KioskService/Api/useIsKioskMode';
import { OrderBuilderStore } from '../OrderBuilderStore';
import { OrderBuilderKioskDrawer } from './KioskDrawer/OrderBuilderKioskDrawer';
import { OrderBuilderPageDialog } from './PageDialog/OrderBuilderPageDialog';

interface OrderBuilderDialogProps
{
	store: OrderBuilderStore;
}

export const OrderBuilderDialog: FC<OrderBuilderDialogProps> =
	({
		store,
	}) =>
	{
		const close = useObserver(() => store.close);
		const initialize = useObserver(() => store.initialize);
		const isEmpty = useObserver(() => store.shoppingCartStore.isEmpty);
		const isKioskMode = useIsKioskMode();

		useEffect(() =>
		{
			initialize()
				.then();
		}, [initialize]);

		useEffect(
			() =>
			{
				if (isEmpty)
				{
					// noinspection JSIgnoredPromiseFromCall
					close();
				}
			},
			[close, isEmpty],
		);

		if (isKioskMode)
		{
			return <OrderBuilderKioskDrawer
				store={store}
			/>;
		}
		else
		{
			return <OrderBuilderPageDialog
				store={store}
			/>;
		}
	};
