import { OrderDescriptor } from '../../Order/OrderDescriptor';
import { WindowEvent } from './WindowEvent';

export class OrderPaymentFailedWindowEvent extends WindowEvent
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	private readonly order: OrderDescriptor;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor(order: OrderDescriptor)
	{
		super('OrderPaymentFailed');

		this.order = order;
	}
}
