import Decimal from 'decimal.js';
import { OrderDescriptor } from '../../Api/Order/OrderDescriptor';
import { LocalizedPrice } from '../../Component/Page/Manager/order-handler/BillManager/Bill/LocalizedPrice';

export function getTotalPriceOfOrder(order: OrderDescriptor): LocalizedPrice
{
	const priceWithoutFee = new Decimal(order.paymentPrice ?? 0);

	return {
		currencyCode: order.currency,
		price: order.paymentFee !== undefined && order.paymentFee > 0
			? priceWithoutFee.add(order.paymentFee)
			: priceWithoutFee,
	};
}
