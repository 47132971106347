import { Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { TRADITIONAL_PAYMENT_METHOD_ID } from '../../../../../../Api/Payment/PaymentMethodDescriptor';
import { useTranslate } from '../../../../../../Bridge/Localization/useTranslate';
import { usePaymentMethodAvailabilityContext } from '../../../availability/context/PaymentMethodAvailabilityContext';
import { usePaymentMethodContext } from '../../context/PaymentMethodContext';
import { useSelectedPaymentMethodDescription } from './useSelectedPaymentMethodDescription';

const useStyles = makeStyles((theme: Theme) => ({
	descriptionText: {
	},
	titleText: {
		marginRight: `${theme.spacing(0.5)}px !important`,
	},
	root: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		width: '100%',
	},
}));

export const PaymentMethodSummary: FC =
	() =>
	{
		const paymentMethods = usePaymentMethodAvailabilityContext();
		const {isBeforeOrder} = usePaymentMethodContext();

		const selectedPaymentMethodDescription = useSelectedPaymentMethodDescription();

		const classes = useStyles();

		const translate = useTranslate();

		const hasSupportedPaymentMethods = useMemo(() =>
		{
			if (paymentMethods === undefined)
				return false;
			else if (isBeforeOrder)
				return paymentMethods.length > 0;
			else
				return paymentMethods
					.filter(paymentMethod => paymentMethod.id !== TRADITIONAL_PAYMENT_METHOD_ID)
					.length > 0;
		}, [isBeforeOrder, paymentMethods]);

		const descriptionText = useMemo(() =>
		{
			if (selectedPaymentMethodDescription !== undefined)
				return selectedPaymentMethodDescription;
			else if (hasSupportedPaymentMethods)
				return undefined;
			else
				return translate('Payment-Method-None');
		}, [hasSupportedPaymentMethods, selectedPaymentMethodDescription, translate]);

		return <div className={classes.root}>
			<Typography className={classes.titleText} color="textPrimary" variant="body1">
				{translate('Payment-Method')}
			</Typography>
			<Typography className={classes.descriptionText} color="textSecondary" variant="body1">
				{descriptionText}
			</Typography>
		</div>;
	};