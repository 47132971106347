import { Box } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { CenteredPageContent } from '../../../../../UI/CenteredPageContent';
import { KioskDrawer } from '../../../../../UI/kiosk_drawer/KioskDrawer';
import { OrderBuilder } from '../../OrderBuilder';
import { OrderBuilderStore } from '../../OrderBuilderStore';

interface OrderBuilderKioskDrawerProps
{
	store: OrderBuilderStore;
}

export const OrderBuilderKioskDrawer: FC<OrderBuilderKioskDrawerProps> =
	({
		store,
	}) =>
	{
		const close = useObserver(() => store.close);

		return <KioskDrawer
			onClose={close}
			bottomContent={
				() =>
					<CenteredPageContent>
						<Box
							paddingY={8}
							style={{
								width: 'min(850px, 100vw)',
								justifyContent: 'center',
								display: 'flex',
								flexDirection: 'column',
								height: '80vh',
							}}
						>
							<OrderBuilder
								store={store}
								flexGrow
							/>
						</Box>
					</CenteredPageContent>
			}
			style={{
				minHeight: '80vh'
			}}
		/>;
	};
