import { useCallback, useLayoutEffect, useState } from 'react';

export interface DimensionObject
{
	width: number;
	height: number;
	top: number;
	left: number;
	x: number;
	y: number;
	right: number;
	bottom: number;
}

export type UseDimensionsHook = [
	(node: HTMLElement) => void,
	DimensionObject | undefined,
	HTMLElement
];

export interface UseDimensionsArgs {
	liveMeasure?: boolean;
}

export function useDimensions(
	{
		liveMeasure = true,
	}: UseDimensionsArgs = {},
): UseDimensionsHook
{
	const [dimensions, setDimensions] = useState();
	const [node, setNode] = useState(null);

	const ref = useCallback(node => {
		setNode(node);
	}, []);

	useLayoutEffect(() => {
		if (node) {
			const measure = () =>
				window.requestAnimationFrame(() =>
					setDimensions(getDimensionObject(node!))
				);
			measure();
			setTimeout(() => measure(), 0);

			if (liveMeasure) {
				window.addEventListener("resize", measure);
				window.addEventListener("scroll", measure);

				return () => {
					window.removeEventListener("resize", measure);
					window.removeEventListener("scroll", measure);
				};
			}
		}
	}, [liveMeasure, node]);

	return [ref, dimensions, node!];
}

function getDimensionObject(node: HTMLElement): DimensionObject {
	const rect = node.getBoundingClientRect();

	return {
		width: rect.width,
		height: rect.height,
		top: "x" in rect ? rect.x : (rect as DOMRect).top,
		left: "y" in rect ? rect.y : (rect as DOMRect).left,
		x: "x" in rect ? rect.x : (rect as DOMRect).left,
		y: "y" in rect ? rect.y : (rect as DOMRect).top,
		right: rect.right,
		bottom: rect.bottom
	};
}