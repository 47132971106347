import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import LocalOfferOutlined from '@material-ui/icons/LocalOfferOutlined';
import { FC } from 'react';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';

const useStyles = makeStyles(theme => ({
	card: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	},
	cardContent: {
		alignItems: 'center',
		display: 'flex',
		gap: theme.spacing(2),
	},
	icon: {
		alignSelf: 'center',
	},
}));

export const ComoNoRewardsCard: FC =
	() =>
	{
		const translate = useTranslate();

		const classes = useStyles();

		return <Card
			className={classes.card}
		>
			<CardContent
				className={classes.cardContent}
			>
				<LocalOfferOutlined
					className={classes.icon}
					fontSize="large"
				/>
				<Typography>
					{translate('Client-Loyalty-Como-No-Redeemable-Rewards')}
				</Typography>
			</CardContent>
		</Card>;
	};
