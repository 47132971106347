import { OrderDescriptor } from '../../../../Api/Order/OrderDescriptor';
import { postAny } from '../../../../Util/Api';

export function moveOrder(order: OrderDescriptor, newPlaceId: number): Promise<OrderDescriptor>
{
	return postAny(
		'/waiter/business/order/transferToPlace',
		{
			orderId: order.id,
			targetPlaceId: newPlaceId,
		},
	);
}
