import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Decimal from 'decimal.js';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { ExternalBill } from '../../../../../../../../Api/ExternalBill/ExternalBill';
import { useLocalizer } from '../../../../../../../../Bridge/Localization/useLocalizer';
import { useTranslate } from '../../../../../../../../Bridge/Localization/useTranslate';
import { useMemoizedDecimal } from '../../../../../../../../Util/decimal/useMemoizedDecimal';
import { nonNullish } from '../../../../../../../../Util/nonNullish';
import { CardBorderRadius } from '../../../../../../../../Util/Theme';
import { usePaymentPriceContext } from '../../../../../../../UI/payment/price/context/PaymentPriceContext';
import PriceTag from '../../../../../../../UI/PriceTag/PriceTag';
import { BasicShoppingCartConfiguration } from '../../../../../ShoppingCart/Configuration/BasicShoppingCartConfiguration';
import { useExternalBillContext } from '../../../context/external-orders/ExternalOrderLinessContext';

const useStyles = makeStyles(() => ({
	accordion: {
		backgroundColor: '#FFF8',
		borderRadius: `${CardBorderRadius}px !important`,
		overflowY: 'auto',
	},
	accordionDetails: {
		flexDirection: 'column',
		overflowY: 'hidden',
		padding: '0px !important',
	},
	accordionSummaryContent: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
	},
	normalCursor: {
		cursor: 'default !important',
	},
}));

interface ExternalBillPanelProps
{
	className?: string;
	collapsible?: boolean;
	expanded?: boolean;
	onExpansion?: (expanded: boolean) => void;
	externalBill: ExternalBill;
	title?: string;
}

export const ExternalBillPanel: FC<ExternalBillPanelProps> =
	({
		className,
		collapsible = true,
		expanded,
		onExpansion,
		externalBill,
		title,
	}) =>
	{
		const localizer = useLocalizer();
		const translate = useTranslate();
		const {currency, tip, serviceFee, rawAdditiveTaxPerTaxGroupId, total} = usePaymentPriceContext();
		const {lines} = useExternalBillContext();

		const totalPriceExcludingTip = useMemoizedDecimal(
			total
				.sub(tip),
		);

		const totalAdditiveTax = useMemo(() =>
		{
			return Array
				.from(rawAdditiveTaxPerTaxGroupId.values())
				.reduce(
					(subTotal, taxAmount) =>
						subTotal.add(taxAmount.toDecimalPlaces(currency.decimalPlaces)),
					new Decimal(0),
				);
		}, [currency.decimalPlaces, rawAdditiveTaxPerTaxGroupId]);

		const classes = useStyles();

		const summary =
			useMemo(
				() => {
					return <AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						classes={{
							root: !collapsible && expanded
								? classes.normalCursor
								: undefined,
						}}
						IconButtonProps={{
							disabled: !collapsible && expanded,
							style: {
								width: !collapsible && expanded ? 0 : 48,
								paddingLeft: !collapsible && expanded ? 4 : 12,
								paddingRight: !collapsible && expanded ? 0 : 12,
								overflow: 'hidden',
								opacity: !collapsible && expanded ? 0 : 1,
								transitionTimingFunction: 'ease',
								transition: 'width 300ms, padding-left 300ms, padding-right 300ms, opacity 300ms',
							},
						}}
					>
						<div className={classes.accordionSummaryContent}>
							<Typography>
								{title ?? translate('Orders')}
							</Typography>
							{
								totalPriceExcludingTip !== undefined &&
								<PriceTag
									color="textSecondary"
									currencyCode={currency.code}
									localizer={localizer}
									price={totalPriceExcludingTip}
									variant="body2"
								/>
							}
						</div>
					</AccordionSummary>;
				},
				[
					classes.accordionSummaryContent,
					classes.normalCursor,
					collapsible,
					currency.code,
					expanded,
					localizer,
					title,
					totalPriceExcludingTip,
					translate,
				]
			);
		const orderLineComponents =
			useMemo(
				() =>
					(lines ?? [])
						.map(
							line =>
								<BasicShoppingCartConfiguration
									currencyCode={externalBill.currencyCode}
									labelText={`${line.quantity}x ${line.description}`}
									price={new Decimal(line.unitPrice)}
									leftMargin={16}
								/>,
						),
				[
					externalBill,
					lines,
				],
			);
		const feeComponent =
			useMemo(
				() =>
				{
					if (serviceFee.greaterThan(0))
						return <BasicShoppingCartConfiguration
							price={serviceFee}
							currencyCode={currency.code}
							labelText={translate('Service-Fee')}
							leftMargin={16}
						/>;
				},
				[
					currency.code,
					serviceFee,
					translate,
				]
			);
		const taxComponent =
			useMemo(
				() =>
				{
					if (totalAdditiveTax.greaterThan(0))
						return <BasicShoppingCartConfiguration
							price={totalAdditiveTax}
							currencyCode={currency.code}
							labelText={translate('Taxes')}
							leftMargin={16}
						/>;
				},
				[currency.code, totalAdditiveTax, translate]
			);
		const details =
			useMemo(
				() =>
					<AccordionDetails
						className={classes.accordionDetails}
					>
						{orderLineComponents}
						{feeComponent}
						{taxComponent}
					</AccordionDetails>,
				[classes.accordionDetails, feeComponent, orderLineComponents, taxComponent],
			);
		const panelClassName =
			useMemo(
				() =>
					nonNullish(className)
						? `${classes.accordion} ${className}`
						: classes.accordion,
				[
					className,
					classes.accordion,
				]
			);

		return <Accordion
			className={panelClassName}
			expanded={expanded}
			onChange={(_, expanded) => onExpansion?.(expanded)}
		>
			{summary}
			<Divider />
			{details}
		</Accordion>;
	};
