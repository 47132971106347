import { Place, PlaceProfile } from '../../../../../Api/Business/Place';
import { getPlainList } from '../../../../../Util/Api';

export function getPlaces(params: GetPlacesParams): Promise<Place[]>
{
	return getPlainList('/waiter/business/places', params, PlaceProfile);
}

interface GetPlacesParams
{
	businessId: number
}