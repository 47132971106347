import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { Repeat } from 'mdi-material-ui';
import * as React from 'react';
import { useMemo } from 'react';
import { MaxNumberOfOrdersPerPlaceSessionMetadata } from '../../../../../Api/Order/OrderRestriction/MaxNumberOfOrdersPerPlaceSessionMetadata';
import { useScreenWidth } from '../../../../../Util/Hooks/useScreenWidth';

const useStyles = makeStyles<Theme>(() => ({
	Root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
}));

interface MenuTopBarAppendixMaxNumberOfOrdersPerPlaceSessionProps
{
	metadata: MaxNumberOfOrdersPerPlaceSessionMetadata;
}

export const MenuTopBarAppendixMaxNumberOfOrdersPerPlaceSession = ({metadata}: MenuTopBarAppendixMaxNumberOfOrdersPerPlaceSessionProps) =>
{
	const classes = useStyles();
	const screenWidth = useScreenWidth();
	const isSmallScreen = useMemo(() => screenWidth === 'xs', [screenWidth]);

	if (metadata.ordersPlaced === undefined || metadata.ordersAllowed === undefined)
	{
		return null;
	}
	else
	{
		return <Box
			className={classes.Root}
		>
			<Repeat
				fontSize={isSmallScreen ? 'small' : 'medium'}
				color={metadata.ordersPlaced >= metadata.ordersAllowed ? 'error' : 'inherit'}
			/>
			&nbsp;
			<Typography
				variant={isSmallScreen ? 'caption' : 'body1'}
				color={metadata.ordersPlaced >= metadata.ordersAllowed ? 'error' : 'inherit'}
			>
				{
					`${metadata.ordersPlaced + 1}/${metadata.ordersAllowed}`
				}
			</Typography>
		</Box>;
	}
};
