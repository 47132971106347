import { MuiThemeProvider } from '@material-ui/core/styles';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext, useMemo } from 'react';
import { useBrandingService } from '../../Component/branding-service/BrandingServiceProvider';
import { BrowserRootLayoutPolyfillContextRef } from '../../Component/BrowserRootLayoutPolyfiller/BrowserRootLayoutPolyfillContextProvider';
import { BrandingService } from '../../Service/BrandingInformation/BrandingService';
import { useIsKioskMode } from '../../Service/KioskService/Api/useIsKioskMode';
import theme from '../Theme';

interface ThemeProviderProps
{
	overrideBrandingService?: BrandingService;
}

export const ThemeProvider: FC<ThemeProviderProps> =
	(
		{
			overrideBrandingService,
			children,
		},
	) =>
	{
		const {lowPerformanceMode} = useContext(BrowserRootLayoutPolyfillContextRef);

		const brandingService = useBrandingService();
		const effectiveBrandingService = useMemo(
			() => overrideBrandingService ?? brandingService,
			[overrideBrandingService, brandingService]
		);
		const isKioskMode = useIsKioskMode();
		const appTheme =
			useObserver(
				() =>
					theme(
						effectiveBrandingService,
						lowPerformanceMode,
						isKioskMode
					)
			);

		return <MuiThemeProvider
			theme={appTheme}
		>
			{children}
		</MuiThemeProvider>;
	};