import { useMemo } from 'react';
import { PaymentIssuer } from '../../../../../../Api/Payment/PaymentIssuer';

export function useMappedPaymentIssuers(paymentIssuers: PaymentIssuer[]): Map<string, PaymentIssuer>
{
	return useMemo(() =>
	{
		return paymentIssuers
			.reduce((result, current) =>
			{
				result.set(current.id, current);

				return result;
			}, new Map<string, PaymentIssuer>());
	}, [paymentIssuers]);
}