import { Avatar, Card, Checkbox, Collapse, FormControlLabel, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as chroma from 'chroma-js';
import InformationIcon from 'mdi-material-ui/Information';
import * as React from 'react';
import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { useLocalizer } from '../../../../../../Bridge/Localization/useLocalizer';
import { ActionButton } from '../../../../../UI/action-button/ActionButton';

const useStyle = makeStyles<Theme, { showError: boolean }>((theme: Theme) => ({
	acknowledgementButton: {
		whiteSpace: 'nowrap',
		width: 'min-content',
	},
	acknowledgementRoot: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'flex-end',
		marginLeft: 0,
		marginTop: theme.spacing(2),

		marginRight: -8,
		marginBottom: -8,
	},
	card: ({showError}) => ({
		backgroundColor: showError ? chroma.blend(theme.palette.primary.main, theme.palette.error.main, 'overlay').css() : theme.palette.primary.main,
		// cursor: 'pointer',
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(2),
	}),
	contentRoot: {
		alignItems: 'center',
		display: 'flex',
	},
	labelRoot: {
		flex: '1 1 auto',
		textAlign: 'justify',
	},
	labelText: {
		color: theme.palette.primary.contrastText,
	},
	icon: {
		color: theme.palette.primary.contrastText,
		height: '100%',
		width: '100%',
	},
	iconRoot: {
		backgroundColor: 'transparent',
		marginRight: theme.spacing(2),
	},
	rememberControl: {
		marginLeft: 0,
		marginRight: 8,
		border: `1px dashed ${theme.palette.primary.contrastText}`,
		borderRadius: 4,
		paddingTop: 2,
		paddingBottom: 3,
		paddingLeft: 7,
		paddingRight: 7,
	},
	rememberCheckbox: {
		color: `${theme.palette.primary.contrastText} !important`,
		padding: `0px ${theme.spacing(1)}px 0px 0px`,
	},
	rememberLabel: {
		color: theme.palette.primary.contrastText,
	},
	validationText: {
		fontSize: '0.75rem !important',
		lineHeight: '1.66 !important',
		marginTop: '10px !important',
		textAlign: 'right',
		marginRight: '-8px !important',
		marginBottom: '-13px !important',
	},
}));

interface RequestUserAcknowledgmentCardProps
{
	allowInteraction?: boolean;
	label: ReactNode;
	showValidation: boolean;
	setValue: (value: boolean, remember: boolean) => void;
	value: boolean;
}

export const RequestUserAcknowledgmentCard: FC<RequestUserAcknowledgmentCardProps> =
	(
		{
			allowInteraction,
			label,
			showValidation,
			value,
			setValue,
		},
	) =>
	{
		const localizer = useLocalizer();

		const [remember, setRemember] = useState(false);

		const showError = useMemo(() => showValidation && !value, [showValidation, value]);

		const classes = useStyle({showError});

		const handleChange = useCallback(() => setValue(!value, remember), [remember, setValue, value]);

		const iconComponent = useMemo(() => {
			return <Avatar className={classes.iconRoot}>
				<InformationIcon className={classes.icon} />
			</Avatar>;
		}, [classes.icon, classes.iconRoot]);

		const labelComponent = useMemo(() => {
			const content = typeof label === 'string'
				? <Typography className={classes.labelText}>{label}</Typography>
				: label;

			return <div className={classes.labelRoot}>{content}</div>;
		}, [classes.labelRoot, classes.labelText, label]);

		const validationComponent = useMemo(() => {
			return <Collapse in={showError} unmountOnExit>
				<Typography className={classes.validationText} color="error">
					{localizer.translate('User-Acknowledgement-Read-Validation')}
				</Typography>
			</Collapse>;

		}, [classes.validationText, localizer, showError]);

		const rememberCheckbox = useMemo(() => {
			return <FormControlLabel
				className={classes.rememberControl}
				control={<Checkbox className={classes.rememberCheckbox} checked={remember} disableRipple onChange={() => setRemember(prev => !prev)} />}
				label={<Typography className={classes.rememberLabel}>{localizer.translate('Generic-Remember')}</Typography>}
			/>;
		}, [classes.rememberCheckbox, classes.rememberControl, classes.rememberLabel, localizer, remember]);

		const acknowledgementButton = useMemo(() => {
			return <ActionButton
				className={classes.acknowledgementButton}
				onClick={handleChange}
				size="small"
				text={localizer.translate('User-Acknowledgement-Read')}
				color={{
					buttonColor: 'primary-contrast-text',
				}}
			/>;
		}, [classes.acknowledgementButton, handleChange, localizer]);

		return <Card className={classes.card}>
			<div className={classes.contentRoot}>
				{iconComponent}
				{labelComponent}
			</div>
			<Collapse in={allowInteraction} unmountOnExit>
				<div className={classes.acknowledgementRoot}>
					{rememberCheckbox}
					{acknowledgementButton}
				</div>
			</Collapse>
			{validationComponent}
		</Card>;
	};