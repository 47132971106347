import * as React from 'react';
import { createContext, FC, useContext, useMemo } from 'react';
import { useWebClient } from '../../Bridge/Client/WebClientProvider';
import { LocationService } from '../../Service/Location/LocationService';
import { IllegalStateException } from '../../Util/Exception/IllegalStateException';

interface Context
{
	locationService: LocationService
}

const ContextRef = createContext<Context | undefined>(undefined);

export const LocationServiceProvider: FC =
	(
		{
			children,
		},
	) =>
	{
		const client = useWebClient(true);
		const locationService = useMemo(() => new LocationService(client, undefined, false), [client]);
		return <ContextRef.Provider
			value={useMemo(() => ({
				locationService,
			}), [locationService])}
		>
			{children}
		</ContextRef.Provider>;
	};

export function useLocationService(): LocationService
{
	const context = useContext(ContextRef);
	return useMemo(
		() => {
			if (context === undefined)
				throw new IllegalStateException(
					'Attempting to use useLocationService outside LocationServiceProvider'
				);
			return context.locationService;
		},
		[context],
	);
}