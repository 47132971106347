import { Collapse } from '@material-ui/core';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { AdyenCheckoutPaymentSettings } from '../../../../adyen/checkout/settings/AdyenCheckoutPaymentSettings';
import { usePaymentIssuerContext } from '../../../../issuer/context/PaymentIssuerContext';
import { PaymentIssuerSelectionForm } from '../../../../issuer/form/PaymentIssuerSelectionForm';
import { usePaymentMethodContext } from '../../../context/PaymentMethodContext';

interface PaymentMethodSettingsProps
{
	adyenRootClassName?: string;
	issuerRootClassName?: string;
}

export const PaymentMethodSettings: FC<PaymentMethodSettingsProps> =
	({
		adyenRootClassName,
		issuerRootClassName,
	}) =>
	{
		const {selectedPaymentMethod} = usePaymentMethodContext();
		const {paymentIssuers} = usePaymentIssuerContext();

		const selectedAdyenCheckoutPaymentMethodType = useMemo(
			() => selectedPaymentMethod?.butlarooPayPaymentMethod?.type,
			[selectedPaymentMethod?.butlarooPayPaymentMethod?.type],
		);

		const showAdyenCheckoutPaymentSettings = useMemo(
			() => selectedAdyenCheckoutPaymentMethodType !== undefined && paymentIssuers.length === 0,
			[paymentIssuers.length, selectedAdyenCheckoutPaymentMethodType],
		);

		const showIssuerSelection = useMemo(
			() => !showAdyenCheckoutPaymentSettings && selectedPaymentMethod?.isIssuerRequired && paymentIssuers.length > 0,
			[paymentIssuers.length, selectedPaymentMethod?.isIssuerRequired, showAdyenCheckoutPaymentSettings],
		);

		return <>
			<Collapse in={showIssuerSelection} unmountOnExit>
				<PaymentIssuerSelectionForm
					className={issuerRootClassName}
				/>
			</Collapse>
			<Collapse in={showAdyenCheckoutPaymentSettings}>
				<AdyenCheckoutPaymentSettings className={adyenRootClassName} />
			</Collapse>
		</>;
	};