import { Theme } from '@material-ui/core/styles';
import { Color as ButlColor } from '../../../Api/Other/Color';
import { ButtonPresetColor } from './ButtonPresetColor';

export type MuiButtonColor = 'primary' | 'secondary'

export type ButtonColor = ButlColor | ButtonPresetColor | string;

export type ButtonColorProp = Exclude<ButtonColor, 'error' | 'error-contrast-text'>;

export function extractMuiButtonColor(color: ButtonColor | undefined): MuiButtonColor | undefined
{
	if (color === 'primary' || color === 'secondary')
		return color;
	else
		return undefined;
}

export function toCss(color: ButtonColor, theme: Theme): string
export function toCss(color: undefined, theme: Theme): undefined
export function toCss(color: ButtonColor | undefined, theme: Theme): string | undefined
{
	if (color === undefined)
		return undefined;
	else if (color === 'primary')
		return theme.palette.primary.main;
	else if (color === 'secondary')
		return theme.palette.secondary.main;
	else if (color === 'error')
		return theme.palette.error.main;
	else if (color === 'primary-contrast-text')
		return theme.palette.primary.contrastText;
	else if (color === 'secondary-contrast-text')
		return theme.palette.secondary.contrastText;
	else if (color === 'error-contrast-text')
		return theme.palette.error.contrastText;
	else if (color instanceof ButlColor)
		return color.css;
	else
		return color;
}