import { CardContent, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AccountCircle, ExitToApp, History } from '@material-ui/icons';
import { Tools } from 'mdi-material-ui';
import { IObservableValue } from 'mobx';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FunctionComponent, useContext, useState } from 'react';
import { ContractingEntity } from '../../../Api/contracting_entity/ContractingEntity';
import { useTranslate } from '../../../Bridge/Localization/useTranslate';
import { DefaultContractingEntity } from '../../../Constants/DefaultContractingEntity';
import { AppVersionContext } from '../../app-version/AppVersionContext';
import { ContractingEntityContactInformationDialog } from '../../contracting_entity_contact_information/ContractingEntityContactInformationDialog';
import { PoweredByButlaroo } from '../../UI/PoweredByButlaroo/PoweredByButlaroo';
import { panelGradient } from '../Business/BusinessConstants';

const useStyles = makeStyles(theme => ({
    contactLink: {
        display: 'inline',
        cursor: 'pointer',
        color: theme.palette.text.secondary,

        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
}));

interface NavigationMenuProps
{
    contractingEntity?: ContractingEntity
    userProfileHidden: boolean
    orderHistoryHidden: boolean
    onCommandLeave?: () => void
    openManagerPanel: () => void
    openHistory: () => void
    openProfile: () => void
    isMenuOpen: IObservableValue<boolean>
}

export const NavigationMenu: FunctionComponent<NavigationMenuProps> =
    (
        {
            contractingEntity = DefaultContractingEntity,
            userProfileHidden,
            orderHistoryHidden,
            onCommandLeave,
            openManagerPanel,
            openHistory,
            openProfile,
            isMenuOpen,
        },
    ) =>
    {
        const appVersion = useContext(AppVersionContext);
        const translate = useTranslate();
        
        const classes = useStyles();

        const [showContactDialog, setShowContactDialog] = useState(false);

        return useObserver(() => <Drawer
            anchor="left"
            open={isMenuOpen.get()}
            onOpen={() => isMenuOpen.set(true)}
            onClose={() => isMenuOpen.set(false)}
            style={{
                zIndex: 200,
            }}
            PaperProps={{
                style: {
                    backgroundImage: panelGradient,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignContent: 'stretch',
                    width: '80%',
                    maxWidth: 320,
                    minWidth: 'fit-content',
                },
            }}
        >
            <List
                id="navigation-menu"
                style={{
                    flex: '0 0 auto',
                }}
            >
                {
                    !userProfileHidden
                        ?
                        <ListItem
                            button
                            divider
                            onClick={() => openProfile()}
                        >
                            <ListItemIcon>
                                <AccountCircle />
                            </ListItemIcon>
                            <ListItemText
                                primary={translate('Profile')}
                            />
                        </ListItem>
                        :
                        <></>
                }
                {
                    !orderHistoryHidden
                        ?
                        <ListItem
                            button
                            divider
                            onClick={() => openHistory()}
                        >
                            <ListItemIcon>
                                <History />
                            </ListItemIcon>
                            <ListItemText
                                primary={translate('Client-OrderHistory-Title')}
                            />
                        </ListItem>
                        :
                        <></>
                }
                {
                    appVersion === 'V2' &&
                    <ListItem
                        button
                        divider
                        onClick={() => openManagerPanel()}
                    >
                        <ListItemIcon>
                            <Tools />
                        </ListItemIcon>
                        <ListItemText
                            primary={translate('Navigation-Manager-Panel')}
                        />
                    </ListItem>
                }
            </List>
            <div
                style={{
                    flex: '0 0 auto',
                    marginTop: 'auto',
                }}
            >
                <CardContent
                    style={{
                        textAlign: 'right',
                        paddingBottom: 8,
                    }}
                >
                    <PoweredByButlaroo
                        contractingEntity={contractingEntity}
                        imageLink
                        utmMedium="nav"
                    />
                    <Typography
                        variant="body1"
                        color="textSecondary"
                        className={classes.contactLink}
                        onClick={
                            () =>
                                setShowContactDialog(true)
                        }
                    >
                        Contact
                    </Typography>
                </CardContent>
                {
                    onCommandLeave &&
                    <>
                        <Divider
                            style={{
                                flex: '0 0 auto',
                            }}
                        />
                        <List
                            style={{
                                flex: '0 0 auto',
                            }}
                        >
                            <ListItem
                                button
                                onClick={() => onCommandLeave()}
                            >
                                <ListItemIcon>
                                    <ExitToApp />
                                </ListItemIcon>
                                <ListItemText
                                    primary={translate('Client-Order-Leave')}
                                />
                            </ListItem>
                        </List>
                    </>
                }
            </div>
            <ContractingEntityContactInformationDialog
                contractingEntity={contractingEntity}
                onClose={
                    () =>
                        setShowContactDialog(false)
                }
                open={showContactDialog}
            />
        </Drawer>);
    };
