import { ButtonBase, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { FC } from 'react';
import { useToggleOrderListSortDirection } from '../context/sort-direction/OrderListSortDirectionProvider';
import { SortDirectionIndicator } from './SortDirectionIndicator';

const useStyles = makeStyles(theme => ({
	root: {
		marginTop: 8,
		marginLeft: 8,
		marginRight: 8,
		marginBottom: 0,

		borderRadius: theme.shape.borderRadius,
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1) - 8,
		},
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(2) - 8,
		},
		userSelect: 'none',

		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',

		'& > :not(:first-child)': {
			marginLeft: 8,
		},
	},
	title: {
		color: theme.palette.common.white,
	},
}));

interface OrderListHeaderProps
{
	title: string | undefined
}

export const OrderListHeader: FC<OrderListHeaderProps> =
	(
		{
			title,
		},
	) =>
	{
		const classes = useStyles();
		const toggleSortDirection = useToggleOrderListSortDirection();
		if (title === undefined)
			return null;
		return <ButtonBase
			className={classes.root}
			onClick={toggleSortDirection}
		>
			<Typography
				align="center"
				classes={{
					root: classes.title,
				}}
			>
				{title}
			</Typography>
			<SortDirectionIndicator />
		</ButtonBase>
	};