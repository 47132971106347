import { Box, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import 'context-filter-polyfill';
import * as React from 'react';
import { CSSProperties, FC, Fragment, ReactNode, useMemo } from 'react';
import { Color } from '../../../Api/Other/Color';
import { getFileUrl } from '../../../Util/Api/Resources/getFileUrl';
import { useIsSmallScreen } from '../../../Util/Hooks/useIsSmallScreen';
import { useResizeObserver } from '../../../Util/Hooks/useResizeObserver';
import { useObjectUrlCacheEntry } from '../../../Util/ObjectUrlCache/Api/useObjectUrlCacheEntry';
import { ImageFit, toObjectFitCss } from '../Image/ImageFit';
import { getMediaButtonImageBackgroundColor } from '../media_button/api/getMediaButtonImageBackgroundColor';
import { useEffectiveIsDarkTextColor } from '../media_button/bar/useEffectiveIsDarkTextColor';
import { useEffectiveTextColor } from '../media_button/bar/useEffectiveTextColor';
import { normalizeMediaButtonHeight } from '../media_button/normalizeMediaButtonHeight';
import { normalizeMediaButtonWidth } from '../media_button/normalizeMediaButtonWidth';
import { getVisibleRectangleOnTargetContainer } from './api/getVisibleRectangleOnTargetContainer';

export const MediaButtonV2BorderRadius = 30;
export const MediaButtonV2BorderRadiusOnSmallScreen = 15;
export const MediaButtonV2Shadow = 'rgba(0, 0, 0, 0.1) 0px 4px 12px';
const MediaButtonV2ImageAspectRatio = 4 / 3;
const MediaButtonV2BackdropImageRatio = 1 / 7;
const textContainerSmallScreenHeight = 105;
const textContainerNormalScreenHeight = 120;

const useStyles =
	makeStyles(
		theme => ({
			root: {
				borderRadius: MediaButtonV2BorderRadius,
				backgroundColor: 'white',
				position: 'relative',
				'&:hover, &:focus-visible': {
					zIndex: 1,
				},
				alignItems: 'stretch',
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
			},
			rootSmallScreen: {
				borderRadius: MediaButtonV2BorderRadiusOnSmallScreen,
			},
			imageContainer: {
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				borderTopLeftRadius: MediaButtonV2BorderRadius,
				borderTopRightRadius: MediaButtonV2BorderRadius,
				width: '100%',
				aspectRatio: '4/3',
			},
			imageContainerSmallScreen: {
				borderTopLeftRadius: MediaButtonV2BorderRadiusOnSmallScreen,
				borderTopRightRadius: MediaButtonV2BorderRadiusOnSmallScreen,
			},
			textContainer: {
				position: 'relative',
				height: textContainerNormalScreenHeight,
			},
			textContainerSmallScreen: {
				height: textContainerSmallScreenHeight,
			},
			textContainerWithoutImage: {
				position: 'absolute',
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			},
			textBackgroundContainer: {
				position: 'absolute',
				top: 0,
				right: 0,
				bottom: 0,
				left: 0,
				borderBottomLeftRadius: MediaButtonV2BorderRadius,
				borderBottomRightRadius: MediaButtonV2BorderRadius,
			},
			textBackgroundContainerSmallScreen: {
				borderBottomLeftRadius: MediaButtonV2BorderRadiusOnSmallScreen,
				borderBottomRightRadius: MediaButtonV2BorderRadiusOnSmallScreen,
			},
			badges: {
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				flexWrap: 'wrap',
				gap: theme.spacing(0.5),
			},
			text: {
				position: 'absolute',
				top: 0,
				right: 0,
				bottom: 0,
				left: 0,
				padding: 16,
				display: 'flex',
				flexDirection: 'column',
			},
			centeredText: {
				alignItems: 'center',
			},
			title: {
				textAlign: 'center',
				fontSize: '1.2rem',
				display: '-webkit-box',
				'-webkit-line-clamp': 2,
				lineClamp: 2,
				'-webkit-box-orient': 'vertical',
				overflow: 'hidden',
				lineHeight: 1.4,
			},
			titleContainer: {
				flex: 1,
				display: 'flex',
				justifyContent: 'center',
				overflow: 'hidden',
			},
			secondaryTitleContainer: {
				textAlign: 'center',
			},
			secondaryTitle: {
				fontSize: '1.2rem',
				fontWeight: 600,
			},
			smallTitle: {
				fontSize: '1rem',
				fontWeight: 500,
			},
		})
	);

export interface MediaButtonV2Props
{
	primaryBadges?: ReactNode[];
	secondaryBadges?: ReactNode[];
	image?: string;
	imageFit?: ImageFit;
	imageBackgroundColor?: Color | undefined;
	onClick?: () => void;
	secondaryTitle?: React.ReactNode;
	style?: CSSProperties;
	title: string;
	textBackdropColor?: Color | undefined;
	textColor?: Color | undefined;
	isTextContrastColorDark?: boolean;
	disabled?: boolean;
}

export const MediaButtonV2: FC<MediaButtonV2Props> =
	({
		primaryBadges,
		secondaryBadges,
		image,
		imageFit,
		imageBackgroundColor,
		onClick,
		secondaryTitle,
		style,
		title,
		textBackdropColor,
		textColor,
		isTextContrastColorDark,
		disabled,
	}) =>
	{
		const classes = useStyles();
		const hasNoImage = image === undefined;
		const finalIsTextContrastColorDark =
			useEffectiveIsDarkTextColor(
				isTextContrastColorDark,
				textColor,
				textBackdropColor
			);
		const finalTextBackdropColor =
			useMemo(
				() =>
					textBackdropColor === undefined
						? finalIsTextContrastColorDark
							? new Color(
								255,
								255,
								255,
								0.7
							)
							: new Color(
								0,
								0,
								0,
								0.7
							)
						: textBackdropColor,
				[finalIsTextContrastColorDark, textBackdropColor]
			);
		const shouldComputeTextBackground = finalTextBackdropColor.a < 1;
		const effectiveTextColor =
			useEffectiveTextColor(
				finalIsTextContrastColorDark
					? 'dark'
					: 'light',
				textColor
			);
		const secondaryTextColor =
			textBackdropColor === undefined
				? effectiveTextColor.withAlpha(0.45)
				: effectiveTextColor;
		const isSmallScreen = useIsSmallScreen();
		const [ref, element] = useResizeObserver();
		const elementHeight = element?.contentRect.height;
		const elementWidth = element?.contentRect.width;
		const {
			result: imageUrl,
		} =
			useObjectUrlCacheEntry(
				`I:${image ?? 'undefined'}`,
				async () =>
				{
					if (image !== undefined && elementWidth !== undefined && elementHeight !== undefined)
					{
						const normalizedHeight = normalizeMediaButtonHeight(elementHeight);
						const normalizedWidth = normalizeMediaButtonWidth(elementWidth);

						const imageResponse = await fetch(
							getFileUrl(`/image/${normalizedWidth}/${normalizedHeight}/Contain/Medium${image}`),
						);

						const imageBlob = await imageResponse.blob();

						return URL.createObjectURL(imageBlob);
					}
				},
				[
					elementWidth,
					image,
				]
			);
		const {
			result: textContainerBackgroundImageUrl
		} =
			useObjectUrlCacheEntry(
				`TCB:${image ?? 'undefined'}:${shouldComputeTextBackground}`,
				async () =>
				{
					return new Promise(
						(resolve) =>
						{
							if (shouldComputeTextBackground
								&& elementWidth !== undefined
								&& imageUrl !== undefined)
							{
								const canvas = document.createElement('canvas');
								const ctx = canvas.getContext('2d');
								const imageElement = new Image();
								imageElement.addEventListener(
									'load',
									() =>
									{
										canvas.width = elementWidth;
										canvas.height =
											isSmallScreen ?
												textContainerSmallScreenHeight
												: textContainerNormalScreenHeight;
										ctx.fillStyle = finalTextBackdropColor.withAlpha(1).css;
										ctx.fillRect(0, 0, canvas.width, canvas.height);
										ctx.filter = 'blur(20px)';
										const {x1, x2, y1, y2, scale} =
											getVisibleRectangleOnTargetContainer(
												imageElement.width,
												imageElement.height,
												canvas.width,
												canvas.width / MediaButtonV2ImageAspectRatio,
												imageFit
											);

										const imageWidth = imageElement.width;
										const imageHeight = imageElement.height;
										const sourceWidth = x2 - x1;
										const sourceHeight = y2 - y1;
										const clipHeight = sourceHeight * MediaButtonV2BackdropImageRatio;
										ctx.drawImage(
											imageElement,
											x1,
											y2 - clipHeight,
											sourceWidth,
											clipHeight,
											0,
											0,
											canvas.width,
											canvas.height
										);
										imageElement.remove();

										if (imageBackgroundColor !== undefined)
										{
											ctx.fillStyle = imageBackgroundColor.css;

											if (x1 < 0)
											{
												ctx.fillRect(
													0,
													0,
													-1 * x1 / scale,
													canvas.height
												);
											}

											if (x2 > imageWidth)
											{
												const delta = (x2 - imageWidth) / scale;

												ctx.fillRect(
													canvas.width - delta,
													0,
													delta,
													canvas.height
												);
											}

											if (y1 < 0)
											{
												ctx.fillRect(
													0,
													0,
													canvas.width,
													-1 * y1 / scale
												);
											}

											if (y2 > imageHeight)
											{
												const delta = (y2 - imageHeight) / scale;

												ctx.fillRect(
													0,
													canvas.height - delta,
													canvas.width,
													delta
												);
											}
										}

										ctx.globalAlpha = finalTextBackdropColor.a;
										ctx.fillStyle = finalTextBackdropColor.withAlpha(1).css;
										ctx.fillRect(0, 0, canvas.width, canvas.height);
										canvas.toBlob(
											blob =>
											{
												if (blob)
												{
													resolve(URL.createObjectURL(blob));
												}
												else
												{
													resolve(undefined);
												}

												canvas.remove();
											},
											'image/png'
										);
									}
								);
								imageElement.src = imageUrl;
							}
							else
							{
								resolve(undefined);
							}
						}
					);
				},
				[
					shouldComputeTextBackground,
					finalTextBackdropColor,
					imageBackgroundColor,
					elementWidth,
					imageUrl,
					imageFit,
					isSmallScreen,
				]
			);
		const defaultBackgroundColor =
			useMemo(
				() =>
					finalIsTextContrastColorDark
						? new Color(255, 255, 255, 1)
						: new Color(0, 0, 0, 1),
				[finalIsTextContrastColorDark]
			);
		const finalBackgroundColor =
			useMemo(
				() =>
					getMediaButtonImageBackgroundColor(
						image,
						hasNoImage 
							? false
							: imageUrl === undefined,
						finalIsTextContrastColorDark,
						imageBackgroundColor ?? defaultBackgroundColor
					),
				[defaultBackgroundColor, finalIsTextContrastColorDark, hasNoImage, image, imageBackgroundColor, imageUrl]
			);

		return <ButtonBase
			innerRef={ref}
			className={
				clsx(
					classes.root,
					isSmallScreen && classes.rootSmallScreen
				)
			}
			focusRipple
			onClick={onClick}
			style={{
				backgroundColor:
					hasNoImage
						? textBackdropColor?.css ?? defaultBackgroundColor.css
						: finalBackgroundColor,
				...style,
			}}
			disabled={disabled}
		>
			{
				hasNoImage
					? <>
						<div
							className={
								clsx(
									classes.imageContainer,
									isSmallScreen && classes.imageContainerSmallScreen
								)
							}
						 />
						<div
							className={
								clsx(
									classes.textContainer,
									isSmallScreen && classes.textContainerSmallScreen
								)
							}
						/>
					</>
					: <div
						className={
							clsx(
								classes.imageContainer,
								isSmallScreen && classes.imageContainerSmallScreen
							)
						}
						style={{
							backgroundImage: `url(${imageUrl})`,
							backgroundSize: toObjectFitCss(imageFit) ?? 'cover',
						}}
					/>
			}
			<div
				className={
					hasNoImage
						? classes.textContainerWithoutImage
						: clsx(
							classes.textContainer,
							isSmallScreen && classes.textContainerSmallScreen
						)
				}
			>
				{
					!hasNoImage &&
					<div
						className={
							clsx(
								classes.textBackgroundContainer,
								isSmallScreen && classes.textBackgroundContainerSmallScreen
							)
						}
						style={{
							backgroundImage:
								textContainerBackgroundImageUrl !== undefined
									? `url(${textContainerBackgroundImageUrl})`
									: undefined,
							backgroundColor: finalTextBackdropColor?.css,
						}}
					/>
				}
				<div
					className={classes.text}
				>
					<div
						className={clsx(classes.titleContainer, hasNoImage && classes.centeredText)}
					>
						<Typography
							className={
								clsx(
									classes.title,
									isSmallScreen && classes.smallTitle
								)
							}
							variant="h6"
							style={{
								color: effectiveTextColor.css,
							}}
						>
							{title}
						</Typography>
					</div>
					{
						secondaryTitle !== undefined &&
						<div
							className={classes.secondaryTitleContainer}
						>
							<Typography
								className={
									clsx(
										classes.secondaryTitle,
										isSmallScreen && classes.smallTitle
									)
								}
								variant="body1"
								style={{
									color: secondaryTextColor.css,
								}}
							>
								{secondaryTitle}
							</Typography>
						</div>
					}
				</div>
			</div>
			<Box
				paddingY={1}
				paddingX={2}
				sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'flex-start',
				}}
			>
				<Box
					sx={{
						flex: 1,
					}}
					className={classes.badges}
				>
					{
						(secondaryBadges ?? [])
							.map(
								(badge, idx) =>
									<Fragment
										key={idx}
									>
										{badge}
									</Fragment>,
							)
					}
				</Box>
				<Box
					className={classes.badges}
				>
					{
						(primaryBadges ?? [])
							.map(
								(badge, idx) =>
									<Fragment
										key={idx}
									>
										{badge}
									</Fragment>,
							)
					}
				</Box>
			</Box>
		</ButtonBase>;
	};