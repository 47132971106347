import { OrderDescriptor } from '../../Api/Order/OrderDescriptor';
import { isPaid } from './isPaid';

export function hasOpenPayment(order: OrderDescriptor): boolean
{
	const totalCost = order.orderLines.reduce(
		(result, orderLine) =>
			result + orderLine.quantity * orderLine.price,
		order.paymentPrice ?? 0,
	);

	return totalCost > 0
		&& !isPaid(order);
}
