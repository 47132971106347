import { Theme } from '@material-ui/core/styles';
import { useMemo } from 'react';
import { ButtonAppearance } from '../../ButtonAppearance';
import { extractMuiButtonColor, toCss } from '../../ButtonColor';
import { defaultSecondButtonColor } from '../../color/defaultSecondButtonColor';
import { normalToHoverColor } from '../../color/normalToHoverColor';

export function useContainedButtonColors(error: boolean, disabled: boolean, color: ButtonAppearance<'contained'>, theme: Theme)
{
	return useMemo(
		() => {
			const buttonColor = (() => {
				if (error)
					return 'error';
				else if (disabled)
					return '#e0e0e0';
				else
					return color.buttonColor ?? 'primary';
			})();
			const contentColor = (() => {
				if (disabled)
					return !error
						? 'rgba(0, 0, 0, 0.27)'
						: 'rgba(255, 255, 255, 0.5)';
				else
					return color.contentColor ?? defaultSecondButtonColor(buttonColor);
			})();


			const buttonColorCss = toCss(buttonColor, theme);
			const buttonColorHoverCss = normalToHoverColor(buttonColorCss).css();
			const contentColorCss = toCss(contentColor, theme);

			const muiButtonColor = extractMuiButtonColor(buttonColor);

			return {buttonColorCss, buttonColorHoverCss, contentColorCss, muiButtonColor};
		},
		[color.buttonColor, color.contentColor, disabled, error, theme],
	);
}