import { makeAutoObservable } from 'mobx';
import { ISO8601ToDateTransformer, SerializationProfile } from '../../Util/Serialization/Serialization';
import { OrderRestrictionReport, OrderRestrictionReportProfile } from './OrderRestrictionReport';

export class CartChangeResponse
{
	// ------------------------- Properties -------------------------

	restrictions: OrderRestrictionReport[];
	dateChanged: Date;
	success: boolean;

	// ------------------------ Constructor -------------------------

	constructor()
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});
	}

	// ----------------------- Initialization -----------------------

	// -------------------------- Computed --------------------------

	// -------------------------- Actions ---------------------------

	// ------------------------ Public logic ------------------------

	// ----------------------- Private logic ------------------------
}

export const CartChangeResponseProfile = SerializationProfile.create(CartChangeResponse)
	.profile('restrictions', OrderRestrictionReportProfile)
	.transform('dateChanged', ISO8601ToDateTransformer);
