import { AxiosError } from 'axios';
import { Http } from '../../../Bridge/Http/Http';
import { AbortedHttpRequestException } from '../../Exception/AbortedHttpRequestException';
import { ConflictHttpRequestException } from '../../Exception/ConflictHttpRequestException';
import { FailedHttpRequestException } from '../../Exception/FailedHttpRequestException';
import { HttpRequestException } from '../../Exception/HttpRequestException';
import { HttpRequestTimeoutException } from '../../Exception/HttpRequestTimeoutException';
import { UnauthorizedHttpRequestException } from '../../Exception/UnauthorizedHttpRequestException';
import { setApiReachable } from '../../Hooks/useApiReachability';

/**
 * Handler for whenever an API request results in an error
 *
 * @param error the {@link AxiosError} caused by an API request
 */
export function onApiRequestError(error: Error): Promise<any>
{
	throw translateError(error);
}

/**
 * Translates an {@link AxiosError} to a (child of) {@link HttpRequestException}
 *
 * @param error the {@link AxiosError} to translate
 * @return translated error
 */
function translateError(error: Error): HttpRequestException
{
	if (isAxiosError(error) && error.message.startsWith('timeout of'))
	{
		return new HttpRequestTimeoutException(error, Http.TIMEOUT_IN_MILLISECONDS);
	}
	else if (isAxiosError(error) && error.code === undefined && error.response === undefined)
	{
		return new FailedHttpRequestException(error);
	}
	else if (isAxiosError(error) && error.code === 'ECONNABORTED')
	{
		return new AbortedHttpRequestException(error);
	}
	else if (isAxiosError(error))
	{
		setApiReachable();

		const responseStatusCode = error?.response?.status;

		if (responseStatusCode === 409)
		{
			return new ConflictHttpRequestException(error);
		}
		else if (responseStatusCode === 403)
		{
			return new UnauthorizedHttpRequestException(error);
		}
		else
		{
			return new HttpRequestException(error);
		}
	}
	else
	{
		throw error;
	}
}

function isAxiosError(error: Error): error is AxiosError
{
	return (error as AxiosError).isAxiosError;
}
