import * as React from 'react';
import { FC, useContext } from 'react';
import { AppVersionContext } from '../app-version/AppVersionContext';
import { AuthenticationContextProviderV2 } from './v2/AuthenticationContextProviderV2';
import { AuthenticationContextProviderV3 } from './v3/AuthenticationContextProviderV3';

export const AuthenticationContextProvider: FC =
	({
		children,
	}) =>
	{
		const appVersion = useContext(AppVersionContext);

		if (appVersion === 'V3')
		{
			return <AuthenticationContextProviderV3>
				{children}
			</AuthenticationContextProviderV3>;
		}
		else
		{
			return <AuthenticationContextProviderV2>
				{children}
			</AuthenticationContextProviderV2>;
		}
	};
