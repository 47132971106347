import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { ProductConfiguration } from '../../../../../Api/Product/ProductConfiguration';
import { OrderLinesContextRef } from './OrderLinesContext';

const useStyles = makeStyles({
    QuantityButtonCell: {
        flex: '0 0 auto',
        verticalAlign: 'middle',
        paddingTop: 8,
        paddingBottom: 8,
    },
    QuantityButtonDecCell: {
        textAlign: 'right',
    },
    QuantityButtonIncCell: {
        textAlign: 'left',
    },
    QuantityButton: {
        width: 24,
        height: 30,
        borderRadius: 12,
        textAlign: 'center',
        zIndex: 2000,
        padding: 0,
        minWidth: 0,
        // backgroundColor: 'rgba(0, 0, 0, 0.54)',
        boxShadow: 'none',
        '&:disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.025)',
        },
        '&:hover': {
            boxShadow: (props: {outOfStock: boolean}) => props.outOfStock
                ? '0px 2px 4px -1px rgba(255,0,0,0.2), 0px 4px 5px 0px rgba(255,0,0,0.14), 0px 1px 10px 0px rgba(255,0,0,0.12)'
                : '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        },
    },
    QuantityButtonText: {
        fontSize: 20,
        color: 'white',
        lineHeight: '30px',
        verticalAlign: 'middle',
        transform: 'translateY(-1px)'
    },
    QuantityDecButton: {
        // borderRadius: '10px 0 0 10px',
    },
    QuantityIncButton: {
        // borderRadius: '0 10px 10px 0',
    },
    QuantityDisplayCell: {
        flex: '0 0 auto',
        verticalAlign: 'middle',
        paddingTop: 8,
        paddingBottom: 8,
    },
    QuantityDisplay: {
        height: 30,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: -12,
        marginRight: -12,
        textAlign: 'center',
    },
    QuantityText: {
        display: 'inline-block',
    },
});

interface OrderLineQuantityControlProps
{
    configuration: ProductConfiguration
    disableIncrement?: boolean;
    quantity: number
    outOfStock: boolean
    changeQuantity: (configuration: ProductConfiguration, diff: number) => void
}

export const OrderLineQuantityControl: FC<OrderLineQuantityControlProps> =
    (
        {
            configuration,
            disableIncrement,
            quantity,
            outOfStock,
            changeQuantity
        },
    ) =>
    {
        const classes = useStyles({
            outOfStock: outOfStock,
        });

        // Concerning the label width
        const quantityLabel = useRef<HTMLParagraphElement>(null);
        const [labelWidthComp, setLabelWidthComp] = useState<number | undefined>(undefined);
        const {quantityColumnWidth, setOrderLineQuantityLabelWidth} = useContext(OrderLinesContextRef);
        useEffect(
            () => {
                if (quantityLabel.current !== null)
                {
                    setLabelWidthComp(quantityLabel.current.offsetWidth);
                }
            },
            [setLabelWidthComp]
        );

        useEffect(() =>
        {
            if (quantity > 0)
                setLabelWidthComp(quantityLabel.current.offsetWidth);
        }, [quantity]);

        useEffect(
            () => {
                if (labelWidthComp !== undefined)
                {
                    setOrderLineQuantityLabelWidth?.(configuration.id, labelWidthComp);
                }
                return () => setOrderLineQuantityLabelWidth?.(configuration.id, undefined);
            },
            [configuration.id, labelWidthComp, setOrderLineQuantityLabelWidth]
        );

        return <>
            <div className={`${classes.QuantityButtonCell} ${classes.QuantityButtonDecCell}`}>
                <Button
                    variant="contained"
                    className={`${classes.QuantityButton} ${classes.QuantityDecButton}`}
                    onClick={
                        event =>
                        {
                            event.stopPropagation();
                            changeQuantity(configuration, -1);
                        }
                    }
                    style={{
                        backgroundColor: outOfStock ? '#f7bebe' : undefined,
                    }}
                >
                    <Typography className={classes.QuantityButtonText}>
                        &minus;
                    </Typography>
                </Button>
            </div>
            <div className={classes.QuantityDisplayCell}>
                <div
                    className={classes.QuantityDisplay}
                    style={{
                        backgroundColor: outOfStock ? '#f4433612' : '#00000003',
                        color: outOfStock ? '#c33f35' : undefined,
                        // fontWeight: outOfStock ? 'bold' : undefined,
                        width: quantityColumnWidth !== undefined
                            ? (quantityColumnWidth + 2 * 16)
                            : undefined,
                        paddingLeft: quantityColumnWidth === undefined
                            ? 16
                            : undefined,
                        paddingRight: quantityColumnWidth === undefined
                            ? 16
                            : undefined,
                    }}
                >
                    <Typography
                        ref={quantityLabel}
                        className={classes.QuantityText}
                    >
                        {quantity}
                    </Typography>
                </div>
            </div>
            <div className={`${classes.QuantityButtonCell} ${classes.QuantityButtonIncCell}`}>
                <Button
                    variant="contained"
                    className={`${classes.QuantityButton} ${classes.QuantityIncButton}`}
                    disabled={disableIncrement}
                    onClick={
                        event =>
                        {
                            event.stopPropagation();
                            changeQuantity(configuration, 1);
                        }
                    }
                    style={{
                        backgroundColor: outOfStock ? '#f7bebe' : undefined,
                    }}
                >
                    <Typography className={classes.QuantityButtonText}>
                        +
                    </Typography>
                </Button>
            </div>
        </>;
    };
