import { ProductStore } from '../ProductStore';
import { FC, default as React, useContext } from 'react';
import PriceTag from '../../../../UI/PriceTag/PriceTag';
import { ProductDialogAddButton } from './ProductDialogAddButton';
import { EntranceContextRef } from '../../../Entrance/EntranceContext';
import { useObserver } from 'mobx-react-lite';

interface ProductDialogActionsProps
{
	store: ProductStore;
}

export const ProductDialogActions: FC<ProductDialogActionsProps> =
	({
		store
	}) =>
	{
		const {
			localizer
		} = useContext(EntranceContextRef);
		return useObserver(() => <>
			{
				store.showPriceDisplay &&
				<PriceTag
					hideCurrencySymbols={store.place.productCurrencySymbolsHidden}
					price={store.price}
					localizer={localizer}
					currencyCode={store.business.productCurrencyCode}
					variant="subtitle1"
					textPrepended={store.priceLabel!}
					style={{
						textAlign: 'left',
						marginRight: 'auto',
					}}
				/>
			}
			{
				store.showOrderButton
					?
					<ProductDialogAddButton store={store}/>
					:
					<></>
			}
		</>);
	}