import * as React from 'react';
import { FC } from 'react';
import { useRootContext } from './RootContext';

/**
 * The buttons in this component are meant to be clicked by the end-to-end testing framework, and not by a user.
 * This is why all of the buttons in this component have a CSS of 'display: none'.
 */
export const TestButtons: FC =
	() =>
	{
		const {
			client,
			notification,
		} = useRootContext(true);
		return <>
			<div
				onClick={() => client.setClientVisibilityForTest('visible')}
				style={{
					display: 'none',
				}}
			>
				testbutton: make client visible
			</div>
			<div
				onClick={() => client.setClientVisibilityForTest('invisible')}
				style={{
					display: 'none',
				}}
			>
				testbutton: make client invisible
			</div>
			<div
				onClick={
					() => notification.notify({
						content: 'Test snackbar message',
					})
				}
				style={{
					display: 'none',
				}}
			>
				testbutton: show snackbar
			</div>
			<div
				onClick={
					() => notification.notify({
						content: 'Test snackbar message',
						persist: true,
					})
				}
				style={{
					display: 'none',
				}}
			>
				testbutton: show persistent snackbar
			</div>
		</>;
	};