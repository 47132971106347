import { makeStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import * as React from 'react';
import { CSSProperties, FC, useContext, useEffect, useMemo } from 'react';
import { BrowserRootLayoutPolyfillContextRef } from '../BrowserRootLayoutPolyfiller/BrowserRootLayoutPolyfillContextProvider';

const useStyles = makeStyles({
    pageRoot: {
        width: '100%',
    },
});

export interface PageProps
{
    className?: string;
    backgroundImageUrl?: string;
    backgroundImageBlur?: number;
    backgroundOverlayColor?: string;
    backgroundColor?: string;
    classes?: Partial<ClassNameMap<'root'>>;
    style?: CSSProperties;
}

export const Page: FC<PageProps> =
    (
        {
            className,
            children,
            backgroundImageUrl,
            backgroundImageBlur,
            backgroundColor = '#000000',
            backgroundOverlayColor,
            style,
        }
    ) =>
    {
        const classes = useStyles();

        useRegisterBackgroundCssOnRootElements(backgroundOverlayColor, backgroundImageUrl, backgroundColor);

        const rootClassName = useMemo(() => clsx(classes.pageRoot, className), [className, classes.pageRoot]);

        return <div
            className={rootClassName}
            style={{
                ...style,
                WebkitBackdropFilter:
                    backgroundImageBlur
                        ? `blur(${backgroundImageBlur}px)`
                        : undefined,
                backdropFilter:
                    backgroundImageBlur
                        ? `blur(${backgroundImageBlur}px)`
                        : undefined,
            }}
        >
            {children}
        </div>;
    }

function useRegisterBackgroundCssOnRootElements(
    backgroundOverlayColor: string | undefined,
    backgroundImageUrl: string | undefined,
    backgroundColor: string,
)
{
    const backgroundCss = useMemo(
        () => getBackgroundCss(
            backgroundOverlayColor,
            backgroundImageUrl,
            backgroundColor,
        ),
        [backgroundColor, backgroundImageUrl, backgroundOverlayColor],
    );
    const {setBackgroundCss} = useContext(BrowserRootLayoutPolyfillContextRef);
    useEffect(
        () => setBackgroundCss(backgroundCss),
        [backgroundCss, setBackgroundCss],
    );
}

function getBackgroundCss(backgroundOverlayColor, backgroundImageUrl, backgroundColor): CSSProperties
{
    return {
        background: `linear-gradient(${backgroundOverlayColor}, ${backgroundOverlayColor}), url(${backgroundImageUrl}), ${backgroundColor}`,
        backgroundPosition: 'center 0',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        ...({
            '-webkit-background-size': 'cover',
            '-moz-background-size': 'cover',
            '-o-background-size': 'cover',
        } as any as CSSProperties),
    };
}