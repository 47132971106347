import { makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import { FC, useCallback } from 'react';
import { CustomerCard } from '../../../../../../../../Api/customer_card/CustomerCard';
import { useRootContext } from '../../../../../../../../RootContext';
import { fetchAny } from '../../../../../../../../Util/Api';
import { QrCodeScanner } from '../../../../../../../UI/qr_code_scanner/QrCodeScanner';

const useStyles = makeStyles(theme => ({
	closeIcon: {
		color: theme.palette.common.white,
		position: 'absolute',
		right: theme.spacing(2),
		top: theme.spacing(2),
	},
	root: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		touchAction: 'none',
	},
}));

interface CustomerCardScannerProps
{
	onClose: () => void;
	onScan: (customerCard: CustomerCard) => void;
}

export const CustomerCardScanner: FC<CustomerCardScannerProps> =
	({
		onClose,
		onScan,
	}) =>
	{
		const {notification} = useRootContext(true);

		const classes = useStyles();

		const handleScan = useCallback(async (data: string | undefined) =>
		{
			const trimmedData = data?.trim();

			if (trimmedData !== undefined && trimmedData.length > 0)
			{
				try
				{
					const customerCard = await fetchAny<CustomerCard>(
						`/client/customerCards/${trimmedData}`,
						undefined,
					);

					onScan({
						id: customerCard.id,
						businessId: customerCard.businessId,
						name: customerCard.name,
					});
				}
				catch
				{
					notification.notify({
						content: 'Invalid customer card',
						variant: 'warning',
					});
				}
			}
		}, [notification, onScan]);

		return <div
			className={classes.root}
		>
			<QrCodeScanner
				onScan={handleScan}
			/>
			<CloseIcon
				className={classes.closeIcon}
				onClick={onClose}
			/>
		</div>;
	};
