import { makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import * as  React from 'react';
import { FC, useCallback, useRef } from 'react';
import { useTranslate } from '../../../../../../Bridge/Localization/useTranslate';

const useStyles = makeStyles(theme => ({
	label: {
		marginLeft: theme.spacing(2),
	},
	root: {
		alignItems: 'center',
		display: 'flex',
		marginBottom: theme.spacing(4),
		marginTop: theme.spacing(4),
	},
	QuantityButtonCell: {
		flex: '0 0 auto',
		verticalAlign: 'middle',
		paddingTop: 8,
		paddingBottom: 8,
	},
	QuantityButtonDecCell: {
		textAlign: 'right',
	},
	QuantityButtonIncCell: {
		textAlign: 'left',
	},
	QuantityButton: {
		width: 24,
		height: 30,
		borderRadius: 12,
		textAlign: 'center',
		zIndex: 2000,
		padding: 0,
		minWidth: 0,
		// backgroundColor: 'rgba(0, 0, 0, 0.54)',
		boxShadow: 'none',
		'&:hover': {
			boxShadow: (props: { outOfStock: boolean }) => props.outOfStock
				? '0px 2px 4px -1px rgba(255,0,0,0.2), 0px 4px 5px 0px rgba(255,0,0,0.14), 0px 1px 10px 0px rgba(255,0,0,0.12)'
				: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
		},
	},
	QuantityButtonText: {
		fontSize: 20,
		color: 'white',
		lineHeight: '30px',
		verticalAlign: 'middle',
		transform: 'translateY(-1px)',
	},
	QuantityDecButton: {
		// borderRadius: '10px 0 0 10px',
	},
	QuantityIncButton: {
		// borderRadius: '0 10px 10px 0',
	},
	QuantityDisplayCell: {
		flex: '0 0 auto',
		verticalAlign: 'middle',
		paddingTop: 8,
		paddingBottom: 8,
	},
	QuantityDisplay: {
		height: 30,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: -12,
		marginRight: -12,
		textAlign: 'center',
		backgroundColor: '#00000003',
		paddingLeft: 16,
		paddingRight: 16,
	},
	QuantityText: {
		display: 'inline-block',
	},
}));

interface PaymentSharingDialogPersonInputProps
{
	maximum?: number;
	onChange: (value: (((prevState: number) => number) | number)) => void;
	value: number;
}

export const PaymentSharingDialogPersonInput: FC<PaymentSharingDialogPersonInputProps> =
	({
		maximum,
		onChange,
		value,
	}) =>
	{
		const translate = useTranslate();

		const classes = useStyles();

		const quantityLabel = useRef<HTMLParagraphElement>(null);

		const handleClickMin = useCallback((event: MouseEvent) =>
		{
			onChange(value => Math.max(value - 1, 1));

			event.stopPropagation();

		}, [onChange]);

		const handleClickPlus = useCallback((event: MouseEvent) =>
		{
			onChange(value => maximum !== undefined ? Math.min(value + 1, maximum) : value + 1);

			event.stopPropagation();

		}, [onChange, maximum]);

		return <div
			className={classes.root}
		>
			<div className={`${classes.QuantityButtonCell} ${classes.QuantityButtonDecCell}`}>
				<Button
					variant="contained"
					className={`${classes.QuantityButton} ${classes.QuantityDecButton}`}
					onClick={event => handleClickMin(event)}
				>
					<Typography className={classes.QuantityButtonText}>
						&minus;
					</Typography>
				</Button>
			</div>
			<div className={classes.QuantityDisplayCell}>
				<div
					className={classes.QuantityDisplay}
				>
					<Typography
						ref={quantityLabel}
						className={classes.QuantityText}
						variant="h4"
					>
						{value}
					</Typography>
				</div>
			</div>
			<div className={`${classes.QuantityButtonCell} ${classes.QuantityButtonIncCell}`}>
				<Button
					variant="contained"
					className={`${classes.QuantityButton} ${classes.QuantityIncButton}`}
					onClick={event => handleClickPlus(event)}
				>
					<Typography className={classes.QuantityButtonText}>
						+
					</Typography>
				</Button>
			</div>
			<Typography
				className={classes.label}
				variant="h4"
			>
				{
					value === 1
						? translate('Generic-Person').toLowerCase()
						: translate('Generic-Persons').toLowerCase()
				}
			</Typography>
		</div>;
	};
