import { Divider, Fade, Grid, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { OrderDescriptor } from '../../../../../Api/Order/OrderDescriptor';
import { UIColors } from '../../../../../Constants/UIColors';
import { useUnpaidCompleteOrders } from '../context/order/order-handler-order-context';
import { BillDialog } from './Bill/Dialog/BillDialog';
import { BillSummary } from './BillSummary/BillSummary';
import { PlaceIdentifier } from './PlaceIdentifier';
import { PlaceOrders } from './PlaceOrders';

const useStyles = makeStyles(
	(theme: Theme) =>
		({
			item:
				{
					padding: theme.spacing(1),
				},
			noBillsText:
				{
					padding: theme.spacing(1),
				},
			root:
				{
					backgroundColor: UIColors.greyLight,
					minWidth: 272,
					overflowY: 'auto',
				},
		}));

interface BillManagerProps
{
	onNumberOfBillsUpdate?: (numberOfBills: number) => void
	style?: CSSProperties
}

export const BillManager: FC<BillManagerProps> =
	({
		 onNumberOfBillsUpdate,
		 style,
	 }) =>
	{
		const classes = useStyles();

		const unpaidCompleteOrders = useUnpaidCompleteOrders();
		const ordersRequiringPayment = useMemo(() => unpaidCompleteOrders ?? [], [unpaidCompleteOrders]);

		const [openedOrders, setOpenedOrders] = useState<OrderDescriptor[]>();
		const [openedPlace, setOpenedPlace] = useState<PlaceIdentifier | undefined>();

		const openBills: PlaceOrders[] = useMemo(
			() =>
			{
				let openBills: PlaceOrders[] = [];

				ordersRequiringPayment
					.forEach(
						({order}) =>
						{
							let billIdx = openBills.findIndex(peerBill => peerBill.placeId === order.placeId);

							if (billIdx > -1)
							{
								let bill = openBills[billIdx];
								bill.orders.push(order);
								openBills.splice(billIdx, 1, bill);
							}
							else
							{
								openBills.push({
									orders: [order],
									placeId: order.placeId,
									placeName: order.orderingLocationName
								});
							}
						});

				return openBills.slice().sort(
					(a, b) =>
						a.placeName.localeCompare(
							b.placeName,
							undefined,
							{
								numeric: true,
								sensitivity: 'base',
							}));
			},
			[ordersRequiringPayment]);

		useEffect(() => onNumberOfBillsUpdate && onNumberOfBillsUpdate(openBills.length), [onNumberOfBillsUpdate, openBills]);

		useEffect(
			() =>
				setOpenedOrders(openedPlace ? ordersRequiringPayment.filter(({order}) => order.placeId === openedPlace.id).map(({order}) => order) : []),
			[openedPlace, ordersRequiringPayment]);

		const onBillDialogClose = useCallback(
			() => {
				setOpenedPlace(undefined);
				setOpenedOrders(undefined);
			},
			[],
		);

		const renderBillSummary = useCallback(
			(
				placeId: number,
				placeName: string,
				orders: OrderDescriptor[]
			) =>
				<Fade
					key={placeId}
					in={orders && orders.length > 0}
				>
					<Grid
						classes={{
							item: classes.item,
						}}
						item
						key={placeId}
					>
						<BillSummary
							onClick={
								() =>
									setOpenedPlace({
										id: placeId,
										name: placeName
									})
							}
							orders={ordersRequiringPayment.filter(({order}) => order.placeId === placeId).map(({order}) => order)}
							place={{
								id: placeId,
								name: placeName
							}}
						/>
					</Grid>
				</Fade>,
			[classes.item, ordersRequiringPayment]);

		return <>
			<BillDialog
				onClose={onBillDialogClose}
				orders={openedOrders}
				placeName={openedPlace?.name}
			/>
			<div
				className={classes.root}
				style={style}
			>
				<Divider />
				<Grid
					alignItems="stretch"
					container
					justifyContent="flex-start"
				>
					{openBills.map(bill => renderBillSummary(bill.placeId, bill.placeName, bill.orders))}
				</Grid>
			</div>
		</>;
	};