import { Typography } from '@material-ui/core';
import * as React from 'react';
import { CSSProperties, FC, useEffect, useRef } from 'react';
import { useLocalizer } from '../../../../../../../Bridge/Localization/useLocalizer';

interface OrderEventDateProps
{
	date: Date
	onWidthChange?: (width: number) => void
	style?: CSSProperties
}

export const OrderEventDate: FC<OrderEventDateProps> =
	(
		{
			date,
			onWidthChange = () => {},
			style = {}
		},
	) =>
	{
		const localizer = useLocalizer();
		const htmlElementRef = useRef<HTMLParagraphElement>(null);
		useEffect(
			() => {
				if (htmlElementRef.current !== null)
				{
					onWidthChange(htmlElementRef.current.offsetWidth);
				}
			},
		);

		return <div
			ref={htmlElementRef}
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'end',
				...style
			}}
		>
			<Typography>
				{localizer.formatNumericDate(date)}
			</Typography>
			<Typography>
				{localizer.formatNumericTimeDownToSeconds(date)}
			</Typography>
		</div>;
	};