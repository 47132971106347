import { Box, makeStyles, Typography } from '@material-ui/core';
import { FC, useMemo } from 'react';
import { OrderDestinationType } from '../../../../../../Api/Business/Place';
import { useLocalizer } from '../../../../../../Bridge/Localization/useLocalizer';
import { GenericHistoricalItem } from '../GenericHistoricalItem';

const useStyles = makeStyles({
	boldText: {
		fontWeight: 'bold',
	},
	importantText: {
		fontSize: '2rem',
	},
	multilineRoot: {
		flexGrow: 1,
		textAlign: 'center',
	},
});

interface HistoricalHandoverItemProps
{
	businessName: string;
	destinationType: OrderDestinationType;
	pickupCode: string | undefined;
	scheduledFor: string | undefined;
}

export const HistoricalHandoverItem: FC<HistoricalHandoverItemProps> =
	({
		businessName,
		destinationType,
		pickupCode,
		scheduledFor,
	}) =>
	{
		const localizer = useLocalizer();

		const classes = useStyles();

		const content = useMemo(
			() =>
			{
				switch (destinationType)
				{
					case 'ADDRESS':
					{
						if (scheduledFor === undefined)
						{
							return <Box
								className={classes.multilineRoot}
							>
								<Typography
									color="textSecondary"
								>
									{localizer.translate('Order-Delivery-OrderWillBeDelivered')}
								</Typography>
							</Box>;
						}
						else
						{
							return <Box
								className={classes.multilineRoot}
							>
								<Typography
									color="textSecondary"
								>
									{localizer.translate('Order-Delivery-ToBeDeliveredAt')}
								</Typography>
								<Typography
									className={classes.importantText}
								>
									{localizer.formatDateTimeFamiliar(new Date(scheduledFor))}
								</Typography>
							</Box>;
						}
					}
					case 'PICKUP_POINT':
					{
						if (scheduledFor === undefined)
						{
							if (pickupCode === undefined)
							{
								return <Box
									className={classes.multilineRoot}
								>
									<Typography
										color="textSecondary"
									>
										{`${localizer.translate('Order-Pickup-OrderNeedsToBePickedUp', businessName)}. ${localizer.translate('Order-Pickup-PickupCodeWillBeAnnouncedThen')}.`}
									</Typography>
								</Box>;
							}
							else
							{
								return <Box
									className={classes.multilineRoot}
								>
									<Typography
										color="textSecondary"
									>
										{`${localizer.translate('Order-Pickup-ToBePickedUp')} ${localizer.translate('Order-Pickup-WithPickupCode')}`}
									</Typography>
									<Typography
										className={classes.importantText}
									>
										{pickupCode}
									</Typography>
								</Box>;
							}
						}
						else
						{
							if (pickupCode === undefined)
							{
								return <Box
									className={classes.multilineRoot}
								>
									<Typography
										color="textSecondary"
									>
										{localizer.translate('Order-Pickup-ToBePickedUpAt')}
									</Typography>
									<Typography
										className={classes.importantText}
									>
										{localizer.formatDateTimeFamiliar(new Date(scheduledFor))}
									</Typography>
									<Typography
										color="textSecondary"
									>
										{localizer.translate('Order-Pickup-PickupCodeWillBeAnnouncedThen')}
									</Typography>
								</Box>;
							}
							else
							{
								return <Box
									className={classes.multilineRoot}
								>
									<Typography
										color="textSecondary"
									>
										{localizer.translate('Order-Pickup-ToBePickedUpAt')}
										&nbsp;
										<span
											className={classes.boldText}
										>
											{localizer.formatDateTimeFamiliar(new Date(scheduledFor))}
										</span>
										&nbsp;
										{localizer.translate('Order-Pickup-WithPickupCode')}
									</Typography>
									<Typography
										className={classes.importantText}
									>
										{pickupCode}
									</Typography>
								</Box>;
							}
						}
					}
					case 'PLACE':
					default:
						return null;
				}
			},
			[businessName, classes.boldText, classes.importantText, classes.multilineRoot, destinationType, localizer, pickupCode, scheduledFor],
		);

		return <GenericHistoricalItem
			content={content}
		/>;
	};
