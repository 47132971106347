import { makeAutoObservable } from 'mobx';
import { SerializationProfile } from '../../Util/Serialization/Serialization';

export class Account
{
    // ------------------------- Properties -------------------------

    key: string | number;
    token: string;
    signedTermsAndConditionsVersion: string | null;

    // ------------------------ Constructor -------------------------

    constructor(
        key: string,
        token: string,
        signedTermsAndConditionsVersion: string | null
    )
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
        this.key = key;
        this.token = token;
        this.signedTermsAndConditionsVersion = signedTermsAndConditionsVersion;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}

export const AccountProfile =
    SerializationProfile.create(Account);
