import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { OrderBuilderStore } from '../../OrderBuilderStore';
import { ShopperLegalConsent } from './ShopperLegalConsent';
import { OrderBuilderSegment } from '../../OrderBuilderSegment';

interface LegalConsentOrderBuilderSegmentProps
{
	store: OrderBuilderStore
}

export const LegalConsentOrderBuilderSegment: FC<LegalConsentOrderBuilderSegmentProps> =
	(
		{
			store,
		},
	) =>
	{
		return useObserver(() =>
			store.currentOrderService.skipLegalConstentCheck
			?
				null
			:
				<OrderBuilderSegment>
					<ShopperLegalConsent
						showValidation={store.showValidation}
						legalConsent={store.hasLegalConsent}
						setLegalConsent={store.setHasLegalConsent}
					/>
				</OrderBuilderSegment>);
	};

