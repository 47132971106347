import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useMemo } from 'react';
import { OrderDescriptor } from '../../../../../Api/Order/OrderDescriptor';
import { ActionButton } from '../../../../UI/action-button/ActionButton';
import { OrderAction } from '../OrderAction';

const useStyles = makeStyles({
	root: {
		minHeight: 40,
	},
});

interface OrderActionProps
{
	order: OrderDescriptor
	action: OrderAction
}

export const OrderActionButton: FC<OrderActionProps> =
	(
		{
			order,
			action,
		},
	) =>
	{
		const importance = useObserver(() => action.importance);
		const icon = useObserver(() => action.icon);
		const name = useObserver(() => action.name);
		const execute = useObserver(() => action.execute);
		const variant = useObserver(() => action.variant);
		
		const onClick = useCallback(
			() => execute(order),
			[execute, order],
		);
		
		const classes = useStyles();
		
		const buttonVariant = useMemo(
			() =>
			{
				if (variant === undefined)
				{
					return importance === 'major'
						? 'contained'
						: 'outlined';
				}
				else
					return variant;
			},
			[importance, variant],
		);

		return <ActionButton
			className={clsx(classes.root, action.className)}
			variant={buttonVariant}
			icon={icon}
			text={name(order)}
			onClick={onClick}
		/>;
	};