import * as React from 'react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { useLocalizer } from '../../../../Bridge/Localization/useLocalizer';
import { useTranslate } from '../../../../Bridge/Localization/useTranslate';
import { formatValidationMessagesToText } from '../../../../Util/Validation';
import { useSetValidity } from '../Core/useSetValidity';
import { validateEmailAddress } from './validateEmailAddress';

interface EmailFormProps
{
	className?: string
	value: string
	label?: string
	onChange: (value: string) => void
	required?: boolean
	showValidation?: boolean
	startError?: string
	marginTop?: boolean
	marginBottom?: boolean
	onIsValidChange?: (isValid: boolean) => void
}

export const EmailForm: FC<EmailFormProps & Omit<TextFieldProps, 'value' | 'label' | 'variant' | 'onChange' | 'required' | 'error'>> =
	(
		{
			className,
			value,
			label,
			onChange,
			required = false,
			showValidation,
			startError,
			marginTop = false,
			marginBottom = false,
			onIsValidChange = () => {},
			...otherProps
		},
	) =>
	{
		const [isTouched, setIsTouched] = useState(false);
		const onTextFieldChange = useCallback(
			(value: string) => {
				setIsTouched(true);
				onChange(value);
			},
			[onChange]
		);
		const effectivelyShowValidation = useMemo(
			() => showValidation !== undefined
				? showValidation
				: isTouched,
			[isTouched, showValidation]
		);
		const localizer = useLocalizer();
		const errors = useMemo(
			() => {
				return validateEmailAddress(
					required,
					value,
					localizer
				);
			},
			[localizer, required, value]
		);
		const typeOfErrorToShow = useMemo(
			() => startError !== undefined && !isTouched
				? 'START_ERROR'
				: 'ERROR',
			[isTouched, startError]
		);
		const errorMessage = useMemo(
			() => {
				return typeOfErrorToShow === 'ERROR'
					?
					(
						effectivelyShowValidation
							?
							(
								errors.length > 0
									? formatValidationMessagesToText(errors)
									: undefined
							)
							: undefined
					)
					:
					startError
			},
			[effectivelyShowValidation, errors, startError, typeOfErrorToShow]
		);
		const helperText = useMemo(
			() => (errorMessage !== undefined)
				?
				<span
					style={{marginLeft: -12}}
				>
					{errorMessage}
				</span>
				:
				null,
			[errorMessage],
		);
		const translate = useTranslate();
		const effectiveLabel = useMemo(
			() => label !== undefined
				? label
				: translate('Generic-Email-Address'),
			[label, translate]
		);
		const isValid = useSetValidity(
			() => errors.length === 0,
			[errors.length]
		);
		useEffect(
			() => onIsValidChange(isValid),
			[isValid, onIsValidChange],
		);
		return <TextField
			className={className}
			type="email"
			value={value}
			required={required}
			onChange={event => onTextFieldChange(event.target.value)}
			error={errorMessage !== undefined}
			label={effectiveLabel}
			helperText={helperText}
			variant="outlined"
			style={{
				marginBottom: marginBottom ? 8 : 0,
				marginTop: marginTop ? 8 : 0,
			}}
			{...otherProps}
		/>;
	};