import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import * as React from 'react';
import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { useValidationContext } from '../../../../validation/context/ValidationContext';
import { PlacePasswordForm } from '../PlacePasswordForm';
import { PlacePasswordSummary } from '../summary/PlacePasswordSummary';

const useStyles = makeStyles(theme => ({
	accordion: {
		backgroundColor: '#fff8',
		display: 'flex',
		flex: '0 1 auto',
		flexDirection: 'column',
		overflowY: 'auto',
	},
	accordionDetails: {
		display: 'flex',
		flex: '0 1 auto',
		flexDirection: 'column',
		overflowY: 'hidden',
		padding: `0px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px !important`,
	},
	accordionError: {
		backgroundColor: '#f001',
	},
	accordionExpandIcon: {
		transform: 'rotate(0deg) !important',
	},
	accordionSummary: {
		flex: '0 0 auto',
		overflow: 'hidden',
	},
	icon: {
		userSelect: 'none',
	},
}));

interface PlacePasswordAccordionProps
{
	hasError?: boolean;
	onChange: (value: string) => void;
	hasFocus: boolean;
	onFocusChange: (hasFocus: boolean) => void;
	value: string;
}

export const PlacePasswordAccordion: FC<PlacePasswordAccordionProps> =
	({
		hasError,
		onChange,
		onFocusChange,
		value,
	}) =>
	{
		const showValidation = useValidationContext();

		const classes = useStyles();

		const showError = useMemo(
			() => showValidation && hasError,
			[hasError, showValidation],
		);

		const onExpansionChange = useCallback((event: ChangeEvent, expanded: boolean) =>
		{
			onFocusChange?.(expanded);
		}, [onFocusChange]);

		const accordionClassName = clsx(
			classes.accordion,
			{
				[classes.accordionError]: showError,
			},
		);

		return <Accordion
			className={accordionClassName}
			expanded
			onChange={onExpansionChange}
			TransitionProps={{
				unmountOnExit: true,
			}}
		>
			<AccordionSummary
				className={classes.accordionSummary}
				classes={{
					expandIcon: classes.accordionExpandIcon,
				}}
				expandIcon={<EditIcon className={classes.icon} fontSize="small" />}
			>
				<PlacePasswordSummary />
			</AccordionSummary>
			<AccordionDetails className={classes.accordionDetails}>
				<PlacePasswordForm onChange={onChange} onComplete={onFocusChange} showError={showError} value={value} />
			</AccordionDetails>
		</Accordion>;
	};