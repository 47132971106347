// @ts-nocheck
/* eslint-disable */
import * as React from 'react';
import { Component, FC, ReactElement, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
// @ts-ignore
import FallingWaiter from '../../../Resources/Icon/falling-waiter.svg';


const useStyles = makeStyles(() => ({
	iconLandscape: {
		marginRight: 50,
		maxWidth: 150,
	},
	iconPortrait: {
		marginBottom: 50,
		maxWidth: 150,
	},
}));

interface ErrorPageIconProps
{
	isPortrait: boolean;
	component?: ReactElement;
}

export const ErrorPageIcon: FC<ErrorPageIconProps> =
	(
		{
			isPortrait,
			component,
		},
	) =>
	{
		const classes = useStyles({});

		const iconClassesString = useMemo(() => isPortrait ? classes.iconPortrait : classes.iconLandscape, [classes.iconLandscape, classes.iconPortrait, isPortrait]);

		return component !== undefined
			?
			component
			:
			<img
				alt={FallingWaiter}
				className={iconClassesString}
				src={FallingWaiter}
			/>;
	};