import { ContractingEntity } from '../Api/contracting_entity/ContractingEntity';

export const DefaultContractingEntity: ContractingEntity = {
	id: 'ButlarooGlobal',
	name: 'Butlaroo Services B.V.',
	url: 'https://www.butlaroo.com',
	timeZone: 'Europe/Amsterdam',
	address: {
		country: 'NL',
		state: 'NB',
		city: 'Eindhoven',
		postalCode: '5616 RM',
		line1: 'Vonderweg 14',
	},
	contact: {
		emailAddress: 'support@butlaroo.com',
		phoneNumber: '+31 85 020 7990',
		url: 'https://www.butlaroo.com/contact',
	},
	legal: {
		privacyPolicyUrl: 'https://www.butlaroo.com/privacy',
		termsAndConditionsUrl: 'https://www.butlaroo.com/terms',
	},
};
