import { addDays, addWeeks, eachDayOfInterval, endOfDay, format, formatISO, isAfter, isBefore, isSameDay, nextFriday, nextMonday, nextSaturday, nextSunday, nextThursday, nextTuesday, nextWednesday, previousFriday, previousMonday, previousSaturday, previousSunday, previousThursday, previousTuesday, previousWednesday, startOfDay } from 'date-fns';
import { toDate, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { DayOfWeek, getIsoDayOfWeek } from './DayOfWeek';
import { addTo, parse } from './Duration';
import { ConjunctionTimeSeriesPayload, DayWindowOffsetFromNowTimeSeriesPayload, DisjunctionTimeSeriesPayload, ExactWindowOffsetFromNowTimeSeriesPayload, NegationBooleanTimeSeriesPayload, RepeatDailyBooleanTimeSeriesPayload, RepeatWeeklyBooleanTimeSeriesPayload, SingleDayBooleanTimeSeriesPayload, SingleOnTimeBooleanTimeSeriesPayload, TimeScheduleSpecification } from './TimeScheduleSpecification';

const MaxDaysInFuture = 365;

export function getAvailableDaysFromTimestamp(date: Date, timeSchedule: TimeScheduleSpecification): string[]
{
	switch (timeSchedule.type)
	{
		case 'ALWAYS':
			return range(MaxDaysInFuture, index => convertToLocalDate(addDays(date, index)));
		case 'CONJUNCTION':
		{
			const termDates = (timeSchedule as ConjunctionTimeSeriesPayload)
				.terms
				.map(term => getAvailableDaysFromTimestamp(date, term))
				.sort((arr1, arr2) => arr1.length - arr2.length);

			const shortest = termDates[0];

			if (termDates.length === 0)
			{
				return range(MaxDaysInFuture, index => convertToLocalDate(addDays(date, index)));
			}
			else if (termDates.length === 1)
			{
				return shortest;
			}
			else
			{
				const termCounter = termDates
					.flatMap(termArray => Array.from(new Set(termArray)))
					.reduce<Map<string, number>>(
						(map, term) => map.set(term, (map.get(term) ?? 0) + 1),
						new Map(),
					);

				return shortest
					.filter(term => termCounter.get(term)! === termDates.length);
			}
		}
		case 'DAY_WINDOW_OFFSET_FROM_NOW':
		{
			const fromInclusive = (timeSchedule as DayWindowOffsetFromNowTimeSeriesPayload).fromInclusive;
			const toExclusive = (timeSchedule as DayWindowOffsetFromNowTimeSeriesPayload).toExclusive;
			const timeZone = (timeSchedule as DayWindowOffsetFromNowTimeSeriesPayload).timeZone;
			const zonedDateTime = getDateInTimeZone(date, timeZone);

			return safelyGetEachDayOfInterval({
				start: fromInclusive === undefined
					? date
					: getZonedDateInLocalTimeZone(
						startOfDay(
							addDays(zonedDateTime, fromInclusive),
						),
						timeZone,
					),
				end: getZonedDateInLocalTimeZone(
					endOfDay(
						toExclusive === undefined
							? addDays(zonedDateTime, MaxDaysInFuture - 1)
							: addDays(zonedDateTime, toExclusive - 1),
					), timeZone),
			});
		}
		case 'DISJUNCTION':
		{
			const termDates = (timeSchedule as DisjunctionTimeSeriesPayload)
				.terms
				.flatMap(term => getAvailableDaysFromTimestamp(date, term));

			return Array.from(
				new Set(termDates),
			).sort();
		}
		case 'EXACT_WINDOW_OFFSET_FROM_NOW':
		{
			const fromInclusive = (timeSchedule as ExactWindowOffsetFromNowTimeSeriesPayload).fromInclusive;
			const toExclusive = (timeSchedule as ExactWindowOffsetFromNowTimeSeriesPayload).toExclusive;

			return safelyGetEachDayOfInterval({
				start: fromInclusive === undefined
					? date
					: addTo(parse(fromInclusive), date),
				end: toExclusive === undefined
					? addDays(date, MaxDaysInFuture - 1)
					: addTo(parse(toExclusive), date),
			});
		}

		case 'NEGATION':
		{
			const allDateStrings = range(MaxDaysInFuture, index => convertToLocalDate(addDays(date, index)));

			const termDateStrings = getAvailableDaysFromTimestamp(date, (timeSchedule as NegationBooleanTimeSeriesPayload).term);

			return allDateStrings.filter(
				dateString =>
					!termDateStrings.includes(dateString),
			);
		}

		case 'NEVER':
			return [];

		case 'REPEAT_DAILY':
		{
			const start = (timeSchedule as RepeatDailyBooleanTimeSeriesPayload).start;
			const end = (timeSchedule as RepeatDailyBooleanTimeSeriesPayload).end;
			const endOnNextDay = (timeSchedule as RepeatDailyBooleanTimeSeriesPayload).endOnNextDay;

			if (start >= end && !endOnNextDay)
			{
				return [];
			}
			else
			{
				const timeZone = (timeSchedule as RepeatDailyBooleanTimeSeriesPayload).timeZone;

				const localDate = convertToLocalDate(date);
				const endDate = toDate(`${localDate}T${end}`, {timeZone});

				if (isAfter(date, endDate) && !endOnNextDay)
					return range(MaxDaysInFuture - 1, index => convertToLocalDate(addDays(date, index + 1)));
				else
					return range(MaxDaysInFuture, index => convertToLocalDate(addDays(date, index)));
			}
		}
		case 'REPEAT_WEEKLY':
		{
			const startDay = (timeSchedule as RepeatWeeklyBooleanTimeSeriesPayload).startDay;
			const startTime = (timeSchedule as RepeatWeeklyBooleanTimeSeriesPayload).startTime;
			const endDay = (timeSchedule as RepeatWeeklyBooleanTimeSeriesPayload).endDay;
			const endTime = (timeSchedule as RepeatWeeklyBooleanTimeSeriesPayload).endTime;
			const endTimeInNextWeek = (timeSchedule as RepeatWeeklyBooleanTimeSeriesPayload).endTimeInNextWeek;

			const startDayTime = `${getIsoDayOfWeek(startDay)}:${startTime}`;
			const endDayTime = `${getIsoDayOfWeek(endDay)}:${endTime}`;

			if (startDayTime >= endDayTime && !endTimeInNextWeek)
			{
				return [];
			}
			else if (startDayTime <= endDayTime && endTimeInNextWeek)
			{
				return range(Math.floor(MaxDaysInFuture / 7) * 7, index => convertToLocalDate(addDays(date, index)));
			}
			else
			{
				const timeZone = (timeSchedule as RepeatWeeklyBooleanTimeSeriesPayload).timeZone;

				const localStartDateTime = getDayInCurrentWeek(date, startDay);
				let localEndDateTime = getDayInCurrentWeek(date, endDay);
				if (endTimeInNextWeek)
				{
					localEndDateTime = addWeeks(localEndDateTime, 1);
				}

				const localStartDate = convertToLocalDate(localStartDateTime);
				const localEndDate = convertToLocalDate(localEndDateTime);

				const startDate = toDate(`${localStartDate}T${startTime}`, {timeZone});
				const endDate = toDate(`${localEndDate}T${endTime}`, {timeZone});

				let currentWeekDays: string[];

				if (isBefore(startDate, date))
				{
					if (isBefore(endDate, date))
					{
						currentWeekDays = [];
					}
					else
					{
						currentWeekDays = safelyGetEachDayOfInterval({
							start: date,
							end: endDate,
						});
					}
				}
				else
				{
					currentWeekDays = safelyGetEachDayOfInterval({
						start: startDate,
						end: endDate,
					});
				}

				return currentWeekDays
					.concat(
						range(Math.floor(MaxDaysInFuture / 7) - 1, index =>
						{
							const start = addWeeks(startDate, index + 1);
							const end = addWeeks(endDate, index + 1);

							return safelyGetEachDayOfInterval({
								start,
								end,
							});
						})
							.flat(),
					);
			}
		}
		case 'SINGLE_DAY':
		{
			const singleDayDate = (timeSchedule as SingleDayBooleanTimeSeriesPayload).date;
			const timeZone = (timeSchedule as SingleDayBooleanTimeSeriesPayload).timeZone;

			const start = toDate(`${singleDayDate}T00:00:00`, {timeZone});
			const end = toDate(`${singleDayDate}T23:59:59`, {timeZone});
			const localStart = getZonedDateInLocalTimeZone(start, timeZone);
			const localEnd = getZonedDateInLocalTimeZone(end, timeZone);

			let result: Date[];
			if (isSameDay(localStart, localEnd))
			{
				result = [
					startOfDay(localStart),
				];
			}
			else
			{
				result = [
					startOfDay(localStart),
					startOfDay(localEnd),
				];
			}

			const dateStartOfRange = startOfDay(date);
			const dateEndOfRange = addDays(dateStartOfRange, MaxDaysInFuture);

			return result
				.filter(option => !isBefore(option, dateStartOfRange) && isBefore(option, dateEndOfRange))
				.map(convertToLocalDate);
		}
		case 'SINGLE_ON_TIME':
		{
			const start = new Date((timeSchedule as SingleOnTimeBooleanTimeSeriesPayload).start);
			const end = new Date((timeSchedule as SingleOnTimeBooleanTimeSeriesPayload).end);

			if (isBefore(end, date))
			{
				return [];
			}
			else
			{
				const dateEndOfRange = addDays(startOfDay(date), MaxDaysInFuture - 1);

				return safelyGetEachDayOfInterval({
					start: isBefore(start, date) ? date : start,
					end: isAfter(end, dateEndOfRange) ? dateEndOfRange : end,
				});
			}
		}
		default:
			throw new Error(`Unknown time schedule type: ${(timeSchedule as any).type}`);
	}
}

function getDayInCurrentWeek(date: Date, dayOfWeek: DayOfWeek): Date
{
	const dateDay = parseInt(format(date, 'i'), 10);
	const isoDay = getIsoDayOfWeek(dayOfWeek);

	if (dateDay === isoDay)
		return date;
	if (dateDay < isoDay)
		return getNextDay(date, dayOfWeek);
	else
		return getPreviousDay(date, dayOfWeek);
}

function getPreviousDay(date: Date, dayOfWeek: DayOfWeek): Date
{
	switch (dayOfWeek)
	{
		case 'MONDAY':
			return previousMonday(date);
		case 'TUESDAY':
			return previousTuesday(date);
		case 'WEDNESDAY':
			return previousWednesday(date);
		case 'THURSDAY':
			return previousThursday(date);
		case 'FRIDAY':
			return previousFriday(date);
		case 'SATURDAY':
			return previousSaturday(date);
		case 'SUNDAY':
			return previousSunday(date);
		default:
			throw new Error(`Unknown day of week: ${dayOfWeek}`);
	}
}

function getNextDay(date: Date, dayOfWeek: DayOfWeek): Date
{
	switch (dayOfWeek)
	{
		case 'MONDAY':
			return nextMonday(date);
		case 'TUESDAY':
			return nextTuesday(date);
		case 'WEDNESDAY':
			return nextWednesday(date);
		case 'THURSDAY':
			return nextThursday(date);
		case 'FRIDAY':
			return nextFriday(date);
		case 'SATURDAY':
			return nextSaturday(date);
		case 'SUNDAY':
			return nextSunday(date);
		default:
			throw new Error(`Unknown day of week: ${dayOfWeek}`);
	}
}

function convertToLocalDate(date: Date): string
{
	return formatISO(
		date,
		{
			representation: 'date',
		},
	);
}

function safelyGetEachDayOfInterval(
	{
		start,
		end,
	}: Interval): string[]
{
	if (isAfter(end, start))
	{
		return eachDayOfInterval({
			start,
			end,
		})
			.map(convertToLocalDate);
	}
	else
	{
		console.warn(`start ${start} is after end ${start}`);
		return [];
	}
}

/**
 * Convert a local date to a zoned date in another time zone.
 * @param localDate
 * @param timeZone
 */
function getDateInTimeZone(
	localDate: Date,
	timeZone: string
)
{
	return utcToZonedTime(localDate, timeZone);
}

/**
 * Convert zoned date (specified in {@code timeZone}) to a local date.
 * @param zonedDate
 * @param timeZone
 */
function getZonedDateInLocalTimeZone(
	zonedDate: Date,
	timeZone: string
)
{
	return zonedTimeToUtc(zonedDate, timeZone);
}

function range<V>(length: number, mapFn: (index: number) => V): V[]
{
	return Array.from({length}, (v, i) => mapFn(i));
}
