import { Typography } from '@material-ui/core';
import Decimal from 'decimal.js';
import * as  React from 'react';
import { FC, useMemo } from 'react';
import { useLocalizer } from '../../../../../../Bridge/Localization/useLocalizer';
import { useTranslate } from '../../../../../../Bridge/Localization/useTranslate';

interface SharePaymentWithOthersLabelProps
{
	amount: Decimal;
	currencyCode: string,
}

export const SharePaymentWithOthersLabel: FC<SharePaymentWithOthersLabelProps> =
	({
		amount,
		currencyCode,
	}) =>
	{
		const translate = useTranslate();
		const localizer = useLocalizer();

		const formattedCurrency = useMemo(
			() =>
				localizer.formatCurrency(
					amount,
					currencyCode,
				)
				?? `${currencyCode} 0`,
			[amount, currencyCode, localizer],
		);

		return <Typography
			align="center"
			variant="h5"
		>
			{translate('Client-OrderHistory-Share-Order-Payment-With-Others-Label', formattedCurrency)}
		</Typography>;
	};
