import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback } from 'react';
import { OrderBuilderStore } from '../../OrderBuilderStore';
import { getAnnouncementKey } from './getAnnouncementKey';
import { RequiredAnnouncementOrderBuilderSegment } from './RequiredAnnouncementOrderBuilderSegment';

interface AnnouncementOrderBuilderSegmentsProps
{
	store: OrderBuilderStore;
}

export const AnnouncementOrderBuilderSegments: FC<AnnouncementOrderBuilderSegmentsProps> =
	(
		{
			store,
		},
	) =>
	{
		const announcementsToAcknowledge = useObserver(() => store.announcementsToAcknowledge);
		const userAcknowledgements = useObserver(() => [...store.persistedUserAcknowledgements.get(), ...store.inMemoryUserAcknowledgements]);
		const addUserAcknowledgement = useObserver(() => store.addUserAcknowledgement);
		const removeUserAcknowledgement = useObserver(() => store.removeUserAcknowledgement);
		const showValidation = useObserver(() => store.showValidation);

		const handleAcknowledgedChange = useCallback((key: string, acknowledged: boolean, remember: boolean) => {
			if (acknowledged)
				addUserAcknowledgement(key, remember);
			else
				removeUserAcknowledgement(key, remember);
		}, [addUserAcknowledgement, removeUserAcknowledgement]);

		return useObserver(
			() =>
				<>
					{
						announcementsToAcknowledge
							.map(announcement => {
								const key = getAnnouncementKey(announcement);

								return <RequiredAnnouncementOrderBuilderSegment
									key={key}
									acknowledged={userAcknowledgements.includes(key)}
									announcement={announcement}
									setAcknowledged={(acknowledged, remember) => handleAcknowledgedChange(key, acknowledged, remember)}
									showValidation={showValidation}
								/>;
							})
					}
				</>,
		);
	};