import Decimal from 'decimal.js';
import { useContext, useMemo } from 'react';
import { CartLine } from '../../../../../../../Api/Order/Cart/CartLine';
import { CartLineVariant } from '../../../../../../../Api/Order/Cart/CartLineVariant';
import { nonNullish } from '../../../../../../../Util/nonNullish';
import { BusinessContextRef } from '../../../../BusinessContext';
import { BusinessStore } from '../../../../BusinessStore';
import { InitializedCartLine } from '../model/InitializedCartLine';
import { InitializedCartLineVariant } from '../model/InitializedCartLineVariant';
import { usePeerCarts } from './usePeerCarts';

export function useInitializedPeerCartLines()
{
	const carts = usePeerCarts();
	const {businessStore} = useContext(BusinessContextRef);

	return useMemo(
		() =>
			carts
				.map(
					cart =>
						cart.lines
				)
				.reduce(
					(a, b) =>
						a.concat(b),
					[]
				)
				.map(
					line =>
						initializeLine(
							line,
							businessStore
						)
				)
				.filter(nonNullish),
		[
			carts,
			businessStore,
		]
	);
}

function initializeLine(
	line: CartLine,
	businessStore: BusinessStore
): InitializedCartLine | undefined
{
	const product = businessStore.productById.get(line.productId);

	if (product === undefined)
	{
		return undefined;
	}
	else
	{
		const variants =
			line.variants
				.map(
					variant =>
						initializeVariant(
							variant,
							businessStore
						)
				)
				.filter(nonNullish);
		const totalPrice =
			new Decimal(line.quantity)
				.mul(
					product.price.plus(
						variants.reduce(
							(a, b) =>
								a.plus(b.variant.price),
							new Decimal(0)
						)
					)
				);

		return {
			id: line.id,
			product,
			quantity: line.quantity,
			variants,
			totalPrice,
		};
	}
}

function initializeVariant(
	lineVariant: CartLineVariant,
	businessStore: BusinessStore
): InitializedCartLineVariant | undefined
{
	const variant = businessStore.productFeatureVariantById.get(lineVariant.variantId);
	const assignment = businessStore.productFeatureAssignmentById.get(lineVariant.assignmentId);

	if (variant === undefined
		|| assignment === undefined)
	{
		return undefined;
	}
	else
	{
		return {
			id: lineVariant.id,
			variant,
			assignment,
		}
	}
}