import * as React from 'react';
import { FC, useContext, useMemo } from 'react';
import { IllegalStateException } from '../../Util/Exception/IllegalStateException';
import { WebClient } from './WebClient';

interface Context
{
	client: WebClient;
}

const ContextRef = React.createContext<Context | undefined>(undefined);

export const WebClientProvider: FC =
	(
		{
			children,
		}
	) =>
	{
		const client = useMemo(() => new WebClient(), []);
		return <ContextRef.Provider
			value={useMemo(() => ({
				client,
			}), [client])}
		>
			{children}
		</ContextRef.Provider>
	};

export function useWebClient(require?: false): WebClient | undefined
export function useWebClient(require: true): WebClient
export function useWebClient(require?: boolean): WebClient | undefined
{
	const context = useContext(ContextRef);
	return useMemo(
		() => {
			if (require && context === undefined)
				throw new IllegalStateException(
					'Attempting to use useClient outside of WebClientProvider'
				);
			return context?.client;
		},
		[context, require]
	);
}
