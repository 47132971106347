import { OrderDescriptor } from '../../../../Api/Order/OrderDescriptor';
import { fetchAny } from '../../../../Util/Api';

export function getWaiterOrders(businessId: number): Promise<OrderDescriptor[]>
{
	return fetchAny(
		'/waiter/business/orders',
		{
			businessId
		},
	);
}
