import { OrderProcessingState, OrderState, PaymentState } from '../../../../../../Api/Order/Order';
import { OrderEventType } from '../../../../../../Api/Order/OrderEvent';
import { IllegalStateException } from '../../../../../../Util/Exception/IllegalStateException';

export type OrderEventProperty = 'code' | 'date' | 'information' | 'refundRequest' | 'state' | 'type' | 'structuredInformation';

export function getOrderEventTypeLanguageEntry(type: OrderEventType): string
{
	return `Order-Event-Type-${capitalizeFirstCharacter(type)}`;
}

export function getOrderEventPropertyLanguageEntry(property: OrderEventProperty): string
{
	return `Order-Event-Property-${capitalizeFirstCharacter(property)}`;
}

export function getOrderEventStateTranslationEntry(type: OrderEventType, state: OrderProcessingState | OrderState | PaymentState): string
{
	if (type === 'OrderState')
		return getOrderStateTranslationEntry(state as OrderState);
	else if (type === 'Payment')
		return getPaymentStateTranslationEntry(state as PaymentState);
	else if (type === 'Process')
		return getOrderProcessingStateTranslationEntry(state as OrderProcessingState);

	throw new IllegalStateException(`Order event with type ${type} has no related states.`);
}

function getOrderProcessingStateTranslationEntry(state: OrderProcessingState): string
{
	return `Order-Processing-State-${capitalizeFirstCharacter(state)}`;
}

function getOrderStateTranslationEntry(state: OrderState): string
{
	return `Order-State-${capitalizeFirstCharacter(state)}`;
}

function getPaymentStateTranslationEntry(state: PaymentState): string
{
	return `Payment-State-${capitalizeFirstCharacter(state)}`;
}

function capitalizeFirstCharacter(value: string): string
{
	return value.charAt(0).toUpperCase().concat(value.substr(1));
}