import { TextField, useTheme } from '@material-ui/core';
import * as React from 'react';
import { ChangeEvent, FC, KeyboardEvent, useCallback, useMemo } from 'react';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { useTranslation } from '../../../../../Bridge/Localization/useTranslation';

interface PlacePasswordFormProps
{
	onChange: (value?: string) => void;
	onComplete?: (hasFocus: boolean) => void;
	showError?: boolean;
	value: string;
}

export const PlacePasswordForm: FC<PlacePasswordFormProps> =
	({
		onChange,
		onComplete,
		showError,
		value,
	}) =>
	{
		const theme = useTheme();
		const translate = useTranslate();
		
		const placeholder = useTranslation('Place-Password-Placeholder');

		const handleChange = useCallback((event: ChangeEvent<{ value: string }>) =>
		{
			const trimmedValue = event.target.value.trim();

			onChange(
				trimmedValue.length > 0
					? trimmedValue
					: undefined,
			);
		}, [onChange]);

		const handleKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) =>
		{
			if (event.key === 'Enter')
				onComplete?.(false);
		}, [onComplete]);

		const helperText = useMemo(() =>
		{
			if (showError)
				return translate('Place-Password-Invalid');
		}, [showError, translate]);

		const formHelperTextProps = useMemo(() =>
		{
			return {
				style: {
					marginLeft: 0,
					marginTop: theme.spacing(1),
				},
			};
		}, [theme]);

		const inputProps = useMemo(() =>
		{
			return {
				autoComplete: 'new-password',
			};
		}, []);

		return <TextField
			FormHelperTextProps={formHelperTextProps}
			error={showError}
			helperText={helperText}
			inputProps={inputProps}
			onChange={handleChange}
			onKeyDown={handleKeyDown}
			placeholder={`${placeholder}...`}
			value={value ?? ''}
			variant="outlined"
		/>;
	};