import { PaymentMethodDescriptor, PaymentMethodDescriptorProfile } from '../../../../../Api/Payment/PaymentMethodDescriptor';
import { fetchList } from '../../../../../Util/Api';
import { PaymentTiming } from '../../model/PaymentTiming';

export function getPaymentMethods(
	timing: PaymentTiming,
	// the amount used to calculate the service fee
	baseAmount: number,
	// extra amount not included in the calculation for the service fee (i.e. tip), but still included in the payment amount
	supplementaryAmount: number,
): Promise<PaymentMethodDescriptor[]>
{
	return fetchList(
		'/client/paymentMethods',
		{
			baseAmount,
			supplementaryAmount,
			timing,
		},
		PaymentMethodDescriptorProfile,
	);
}
