import { Checkbox, FormControlLabel, Theme, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import { FC, useCallback, useContext } from 'react';
import { StoredVariable } from '../../../../Util/StoredVariable';
import { EntranceContextRef } from '../../Entrance/EntranceContext';
import { useObserver } from 'mobx-react-lite';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		margin: theme.spacing(2),
	},
	form: {
		width: 'fit-content',
	},
}));

export const ManagerDeviceSettings: FC = () => {
	const {entranceStore} = useContext(EntranceContextRef);

	const classes = useStyles();

	const renderOption = useCallback(
		(label: string, checked: StoredVariable<boolean>) =>
			<FormControlLabel
				classes={{
					root: classes.form,
				}}
				control={
					<Checkbox
						checked={checked.value}
						onChange={
							event =>
								checked.set(event.target.checked)
						}
						color="primary"
					/>
				}
				label={label}
			/>,
		[classes],
	);

	return useObserver(() => {
		return <div
			className={classes.container}
		>
			<Typography
				variant="h5"
			>
				Device settings
			</Typography>
			{renderOption('Require session', entranceStore.placeSessionRequired)}
			{renderOption('User profile hidden', entranceStore.userProfileHidden)}
			{renderOption('Order history hidden', entranceStore.orderHistoryHidden)}
			{renderOption('Reset navigation after placing order', entranceStore.navigateToBusinessRootAfterOrder)}
			{renderOption('Clear order options (e.g. payment method, client name) after placing order', entranceStore.clearOrderOptionsAfterOrder)}
			{renderOption('Clear the shopping cart when (re-)loading page', entranceStore.clearShoppingCartAfterReturning)}
			{renderOption('Kiosk mode', entranceStore.isKioskMode)}
		</div>;
	});
};