import { ButtonBase, Card, CardContent, CircularProgress, Collapse, Dialog, IconButton, makeStyles, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { CheckCircle } from '@material-ui/icons';
import clsx from 'clsx';
import QrCodeScanIcon from 'mdi-material-ui/QrcodeScan';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useContext, useState } from 'react';
import { useTranslate } from '../../../../../../../Bridge/Localization/useTranslate';
import { useIsKioskMode } from '../../../../../../../Service/KioskService/Api/useIsKioskMode';
import { BusinessContextRef } from '../../../../BusinessContext';
import { AgeVerificationVoucherScanner } from './scanner/AgeVerificationVoucherScanner';

const useStyles = makeStyles(theme => ({
	contentRoot: {
		alignItems: 'center',
		display: 'flex',
		textAlign: 'left',
		gap: theme.spacing(1),
	},
	root: {
		backgroundColor: '#fff8',
		width: '100%',
	},
	rootButton: {
		display: 'block',
		width: '100%',

		'&:hover': {
			backgroundColor: 'rgba(0,0,0,0.05)',
		},
	},
	rootButtonFocusVisible: {
		backgroundColor: 'rgba(0,0,0,0.05)',
	},
	rootSuccess: {
		backgroundColor: green[50],
	},
	rootError: {
		backgroundColor: '#f001',
	},
	scanButton: {
		backgroundColor: theme.palette.primary.main,
		borderRadius: theme.shape.borderRadius,
		color: theme.palette.primary.contrastText,

		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
	checkIcon: {
		color: theme.palette.success.main,
	},
}));

interface AgeVerificationFormProps
{
	showValidation: boolean;
}

export const AgeVerificationForm: FC<AgeVerificationFormProps> =
	({
		showValidation,
	}) =>
	{
		const classes = useStyles();
		const translate = useTranslate();
		const {currentAgeVerificationService} = useContext(BusinessContextRef);
		const isChallengePassed = useObserver(() => currentAgeVerificationService.isChallengePassed);
		const isLoading = useObserver(() => currentAgeVerificationService.isLoading);
		const [isDialogOpen, setIsDialogOpen] = useState(false);
		const openDialog = useCallback(() => setIsDialogOpen(true), []);
		const closeDialog = useCallback(() => setIsDialogOpen(false), []);
		const isKioskMode = useIsKioskMode();
		const rootClassName =
			clsx(
				classes.root,
				{
					[classes.rootSuccess]: isChallengePassed,
					[classes.rootError]: showValidation && !isChallengePassed,
				},
			);

		return <>
			<Card
				className={rootClassName}
			>
				<ButtonBase
					onClick={openDialog}
					className={classes.rootButton}
					disabled={isKioskMode || isChallengePassed}
					focusVisibleClassName={classes.rootButtonFocusVisible}
				>
					<CardContent>
						<Collapse
							in={!isChallengePassed}
						>
							<div
								className={classes.contentRoot}
							>
								<div>
									<Typography>
										{translate('AgeVerification-Voucher-Scan')}
									</Typography>
									<Typography
										color="textSecondary"
									>
										{translate('AgeVerification-Voucher-DescriptionInCheckout')}
									</Typography>
								</div>
								<IconButton
									className={classes.scanButton}
									component="span"
									onClick={openDialog}
									tabIndex={-1}
								>
									{
										isLoading
											? <CircularProgress
												size={24}
												color="inherit"
											/>
											: <QrCodeScanIcon />
									}
								</IconButton>
							</div>
						</Collapse>
						<Collapse
							in={isChallengePassed}
						>
							<div
								className={classes.contentRoot}
							>
								<CheckCircle
									className={classes.checkIcon}
								/>
								<Typography>
									{translate('AgeVerification-Verified')}
								</Typography>
							</div>
						</Collapse>
					</CardContent>
				</ButtonBase>
			</Card>
			<Dialog
				onClose={closeDialog}
				open={isDialogOpen}
			>
				<AgeVerificationVoucherScanner
					onClose={closeDialog}
				/>
			</Dialog>
		</>;
	};
