import { Hidden, Theme, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Restaurant from '@material-ui/icons/Restaurant';
import Decimal from 'decimal.js';
import CircleMultipleOutline from 'mdi-material-ui/CircleMultipleOutline';
import * as React from 'react';
import { CSSProperties, FC, useContext, useMemo } from 'react';
import { OrderDescriptor } from '../../../../../../Api/Order/OrderDescriptor';
import { VerticalDivider } from '../../../../../UI/VerticalDivider';
import { EntranceContextRef } from '../../../../Entrance/EntranceContext';

const useStyles = makeStyles((theme: Theme) => ({
	divider: {
		alignSelf: 'stretch',
	},
	placeContainer: {
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},
		alignItems: 'center',
		display: 'flex',
		padding: theme.spacing(1),
	},
	placeText: {
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(2),
		},
	},
	priceContainer: {
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},
		alignItems: 'center',
		display: 'flex',
		padding: theme.spacing(1),
	},
	priceText: {
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(2),
		},
	},
	root: {
		[theme.breakpoints.down('sm')]: {
			height: 56,
			marginLeft: 28 + theme.spacing(1),
		},
		[theme.breakpoints.up('md')]: {
			height: 64,
			marginLeft: theme.spacing(1),
		},

		alignItems: 'center',
		display: 'flex',
	},
}));

interface OrderInfoHeaderProps
{
	className?: string;
	order: OrderDescriptor;
	style?: CSSProperties;
}

export const OrderInfoHeader: FC<OrderInfoHeaderProps> =
	({
		 className,
		 order,
		 style,
	 }) =>
	{
		const classes = useStyles();

		const {
			localizer,
		} = useContext(EntranceContextRef);

		const divider = useMemo(
			() =>
				<VerticalDivider
					className={classes.divider}
					color={'rgba(0, 0, 0, 0.12)'}
					gradient={false}
				/>,
			[classes.divider],
		);

		const paymentPriceDecimal = useMemo(
			() =>
				order.paymentPrice === undefined
					? undefined
					: new Decimal(order.paymentPrice),
			[order.paymentPrice]
		);

		return <div
			className={`${classes.root}${className ? ` ${className}` : ''}`}
			style={style}
		>
			<Hidden
				mdUp
			>
				{divider}
			</Hidden>
			<div
				className={classes.placeContainer}
			>
				<Restaurant />
				<Typography
					classes={{
						root: classes.placeText,
					}}
				>
					{order.orderingLocationName}
				</Typography>
			</div>
			{divider}
			<div
				className={classes.priceContainer}
			>
				<CircleMultipleOutline />
				<Typography
					classes={{
						root: classes.priceText,
					}}
				>
					{localizer.formatCurrency(paymentPriceDecimal, order.currency)}
				</Typography>
			</div>
		</div>;
	};
