import { Card, List } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useMemo } from 'react';
import { OrderDescriptor } from '../../../../Api/Order/OrderDescriptor';
import { subtractFrom } from '../../../../Api/Util/time/Duration';
import { useRootContext } from '../../../../RootContext';
import { isExternalBillOrderLine } from '../../../../Util/order_line/isExternalBillOrderLine';
import { isRequestBillOrderLine } from '../../../../Util/order_line/isRequestBillOrderLine';
import { isRequestWaiterOrderLine } from '../../../../Util/order_line/isRequestWaiterOrderLine';
import { sortOrderLines } from '../../../../Util/order_line/sortOrderLines';
import { isPaymentTimingOut } from '../../../../Util/Orders/isPaymentTimingOut';
import { isReadyForProcessing } from '../../../../Util/Orders/isReadyForProcessing';
import { OrderActions } from './actions/OrderActions';
import { HistoricalCommentItem } from './items/type/HistoricalCommentItem';
import { HistoricalHandoverItem } from './items/type/HistoricalHandoverItem';
import { HistoricalHeaderItem } from './items/type/HistoricalHeaderItem';
import { HistoricalHeaderLocationItem } from './items/type/HistoricalHeaderLocationItem';
import { HistoricalOrderLineItem } from './items/type/HistoricalOrderLineItem';
import { HistoricalPaymentItem } from './items/type/HistoricalPaymentItem';
import { HistoricalPaymentReferenceItem } from './items/type/HistoricalPaymentReferenceItem';
import { HistoricalProcessingItem } from './items/type/HistoricalProcessingItem';
import { OrderAction } from './OrderAction';
import { OrderEmbeddables } from './OrderEmbeddables';
import { mergeOrderLines } from './utils/mergeOrderLines';

export interface HistoricalOrderProps
{
	actions: OrderAction[];
	order: OrderDescriptor;
	onPaymentClick: (order: OrderDescriptor) => void;
}

export const HistoricalOrder: FC<HistoricalOrderProps> =
	({
		actions,
		order,
		onPaymentClick,
	}) =>
	{
		const {clockService} = useRootContext(true);

		const currentMinute = useObserver(() => clockService.currentMinute);

		const onPaymentClickHandler = useCallback(
			() => onPaymentClick(order),
			[onPaymentClick, order],
		);

		const paymentIsTimingOut = useMemo(
			() =>
				isPaymentTimingOut(
					order,
					subtractFrom(
						{
							seconds: 60,
							nanos: 0,
						},
						currentMinute,
					),
				),
			[currentMinute, order],
		);

		const orderIsARequest = useMemo(
			() =>
				order
					.orderLines
					.some(
						line =>
							isExternalBillOrderLine(line) ||
							isRequestBillOrderLine(line) ||
							isRequestWaiterOrderLine(line),
					)
			,
			[order.orderLines],
		);

		const sortedOrderLines = useMemo(
			() =>
				mergeOrderLines(
					sortOrderLines(
						order.orderLines,
					),
				),
			[order.orderLines],
		);

		return <Card>
			<List
				disablePadding
			>
				<OrderEmbeddables order={order} />
				<HistoricalHeaderLocationItem
					businessId={order.businessId}
					businessName={order.businessName}
					orderingLocation={order.orderingLocationName}
				/>
				<HistoricalHeaderItem
					creationDate={order.dateScheduled ?? order.dateOrdered}
					isPaymentTimingOut={paymentIsTimingOut}
					paymentState={order.paymentState}
					state={order.state}
				/>
				{
					sortedOrderLines.map(
						line =>
							<HistoricalOrderLineItem
								currency={order.currency}
								key={line.uuid}
								line={line}
							/>,
					)
				}
				{
					order.comment !== undefined &&
					<HistoricalCommentItem
						comment={order.comment}
					/>
				}
				{
					order.paymentMethodId !== undefined &&
					order.paymentState !== undefined &&
					order.paymentPrice !== undefined &&
					order.paymentPrice > 0 &&
					<HistoricalPaymentItem
						creationDate={order.dateScheduled ?? order.dateOrdered}
						isPaymentTimingOut={paymentIsTimingOut}
						onClick={onPaymentClickHandler}
						payment={{
							amount: order.paymentPrice,
							currency: order.paymentCurrency,
						}}
						paymentIssuerId={order.paymentIssuerId}
						paymentMethodId={order.paymentMethodId}
						paymentState={order.paymentState}
					/>
				}
				{
					order.paymentReference !== undefined &&
					<HistoricalPaymentReferenceItem
						paymentReference={order.paymentReference}
					/>
				}
				{
					order.processingState !== undefined &&
					<HistoricalProcessingItem
						localizeAsRequest={orderIsARequest}
						processingState={order.processingState}
					/>
				}
				{
					isReadyForProcessing(order) &&
					order.processingState !== 'failed' &&
					order.destinationType !== 'PLACE' &&
					<HistoricalHandoverItem
						businessName={order.businessName}
						destinationType={order.destinationType}
						pickupCode={order.pickupCode}
						scheduledFor={order.scheduledFor}
					/>
				}
				<OrderActions
					order={order}
					actions={actions}
				/>
			</List>
		</Card>;
	};
