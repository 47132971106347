import { useLayoutEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export function useResizeObserver():
	[
		(value: HTMLElement | null) => void,
		(ResizeObserverEntry | undefined)
	]
{
	const [entry, setEntry] = useState<ResizeObserverEntry | undefined>();
	const [node, setNode] = useState<HTMLElement | null>(null);
	const [observer] =
		useState(
			() =>
				new ResizeObserver(
					([entry]) =>
						window.requestAnimationFrame(
							() =>
								setEntry(entry),
						),
				),
		);
	useLayoutEffect(
		() =>
		{
			if (node !== null)
			{
				observer.observe(node);

				return () =>
				{
					observer.disconnect();
				};
			}
		},
		[node, observer],
	);

	return [setNode, entry];
}
