import { DependencyList, useCallback } from 'react';
import { AsyncResult, useAsyncResult } from '../../async/useAsyncResult';
import { useObjectUrlCache } from './useObjectUrlCache';

export function useObjectUrlCacheEntry(
	key: string,
	loader: () => Promise<string>,
	deps: DependencyList
): AsyncResult<string | undefined>
{
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const loaderCallback = useCallback(loader, deps);
	const cache = useObjectUrlCache();

	return useAsyncResult(
		async () =>
		{
			if (cache.has(key))
			{
				return cache.get(key);
			}
			else
			{
				const objectUrl = await loaderCallback();

				if (objectUrl !== undefined)
				{
					cache.set(
						key,
						objectUrl
					);
				}

				return objectUrl;
			}
		},
		[
			cache,
			key,
			loaderCallback,
		]
	);
}