import { Slide, Typography } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext } from 'react';
import { InfoSnackbar } from '../../UI/InfoSnackbar/InfoSnackbar';
import { Page } from '../../UI/Page';
import PageBackButton from '../../UI/PageBackButton';
import { PageContent } from '../../UI/PageContent';
import { EntranceContextRef } from '../Entrance/EntranceContext';
import { LoginCard } from './LoginCard';
import { LoginStore } from './LoginStore';

interface LoginProps
{
    store: LoginStore
}

const Login: FC<LoginProps> =
    (
        {
            store,
        }
    ) =>
    {
        const {brandingService} = useContext(EntranceContextRef);
        return useObserver(() => {
            return <Page
                style={{
                    backgroundImage: `url(${brandingService.backgroundImageUrl})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                }}
            >
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: brandingService.backgroundColor.css,
                    }}
                >
                    <InfoSnackbar
                        store={store.infoSnackbarStore}
                        horizontalAnchor="center"
                        verticalAnchor="bottom"
                    />
                    <PageBackButton
                        onClick={store.onClose}
                    />
                    <PageContent xs>
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'stretch',
                            }}
                        >
                            <div
                                style={{
                                    flex: '0 0 auto',
                                    height: 100,
                                    paddingLeft: 30,
                                    paddingRight: 30,
                                    justifyContent: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        backgroundImage: brandingService.logoImageUrl
                                            ? `url(${brandingService.logoImageUrl})`
                                            : undefined,
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center'
                                    }}
                                />
                            </div>
                            {
                                brandingService.logoSubtitle !== undefined
                                && brandingService.logoSubtitle.trim().length > 0 &&
                                <div
                                    style={{
                                        flex: '0 0 auto',
                                        height: 100,
                                        paddingLeft: 30,
                                        paddingRight: 30,
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            color: brandingService.backgroundContrastTextColor.css,
                                        }}
                                    >
                                        {brandingService.logoSubtitle}
                                    </Typography>
                                </div>
                            }

                            <Slide
                                direction="up"
                                in
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        paddingTop: 10,
                                    }}
                                >
                                    <LoginCard
                                        store={store.loginCardStore}
                                        width="70%"
                                    />
                                </div>
                            </Slide>
                        </div>
                    </PageContent>
                </div>
            </Page>;
        });
    };

export default Login;