import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isWidthDown } from '@material-ui/core/withWidth';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { useIsKioskMode } from '../../../Service/KioskService/Api/useIsKioskMode';
import { useScreenWidth } from '../../../Util/Hooks/useScreenWidth';
import { useBottomBarHeight } from './BottomBarConstants';
import { useAndRequireSessionStatusBarStore } from './SessionStatusBarStoreProvider';

const useStyle = makeStyles(() => ({
	spacer: {
		flexShrink: 0,
		width: '100%',
	},
}));

export const SessionStatusBarPlaceholder: FC =
	() =>
	{
		const classes = useStyle();
		const store = useAndRequireSessionStatusBarStore();
		const bottomBarHeight = useBottomBarHeight();
		const isCollapsedHistoryShown = useObserver(() => store.doShowCollapsedHistory);
		const isCollapsedShoppingCartShown = useObserver(() => store.doShowCollapsedShoppingCart);
		const screenWidth = useScreenWidth();
		const isKioskMode = useIsKioskMode();
		const isAtMostSmallSizedWindow =
			useMemo(
				() =>
					isWidthDown('sm', screenWidth),
				[screenWidth]
			);
		const spacer =
			useMemo(
				() =>
					<Box
						sx={{
							height: bottomBarHeight,
						}}
						className={classes.spacer}
					/>,
				[
					bottomBarHeight,
					classes.spacer,
				]
			);

		if (isCollapsedHistoryShown)
			return spacer;
		else if (isCollapsedShoppingCartShown && (isAtMostSmallSizedWindow || isKioskMode))
			return spacer;
		else
			return null;
	};
