import { makeAutoObservable } from 'mobx';
import { SerializationProfile } from '../../Util/Serialization/Serialization';
import { Routing, RoutingProfile } from '../Business/Routing/Routing';
import { VatGroup } from '../vat_group/VatGroup';

export class ProductFee
{
    // ------------------------- Properties -------------------------

    id: number;
    name: string;
    feePercentage: number;
    feeAmount: number;
    activationTimeScheduleId?: number;
    applyOncePerOrder: boolean;
    public readonly vatGroup: VatGroup | undefined;
    public readonly routing?: Routing;

    // ------------------------ Constructor -------------------------

    constructor()
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}

export const ProductFeeProfile =
    SerializationProfile.create(ProductFee)
        .profile('routing', RoutingProfile);
