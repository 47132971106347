import { BaseStore } from '@intentic/ts-foundation';
import { action, makeObservable, observable } from 'mobx';
import { Business } from '../../../Api/Business/Business';
import { Bridge } from '../../../Bridge/Bridge';
import { BrandingService } from '../../../Service/BrandingInformation/BrandingService';
import { WaiterService } from '../../../Service/UserService/WaiterService';
import { EntranceStore } from '../Entrance/EntranceStore';
import { ManagerView } from './ManagerView';

export const ManagerSmallSidebarWidth = 48;
export const ManagerLargeSidebarWidth = 200;
export const ManagerToolbarHeight = 48;

export class ManagerStore extends BaseStore
{
	// ------------------------ Dependencies ------------------------

	entranceStore: EntranceStore;

	// ------------------------- Properties -------------------------

	bridge: Bridge;
	waiterService: WaiterService;
	brandingService: BrandingService;
	business: Business;
	windowSize = window.innerWidth;
	isSidebarOpen = false;
	view: ManagerView;

	// ------------------------ Constructor -------------------------

	constructor(
		entranceStore: EntranceStore,
		bridge: Bridge,
		business: Business,
		waiterService: WaiterService,
		managerBrandingService: BrandingService,
		view?: ManagerView,
	)
	{
		super();

		makeObservable(
			this,
			{
				entranceStore: observable,
				bridge: observable,
				waiterService: observable,
				brandingService: observable,
				business: observable,
				windowSize: observable,
				isSidebarOpen: observable,
				view: observable,
				setView: action.bound,
			},
		);

		this.entranceStore = entranceStore;
		this.bridge = bridge;
		this.business = business;
		this.waiterService = waiterService;
		this.brandingService = managerBrandingService;

		this.setView(view || 'order-handler');
	}

	// ----------------------- Initialization -----------------------

	initialize(): Promise<void>
	{
		// return Promise.resolve(this.notificationStore.open());
		return Promise.resolve();
	}

	// -------------------------- Computed --------------------------

	// --------------------------- Stores ---------------------------

	// -------------------------- Actions ---------------------------

	setView(view: ManagerView): void
	{
		this.view = view;
	}

	// ------------------------ Public logic ------------------------

	// ----------------------- Private logic ------------------------
}