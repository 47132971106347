// @ts-nocheck
import { BaseStore } from '@intentic/ts-foundation';
import { computed, makeObservable, observable } from 'mobx';
/* eslint-disable */
import * as React from 'react';
import { ComponentClass, FC } from 'react';

export class Screen<T extends BaseStore = BaseStore, C = {}>
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    public readonly id: string;
    public readonly component: () => ComponentClass;
    public readonly route: string | undefined;
    public readonly fromRoute: ((route: string, props: any, parentStore?: BaseStore, parentContexts?: Map<React.Context<any>, any>) => Promise<T | null>) | undefined;

    public readonly context: React.Context<C> | undefined;

    /**
     * This field is not marked {@link observable} because when it is, the app goes into an
     * infinite loop upon startup.
     */
    public readonly contextProvider: React.Provider<C> | undefined;
    public readonly contextFromParent: (parentContext: any, store: T) => C;
    public readonly toRoute: ((store: T) => string) | undefined;
    public readonly childScreens: Array<Screen<any, any>>;
    public readonly leftDrawer: Screen<BaseStore> | undefined;
    public readonly rightDrawer: Screen<BaseStore> | undefined;
    public readonly isDialog: boolean;
    public readonly isContent: boolean;
    public readonly isFullScreen: boolean;
    public readonly allowRoutingToChildScreen: (childScreen: Screen, store: BaseStore) => boolean;
    public readonly hooksProviderComponent?: FC

    // ----------------------- Initialization -----------------------

    constructor(
        id: string,
        component: () => ComponentClass,
        route?: string,
        fromRoute?: (route: string, props: any, parentStore?: BaseStore, parentContext?: any) => Promise<T | null>,
        context?: React.Context<C>,
        contextProvider?: React.Provider<C>,
        contextFromParent: (parentContext: any, store: T) => C = parentContext => parentContext,
        toRoute?: (store: T) => string,
        childScreens: Array<Screen<any, any>> = [],
        leftDrawer?: Screen<BaseStore>,
        rightDrawer?: Screen<BaseStore>,
        isDialog: boolean = false,
        isContent: boolean = false,
        isFullScreen: boolean = false,
        allowRoutingToChildScreen: (childScreen: Screen, store: BaseStore) => boolean = () => true,
        hooksProviderComponent?: FC
    )
    {
        makeObservable(this, {
            toRoute: observable,
            childScreens: observable,
            leftDrawer: observable,
            rightDrawer: observable,
            isDialog: observable,
            isContent: observable,
            isFullScreen: observable,
            allowRoutingToChildScreen: observable,
            screenById: computed,
            hasRoute: computed,
        });

        this.id = id;
        this.component = component;
        this.route = route;
        this.fromRoute = fromRoute;
        this.context = context;
        this.contextProvider = contextProvider;
        this.contextFromParent = contextFromParent;
        this.toRoute = toRoute;
        this.childScreens = childScreens;
        this.leftDrawer = leftDrawer;
        this.rightDrawer = rightDrawer;
        this.isDialog = isDialog;
        this.isContent = isContent;
        this.isFullScreen = isFullScreen;
        this.allowRoutingToChildScreen = allowRoutingToChildScreen;
        this.hooksProviderComponent = hooksProviderComponent;
    }

    // -------------------------- Computed --------------------------

    get screenById(): Map<string, Screen>
    {
        const screenById = new Map<string, Screen>();

        this.childScreens.forEach(
            screen =>
                screenById.set(
                    screen.id,
                    screen));

        return screenById;
    }

    get hasRoute(): boolean
    {
        return this.route != null
            && this.fromRoute != null
            && this.toRoute != null;
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
