import { ArrowBack } from '@material-ui/icons';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback } from 'react';
import { useRootContext } from '../../../../../RootContext';
import { PageTopBar } from '../../../../UI/PageTopBar';
import { ShoppingCartStore } from '../../ShoppingCart/ShoppingCartStore';

interface ComoRewardsTopBarProps
{
	shoppingCartStore: ShoppingCartStore;
}

export const ComoRewardsTopBar: FC<ComoRewardsTopBarProps> = ({
	shoppingCartStore,
}) =>
{
	const {navigator, brandingService, translate} = useRootContext(true);
	const onIconClick = useCallback(() =>
	{
		navigator.popScreen();
	}, [navigator]);

	return useObserver(() =>
		<PageTopBar
			topBarColor={brandingService.topBarColor}
			topBarContrastTextColor={brandingService.tintContrastTextColor}
			backgroundContrastTextColor={brandingService.backgroundContrastTextColor}
			icon={ArrowBack}
			onIconClick={onIconClick}
			title={translate('Client-Loyalty-Como-Rewards')}
			sidebarEnabled={!shoppingCartStore.isEmpty}
		/>,
	);
};
