import { Tab, Tabs } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import { FC, Fragment, useCallback, useState } from 'react';
import { NamedOrderList } from '../NamedOrderList';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexGrow: 1,
    },
    tab: {
        color: theme.palette.common.white,
    },
    tabs: {
        backgroundColor: theme.palette.common.black,
    },
    tabScrollButtons: {
        color: theme.palette.common.white,
    },
}));

interface OrderContainerSingleProps
{
    orderLists: NamedOrderList[];
}

export const OrderContainerSingle: FC<OrderContainerSingleProps> =
    (
        {
            orderLists,
        },
    ) =>
    {
        const classes = useStyles();

        const [activeTabIdx, setActiveTabIdx] = useState(0);

        const renderTab = useCallback(
            (idx: number, name: string) => <Tab
                classes={{
                    root: classes.tab,
                }}
                key={idx}
                label={name}
                value={idx}
            />,
            [classes],
        );

        return <>
            <Tabs
                classes={{
                    root: classes.tabs,
                    scrollButtons: classes.tabScrollButtons,
                }}
                onChange={(event, value) => setActiveTabIdx(value)}
                scrollButtons="on"
                value={activeTabIdx}
                variant="scrollable"
            >
                {
                    orderLists.map((orderList, idx) => renderTab(
                        idx,
                        orderList.name
                    ))
                }
            </Tabs>
            <Fragment
                key={orderLists[activeTabIdx].name}
            >
                {orderLists[activeTabIdx].list}
            </Fragment>
        </>;
    };