import { BaseStore } from '@intentic/ts-foundation';
import { makeObservable, observable } from 'mobx';
import { PublicExternalBillRequest } from '../../../Api/ExternalBill/PublicExternalBillRequest';
import { Bridge } from '../../../Bridge/Bridge';

export class BillStore extends BaseStore
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	readonly bridge: Bridge;
	readonly request: PublicExternalBillRequest;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor(
		bridge: Bridge,
		request: PublicExternalBillRequest,
	)
	{
		super();

		makeObservable(
			this,
			{
				bridge: observable,
				request: observable,
			},
		);

		this.bridge = bridge;
		this.request = request;
	}
}
