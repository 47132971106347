import UIElement from '@adyen/adyen-web/dist/types/components/UIElement';
import Core from '@adyen/adyen-web/dist/types/core';
import { PaymentMethods } from '@adyen/adyen-web/dist/types/types';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PaymentIssuer } from '../../../../../Api/Payment/PaymentIssuer';
import { PaymentMethodDescriptor, TRADITIONAL_PAYMENT_METHOD_ID } from '../../../../../Api/Payment/PaymentMethodDescriptor';
import { AdyenCheckoutData } from '../../../../../lib/adyen/AdyenCheckoutData';
import { AdyenCheckoutState } from '../../../../../lib/adyen/AdyenCheckoutState';
import { useAsyncEffect } from '../../../../../Util/async/useAsyncEffect';
import { BusinessContextRef } from '../../../../Page/Business/BusinessContext';
import { AdyenCheckoutEnvironment } from './AdyenCheckoutEnvironment';
import { useClientOnAdditionalDetails } from './client_hooks/useClientOnAdditionalDetails';
import { useClientOnError } from './client_hooks/useClientOnError';
import { useClientOnSubmit } from './client_hooks/useClientOnSubmit';
import { constructAdyenCheckoutClient } from './util/constructAdyenCheckoutClient';

export const ADYEN_CHECKOUT_APPLE_PAY_TYPE: keyof PaymentMethods = 'applepay';

export const ADYEN_CHECKOUT_GOOGLE_PAY_TYPES: (keyof PaymentMethods)[] = [
	'googlepay',
	'paywithgoogle',
];

interface Context
{
	activeComponent?: UIElement;
	client?: Core;
	data?: AdyenCheckoutData;
	enabled: boolean;
	isLoading: boolean;
	paymentSettingsHasError: boolean;
	refreshPaymentSession: () => void;
	setActiveComponent: (component?: InstanceType<PaymentMethods[keyof PaymentMethods]>) => void;
	setPaymentSettingsHasError: (hasError: boolean) => void;
}

const ContextRef = createContext<Context>(undefined as never);

export function useAdyenCheckoutContext(): Context
{
	return useContext(ContextRef);
}

interface AdyenCheckoutContextProviderProps
{
	enabled: boolean;
	environment: AdyenCheckoutEnvironment;
	onPaymentSessionHasErrorChange: (hasError: boolean) => void;
	paymentIssuer?: PaymentIssuer;
	paymentMethod?: PaymentMethodDescriptor;
}

export const AdyenCheckoutContextProvider: FC<AdyenCheckoutContextProviderProps> =
	({
		children,
		enabled,
		environment,
		onPaymentSessionHasErrorChange,
		paymentIssuer,
		paymentMethod,
	}) =>
	{
		const {businessStore} = useContext(BusinessContextRef);

		const [activeComponent, setActiveComponent] = useState<InstanceType<PaymentMethods[keyof PaymentMethods]> | undefined>();
		const [isLoading, setIsLoading] = useState(false);
		const [paymentSettingsHasError, setPaymentSettingsHasError] = useState(false);

		const checkoutClient = useObserver(() => businessStore.checkoutClient);
		const setCheckoutClient = useObserver(() => businessStore.setCheckoutClient);

		const handleChange = useCallback((state: AdyenCheckoutState) =>
		{
			setPaymentSettingsHasError(state.isValid === false && state.errors !== undefined);
		}, []);

		const onAdditionalDetails = useClientOnAdditionalDetails();
		const onSubmit = useClientOnSubmit();
		const onError = useClientOnError();

		const setupClient = useCallback(
			async () =>
			{
				if (enabled)
				{
					setIsLoading(true);

					const client = await constructAdyenCheckoutClient(
						environment,
						onAdditionalDetails,
						handleChange,
						onError,
						onSubmit,
						false,
					);

					setCheckoutClient(client);

					setIsLoading(false);
				}
				else
				{
					setCheckoutClient(undefined);
				}
			},
			[enabled, environment, handleChange, onAdditionalDetails, onError, onSubmit, setCheckoutClient],
		);

		const refreshClient = useCallback(
			async () =>
			{
				try
				{
					await setupClient();
				}
				finally
				{
					setIsLoading(false);
				}
			},
			[setupClient],
		);

		useAsyncEffect(() => ({
			promise: setupClient?.(),
			then: () => setIsLoading(false),
			catch: () => setIsLoading(false),
		}), [setupClient]);

		useEffect(
			() =>
			{
				if (!isLoading)
				{
					const isTraditional = paymentMethod?.id === TRADITIONAL_PAYMENT_METHOD_ID;

					const hasNoIssuerIfRequired = paymentMethod?.isIssuerRequired
						? paymentIssuer === undefined
						: false;

					if (isTraditional || hasNoIssuerIfRequired)
					{
						onPaymentSessionHasErrorChange(false);
					}
				}
			},
			[checkoutClient, isLoading, onPaymentSessionHasErrorChange, paymentIssuer, paymentMethod?.id, paymentMethod?.isIssuerRequired],
		);

		const contextValue = useMemo(
			() => ({activeComponent, client: checkoutClient, enabled, isLoading, paymentSettingsHasError, refreshPaymentSession: refreshClient, setActiveComponent, setPaymentSettingsHasError}),
			[activeComponent, checkoutClient, enabled, isLoading, paymentSettingsHasError, refreshClient],
		);

		return <ContextRef.Provider value={contextValue}>
			{children}
		</ContextRef.Provider>;
	};
