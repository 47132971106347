import { useObserver } from 'mobx-react-lite';
import { useEffect } from 'react';
import { SessionStatusBarStore } from './SessionStatusBarStore';

export function useSessionStatusBarWindowResizeListener(store: SessionStatusBarStore)
{
	const updateViewportWidth = useObserver(() => store.updateViewportWidth);
	useEffect(
		() => {
			window.addEventListener('resize', updateViewportWidth);
			return () => window.removeEventListener('resize', updateViewportWidth);
		},
		[updateViewportWidth],
	);
}