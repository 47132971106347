// @ts-nocheck
/* eslint-disable */
import { getServiceWorkerRegistration } from './getServiceWorkerRegistration';
import { getPushNotificationPermission } from './getPushNotificationPermission';
import { subscribeToPushNotifications } from './subscribeToPushNotifications';
import { registerServiceWorker } from './registerServiceWorker';

export async function startPushNotifications(): Promise<boolean | undefined> // Promise<PushSubscriptionJSON | undefined>
{
    const granted = getPushNotificationPermission();

    return Promise.resolve(granted);

    // TODO: uncomment for VAPID subscription
    // const registration = getServiceWorkerRegistration();

    // if (granted)
    // {
    //     if (registration)
    //     {
    //         return subscribeToPushNotifications()
    //             .then();
    //     }
    //     else
    //     {
    //         return registerServiceWorker()
    //             .then(subscribeToPushNotifications)
    //             .then(
    //                 subscription =>
    //                     Promise.resolve(subscription));
    //     }
    // }
}