// @ts-nocheck
/* eslint-disable */

import { makeObservable, observable } from 'mobx';
import { Localizer } from '../../../Bridge/Localization/Localizer';
import { Coupon } from './Coupon';

export class DiscountProductTotalRelativeCoupon extends Coupon
{
    /*---------------------------------------------------------------*
     *                          Properties                           *
     *---------------------------------------------------------------*/

    amountOfDiscount: number;

    /*---------------------------------------------------------------*
     *                          Constructor                          *
     *---------------------------------------------------------------*/

    constructor(
        displayName: string,
        businessId: number,
        amountOfDiscount: number,
        id?: number,
        externalId?: string,
    )
    {
        super('DISCOUNT_PRODUCT_TOTAL_RELATIVE', displayName, businessId, id, externalId);

        makeObservable(
            this,
            {
                amountOfDiscount: observable,
            },
        );

        this.amountOfDiscount = amountOfDiscount;
    }

    /*---------------------------------------------------------------*
     *                        Initialization                         *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                           Computed                            *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                            Actions                            *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                          Public logic                         *
     *---------------------------------------------------------------*/

    effectDescriptionShort(
        localizer: Localizer,
        discount: number
    ): string
    {
        return localizer
            .translate(
                'Discount-PercentageDiscount',
                (this.amountOfDiscount * 100).toString()
            );
    }

    effectDescriptionLong(
        localizer: Localizer,
        discount: number
    ): string
    {
        return localizer
            .translate(
                'Discount-PercentageDiscountOffWholeOrder',
                (this.amountOfDiscount * 100).toString()
            );
    }

    /*---------------------------------------------------------------*
     *                         Private logic                         *
     *---------------------------------------------------------------*/
}
