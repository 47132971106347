import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';
import * as React from 'react';
import { ComponentType, FC, useMemo, useReducer } from 'react';
import { OrderEvent } from '../../../../../../Api/Order/OrderEvent';
import { OrderEventDate } from './components/OrderEventDate';
import { OrderEventProps } from './components/OrderEventProps';
import { OrderPaymentEvent } from './components/types/OrderPaymentEvent';
import { OrderProcessingEvent } from './components/types/OrderProcessingEvent';
import { OrderRefundRequestEvent } from './components/types/OrderRefundRequestEvent';
import { OrderStateEvent } from './components/types/OrderStateEvent';

interface OrderEventsListProps
{
	orderEvents: OrderEvent[];
}

export const OrderEventsList: FC<OrderEventsListProps> =
	(
		{
			orderEvents,
		},
	) =>
	{
		const [largestDateWidth, setDateWidth] = useMaxValueOfMap<number>();
		const items = useMemo(
			() =>
				orderEvents
					.filter(event => getComponent(event) !== undefined)
					.map((event, idx) => {
						const Component = getComponent(event)!;
						return <TimelineItem key={idx}>
							<TimelineOppositeContent
								style={{
									maxWidth: largestDateWidth + 32,
									display: 'flex',
									flexDirection: 'row',
									overflowX: 'hidden',
									alignItems: 'center',
								}}
							>
								<OrderEventDate
									date={event.date}
									onWidthChange={width => setDateWidth(idx, width)}
									style={{
										flex: '0 0 auto',
									}}
								/>
							</TimelineOppositeContent>
							<TimelineSeparator>
								{idx > 0 ? <TimelineConnector /> : <div style={{flex: '1'}}/>}
								<TimelineDot />
								{idx < orderEvents.length - 1 ? <TimelineConnector /> : <div style={{flex: '1'}}/>}
							</TimelineSeparator>
							<TimelineContent>
								<Component orderEvent={event} />
							</TimelineContent>
						</TimelineItem>;
					}),
			[largestDateWidth, orderEvents, setDateWidth],
		);

		return <Timeline>
			{items}
		</Timeline>;
	};

function getComponent(event: OrderEvent): ComponentType<OrderEventProps> | undefined
{
	switch (event.type)
	{
		case 'Payment':
			return OrderPaymentEvent;
		case 'Process':
			return OrderProcessingEvent;
		case 'OrderState':
			return OrderStateEvent;
		case 'RefundRequest':
			return OrderRefundRequestEvent;
	}
}

function useMaxValueOfMap<T>(): [number | undefined, (key: T, value: number) => void]
{
	const [map, setValueInMap] = useReducer(
		(dateWidths: Map<T, number>, action: {key: T, value: number}) => {
			const newDateWidths = new Map<T, number>();
			dateWidths.forEach((value, key) => {
				newDateWidths.set(key, value);
			});
			newDateWidths.set(action.key, action.value);
			return newDateWidths;
		},
		new Map<T, number>(),
	);
	const largestValueInMap = useMemo(
		() => {
			let largest: number | undefined = undefined;
			map.forEach((value) => {
				if (largest === undefined || largest < value)
				{
					largest = value;
				}
			});
			return largest;
		},
		[map],
	);
	return [largestValueInMap, (key: T, value: number) => setValueInMap({key, value})];
}