import { Decimal } from 'decimal.js';
import { useMemo } from 'react';
import { ExternalBill } from '../../../../../../../Api/ExternalBill/ExternalBill';

export function useExternalBillSettlerPriceAmount(
	externalBill: ExternalBill,
): Decimal | undefined
{
	return useMemo(
		() =>
			new Decimal(externalBill.remainingAmount),
		[externalBill],
	);
}
