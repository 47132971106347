import { makeAutoObservable } from 'mobx';
import { Storage } from '../Bridge/Storage/Storage';
import { StoredVariable } from './StoredVariable';

export class PeriodLogger
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	private _initialized: boolean;
	public readonly onInitialized: Promise<void>;
	private resolveOnInitialized: () => void;
	private intervalEventHandler: NodeJS.Timer | undefined;
	private lastVisible: StoredVariable<Date | undefined>;
	private _endOfLastPeriod: Date | undefined;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor(
		storage: Storage,
		storageKey: string
	)
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});

		this._initialized = false;
		this.lastVisible = new StoredVariable<Date|undefined>(
			storage,
			storageKey,
			stringValue => stringValue !== undefined
				?
				new Date(Date.parse(stringValue))
				:
				undefined,
			deserializedValue => deserializedValue !== undefined
				?
				deserializedValue.toISOString()
				:
				undefined
		);
		this.onInitialized = new Promise<void>(resolve => {
			this.resolveOnInitialized = resolve;
		});

		this.lastVisible.syncWithStorage();
		this.setEndOfLastPeriod(this.lastVisible.value);
		this.setInitialized();
	}

	/*---------------------------------------------------------------*
	 *                         Business Logic                        *
	 *---------------------------------------------------------------*/

	public get endOfLastPeriod(): Date | undefined
	{
		return this._endOfLastPeriod;
	}

	private get inPeriod(): boolean
	{
		return this.intervalEventHandler !== undefined;
	}

	public get value(): Date | undefined
	{
		return this.lastVisible.value;
	}

	public startPeriod(): void
	{
		if (this.intervalEventHandler === undefined)
		{
			this.intervalEventHandler = setInterval(
				() => {
					this.lastVisible.set(new Date());
				},
				1000
			)
		}
	}

	public endPeriod(): void
	{
		if (this.intervalEventHandler !== undefined)
		{
			clearInterval(this.intervalEventHandler);
			this.intervalEventHandler = undefined;
			this.setEndOfLastPeriod(this.lastVisible.value);
		}
	}

	private setEndOfLastPeriod(endOfLastOpening: Date | undefined): void
	{
		this._endOfLastPeriod = endOfLastOpening;
	}

	private setInitialized(): void
	{
		this._initialized = true;
		this.resolveOnInitialized();
	}
}