import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CartOffIcon from 'mdi-material-ui/CartOff';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { ActionButton } from '../../../../UI/action-button/ActionButton';
import { ProductStore } from '../ProductStore';
import { useCallbackOnClickAddToCart } from './useCallbackOnClickAddToCart';
import { useDoesProductHaveConfigurationScreenBeforeAddingItToCart } from './useDoesProductHaveConfigurationScreenBeforeAddingItToCart';

interface ProductDialogAddButtonProps
{
	store: ProductStore;
}

export const ProductDialogAddButton: FC<ProductDialogAddButtonProps> =
	(
		{
			store
		}
	) =>
	{
		const translate = useTranslate();
		const isInStock = useObserver(() => store.isInStock);
		const orderButtonHasErrorColor = useObserver(() => store.orderButtonHasErrorColor);
		const isConfigurable = useDoesProductHaveConfigurationScreenBeforeAddingItToCart(store.product);
		const orderButtonIsDisabled = useObserver(() => store.orderButtonIsDisabled);
		const configurationStore = useObserver(() => store.configurationStore);

		const onClickAddToCart = useCallbackOnClickAddToCart(store);

		return <ActionButton
			id="product-dialog-add-button"
			text={
				isInStock
					?
					(
						isConfigurable && !configurationStore
							?
							`${translate('Client-Order-Add[ItemToOrder]')}...`
							:
							translate('Client-Order-Add[ItemToOrder]')
					)
					:
					translate('Product-OutOfStock')
			}
			onClick={() => onClickAddToCart()}
			size="medium"
			style={{
				alignSelf: 'stretch',
				margin: 0,
			}}
			error={orderButtonHasErrorColor}
			disabled={orderButtonIsDisabled}
			icon={isInStock ? ShoppingCartIcon : CartOffIcon}
		/>;
	};
