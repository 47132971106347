import { makeAutoObservable } from 'mobx';
import { Storage } from '../Bridge/Storage/Storage';
import { UninitializedStorageVariableException } from './Exception/UninitializedStorageVariableException';

export class StoredVariable<T>
{
    // ------------------------ Dependencies ------------------------

    private storage: Storage;

    // ------------------------- Properties -------------------------

    /**
     * The key this variable will have in {@link Storage}. Warning: {@link Storage}
     * has a global namespace!
     */
    private keyInStorage: string;

    private fromString: (stringValue: string | undefined) => T;
    private valueToString: (value: T) => string | undefined;

    private _value: T;
    private _initialized: boolean;

    // ------------------------ Constructor -------------------------

    /**
     * @param storage The {@link Storage}
     * @param keyInStorage The key this variable will have in {@link Storage}. Warning:
     * {@link Storage} has a global namespace!
     * @param fromString For converting to a real type
     * @param valueToString For readying the value for storage
     */
    constructor(
        storage: Storage,
        keyInStorage: string,
        fromString: (stringValue: string | undefined) => T,
        valueToString: (value: T) => string | undefined,
    )
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
        this.storage = storage;
        this.keyInStorage = keyInStorage;
        this.fromString = fromString;
        this.valueToString = valueToString;
        this._initialized = false;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    get value(): T
    {
        if (!this.initialized)
        {
            throw new UninitializedStorageVariableException(this.keyInStorage);
        }

        return this._value;
    }

    // -------------------------- Actions ---------------------------

    public syncWithStorage(): void
    {
        const storage = this.storage;
        const keyInStorage = this.keyInStorage;
        const fromString = this.fromString;
        const setValue = (value: T) => this.setValue(value);

        const valueInStorage: string = storage.get(keyInStorage);
        const value = fromString(valueInStorage);
        setValue(value);
    }

    public set(value: T): void
    {
        const valueInStorage: string | undefined = this.valueToString(value);
        this.setValue(this.fromString(valueInStorage)); // use deserializer for "materialized" value
                                                        // in case it has real class constructor
        const storage = this.storage;
        const keyInStorage = this.keyInStorage;

        storage.set(keyInStorage, valueInStorage);
    }

    private setValue(value: T): void
    {
        this._value = value;
        this._initialized = true;
    }

    // ------------------------ Public logic ------------------------

    public get(): T
    {
        return this._value;
    }

    public get initialized(): boolean
    {
        return this._initialized;
    }

    // ----------------------- Private logic ------------------------
}
