import { Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { FC } from 'react';
import { useTranslate } from '../../../../../../Bridge/Localization/useTranslate';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		width: '100%',
	},
	titleText: {
		marginRight: `${theme.spacing(0.5)}px !important`,
		userSelect: 'none',
	},
}));

export const PlacePasswordSummary: FC =
	() =>
	{
		const classes = useStyles();

		const translate = useTranslate();

		return <div className={classes.root}>
			<Typography className={classes.titleText} color="textPrimary" variant="body1">
				{translate('Generic-Password')}
			</Typography>
		</div>;
	};