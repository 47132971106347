import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { Schedule } from '@material-ui/icons';
import * as React from 'react';
import { useMemo } from 'react';
import { MaxPlaceSessionDurationMetadata } from '../../../../../Api/Order/OrderRestriction/MaxPlaceSessionDurationMetadata';
import { useCurrentMinute } from '../../../../../Util/clock/useCurrentMinute';
import { useScreenWidth } from '../../../../../Util/Hooks/useScreenWidth';

const useStyles = makeStyles<Theme>(() => ({
	Root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
}));

interface MenuTopBarAppendixMaxPlaceSessionDurationProps
{
	metadata: MaxPlaceSessionDurationMetadata;
}

export const MenuTopBarAppendixMaxPlaceSessionDuration = ({metadata}: MenuTopBarAppendixMaxPlaceSessionDurationProps) =>
{
	const classes = useStyles();
	const screenWidth = useScreenWidth();
	const isSmallScreen = useMemo(() => screenWidth === 'xs', [screenWidth]);

	const currentMinute = useCurrentMinute();

	const durationLeftInMillis = metadata.endDate === undefined ? 0 : new Date(metadata.endDate).getTime() - currentMinute.getTime();
	const hours = Math.floor(durationLeftInMillis / 3600000);
	const minutes = Math.floor((durationLeftInMillis % 3600000) / 60000);

	if (metadata.endDate === undefined)
	{
		return null;
	}
	else
	{
		return <Box
			className={classes.Root}
		>
			<Schedule
				fontSize={isSmallScreen ? 'small' : 'medium'}
				color={durationLeftInMillis < 0 ? 'error' : 'inherit'}
			/>
			&nbsp;
			<Typography
				variant={isSmallScreen ? 'caption' : 'body1'}
				color={durationLeftInMillis < 0 ? 'error' : 'inherit'}
			>
				{
					`${Math.max(0, hours)}:${Math.max(0, minutes).toString(10).padStart(2, '0')}`
				}
			</Typography>
		</Box>;
	}
};
