import { isFriday, isMonday, isSaturday, isSunday, isThursday, isTuesday, isWednesday, nextFriday, nextMonday, nextSaturday, nextSunday, nextThursday, nextTuesday, nextWednesday } from 'date-fns';

export const DayOfWeekValues = [
	'MONDAY',
	'TUESDAY',
	'WEDNESDAY',
	'THURSDAY',
	'FRIDAY',
	'SATURDAY',
	'SUNDAY',
] as const;

export type DayOfWeek = typeof DayOfWeekValues[number];

export function getIsoDayOfWeek(dayOfWeek: DayOfWeek): 1 | 2 | 3 | 4 | 5 | 6 | 7
{
	switch (dayOfWeek)
	{
		case 'MONDAY':
			return 1;

		case 'TUESDAY':
			return 2;

		case 'WEDNESDAY':
			return 3;

		case 'THURSDAY':
			return 4;

		case 'FRIDAY':
			return 5;

		case 'SATURDAY':
			return 6;

		case 'SUNDAY':
			return 7;

		default:
			throw new Error(`Unknown day of week: ${dayOfWeek}`);
	}
}

export function getDayInCurrentOrNextWeek(timestamp: Date, dayOfWeek: DayOfWeek): Date
{
	return isDayOfWeek(timestamp, dayOfWeek)
		? timestamp
		: getDayInNextWeek(timestamp, dayOfWeek);
}

export function isDayOfWeek(timestamp: Date, dayOfWeek: DayOfWeek): boolean
{
	switch (dayOfWeek)
	{
		case 'MONDAY':
			return isMonday(timestamp);
		case 'TUESDAY':
			return isTuesday(timestamp);
		case 'WEDNESDAY':
			return isWednesday(timestamp);
		case 'THURSDAY':
			return isThursday(timestamp);
		case 'FRIDAY':
			return isFriday(timestamp);
		case 'SATURDAY':
			return isSaturday(timestamp);
		case 'SUNDAY':
			return isSunday(timestamp);
	}
}

export function getDayInNextWeek(timestamp: Date, dayOfWeek: DayOfWeek): Date
{
	switch (dayOfWeek)
	{
		case 'MONDAY':
			return nextMonday(timestamp);
		case 'TUESDAY':
			return nextTuesday(timestamp);
		case 'WEDNESDAY':
			return nextWednesday(timestamp);
		case 'THURSDAY':
			return nextThursday(timestamp);
		case 'FRIDAY':
			return nextFriday(timestamp);
		case 'SATURDAY':
			return nextSaturday(timestamp);
		case 'SUNDAY':
			return nextSunday(timestamp);
	}
}
