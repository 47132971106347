import { useEffect } from 'react';
import { PaymentIssuer } from '../../../../../../Api/Payment/PaymentIssuer';
import { StorageVars } from '../../../../../../Constants/StorageConstants';
import { useStorage } from '../../../../../Root/StorageContextProvider';

export function useStoreSelectedPaymentIssuer(selectedPaymentIssuer: PaymentIssuer | undefined, didSelectInitialPaymentIssuers: boolean): void
{
	const storage = useStorage(true);

	useEffect(() =>
	{
		if (didSelectInitialPaymentIssuers)
		{
			storage.set(StorageVars.PaymentIssuer, selectedPaymentIssuer?.id);
		}
	}, [didSelectInitialPaymentIssuers, selectedPaymentIssuer?.id, storage]);
}