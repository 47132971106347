import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { FC, ReactElement, useMemo } from 'react';
import { UIColors } from '../../../Constants/UIColors';
import { useRootContext } from '../../../RootContext';
import { useScreenOrientation } from '../../../Util/Hooks/useScreenOrientation';
import { PageTopBar } from '../../UI/PageTopBar';
import { ErrorPageIcon } from './ErrorPageIcon';
import { ErrorPageText } from './ErrorPageText';

const useStyles = makeStyles(() => ({
	contentRoot: {
		alignItems: 'center',
		display: 'flex',
		flexGrow: 1,
		height: '100%',
		maxWidth: 800,
		padding: 50,
	},
	contentRootPortrait: {
		flexDirection: 'column',
		justifyContent: 'center',
	},
	root: {
		alignItems: 'center',
		backgroundColor: UIColors.black,
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		minHeight: 320,
		width: '100%',
	},
}));


interface ErrorPageProps
{
	title: string
	message: string
	exceptionId?: string
	component?: ReactElement
}

export const ErrorPage: FC<ErrorPageProps> =
	(
		{
			title,
			message,
			exceptionId,
			component,
		},
	) =>
	{
		const classes = useStyles({});
		const orientation = useScreenOrientation();
		const isPortrait = useMemo(() => orientation === 'portrait', [orientation]);
		const contentRootClassesString = useMemo(
			() => isPortrait
				?
				`${classes.contentRoot} ${classes.contentRootPortrait}`
				:
				classes.contentRoot,
			[classes.contentRoot, classes.contentRootPortrait, isPortrait],
		);
		const {brandingService} = useRootContext(true);

		return <div
			className={classes.root}
		>
			<PageTopBar
				topBarColor={brandingService.topBarColor}
				topBarContrastTextColor={brandingService.topBarContrastTextColor}
				imageUrl={brandingService.logoInlineImageUrl}
				sidebarEnabled={false}
				inline
			/>
			<div
				className={contentRootClassesString}
			>
				<ErrorPageIcon
					isPortrait={isPortrait}
					component={component}
				/>
				<ErrorPageText
					exceptionId={exceptionId}
					isPortrait={isPortrait}
					title={title}
					message={message}
				/>
			</div>
		</div>;
	};