import * as React from 'react';
import { createContext, FC, useContext, useMemo } from 'react';
import { StorageVars } from '../../../../Constants/StorageConstants';
import { IllegalStateException } from '../../../../Util/Exception/IllegalStateException';
import { useStoredVariable } from '../../../../Util/useStoredVariable';

interface Context
{
	isSet: true
	filterRouting: string | undefined
	setFilterRouting: (value: string | undefined) => void
}

const ContextRef = createContext({} as Context);

interface ManagerFilterRoutingContextProviderProps
{
}

export const ManagerFilterRoutingContextProvider: FC<ManagerFilterRoutingContextProviderProps> =
	(
		{
			children,
		},
	) =>
	{
		const [filterRouting, setFilterRouting] = useStoredVariable(
			StorageVars.ManagerFilterRouting,
			stringValue => stringValue,
			value => value
		);
		return <ContextRef.Provider
			value={useMemo(() => ({
				isSet: true,
				filterRouting,
				setFilterRouting,
			}), [filterRouting, setFilterRouting])}
		>
			{children}
		</ContextRef.Provider>;
	};

export function useManagerFilterRouting(): [string | undefined, (value: string | undefined) => void]
{
	const {
		isSet,
		filterRouting,
		setFilterRouting,
	} = useContext(ContextRef);
	return useMemo(
		() => {
			if (!isSet)
				throw new IllegalStateException(
					'Attempting to use useOrderHandlerFilterRouting outside of FilterRoutingContextProvider'
				);
			return [
				filterRouting,
				setFilterRouting,
			];
		},
		[filterRouting, isSet, setFilterRouting]
	);
}
