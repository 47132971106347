import { List } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { ProductRecommendationList as ProductRecommendationListModel } from '../../../../../Api/Product/ProductRecommendationList';
import { useLocalizer } from '../../../../../Bridge/Localization/useLocalizer';
import { useIsKioskMode } from '../../../../../Service/KioskService/Api/useIsKioskMode';
import { ProductRecommendationList } from './ProductRecommendationList';

interface ProductRecommendationListsProps
{
	hideTexts?: boolean;
	productRecommendationLists: ProductRecommendationListModel[];
}

export const ProductRecommendationLists: FC<ProductRecommendationListsProps> =
	(
		{
			hideTexts,
			productRecommendationLists,
		},
	) =>
	{
		const localizer = useLocalizer();
		const hasRecommendations = useMemo(() => productRecommendationLists.length > 0, [productRecommendationLists.length]);
		const isKioskMode = useIsKioskMode();

		return <List>
			{
				hasRecommendations
					?
					productRecommendationLists.map(
						productRecommendationList =>
							<ListItem
								key={productRecommendationList.uuid}
							>
								<ProductRecommendationList
									hideText={hideTexts}
									productRecommendationList={productRecommendationList}
									gridItemType={
										isKioskMode
											? 'TILE_V2'
											: 'TILE'
									}
								/>
							</ListItem>,
					)
					:
					localizer.translate('Product-Recommendations-None')
			}
		</List>;
	};