import chroma from 'chroma-js';
import { makeAutoObservable } from 'mobx';
import { SerializationProfile } from '../../Util/Serialization/Serialization';

export class Color
{
    // ------------------------- Properties -------------------------

    r: number;
    g: number;
    b: number;
    private _a: number;

    // ------------------------ Constructor -------------------------

    constructor(r: number, g: number, b: number, a?: number)
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
        this.r = r;
        this.g = g;
        this.b = b;
        this.a = a ?? 1;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    get css(): string
    {
        return `rgba(${this.r},${this.g},${this.b},${this.a})`;
    }

    get chroma(): chroma.Color
    {
        return chroma(this.css);
    }

    get isDark(): boolean
    {
        return this.chroma.luminance() <= 0.5;
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    withAlpha(alpha: number)
    {
        return new Color(
            this.r,
            this.g,
            this.b,
            alpha,
        );
    }

    // ----------------------- Private logic ------------------------

    // -------------------- Getters and setters ---------------------

    get a(): number
    {
        return this._a !== undefined
            ?
                this._a
            :
                1;
    }

    set a(a: number)
    {
        this._a = a;
    }
}

export const ColorProfile =
    SerializationProfile.create(Color);
