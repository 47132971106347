import { useObserver } from 'mobx-react-lite';
import { useMemo } from 'react';
import { toISODurationString } from '../../../../Api/Util/time/iso8601';
import { TimeScheduleSpecification } from '../../../../Api/Util/time/TimeScheduleSpecification';
import { nonNullish } from '../../../../Util/nonNullish';
import { useCurrentPlaceService } from '../../../current-place-service/CurrentPlaceService';
import { useCurrentOrderService } from '../BusinessContext';
import { useAllowedOrderingTimeScheduleWithoutMinimum } from './useAllowedOrderingTimeScheduleWithoutMinimum';

/**
 * Takes the following into accounts:
 *
 * <ul>
 * 	<li>Allowed pre-ordering times set on current place</li>
 * 	<li>Minimum ordering time set on current place</li>
 * 	<li>Maximum preparation time set on current products</li>
 * 	<li>Orderability time schedules set on current products</li>
 * </ul>
 *
 * Explicitly not taking into account:
 *
 * <ul>
 * 	<li>Ordering time restrictions due to order restrictions (handled in API))</li>
 * 	<li>Orderability time schedules set on current menu cards (deprecated))</li>
 * 	<li>Orderability time schedules set on current product categories (deprecated)</li>
 * </ul>
 */
export function useAllowedOrderingTimeSchedule(): TimeScheduleSpecification
{
	const currentOrderService = useCurrentOrderService(true);
	const currentPlaceService = useCurrentPlaceService();
	const baseTimeSchedule = useAllowedOrderingTimeScheduleWithoutMinimum();

	const maximumPreparationTime = useObserver(() => currentOrderService.maximumPreparationTime);
	const minimumOrderTimeInFuture = useObserver(() => currentPlaceService.place.minimumOrderTimeInFuture);

	const timeZone = useMemo(
		() =>
			Intl
				.DateTimeFormat()
				.resolvedOptions()
				.timeZone,
		[],
	);

	const minimumOrderTimeTimeSchedule = useMemo<TimeScheduleSpecification>(
		() =>
			({
				type: 'EXACT_WINDOW_OFFSET_FROM_NOW',
				fromInclusive: toISODurationString(minimumOrderTimeInFuture),
				timeZone,
			}),
		[minimumOrderTimeInFuture, timeZone],
	);

	const maximumPreparationTimeTimeSchedule = useMemo<TimeScheduleSpecification>(
		() =>
			maximumPreparationTime === undefined
				? undefined
				: ({
					type: 'EXACT_WINDOW_OFFSET_FROM_NOW',
					fromInclusive: toISODurationString(maximumPreparationTime),
					timeZone,
				}),
		[maximumPreparationTime, timeZone],
	);

	return useMemo<TimeScheduleSpecification>(
		() =>
			({
				type: 'CONJUNCTION',
				terms: [
					minimumOrderTimeTimeSchedule,
					maximumPreparationTimeTimeSchedule,
					baseTimeSchedule,
				]
					.filter(nonNullish),
			}),
		[baseTimeSchedule, maximumPreparationTimeTimeSchedule, minimumOrderTimeTimeSchedule],
	);
}
