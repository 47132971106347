import { makeAutoObservable } from 'mobx';
import { SerializationProfile } from '../../Util/Serialization/Serialization';

export class PaymentIssuer
{
    id: string;
    name: string;
    group: string | undefined;

    constructor()
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
    }
}

export const PaymentIssuerProfile =
    SerializationProfile.create(PaymentIssuer);
