import * as React from 'react';
import { FC } from 'react';
import { ProductConfiguration } from '../../../../../../Api/Product/ProductConfiguration';
import { useComputed } from '../../../../../../Util/Hooks/useComputed';
import { OrderBuilderStore } from '../../OrderBuilderStore';
import { ProductRecommendationOrderBuilderSegmentRecommendation } from './recommendation/ProductRecommendationOrderBuilderSegmentRecommendation';

interface ProductRecommendationOrderBuilderSegmentProps
{
	store: OrderBuilderStore
}

export const ProductRecommendationOrderBuilderSegment: FC<ProductRecommendationOrderBuilderSegmentProps> =
	(
		{
			store,
		},
	) =>
	{
		const configurations =
			useComputed(
				() =>
					store.currentOrderService.configurations
						.filter(
							configuration =>
								store.businessStore.hasProductRecommendations(
									configuration.product.id,
									'AT_SHOPPING_CART'
								)
						),
				[store]
			);
		const hasForm =
			useComputed(
				() =>
					configurations.length > 0,
				[configurations]
			);

		if (hasForm)
		{
			return <Inner
				store={store}
				configurations={configurations}
			/>;
		}
		else
		{
			return null;
		}
	};

interface InnerProps extends ProductRecommendationOrderBuilderSegmentProps
{
	configurations: ProductConfiguration[];
}

export const Inner: FC<InnerProps> =
	(
		{
			store,
			configurations,
		},
	) =>
	{
		const recommendationLists =
			useComputed(
				() =>
					Array.from(
						new Set(
							configurations
								.map(
									configuration =>
										store.businessStore.getProductRecommendationLists(configuration.product.id)
											.filter(
												list =>
													list.moment === 'AT_SHOPPING_CART'
											)
								)
								.reduce(
									(a, b) =>
										a.concat(b),
									[]
								)
						)
					),
				[configurations, store]
			);
		const recommendationListViewers =
			useComputed(
				() =>
					recommendationLists
						.filter(
							list =>
								store.businessStore.hasVisibleRecommendations(list)
						)
						.map(
							list =>
								<ProductRecommendationOrderBuilderSegmentRecommendation
									key={list.uuid}
									list={list}
								/>,
						),
				[recommendationLists, store]
			);

		return <>
			{recommendationListViewers}
		</>;
	};
