import { useMemo } from 'react';
import { useScreenWidth } from '../../../../../../Util/Hooks/useScreenWidth';

export function useSpacingBetweenFullscreenMenuGridColumns(): 1 | 3
{
	const screenWidth = useScreenWidth();

	return useMemo(() => {
		switch (screenWidth)
		{
			case 'xs':
				return 1;

			default:
				return 3;
		}
	}, [screenWidth]);
}
