import { PaymentMethodDescriptor } from '../../../../../../../Api/Payment/PaymentMethodDescriptor';

export const AdyenCheckoutPaymentMethodTypesRequiringUserInput = ['bcmc', 'scheme'];

export function adyenCheckoutPaymentMethodRequiresUserInput(paymentMethod?: PaymentMethodDescriptor): boolean
{
	const type = paymentMethod?.butlarooPayPaymentMethod?.type;

	return type === undefined
		? false
		: AdyenCheckoutPaymentMethodTypesRequiringUserInput.includes(type);
}