import { Storage } from '../../../Bridge/Storage/Storage';
import { StorageVars } from '../../../Constants/StorageConstants';
import { getAuthUrl } from '../../../Util/Api/Resources/getAuthUrl';
import { toQueryParams } from '../../../Util/toQueryParams';
import { AuthenticationResultV3 } from '../AuthenticationResult';
import { authorize } from './authorize';
import { getJwkSet } from './JwkSet';
import { validateToken } from './validateToken';

export async function resolveAuthentication(storage: Storage, code: string, state: string, recoverMode: boolean): Promise<AuthenticationResultV3>
{
	const redirectUri = `${window.location.origin}/auth${recoverMode ? '?recover' : ''}`;

	const oidcState = storage.get(StorageVars.OidcState);
	const oidcCodeVerifier = (storage.get(StorageVars.OidcCodeVerifier) ?? '');
	storage.set(StorageVars.OidcState);
	storage.set(StorageVars.OidcCodeVerifier);

	if (state !== oidcState)
	{
		if (recoverMode)
		{
			console.warn('Invalid state detected, retrying authentication.');
			throw new Error('Invalid state detected');
		}
		else
		{
			// we are assuming we are authenticated, but the browser state has changed.
			await authorize(storage, true, undefined, true);
		}
	}

	const body = {
		code: code,
		grant_type: 'authorization_code',
		client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
		redirect_uri: redirectUri,
		code_verifier: oidcCodeVerifier,
	};

	const response = await fetch(
		getAuthUrl('/oauth2/token'),
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
			},
			body: toQueryParams(body),
		},
	);

	const tokens = await response.json();
	const jwkSet = getJwkSet();

	const accessTokenPayload = await validateToken(jwkSet, tokens.access_token);
	const idTokenPayload = await validateToken(jwkSet, tokens.id_token);

	return {
		version: 'V3',
		accessToken: tokens.access_token,
		refreshToken: tokens.refresh_token,
		accessTokenPayload,
		idTokenPayload,
	};
}
