export interface TimeSlot
{
	start: Date;
	end: Date;
}

export interface TimeSlotDto
{
	start: string;
	end: string;
}

export function toTimeSlot(dto: TimeSlotDto): TimeSlot
{
	return {
		start: new Date(dto.start),
		end: new Date(dto.end),
	};
}

export function toTimeSlotDto(timeSlot: TimeSlot): TimeSlotDto
{
	return {
		start: timeSlot.start.toISOString(),
		end: timeSlot.end.toISOString(),
	};
}
