import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import * as React from 'react';
import { FC, useCallback, useMemo } from 'react';
import { OrderProcessingState, OrderState, PaymentState } from '../../../../../../../Api/Order/Order';
import { OrderEventType } from '../../../../../../../Api/Order/OrderEvent';
import { useLocalizer } from '../../../../../../../Bridge/Localization/useLocalizer';
import { getOrderEventStateTranslationEntry } from '../localization';

const useStyle = makeStyles(() => ({
	states: {
		alignItems: 'center',
		display: 'flex',
	},
}));

interface OrderEventStateTransitionProps
{
	fromState?: OrderProcessingState | OrderState | PaymentState;
	toState?: OrderProcessingState | OrderState | PaymentState;
	orderEventType: OrderEventType;
	secondary?: boolean;
}

export const OrderEventStateTransition: FC<OrderEventStateTransitionProps> =
	(
		{
			fromState,
			toState,
			orderEventType,
			secondary,
		},
	) =>
	{
		const localizer = useLocalizer();

		const classes = useStyle();

		const hasFromState = useMemo(() => fromState !== undefined, [fromState]);
		const hasToState = useMemo(() => toState !== undefined, [toState]);
		const isSameState = useMemo(() => fromState === toState, [fromState, toState]);

		const translateState = useCallback((state: OrderProcessingState | OrderState | PaymentState) => {
			const languageEntry = getOrderEventStateTranslationEntry(orderEventType, state);
			return localizer.translate(languageEntry);
		}, [localizer, orderEventType]);

		if (!hasFromState && !hasToState)
			return null;
		else if (isSameState)
			return <OrderEventStateTransitionText secondary={secondary} text={translateState(toState!)} />;
		else if (hasFromState && !hasToState)
			return <OrderEventStateTransitionText secondary={secondary} text={translateState(fromState!)} />;
		else if (!hasFromState && hasToState)
			return <OrderEventStateTransitionText secondary={secondary} text={translateState(toState!)} />;
		else
			return <div className={classes.states}>
				<OrderEventStateTransitionText secondary={secondary} text={`${translateState(fromState!)} `} />
				<OrderEventStateTransitionIcon secondary={secondary} />
				<OrderEventStateTransitionText secondary={secondary} text={` ${translateState(toState!)}`} />
			</div>;
	};

const useOrderEventStateTransitionIconStyle = makeStyles((theme: Theme) => ({
	iconSecondary: {
		color: theme.palette.text.secondary,
	},
}));

interface OrderEventStateTransitionIconProps
{
	secondary?: boolean;
}

const OrderEventStateTransitionIcon: FC<OrderEventStateTransitionIconProps> =
	(
		{
			secondary,
		},
	) =>
	{
		const classes = useOrderEventStateTransitionIconStyle();

		const className = useMemo(() => secondary ? classes.iconSecondary : undefined, [classes.iconSecondary, secondary]);

		return <ArrowRightAltIcon className={className} />;
	};

const useOrderEventStateTransitionTextPropsStyle = makeStyles(() => ({
	text: {
		fontStyle: 'italic',
	},
}));

interface OrderEventStateTransitionTextProps
{
	secondary?: boolean;
	text: string;
}

const OrderEventStateTransitionText: FC<OrderEventStateTransitionTextProps> =
	(
		{
			secondary,
			text,
		},
	) =>
	{
		const classes = useOrderEventStateTransitionTextPropsStyle();

		return <Typography className={classes.text} color={secondary ? 'textSecondary' : 'textPrimary'}>{text}</Typography>;
	};