// @ts-nocheck
/* eslint-disable */
import { BaseStore } from '@intentic/ts-foundation';
import { action, makeObservable, observable, override } from 'mobx';
import { DietaryFlag } from '../../../Api/Product/DietaryFlag';
import { NutritionFlag } from '../../../Api/Product/NutritionFlag';
import { ProfileService } from '../../../Service/ProfileService';

export class ProfileStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    readonly profileService: ProfileService;
    readonly nutritionFlags = observable.map<string, boolean>();
    readonly allergens = observable.map<string, boolean>();
    isInitialized: boolean;
    readonly close: () => Promise<boolean>;

    // ------------------------ Constructor -------------------------

    constructor(
        profileService: ProfileService,
        close: () => Promise<boolean>,
    )
    {
        super();

        makeObservable(this, {
            initialize: override,
            toggleNutritionFlag: action.bound,
            toggleAllergen: action.bound,
        });

        this.profileService = profileService;
        this.close = close;
        this.isInitialized = false;
    }

    // ----------------------- Initialization -----------------------

    initialize(): Promise<Promise<void>[]>
    {
        return Promise.resolve();
    }

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    public toggleNutritionFlag(flag: DietaryFlag, doPersist: boolean = true): Promise<void>
    {
        return this.profileService.toggleDietaryFlag(flag, doPersist);
    }

    public toggleAllergen(allergen: string, doPersist: boolean = true): Promise<void>
    {
        return this.profileService.toggleAllergen(allergen, doPersist);
    }

    // ------------------------ Public logic ------------------------

    hasChecked(flag: DietaryFlag): boolean
    {
        return this.profileService.hasDietaryFlag(flag);
    }

    needsToSeeNutritionFlag(flag: NutritionFlag): boolean
    {
        return this.profileService.needsToSeeNutritionFlag(flag);
    }

    hasAllergen(allergen: string): boolean
    {
        return this.profileService.hasAllergen(allergen);
    }

    // ----------------------- Private logic ------------------------
}
