import { Checkbox, FormControlLabel, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useState } from 'react';

const useStyles = makeStyles(
    (theme: Theme) =>
        ({
            form:
                {
                    marginBottom: theme.spacing(1),
                    marginTop: theme.spacing(1),
                },
        }));

export interface OpenMenuProps
{
    onLocked: (locked: boolean) => void;
}

export const OpenMenu: FC<OpenMenuProps> =
    observer(
        ({
             onLocked,
         }) =>
        {
            const classes = useStyles();

            const [isLocked, setIsLocked] = useState(false);

            const onLockedChange = useCallback(
                (_, checked) =>
                {
                    setIsLocked(checked);
                    onLocked(checked);
                },
                [onLocked]);

            return <>
                <FormControlLabel
                    classes={{
                        root: classes.form,
                    }}
                    control={
                        <Checkbox
                            checked={isLocked}
                            color="primary"
                            onChange={onLockedChange}
                        />
                    }
                    label="User cannot leave menu without entering a PIN code"
                />
            </>;
        });
