import { makeStyles } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext, useEffect, useRef } from 'react';
import { Order } from '../../../../../Api/Order/Order';
import { isValidAdyenCheckoutComponent } from '../../../../../Util/payment/isValidAdyenCheckoutComponent';
import { BusinessContextRef } from '../../../../Page/Business/BusinessContext';
import { getAdyenCheckoutPaymentMethodAlias } from '../../adyen/util/getAdyenCheckoutPaymentMethodAlias';
import { useOrderPaymentContext } from '../../context/OrderPaymentContext';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
	},
});

interface PaymentDialogStartAdyenPaymentButtonProps
{
	order: Order;
	paymentMethod: string;
}

export const PaymentDialogStartAdyenPaymentButton: FC<PaymentDialogStartAdyenPaymentButtonProps> =
	({
		order,
		paymentMethod,
	}) =>
	{
		const {businessStore} = useContext(BusinessContextRef);
		const {onPaymentProcessingResult} = useOrderPaymentContext();

		const classes = useStyles();

		const containerRef = useRef<HTMLDivElement>(null);

		const checkoutClient = useObserver(() => businessStore.checkoutClient);

		useEffect(() =>
		{
			if (checkoutClient !== undefined)
			{
				const name = getAdyenCheckoutPaymentMethodAlias(paymentMethod) ?? paymentMethod;

				const component = checkoutClient.create(
					name
						.replace('adyen-', '')
						.toLowerCase(),
				);

				if (isValidAdyenCheckoutComponent(order, component))
				{
					if (containerRef.current !== null)
					{
						component.mount(containerRef.current);

						component.update({
							showPayButton: true,
						});

						return () =>
						{
							component.unmount();
						};
					}
				}
				else
				{
					onPaymentProcessingResult({
						error: new Error(
							`Invalid payment component with session ID "${component.props.session.session.id}" and amount "${component.props.amount.currency} ${component.props.amount.value}" for order ${order.uuid}`,
						),
						state: 'Failed',
					});
				}
			}
		}, [checkoutClient, onPaymentProcessingResult, order, paymentMethod]);

		return <div
			className={classes.root}
			ref={containerRef}
		/>;
	};
