import { makeObservable, observable } from 'mobx';
import { v4 as uuid } from 'uuid';
import { DialogInstance } from './DialogInstance';
import { DialogProps } from './DialogProps';

export abstract class Dialog
{
    dialogs = observable.array<DialogInstance>();

    constructor()
    {
        makeObservable(
            this,
            {
                dialogs: observable,
            },
        );
    }

    protected pushNewInstance(props: DialogProps): DialogInstance
    {
        const instance =
            new DialogInstance(
                uuid(),
                props,
                instance => this.dialogs.remove(instance));

        this.dialogs.push(instance);

        return instance;
    }

    abstract present(props: DialogProps): Promise<DialogInstance>;
}
