import { FC, useContext, useEffect, useMemo } from 'react';
import { QueryParameters } from '../../Constants/QueryParameters';
import { StorageVars } from '../../Constants/StorageConstants';
import { QueryParameterContext } from '../query-parameter-intercepter/QueryParameterContext';
import { useStorage } from '../Root/StorageContextProvider';
import { isAppVersion } from './AppVersion';
import { AppVersionContext } from './AppVersionContext';

export const AppVersionContextProvider: FC =
	({
		children,
	}) =>
	{
		const storage = useStorage(true);
		const queryParameters = useContext(QueryParameterContext);

		const appVersion = useMemo(
			() =>
			{
				const rawValue = window.location.pathname.startsWith('/place/-')
					? queryParameters.get(QueryParameters.AppVersion) ?? storage.get(StorageVars.AppVersion)
					: queryParameters.get(QueryParameters.AppVersion);

				return isAppVersion(rawValue)
					? rawValue
					: 'V2';
			},
			[queryParameters, storage],
		);

		useEffect(
			() =>
				storage.set(StorageVars.AppVersion, appVersion),
			[appVersion, storage],
		);

		return <AppVersionContext.Provider
			value={appVersion}
		>
			{children}
		</AppVersionContext.Provider>;
	};
