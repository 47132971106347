import { DependencyList, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

export interface AsyncResult<T>
{
	isLoading: boolean;
	result?: T;
	setResult: Dispatch<SetStateAction<T | undefined>>;
	error?: any;
	reload: () => void;
}

export function useAsyncResult<T>(
	callback: () => Promise<T>,
	deps: DependencyList,
): AsyncResult<T>
{
	const [isLoading, setLoading] = useState(true);
	const [result, setResult] = useState<T | undefined>(undefined);
	const [error, setError] = useState<any | undefined>(undefined);
	const [state, setState] = useState(0);
	const reload =
		useCallback(
			() => setState(value => value + 1),
			[setState]
		);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const memoizedCallback = useCallback(callback, deps);

	useEffect(
		() =>
		{
			setLoading(true);

			memoizedCallback()
				.then(
					result =>
					{
						setResult(result);
						setError(undefined);
					},
				)
				.catch(
					reason =>
					{
						console.log('error', reason);

						setError(reason);
					},
				)
				.finally(
					() =>
					{
						setLoading(false);
					},
				);
		},
		[setLoading, memoizedCallback, setResult, setError, state],
	);

	return {
		isLoading: isLoading,
		result: result,
		setResult: setResult,
		error: error,
		reload: reload,
	};
}
