import { BaseStore } from '@intentic/ts-foundation';
import { makeObservable, observable } from 'mobx';
import { ProductRecommendationList } from '../../../../../Api/Product/ProductRecommendationList';

export class ProductRecommendationStore extends BaseStore
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	triggeringProductId: number;
	productRecommendationLists: ProductRecommendationList[];

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor(
		triggeringProductId: number,
		productRecommendationLists: ProductRecommendationList[],
	)
	{
		super();

		makeObservable(
			this,
			{
				triggeringProductId: observable,
				productRecommendationLists: observable,
			},
		);

		this.triggeringProductId = triggeringProductId;
		this.productRecommendationLists = productRecommendationLists;
	}

	/*---------------------------------------------------------------*
	 *                         Business Logic                        *
	 *---------------------------------------------------------------*/
}