import UIElement from '@adyen/adyen-web/dist/types/components/UIElement';
import { CheckoutSessionPaymentResponse } from '@adyen/adyen-web/dist/types/types';
import { AdyenCheckoutState } from '../../../../../../lib/adyen/AdyenCheckoutState';
import { postJsonAny } from '../../../../../../Util/Api';
import { useEventCallback } from '../../../../../../Util/Event/useEventCallback';
import { useProcessingOrderContext } from '../../../../processing_order_context/ProcessingOrderContext';
import { useProcessAdyenCheckoutPaymentResponse } from '../../../hooks/useProcessAdyenCheckoutPaymentResponse';

export function useClientOnAdditionalDetails(): (state: AdyenCheckoutState, component: UIElement<unknown>) => Promise<void>
{
	const {processingOrder} = useProcessingOrderContext();

	const processAdyenCheckoutPaymentResponse = useProcessAdyenCheckoutPaymentResponse();

	return useEventCallback(
		async (state, component) =>
		{
			if (processingOrder === undefined)
			{
				throw new Error('No payment in progress');
			}
			else
			{
				const response = await postJsonAny<CheckoutSessionPaymentResponse>(
					'/client/payments/ButlarooPay/details',
					undefined,
					{
						orderId: processingOrder.id,
						threeDsResult: state.data.details.threeDSResult,
					},
				);

				return processAdyenCheckoutPaymentResponse(
					response,
					component,
				);
			}
		},
	);
}
