import * as React from 'react';
import { ComponentType, createElement, CSSProperties, FC, useCallback, useMemo } from 'react';
import { Fade, Theme, Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PeopleIcon from '@material-ui/icons/People';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import { PlaceSession } from '../../../../../Api/Business/PlaceSession';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles(
	(theme: Theme) =>
		({
			duoIconRoot:
				{
					display: 'flex',
					justifyContent: 'space-between',
				},
			root:
				{
					padding: theme.spacing(1),
					position: 'absolute',
					top: 0,
					width: '100%',
				},
			icon:
				{
					fontSize: 16,
					opacity: 0.75,
				},
			iconRoot:
				{
					display: 'flex',
					justifyContent: 'center',
					paddingBottom: theme.spacing(0.5),
				},
			iconText:
				{
					fontSize: 12,
					fontWeight: 'lighter',
					marginLeft: theme.spacing(0.5),
				},
		}));

interface PlaceButtonSessionOverlayProps
{
	className?: string;
	placeSession: PlaceSession;
	show?: boolean;
	style?: CSSProperties;
}

export const PlaceButtonSessionOverlay: FC<PlaceButtonSessionOverlayProps> =
	({
		 className,
		 placeSession,
		 show,
		 style,
	 }) =>
	{
		const classes = useStyles();

		const rootClassesString = useMemo(
			() => className ? `${classes.root} ${className}` : classes.root,
			[classes.root, className]);

		const renderIcon = useCallback(
			(iconType: ComponentType<SvgIconProps>, text: number | string) =>
				<div
					className={classes.iconRoot}
				>
					{
						createElement(
							iconType,
							{
								classes: {
									root: classes.icon,
								},
							})
					}
					<Typography
						classes={{
							root: classes.iconText,
						}}
						noWrap
						variant="body2"
					>
						{text}
					</Typography>
				</div>,
			[classes.icon, classes.iconRoot, classes.iconText]);

		return <Fade
			in={show}
			unmountOnExit
		>
			<div
				className={rootClassesString}
				style={style}
			>
				<div
					className={classes.duoIconRoot}
				>
					{renderIcon(AccessTimeIcon, placeSession.startTime)}
					{renderIcon(PeopleIcon, placeSession.numberOfCovers)}
				</div>
				{placeSession.menuCard && renderIcon(RestaurantMenuIcon, placeSession.menuCard.name)}
			</div>
		</Fade>;
	};
