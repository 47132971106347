import { SerializationProfile } from '../../Util/Serialization/Serialization';
import { Business, BusinessProfile } from './Business';
import { Place, PlaceProfile } from './Place';

export class HashData
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	readonly business: Business;
	readonly hash: string;
	readonly place: Place;
}

export const HashDataProfile = SerializationProfile
	.create(HashData)
	.profile('business', BusinessProfile)
	.profile('place', PlaceProfile);
