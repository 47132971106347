import { Button, Grid, makeStyles, Snackbar, SnackbarContent } from '@material-ui/core';
import { Check, Close, Error, Info, ThumbUp, Warning } from '@material-ui/icons';
import clsx from 'clsx';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { UIColors } from '../../../Constants/UIColors';
import { InfoSnackbarStore } from './InfoSnackbarStore';
import { InfoSnackbarVariant } from './InfoSnackbarVariant';

const useStyles = makeStyles(theme => ({
    bar: {
        display: 'flex',
        paddingRight: 8,
    },
    button: {
        minWidth: 40,
        width: 40,
    },
    buttonIcon: {
        color: 'white',
    },
    error: {
        backgroundColor: UIColors.negative,
    },
    info: {
        backgroundColor: UIColors.info,
    },
    message: {
        flexGrow: 1,
    },
    messageItem: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
    },
    success: {
        backgroundColor: UIColors.positive,
    },
    variantIcon: {
        marginRight: theme.spacing(1),
    },
    warning: {
        backgroundColor: UIColors.warning,
    },
}));

interface InfoSnackbarProps
{
    horizontalAnchor: 'left' | 'center' | 'right';
    verticalAnchor: 'top' | 'bottom';
    store: InfoSnackbarStore
}

export const InfoSnackbar: FC<InfoSnackbarProps> =
    (
        {
            horizontalAnchor,
            verticalAnchor,
            store,
        }
    ) =>
    {
        const classes = useStyles();

        return useObserver(() => <Snackbar
            anchorOrigin={{
                horizontal: horizontalAnchor,
                vertical: verticalAnchor,
            }}
            autoHideDuration={store.currentMessage && store.currentMessage.action ? 10000 : 1000}
            open={store.isOpen}
            onClose={store.close}
            onExited={store.processMessageQueue}
        >
            {
                store.currentMessage &&
                <SnackbarContent
                    classes={{
                        message: classes.message,
                        root: clsx(classes.bar, classes[store.currentMessage.variant]),
                    }}

                    message={
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid
                                item
                                classes={{
                                    item: classes.messageItem,
                                }}
                            >
                                {
                                    !store.currentMessage.action &&
                                    <SnackbarIcon
                                        infoSnackbarVariant={store.currentMessage.variant}
                                        className={classes.variantIcon}
                                    />
                                }
                                {store.currentMessage.message}
                            </Grid>
                            <Grid
                                item
                            >
                                <Button
                                    onClick={store.hide}
                                    classes={{
                                        root: classes.button,
                                    }}
                                >
                                    <Close
                                        className={classes.buttonIcon}
                                    />
                                </Button>
                                {
                                    store.currentMessage.action &&
                                    <Button
                                        onClick={
                                            () =>
                                            {
                                                store.currentMessage.action?.();
                                                store.hide();
                                            }}
                                        classes={{
                                            root: classes.button,
                                        }}
                                    >
                                        <Check
                                            className={classes.buttonIcon}
                                        />
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                    }
                />
            }
        </Snackbar>);
    };

interface SnackbarIconProps
{
    infoSnackbarVariant: InfoSnackbarVariant
    className?: string
}

const SnackbarIcon: FC<SnackbarIconProps> =
    (
        {
            infoSnackbarVariant,
            className,
        }
    ) =>
    {
        switch (infoSnackbarVariant)
        {
            case InfoSnackbarVariant.error:
                return <Error
                    classes={{
                        root: className,
                    }}
                />;
            case InfoSnackbarVariant.info:
                return <Info
                    classes={{
                        root: className,
                    }}
                />;
            case InfoSnackbarVariant.success:
                return <ThumbUp
                    classes={{
                        root: className,
                    }}
                />;
            case InfoSnackbarVariant.warning:
                return <Warning
                    classes={{
                        root: className,
                    }}
                />;
            default:
                return null;
        }
    };
