import { makeStyles } from '@material-ui/core';
import Decimal from 'decimal.js';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { useCurrentPlaceService } from '../../../../../../current-place-service/CurrentPlaceService';
import { usePaymentPriceContext } from '../../../../../../UI/payment/price/context/PaymentPriceContext';
import { TipForm } from '../../../../OrderBuilder/segments/tip/TipForm';
import { useTipContext } from '../../context/tip/TipContextProvider';

export const TipBillSettlerSegment: FC =
	() =>
	{
		const currentPlaceService = useCurrentPlaceService();
		const {support, setTip, tip} = useTipContext();

		const place = useObserver(() => currentPlaceService.place!);

		const disallowed = useMemo(
			() => support === 'DISALLOWED' || !place.paymentAfterEnabled,
			[place.paymentAfterEnabled, support],
		);

		if (disallowed)
			return null;
		else
			return <Inner setTip={setTip} tip={tip} />;
	};


const useStyles = makeStyles({
	tipForm: {
		alignSelf: 'stretch',
		marginBottom: 16,
	},
});

interface InnerProps
{
	setTip: Dispatch<SetStateAction<Decimal | undefined>>;
	tip?: Decimal;
}

const Inner: FC<InnerProps> =
	({
		setTip,
		tip,
	}) =>
	{
		const {currency} = usePaymentPriceContext();

		const classes = useStyles();

		return <TipForm
			className={classes.tipForm}
			currencyCode={currency.code}
			onChange={setTip}
			value={tip}
		/>;
	};
