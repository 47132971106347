import { Collapse, makeStyles } from '@material-ui/core';
import Decimal from 'decimal.js';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from '../../../../../Bridge/Localization/useTranslation';
import { ActionButton } from '../../../../UI/action-button/ActionButton';
import { useMostRecentOrder } from '../useMostRecentOrder';
import { useOnClickCancelPayment } from '../useOnClickCancelPayment';

const useStyles = makeStyles({
	root: {
		marginTop: 'auto',
		marginBottom: 70,
	},
});

interface OrderPaymentInProgressPageCancelButtonProps
{
	paymentAmountRemaining?: Decimal;
}

export const OrderPaymentInProgressPageCancelButton: FC<OrderPaymentInProgressPageCancelButtonProps> =
	({
		paymentAmountRemaining,
	}) =>
	{
		const [onClickCancelPayment, resolving] = useOnClickCancelPayment();
		const mostRecentOrder = useMostRecentOrder();

		const paymentState = useObserver(() => mostRecentOrder?.paymentState);

		const classes = useStyles();

		return <div
			className={classes.root}
		>
			<Collapse
				in={paymentAmountRemaining === undefined || paymentAmountRemaining.gte(0)}
			>
				<ActionButton
					color={{
						linesAndContentColor: 'primary-contrast-text',
					}}
					disabled={paymentState !== 'negotiated'}
					loading={paymentState !== 'negotiated' || resolving}
					onClick={onClickCancelPayment}
					text={useTranslation('Payment-Cancel')}
					variant="outlined"
				/>
			</Collapse>
		</div>;
	};
