import { Card, Collapse, Divider, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Decimal from 'decimal.js';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { useLocalizer } from '../../../../../../Bridge/Localization/useLocalizer';
import { useMemoizedDecimal } from '../../../../../../Util/decimal/useMemoizedDecimal';
import { usePaymentPriceContext } from '../../../../../UI/payment/price/context/PaymentPriceContext';
import PriceTag from '../../../../../UI/PriceTag/PriceTag';
import { TipControl } from './TipControl';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		alignItems: 'center',
		backgroundColor: '#FFF8',
	},
	content: {
		paddingRight: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingTop: theme.spacing(1.5),
		paddingBottom: theme.spacing(1.5),
	},
	header: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: theme.spacing(2),
	},
	totalRow: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	priceTag: {
		marginLeft: 'auto',
	},
}));

interface TipFormProps
{
	value: Decimal | undefined;
	onChange: (value: Decimal | undefined) => void;
	currencyCode: string;
	className?: string;
}

export const TipForm: FC<TipFormProps> =
	({
		value,
		currencyCode,
		className,
		...other
	}) =>
	{
		const classes = useStyles();

		const localizer = useLocalizer();
		const {tip, total} = usePaymentPriceContext();

		const label = useMemo(
			() => localizer.translate('Order-Field-Tip'),
			[localizer],
		);
		const totalLabel = useMemo(
			() => localizer.translate('Client-ShoppingCart-Total'),
			[localizer],
		);

		const rootClassName = useMemo(() => clsx(classes.root, className), [className, classes.root]);

		const subTotalAmount = useMemoizedDecimal(
			total.sub(tip),
		);

		const totalPriceIncludingTip = useMemoizedDecimal(
			subTotalAmount
				.add(value ?? 0),
		);

		return <Card
			classes={{
				root: rootClassName,
			}}
		>
			<div
				className={classes.content}
			>
				<div
					className={classes.header}
				>
					<Typography
						variant="body2"
					>
						{label}
					</Typography>
					<div
						className={classes.priceTag}
					>
						<PriceTag
							price={value ?? new Decimal(0)}
							localizer={localizer}
							currencyCode={currencyCode}
							variant="body2"
							color="textSecondary"
						/>
					</div>
				</div>
				<TipControl
					value={value}
					currencyCode={currencyCode}
					subTotalAmount={subTotalAmount}
					{...other}
				/>
			</div>
			<Collapse
				in={value !== undefined}
			>
				<Divider />
				<div
					className={classes.content}
				>
					<div
						className={classes.totalRow}
					>
						<Typography
							variant="body2"
						>
							{totalLabel}
						</Typography>
						<div
							className={classes.priceTag}
						>
							<PriceTag
								price={totalPriceIncludingTip}
								localizer={localizer}
								currencyCode={currencyCode}
								variant="body2"
								color="textSecondary"
							/>
						</div>
					</div>
				</div>
			</Collapse>
		</Card>;
	};