import * as React from 'react';
import { FC, useCallback } from 'react';
import { useTranslation } from '../../../../../Bridge/Localization/useTranslation';
import { PageDialog } from '../../../../UI/PageDialog';
import { History } from '../History';
import { HistoryStore } from '../HistoryStore';

interface HistoryDialogProps
{
    store: HistoryStore
}

export const HistoryDialog: FC<HistoryDialogProps> =
    (
        {
            store,
        }
    ) =>
    {
        return <PageDialog
            title={useTranslation('Client-OrderHistory-Title')}
            onClose={useCallback(() => store.pop(), [store])}
        >
            <History
                store={store}
            />
        </PageDialog>;
    };
