import { action, computed, makeObservable, observable } from 'mobx';
import { BaseStore } from '@intentic/ts-foundation';
import { Screen } from './Screen';
import * as React from 'react';
import { ContextWithProvider } from './ContextWithProvider';
import { v4 as uuid } from 'uuid';


export class ScreenInstantiation<T extends BaseStore = BaseStore, C = {}>
{
    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    public readonly uuid: string;
    screen: Screen<T, C>;
    store: T;
    parentScreenInstantiation: ScreenInstantiation<BaseStore> | undefined;
    leftDrawers = observable.array<ScreenInstantiation<BaseStore>>();
    contentScreens = observable.array<ScreenInstantiation<BaseStore>>();
    rightDrawers = observable.array<ScreenInstantiation<BaseStore>>();
    dialogs = observable.array<ScreenInstantiation<BaseStore>>();
    _whenPopped: Promise<boolean>;

    /**
     * The number of pixels the screen is scrolled down. Undefined if information is not loaded, null is an illegal
     * value.
     */
    scrollTop: number | undefined;
    
    // ----------------------- Initialization -----------------------
    
    constructor(
        screen: Screen<T, C>,
        store: T,
        parentScreenInstantiation: ScreenInstantiation<BaseStore> | undefined,
    )
    {
        makeObservable(this, {
            screen: observable,
            store: observable,
            parentScreenInstantiation: observable,
            leftDrawers: observable,
            contentScreens: observable,
            rightDrawers: observable,
            dialogs: observable,
            _whenPopped: observable,
            inheritedContexts: computed,
            context: computed,
            inheritedContextProviders: computed,
            whenPopped: computed,
            markedForPop: computed,
            setMarkedForPop: action.bound,
            setUnmarkedForPop: action.bound,
        });
        this.uuid = uuid();
        this.screen = screen;
        this.store = store;
        this.parentScreenInstantiation = parentScreenInstantiation;
    }
    
    // -------------------------- Computed --------------------------

    get inheritedContexts(): Map<React.Context<any>, any>
    {
        let result: Map<React.Context<any>, any>;
        if (this.parentScreenInstantiation !== undefined)
        {
            result = this.parentScreenInstantiation.inheritedContexts;
        }
        else
        {
            result = new Map<React.Context<any>, any>();
        }
        if (this.screen.context !== undefined)
        {
            result.set(this.screen.context, this.context);
        }
        return result;
    }

    get context(): C
    {
        const contextFromParent = this
            .screen
            .contextFromParent(
                this.parentContext,
                this.store
            );
        return contextFromParent !== undefined
            ?
            contextFromParent
            :
            {} as C;
    }

    private get parentContext(): any
    {
        return this.parentScreenInstantiation !== undefined
            ?
            this.parentScreenInstantiation.context
            :
            undefined
    }

    get inheritedContextProviders(): ContextWithProvider<any>[]
    {
        const result: ContextWithProvider<any>[] = [];
        if (this.parentScreenInstantiation !== undefined)
        {
            result.push(...this.parentScreenInstantiation.inheritedContextProviders);
        }
        if (this.screen.contextProvider !== undefined)
        {
            result.push({
                ref: this.screen.context!,
                context: this.context,
                provider: this.screen.contextProvider,
            });
        }
        return result;
    }
    
    // -------------------------- Actions ---------------------------

    public get whenPopped(): Promise<boolean> | undefined
    {
        return this._whenPopped;
    }

    public get markedForPop()
    {
        return this._whenPopped !== undefined;
    }

    public setMarkedForPop(whenPopped: Promise<boolean>)
    {
        this._whenPopped = whenPopped;
    }

    public setUnmarkedForPop()
    {
        this._whenPopped = undefined;
    }
    
    // ------------------------ Public logic ------------------------

    public allowAsChild(childScreen: Screen): boolean
    {
        return this.screen.allowRoutingToChildScreen(childScreen, this.store);
    }

    public isInSubtreeWithRoot(screenInstantiation: ScreenInstantiation<BaseStore>): boolean
    {
        let instance: ScreenInstantiation<any, any> = this;
        while (instance) {
            if (instance === screenInstantiation) {
                return true;
            }
            instance = instance.parentScreenInstantiation;
        }
        return false;
    }

    /**
     * @param screenInstance
     * @return `true` if this {@link ScreenInstantiation} is a direct or indirect child of the supplied
     * {@link ScreenInstantiation}, `false` otherwise. Will return `false` if this {@link ScreenInstantiation} is
     * the supplied {@link ScreenInstantiation}.
     */
    public isDirectOrIndirectChildOf(screenInstance: ScreenInstantiation): boolean
    {
        let instance: ScreenInstantiation<BaseStore> = this.parentScreenInstantiation;
        while (instance) {
            if (instance === screenInstance)
            {
                return true;
            }
            instance = instance.parentScreenInstantiation;
        }
        return false;
    }

    // ----------------------- Private logic ------------------------
}
