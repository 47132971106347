import { FC, useMemo } from 'react';
import { useLocalizer } from '../../../../../../Bridge/Localization/useLocalizer';
import { GenericHistoricalItem } from '../GenericHistoricalItem';

interface HistoricalPaymentReferenceItemProps
{
	paymentReference: string;
}

export const HistoricalPaymentReferenceItem: FC<HistoricalPaymentReferenceItemProps> =
	({
		paymentReference,
	}) =>
	{
		const localizer = useLocalizer();

		const description = useMemo(
			() =>
				`${localizer.translate('Order-Payment-Reference')}: ${paymentReference}`,
			[localizer, paymentReference],
		);

		return <GenericHistoricalItem
			description={description}
		/>;
	};
