import { getServiceWorkerRegistration } from './getServiceWorkerRegistration';
import { ServiceWorkerConstants } from '../../Constants/ServiceWorkerConstants';

export function showPushNotification(options?: NotificationOptions): void
{
    getServiceWorkerRegistration()
        .then(
            registration =>
            {
                if (registration)
                {
                    registration.showNotification(
                        ServiceWorkerConstants.AppName,
                        {
                            ...ServiceWorkerConstants.DefaultOptions,
                            ...options,
                        })
                        .then();
                }
            });
}