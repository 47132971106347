import { BaseStore } from '@intentic/ts-foundation';
import Decimal from 'decimal.js';
import { action, computed, makeObservable, observable } from 'mobx';
import { Announcement } from '../../../../Api/Business/Announcement';
import { OrderRestrictionReport } from '../../../../Api/Order/OrderRestrictionReport';
import { Product } from '../../../../Api/Product/Product';
import { ProductConfiguration } from '../../../../Api/Product/ProductConfiguration';
import { Bridge } from '../../../../Bridge/Bridge';
import { CurrentOrderService } from '../../../../Service/CurrentOrder/CurrentOrderService';
import { CurrentPlaceService } from '../../../../Service/CurrentPlace/CurrentPlaceService';
import { BoxedReaction } from '../../../../Util/Reaction/BoxedReaction';
import { BusinessStore } from '../BusinessStore';

export class ShoppingCartStore extends BaseStore
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    bridge: Bridge;
    currentOrderService: CurrentOrderService;
    currentPlaceService: CurrentPlaceService;
    doShowPaymentMethodSelection: boolean;
    onOpenProduct: (product: Product) => void;
    public showValidation: boolean;
    private static placeSessionReaction: BoxedReaction = BoxedReaction.create();

    // ------------------------ Constructor -------------------------

    constructor(
        bridge: Bridge,
        currentOrderService: CurrentOrderService,
        currentPlaceService: CurrentPlaceService,
        businessStore: BusinessStore,
        onOpenProduct: (product: Product) => void,
    )
    {
        super();

        makeObservable<ShoppingCartStore, 'orderValueValidation'>(
            this,
            {
                bridge: observable,
                currentOrderService: observable,
                currentPlaceService: observable,
                doShowPaymentMethodSelection: observable,
                onOpenProduct: observable,
                showValidation: observable,
                isAllowedToOrder: computed,
                quantity: computed,
                isEmpty: computed,
                configurations: computed,
                quantityByProduct: computed,
                violatedReports: computed,
                announcements: computed,
                canOrder: computed,
                basePrice: computed,
                notEnoughInStock: computed,
                minimumOrderValue: computed,
                isValueSufficient: computed,
                orderValueValidation: computed,
                validation: computed,
                messages: computed,
                setShowValidation: action.bound,
                add: action.bound,
                clear: action.bound,
                openProduct: action.bound,
                setOwnCartId: action.bound,
            },
        );

        this.bridge = bridge;
        this.currentOrderService = currentOrderService;
        this.currentPlaceService = currentPlaceService;
        this.onOpenProduct = onOpenProduct;
        this.showValidation = false;
        ShoppingCartStore.placeSessionReaction
            .define(
                () => businessStore.placeSession,
                () => {
                    currentOrderService.checkOrder();
                }
            );
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    get isAllowedToOrder(): boolean
    {
        return this.violatedReports.length === 0;
    }

    get quantity()
    {
        return this.currentOrderService.quantity;
    }

    get isEmpty()
    {
        return this.currentOrderService.isEmpty;
    }

    get configurations()
    {
        return this.currentOrderService.configurations;
    }

    get quantityByProduct()
    {
        return this.currentOrderService.quantityByProduct;
    }

    get violatedReports(): OrderRestrictionReport[]
    {
        return this.currentOrderService.violatedReports;
    }

    get announcements(): Announcement[]
    {
        return this.currentOrderService.announcements;
    }

    get canOrder(): boolean
    {
        return this.currentOrderService.canOrder;
    }

    get basePrice()
    {
        return this.currentOrderService.totalProductAmount;
    }

    get notEnoughInStock(): boolean
    {
        for (let configuration of this.configurations)
        {
            if (!this.currentOrderService.isInStock(configuration))
            {
                return true;
            }
        }
        return false;
    }

    get minimumOrderValue(): Decimal
    {
        return this.currentOrderService.minimumOrderValue;
    }

    get isValueSufficient(): boolean
    {
        return this.currentOrderService.totalProductAmount.greaterThanOrEqualTo(
            this.currentOrderService.minimumOrderValue,
        );
    }

    private get orderValueValidation(): string[]
    {
        const validation: string[] = [];

        if (!this.isValueSufficient)
        {
            const minimumValueString = this.bridge.localizer.formatCurrency(this.minimumOrderValue, this.currentOrderService.business.productCurrencyCode) ?? '';
            const deficitString = this.bridge.localizer.formatCurrency(this.minimumOrderValue.sub(this.basePrice), this.currentOrderService.business.productCurrencyCode) ?? '';

            validation.push(`${this.bridge.localizer.translate('Client-Order-Value-More', deficitString, minimumValueString)}.`);
        }

        return validation;
    }

    get validation(): string[]
    {
        const validation: string[] = [];

        if (this.notEnoughInStock)
        {
            validation.push(this.bridge.localizer.translate('Client-ShoppingCart-OfSomeItemsNotEnoughQuantityInStock'));
        }

        return [
            ...validation,
            ...this.orderValueValidation
        ];
    }

    get messages(): {content: string, validation?: boolean}[]
    {
        const messages: { content: string, validation?: boolean }[] = [];

        if (!this.isValueSufficient && !this.showValidation)
        {
            const minimumValueString = this.bridge.localizer.formatCurrency(this.minimumOrderValue, this.currentOrderService.business.productCurrencyCode);

            messages.push({
                content: `${this.bridge.localizer.translate('Generic-Minimum')}: ${minimumValueString}`
            });
        }

        if (this.showValidation)
        {
            this.orderValueValidation.forEach(
                validationMessage => messages.push({
                    content: validationMessage,
                    validation: true
                })
            );
        }

        return messages;
    }

    // --------------------------- Stores ---------------------------

    setShowValidation(showValidation: boolean)
    {
        this.showValidation = showValidation;
    }

    add(
        productConfiguration: ProductConfiguration,
        diffInQuantity: number
    ): void
    {
        this.currentOrderService.add(productConfiguration, diffInQuantity);
    }

    clear()
    {
        this.currentOrderService.clearOrderLines();
    }

    openProduct(product: Product)
    {
        this.onOpenProduct(product);
    }

    setOwnCartId(ownCartId: number)
    {
        this.currentOrderService.setOwnCartId(ownCartId);
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
