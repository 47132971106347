import { useEffect, useState } from 'react';

export function useIsImageLoading(src?: string): boolean
{
	const [isLoading, setLoading] = useState(src !== undefined);

	useEffect(
		() =>
		{
			if (src)
			{
				setLoading(true);
				
				const image = new Image();
				image.onload =
					() =>
						setLoading(false);
				image.onerror =
					() =>
						setLoading(false);
				image.src = src;
			}
			else
			{
				setLoading(false);
			}
		},
		[src]
	);

	return isLoading;
}