import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { ObjectUrlCache } from './ObjectUrlCache';
import { ObjectUrlCacheContext } from './ObjectUrlCacheContext';

export interface ObjectUrlCacheProviderProps
{

}

export const ObjectUrlCacheProvider: FC<ObjectUrlCacheProviderProps> =
	({
		children,
	}) =>
	{
		const [cache] =
			useState<ObjectUrlCache>(
				() =>
					new Map()
			);

		useEffect(
			() =>
			{
				return () =>
				{
					cache.forEach(
						url =>
							URL.revokeObjectURL(url)
					);
				};
			},
			[
				cache,
			]
		);

		return <ObjectUrlCacheContext.Provider
			value={cache}
		>
			{children}
		</ObjectUrlCacheContext.Provider>;
	};