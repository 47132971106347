import * as React from 'react';
import { FC, useMemo } from 'react';
import { useLocalizer } from '../../../../../../../Bridge/Localization/useLocalizer';
import { PageDialog } from '../../../../../../UI/PageDialog';
import { OrderEvents } from '../OrderEvents';

interface OrderEventsDialogProps
{
	onClose: () => void;
	orderId?: number;
}

export const OrderEventsDialog: FC<OrderEventsDialogProps> =
	({
		 onClose,
		 orderId,
	 }) =>
	{
		const localizer = useLocalizer();

		const content = useMemo(() => orderId !== undefined && <OrderEvents orderId={orderId} />, [orderId]);

		const title = useMemo(() => localizer.translate('Order-Timeline-Title'), [localizer]);

		return <PageDialog
			fullWidth
			maxWidth="md"
			onClose={onClose}
			open={orderId !== undefined}
			title={title}
		>
			{content}
		</PageDialog>;
	};