import { makeStyles } from '@material-ui/core';
import { green, red, yellow } from '@material-ui/core/colors';
import { FC, useMemo } from 'react';
import { OrderProcessingState } from '../../../../../../Api/Order/Order';
import { useLocalizer } from '../../../../../../Bridge/Localization/useLocalizer';
import { GenericHistoricalItem } from '../GenericHistoricalItem';

const useStyles = makeStyles(theme => ({
	item: {
		transition: theme.transitions.create('background-color', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.complex,
		}),
	},
}));

interface HistoricalProcessingItemProps
{
	localizeAsRequest: boolean;
	processingState: OrderProcessingState;
}

export const HistoricalProcessingItem: FC<HistoricalProcessingItemProps> =
	({
		localizeAsRequest,
		processingState,
	}) =>
	{
		const localizer = useLocalizer();

		const description = useMemo(
			() =>
			{
				switch (processingState)
				{
					case 'failed':
						return localizer.translate(
							localizeAsRequest
								? 'Order-Request-Processing-Failed'
								: 'Order-Processing-Failed',
						);
					case 'partially_failed':
						return localizer.translate(
							localizeAsRequest
								? 'Order-Request-Processing-PartiallyFailed'
								: 'Order-Processing-PartiallyFailed',
						);
					case 'processed':
						return localizer.translate(
							localizeAsRequest
								? 'Order-Request-Processing-Processed'
								: 'Order-Processing-Processed',
						);
					case 'scheduled':
					case 'processing':
					default:
						return localizer.translate(
							localizeAsRequest
								? 'Order-Request-Processing-Processing'
								: 'Order-Processing-Processing',
						);

				}
			},
			[localizeAsRequest, localizer, processingState],
		);

		const backgroundColor = useMemo(
			() =>
			{
				switch (processingState)
				{
					case 'failed':
					case 'partially_failed':
						return red[100];
					case 'processed':
						return green[100];
					case 'scheduled':
					case 'processing':
					default:
						return yellow[100];
				}
			},
			[processingState],
		);

		const classes = useStyles();

		return <GenericHistoricalItem
			className={classes.item}
			description={description}
			style={{
				backgroundColor,
			}}
		/>;
	};
