import { makeStyles, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { useDebounce } from '../../../../../Util/Hooks/useDebounce';
import { formatValidationMessagesToText } from '../../../../../Util/Validation';
import { CouponCard } from '../CouponCard/CouponCard';
import { LoyaltyCouponCard } from '../LoyaltyCouponCard/LoyaltyCouponCard';
import { CouponFormStore } from './CouponFormStore';

const useStyles = makeStyles({
	addedCouponsList: {
		marginBottom: 10,
	},
});

export interface CouponFormProps
{
	store: CouponFormStore;
}

export const CouponForm: FC<CouponFormProps> =
	({
		store,
	}) =>
	{
		const classes = useStyles();

		const addedLoyaltyDiscountCoupons = useObserver(() => store.addedLoyaltyDiscountCoupons);
		const addedCouponsStores = useObserver(() => store.addedCouponsStores);
		const addedCouponsText = useObserver(() => store.addedCouponsText);
		const canAddCoupon = useObserver(() => store.canAddCoupon);
		const storeCouponCode = useObserver(() => store.couponCode);
		const couponCodeValidation = useObserver(() => store.couponCodeValidation);
		const explanationText = useObserver(() => store.explanationText);
		const hasActiveCoupons = useObserver(() => store.hasActiveCoupons);
		const label = useObserver(() => store.label);
		const listenToEnterKey = useObserver(() => store.listenToEnterKey);
		const onChangeCouponCode = useObserver(() => store.onChangeCouponCode);
		const setShowValidation = useObserver(() => store.setShowValidation);
		const showValidation = useObserver(() => store.showValidation);
		const textField = useObserver(() => store.textField);

		const [couponCode, setCouponCode] = useState(storeCouponCode);
		const debouncedCouponCode = useDebounce(couponCode);

		useEffect(
			() =>
			{
				if (storeCouponCode === undefined || storeCouponCode.trim() === '')
					setCouponCode('');
			},
			[storeCouponCode],
		);

		useEffect(
			() =>
			{
				// noinspection JSIgnoredPromiseFromCall
				onChangeCouponCode(debouncedCouponCode);
			},
			[debouncedCouponCode, onChangeCouponCode],
		);

		const onRemove= (discount)=>store.removeLoyaltyDiscountCoupon(discount);

		return <div
			style={{
				alignItems: 'center',
				width: '100%',
			}}
		>
			{
				hasActiveCoupons &&
				<>
					<Typography
						variant="body1"
						color="textSecondary"
					>
						{addedCouponsText}
					</Typography>
					<div
						className={classes.addedCouponsList}
					>
						{
							addedCouponsStores
								.map((couponCardStore, key) =>
									<CouponCard
										key={key}
										store={couponCardStore}
									/>,
								)
						}
						{
							addedLoyaltyDiscountCoupons.length > 0 &&
							<LoyaltyCouponCard
								discount={addedLoyaltyDiscountCoupons[0]}
								onRemove={onRemove}
							/>
						}
					</div>
				</>
			}
			{
				canAddCoupon &&
				<>
					<Typography
						variant="body1"
						color="textSecondary"
					>
						{explanationText}
					</Typography>
					<TextField
						required={store.requiresCouponCode}
						fullWidth
						inputRef={textField}
						variant="outlined"
						error={showValidation && couponCodeValidation.length > 0}
						onBlur={() => setShowValidation(true)}
						onKeyPress={listenToEnterKey}
						label={label}
						value={couponCode}
						inputProps={{
							type: store.requiresCouponCode ? 'password' : 'text',
						}}
						margin="dense"
						onChange={event => setCouponCode(event.target.value)}
						style={{
							marginTop: 10,
							marginBottom: 10,
						}}
						helperText={
							showValidation && (formatValidationMessagesToText(couponCodeValidation) !== undefined)
								? <span
									style={{marginLeft: -12}}
								>
                            {
								showValidation
									? formatValidationMessagesToText(couponCodeValidation)
									: null
							}
                        </span>
								: null
						}
					/>
				</>
			}
		</div>;
	};
