import { Avatar, makeStyles, Theme, Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem/ListItem';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import Decimal from 'decimal.js';
import * as React from 'react';
import { useContext, useState } from 'react';
import { Product } from '../../../../../Api/Product/Product';
import { ProductConfiguration } from '../../../../../Api/Product/ProductConfiguration';
import { getFileUrl } from '../../../../../Util/Api/Resources/getFileUrl';
import { CommaSeparatedList } from '../../../../UI/CommaSeparatedList';
import PriceTag from '../../../../UI/PriceTag/PriceTag';
import { EntranceContextRef } from '../../../Entrance/EntranceContext';
import { OrderLineQuantityControl } from './OrderLineQuantityControl';

interface StylesProps
{
    leftMargin: number
    rightMargin: number
    thumbnailHorizontalMargins: number
}

const useStyles = makeStyles((theme: Theme) => ({
    ShoppingCartConfiguration: ({leftMargin, rightMargin}: StylesProps) => ({
        width: '100%',
        zIndex: 1500,
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
        paddingLeft: `${leftMargin}px !important`,
        paddingRight: `${rightMargin}px !important`,
        cursor: 'pointer',

        transition: theme.transitions.create('background-color', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.standard,
        }),

        '& *': {
            cursor: 'pointer',
        },

        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.05)',
        },
    }),
    AvatarContainer: ({thumbnailHorizontalMargins, leftMargin}: StylesProps) => ({
        flex: '0 0 auto',
        paddingTop: 8,
        paddingBottom: 8,
        marginLeft: -leftMargin,
        paddingLeft: thumbnailHorizontalMargins,
        paddingRight: thumbnailHorizontalMargins,
        // width: 50,
        verticalAlign: 'middle',
        // width: 0,
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
    }),
    DescriptionContainer: {
        flex: '1 1 auto',
        verticalAlign: 'middle',
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 16,
        // paddingLeft: 8,
    },
    ShoppingCartConfigurationAvatar: {},
    OptionGridContainer: {},
    OptionGridItem: {},
    helperText: {
        color: theme.palette.error.main,
        fontSize: 'smaller !important',
    },
}));

export interface ShoppingCartConfigurationProps
{
    configuration: ProductConfiguration;
    disableIncrement?: boolean;
    quantity: number;
    price?: Decimal;
    editable?: boolean;
    style?: CSSProperties;
    outOfStock?: boolean;
    productCurrencyCode: string;

    /**
     * Callback for opening a {@link Product} detail component
     * @param product
     */
    openProduct?: (product: Product) => void;

    /**
     * Callback for changing the quantity of a {@link ProductConfiguration} in a certain {@link Order}.
     * @param configuration
     * @param diff The quantity difference. I.e. '-1' will subtract 1 from the quantity.
     */
    changeQuantity?: (configuration: ProductConfiguration, diff: number) => void;

    leftMargin?: number;
    rightMargin?: number;
    thumbnailHorizontalMargins?: number;
}

export const ShoppingCartConfiguration = (props: ShoppingCartConfigurationProps) => {
    const {
        style,
        configuration,
        disableIncrement,
        quantity,
        price,
        editable = false,
        outOfStock = false,
        productCurrencyCode,
        openProduct = () => {},
        changeQuantity = () => {},
        leftMargin = 12,
        rightMargin = 12,
        thumbnailHorizontalMargins = 12,
    } = props;
    const {localizer} = useContext(EntranceContextRef);
    const classes = useStyles({leftMargin, rightMargin, thumbnailHorizontalMargins});
    const notEnoughQuantityLabel = useState(
        localizer.translate('Client-ShoppingCart-NotEnoughQuantityInStock')
    );

    return <>
        <ListItem
            key={configuration.id}
            dense
            divider
            className={classes.ShoppingCartConfiguration}
            onClick={() => openProduct(configuration.product)}
            style={{
                ...style,
                backgroundColor: outOfStock
                    ?
                    '#f001'
                    :
                    undefined,
            }}
        >
            {configuration.product.imageUrl && <div
                className={classes.AvatarContainer}
                // style={{
                //     backgroundImage: `url("${getFileUrl(configuration.product.imageUrl)}")`
                // }}
            >
                <Avatar
                    src={getFileUrl(configuration.product.imageUrl)}
                    className={classes.ShoppingCartConfigurationAvatar}
                />
            </div>}
            <div
                className={classes.DescriptionContainer}
            >
                <Typography
                    variant="body1"
                    color="textPrimary"
                >
                    {
                        editable
                            ?
                            configuration.product.name
                            :
                            `${quantity}× ${configuration.product.name}`
                    }
                </Typography>
                <Typography
                    variant="body1"
                    color="textSecondary"
                >
                    {
                        editable
                            ?
                            <>
                                <PriceTag
                                    price={configuration.price}
                                    localizer={localizer}
                                    currencyCode={productCurrencyCode}
                                    style={{
                                        display: 'inline-block',
                                    }}
                                    color="textSecondary"
                                    component="span"
                                />
                                {configuration.variantConfigurations.length > 0 && ` | `}
                            </>
                            :
                            undefined
                    }
                    <CommaSeparatedList items={configuration.variantConfigurations.map(v => v.variant.name)}/>
                </Typography>
            </div>
            {
                editable
                    ?
                    <OrderLineQuantityControl
                        configuration={configuration}
                        disableIncrement={disableIncrement}
                        quantity={quantity}
                        outOfStock={outOfStock}
                        changeQuantity={changeQuantity}
                    />
                    :
                    <PriceTag
                        price={(price ?? configuration.price).mul(quantity)}
                        localizer={localizer}
                        currencyCode={productCurrencyCode}
                        style={{
                            display: 'inline-block',
                        }}
                        color="textSecondary"
                        component="span"
                    />
            }
        </ListItem>
        {outOfStock ? <ListItem
            style={{
                backgroundColor: '#f001',
            }}
            dense
            divider
        >
            <Typography
                className={classes.helperText}
            >
                {notEnoughQuantityLabel}
            </Typography>
        </ListItem> : <></>}
    </>;
};
