import { Linking } from './Linking';

export class WebLinking extends Linking
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------
    
    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    canOpenUrl(url: string): Promise<boolean>
    {
        return Promise.resolve(true);
    }

    getInitialUrl(): Promise<string>
    {
        return Promise.resolve(document.location.href);
    }

    openUrl(url: string): Promise<void>
    {
        window.location.assign(url);

        return Promise.resolve();
    }

    // ----------------------- Private logic ------------------------
}
