import Decimal from 'decimal.js';
import { makeAutoObservable } from 'mobx';
import { IllegalStateException } from '../../Util/Exception/IllegalStateException';
import { SerializationProfile } from '../../Util/Serialization/Serialization';
import { Product } from './Product';
import { ProductConfigurationVariant } from './ProductConfigurationVariant';
import { ProductFeatureVariantProfile } from './ProductFeatureVariant';
import { ProductProfile } from './ProductProfile';

export class ProductConfiguration
{
    // ------------------------- Properties -------------------------
    
    product: Product;
    variantConfigurations: ProductConfigurationVariant[];

    // ------------------------ Constructor -------------------------

    constructor(
        product: Product,
        variants?: ProductConfigurationVariant[]
    )
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });

        this.product = product;
        this.variantConfigurations = variants ?? [];
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    get id(): string
    {
        const variantsSortedById =
            this.variantConfigurations
                .slice()
                .sort(
                    (a, b) =>
                        a.variant.id > b.variant.id
                            ?
                            1
                            :
                            (
                                a.variant.id === b.variant.id
                                    ?
                                    (
                                        a.assignment.id > b.assignment.id
                                            ?
                                            1
                                            :
                                            (
                                                a.assignment.id === b.assignment.id
                                                    ?
                                                    0
                                                    :
                                                    -1
                                            )
                                    )
                                    :
                                    -1
                            ))
                .map(cartLineVariant => `${cartLineVariant.assignment.id}:${cartLineVariant.variant.id}`)
                .join('-');
        
        return `${this.product.id};` + variantsSortedById;
    }

    get price(): Decimal
    {
        let price = this.product.price;

        this.variantConfigurations.forEach(
            variant =>
                price = price.add(variant.variant.price));

        return price;
    }

    get isValid(): boolean
    {
        for (let assignment of this.product.presentFeatureAssignments)
        {
            const variantConfigurationsInAssignment = this.variantConfigurationsByAssignment[assignment.id.toString()];

            if (assignment.productFeature.maxAllowedVariants >= 0
                && assignment.productFeature.maxAllowedVariants < variantConfigurationsInAssignment.length)
            {
                return false;
            }

            if (variantConfigurationsInAssignment.length > 0)
            {
                if (assignment.isConditional)
                {
                    if (assignment.conditionalFeatureVariant
                        && !this.has(assignment.conditionalFeatureAssignmentId, assignment.conditionalFeatureVariantId))
                    {
                        return false;
                    }
                }
            }
        }

        return true;
    }

    private get variantConfigurationsByAssignment(): {[assignmentId: string]: ProductConfigurationVariant[]}
    {
        const variantConfigurations: {[assignmentId: string]: ProductConfigurationVariant[]} = {};

        this.product.presentFeatureAssignments
            .forEach(assignment => {
                variantConfigurations[assignment.id.toString()] = [];
            });

        this.variantConfigurations.forEach(
            variantConfiguration => {
                const key = variantConfiguration.assignment.id.toString();
                if (variantConfigurations[key] === undefined)
                {
                    throw new IllegalStateException(`Assignment ${key} not found in Product ${this.product.id}`)
                }
                variantConfigurations[key].push(variantConfiguration);
            }
        );

        return variantConfigurations;
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

    private has(productFeatureAssignmentID: number | undefined = undefined, productFeatureVariantID: number | undefined = undefined)
    {
        for (let variantConfiguration of this.variantConfigurations)
        {
            if (variantConfiguration.assignment.id === productFeatureAssignmentID
                && variantConfiguration.variant.id === productFeatureVariantID)
            {
                return true;
            }
        }
        return false;
    }
}

export const ProductConfigurationProfile =
    SerializationProfile.create(ProductConfiguration)
        .profile('product', ProductProfile)
        .profile('variantConfigurations', ProductFeatureVariantProfile);
