import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

const useStyles = makeStyles({
	image: {
		borderRadius: 13,
		height: 50,
		width: 150,
	},
	link: {
		borderRadius: 13,
		display: 'inline-block',
		height: 50,
		overflow: 'hidden',
		width: 150,
	},
});

interface AppleAppStoreBadgeProps
{
	className?: string;
	link: string;
}

export const AppleAppStoreBadge: FC<AppleAppStoreBadgeProps> =
	({
		className,
		link,
	}) =>
	{
		const classes = useStyles();

		return <a
			className={
				clsx(
					classes.link,
					className,
				)
			}
			href={link}
		>
			<img
				alt="Download on the App Store"
				className={classes.image}
				src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1563840000&h=c8a888b2833282c884df02537e167802"
			/>
		</a>;
	};
