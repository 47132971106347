import { makeAutoObservable } from 'mobx';
import { ISO8601ToDateTransformer, SerializationProfile } from '../../Util/Serialization/Serialization';
import { Cart, CartProfile } from './Cart/Cart';

export class CartUpdate
{
	// ------------------------- Properties -------------------------

	timestamp: Date;
	cart: Cart;

	// ------------------------ Constructor -------------------------

	constructor()
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});
	}

	// ----------------------- Initialization -----------------------

	// -------------------------- Computed --------------------------

	// -------------------------- Actions ---------------------------

	// ------------------------ Public logic ------------------------

	// ----------------------- Private logic ------------------------
}

export const CartUpdateProfile = SerializationProfile.create(CartUpdate)
		.transform('timestamp', ISO8601ToDateTransformer)
		.profile('cart', CartProfile);
