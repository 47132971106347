import { useEffect, useState } from 'react';
import { StorageVars } from '../../../../../../Constants/StorageConstants';
import { useStorage } from '../../../../../Root/StorageContextProvider';

export function useSelectInitialPaymentMethod(
	onSelect: (paymentMethodId?: string) => void,
	isInitialized: boolean,
	reset: boolean,
): boolean
{
	const storage = useStorage(true);

	const [didSelectInitialPaymentMethod, setDidSelectInitialPaymentMethod] = useState(false);

	useEffect(() =>
	{
		if (isInitialized && !didSelectInitialPaymentMethod)
		{
			if (reset)
			{
				onSelect(undefined);
				setDidSelectInitialPaymentMethod(true);
			}
			else
			{
				const value = storage.get(StorageVars.PaymentMethod);

				if (!didSelectInitialPaymentMethod)
				{
					if (value === undefined)
						onSelect(undefined);
					else
						// Previous implementations stored payment-methods as a comma-seperated list
						// The splitting is only there for backwards compatibility
						onSelect(value.split(',')[0]);
				}

				setDidSelectInitialPaymentMethod(true);
			}
		}
	}, [didSelectInitialPaymentMethod, isInitialized, onSelect, reset, storage]);

	return didSelectInitialPaymentMethod;
}