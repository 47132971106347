import { createTheme, ThemeOptions } from '@material-ui/core';
import shadows from '@material-ui/core/styles/shadows';
import { BrandingService } from '../Service/BrandingInformation/BrandingService';

shadows[1] = '0px 1px 5px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.05), 0px 3px 1px -2px rgba(0, 0, 0, 0.05)';
shadows[2] = '0px 1px 5px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.05), 0px 3px 1px -2px rgba(0, 0, 0, 0.05)';

export const CardBorderRadius = 8;
export const GreyButtonBackgroundColor = '#F4F6F7';
export const GreyButtonTextColor = '#869296';

const theme =
    (
        brandingService: BrandingService,
        lowPerformanceMode: boolean,
        isKioskMode: boolean
    ) =>
    {
        return createTheme({
            transitions: lowPerformanceMode
                ? {create: () => 'none'}
                : {},
            typography: {
                body1: {
                    fontSize:
                        isKioskMode
                            ? '1.3rem'
                            : '1rem',
                },
                body2: {
                    fontSize:
                        isKioskMode
                            ? '1.3rem'
                            : '1rem',
                },
                h1: {
                    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    // fontSize: 25,
                    // fontWeight: 'normal',
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 500,
                    fontSize: '1.25rem',
                    lineHeight: '1.6',
                    letterSpacing: '0.0075rem',
                },
                h5: {
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 500,
                    fontSize: '1.25rem',
                    lineHeight: '1.6',
                    letterSpacing: '0.0075rem',
                },
                h6: {
                    fontFamily: 'Raleway, sans-serif',
                    fontWeight: 700,
                },
                useNextVariants: true,
            },
            palette: {
                backgroundColor: {
                    main: brandingService.backgroundColor.css,
                    contrastText: brandingService.backgroundContrastTextColor
                        && brandingService.backgroundContrastTextColor.css,
                    light: brandingService.backgroundColor.chroma.brighten(1).hex(),
                    dark: brandingService.backgroundColor.chroma.darken(1).hex(),
                    '50': brandingService.backgroundColor.chroma.brighten(2.25).hex(),
                    '100': brandingService.backgroundColor.chroma.brighten(2).hex(),
                    '200': brandingService.backgroundColor.chroma.brighten(1.5).hex(),
                    '300': brandingService.backgroundColor.chroma.brighten(1).hex(),
                    '400': brandingService.backgroundColor.chroma.brighten(0.5).hex(),
                    '500': brandingService.backgroundColor.chroma.hex(),
                    '600': brandingService.backgroundColor.chroma.darken(0.5).hex(),
                    '700': brandingService.backgroundColor.chroma.darken(1).hex(),
                    '800': brandingService.backgroundColor.chroma.darken(1.5).hex(),
                    '900': brandingService.backgroundColor.chroma.darken(2).hex(),
                    'A100': brandingService.backgroundColor.chroma.brighten(1).hex(),
                    'A200': brandingService.backgroundColor.chroma.brighten(1).hex(),
                    'A400': brandingService.backgroundColor.chroma.brighten(1).hex(),
                    'A700': brandingService.backgroundColor.chroma.brighten(1).hex(),
                },
                primary: {
                    main: brandingService.tintColor.css,
                    contrastText: brandingService.tintContrastTextColor
                        && brandingService.tintContrastTextColor.css,
                    light: brandingService.tintColor.chroma.brighten(1).hex(),
                    dark: brandingService.tintColor.chroma.darken(1).hex(),
                    '50': brandingService.tintColor.chroma.brighten(2.25).hex(),
                    '100': brandingService.tintColor.chroma.brighten(2).hex(),
                    '200': brandingService.tintColor.chroma.brighten(1.5).hex(),
                    '300': brandingService.tintColor.chroma.brighten(1).hex(),
                    '400': brandingService.tintColor.chroma.brighten(0.5).hex(),
                    '500': brandingService.tintColor.chroma.hex(),
                    '600': brandingService.tintColor.chroma.darken(0.5).hex(),
                    '700': brandingService.tintColor.chroma.darken(1).hex(),
                    '800': brandingService.tintColor.chroma.darken(1.5).hex(),
                    '900': brandingService.tintColor.chroma.darken(2).hex(),
                    'A100': brandingService.tintColor.chroma.brighten(1).hex(),
                    'A200': brandingService.tintColor.chroma.brighten(1).hex(),
                    'A400': brandingService.tintColor.chroma.brighten(1).hex(),
                    'A700': brandingService.tintColor.chroma.brighten(1).hex(),
                },
                secondary: brandingService.secondaryColor && {
                    main: brandingService.secondaryColor
                        && brandingService.secondaryColor.css,
                    contrastText: brandingService.secondaryContrastTextColor
                        && brandingService.secondaryContrastTextColor.css,
                    light: brandingService.secondaryColor.chroma.brighten(1).hex(),
                    dark: brandingService.secondaryColor.chroma.darken(1).hex(),
                    '50': brandingService.secondaryColor.chroma.brighten(2.25).hex(),
                    '100': brandingService.secondaryColor.chroma.brighten(2).hex(),
                    '200': brandingService.secondaryColor.chroma.brighten(1.5).hex(),
                    '300': brandingService.secondaryColor.chroma.brighten(1).hex(),
                    '400': brandingService.secondaryColor.chroma.brighten(0.5).hex(),
                    '500': brandingService.secondaryColor.chroma.hex(),
                    '600': brandingService.secondaryColor.chroma.darken(0.5).hex(),
                    '700': brandingService.secondaryColor.chroma.darken(1).hex(),
                    '800': brandingService.secondaryColor.chroma.darken(1.5).hex(),
                    '900': brandingService.secondaryColor.chroma.darken(2).hex(),
                    'A100': brandingService.secondaryColor.chroma.brighten(1).hex(),
                    'A200': brandingService.secondaryColor.chroma.brighten(1).hex(),
                    'A400': brandingService.secondaryColor.chroma.brighten(1).hex(),
                    'A700': brandingService.secondaryColor.chroma.brighten(1).hex(),
                },
                error: brandingService.errorColor && {
                    main: brandingService.errorColor
                        && brandingService.errorColor.css,
                    contrastText: brandingService.errorContrastTextColor
                        && brandingService.errorContrastTextColor.css,
                    light: brandingService.errorColor.chroma.brighten(1).hex(),
                    dark: brandingService.errorColor.chroma.darken(1).hex(),
                    '50': brandingService.errorColor.chroma.brighten(2.25).hex(),
                    '100': brandingService.errorColor.chroma.brighten(2).hex(),
                    '200': brandingService.errorColor.chroma.brighten(1.5).hex(),
                    '300': brandingService.errorColor.chroma.brighten(1).hex(),
                    '400': brandingService.errorColor.chroma.brighten(0.5).hex(),
                    '500': brandingService.errorColor.chroma.hex(),
                    '600': brandingService.errorColor.chroma.darken(0.5).hex(),
                    '700': brandingService.errorColor.chroma.darken(1).hex(),
                    '800': brandingService.errorColor.chroma.darken(1.5).hex(),
                    '900': brandingService.errorColor.chroma.darken(2).hex(),
                    'A100': brandingService.errorColor.chroma.brighten(1).hex(),
                    'A200': brandingService.errorColor.chroma.brighten(1).hex(),
                    'A400': brandingService.errorColor.chroma.brighten(1).hex(),
                    'A700': brandingService.errorColor.chroma.brighten(1).hex(),
                },
            },
            shadows: shadows,
            overrides: {
                MuiCardContent: {
                    root: {
                        paddingBottom: 16,
                        '&:last-child': {
                            paddingBottom: 16,
                        },
                    },
                },
                MuiFormHelperText: {
                    root: {
                        '& code': {
                            backgroundColor: 'rgba(0, 0, 0, 0.09)',
                            whiteSpace: 'pre',
                            padding: '1px 3px',
                            border: '1px solid',
                            borderRadius: '6px',
                            verticalAlign: 'middle',
                        },
                    },
                },
            },
            props: {
                MuiButton: {
                    disableElevation: lowPerformanceMode ? true : undefined,
                    disableFocusRipple: lowPerformanceMode ? true : undefined,
                },
                MuiButtonBase: {
                    disableRipple: lowPerformanceMode ? true : undefined,
                    disableTouchRipple: lowPerformanceMode ? true : undefined,
                },
                MuiPaper: {
                    elevation: 2,
                },
            },
            shape: {
                borderRadius: CardBorderRadius,
            },
        } as ThemeOptions);
    };

export default theme;
