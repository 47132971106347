import { makeAutoObservable } from 'mobx';
import { SerializationProfile } from '../../../Util/Serialization/Serialization';
import { RoutingState } from './RoutingState';

export class Routing
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	id: number;
	uuid: string;
	type: string;
	externalId?: string;
	name: string;
	report: object;
	lastActivityDate: Date;
	lastReportDate: Date;
	state: RoutingState;
	pauseDate?: Date;
	isActivityDeterminedExternally: boolean;
	isMonitored: boolean;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor()
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});
	}

	/*---------------------------------------------------------------*
	 *                         Business Logic                        *
	 *---------------------------------------------------------------*/

	/*---------------------------------------------------------------*
	 *                      Getters and setters                      *
	 *---------------------------------------------------------------*/

	/*---------------------------------------------------------------*
	 *                        Nested classes                         *
	 *---------------------------------------------------------------*/
}

export const RoutingProfile =
	SerializationProfile.create(Routing);
