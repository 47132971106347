import { Collapse, ListItem } from '@material-ui/core';
import * as React from 'react';
import { FC } from 'react';
import { RequestBillButton } from '../TopContent/RequestBillButton';

interface RequestBillButtonListItemProps
{
	className?: string;
	onClick: () => void;
	onClickLegacy: () => void;
	show?: boolean;
	useLegacy: boolean;
}

export const RequestBillButtonListItem: FC<RequestBillButtonListItemProps> =
	(
		{
			className,
			onClick,
			onClickLegacy,
			show = true,
			useLegacy,
		},
	) =>
	{
		return <Collapse in={show} timeout={100} unmountOnExit>
			<ListItem className={className}>
				<RequestBillButton
					onClick={onClick}
					onClickLegacy={onClickLegacy}
					useLegacy={useLegacy}
				/>
			</ListItem>
		</Collapse>;
	};