import * as React from 'react';
import { FC } from 'react';
import { CommaSeparatedList } from '../../../../../../../../UI/CommaSeparatedList';
import { InitializedCartLine } from '../../../model/InitializedCartLine';

interface PeerShoppingCartLineSummaryProps
{
	line: InitializedCartLine;
}

export const PeerShoppingCartLineSummary: FC<PeerShoppingCartLineSummaryProps> =
	({
		line,
	}) =>
	{
		return <>
			{line.quantity}× {line.product.name}
			{
				line.variants.length > 0 &&
				<>
					&nbsp;(<CommaSeparatedList
						items={
							line.variants.map(
								variant =>
									variant.variant.name
							)
						}
					/>)
				</>
			}
		</>;
	};