import { CircularProgress, makeStyles } from '@material-ui/core';
import { loadScript } from '@paypal/paypal-js';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext, useRef, useState } from 'react';
import { Order } from '../../../../../Api/Order/Order';
import { postJsonAny } from '../../../../../Util/Api';
import { useAsyncEffect } from '../../../../../Util/async/useAsyncEffect';
import { BusinessContextRef } from '../../../../Page/Business/BusinessContext';
import { useOrderPaymentContext } from '../../context/OrderPaymentContext';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
	},
});

interface PaymentDialogStartPayPalPaymentButtonProps
{
	order: Order;
}

interface ClientToken
{
	token: string;
}

export const PaymentDialogStartPayPalPaymentButton: FC<PaymentDialogStartPayPalPaymentButtonProps> =
	({
		order,
	}) =>
	{
		const {businessStore} = useContext(BusinessContextRef);
		const {onPaymentProcessingResult} = useOrderPaymentContext();

		const [loading, setLoading] = useState(true);

		const containerRef = useRef<HTMLDivElement>(null);

		const currencyCode = order.paymentCurrencyCode;
		const clientId = useObserver(() => businessStore.business.payPalClientId);

		const classes = useStyles();

		useAsyncEffect(() => ({
			promise: (async () =>
			{
				const clientToken = await postJsonAny<ClientToken>(
					'/client/paypal/clientToken',
					{},
				);

				const paypal = await loadScript({
					'client-id': clientId,
					'data-client-token': clientToken.token,
					currency: currencyCode,
					intent: 'capture',
					'integration-date': '2022-06-01',
				});

				return paypal.Buttons({
					fundingSource: paypal.FUNDING.PAYPAL,

					createOrder: async () =>
					{
						return await postJsonAny<string>(
							`/client/paypal/${order.id}/start`,
							{}
						);
					},

					onApprove: async () =>
					{
						const success = await postJsonAny<boolean>(
							`/client/paypal/${order.id}/capture`,
							{
							},
						);

						if (success)
						{
							onPaymentProcessingResult({
								state: 'Success'
							})
						}
						else
						{
							onPaymentProcessingResult({
								error: new Error('Payment was not approved'),
								state: 'Failed',
							});
						}
					},

					onCancel: async () =>
					{
						await postJsonAny<boolean>(
							`/client/paypal/${order.id}/expire`,
							{},
						);
						
						onPaymentProcessingResult({
							error: new Error('Payment cancelled'),
							state: 'Failed',
						});
					},

					onError: error =>
					{
						onPaymentProcessingResult({
							error: new Error(JSON.stringify(error)),
							state: 'Failed',
						});
					},
				});
			})(),
			then: buttonRenderer =>
			{
				setLoading(false);
				buttonRenderer.render(containerRef.current);
			},
			catch: error =>
			{
				throw error;
			},
		}), [clientId, currencyCode, onPaymentProcessingResult, order.id]);

		return <div
			className={classes.root}
			ref={containerRef}
		>
			{
				loading &&
				<CircularProgress size={18} />
			}
		</div>;
	};