import { Box, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { FC, Fragment, ReactNode } from 'react';

const useStyles = makeStyles(theme => ({
	container: {
		position: 'absolute',
		left: theme.spacing(1),
		right: theme.spacing(1),
		top: theme.spacing(1),
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
	},
	badges: {
		display: 'flex',
		flexFlow: 'row wrap',
		alignItems: 'center',
		gap: theme.spacing(0.5),
	},
}));

interface MediaButtonBadgesProps
{
	primaryBadges: ReactNode[];
	secondaryBadges: ReactNode[];
}

export const MediaButtonBadges: FC<MediaButtonBadgesProps> =
	({
		primaryBadges,
		secondaryBadges,
	}) =>
	{
		const classes = useStyles();

		if (primaryBadges.length === 0 && secondaryBadges.length === 0)
		{
			return null;
		}
		else
		{
			return <Box
				className={classes.container}
			>
				<Box
					sx={{
						flex: 1,
					}}
					className={classes.badges}
				>
					{
						secondaryBadges
							.map(
								(badge, idx) =>
									<Fragment
										key={idx}
									>
										{badge}
									</Fragment>
							)
					}
				</Box>
				<Box
					className={classes.badges}
				>
					{
						primaryBadges
							.map(
								(badge, idx) =>
									<Fragment
										key={idx}
									>
										{badge}
									</Fragment>
							)
					}
				</Box>
			</Box>;
		}
	};
