import { HttpRequestException } from './HttpRequestException';
import { AxiosError } from 'axios';

export class FailedHttpRequestException extends HttpRequestException
{
	// ------------------------- Properties -------------------------

	readonly name = 'FailedHttpRequestException';

	// ------------------------ Constructor -------------------------

	constructor(cause: AxiosError)
	{
		super(
			cause,
			`Failed to request ${cause.config.method?.toUpperCase()} ${cause.config.url}.`,
		);
	}

	// ----------------------- Initialization -----------------------

	// -------------------------- Computed --------------------------

	// -------------------------- Actions ---------------------------

	// ------------------------ Public logic ------------------------

	// ----------------------- Private logic ------------------------
}
