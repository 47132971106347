import { Business } from '../../../../../Api/Business/Business';
import { Place } from '../../../../../Api/Business/Place';
import { Product } from '../../../../../Api/Product/Product';
import { Localizer } from '../../../../../Bridge/Localization/Localizer';

export function computePriceLabel(
	product: Product,
	business: Business,
	place: Place,
	localizer: Localizer
): string | undefined
{
	let price = product.minPrice;
	let hasPriceAlteration = product.hasPriceAlteration;

	if (product.isPriceHidden(price))
	{
		return undefined;
	}
	else
	{
		const formattedPrice: string | null | undefined = (() =>
		{
			if (place.productCurrencySymbolsHidden)
				return localizer.formatCurrencyWithoutSymbol(price, business.productCurrencyCode);
			else
				return localizer.formatCurrency(price, business.productCurrencyCode);
		})();

		if (formattedPrice == null)
		{
			return undefined;
		}

		if (hasPriceAlteration)
		{
			return `${localizer.translate('Generic-From')} ${formattedPrice}`;
		}
		else
		{
			return formattedPrice;
		}
	}
}
