import { SerializationProfile } from '../../Util/Serialization/Serialization';
import { ContractingEntity } from '../contracting_entity/ContractingEntity';
import { Cart, CartProfile } from '../Order/Cart/Cart';
import { OrderRestrictionReport, OrderRestrictionReportProfile } from '../Order/OrderRestrictionReport';
import { ProductRecommendationList, ProductRecommendationListProfile } from '../Product/ProductRecommendationList';
import { Announcement, AnnouncementProfile } from './Announcement';
import { PlaceSession, PlaceSessionProfile } from './PlaceSession';

export class BusinessEntrance
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	readonly contractingEntity: ContractingEntity;
	readonly announcements: Announcement[];
	readonly cart: Cart;
	readonly orderRestrictions: OrderRestrictionReport[];
	readonly placeSession?: PlaceSession;
	readonly productRecommendationLists: ProductRecommendationList[];
	readonly visitId: number;
	readonly loyaltyIntegration?: 'COMO' | 'PIGGY';
	readonly loyaltyIntegrationIconUrl?: string;
	readonly loyaltyIntegrationLogoUrl?: string;
}

export const BusinessEntranceProfile = SerializationProfile
	.create(BusinessEntrance)
	.profile('announcements', AnnouncementProfile)
	.profile('orderRestrictions', OrderRestrictionReportProfile)
	.profile('productRecommendationLists', ProductRecommendationListProfile)
	.profile('cart', CartProfile)
	.profile('placeSession', PlaceSessionProfile);
