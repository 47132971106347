import Decimal from 'decimal.js';
import { makeAutoObservable } from 'mobx';
import { NumberToDecimalTransformer, SerializationProfile } from '../../Util/Serialization/Serialization';
import { PostalCodeRange, PostalCodeRangeProfile } from './PostalCodeRange';

export class DeliveryAddressOverride
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	countryCode: string;
	priceAmount?: Decimal;
	minimumPriceAmount?: Decimal;
	freeDeliveryPriceAmountThreshold?: Decimal;
	postalCodes?: PostalCodeRange;

	constructor()
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});
	}

	/*---------------------------------------------------------------*
	 *                         Business Logic                        *
	 *---------------------------------------------------------------*/

	get hasPostalCodes(): boolean
	{
		return this.postalCodes !== undefined;
	}

	isForCountry(countryCode: string): boolean
	{
		return this.countryCode === countryCode;
	}

	isDefaultForCountry(countryCode: string): boolean
	{
		return this.isForCountry(countryCode) && !this.hasPostalCodes;
	}

	containsPostalCode(postalCode: number): boolean
	{
		return this.postalCodes !== undefined && this.postalCodes.contains(postalCode);
	}

	amountExceedsZeroPriceThreshold(amount: Decimal): boolean
	{
		if (this.freeDeliveryPriceAmountThreshold === undefined)
			return false;

		return this.freeDeliveryPriceAmountThreshold.lessThanOrEqualTo(amount);
	}

	getOverriddenPriceAmount(amount: Decimal): Decimal | undefined
	{
		return this.amountExceedsZeroPriceThreshold(amount)
			? new Decimal(0)
			: this.priceAmount;
	}

	getOverriddenMinimumPriceAmount(): Decimal | undefined
	{
		return this.minimumPriceAmount;
	}
}

export const DeliveryAddressOverrideProfile = SerializationProfile.create(DeliveryAddressOverride)
	.profile('postalCodes', PostalCodeRangeProfile)
	.transform('priceAmount', NumberToDecimalTransformer)
	.transform('minimumPriceAmount', NumberToDecimalTransformer)
	.transform('freeDeliveryPriceAmountThreshold', NumberToDecimalTransformer);