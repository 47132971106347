import { trimLeadingSlashes } from '../../trimLeadingSlashes';
import { trimTrailingSlashes } from '../../trimTrailingSlashes';

/**
 * Gets the complete URL of an API file
 *
 * @param resource the relative URL of the file
 * @return compelete URL for the file
 */
export function getFileUrl(resource: string): string
{
	return `${trimTrailingSlashes(process.env.REACT_APP_FILE_BASE_URL)}/${trimLeadingSlashes(resource)}`;
}
