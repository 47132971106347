import { Theme } from '@material-ui/core/styles';
import chroma from 'chroma-js';
import { useMemo } from 'react';
import { ButtonAppearance } from '../../ButtonAppearance';
import { extractMuiButtonColor, toCss } from '../../ButtonColor';

export function useOutlinedButtonColors(error: boolean, disabled: boolean, color: ButtonAppearance<'outlined'>, theme: Theme)
{
	return useMemo(
		() => {
			const linesAndContentColor = error
				? 'error'
				: color.linesAndContentColor ?? 'primary';
			const contentAlpha = !disabled ? 1 : 0.4;
			const contentColorCss = chroma(toCss(linesAndContentColor, theme)).alpha(contentAlpha).css();
			const outlineColorCss = chroma(contentColorCss).alpha(0.5 * contentAlpha).css();
			const buttonHoverColorCss = chroma(contentColorCss).alpha(0.04).css();
			const muiButtonColor = extractMuiButtonColor(linesAndContentColor);

			return {contentColorCss, outlineColorCss, buttonHoverColorCss, muiButtonColor};
		},
		[color.linesAndContentColor, disabled, error, theme],
	);
}