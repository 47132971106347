import { Loader } from '@intentic/ts-foundation';
import { isWidthDown } from '@material-ui/core/withWidth';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { BrandingService } from '../../../Service/BrandingInformation/BrandingService';
import { useScreenWidth } from '../../../Util/Hooks/useScreenWidth';
import { ThemeProvider } from '../../../Util/ThemeProvider/ThemeProvider';
import { AuthenticationContext } from '../../authentication-provider/AuthenticationContext';
import { EntranceContextRef } from '../Entrance/EntranceContext';
import { ManagerFilterRoutingContextProvider } from './context/ManagerFilterRoutingContextProvider';
import { ManagerMenuCardsContextProvider } from './context/menu-card/ManagerMenuCardsContext';
import { ManagerPlaceCodesContextProvider } from './context/place-code/ManagerPlaceCodesContext';
import { ManagerPlaceSessionsContextProvider } from './context/place-session/ManagerPlaceSessionsContext';
import { ManagerPlacesContextProvider } from './context/place/ManagerPlacesContext';
import { ManagerTimeSchedulesContextProvider } from './context/time-schedule/ManagerTimeSchedulesContext';
import { ManagerContextProvider } from './manager-context';
import { ManagerContent } from './ManagerContent';
import { ManagerSidebar } from './ManagerSidebar';
import { ManagerStore } from './ManagerStore';
import { ManagerToolbar } from './ManagerToolbar';

interface ManagerProps
{
	store: ManagerStore;
}

const Manager: FC<ManagerProps> =
	(
		{
			store,
		},
	) =>
	{
		const {registerAccount} = useContext(AuthenticationContext);
		const {waiterService} = useContext(EntranceContextRef);

		const screenWidth = useScreenWidth();

		const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

		// Services
		const brandingService = useObserver(() => new BrandingService({business: waiterService.business}));

		useEffect(
			() => {
				if (isWidthDown(screenWidth, 'xs'))
					setIsSidebarExpanded(false);
			},
			[screenWidth]
		);

		const toggleSidebar = useCallback(
			() => setIsSidebarExpanded(!isSidebarExpanded),
			[isSidebarExpanded]
		);

		const exit = useCallback(
			async () =>
			{
				const success = await waiterService.exitBusiness();

				if (success)
				{
					await store.bridge.navigator.popScreen();
					await registerAccount();
				}
			},
			[registerAccount, store.bridge.navigator, waiterService],
		);

		const business = useObserver(() => waiterService.business!);
		const businessId = useMemo(() => business.id, [business.id]);
		const storeView = useObserver(() => store.view);

		const businessWaiterWorksFor = useObserver(() => waiterService.businessWaiterWorksFor);
		const setView = useObserver(() => store.setView);

		if (businessWaiterWorksFor === undefined)
			return <Loader/>;

		return <ThemeProvider
			overrideBrandingService={brandingService}
		>
			<ManagerContextProvider
				value={{
					brandingService: brandingService,
					business: business,
					businessWaiterWorksFor: businessWaiterWorksFor,
					waiterService: waiterService,
				}}
			>
				<ManagerFilterRoutingContextProvider>
					<ManagerTimeSchedulesContextProvider businessId={businessId} view={storeView}>
						<ManagerMenuCardsContextProvider businessId={businessId} view={storeView}>
							<ManagerPlacesContextProvider businessId={businessId} view={storeView}>
								<ManagerPlaceSessionsContextProvider businessId={businessId} view={storeView}>
									<ManagerPlaceCodesContextProvider>
										<ManagerSidebar
											isExpanded={isSidebarExpanded}
											onClose={exit}
											navigateTo={setView}
										/>
										<ManagerToolbar
											isSidebarExpanded={isSidebarExpanded}
											navigateTo={setView}
											toggleSidebar={toggleSidebar}
										/>
										<ManagerContent
											isSidebarExpanded={isSidebarExpanded}
											store={store}
										/>
									</ManagerPlaceCodesContextProvider>
								</ManagerPlaceSessionsContextProvider>
							</ManagerPlacesContextProvider>
						</ManagerMenuCardsContextProvider>
					</ManagerTimeSchedulesContextProvider>
				</ManagerFilterRoutingContextProvider>
			</ManagerContextProvider>
		</ThemeProvider>;
	};

export default Manager;