export function generateCodeChallenge(verifier: string): Promise<string>
{
	if (verifier.length < 43 || verifier.length > 128)
		throw new Error('Verifier has invalid length');

	return base64UrlOfHash(verifier);
}

function textEncodeLite(str: string)
{
	const buf = new ArrayBuffer(str.length);
	const bufView = new Uint8Array(buf);

	for (let i = 0; i < str.length; i++)
	{
		bufView[i] = str.charCodeAt(i);
	}
	return bufView;
}

async function base64UrlOfHash(verifier: string): Promise<string>
{
	const buffer = await crypto.subtle.digest('SHA-256', textEncodeLite(verifier));

	return uint8ToUrlBase64(new Uint8Array(buffer));
}

function uint8ToUrlBase64(uint8: Uint8Array): string
{
	let bin = '';
	uint8.forEach(code =>
	{
		bin += String.fromCharCode(code);
	});
	return btoa(bin)
		.replace(/\+/g, '-')
		.replace(/\//g, '_')
		.replace(/=+/g, '');
}
