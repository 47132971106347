import UIElement from '@adyen/adyen-web/dist/types/components/UIElement';
import { PaymentAction } from '@adyen/adyen-web/dist/types/types';
import { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';
import { Subject } from 'rxjs';
import { Order } from '../../../../Api/Order/Order';
import { useProcessOrderPayment } from '../hooks/useProcessOrderPayment';
import { OrderPaymentProcessingResult } from '../model/OrderPaymentProcessingResult';

interface OrderPaymentContext
{
	initiatePayment: (order: Order, component?: UIElement<any>) => void;
	manualPaymentAction?: PaymentAction;
	onPaymentProcessingResult: (result: OrderPaymentProcessingResult) => void;
	orderInProgress?: Order;
	requiresExtraUserInteraction: boolean;
	subject: Subject<OrderPaymentProcessingResult>;
}

const OrderPaymentContextRef = createContext<OrderPaymentContext>(undefined!);

export function useOrderPaymentContext(): OrderPaymentContext
{
	return useContext(OrderPaymentContextRef);
}

export const OrderPaymentContextProvider: FC =
	({
		children,
	}) =>
	{
		const [subject, setSubject] = useState(new Subject<OrderPaymentProcessingResult>());
		const [orderInProgress, setOrderInProgress] = useState<Order | undefined>();
		const [manualPaymentAction, setManualPaymentAction] = useState<PaymentAction | undefined>();
		const [requiresExtraUserInteraction, setRequiresExtraUserInteraction] = useState(false);

		const processOrderPayment = useProcessOrderPayment();

		const handlePaymentProcessingResult = useCallback(
			(
				result: OrderPaymentProcessingResult,
				component?: UIElement<any>,
			) =>
			{
				switch (result.state)
				{
					case 'Failed':
					{
						subject.error(result.error);

						setOrderInProgress(undefined);
						setManualPaymentAction(undefined);
						setRequiresExtraUserInteraction(false);
						setSubject(new Subject());

						break;
					}
					case 'Pending':
					{
						const effectiveComponent = result.component ?? component;

						if (result.redirectUrl !== undefined)
							window.location.assign(result.redirectUrl);

						setRequiresExtraUserInteraction(result.requiresExtraUserInteraction);

						if (result.requiresExtraUserInteraction)
						{
							setManualPaymentAction(result.action);
						}
						else if (effectiveComponent !== undefined && result.action !== undefined)
						{
							effectiveComponent.handleAction(result.action);
							setManualPaymentAction(undefined);
						}
						else
						{
							setManualPaymentAction(undefined);
						}

						break;
					}
					case 'Success':
					{
						subject.complete();

						setOrderInProgress(undefined);
						setManualPaymentAction(undefined);
						setRequiresExtraUserInteraction(false);
						setSubject(new Subject());

						break;
					}
				}
			},
			[subject],
		);

		const initiatePayment = useCallback(
			(
				order: Order,
				adyenCheckoutComponent?: UIElement<any>,
			) =>
			{
				if (order.paymentPrice !== undefined && !order.paymentPrice.isZero())
				{
					setOrderInProgress(order);

					return new Promise(
						async (resolve, reject) =>
						{
							subject
								.toPromise()
								.then(resolve)
								.catch(reject);

							// This method will eventually publish a new value on the subject, which resolves or rejects the outer promise
							handlePaymentProcessingResult(
								await processOrderPayment(order, adyenCheckoutComponent),
							);
						},
					);
				}
			},
			[handlePaymentProcessingResult, processOrderPayment, subject],
		);

		const contextValue = useMemo<OrderPaymentContext>(
			() =>
				({
					initiatePayment,
					manualPaymentAction,
					onPaymentProcessingResult: handlePaymentProcessingResult,
					orderInProgress,
					requiresExtraUserInteraction,
					subject,
				}),
			[handlePaymentProcessingResult, initiatePayment, manualPaymentAction, orderInProgress, requiresExtraUserInteraction, subject],
		);

		return <OrderPaymentContextRef.Provider
			value={contextValue}
		>
			{children}
		</OrderPaymentContextRef.Provider>;
	};
