// @ts-nocheck
/* eslint-disable */
import Decimal from 'decimal.js';
import { makeAutoObservable } from 'mobx';
import { SerializationProfile } from '../../Util/Serialization/Serialization';
import { ProductFeature } from './ProductFeature';
import { ProductFeatureVariant } from './ProductFeatureVariant';
import { ProductFee } from './ProductFee';

export class ProductFeatureAssignment
{
    // ------------------------- Properties -------------------------

    id: number;
    productFeatureId: number;
    conditionalFeatureAssignmentId?: number;
    conditionalFeatureVariantId?: number;
    productFeature: ProductFeature;
    conditionalFeatureAssignment?: ProductFeatureAssignment;
    conditionalFeatureVariant?: ProductFeatureVariant;

    // ------------------------ Constructor -------------------------

    constructor()
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
    }

    // ----------------------- Initialization -----------------------

    initialize(
        featureById: Map<number, ProductFeature>,
        assignmentById: Map<number, ProductFeatureAssignment>,
        variantById: Map<number, ProductFeatureVariant>,
        feeById: Map<number, ProductFee>
    )
    {
        this.productFeature = featureById.get(this.productFeatureId);

        if (this.conditionalFeatureAssignmentId != null)
        {
            this.conditionalFeatureAssignment = assignmentById.get(this.conditionalFeatureAssignmentId);
        }

        if (this.conditionalFeatureVariantId != null)
        {
            this.conditionalFeatureVariant = variantById.get(this.conditionalFeatureVariantId);
        }
    }

    // -------------------------- Computed --------------------------

    get isConditional()
    {
        return this.conditionalFeatureAssignmentId !== undefined
            && this.conditionalFeatureVariantId !== undefined;
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    getMinOrMaxPriceAlteration(isMinimum: boolean): Decimal
    {
        let priceAlterations: Decimal[] = [];

        this.productFeature.variants.forEach(
            variant =>
            {
                priceAlterations.push(variant.price);
            });

        priceAlterations.sort((a, b) => a.comparedTo(b));

        if (!isMinimum)
            priceAlterations.reverse();

        switch (this.productFeature.type)
        {
            case 'addition':
                let minOrMaxPriceAlteration = new Decimal(0);
                let count = 0;

                for (let priceAlteration of priceAlterations)
                {
                    if (this.productFeature.maxAllowedVariants != null && count >= this.productFeature.maxAllowedVariants)
                    {
                        break;
                    }

                    const forceAddition = this.productFeature.minAllowedVariants != null && count < this.productFeature.minAllowedVariants;

                    if (forceAddition || priceAlteration.comparedTo(0) === (isMinimum ? -1 : 1))
                    {
                        minOrMaxPriceAlteration = minOrMaxPriceAlteration.add(priceAlteration);
                    }
                    
                    count += 1;
                }

                return minOrMaxPriceAlteration;

            case 'alternative':

                if (priceAlterations.length === 0)
                {
                    return new Decimal(0);
                }
                else
                {
                    return priceAlterations[0];
                }
        }

        return new Decimal(0);
    }

    // ----------------------- Private logic ------------------------
}

export const ProductFeatureAssignmentProfile =
    SerializationProfile.create(ProductFeatureAssignment);
