import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { useRootContext } from '../../../RootContext';

interface NotificationSnackbarFullyInitializedRequirerProps
{
	showWhenLoading: React.ReactElement
}

export const NotificationSnackbarInitializedRequirer: FC<NotificationSnackbarFullyInitializedRequirerProps> =
	(
		{
			children,
			showWhenLoading,
		},
	) =>
	{
		const {notification} = useRootContext(true);
		const initialized = useObserver(() => notification.initialized);

		return initialized
			?
			<>
				{children}
			</>
			:
			showWhenLoading;
	};