import { Grid } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import * as React from 'react';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from '../../../../Bridge/Localization/useTranslation';
import { ActionButton } from '../../../UI/action-button/ActionButton';
import { BillSettlerDialog } from '../Payment/bill-settler/BillSettlerDialog';
import { HistorySectionHeader } from './HistorySectionHeader';

export const HistoryOpenBillSection: FC =
	() =>
	{
		const [dialogOpen, setDialogOpen] = useState(false);
		const openDialog = useCallback(() => setDialogOpen(true), []);
		const closeDialog = useCallback(() => setDialogOpen(false), []);
		return <>
			<HistorySectionHeader translationKey="Client-Order-OpenBill"/>
			{
				dialogOpen &&
				<BillSettlerDialog onClose={closeDialog}/>
			}
			<Grid item xs={12}>
				<ActionButton
					icon={VisibilityIcon}
					text={useTranslation('Generic-See')}
					onClick={openDialog}
					variant="paper"
					fullWidth
				/>
			</Grid>
		</>;
	};
