import { useEffect } from 'react';
import { ExternalBill } from '../../../../../../../Api/ExternalBill/ExternalBill';
import { ExternalBillStateEvent } from '../../../../../../../Api/ExternalBill/ExternalBillStateEvent';
import { useRootContext } from '../../../../../../../RootContext';
import { fetchAny } from '../../../../../../../Util/Api';

export function useExternalBillUpdater(
	externalBill: ExternalBill | undefined,
	onUpdate: (externalBill: ExternalBill) => void
)
{
	const {
		webSocketService,
	} = useRootContext(true);

	useEffect(
		() =>
			webSocketService?.onMessage(
				'ExternalBillStateEvent',
				(event: ExternalBillStateEvent) =>
				{
					if (event.externalBillId === externalBill?.id)
					{
						fetchAny(`/clientExternalBills/${event.externalBillId}`, {})
							.then(onUpdate);
					}
				}
			),
		[
			webSocketService,
			externalBill,
			onUpdate,
		]
	);
}
