import { TextField } from '@material-ui/core';
import * as React from 'react';
import { ChangeEvent, FC, MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalizer } from '../../../Bridge/Localization/useLocalizer';
import { useDebounce } from '../../../Util/Hooks/useDebounce';
import { useSearchContext } from './SearchContext';

interface SearchInputProps
{
	className?: string
	inputClassName?: string
	inputRootClassName?: string
	inputRef?: MutableRefObject<HTMLInputElement | null>
	placeholder?: string
}

export const SearchInput: FC<SearchInputProps> =
	(
		{
			className,
			inputClassName,
			inputRootClassName,
			inputRef,
			placeholder,
		},
	) =>
	{
		const localizer = useLocalizer();
		const {setQuery} = useSearchContext();

		const [localQuery, setLocalQuery] = useState<string | undefined>(undefined);
		const debouncedLocalQuery = useDebounce(localQuery, 300);

		const handleChange = useCallback(
			(event: ChangeEvent<{ value: string }>) =>
				setLocalQuery(
					(event.target.value !== undefined && event.target.value !== '')
						? event.target.value
						: undefined,
				)
			,
			[setLocalQuery],
		);

		useEffect(() => setQuery(debouncedLocalQuery), [debouncedLocalQuery, setQuery]);

		const placeholderString = useMemo(() => placeholder ?? `${localizer.translate('Generic-Search')}...`, [localizer, placeholder]);

		return <TextField
			classes={{
				root: className,
			}}
			InputProps={{
				classes: {
					input: inputClassName,
					root: inputRootClassName,
				},
				disableUnderline: true,
			}}
			inputRef={inputRef}
			onChange={handleChange}
			placeholder={placeholderString}
			value={localQuery ?? ''}
		/>;
	};
