import { grey } from '@material-ui/core/colors';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import QrcodeScan from 'mdi-material-ui/QrcodeScan';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { ScanType } from '../../../../Api/Other/Scan';
import { useLocalizer } from '../../../../Bridge/Localization/useLocalizer';
import { ScreenInstantiation } from '../../../../Bridge/Navigator/ScreenInstantiation';
import { getFileUrl } from '../../../../Util/Api/Resources/getFileUrl';
import { ActionButton } from '../../../UI/action-button/ActionButton';

interface ScannerButtonProps
{
	mode: ScanType;
	onStartScanning: () => Promise<ScreenInstantiation>;
	pointerEvents?: boolean;
}

export const ScannerButton: FC<ScannerButtonProps> =
	(
		{
			mode,
			onStartScanning,
			pointerEvents,
		},
	) =>
	{
		const localizer = useLocalizer();

		const languageEntryId = useMemo(() => mode === 'stream' ? 'Qr-Code-Scan' : 'Qr-Code-Photo', [mode]);
		const icon = useMemo(() => mode === 'stream' ? QrcodeScan : PhotoCamera, [mode]);

		return <ActionButton
			icon={icon}
			text={localizer.translate(languageEntryId)}
			onClick={onStartScanning}
			paperStyle={{
				overflow: 'hidden',
			}}
			style={{
				pointerEvents: pointerEvents ? 'auto' : 'none',
			}}
			variant="paper"
		>
			<div
				style={{
					width: '100%',
					background: grey[100],
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<img
					alt="logo"
					style={{
						margin: 5,
						height: 30,
					}}
					src={getFileUrl('/static/butlaroo/logo/butlaroo-logo-black.svg')}
				/>
			</div>
		</ActionButton>;
	};