import { OrderDestinationAddress } from '../../../../Api/Business/OrderDestinationAddress';
import { getUrlParameterByName } from '../../../../Util/Url/getUrlParameterByName';

export function getOrderDestinationAddressFromUrl()
{
	const destinationAddressAsString = getUrlParameterByName('orderDestinationAddress');

	if (destinationAddressAsString)
	{
		const sourceAddress = JSON.parse(destinationAddressAsString);
		const address = new OrderDestinationAddress();
		address.street = sourceAddress.street;
		address.number = sourceAddress.number;
		address.line2 = sourceAddress.line2;
		address.name = sourceAddress.name;
		address.companyName = sourceAddress.companyName;
		address.postalCode = sourceAddress.postalCode;
		address.city = sourceAddress.city;
		address.stateOrProvince = sourceAddress.stateOrProvince;
		address.country = sourceAddress.country;

		return address;
	}
	else
	{
		return undefined;
	}
}
