import { Link, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getName, registerLocale } from 'i18n-iso-countries';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FunctionComponent, useMemo } from 'react';
import { ContractingEntity } from '../../Api/contracting_entity/ContractingEntity';
import { useLocale } from '../../Bridge/Localization/useLocale';
import { useTranslate } from '../../Bridge/Localization/useTranslate';
import { useRootContext } from '../../RootContext';

const useStyles = makeStyles(theme => ({
	root: {
		columnGap: theme.spacing(2),
		display: 'grid',
		gridTemplateColumns: 'repeat(12, 1fr)',
	},
	addressLine: {
		gridColumn: 'span 12',
	},
	linkPrefix: {
		marginRight: theme.spacing(2),
	},
	link: {
		cursor: 'pointer',
		color: theme.palette.text.secondary,
		fontSize: '1rem',

		'&:hover': {
			color: theme.palette.primary.main,
		},
	},
	linkPlaceholder: {
		color: theme.palette.text.secondary,
	},
	table: {
		gridColumn: 'span 12',

		'& td': {
			borderBottom: 'none',
		},

		'& tr': {
			verticalAlign: 'top',
		},
	},
}));

interface ContractingEntityContactInformationViewProps
{
	contractingEntity: ContractingEntity;
}

export const ContractingEntityContactInformationView: FunctionComponent<ContractingEntityContactInformationViewProps> =
	({
		contractingEntity,
	}) =>
	{
		const {client} = useRootContext(true);
		const clientLocale = useLocale();
		const translate = useTranslate();

		const classes = useStyles();

		const isMobileOrTablet = useObserver(() => client.isMobileOrTablet());

		const countryName = useMemo(
			() =>
			{
				registerLocale(require(`i18n-iso-countries/langs/${clientLocale.languageCode}.json`));

				return getName(contractingEntity.address.country, clientLocale.languageCode);
			},
			[clientLocale.languageCode, contractingEntity.address.country],
		);

		return <div
			className={classes.root}
		>
			<Typography
				className={classes.addressLine}
			>
				{contractingEntity.name}
			</Typography>
			<Typography
				className={classes.addressLine}
			>
				{contractingEntity.address.line1}
			</Typography>
			{
				contractingEntity.address.line2 !== undefined &&
				<Typography
					className={classes.addressLine}
				>
					{contractingEntity.address.line2}
				</Typography>
			}
			<Typography
				className={classes.addressLine}
			>
				{contractingEntity.address.postalCode}, {contractingEntity.address.city}
			</Typography>
			<Typography
				className={classes.addressLine}
				paragraph
			>
				{countryName}
			</Typography>
			<Table
				className={classes.table}
				padding="none"
				size="small"
			>
				<TableBody>
					<TableRow>
						<TableCell>
							<Typography
								className={classes.linkPrefix}
							>
								{translate('Generic-Email-Address-Short')}:
							</Typography>
						</TableCell>
						<TableCell>
							<Link
								className={classes.link}
								href={`mailto:${contractingEntity.contact.emailAddress}`}
								target="_blank"
							>
								{contractingEntity.contact.emailAddress}
							</Link>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<Typography
								className={classes.linkPrefix}
							>
								{translate('Generic-Phone-Number-Short')}:
							</Typography>
						</TableCell>
						<TableCell>
							{
								isMobileOrTablet
									? <Link
										className={classes.link}
										href={`tel:${contractingEntity.contact.phoneNumber}`}
									>
										{contractingEntity.contact.phoneNumber}
									</Link>
									: <Typography
										className={classes.linkPlaceholder}
									>
										{contractingEntity.contact.phoneNumber}
									</Typography>
							}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<Typography
								className={classes.linkPrefix}
							>
								{translate('Generic-Website')}:
							</Typography>
						</TableCell>
						<TableCell>
							<Link
								className={classes.link}
								href={`${contractingEntity.contact.url}?utm_source=app&utm_medium=nav_contact`}
								target="_blank"
							>
								{contractingEntity.contact.url}
							</Link>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</div>;
	};
