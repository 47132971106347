// @ts-nocheck
/* eslint-disable */
import * as DetectBrowser from 'detect-browser';
import { BotInfo, BrowserInfo, NodeInfo } from 'detect-browser';
import { action, computed, makeObservable, observable } from 'mobx';
import { AppType } from '../../Constants/AppType';
import { Client } from './Client';
import { BrowserName } from './Model/BrowserName';
import { ClientVisibility } from './Model/ClientVisibility';

export type ClientBrowserName = BrowserName | 'opera-mini'; // Quick fix for error in assigning this.browserName

export class WebClient extends Client
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    /**
     * Even though having an instance of {@link WebClient} already means the current client is a web app, we do not know
     * if the thing running the web app is a browser, a NodeJS instance, or a bot (e.g. a search engine's crawler). This
     * property shows which is the case.
     */
    private readonly webClientType: 'browser' | 'bot' | 'node';
    public readonly browserName: ClientBrowserName;
    private readonly browserVersion: number;

    /**
     * This detects if the web app was started as a PWA from an app launcher; in that case the {@link #displayMode} will
     * be 'standalone'. If the app is opened in a browser, the {@link #displayMode} is 'browser'.
     */
    public displayMode: 'browser' | 'standalone';

    private readonly detectedClientInfo: BrowserInfo | BotInfo | NodeInfo | null;

    public scrollYOffset: number;

    public viewportWidth: number;

    public viewportHeight: number;

    // ------------------------ Constructor -------------------------

    constructor()
    {
        super();

        makeObservable(this, {
            displayMode: observable,
            scrollYOffset: observable,
            viewportWidth: observable,
            viewportHeight: observable,

            platform: computed,
            isFacebookApp: computed,

            setClientVisibilityForTest: action.bound,
            setClientVisibility: action.bound,
            setScrollYOffset: action.bound,
            updateViewportDimensions: action.bound,
        });

        this.type = AppType.Web;

        this.visibility = undefined;
        this.detectedClientInfo = DetectBrowser.detect();

        if (this.detectedClientInfo)
        {
            switch (this.detectedClientInfo.name)
            {
                case 'bot':
                    this.webClientType = 'bot';
                    break;
                case 'node':
                    this.webClientType = 'node';
                    break;
                case null:
                    this.webClientType = undefined;
                    break;
                default:
                    this.webClientType = 'browser';
            }
            if (this.detectedClientInfo instanceof BrowserInfo)
            {
                this.browserName = this.detectedClientInfo.name;
                this.operatingSystem = this.detectedClientInfo.os;

                let parsedVersion = parseFloat(this.detectedClientInfo.version);
                this.browserVersion = !isNaN(parsedVersion)
                    ? parsedVersion
                    : undefined;
            }
        }

        // todo: in case of web client type being Node or a bot, correctly set this.operatingSystem
        this.initializeDisplayMode();
        this.initializeDocumentVisibilityEventListener();

        window.addEventListener('resize', this.updateViewportDimensions);
        this.updateViewportDimensions();
    }

    // ----------------------- Initialization -----------------------

    initializeDisplayMode()
    {
        if ((this.browserName === 'safari' || this.browserName === 'ios') && window.navigator.hasOwnProperty('standalone'))
        {
            this.displayMode = (window.navigator as any).standalone
                ? 'standalone'
                : 'browser';
        }
        else
        {
            this.displayMode = window.matchMedia('(display-mode: standalone)').matches
                ? 'standalone'
                : 'browser';
        }
    }

    // -------------------------- Computed --------------------------

    get platform(): string
    {
        if (this.displayMode === 'standalone')
        {
            return `${this.displayMode} ${this.browserName} ${this.browserVersion}`;
        }
        else
        {
            return `${this.browserName} ${this.browserVersion}`;
        }
    }

    get isFacebookApp()
    {
        const ua = navigator.userAgent || navigator.vendor || (window as any).opera;
        return (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1);
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    /**
     * Should only be called by test code
     */
    public setClientVisibilityForTest(clientVisibility: ClientVisibility): void
    {
        this.setClientVisibility(clientVisibility);
    }

    /**
     * @param clientVisibility
     */
    private setClientVisibility(clientVisibility: ClientVisibility): void
    {
        this.visibility = clientVisibility;
    }

    setScrollYOffset(scrollYOffset: number)
    {
        this.scrollYOffset = scrollYOffset;
    }

    // ------------------------ Public logic ------------------------

    public isMobileOrTablet()
    {
        if (this.operatingSystem == null)
        {
            return true;
        }
        else if (
            this.operatingSystem.startsWith('Windows')
            && this.operatingSystem !== 'Windows Mobile'
            && this.operatingSystem !== 'Windows 8'
            && this.operatingSystem !== 'Windows 8.1'
        )
        {
            return false;
        }

        let check = false;
        ((a) => {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
            {
                check = true;
            }
        })(navigator.userAgent || navigator.vendor || (window as any).opera);

        return check;
    }

    get canStreamUserMedia(): boolean
    {
        return this.supportsUserMediaStream;
    }

    get supportsUserMediaStream(): boolean
    {
        if (navigator === undefined
            || navigator.mediaDevices === undefined
            || navigator.mediaDevices.getUserMedia === undefined)
        {
            return false;
        }

        if (this.webClientType === 'browser')
        {
            if (this.operatingSystem === 'iOS')
            {
                if (this.browserName !== 'ios' && this.browserName !== 'safari')
                {
                    return false;
                }

                if ((this.browserName === 'ios' || this.browserName === 'safari') && this.browserVersion < 11)
                {
                    return false;
                }

                if (this.displayMode === 'standalone')
                {
                    return false;
                }
            }
        }
        return true;
    }

    /**
     * If turning on the flashlight during a user media stream is supported. If support is confirmed, the returned promise is resolved; if
     * support is confirmed to not exist, then returned promise is rejected.
     */
    public torchSupport(mediaStream: MediaStream): Promise<void>
    {
        if (this.webClientType === 'browser')
        {
            const os = this.operatingSystem;
            const isNotMobile = os !== 'Android OS'
                && os !== 'iOS'
                && os !== 'Windows Mobile'
                && os !== 'Amazon OS';
            if (isNotMobile)
            {
                return Promise.reject();
            }
            if (this.operatingSystem === 'iOS')
            {
                return Promise.reject();
            }
            if (this.browserName !== 'chrome')
            {
                return Promise.reject();
            }
        }

        return this.pollTorchSupported(mediaStream, 25);
    }

    /**
     * Check for torch support by polling for n times with interval 200ms. The polling consists of attempting set the torch
     * constraint to false on the MediaStream, and if this throws an exception, the MediaStream is either not properly
     * initialized yet, or we are in a client that does not support the torch constraint. We poll for a while to wait out the
     * initialization of the MediaStream.
     * @param mediaStream the stream on which torch support is polled
     * @param n the number times it should poll
     */
    private pollTorchSupported(mediaStream: MediaStream, n: number): Promise<void>
    {
        return new Promise((resolve, reject) => {
            if (mediaStream && mediaStream.active && n > 0)
            {
                setTimeout(
                    () => this.setTorchOnMediaStream(mediaStream, false)
                        .then(() => resolve())
                        .catch(() => this.pollTorchSupported(mediaStream, n - 1)
                            .then(() => resolve())
                            .catch(() => reject())),
                    200,
                );
            }
            else
            {
                reject();
            }
        });
    }

    get supportsURLSearchParams(): boolean
    {
        if (this.browserName === 'edge') {
            return false;
        }
        return true;
    }

    // ----------------------- Private logic ------------------------

    private updateViewportDimensions(): void
    {
        this.viewportWidth = window.innerWidth;
        this.viewportHeight = window.innerHeight;
    }

    private initializeDocumentVisibilityEventListener()
    {
        const hidden = this.documentVisibilityPropertyName();
        const visibilityChange = this.documentVisibilityChangeEventName();
        const callback = () => {
            this.setClientVisibility(
                !document[hidden]
                    ?
                    'visible'
                    :
                    'invisible'
            );
        };
        document.addEventListener(
            visibilityChange,
            () => callback(),
        );
        callback();
    }

    private documentVisibilityPropertyName(): string
    {
        if (typeof document.hidden !== 'undefined')
        { // Opera 12.10 and Firefox 18 and later support
            return 'hidden';
        }
        else if (typeof document['msHidden'] !== 'undefined')
        {
            return 'msHidden';
        }
        else if (typeof document['webkitHidden'] !== 'undefined')
        {
            return 'webkitHidden';
        }
    }

    private documentVisibilityChangeEventName(): string
    {
        if (typeof document.hidden !== 'undefined')
        { // Opera 12.10 and Firefox 18 and later support
            return 'visibilitychange';
        }
        else if (typeof document['msHidden'] !== 'undefined')
        {
            return 'msvisibilitychange';
        }
        else if (typeof document['webkitHidden'] !== 'undefined')
        {
            return 'webkitvisibilitychange';
        }
    }

    private setTorchOnMediaStream(mediaStream: MediaStream, torchEnabled: boolean): Promise<void>
    {
        const track = mediaStream.getVideoTracks()[0];
        return track
            .applyConstraints({
                advanced: [
                    {
                        torch: torchEnabled,
                    } as any,
                ],
            });
    }
}
