import { Typography } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, Fragment } from 'react';
import { ComoRewardStore } from './ComoRewardStore';

interface ComoRewardInformationProps
{
	store: ComoRewardStore;
}

export const ComoRewardInformation: FC<ComoRewardInformationProps> =
	({
		store,
	}) =>
	{
		const rewardName = store.reward.name;
		const rewardDescriptionLines = useObserver(() => store.descriptionLines);

		return <>
			{
				rewardDescriptionLines
					?.map((line, idx) =>
						<div
							key={idx}
							style={(idx === 0)
								? {pageBreakInside: 'avoid'}
								: {}
							}
						>
							{
								idx === 0 &&
								<Typography
									variant="h4"
									style={{
										bottom: 0,
										color: 'black',
										margin: '0px 0px 13px -2px',
										fontFamily: '\'Raleway\', sans-serif',
									}}
								>
									{rewardName}
								</Typography>
							}
							<Typography
								variant="body1"
								color="textPrimary"
							>
								{
									line.length === 0 && idx !== rewardDescriptionLines.length - 1
										?
										<span>&nbsp;</span>
										:
										line
								}
							</Typography>
						</div>,
					)
			}
		</>;
	};