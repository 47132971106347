import { BaseStore } from '@intentic/ts-foundation';
import Decimal from 'decimal.js';
import { action, computed, makeObservable, observable, ObservableMap } from 'mobx';
import { BasketRequirements } from '../../../../../Api/Order/Loyalty/Como/BasketRequirements';
import { Reward } from '../../../../../Api/Order/Loyalty/Como/Reward';
import { ProductConfiguration } from '../../../../../Api/Product/ProductConfiguration';
import { postJsonAny } from '../../../../../Util/Api';
import { IllegalArgumentException } from '../../../../../Util/Exception/IllegalArgumentException';
import { isDefined } from '../../../../../Util/isDefined';
import { ShoppingCartLine } from '../../Product/ProductConfiguration/ShoppingCartLine';
import { ComoRewardsStore } from '../Rewards/ComoRewardsStore';

export class ComoRewardStore extends BaseStore
{
	// ------------------------ Dependencies ------------------------

	// ------------------------- Properties -------------------------

	public readonly comoRewardsStore: ComoRewardsStore;

	public readonly rewardId: string;

	public showValidation: boolean;

	public readonly productsByBasketRequirements = observable.map<BasketRequirements, ObservableMap<string, ShoppingCartLine>>();

	// ------------------------ Constructor -------------------------

	constructor(
		comoRewardsStore: ComoRewardsStore,
		rewardId: string,
	)
	{
		super();

		makeObservable(
			this,
			{
				showValidation: observable,
				productsByBasketRequirements: observable,
				reward: computed,
				isRedeemed: computed,
				descriptionLines: computed,
				basketRequirements: computed,
				hasErrorByBasketRequirements: computed,
				setShowValidation: action.bound,
				addProductConfiguration: action.bound,
				removeProductConfiguration: action.bound,
				redeem: action.bound,
				remove: action.bound,
				setTabIndex: action.bound,
			},
		);

		this.comoRewardsStore = comoRewardsStore;
		this.rewardId = rewardId;

		this.showValidation = false;
	}

	// ----------------------- Initialization -----------------------

	async initialize(): Promise<void> {}

	// -------------------------- Computed --------------------------

	get reward(): Reward
	{
		return this.comoRewardsStore.rewardsById.get(this.rewardId);
	}

	get isRedeemed(): boolean
	{
		return this.comoRewardsStore.shoppingCartStore.currentOrderService.confirmedAssetKeys.includes(this.reward.id);
	}

	get descriptionLines(): string[]
	{
		return this.reward.description.split(/\n/);
	}

	get basketRequirements(): BasketRequirements[]
	{
		return this
				.reward
				.itemsSelection
				?.discounts
				?.filter(isDefined)
				?.flatMap(
					discount =>
						discount.basketRequirements ?? [],
				)
			?? [];
	}

	get hasErrorByBasketRequirements(): Map<BasketRequirements, boolean>
	{
		const currencyFractionDigits = this.comoRewardsStore.businessStore.bridge.localizer.getCurrencyFractionDigits(
			this.comoRewardsStore.businessStore.business.productCurrencyCode,
		);

		return this
			.basketRequirements
			.reduce(
				(result, current) =>
				{
					const productConfigurations = Array.from(
						this.productsByBasketRequirements.get(current)?.values() ?? [],
					);

					const minimumOrdered = productConfigurations.reduce(
						(result, current) =>
							result + current.quantity,
						0,
					);

					const minimumSpend = productConfigurations.reduce(
						(result, current) =>
							result.add(current.price),
						new Decimal(0),
					);

					const minimumSpendMinor = minimumSpend.mul(
						new Decimal(10).pow(currencyFractionDigits),
					);

					const minimumOrderedHasError = current.conditions?.quantity?.minimum === undefined
						? false
						: minimumOrdered < current.conditions.quantity.minimum;

					const minimumSpendHasError = current.conditions?.spend?.minimum === undefined
						? false
						: minimumSpendMinor.comparedTo(current.conditions.spend.minimum) < 0;

					result.set(current, minimumOrderedHasError || minimumSpendHasError);

					return result;
				},
				new Map(),
			);
	}

	get hasItemsSelection(): boolean
	{
		return this.reward.itemsSelection !== undefined;
	}

	// --------------------------- Stores ---------------------------

	// -------------------------- Actions ---------------------------

	public setShowValidation(value: boolean): void
	{
		this.showValidation = value;
	}

	public getProductConfigurations(basketRequirements: BasketRequirements): ShoppingCartLine[]
	{
		return Array.from(
			this.productsByBasketRequirements.get(basketRequirements)?.values() ?? [],
		);
	}

	public addProductConfiguration(
		basketRequirements: BasketRequirements,
		productConfiguration: ProductConfiguration,
	): void
	{
		if (!this.productsByBasketRequirements.has(basketRequirements))
			this.productsByBasketRequirements.set(basketRequirements, observable.map());

		const relevantProductConfigurationMap = this.productsByBasketRequirements.get(basketRequirements);

		if (relevantProductConfigurationMap.has(productConfiguration.id))
		{
			relevantProductConfigurationMap
				.get(productConfiguration.id)!
				.incrementQuantity();
		}
		else
		{
			relevantProductConfigurationMap.set(
				productConfiguration.id,
				new ShoppingCartLine(
					productConfiguration,
					1,
				),
			);
		}
	}

	public removeProductConfiguration(productConfiguration: ProductConfiguration): void
	{
		const existingMappedProductConfiguration = Array
			.from(this.productsByBasketRequirements.entries())
			.map(
				entry =>
				{
					const productConfigurationMap = entry[1];

					if (productConfigurationMap === undefined)
					{
						return undefined;
					}
					else
					{
						const existingProductConfiguration = Array
							.from(productConfigurationMap.values())
							.map(
								value =>
									value.productConfiguration,
							)
							.filter(
								value =>
									value === productConfiguration,
							)[0];

						return existingProductConfiguration === undefined
							? undefined
							: {
								basketRequirements: entry[0],
								existingProductConfiguration,
							};
					}
				},
			)
			.filter(isDefined)[0];

		if (existingMappedProductConfiguration === undefined)
			throw new IllegalArgumentException('Product configuration not found');

		const relevantProductConfigurationMap = this.productsByBasketRequirements.get(
			existingMappedProductConfiguration.basketRequirements,
		);

		relevantProductConfigurationMap.delete(existingMappedProductConfiguration.existingProductConfiguration.id);

		if (relevantProductConfigurationMap.size === 0)
			this.productsByBasketRequirements.delete(existingMappedProductConfiguration.basketRequirements);
	}

	async redeem(): Promise<void>
	{
		if (this.reward.points !== undefined)
		{
			const assetKey = await this.redeemPointShopItem();

			await this.comoRewardsStore.refreshMembership();

			if (this.comoRewardsStore.rewardsById.get(assetKey)?.redeemable)
			{
				this.comoRewardsStore.shoppingCartStore.currentOrderService.setAssetKey(assetKey);
				await this.comoRewardsStore.shoppingCartStore.currentOrderService.checkOrderIfNecessary();
			}

			await this.close();
		}
		else if (this.reward.redeemable)
		{
			this.comoRewardsStore.shoppingCartStore.currentOrderService.setAssetKey(this.reward.id);
			await this.comoRewardsStore.shoppingCartStore.currentOrderService.checkOrderIfNecessary();

			await this.close();
		}
		else
		{
			const atLeastOneBasketRequirementsHasError = Array
				.from(this.hasErrorByBasketRequirements.values())
				.some(
					value =>
						value === true
				);

			if (atLeastOneBasketRequirementsHasError)
			{
				this.setShowValidation(true);
			}
			else
			{
				Array
					.from(this.productsByBasketRequirements.values())
					.flatMap(
						values =>
							Array.from(values.values()),
					)
					.forEach(
						line =>
							this.comoRewardsStore.shoppingCartStore.add(
								line.productConfiguration,
								line.quantity,
							),
					);

				this.comoRewardsStore.shoppingCartStore.currentOrderService.setAssetKey(this.reward.id);
				await this.comoRewardsStore.shoppingCartStore.currentOrderService.checkOrderIfNecessary();

				await this.close();
			}
		}
	}

	async remove(): Promise<void>
	{
		this.comoRewardsStore.shoppingCartStore.currentOrderService.removeAssetKey(this.reward.id);
		await this.comoRewardsStore.shoppingCartStore.currentOrderService.checkOrderIfNecessary();

		await this.close();
	}

	// ------------------------ Public logic ------------------------

	public close(): Promise<unknown>
	{
		return this.comoRewardsStore.businessStore.bridge.navigator.popScreen();
	}

	public setTabIndex(index: number): void
	{
		this.comoRewardsStore.setTabIndex(index);
	}

	// ----------------------- Private logic ------------------------

	private redeemPointShopItem(): Promise<string>
	{
		return postJsonAny<string>(
			'/client/business/loyalty/como/point-shop/redeem',
			{
				como_point_shop_item_id: this.reward.id,
			},
		);
	}
}
