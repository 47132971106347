import Decimal from 'decimal.js';
import { ISO4217ToCurrencyTransformer, NumberToDecimalTransformer, SerializationProfile } from '../../Util/Serialization/Serialization';
import { Currency } from './Currency';

export class Money
{
	readonly currency: Currency;
	readonly amount: Decimal;

	constructor(currency: Currency, amount: Decimal)
	{
		this.currency = currency;
		this.amount = amount;
	}

	/**
	 * Amount in cents, e.g. $13.45 would become 1345, or -$2.00 would become -200.
	 */
	public get amountMinor(): Decimal
	{
		return this.amount
			.mul(new Decimal(10).pow(this.currency.decimalPlaces ?? 0))
			.round();
	}
}

export const MoneyProfile = SerializationProfile.create(Money)
	.transform('currency', ISO4217ToCurrencyTransformer)
	.transform('amount', NumberToDecimalTransformer);
