import { makeAutoObservable } from 'mobx';
import { SerializationProfile } from '../../Util/Serialization/Serialization';
import { OrderRestrictionConfiguration } from './OrderRestriction/OrderRestrictionConfiguration';
import { OrderRestrictionType } from './OrderRestrictionType';

export class OrderRestrictionReport
{
    isPassed: number;
    message: string;
    type: OrderRestrictionType;
    configuration: OrderRestrictionConfiguration;
    metadata: {};

    constructor()
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
    }
}

export const OrderRestrictionReportProfile =
    SerializationProfile.create(OrderRestrictionReport);
