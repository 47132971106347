import { Box } from '@material-ui/core';
import * as React from 'react';
import { ReactNode } from 'react';

export interface ListItemPrimaryBadgesProps
{
    primaryBadges?: ReactNode[];
}

export const ListItemPrimaryBadges: React.FC<ListItemPrimaryBadgesProps> =
    ({
        primaryBadges,
    }) =>
    {
        if ((primaryBadges ?? []).length > 0)
        {
            return <>
                {
                    primaryBadges.map(
                        (badge, idx) =>
                            <Box
                                key={idx}
                                paddingLeft={1}
                            >
                                {badge}
                            </Box>
                    )
                }
            </>;
        }
        else
        {
            return null;
        }
    };
