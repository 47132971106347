import Decimal from 'decimal.js';
import { Duration } from 'iso8601-duration';
import { computed, makeObservable, observable } from 'mobx';
import { NumberToDecimalTransformer, SerializationProfile, StringToDurationTransformer } from '../../Util/Serialization/Serialization';
import { OrderState } from '../Order/Order';
import { AddressRestriction, AddressRestrictionProfile } from '../Other/AddressRestriction';
import { DeliveryConfiguration, DeliveryConfigurationProfile } from '../Other/DeliveryConfiguration';
import { TimeScheduleSpecification } from '../Util/time/TimeScheduleSpecification';
import { VatGroup } from '../vat_group/VatGroup';
import { PlaceCode } from './PlaceCode';
import { Routing, RoutingProfile } from './Routing/Routing';

export type OrderFormFieldSupport = 'DISALLOWED' | 'ALLOWED' | 'REQUIRED';
export type VariableOrderFormFieldSupport = OrderFormFieldSupport | 'VARIABLE';
export type OrderDestinationType = 'PLACE' | 'PICKUP_POINT' | 'ADDRESS';
export type PlaceState = 'deleted' | 'disabled' | 'enabled';
export type ScheduledOrderPlacementTime = 'ORDER_CREATION' | 'PREPARATION_START';

export class Place
{
    // ------------------------- Properties -------------------------

    id: number;
    name: string;
    state: PlaceState;
    isOrderWaiterSupported: boolean;
    isOrderBillSupported: boolean;
    isOrderProductSupported: boolean;
    isClientNameRequired: boolean;
    isClientNameNumeric: boolean;
    orderExpiryDuration?: Duration;
    scaninExpiryDuration?: Duration;
    couponCodeSupport: OrderFormFieldSupport;
    orderInFutureSupport: OrderFormFieldSupport;
    customerCardSupport: OrderFormFieldSupport;
    lastNameField: OrderFormFieldSupport;
    companyNameField: OrderFormFieldSupport;
    phoneNumberField: OrderFormFieldSupport;
    emailField: OrderFormFieldSupport;
    commentField: OrderFormFieldSupport;
    tipField: OrderFormFieldSupport;
    readonly passwordSupport: OrderFormFieldSupport;
    minimumOrderValue: Decimal;
    public readonly pickupLocationDescription: string | undefined;
    public readonly pickupLocationDescriptionLanguageCode: string | undefined;
    deliveryConfiguration: DeliveryConfiguration;
    deliveryFeeVatGroup?: VatGroup | undefined;
    public readonly deliveryFeeRouting?: Routing;
    supportsOrderingAsSoonAsPossible: boolean;
    minimumOrderTimeInFuture: Duration;
    public readonly orderInFutureAllowedDates: TimeScheduleSpecification;
    supportsDestinationTypePlace: boolean;
    supportsDestinationTypePickupPoint: boolean;
    supportsDestinationTypeAddress: boolean;
    defaultDestinationType: OrderDestinationType | undefined;
    addressRestrictions: AddressRestriction[] | undefined;
    loyaltyIntegrationId?: string;
    public readonly paymentUpFrontSupport: VariableOrderFormFieldSupport;
    public readonly upFrontPaymentAllowedTimeScheduleId?: number;
    public readonly upFrontPaymentRequiredTimeScheduleId?: number;
    public readonly paymentAfterEnabled: boolean;
    placeCodes: PlaceCode[];
    menuCardIds?: number[];
    smsRemindersEnabled: boolean;
    scheduledOrderPlacementTime: ScheduledOrderPlacementTime;
    finalOrderState: OrderState;
    timeSchedulesIgnored: boolean;
    routingStatusIgnored: boolean;
    productCurrencySymbolsHidden: boolean;
    payExternalBillLegacyMode: boolean;
    public readonly explicitConsentOrderTrackerRequired: OrderFormFieldSupport;
    public readonly explicitConsentOrderTrackerStatement: string | undefined;
    searchEnabled: boolean;
    public readonly orderProductsTimeScheduleId?: number;
    public readonly requestBillRoutingId?: number;
    public readonly requestBillTimeScheduleId?: number;
    public readonly requestWaiterRoutingId?: number;
    public readonly requestWaiterTimeScheduleId?: number;
    public isShoppingCartSharingEnabled: boolean;
    public isAgeVerificationRequiredForRestrictedProducts: boolean;

    // ------------------------ Constructor -------------------------

    constructor(name: string)
    {
        makeObservable(
            this,
            {
                placeCodes: observable,
                menuCardIds: observable,
                smsRemindersEnabled: observable,
                scheduledOrderPlacementTime: observable,
                finalOrderState: observable,
                timeSchedulesIgnored: observable,
                routingStatusIgnored: observable,
                productCurrencySymbolsHidden: observable,
                payExternalBillLegacyMode: observable,
                supportsDestinationTypePlace: observable,
                supportsDestinationTypePickupPoint: observable,
                supportsDestinationTypeAddress: observable,
                searchEnabled: observable,
                supportedDestinationTypes: computed,
            },
        );

        this.name = name;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    public get supportedDestinationTypes(): OrderDestinationType[]
    {
        const destinationTypes: OrderDestinationType[] = [];
        if (this.supportsDestinationTypePlace)
        {
            destinationTypes.push('PLACE');
        }
        if (this.supportsDestinationTypePickupPoint)
        {
            destinationTypes.push('PICKUP_POINT');
        }
        if (this.supportsDestinationTypeAddress)
        {
            destinationTypes.push('ADDRESS');
        }
        return destinationTypes;
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}

export const PlaceProfile =
    SerializationProfile.create(Place)
        .profile('addressRestrictions', AddressRestrictionProfile)
        .profile('deliveryConfiguration', DeliveryConfigurationProfile)
        .profile('deliveryFeeRouting', RoutingProfile)
        .transform('scaninExpiryDuration', StringToDurationTransformer)
        .transform('minimumOrderValue', NumberToDecimalTransformer)
        .transform('minimumOrderTimeInFuture', StringToDurationTransformer);
