import { useObserver } from 'mobx-react-lite';
import { useContext } from 'react';
import { Product } from '../../Api/Product/Product';
import { useLocalizer } from '../../Bridge/Localization/useLocalizer';
import { BusinessContextRef } from '../../Component/Page/Business/BusinessContext';
import { computePriceLabel } from '../../Component/Page/Business/Menu/Api/computePriceLabel';

export function usePriceLabel(
	product: Product
): string | undefined
{
	const localizer = useLocalizer();
	const {
		businessStore,
		currentPlaceService
	} = useContext(BusinessContextRef);
	return useObserver(() => computePriceLabel(
		product,
		businessStore.business,
		currentPlaceService.place,
		localizer,
	));
}