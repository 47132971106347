// @ts-nocheck
/* eslint-disable */
import { useObserver } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';
import { WebDialog } from '../../Bridge/Dialog/WebDialog';
import { WebNavigator } from '../../Bridge/Navigator/WebNavigator';
import { BrowserRootLayoutPolyfillContextRef } from './BrowserRootLayoutPolyfillContextProvider';

export function useBrowserRootLayoutPolyfillerNavigationListener(
	dialog: WebDialog,
	navigator: WebNavigator,
)
{
	const navigationHash = useObserver(
		() => dialog.dialogs.length > 0
			? dialog.dialogs[dialog.dialogs.length - 1].id
			: navigator.currentScreenInstance?.uuid
	);
	const {
		reApplyCss
	} = useContext(BrowserRootLayoutPolyfillContextRef);
	useEffect(
		reApplyCss,
		[navigationHash]
	)
}