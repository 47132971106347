import Decimal from 'decimal.js';
import { makeAutoObservable } from 'mobx';
import { NumberToDecimalTransformer, SerializationProfile } from '../../Util/Serialization/Serialization';
import { extractPostalCodeNumber } from '../Util/postalCode';
import { DeliveryAddressOverride, DeliveryAddressOverrideProfile } from './DeliveryAddressOverride';

export class DeliveryConfiguration
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	priceAmount?: Decimal;
	minimumPriceAmount?: Decimal;
	freeDeliveryPriceAmountThreshold?: Decimal;
	addressOverrides?: DeliveryAddressOverride[];

	constructor()
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});
	}

	/*---------------------------------------------------------------*
	 *                         Business Logic                        *
	 *---------------------------------------------------------------*/

	getConfiguredPriceAmountFromPostalCodeString(
		countryCode: string | undefined = undefined,
		postalCodeString: string | undefined = undefined,
		amount: Decimal,
	): Decimal
	{
		const postalCodeNumbers = extractPostalCodeNumber(postalCodeString);

		return this.getConfiguredPriceAmount(countryCode, postalCodeNumbers, amount);
	}

	getConfiguredPriceAmount(
		countryCode: string | undefined = undefined,
		postalCode: number | undefined = undefined,
		amount: Decimal,
	): Decimal
	{
		const override = this.getOverride(countryCode, postalCode);

		if (override === undefined)
			return this.getDefaultPriceAmount(amount);

		return override.getOverriddenPriceAmount(amount) ?? this.getDefaultPriceAmount(amount);
	}

	getConfiguredMinimumPriceAmountFromPostalCodeString(
		countryCode: string | undefined = undefined,
		postalCodeString?: string,
	): Decimal | undefined
	{
		const postalCodeNumbers = extractPostalCodeNumber(postalCodeString);

		return this.getConfiguredMinimumPriceAmount(countryCode, postalCodeNumbers);
	}

	getConfiguredMinimumPriceAmount(
		countryCode: string | undefined = undefined,
		postalCode: number | undefined = undefined,
	): Decimal | undefined
	{
		const override = this.getOverride(countryCode, postalCode);

		if (override === undefined)
			return this.getDefaultMinimumPriceAmount();

		return override.getOverriddenMinimumPriceAmount() ?? this.getDefaultMinimumPriceAmount();
	}

	private getOverride(
		countryCode: string | undefined = undefined,
		postalCode: number | undefined = undefined,
	): DeliveryAddressOverride | undefined
	{
		if (countryCode === undefined)
			return undefined;

		if (postalCode === undefined)
			return this.getCountryOverride(countryCode);

		const postalCodeOverride = this.getPostalCodeOverride(countryCode, postalCode);

		if (postalCodeOverride !== undefined)
			return postalCodeOverride;

		return this.getCountryOverride(countryCode);
	}

	private getPostalCodeOverride(
		countryCode: string,
		postalCode: number,
	): DeliveryAddressOverride | undefined
	{
		if (this.addressOverrides === undefined)
			return undefined;

		return this.addressOverrides
			.filter(override => override.isForCountry(countryCode) && override.containsPostalCode(postalCode))[0];
	}

	private getCountryOverride(countryCode: string): DeliveryAddressOverride | undefined
	{
		if (this.addressOverrides === undefined)
			return undefined;

		return this.addressOverrides
			.filter(override => override.isDefaultForCountry(countryCode))[0];
	}

	private getDefaultPriceAmount(amount: Decimal): Decimal
	{
		return this.amountExceedsZeroPriceThreshold(amount)
			? new Decimal(0)
			: this.priceAmount ?? new Decimal(0);
	}

	private getDefaultMinimumPriceAmount(): Decimal | undefined
	{
		return this.minimumPriceAmount;
	}

	private amountExceedsZeroPriceThreshold(amount: Decimal): boolean
	{
		if (this.freeDeliveryPriceAmountThreshold === undefined)
			return false;

		return this.freeDeliveryPriceAmountThreshold.lessThanOrEqualTo(amount);
	}
}

export const DeliveryConfigurationProfile = SerializationProfile.create(DeliveryConfiguration)
	.profile('addressOverrides', DeliveryAddressOverrideProfile)
	.transform('priceAmount', NumberToDecimalTransformer)
	.transform('minimumPriceAmount', NumberToDecimalTransformer)
	.transform('freeDeliveryPriceAmountThreshold', NumberToDecimalTransformer);