import { QrCodeScan, QrReaderType } from '@intentic/qr-reader-web';
import { makeAutoObservable } from 'mobx';
import { Client } from '../../../Bridge/Client/Client';
import { Localizer } from '../../../Bridge/Localization/Localizer';
import { HashNotMatchedException } from '../../Page/Entrance/HashNotMatchedException';
import getPlaceCodeHashFromQrCode from './getPlaceCodeHashFromQrCode';
import { PlaceCodeScannerState } from './PlaceCodeScannerState';

export class PlaceCodeScannerStore<T>
{
	// ------------------------ Dependencies ------------------------

	// ------------------------- Properties -------------------------

	private readonly SCANNER_LOAD_TIMEOUT: number = 5000;

	public readonly localizer: Localizer;
	public readonly client: Client;
	private readonly onHash: (hash: string, scan: QrCodeScan) => Promise<T | undefined>;
	private readonly onFinish: (result: T | undefined, scan?: QrCodeScan) => Promise<T | undefined>;
	private readonly onLoad?: () => void;
	private readonly onInvalidHash?: (hash: string, scan: QrCodeScan) => void;
	private readonly onUnknownUrl?: (url: string, scan: QrCodeScan) => void;
	private readonly onNoHash?: (scan: QrCodeScan) => void;
	private readonly onErrorWhileResolvingCodeCallback?: (scan: QrCodeScan, error?: Error, hash?: string) => void;
	scannerState: PlaceCodeScannerState;
	timeoutTimer: any;
	useFallback: boolean;
	lastScannedUrl: string | undefined;

	// ------------------------ Constructor -------------------------

	constructor(
		localizer: Localizer,
		client: Client,
		onHash: (hash: string, scan: QrCodeScan) => Promise<T | undefined>,
		onFinish: (result: T | undefined, scan?: QrCodeScan) => Promise<T | undefined>,
		onLoad?: () => void,
		onInvalidHash?: ((hash: string, scan: QrCodeScan) => void) | undefined,
		onUnknownUrl?: (url: string, scan: QrCodeScan) => void,
		onNoHash?: (scan: QrCodeScan) => void,
		onErrorWhileResolvingCode?: (scan: QrCodeScan, error?: Error, hash?: string) => void,
	)
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});

		this.localizer = localizer;
		this.client = client;
		this.onHash = onHash;
		this.onFinish = onFinish;
		this.onLoad = onLoad;
		this.onInvalidHash = onInvalidHash;
		this.onUnknownUrl = onUnknownUrl;
		this.onNoHash = onNoHash;
		this.onErrorWhileResolvingCodeCallback = onErrorWhileResolvingCode;
	}

	// ----------------------- Initialization -----------------------

	// -------------------------- Computed --------------------------

	get type(): QrReaderType
	{
		return this.useFallback || !this.canStreamVideo
			? 'photo'
			: 'stream';
	}

	get canStreamVideo(): boolean
	{
		return this.client.canStreamUserMedia;
	}

	// --------------------------- Stores ---------------------------

	// -------------------------- Actions ---------------------------

	setUseFallback(useFallback: boolean): void
	{
		this.useFallback = useFallback;
	}

	setLastScannedUrl(url: string | undefined): void
	{
		this.lastScannedUrl = url;
	}

	handleException(exception: Error): void
	{
		if (exception)
		{
			if (exception.name === 'NotSupportedError' || exception.name === 'NotFoundError')
			{
				this.setScannerState(PlaceCodeScannerState.notSupported);
				this.setUseFallback(true);
				console.error(exception);
			}
			else if (exception.name === 'NotAllowedError')
			{
				this.setScannerState(PlaceCodeScannerState.notAuthorized);
				this.setUseFallback(true);
			}
			else
			{
				this.setScannerState(PlaceCodeScannerState.error);
				this.setUseFallback(true);
				console.error(exception);
			}
		}
	}

	startTimeoutTimer(): void
	{
		this.stopTimeoutTimer();
		this.timeoutTimer = setTimeout(
			() =>
			{
				if (this.type === 'stream' &&
					this.scannerState === PlaceCodeScannerState.loading)
				{
					this.setScannerState(PlaceCodeScannerState.timedOut);
					this.setUseFallback(true);
				}
			},
			this.SCANNER_LOAD_TIMEOUT);
	}

	stopTimeoutTimer(): void
	{
		clearTimeout(this.timeoutTimer);
	}

	setScannerState(scannerState: PlaceCodeScannerState): void
	{
		if (this.scannerState !== scannerState)
		{
			this.scannerState = scannerState;
		}
	}

	onScannerInit(): void
	{
		this.setScannerState(PlaceCodeScannerState.loading);
		this.startTimeoutTimer();
	}

	onScannerLoad(): void
	{
		this.setScannerState(PlaceCodeScannerState.loaded);

		if (this.onLoad)
		{
			this.onLoad();
		}
	}

	reload(): void
	{
		this.setUseFallback(false);
		this.onScannerInit();
	}

	// ------------------------ Public logic ------------------------

	onScan(scan: QrCodeScan): Promise<T | undefined>
	{
		if (scan.code?.data)
		{
			if (scan.code.data !== this.lastScannedUrl)
			{
				const hash = getPlaceCodeHashFromQrCode(scan.code);
				this.setLastScannedUrl(hash);
				setTimeout(
					() => this.setLastScannedUrl(undefined),
					1,
				);
				if (hash)
				{
					return this
						.onHash(hash, scan)
						.then(result => this.onFinish(result))
						.catch(error => {
							if (error instanceof HashNotMatchedException)
							{
								this.onUnknownCode(scan);
							}
							else
							{
								this.onErrorWhileResolvingCode(scan, error, hash);
							}
							return Promise.resolve(undefined);
						});
				}
				else
				{
					this.onInvalidCode(scan);
					return Promise.resolve(undefined);
				}
			}
			else
			{
				return Promise.resolve(undefined);
			}
		}
		else
		{
			this.onNoCode(scan.imageData, scan.processingTime);
			return Promise.resolve(undefined);
		}
	}

	private onInvalidCode(scan: QrCodeScan): void
	{
		this.setScannerState(PlaceCodeScannerState.invalidQrCode);
		this.onInvalidHash?.(scan.code ? scan.code.data : '', scan);
	}

	onNoCode(imageData: ImageData, processingTime: number): void
	{
		this.setScannerState(PlaceCodeScannerState.noQrCode);
		this.onNoHash?.({
			imageData: imageData, processingTime: processingTime,
		});
	}

	onUnknownCode(scan: QrCodeScan): void
	{
		this.setScannerState(PlaceCodeScannerState.unknownQrCode);
		this.onUnknownUrl?.(scan.code ? scan.code.data : '', scan);
	}

	private onErrorWhileResolvingCode(scan: QrCodeScan, e?: Error, hash?: string): void
	{
		this.setScannerState(PlaceCodeScannerState.noQrCode);
		this.onErrorWhileResolvingCodeCallback?.(scan, e, hash);
	}

	// ----------------------- Private logic ------------------------
}
