import { Collapse, makeStyles, Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import Decimal from 'decimal.js';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { useTranslate } from '../../../../Bridge/Localization/useTranslate';
import { usePaymentPriceContext } from '../../../UI/payment/price/context/PaymentPriceContext';
import { useCurrentOrderService } from '../BusinessContext';
import { BasicShoppingCartConfiguration } from './Configuration/BasicShoppingCartConfiguration';
import { OrderLinesContainer } from './Configuration/OrderLinesContainer';
import { ShoppingCartConfiguration } from './Configuration/ShoppingCartConfiguration';
import { ShoppingCartStore } from './ShoppingCartStore';

const useStyles = makeStyles({
	shoppingCart: {
		overflowY: 'hidden',
		flexDirection: 'column',
		borderSpacing: 0,
		borderCollapse: 'collapse',
		width: '100%',
	},
	shoppingCartTotalRow: {
		flexGrow: 0,
		flexShrink: 0,
		borderTop: '1px solid #e0e0e0',
	},
});

export interface ShoppingCartProps
{
	store: ShoppingCartStore;
	style?: CSSProperties;
}

export const ShoppingCart: FC<ShoppingCartProps> =
	({
		store,
		style,
	}) =>
	{
		const currentOrderService = useCurrentOrderService(true);
		const translate = useTranslate();
		const {currency, deliveryFee, serviceFee, rawAdditiveTaxPerTaxGroupId} = usePaymentPriceContext();

		const classes = useStyles();

		const totalAdditiveTax = useMemo(() =>
		{
			return Array
				.from(rawAdditiveTaxPerTaxGroupId.values())
				.reduce(
					(subTotal, taxAmount) =>
						subTotal.add(taxAmount.toDecimalPlaces(currency.decimalPlaces)),
					new Decimal(0),
				);
		}, [currency.decimalPlaces, rawAdditiveTaxPerTaxGroupId]);

		const add = useObserver(() => store.add);
		const activeProductFeeAmounts = useObserver(() => currentOrderService.activeProductFeeAmounts);
		const configurations = useObserver(() => store.configurations);
		const isInStock = useObserver(() => currentOrderService.isInStock);
		const openProduct = useObserver(() => store.openProduct);
		const productCurrencyCode = useObserver(() => currentOrderService.business.productCurrencyCode);
		const quantity = useObserver(() => store.quantity);
		const writeAheadCartContents = useObserver(() => currentOrderService.writeAheadCartContents);

		return quantity === 0
			?
			<Typography
				color="textSecondary"
				style={{
					paddingTop: 8,
					paddingBottom: 8,
					paddingLeft: 16,
					paddingRight: 8,
				}}
			>
				{translate('Client-ShoppingCart-NoItems')}
			</Typography>
			:
			<>
				<div
					style={{
						flex: '0 1 auto',
						overflowX: 'hidden',
						overflowY: 'auto',
						marginBottom: -1,
					}}
				>
					<div
						className={classes.shoppingCart}
						style={style}
					>
						<OrderLinesContainer>
							<TransitionGroup>
								{
									configurations.map(
										configuration =>
											<Collapse
												key={configuration.id}
											>
												<ShoppingCartConfiguration
													configuration={configuration}
													quantity={writeAheadCartContents.get(configuration)!}
													editable
													outOfStock={!isInStock(configuration)}
													productCurrencyCode={productCurrencyCode}
													openProduct={openProduct}
													changeQuantity={add}
													leftMargin={16}
													rightMargin={8}
												/>
											</Collapse>
									)
								}
								{
									activeProductFeeAmounts.map(
										productFeeAmount =>
											<Collapse
												key={`${productFeeAmount.productFee.name}_${productFeeAmount.amount}`}
											>
												<BasicShoppingCartConfiguration
													price={productFeeAmount.amount}
													currencyCode={productCurrencyCode}
													labelText={productFeeAmount.productFee.name}
													leftMargin={16}
													rightMargin={8}
													showFreeWhenZero
												/>
											</Collapse>,
									)
								}
								{
									!deliveryFee.isZero() &&
									<Collapse>
										<BasicShoppingCartConfiguration
											price={deliveryFee}
											currencyCode={productCurrencyCode}
											labelText={translate('Delivery-Fee')}
											leftMargin={16}
											rightMargin={8}
											showFreeWhenZero
										/>
									</Collapse>
								}
								{
									!serviceFee.isZero() &&
									<Collapse>
										<BasicShoppingCartConfiguration
											price={serviceFee}
											currencyCode={currency.code}
											labelText={translate('Service-Fee')}
											leftMargin={16}
											rightMargin={8}
										/>
									</Collapse>
								}
								{
									!totalAdditiveTax.isZero() &&
									<Collapse>
										<BasicShoppingCartConfiguration
											price={totalAdditiveTax}
											currencyCode={currency.code}
											labelText={translate('Taxes')}
											leftMargin={16}
											rightMargin={8}
										/>
									</Collapse>
								}
							</TransitionGroup>
						</OrderLinesContainer>
					</div>
				</div>
			</>;
	};
