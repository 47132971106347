import { ErrorOutline } from '@material-ui/icons';
import * as React from 'react';
import { FC } from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	error: {
		color: theme.palette.error.main,
	},
	messageRoot: {
		alignItems: 'center',
		display: 'flex',
		marginTop: 100,
		maxWidth: 400,
		minWidth: 200,
		paddingLeft: 40,
		paddingRight: 40,
	},
	messageIcon: {
		fontSize: 68,
		marginRight: 10,
	},
	messageCentered: {
		textAlign: 'center',
	},
	scanner: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
}));

interface PlaceCodeScannerMessageProps
{
	message: string
	error?: boolean
}

export const PlaceCodeScannerMessage: FC<PlaceCodeScannerMessageProps> =
	(
		{
			message,
			error,
		},
	) =>
	{
		const classes = useStyles();
		if (error)
		{
			return <div
				className={classes.messageRoot}
			>
				<ErrorOutline
					classes={{
						root: `${classes.messageIcon} ${classes.error}`,
					}}
				/>
				<Typography
					classes={{
						root: classes.error,
					}}
					variant="body1"
				>
					{message}
				</Typography>
			</div>;
		}
		else
		{
			return <div
				className={`${classes.messageRoot} ${classes.messageCentered}`}
			>
				<Typography
					variant="body1"
				>
					{message}
				</Typography>
			</div>;
		}
	};