import { SerializationProfile } from '../../Util/Serialization/Serialization';
import { UserResponse } from '../WebSocket/UserResponse';
import { ExternalOrder, ExternalOrderProfile } from './ExternalOrder';

export class ExternalOrdersOnPlaceUserResponse extends UserResponse
{
	readonly externalOrders: ExternalOrder[];
}

export const ExternalOrdersOnPlaceUserResponseProfile = SerializationProfile.create(ExternalOrdersOnPlaceUserResponse)
	.profile('externalOrders', ExternalOrderProfile);