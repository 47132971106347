import makeStyles from '@material-ui/core/styles/makeStyles';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext, useMemo } from 'react';
import { OrderDescriptor } from '../../../../../../../Api/Order/OrderDescriptor';
import { PageDialog } from '../../../../../../UI/PageDialog';
import { EntranceContextRef } from '../../../../../Entrance/EntranceContext';
import { OrderInfo } from '../OrderInfo';
import { OrderInfoHeader } from '../OrderInfoHeader';
import { ShowOrderTimelineButton } from '../ShowOrderTimelineButton';

const useStyles = makeStyles(() => ({
    content: {
        display: 'flex',
        flexGrow: 1,
    },
    dialogContent: {
        display: 'flex',
    },
    orderInfo: {
        flexGrow: 1,
    },
}));

interface OrderInfoDialogProps
{
    onClose: () => void;
    onOpenOrderEvents: (orderId: number) => void;
    order?: OrderDescriptor;
}

export const OrderInfoDialog: FC<OrderInfoDialogProps> =
    (
        {
            onClose,
            onOpenOrderEvents,
            order,
        },
    ) =>
    {
        const {waiterService} = useContext(EntranceContextRef);

        const classes = useStyles();

        const isRoot = useObserver(() => waiterService.user?.isRoot);

		const header = useMemo(() =>
		{
			if (order !== undefined)
				return <OrderInfoHeader order={order} />;
		}, [order]);

        const actions = useMemo(() =>
        {
            if (order !== undefined && isRoot)
                return <ShowOrderTimelineButton
                    onOpenOrderEvents={onOpenOrderEvents}
                    order={order}
                />;
            else
                return undefined;
        }, [isRoot, onOpenOrderEvents, order]);

        return <PageDialog
            classes={{
                content: classes.content,
                DialogContent: classes.dialogContent,
            }}
            contentPaddingBottom={0}
            contentPaddingLeft={0}
            contentPaddingRight={0}
            contentPaddingTop={0}
            fullWidth
            maxWidth="sm"
            onClose={onClose}
            open={order !== undefined}
            title={header}
            dialogActions={actions}
            id="order-handler-order-info-dialog"
        >
            {
                order !== undefined &&
                <OrderInfo
                    className={classes.orderInfo}
                    order={order}
                />
            }
        </PageDialog>;
    };