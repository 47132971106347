import * as React from 'react';
import { FC } from 'react';
import { useApiReachability } from '../../Util/Hooks/useApiReachability';

interface ApiReachabilitySuspenseProps
{
	showWhenUnreachable: React.ReactElement
}

export const ApiReachabilitySuspense: FC<ApiReachabilitySuspenseProps> =
	(
		{
			showWhenUnreachable,
			children,
		},
	) =>
	{
		const reachability = useApiReachability();
		return reachability === 'connected'
			?
			<>{children}</>
			:
			showWhenUnreachable;
	};