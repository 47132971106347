import { useMemo } from 'react';
import { usePaymentIssuerContext } from '../../../issuer/context/PaymentIssuerContext';
import { usePaymentMethodContext } from '../../context/PaymentMethodContext';

export function useSelectedPaymentMethodDescription(): string | undefined
{
	const {selectedPaymentMethod} = usePaymentMethodContext();
	const {selectedPaymentIssuer} = usePaymentIssuerContext();

	return useMemo(() =>
	{
		if (selectedPaymentMethod === undefined)
		{
			return undefined;
		}
		else
		{
			if (selectedPaymentIssuer === undefined)
				return selectedPaymentMethod.name;
			else
				return `${selectedPaymentMethod.name} - ${selectedPaymentIssuer.name}`;
		}
	}, [selectedPaymentIssuer, selectedPaymentMethod]);
}