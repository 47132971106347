import { Exception } from './Exception';

export class IllegalStateException extends Exception
{
    // ------------------------- Properties -------------------------

    readonly name = 'IllegalStateException';

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
