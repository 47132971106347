import { observer } from 'mobx-react';
import * as React from 'react';
import { CSSProperties } from 'react';
import { BrandingService } from '../../Service/BrandingInformation/BrandingService';
import { Page } from './Page';

export interface PageProps
{
    brandingService: BrandingService;
    className?: string;
    style?: CSSProperties;
}

@observer
export default class BrandedPage extends React.Component<PageProps>
{
    render(): React.ReactNode
    {
        const brandingService = this.props.brandingService;
        return <Page
            backgroundImageUrl={brandingService.backgroundImageUrl}
            backgroundImageBlur={brandingService.backgroundImageBlur}
            backgroundOverlayColor={brandingService.backgroundColor.css}
            backgroundColor={brandingService.averageColorOfBackground !== undefined
                ?
                brandingService.averageColorOfBackground.css
                :
                undefined}
            className={this.props.className}
            style={this.props.style}
        >
            {this.props.children}
        </Page>;
    }
}
