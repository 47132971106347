import { makeStyles } from '@material-ui/core';
import { SnackbarProvider, useSnackbar } from 'notistack';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { useRootContext } from '../../../RootContext';
import { Notification } from '../Notification';
import { WebNotification } from '../WebNotification';
import { NotificationSnackbarDismissAction } from './NotificationSnackbarDismissAction';

const useStyles = makeStyles(
	() =>
		({
			containerAnchorOriginBottomCenter:
				{
					// Required to fix a bug in notistack
					alignSelf: 'center',
					pointerEvents: 'inherit', // notistack adds a pointer-events: none - fails on Cypress snackbar.spec.js
				},
		}));

/**
 * Provides snackbar functionality.
 */
export const NotificationSnackbarProvider: FC =
	(
		{
			children,
		},
	) =>
	{
		const classes = useStyles();

		const {notification} = useRootContext(true);

		const onDismissSnackbar = (key?) => notification.onDismissSnackbar(key);

		return <SnackbarProvider
			action={
				key => NotificationSnackbarDismissAction({
					key: key,
					onDismiss: onDismissSnackbar,
				})
			}
			anchorOrigin={{
				horizontal: 'center',
				vertical: 'bottom',
			}}
			autoHideDuration={3000}
			classes={{
				containerAnchorOriginBottomCenter: classes.containerAnchorOriginBottomCenter,
			}}
			ClickAwayListenerProps={{
				onClickAway: event =>
				{
					event.preventDefault();
					onDismissSnackbar();
				},
			}}
			disableWindowBlurListener
			maxSnack={1}
			preventDuplicate
		>
			<NotificationSnackbarProviderInner notification={notification}>
				{children}
			</NotificationSnackbarProviderInner>
		</SnackbarProvider>;
	};

interface NotificationSnackbarProviderInnerProps
{
	notification: Notification;
}

/**
 * Component private to NotificationSnackbarProvider; is split off into a
 * seperate component because we need to use React Hooks inside {@link SnackbarProvider}.
 */
const NotificationSnackbarProviderInner: FC<NotificationSnackbarProviderInnerProps> =
	(
		{
			children,
			notification,
		},
	) =>
	{
		const withSnackbarProps = useSnackbar();

		useEffect(
			() => (notification as WebNotification).initialize(withSnackbarProps),
			[notification, withSnackbarProps]
		);

		return <>
			{children}
		</>;
	};
