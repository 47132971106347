import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { CSSProperties, FC, useEffect, useMemo } from 'react';
import { getFileUrl } from '../../../Util/Api/Resources/getFileUrl';
import { ImageCompression } from './ImageCompression';
import { useIsImageLoading } from '../../../Util/image/useIsImageLoading';
import { ImageFit } from './ImageFit';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		backgroundPosition: 'center 40%',
		backgroundRepeat: 'no-repeat',
		bottom: 0,
		left: 0,
		right: 0,
		position: 'absolute',
		top: 0,

		transition: theme.transitions.create('background-image', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
}));

interface ImageProps
{
	className?: string;
	compression: ImageCompression;
	height: number;
	fit: ImageFit;
	path: string;
	style?: CSSProperties;
	width: number;
	onStartLoading?: () => void;
	onEndLoading?: () => void;
}

export const Image: FC<ImageProps> =
	(
		{
			className,
			compression,
			height,
			fit,
			path,
			style,
			width,
			onStartLoading,
			onEndLoading,
		},
	) =>
	{
		const fullImageUrl = useMemo(
			() =>
			{
				if (path.startsWith("https://"))
					return path;
				else
					return getFileUrl(`/image/${width}/${height}/${fit}/${compression}${path}`)
			},
			[compression, fit, height, path, width]
		);

		const classes = useStyles();

		const combinedClassName = useMemo(
			() =>
				className === undefined
					? classes.root
					: `${className} ${classes.root}`,
			[className, classes.root],
		);

		const isImageLoading = useIsImageLoading(fullImageUrl);

		useEffect(
			() =>
			{
				if (isImageLoading)
				{
					if (onStartLoading)
					{
						onStartLoading();
					}
				}
				else
				{
					if (onEndLoading)
					{
						onEndLoading();
					}
				}
			},
			[isImageLoading, onEndLoading, onStartLoading]
		);

		return useMemo(
			() =>
				<span
					className={combinedClassName}
					style={{
						...style,
						backgroundImage: `url(${fullImageUrl})`,
						backgroundSize: fit === 'Contain' ? 'contain' : 'cover',
					}}
				/>,
			[combinedClassName, style, fullImageUrl, fit],
		);
	};
