import { Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Decimal from 'decimal.js';
import { useObserver } from 'mobx-react-lite';
import { FC, useMemo, useState } from 'react';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { ActionButton } from '../../../../UI/action-button/ActionButton';
import { NegativeActionButton } from '../../../../UI/action-button/type/NegativeActionButton';
import { ComoRewardDialogTab } from './ComoRewardDialogTab';
import { ComoRewardStore } from './ComoRewardStore';

interface ComoRewardDialogActionsProps
{
	activeTab: ComoRewardDialogTab;
	setActiveTab: (value: ComoRewardDialogTab) => void;
	store: ComoRewardStore;
}

export const ComoRewardDialogActions: FC<ComoRewardDialogActionsProps> =
	({
		activeTab,
		setActiveTab,
		store,
	}) =>
	{
		const translate = useTranslate();
		// use this state to keep track of the running promises, as otherwise the wrong button would become visible right before closing the dialog.
		const [isRedeemedOverride, setIsRedeemedOverride] = useState<boolean | undefined>(undefined);

		const redeemable = useObserver(() => store.reward.redeemable);
		const basketRequirements = useObserver(() => store.basketRequirements);
		const hasErrorByBasketRequirements = useObserver(() => store.hasErrorByBasketRequirements);
		const showValidation = useObserver(() => store.showValidation);

		const label = useMemo(() =>
		{
			if (store.reward.points === undefined)
				return undefined;
			else
			{
				const points = new Decimal(store.reward.points)
					.mul(
						new Decimal(10)
							.pow(-store.comoRewardsStore.shoppingCartStore.currentOrderService.currency.decimalPlaces),
					)
					.toString();

				return `${points} ${translate('Client-Loyalty-Como-Points')}`;
			}
		}, [store.comoRewardsStore.shoppingCartStore.currentOrderService.currency.decimalPlaces, store.reward.points, translate]);

		const isRedeemed = useObserver(() => store.isRedeemed);
		const pointBalance = useObserver(() => store.comoRewardsStore.membership.points);

		const canNavigateToItemsSelection = useMemo(
			() =>
				activeTab === 'information' && store.hasItemsSelection,
			[activeTab, store.hasItemsSelection],
		);

		const hasError = useMemo(
			() =>
			{
				if (activeTab === 'itemsSelection')
				{
					return Array
						.from(hasErrorByBasketRequirements.values())
						.some(
							value =>
								value === true
						);
				}
				else
				{
					return false;
				}
			},
			[activeTab, hasErrorByBasketRequirements]
		)

		return <>
			{
				store.reward.points !== undefined &&
				<Typography
					variant="subtitle1"
					color="textPrimary"
					style={{
						textAlign: 'left',
						marginRight: 'auto',
					}}
				>
					{
						label
					}
				</Typography>
			}
			{
				(isRedeemedOverride ?? isRedeemed)
					? <NegativeActionButton
						id="como-reward-dialog-remove-button"
						text={translate('Generic-Remove(NotDelete)')}
						onClick={() =>
						{
							setIsRedeemedOverride(true);
							return store
								.remove()
								.catch((e) =>
								{
									setIsRedeemedOverride(undefined);
									throw e;
								});
						}}
						size="medium"
						style={{
							alignSelf: 'stretch',
							margin: 0,
						}}
						icon={CloseIcon}
					/>
					: <ActionButton
						id="como-reward-dialog-add-button"
						text={
							store.reward.points === undefined
								? canNavigateToItemsSelection
									? `${translate('Client-Order-Add[ItemToOrder]')}...`
									: translate('Client-Order-Add[ItemToOrder]')
								: (store.reward.points > pointBalance
									? translate('Client-Loyalty-Como-Not-Enough-Points')
									: translate('Client-Loyalty-Como-Buy-With-Points'))
						}
						disabled={store.reward.points > pointBalance || (showValidation && hasError)}
						error={showValidation && hasError}
						onClick={() =>
						{
							if (!redeemable && basketRequirements.length > 0 && activeTab === 'information')
							{
								setActiveTab('itemsSelection');
							}
							else
							{
								setIsRedeemedOverride(false);
								return store
									.redeem()
									.then(
										() =>
											store.setTabIndex(0),
									)
									.catch((e) =>
									{
										setIsRedeemedOverride(undefined);
										throw e;
									});
							}
						}}
						size="medium"
						style={{
							alignSelf: 'stretch',
							margin: 0,
						}}
						icon={ShoppingCartIcon}
					/>
			}
		</>;
	};