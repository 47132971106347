import { Color } from '../../../../Api/Other/Color';

export function getMediaButtonImageBackgroundColor(
	image: string | undefined,
	isImageLoading: boolean,
	imageIsTextContrastColorDark: boolean,
	backgroundColor: Color
)
{
	if (image)
	{
		if (isImageLoading)
		{
			if (imageIsTextContrastColorDark)
			{
				return 'rgba(255, 255, 255, 0.5)';
			}
			else
			{
				return 'rgba(0, 0, 0, 0.5)';
			}
		}
		else
		{
			return backgroundColor.css;
		}
	}
	else
	{
		return backgroundColor.css;
	}
}