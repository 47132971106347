import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { Announcement } from '../../../../../../Api/Business/Announcement';
import { OrderBuilderSegment } from '../../OrderBuilderSegment';
import { RequestUserAcknowledgmentCard } from './RequestUserAcknowledgmentCard';

interface RequiredAnnouncementOrderBuilderSegmentProps
{
	acknowledged: boolean;
	announcement: Announcement;
	setAcknowledged: (acknowledged: boolean, remember: boolean) => void;
	showValidation: boolean;
}

export const RequiredAnnouncementOrderBuilderSegment: FC<RequiredAnnouncementOrderBuilderSegmentProps> =
	(
		{
			acknowledged,
			announcement,
			setAcknowledged,
			showValidation,
		},
	) =>
	{
		const label = useObserver(() => announcement.content);

		return <OrderBuilderSegment>
			<RequestUserAcknowledgmentCard
				allowInteraction={!acknowledged}
				label={label}
				setValue={setAcknowledged}
				showValidation={showValidation}
				value={acknowledged}
			/>
		</OrderBuilderSegment>;
	};