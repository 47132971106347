import { createContext, Dispatch, SetStateAction, useContext } from 'react';

interface SearchContext
{
	isSearching: boolean;
	setIsSearching: Dispatch<SetStateAction<boolean>>;
	setQuery: Dispatch<SetStateAction<string | undefined>>;
	query?: string;
}

export const SearchContextRef = createContext<SearchContext>({} as SearchContext);
export const SearchContextProvider = SearchContextRef.Provider;
export const useSearchContext = () => useContext(SearchContextRef);