import { useObserver } from 'mobx-react-lite';
import { useMemo } from 'react';
import { TimeScheduleSpecification } from '../../../../Api/Util/time/TimeScheduleSpecification';
import { nonNullish } from '../../../../Util/nonNullish';
import { useCurrentPlaceService } from '../../../current-place-service/CurrentPlaceService';
import { useCurrentOrderService } from '../BusinessContext';

/**
 * Takes the following into accounts:
 *
 * <ul>
 * 	<li>Allowed pre-ordering times set on current place</li>
 * 	<li>Orderability time schedules set on current products</li>
 * </ul>
 *
 * Explicitly not taking into account:
 *
 * <ul>
 * 	<li>Minimum ordering time set on current place</li>
 * 	<li>Maximum preparation time set on current products</li>
 * 	<li>Ordering time restrictions due to order restrictions (handled in API))</li>
 * 	<li>Orderability time schedules set on current menu cards (deprecated))</li>
 * 	<li>Orderability time schedules set on current product categories (deprecated)</li>
 * </ul>
 */
export function useAllowedOrderingTimeScheduleWithoutMinimum(): TimeScheduleSpecification
{
	const currentOrderService = useCurrentOrderService(true);
	const currentPlaceService = useCurrentPlaceService();

	const configurations = useObserver(() => currentOrderService.configurations);
	const orderInFutureAllowedDates = useObserver(() => currentPlaceService.place.orderInFutureAllowedDates);

	const productOrderabilityTimeSchedules = useMemo<TimeScheduleSpecification[]>(
		() =>
			[
				...new Set(
					configurations
						.slice()
						.map(configuration => configuration.product.orderableAt),
				),
			],
		[configurations],
	);

	return useMemo<TimeScheduleSpecification>(
		() =>
			({
				type: 'CONJUNCTION',
				terms: [
					orderInFutureAllowedDates,
					...productOrderabilityTimeSchedules,
				]
					.filter(nonNullish),
			}),
		[orderInFutureAllowedDates, productOrderabilityTimeSchedules],
	);
}
