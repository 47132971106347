import { Box, Typography } from '@material-ui/core';
import * as React from 'react';
import { FC } from 'react';
import { useTranslate } from '../../../Bridge/Localization/useTranslate';
import { useRootContext } from '../../../RootContext';
import { DialogVisibilityContextProvider, useDialogVisibilityContext } from '../../UI/dialog/context/visibility/DialogVisibilityContext';
import { PageDialog } from '../../UI/PageDialog';
import { QrCodeViewer } from '../../UI/qr_code_viewer/QrCodeViewer';
import { AgeVerificationVoucherStore } from './AgeVerificationVoucherStore';

interface AgeVerificationVoucherProps
{
	store: AgeVerificationVoucherStore;
}

export const AgeVerificationVoucher: FC<AgeVerificationVoucherProps> =
	() =>
	{
		const {navigator} = useRootContext(true);

		return <DialogVisibilityContextProvider
			onExited={
				() =>
					navigator.popScreen()
			}
		>
			<Inner />
		</DialogVisibilityContextProvider>;
	};

const Inner: FC =
	() =>
	{
		const {close, isOpen, onExited} = useDialogVisibilityContext();
		const translate = useTranslate();

		return <PageDialog
			fullWidth
			maxWidth="sm"
			onClose={close}
			onExited={onExited}
			open={isOpen}
			title={translate('AgeVerification-Voucher-Scan')}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<QrCodeViewer
					value={window.location.href}
				/>
				<Box
					marginTop={3}
				>
					<Typography
						variant="body1"
					>
						{translate('AgeVerification-Voucher-DescriptionInScreen')}
					</Typography>
				</Box>
			</Box>
		</PageDialog>
	};
