// @ts-nocheck
/* eslint-disable */
import { useObserver } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Event } from '../../../Api/WebSocket/Event';
import { useRootContext } from '../../../RootContext';
import { QuerySubscription, WebSocketQuery } from './WebSocketService';

//TODO: Should default to the Event Type instead of any => check usages afterwards
export function useWebSocketQuery<T extends Event = any>(
	query: WebSocketQuery | undefined,
	type: string,
	handler: (event: T) => void,
)
{
	const {
		webSocketService,
	} = useRootContext(true);
	const [querySubscription, setQuerySubscription] = useState<QuerySubscription>();

	const [queryAsString, setQueryAsString] = useState<string>();
	const [stableQuery, setStableQuery] = useState<WebSocketQuery>();
	useEffect(
		() => {
			if (queryAsString !== query?.asString)
			{
				setQueryAsString(query?.asString);
				setStableQuery(query);
			}
		},
		[query, queryAsString]
	)

	useEffect(
		() => {
			if (stableQuery !== undefined)
			{
				const querySubscription = webSocketService.startQuery(stableQuery, {
					type: type,
					handler: event => handler(event),
				});
				setQuerySubscription(querySubscription);
				return () => querySubscription.dispose()
			}
		},
		[stableQuery, type, webSocketService] // omit handler because it will indefinitely trigger re-rendering
	);

	const querySubscriptionIsOpen = useObserver(() => querySubscription?.isOpen === true);
	return querySubscriptionIsOpen;
}