import axios from 'axios';
import { SerializationProfile } from '../../../lib/Serialization/Serialization';
import { getApiUrl } from '../../Util/Api/Resources/getApiUrl';

export abstract class Http
{
    // ------------------------- Properties -------------------------

    /**
     * After this timeout, an exception will be thrown.
     */
    public static TIMEOUT_IN_MILLISECONDS: number = 60 * 1000;

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    /**
     *
     * @param resource A relative path without the API's base URL, with leading slash. For
     * example: for a resource with full url 'https://your.app.com/api/your/resource', this
     * parameter would be '/your/resource'
     * @param params
     */
    public fetchAny(resource: string, params: any): Promise<any>
    {
        return axios({
            method: 'get',
            params: this.encodeUrlParams(params),
            url: getApiUrl(resource),
            timeout: Http.TIMEOUT_IN_MILLISECONDS,
        })
            .then(
                response =>
                    Promise.resolve(response.data))
            .catch(
                reason =>
                    Promise.reject(reason.response ? reason.response.status : reason));
    }

    /**
     *
     * @param resource A relative path without the API's base URL, with leading slash. For
     * example: for a resource with full url 'https://your.app.com/api/your/resource', this
     * parameter would be '/your/resource'
     * @param params
     * @param profile
     */
    public fetch<T>(resource: string, params: any, profile: SerializationProfile<T>): Promise<T>
    {
        return axios({
            method: 'get',
            params: this.encodeUrlParams(params),
            url: getApiUrl(resource),
            timeout: Http.TIMEOUT_IN_MILLISECONDS,
        })
            .then(
                response =>
                    Promise.resolve(profile.deserializeSingular(response.data)))
            .catch(
                reason =>
                    Promise.reject(reason.response.status));
    }

    /**
     *
     * @param resource A relative path without the API's base URL, with leading slash. For
     * example: for a resource with full url 'https://your.app.com/api/your/resource', this
     * parameter would be '/your/resource'
     * @param params
     * @param profile
     */
    public fetchList<T>(resource: string, params: any, profile: SerializationProfile<T>): Promise<T[]>
    {
        return axios({
            method: 'get',
            params: this.encodeUrlParams(params),
            url: getApiUrl(resource),
            timeout: Http.TIMEOUT_IN_MILLISECONDS,
        })
            .then(
                response =>
                    Promise.resolve(profile.deserializeArray(response.data)))
            .catch(
                reason =>
                    Promise.reject(reason.response.status));
    }

    /**
     *
     * @param resource A relative path without the API's base URL, with leading slash. For
     * example: for a resource with full url 'https://your.app.com/api/your/resource', this
     * parameter would be '/your/resource'
     * @param params
     */
    public postAny(resource: string, params: any = {}): Promise<any>
    {
        return axios({
            method: 'post',
            params: this.encodeUrlParams(params),
            url: getApiUrl(resource),
            timeout: Http.TIMEOUT_IN_MILLISECONDS,
        })
            .then(
                response =>
                    Promise.resolve(response.data))
            .catch(
                reason =>
                    Promise.reject(reason.response.status));
    }

    /**
     *
     * @param resource A relative path without the API's base URL, with leading slash. For
     * example: for a resource with full url 'https://your.app.com/api/your/resource', this
     * parameter would be '/your/resource'
     * @param params
     * @param profile
     */
    public post<T>(resource: string, params: any, profile: SerializationProfile<T>): Promise<T>
    {
        return axios({
            method: 'post',
            params: this.encodeUrlParams(params),
            url: getApiUrl(resource),
            timeout: Http.TIMEOUT_IN_MILLISECONDS,
        })
            .then(
                response =>
                    Promise.resolve(profile.deserializeSingular(response.data)))
            .catch(
                reason =>
                    Promise.reject(reason.response.status));
    }

    /**
     *
     * @param resource A relative path without the API's base URL, with leading slash. For
     * example: for a resource with full url 'https://your.app.com/api/your/resource', this
     * parameter would be '/your/resource'
     * @param params
     * @param profile
     */
    public postAndFetchList<T>(resource: string, params: any, profile: SerializationProfile<T>): Promise<T[]>
    {
        return axios({
            method: 'post',
            params: this.encodeUrlParams(params),
            url: getApiUrl(resource),
            timeout: Http.TIMEOUT_IN_MILLISECONDS,
        })
            .then(response => Promise.resolve(profile.deserializeArray(response.data)));
    }

    // ----------------------- Private logic ------------------------

    protected abstract encodeUrlParams(params: any): URLSearchParams | any[];
}
