/**
 * <p>Taken from StackOverflow answer https://stackoverflow.com/a/175787/2225070, which states:</p>
 *
 * <p><b>2nd October 2020</b>: note that many bare-bones approaches are fraught with subtle bugs
 * (eg. whitespace, implicit partial parsing, radix, coercion of arrays etc.) that many
 * of the answers here fail to take into account. The following implementation might work
 * for you, but note that it does not cater for number separators other than the decimal point ".":</p>
 */
export function isNumeric(str: string)
{
	return !isNaN(str as any) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
		&& !isNaN(parseFloat(str))  // ...and ensure strings of whitespace fail
}