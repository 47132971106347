import { Box, Collapse, Typography } from '@material-ui/core';
import * as React from 'react';
import { FC, useCallback, useMemo, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { useTranslate } from '../../../../../../../../Bridge/Localization/useTranslate';
import { ActionButton } from '../../../../../../../UI/action-button/ActionButton';
import { InitializedCartLine } from '../../model/InitializedCartLine';
import { PeerShoppingCartAddLinesToOrderButton } from './add_lines_to_order_button/PeerShoppingCartAddLinesToOrderButton';
import { PeerShoppingCartLine } from './line/PeerShoppingCartLine';

interface PeerShoppingCartLinesProps
{
	lines: InitializedCartLine[];
}

export const PeerShoppingCartLines: FC<PeerShoppingCartLinesProps> =
	({
		lines,
	}) =>
	{
		const translate = useTranslate();
		const [selectedLineIds, setSelectedLineIds] =
			useState(
				() =>
					new Set<number>()
			);
		const selectLine =
			useCallback(
				(line: InitializedCartLine) =>
					setSelectedLineIds(
						currentSelectedLineIds =>
							new Set([
								...Array.from(currentSelectedLineIds),
								line.id,
							])
					),
				[]
			);
		const deselectLine =
			useCallback(
				(line: InitializedCartLine) =>
					setSelectedLineIds(
						currentSelectedLineIds =>
							new Set([
								...Array.from(currentSelectedLineIds)
									.filter(
										selectedLineId =>
											line.id !== selectedLineId
									),
							])
					),
				[]
			);
		const selectedLines =
			useMemo(
				() =>
					lines.filter(
						line =>
							selectedLineIds.has(line.id)
					),
				[
					lines,
					selectedLineIds,
				]
			);
		const selectAll =
			useCallback(
				() =>
					setSelectedLineIds(
						new Set(
							lines.map(
								line =>
									line.id
							)
						)
					),
				[lines]
			);
		const deselectAll =
			useCallback(
				() =>
					setSelectedLineIds(
						new Set()
					),
				[]
			);

		return <TransitionGroup>
			<Collapse>
				<Box
					padding={2}
				>
					<Typography
						variant="body1"
						color="textSecondary"
					>
						{translate('Client-PeerShoppingCart-Justification')}
					</Typography>
				</Box>
			</Collapse>
			<Collapse>
				<Box
					paddingX={2}
					paddingBottom={1}
				>
					{
						lines.length === selectedLines.length
							? <ActionButton
								text={
									<Box
										sx={{
											width: '100%',
										}}
									>
										Alles deselecteren
									</Box>
								}
								onClick={deselectAll}
							/>
							: <ActionButton
								text={
									<Box
										sx={{
											width: '100%',
										}}
									>
										Alles selecteren
									</Box>
								}
								onClick={selectAll}
							/>
					}
				</Box>
			</Collapse>
			{
				lines.map(
					line =>
						<Collapse
							key={line.id}
						>
							<PeerShoppingCartLine
								line={line}
								selected={selectedLineIds.has(line.id)}
								onSelect={selectLine}
								onDeselect={deselectLine}
							/>
						</Collapse>
				)
			}
			{
				selectedLines.length > 0 &&
				<Collapse>
					<Box
						padding={2}
					>
						<PeerShoppingCartAddLinesToOrderButton
							selectedLines={selectedLines}
						/>
					</Box>
				</Collapse>
			}
		</TransitionGroup>;
	};