import { Box, ButtonBase, Card, makeStyles, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';
import { useTranslate } from '../../../../Bridge/Localization/useTranslate';
import { GreyButtonBackgroundColor, GreyButtonTextColor } from '../../../../Util/Theme';

const useStyles =
	makeStyles(
		() => ({
			button: {
				color: GreyButtonTextColor,
				borderBottomLeftRadius: 15,
				borderBottomRightRadius: 15,
			},
			nonCardButton: {
				backgroundColor: GreyButtonBackgroundColor,
			},
			label: {
				fontWeight: 500,
			},
		})
	);

interface KioskDrawerBackButtonProps
{
	onClick: () => void;
	card?: boolean;
}

export const KioskDrawerBackButton: FC<KioskDrawerBackButtonProps> =
	(
		{
			onClick,
			card,
		}
	) =>
	{
		const classes = useStyles();
		const translate = useTranslate();
		const button =
			<ButtonBase
				onClick={onClick}
				className={
					clsx(
						classes.button,
						!card && classes.nonCardButton,
					)
				}
			>
				<Box
					paddingY={2}
					paddingX={3}
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
					}}
				>
					<Box>
						<ArrowBack />
					</Box>
					<Box
						paddingLeft={1}
					>
						<Typography
							variant="body1"
							className={classes.label}
						>
							{translate('Generic-Back')}
						</Typography>
					</Box>
				</Box>
			</ButtonBase>;

		if (card)
		{
			return <Card
				className={classes.button}
			>
				{button}
			</Card>;
		}
		else
		{
			return button;
		}
	};
