import Decimal from 'decimal.js';

export function divideExact(
	value: Decimal,
	numberOfDivisions: number,
	decimalPlaces: number,
): Decimal[]
{
	const increment = new Decimal(1).mul(new Decimal(10).pow(-decimalPlaces));

	if (numberOfDivisions === 1)
	{
		return [value];
	}
	else
	{
		const quotient = value.div(numberOfDivisions).toDecimalPlaces(decimalPlaces, Decimal.ROUND_FLOOR);
		const remainder = value.sub(quotient.mul(numberOfDivisions));

		if (remainder.isZero())
		{
			return Array.from({length: numberOfDivisions}, () => quotient);
		}
		else
		{
			const numberOfIncrementsNeeded = remainder.div(increment).ceil().toNumber();

			return Array.from({length: numberOfDivisions}, (_, i) =>
			{
				return (i < numberOfIncrementsNeeded)
					? quotient.add(increment)
					: quotient;
			});
		}
	}
}