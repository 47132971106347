import { Slide, Theme, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from '../../../../Bridge/Localization/useTranslation';
import { UIColors } from '../../../../Constants/UIColors';
import { NegativeActionButton } from '../../../UI/action-button/type/NegativeActionButton';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		backgroundColor: UIColors.greyLight,
		borderRadius: theme.shape.borderRadius,
		bottom: 80,
		display: 'flex',
		justifyContent: 'space-between',
		padding: theme.spacing(2),
		position: 'absolute',

		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},

		[theme.breakpoints.up('sm')]: {
			alignItems: 'center',
			borderRadius: theme.shape.borderRadius,
		},
	},
	text: {
		[theme.breakpoints.down('xs')]: {
			marginBottom: theme.spacing(2),
		},
		[theme.breakpoints.up('sm')]: {
			marginRight: theme.spacing(2),
		},
	},
}));

interface ManagerPlaceCodeInfoAssignedCodeContentProps
{
	hash: string;
	onUnassignPlaceCode: () => void
	placeName: string;
	show: boolean;
}

export const ManagerPlaceCodeInfoAssignedCodeContent: FC<ManagerPlaceCodeInfoAssignedCodeContentProps> =
	(
		{
			hash,
			onUnassignPlaceCode,
			placeName,
			show,
		},
	) =>
	{
		const classes = useStyles();

		return <Slide
			direction="up"
			in={show}
			unmountOnExit
		>
			<div
				className={classes.root}
			>
				<Typography
					align="center"
					classes={{
						root: classes.text,
					}}
					variant="h6"
				>
					{useTranslation('Place', hash)}&nbsp;{placeName}
				</Typography>
				<Typography
					classes={{
						root: classes.text,
					}}
				>
					{useTranslation('Place-Code-Scanned', hash)}
				</Typography>
				<NegativeActionButton
					icon={RemoveCircleOutlineIcon}
					onClick={onUnassignPlaceCode}
					text={useTranslation('Place-Code-Unassign')}
				/>
			</div>
		</Slide>;
	};
