import { Client } from '../../Bridge/Client/Client';
import { WebClient } from '../../Bridge/Client/WebClient';

/**
 * Opens or downloads a file:
 * <ul>
 *     <li>Open the file in the client's PDF viewer on Android</li>
 *     <li>Open the file in a new tab on iOS</li>
 *     <li>Download the file anywhere else</li>
 * </ul>
 *
 * @param blob binary blob of the file
 * @param filename name of the file
 * @param client used {@link Client}
 * @param asPopup whether to open as a popup (Android only)
 */
export function openFile(
	blob: Blob,
	filename: string,
	client: Client,
	asPopup?: boolean,
): void
{
	const blobUrl = (window.URL || window.webkitURL).createObjectURL(blob);

	client.operatingSystem === 'Android OS'
		? window.open(blobUrl, asPopup ? '_blank' : '_parent')
		: downloadFile(client, blobUrl, filename);
}

/**
 * Downloads a file from an URL with an optional given file name.
 *
 * @param client used {@link WebClient}
 * @param fileURL URL to download from
 * @param fileName (optional) name the downloaded file should have
 */
function downloadFile(client: Client, fileURL: string, fileName?: string): void
{
	if ((client instanceof WebClient && client.browserName === 'ie') && !(window as any).ActiveXObject && document.execCommand)
	{
		const _window = window.open(fileURL, '_blank');

		if (_window)
		{
			_window.document.close();
			_window.document.execCommand('SaveAs', true, fileName || fileURL);
			_window.close();
		}
	}
	else
	{
		const save = document.createElement('a');
		const filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);

		save.href = fileURL;
		save.target = '_blank';
		save.download = fileName || filename;

		if (client.operatingSystem === 'iOS' && (!(client instanceof WebClient) || client.browserName !== 'chrome'))
		{
			document.location.href = save.href;
		}
		else
		{
			let event = new MouseEvent(
				'click',
				{
					view: window,
					bubbles: true,
					cancelable: false,
				},
			);

			save.dispatchEvent(event);

			// Revoke object URL after a delay
			setTimeout(() => (window.URL || window.webkitURL).revokeObjectURL(save.href), 1000);
		}
	}
}
