import { PaymentAction } from '@adyen/adyen-web/dist/types/types';
import Decimal from 'decimal.js';
import { makeAutoObservable } from 'mobx';
import { isCompletedOrderState } from '../../Util/Orders/isCompletedOrderState';
import { ISO8601ToDateTransformer, NumberToDateTransformer, NumberToDecimalTransformer, SerializationProfile } from '../../Util/Serialization/Serialization';
import { Business, BusinessProfile } from '../Business/Business';
import { OrderDestinationAddress, OrderDestinationAddressProfile } from '../Business/OrderDestinationAddress';
import { OrderDestinationType, Place, PlaceProfile } from '../Business/Place';
import { PlaceSession, PlaceSessionProfile } from '../Business/PlaceSession';
import { PaymentMethodId } from '../Payment/PaymentMethodId';
import { OrderLine, OrderLineProfile } from './OrderLine';

export type OrderProcessingState = 'scheduled' | 'processing' | 'processed' | 'failed' | 'partially_failed';

export type OrderState = 'scheduled' | 'ordered' | 'acknowledged' | 'handled' | 'voided' | 'prepared' | 'pickedUp' | 'delivered';

export type PaymentState = 'created' | 'negotiated' | 'failed' | 'unpaid' | 'paid';

export class Order
{
    // ------------------------- Properties -------------------------

    id: number;
    uuid: string;
    clientId: number;
    clientName: string | undefined;
    comment: string | undefined;
    clientPhoneNumber: string | undefined;
    public readonly business: Business;
    place: Place;
    placeSession: PlaceSession | undefined;
    state: OrderState;
    finalState: OrderState;
    numberOfPositiveClientFeedbacks: number;
    numberOfNegativeClientFeedbacks: number;
    waiterId: number;
    dateLastChanged: Date;
    dateScheduled: Date | undefined;
    dateOrdered: Date | undefined;
    dateAcknowledged: Date | undefined;
    dateHandled: Date | undefined;
    dateVoided: Date | undefined;
    scheduledFor: Date | undefined;
    destinationType: OrderDestinationType;
    address: OrderDestinationAddress | undefined;
    currencyCode: string;
    paymentGateway?: string;
    paymentMethod: PaymentMethodId;
    paymentMethodId?: string;
    paymentIssuerId: string;
    paymentState: PaymentState;
    paymentCurrencyCode: string;
    paymentPrice: Decimal | undefined;
    paymentAmountRemaining: Decimal | undefined;
    paymentFee: Decimal | undefined;
    paymentAuthorizationId: string;
    paymentId: string;
    paymentSessionId?: string;
    paymentDate: Date;
    paymentReference: string;
    paymentSignature: string;
    paymentUrl: string;
    paymentReturnUrl: string;
    paymentAction: PaymentAction | undefined
    tip?: Decimal;
    processingState: OrderProcessingState;
    orderLines: OrderLine[] | undefined;
    newCartId: number;
    pickupId: string | undefined;
    smsRemindersEnabled: boolean;
    public readonly embeddableHtmlConsentObtained: boolean;

    // ------------------------ Constructor -------------------------

    constructor()
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    get hasPayment(): boolean
    {
        return this.paymentState !== undefined && this.paymentMethod !== undefined && this.paymentMethod !== 'traditional';
    }

    get hasOpenPayment(): boolean
    {
        return this.hasPayment
            && this.state !== 'voided'
            && this.paymentMethod !== 'traditional'
            && this.paymentState === 'negotiated'
            && this.paymentCurrencyCode != null
            && this.paymentPrice != null;
    }

    get hasFee(): boolean
    {
        return this.paymentFee !== undefined
            && this.paymentFee.gt(0);
    }

    get hasTip(): boolean
    {
        return this.tip !== undefined;
    }

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------

    /*---------------------------------------------------------------*
     *                      Getters and setters                      *
     *---------------------------------------------------------------*/

    get dateCreated(): Date
    {
        return this.dateScheduled ?? this.dateOrdered!;
    }

    get isComplete(): boolean
    {
        return isCompletedOrderState(this.state);
    }
}

export const OrderProfile =
    SerializationProfile.create(Order)
        .profile('business', BusinessProfile)
        .profile('place', PlaceProfile)
        .profile('placeSession', PlaceSessionProfile)
        .transform('dateScheduled', ISO8601ToDateTransformer)
        .transform('dateLastChanged', NumberToDateTransformer)
        .transform('dateOrdered', NumberToDateTransformer)
        .transform('dateAcknowledged', NumberToDateTransformer)
        .transform('dateHandled', NumberToDateTransformer)
        .transform('dateVoided', NumberToDateTransformer)
        .transform('scheduledFor', ISO8601ToDateTransformer)
        .transform('paymentDate', NumberToDateTransformer)
        .transform('paymentPrice', NumberToDecimalTransformer)
        .transform('paymentAmountRemaining', NumberToDecimalTransformer)
        .transform('paymentFee', NumberToDecimalTransformer)
        .transform('tip', NumberToDecimalTransformer)
        .profile('orderLines', OrderLineProfile)
        .profile('address', OrderDestinationAddressProfile);
