export const UIColors =
{
    black: '#212121',                          // grey[900]
    greyDark: '#757575',                       // grey[600]
    grey: '#E0E0E0',                           // grey[300]
    greyLight: '#F5F5F5',                      // grey[100]
    info: '#64B5F6',                           // blue[300]
    infoHighlight: '#BBDEFB',                  // blue[100]
    negative: '#E57373',                       // red[300]
    negativeDark: '#E53935',                   // red[600]
    negativeHighlight: '#FFCDD2',              // red[100]
    negativeOverlay: 'rgba(229,115,115,0.26)', // red[300], alpha: 0.25
    positive: '#81C784',                       // green[300]
    positiveDark: '#43A047',                   // green[600]
    positiveHighlight: '#C8E6C9',              // green[100]
    positiveOverlay: 'rgba(129,199,132,0.25)', // green[300], alpha: 0.25
    selected: '#303F9F',                       // indigo[700]
    warning: '#FFB74D',                        // orange[300]
    warningDark: '#FB8C00',                    // orange[600]
    warningHighlight: '#FFE0B2',               // orange[100]
}
