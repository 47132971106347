import { FormControl, PropTypes, TextField } from '@material-ui/core';
import * as React from 'react';
import { ChangeEvent, FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useAllowedCharactersCheck } from '../Core/useAllowedCharactersCheck';
import { useMaxLengthCheck } from '../Core/useMaxLengthCheck';
import { useSetValidity } from '../Core/useSetValidity';

interface TextFormProps
{
	helperText?: string
	label?: ReactNode
	margin?: PropTypes.Margin
	marginBottom?: boolean
	marginTop?: boolean
	onErrorUpdate?: (isError: boolean) => void
	required?: boolean
	requiredLabel?: string
	setValue: (value: string) => void
	value: string

	/**
	 * An error to view only at the start, i.e. right up until the point where the first edit is made in the
	 * text form.
	 */
	startError?: string
	showValidation?: boolean
	maxLength?: number
	allowedCharacters?: string[]
}

export const TextForm: FC<TextFormProps> =
	(
		{
			helperText,
			label,
			margin,
			marginBottom,
			marginTop,
			onErrorUpdate,
			required,
			requiredLabel,
			setValue,
			value,
			startError,
			showValidation = true,
			maxLength,
			allowedCharacters,
		},
	) =>
	{
		const requiredError = useMemo(
			() => {
				if (required && ((value?.length ?? 0) === 0))
					return requiredLabel;
				else
					return undefined;
			},
			[required, requiredLabel, value],
		);
		const {
			maxLengthError,
			maxLengthHelperText,
		} = useMaxLengthCheck(value, maxLength, showValidation);
		const {
			allowedCharactersError,
			allowedCharactersHelperText,
		} = useAllowedCharactersCheck(value, allowedCharacters, showValidation);
		const error = useMemo(
			() => {
				const errors: (string | JSX.Element | JSX.Element[])[] = [];
				if (requiredError !== undefined)
					errors.push(requiredError);
				if (maxLengthError !== undefined)
					errors.push(maxLengthError);
				if (allowedCharactersError !== undefined)
					errors.push(allowedCharactersError);
				return errors;
			},
			[allowedCharactersError, maxLengthError, requiredError]
		);
		const isError = !useSetValidity(
			() => error.length === 0,
			[error.length]
		);
		const [isTouched, setIsTouched] = useState(false);
		const typeOfErrorToShow = useMemo(
			() => startError !== undefined && !isTouched
				? 'START_ERROR'
				: 'ERROR',
			[isTouched, startError]
		);

		const onChange = useCallback(
			(event: ChangeEvent<HTMLInputElement>) => {
				setIsTouched(true);
				setValue(event.target.value);
			},
			[setValue],
		);

		useEffect(() => onErrorUpdate?.(isError), [isError, onErrorUpdate]);

		const errorHelperText = useMemo(
			() => typeOfErrorToShow === 'START_ERROR'
				? startError !== undefined ? [<>{startError}</>] : []
				: ((showValidation && error !== undefined) ? error : []),
			[typeOfErrorToShow, startError, showValidation, error],
		);

		const combinedHelperText = useMemo(
			() => {
				const helperTexts: (string | JSX.Element | JSX.Element[])[] = [];
				if (helperText !== undefined)
					helperTexts.push(helperText);
				if (errorHelperText !== undefined)
					helperTexts.push(...errorHelperText);
				if (maxLengthHelperText !== undefined)
					helperTexts.push(maxLengthHelperText);
				if (allowedCharactersHelperText !== undefined)
					helperTexts.push(allowedCharactersHelperText);
				const fragments: JSX.Element[] = [];
				for (let i = 0; i < helperTexts.length; i++)
				{
					const helperText = helperTexts[i];
					if (typeof helperText === 'string')
						fragments.push(<>{helperText}</>);
					else if (Array.isArray(helperText))
					{
						fragments.push(...helperText);
					}
					else
					{
						fragments.push(helperText)
					}
					if (i < helperTexts.length - 1)
						fragments.push(<br />);
				}
				return <>
					{
						fragments
							.map((fragment, key) => <React.Fragment key={key}>
								{fragment}
							</React.Fragment>)
					}
				</>;
			},
			[allowedCharactersHelperText, errorHelperText, helperText, maxLengthHelperText],
		);

		const textFieldInErrorMode = useMemo(
			() => (typeOfErrorToShow === 'START_ERROR' && startError !== undefined)
				|| (typeOfErrorToShow === 'ERROR' && showValidation && isError),
			[isError, showValidation, startError, typeOfErrorToShow]
		);
		return <FormControl>
			<TextField
				error={textFieldInErrorMode}
				helperText={combinedHelperText}
				label={label}
				variant="outlined"
				margin={margin}
				onChange={onChange}
				required={required}
				style={{
					marginBottom: marginBottom ? 8 : 0,
					marginTop: marginTop ? 8 : 0,
				}}
				value={value}
			/>
		</FormControl>;
	};