import { Collapse, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { usePaymentIssuerContext } from '../../../../issuer/context/PaymentIssuerContext';
import { usePaymentMethodContext } from '../../../context/PaymentMethodContext';
import { adyenCheckoutPaymentMethodRequiresUserInput } from '../settings/adyenCheckoutPaymentMethodRequiresUserInput';
import { BeforehandPaymentMethodSelectBox } from './BeforehandPaymentMethodSelectBox';
import { PaymentMethodSelector } from './PaymentMethodSelector';

const useStyles = makeStyles({
	paymentMethod: {
		flex: '0 1 auto',
		overflowY: 'hidden',
		width: '100%',
	},
});

export const PaymentMethodSelection: FC =
	() =>
	{
		const {getPaymentMethodById, selectedPaymentMethod, selectPaymentMethod} = usePaymentMethodContext();
		const {paymentIssuers} = usePaymentIssuerContext();

		const classes = useStyles();

		const [selectedPaymentMethodIdOverride, setSelectedPaymentMethodIdOverride] = useState(selectedPaymentMethod?.id);

		useEffect(() =>
		{
			setSelectedPaymentMethodIdOverride(selectedPaymentMethod?.id);
		}, [selectedPaymentMethod]);

		const handleOverrideSelection = useCallback((paymentMethodId: string) =>
		{
			setSelectedPaymentMethodIdOverride(paymentMethodId);
			selectPaymentMethod(paymentMethodId);
		}, [selectPaymentMethod]);

		const minimized = useMemo(() =>
		{
			if (selectedPaymentMethodIdOverride === undefined)
			{
				return false;
			}
			else
			{
				const selectedPaymentMethodOverride = getPaymentMethodById(selectedPaymentMethodIdOverride);

				const requireIssuerConfiguration = selectedPaymentMethodOverride?.isIssuerRequired && paymentIssuers.length > 0;
				const requiresUserInput = adyenCheckoutPaymentMethodRequiresUserInput(selectedPaymentMethodOverride);

				return requireIssuerConfiguration || requiresUserInput;
			}
		}, [getPaymentMethodById, paymentIssuers.length, selectedPaymentMethodIdOverride]);

		return <>
			<Collapse in={minimized} unmountOnExit>
				<BeforehandPaymentMethodSelectBox
					onChange={handleOverrideSelection}
					value={selectedPaymentMethodIdOverride}
				/>
			</Collapse>
			<Collapse in={!minimized} unmountOnExit>
				<PaymentMethodSelector
					className={classes.paymentMethod}
					onChange={handleOverrideSelection}
					value={selectedPaymentMethodIdOverride}
				/>
			</Collapse>
		</>;
	};