import { ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FC, ReactNode } from 'react';
import { AnnouncementAction } from '../../../../Api/Business/AnnouncementAction';
import { IllegalArgumentException } from '../../../../Util/Exception/IllegalArgumentException';

const useStyles = makeStyles(({
    button: {
        width: '100%',
        textAlign: 'left',
    },
}));

export interface AnnouncementActionWrapperProps
{
    action: AnnouncementAction;
    children: ReactNode
}

export const AnnouncementActionWrapper: FC<AnnouncementActionWrapperProps> =
    (
        {
            action,
            children,
        }
    ) =>
    {
        const classes = useStyles();

        if (action.type === 'OpenURL')
        {
            return <ButtonBase
                href={action.url}
                target="_blank"
                rel="noopener noreferrer"
                focusRipple
                className={classes.button}
            >
                {children}
            </ButtonBase>;
        }
        else
        {
            throw new IllegalArgumentException(`Unknown action type "${action!.type}"`);
        }
    };
