import { BaseStore } from '@intentic/ts-foundation';
import { action, makeObservable, observable, override, runInAction } from 'mobx';
import { RoutingException } from '../../Util/Exception/RoutingException';
import { BoxedReaction } from '../../Util/Reaction/BoxedReaction';
import { WebClient } from '../Client/WebClient';
import { cancelFullScreen } from './cancelFullScreen';
import { BrowserHistoryEntryState } from './Model/BrowserHistoryEntryState';
import { Navigator } from './Navigator';
import { ScreenInstantiation } from './ScreenInstantiation';

export class WebNavigator extends Navigator
{
    // ------------------------ Dependencies ------------------------

    private readonly client: WebClient;
    public readonly scrollTo: (scrollTop: number) => void;

    busyTruncatingHistory: boolean;
    popHandlerBusy: boolean;
    counterActionState: 'idle' | 'in_progress';

    // ------------------------- Properties -------------------------

    static replaceBrowserUrlWithSpecifiedRoute: BoxedReaction = BoxedReaction.create();

    /**
     * Map of promises for each {@link ScreenInstantiation} on the screen stack that get resolved when the screen in
     * question is fully loaded. This map is an instance variable because the promises get created and subscribed to
     * by a user-triggered event, but get resolved by a browser-triggered event (popstate).
     */
    readonly popPromiseResolverByScreenStackIdx = observable
        .map<number | 'TRUNCATE_FUTURE', {resolve: (value?: boolean) => void, reject: (value?: any) => void, force: boolean}>();

    // ------------------------ Constructor -------------------------

    constructor(
        client: WebClient,
        scrollTo: (scrollTop: number) => void
    )
    {
        super();

        makeObservable(this, {
            popState: action.bound,
            pushScreen: override,
            popScreen: override,
        });

        this.busyTruncatingHistory = false;
        this.popHandlerBusy = false;
        this.counterActionState = 'idle';

        this.client = client;
        this.scrollTo = scrollTo;
    }

    // ----------------------- Initialization -----------------------

    public initialize(): void
    {
        super.initialize();
        /*
         * Register handler for all "forward" and "back" calls that come from the browser
         */
        window.onpopstate = event =>
        {
            if (event)
            {
                this.popState(event);
            }
        };

        WebNavigator.replaceBrowserUrlWithSpecifiedRoute.define(
            () =>
                this.currentScreenInstance,
            () =>
            {
                if (this.currentScreenInstance && this.currentScreenInstance.screen && this.currentScreenInstance.screen.toRoute)
                {
                    const newPartialRoute =
                        this.currentScreenInstance.screen.toRoute(this.currentScreenInstance.store);

                    const currentRoute = document.location.pathname;

                    /*
                     * @daniel what does this try to measure?
                     */
                    if (!currentRoute.endsWith(newPartialRoute))
                    {
                        runInAction(() => {
                            window.history.replaceState(
                                new BrowserHistoryEntryState(this.screenStack.currentIndex!),
                                '',
                                this.route,
                            );
                            // this.screenStack.push(this.currentScreenInstance);
                            // this.screenStackIdx += 1;
                        });
                    }
                }
            });
    }

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    /**
     * Handles all 'forward' and 'back' calls that come from browser
     * @param event The browser event
     */
    popState(event: PopStateEvent)
    {
        if (this.busyTruncatingHistory) {
            const transitionData = this.popPromiseResolverByScreenStackIdx.get('TRUNCATE_FUTURE')!;
            transitionData.resolve(true);
            return;
        }

        const currentScreenStackIdx = this.screenStack.currentIndex!;
        const newState: BrowserHistoryEntryState = event.state;

        if (this.popHandlerBusy) {
            const counterActionState = this.counterActionState;
            if (counterActionState === 'idle') {
                this.counterActionState = 'in_progress';
                window.history.go(1);
            } else if (counterActionState === 'in_progress') {
                this.counterActionState = 'idle';
            }
            return;
        }

        /*
         * Whether this event is a move downward (pop) in the screen stack (isPop = true), or upward (isPop = false),
         * with the root screen in the stack being the "lowest".
         */
        const isPop = newState === null
            || this.screenStack.currentIndex! > newState.screenStackIdx;

        const transitionData = this.popPromiseResolverByScreenStackIdx.get(currentScreenStackIdx);
        if (transitionData !== undefined) {
            this.popPromiseResolverByScreenStackIdx.delete(currentScreenStackIdx);
        }

        /*
         * See if there are screen transition callbacks to be performed for this transition
         */
        let doFirst = Promise.resolve(true);
        if (isPop && this.currentScreenInstance && (transitionData === undefined || !transitionData.force)) {
            doFirst = this.screenTransitionCallback(this.currentScreenInstance.screen.id);
        }

        this.popHandlerBusy = true;
        this.counterActionState = 'idle';

        doFirst
            .then(shouldContinue => {
                if (shouldContinue) {
                    newState && this.screenStack.moveTo(newState.screenStackIdx);
                }

                /*
                 * Promise resolving
                 */
                if (transitionData !== undefined)
                {
                    transitionData.resolve(shouldContinue);
                }

                if (!shouldContinue) {
                    window.history.go(1);
                    this.currentScreenInstance?.setUnmarkedForPop();
                }

                this.popHandlerBusy = false;
            });
    }

    // ------------------------ Public logic ------------------------

    /**
     * Pushing a {@link ScreenInstantiation} to the top of the stack. Note that in line with the nature of a stack,
     * this is always a new {@link ScreenInstantiation}
     */
    pushScreen(screenId: string, store: BaseStore): Promise<ScreenInstantiation>
    {
        /*
         * Handle scrolling
         */
        if (this.currentScreenInstance)
        {
            // store what the last scroll position of the previous screen was
            this.currentScreenInstance.scrollTop = this.client.scrollYOffset;

            // reset the scroll position for the new screen
            this.scrollTo(0);
        }

        const newScreenInstance = this.instantiate(screenId, store, this.currentScreenInstance);

        this.screenStack.truncateFutureAndPush(newScreenInstance);

        const route = newScreenInstance.screen.toRoute && newScreenInstance.screen.toRoute(newScreenInstance.store);
        if (!route)
        {
            throw new RoutingException(`Screen ${newScreenInstance.screen.id} must have a route`);
        }
        if (route)
        {
            window.history.pushState(new BrowserHistoryEntryState(this.screenStack.currentIndex!), '', route);
        }

        return Promise.resolve(newScreenInstance);
    }

    /**
     * @param force
     * @return Promise<boolean> Note that it in most situations, one should not subscribe to this promise, as this
     * promise will only exist/fulfill when the pop screen transition is initiated from the user interface (i.e. when
     * this method is called), and not from a device/browser's 'back'-button. If you want a reaction to the pop screen
     * event that will also happen in those remaining cases, you should register a callback with
     * {@link #addScreenTransitionCallback}.
     */
    popScreen(
        force: boolean = false,
        uuidOfScreenInstantiationToPop?: string,
    ): Promise<boolean>
    {
        const doRoute = true;

        if (this.currentScreenInstance && doRoute)
        {
            if (this.client.isMobileOrTablet()
                && this.currentScreenInstance.screen.isFullScreen)
            {
                if (this.currentScreenInstance.screen.isFullScreen)
                {
                    cancelFullScreen();
                }
            }

            const route = this.currentScreenInstance.screen.toRoute
                && this.currentScreenInstance.screen.toRoute(this.currentScreenInstance.store);

            if (route)
            {
                const currentScreenInstance = this.currentScreenInstance;
                if (currentScreenInstance.markedForPop)
                {
                    return currentScreenInstance.whenPopped!;
                }

                const initialLocation = window.location.href;

                const whenPopped = new Promise<boolean>(
                    (resolve, reject) =>
                    {
                        this.popPromiseResolverByScreenStackIdx.set(
                            this.screenStack.currentIndex!,
                            {
                                resolve: (aValue) => resolve(aValue ?? true),
                                reject: reject,
                                force: force,
                            },
                        );

                        const handle = setInterval(
                            () =>
                            {
                                const currentLocation = window.location.href;

                                if (currentLocation.startsWith(initialLocation))
                                    window.history.back();
                                else
                                    clearInterval(handle);
                            },
                            100,
                        );
                    },
                );
                this.currentScreenInstance.setMarkedForPop(whenPopped);
                return whenPopped;
            }
            else
            {
                return super.popScreen(force, uuidOfScreenInstantiationToPop);
            }
        }
        else
        {
            return Promise.resolve(true);
        }
    }

    async truncateFuture(): Promise<void>
    {
        const state = new BrowserHistoryEntryState(this.screenStack.currentIndex!);
        if (this.currentScreenInstance !== undefined) {
            const route = this.currentScreenInstance.screen.toRoute
                && this.currentScreenInstance.screen.toRoute(this.currentScreenInstance.store);
            this.busyTruncatingHistory = true;
            const backPromise = new Promise<any>(
                (resolve, reject) =>
                {
                    this.popPromiseResolverByScreenStackIdx.set(
                        'TRUNCATE_FUTURE',
                        {
                            resolve: resolve,
                            reject: reject,
                            force: true,
                        });

                    window.history.back();
                });
            await backPromise
            window.history.pushState(state, '', route);
            this.busyTruncatingHistory = false;
            super.truncateFuture();
        }
    }

    // ----------------------- Private logic ------------------------
}
