import { makeStyles, TypographyProps } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import * as React from 'react';
import { FC } from 'react';

const useStyles = makeStyles({
    root: {
        fontWeight: 500,
    },
});

export interface BoldTypographyProps extends TypographyProps
{

}

export const BoldTypography: FC<BoldTypographyProps> =
    props =>
    {
        const classes = useStyles();

        return <Typography
            className={classes.root}
            {...props}
        />;
    };
