import { OrderDescriptor } from '../../Api/Order/OrderDescriptor';

export function getOrderIdArray(orders?: OrderDescriptor | OrderDescriptor[]): number[]
{
    let orderIds: number[] = [];

    if (orders)
    {
        if (orders instanceof Array)
        {
            orders.forEach(
                order =>
                    orderIds.push(order.id));
        }
        else
        {
            orderIds.push(orders.id);
        }
    }

    return orderIds;
}
