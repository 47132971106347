import { Hidden, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { FC } from 'react';
import { useIsKioskMode } from '../../../Service/KioskService/Api/useIsKioskMode';
import { drawerWidth } from '../../Page/Business/BusinessConstants';
import { bottomBarBorderColor, bottomBarColor, bottomBarContentColor, useBottomBarHeight } from './BottomBarConstants';
import { useAndRequireSessionStatusBarStore } from './SessionStatusBarStoreProvider';

const useStyles = makeStyles(({
	BarFullWidth: {
		display: 'flex',
		flexDirection: 'row',
		backgroundColor: bottomBarColor,
		color: bottomBarContentColor,
		// paddingLeft: 8,
		// paddingRight: 8,
		// paddingRight: 8,
	},
	BarPartialWidth: {
		display: 'flex',
		flexDirection: 'row',
		backgroundColor: bottomBarColor,
		color: bottomBarContentColor,
		marginRight: drawerWidth - 20,
		paddingRight: 20,
		// paddingLeft: 8,
		// paddingRight: 8,
		// paddingRight: 8,
	},
	StatusPart: {
		flex: '1 1 auto',
		display: 'flex',
		flexDirection: 'row',
	},
	VerticalDivider: {
		flex: '0 0 auto',
		width: 1,
		borderTopWidth: 0,
		borderBottomWidth: 0,
		borderLeftWidth: 1,
		borderRightWidth: 0,
		borderLeftStyle: 'solid',
		borderLeftColor: bottomBarBorderColor,
		marginTop: 10,
		marginBottom: 10,
	},
}));

export const SessionStatusBarWrapper: FC =
	(
		{
			children,
		},
	) =>
	{
		const store = useAndRequireSessionStatusBarStore();
		const classes = useStyles();
		const isKioskMode = useIsKioskMode();
		const bottomBarHeight = useBottomBarHeight();

		return <>
			<Hidden
				mdUp={!isKioskMode}
			>
				<div
					className={classes.BarFullWidth}
					style={{
						backgroundColor: store.brandingService.tintColor.withAlpha(0.9).css,
						color: store.brandingService.tintContrastTextColor.css,
						height: bottomBarHeight,
					}}
				>
					{children}
				</div>
			</Hidden>
			{
				!isKioskMode &&
				<Hidden
					smDown
				>
					<div
						className={
							!store.shoppingCartStore.isEmpty
								? classes.BarPartialWidth
								: classes.BarFullWidth
						}
						style={{
							backgroundColor: store.brandingService.tintColor.withAlpha(0.9).css,
							color: store.brandingService.tintContrastTextColor.css,
							height: bottomBarHeight,
						}}
					>
						{children}
					</div>
				</Hidden>
			}
		</>;
	};
