import * as React from 'react';
import { ProductStore } from './ProductStore';

export interface ProductContext
{
	productStore: ProductStore;
}

export const ProductContextRef = React
	.createContext<ProductContext>({} as ProductContext);

export const ProductContextProvider = ProductContextRef.Provider;
export const ProductContextConsumer = ProductContextRef.Consumer;
