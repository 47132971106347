import { useEffect, useState } from 'react';
import { ExternalBill } from '../../../../../../../Api/ExternalBill/ExternalBill';
import { PublicExternalBillRequest } from '../../../../../../../Api/ExternalBill/PublicExternalBillRequest';
import { postJsonAny } from '../../../../../../../Util/Api';
import { useExternalBillUpdater } from './useExternalBillUpdater';

interface ExternalBillResult
{
	error?: string;
	externalBill?: ExternalBill;
	isLoading: boolean;
}

export function usePublicExternalBill(request: PublicExternalBillRequest): ExternalBillResult
{
	const [externalBill, setExternalBill] = useState<ExternalBill | undefined>(undefined);
	const [error, setError] = useState<string | undefined>(undefined);
	const [isLoading, setLoading] = useState(true);

	useEffect(
		() =>
		{
			setError(undefined);
			setLoading(true);
			postJsonAny<ExternalBill>(
				'/externalBills/publiclyRequest',
				{},
				request,
			).then(
				result =>
				{
					setExternalBill(result);
					setLoading(false);
				},
			).catch(
				(error: Error) =>
					setError(error.message),
			);
		},
		[request],
	);

	useExternalBillUpdater(
		externalBill,
		setExternalBill,
	);

	return {
		error,
		externalBill,
		isLoading,
	};
}
