import { CircularProgress, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { FC, useContext, useEffect, useState } from 'react';
import { BillBusinessEntrance, BillBusinessEntranceProfile } from '../../../Api/Business/BillBusinessEntrance';
import { useTranslate } from '../../../Bridge/Localization/useTranslate';
import { useRootContext } from '../../../RootContext';
import { postJson } from '../../../Util/Api';
import { useAsyncResult } from '../../../Util/async/useAsyncResult';
import { DialogVisibilityContextProvider } from '../../UI/dialog/context/visibility/DialogVisibilityContext';
import { FormFocusContextProvider } from '../../UI/focus/context/FormFocusContext';
import { ValidationBoundary } from '../../UI/Form/Core/ValidationBoundary';
import { ValidationContextProvider } from '../../UI/validation/context/ValidationContext';
import { BusinessContextProvider } from '../Business/BusinessContext';
import { ExternalBillViewer } from '../Business/Payment/bill-settler/BillSettlerDialog';
import { EmailContextProvider } from '../Business/Payment/bill-settler/context/email/EmailContext';
import { ExternalBillContextProvider } from '../Business/Payment/bill-settler/context/external-orders/ExternalOrderLinessContext';
import { TipContextProvider } from '../Business/Payment/bill-settler/context/tip/TipContextProvider';
import { usePublicExternalBill } from '../Business/Payment/bill-settler/hooks/external-bill/usePublicExternalBill';
import { EntranceContextRef } from '../Entrance/EntranceContext';
import { NoHashValue } from '../Entrance/EntranceStore';
import { BillStore } from './BillStore';

const useStyles = makeStyles({
	loaderRoot: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
});

interface BillProps
{
	store: BillStore;
}

export const Bill: FC<BillProps> =
	(
		{
			store,
		},
	) =>
	{
		const {navigator, notification} = useRootContext(true);
		const {entranceStore} = useContext(EntranceContextRef);
		const translate = useTranslate();
		const {error: externalBillError, externalBill} = usePublicExternalBill(store.request);

		const [showValidation, setShowValidation] = useState(false);

		const classes = useStyles();

		const {error: entranceError, result: entrance} =
			useAsyncResult<BillBusinessEntrance | undefined>(
				async () =>
				{
					if (externalBill?.state === 'Open')
					{
						try
						{
							return await postJson(
								`/externalBills/${externalBill.id}/visit`,
								{},
								BillBusinessEntranceProfile,
								store.request,
							);
						}
						catch (error)
						{
							navigator
								.popScreen()
								.then(
									() =>
										notification.notify({
											content: translate('Generic-Error-Occurred'),
											variant: 'error',
										}),
								);
						}
					}
					else
					{
						return undefined;
					}
				},
				[externalBill?.id, externalBill?.state, navigator, notification, store.request, translate],
			);

		const {error: businessError, result: businessStore} =
			useAsyncResult(
				async () =>
				{
					if (entrance !== undefined)
					{
						return entranceStore.createBusinessStore(
							entrance.business,
							entrance.place,
							NoHashValue,
						);
					}
				},
				[entrance, entranceStore],
			);

		useEffect(
			() =>
			{
				if (businessError !== undefined || entranceError !== undefined || externalBillError !== undefined)
					navigator
						.popScreen()
						.then(
							() =>
								notification.notify({
									content: translate('Generic-Error-Occurred'),
									variant: 'error',
								}),
						);
			},
			[businessError, entranceError, externalBillError, navigator, notification, translate],
		);

		if (businessStore === undefined || entrance === undefined)
		{
			return <div
				className={classes.loaderRoot}
			>
				<CircularProgress />
			</div>;
		}
		else
		{
			return <BusinessContextProvider
				value={{
					businessStore,
					clockService: store.bridge.clockService,
					localizer: store.bridge.localizer,
					currentPlaceService: businessStore.currentPlaceService,
					currentOrderService: businessStore.currentOrderService,
					currentAgeVerificationService: businessStore.currentAgeVerificationService,
				}}
			>
				<DialogVisibilityContextProvider
					onExited={
						() =>
							navigator.popScreen()
					}
				>
					<ValidationContextProvider
						showValidation={showValidation}
					>
						<ValidationBoundary>
							<FormFocusContextProvider
								defaultForm={'external-orders'}
								forms={['external-orders', 'payment-method', 'email']}
							>
								<EmailContextProvider
									place={entrance.place}
								>
									<TipContextProvider
										place={entrance.place}
									>
										<ExternalBillContextProvider
											externalBill={externalBill}
										>
											<ExternalBillViewer
												business={entrance.business}
												place={entrance.place}
												externalBill={externalBill}
												startShowingValidation={
													() =>
														setShowValidation(true)
												}
											/>
										</ExternalBillContextProvider>
									</TipContextProvider>
								</EmailContextProvider>
							</FormFocusContextProvider>
						</ValidationBoundary>
					</ValidationContextProvider>
				</DialogVisibilityContextProvider>
			</BusinessContextProvider>;
		}
	};
