import { Button, Dialog as MuiDialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { DialogInstance } from '../../Bridge/Dialog/DialogInstance';
import { PinCodeDialogInputSpec } from '../../Bridge/Dialog/Input/PinCodeDialogInputSpec';
import { topBarHeight, topBarHeightMobile } from './PageTopBar';
import { PinCodeInput } from './PinCodeInput/PinCodeInput';
import { PinCodeInputStore } from './PinCodeInput/PinCodeInputStore';

export interface PageProps
{
    instance: DialogInstance
}

const useStyles = makeStyles((theme: Theme) => ({
    TitleBar: {
        [theme.breakpoints.down('sm')]: {
            height: topBarHeightMobile,
        },
        [theme.breakpoints.up('md')]: {
            height: topBarHeight,
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingRight: 0,
    },
}));

export const Dialog: FC<PageProps> =
    (
        {
            instance,
        }
    ) => {
        const [id] = useState(() => uuid());
        const titleId = `alert-dialog-${id}-title`;
        const descriptionId = `alert-dialog-${id}-description`;
        const classes = useStyles();

        const input = useObserver(() => instance.props.input);
        const inputStore = input && input instanceof PinCodeDialogInputSpec
            && new PinCodeInputStore(
                value => {
                    return input.onSubmit(value);
                },
                input.length,
            );

        return useObserver(() => <MuiDialog
            open={true}
            onClose={() => {
                instance.dismiss();
                instance.props.performOnCancel && instance.props.performOnCancel();
            }}
            aria-labelledby={titleId}
            aria-describedby={descriptionId}
            maxWidth="lg"
        >
            <DialogTitle
                id={titleId}
                classes={{root: classes.TitleBar}}
            >
                {instance.props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    id={descriptionId}
                >
                    {instance.props.content}
                </DialogContentText>
            </DialogContent>
            {
                inputStore && input instanceof PinCodeDialogInputSpec &&
                <DialogContent
                    id={descriptionId}
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <PinCodeInput store={inputStore} />
                </DialogContent>
            }
            <DialogActions>
                {
                    instance.props.actions.map(
                        (action, idx) =>
                            <Button
                                key={action.name}
                                onClick={() =>
                                {
                                    if (action.isCancel)
                                    {
                                        instance.dismiss();
                                        action.perform();
                                    } else
                                    {
                                        attemptInputSubmit(inputStore)
                                            .then(() => {
                                                instance.dismiss();
                                                action.perform();
                                            });
                                    }
                                }}
                                color="primary"
                                autoFocus={idx === instance.props.actions.length - 1}
                            >
                                {action.name}
                            </Button>)
                }
            </DialogActions>
        </MuiDialog>);
    };

function attemptInputSubmit(inputStore: any): Promise<void>
{
    if (inputStore)
    {
        if (inputStore instanceof PinCodeInputStore)
        {
            return inputStore.attemptCompletion();
        }
    }
    return Promise.resolve();
}