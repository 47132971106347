import { AxiosResponse } from 'axios';
import { setApiReachable } from '../../Hooks/useApiReachability';

/**
 * Handler for whenever an API request results in a proper response
 *
 * @param response the resulting {@link AxiosResponse}
 * @return the provided {@link AxiosResponse}
 */
export function onApiResponseReceived(response: AxiosResponse): AxiosResponse
{
	setApiReachable();
	return response;
}