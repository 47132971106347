import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from '../../../../../../Bridge/Localization/useTranslation';
import { PositiveActionButton } from '../../../../../UI/action-button/type/PositiveActionButton';

interface OpenMenuDialogActionsProps
{
	onStart: () => Promise<void>;
}

export const OpenMenuDialogActions: FC<OpenMenuDialogActionsProps> =
	(
		{
			onStart,
		},
	) =>
	{
		return <PositiveActionButton
			icon={PlayArrowIcon}
			onClick={onStart}
			text={useTranslation('Generic-Start')}
		/>;
	};
