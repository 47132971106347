import * as React from 'react';

interface HorizontalDividerProps
{
    className?: string;
    color?: string;
    gradient?: boolean;
    height?: number | string;
    style?: React.CSSProperties;
    verticalMargin?: number | string;
}

export const HorizontalDivider: React.FC<HorizontalDividerProps> =
    ({
         className,
         color,
         gradient,
         height,
         style,
         verticalMargin,
     }) =>
    {
        return <div
            className={className}
            style={{
                alignItems: 'center',
                display: 'flex',
                marginBottom: verticalMargin,
                marginTop: verticalMargin,
                ...style,
            }}
        >
            <hr
                style={{
                    backgroundImage: `linear-gradient(270deg, ${color}, ${gradient ? 'transparent' : `${color}`})`,
                    borderWidth: 0,
                    flexGrow: 1,
                    height: height,
                    marginRight: 0,
                }}
            />
            <hr
                style={{
                    backgroundImage: `linear-gradient(90deg, ${color}, ${gradient ? 'transparent' : `${color}`})`,
                    borderWidth: 0,
                    flexGrow: 1,
                    height: height,
                    marginLeft: 0,
                }}
            />
        </div>;
    };

HorizontalDivider.defaultProps =
    {
        color: 'black',
        gradient: true,
        height: 1,
        verticalMargin: 20,
    };
