import { IconButton, makeStyles } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Alert } from '@material-ui/lab';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PaymentIssuer } from '../../../../../../../Api/Payment/PaymentIssuer';
import { PaymentMethodDescriptor } from '../../../../../../../Api/Payment/PaymentMethodDescriptor';
import { useTranslate } from '../../../../../../../Bridge/Localization/useTranslate';
import { AdyenCheckoutData } from '../../../../../../../lib/adyen/AdyenCheckoutData';
import { CardBorderRadius } from '../../../../../../../Util/Theme';
import { useFormFocus } from '../../../../../../UI/focus/context/FormFocusContext';
import { useAdyenCheckoutContext } from '../../../../../../UI/payment/adyen/checkout/AdyenCheckoutContext';
import { PaymentMethodsLoadingCard } from '../../../../../../UI/payment/method/form/loading/PaymentMethodsLoadingCard';
import { PaymentMethodForm } from '../../../../../../UI/payment/method/form/PaymentMethodForm';
import { usePaymentPriceContext } from '../../../../../../UI/payment/price/context/PaymentPriceContext';
import { EntranceContextRef } from '../../../../../Entrance/EntranceContext';
import { BusinessContextRef } from '../../../../BusinessContext';

const useStyles = makeStyles({
	alert: {
		alignItems: 'center',
	},
	paymentMethods: {
		borderRadius: `${CardBorderRadius}px !important`,
		width: '100%',
	},
});

interface PaymentMethodsBillSettlerSegmentProps
{
	onChange: (paymentMethod?: PaymentMethodDescriptor, paymentIssuer?: PaymentIssuer, adyenCheckoutData?: AdyenCheckoutData) => void;
	onHasErrorChange: (hasError: boolean) => void;
	paymentSessionHasError: boolean;
}

export const PaymentMethodsBillSettlerSegment: FC<PaymentMethodsBillSettlerSegmentProps> =
	({
		onChange,
		onHasErrorChange,
		paymentSessionHasError,
	}) =>
	{
		const translate = useTranslate();
		const {notification} = useContext(EntranceContextRef);
		const {currentPlaceService} = useContext(BusinessContextRef);
		const {total} = usePaymentPriceContext();
		const {enabled, isLoading, refreshPaymentSession} = useAdyenCheckoutContext();

		const classes = useStyles();

		const [hasError, setHasError] = useState(false);

		const place = useObserver(() => currentPlaceService.place!);

		const [focused, setFocused] = useFormFocus('payment-method');

		const handleChange = useCallback((
			paymentMethod?: PaymentMethodDescriptor,
			paymentIssuer?: PaymentIssuer,
			adyenCheckoutData?: AdyenCheckoutData,
		) =>
		{
			setFocused(paymentMethod === undefined);
			
			onChange(paymentMethod, paymentIssuer, adyenCheckoutData);
		}, [onChange, setFocused]);

		const handleRetry = useCallback(
			async () =>
			{
				if (enabled)
				{
					try
					{
						await refreshPaymentSession();
					}
					catch (error)
					{
						notification.notify({
							content: translate('Payment-Session-Setup-Failed'),
							variant: 'error',
						});
					}
				}
			},
			[enabled, notification, refreshPaymentSession, translate],
		);
		
		useEffect(
			() => onHasErrorChange(hasError),
			[hasError, onHasErrorChange],
		);

		return useMemo(() =>
		{
			if (total.greaterThan(0) && place.paymentAfterEnabled)
			{
				if (isLoading)
				{
					return <PaymentMethodsLoadingCard />;
				}
				else if (paymentSessionHasError)
				{
					return <Alert
						action={
							enabled &&
							<IconButton
								onClick={handleRetry}
							>
								<RefreshIcon />
							</IconButton>
						}
						className={classes.alert}
						severity="error"
					>
						{translate('Payment-Session-Setup-Failed')}
					</Alert>;
				}
				else
				{
					return <PaymentMethodForm
						className={classes.paymentMethods}
						hasFocus={focused}
						onChange={handleChange}
						onFocusChange={setFocused}
						onHasErrorChange={setHasError}
					/>;
				}
			}
			else
				return null;
		}, [classes.alert, classes.paymentMethods, enabled, focused, handleChange, handleRetry, isLoading, paymentSessionHasError, place.paymentAfterEnabled, setFocused, total, translate]);
	};