import { addDays, format, startOfDay } from 'date-fns';
import { toDate, utcToZonedTime } from 'date-fns-tz';
import { getIsoDayOfWeek } from './DayOfWeek';
import { addTo, parse } from './Duration';
import { ConjunctionTimeSeriesPayload, DayWindowOffsetFromNowTimeSeriesPayload, DisjunctionTimeSeriesPayload, ExactWindowOffsetFromNowTimeSeriesPayload, NegationBooleanTimeSeriesPayload, RepeatDailyBooleanTimeSeriesPayload, RepeatWeeklyBooleanTimeSeriesPayload, SingleDayBooleanTimeSeriesPayload, SingleOnTimeBooleanTimeSeriesPayload, TimeScheduleSpecification } from './TimeScheduleSpecification';

/**
 * evaluate if a time schedule is true for a given {@code targetDate}, when evaluating it at {@code date}
 * @param date the date of the evaluation. (Often "now").
 * @param targetDate the date that should be evaluated.
 * @param specification the time schedule specification to evaluate.
 */
export function evaluateTimeSchedule(
	date: Date,
	targetDate: Date,
	specification: TimeScheduleSpecification,
): boolean
{
	switch (specification.type)
	{
		case 'ALWAYS':
			return true;

		case 'CONJUNCTION':
			return evaluateConjunctionTimeSchedule(date, targetDate, specification as ConjunctionTimeSeriesPayload);

		case 'DAY_WINDOW_OFFSET_FROM_NOW':
			return evaluateDayWindowOffsetFromNowTimeSchedule(date, targetDate, specification as DayWindowOffsetFromNowTimeSeriesPayload);

		case 'DISJUNCTION':
			return evaluateDisjunctionTimeSchedule(date, targetDate, specification as DisjunctionTimeSeriesPayload);

		case 'EXACT_WINDOW_OFFSET_FROM_NOW':
			return evaluateExactWindowOffsetFromNowTimeSchedule(date, targetDate, specification as ExactWindowOffsetFromNowTimeSeriesPayload);

		case 'NEGATION':
			return evaluateNegationTimeTimeSchedule(date, targetDate, specification as NegationBooleanTimeSeriesPayload);

		case 'NEVER':
			return false;

		case 'REPEAT_DAILY':
			return evaluateRepeatDailyTimeSchedule(date, targetDate, specification as RepeatDailyBooleanTimeSeriesPayload);

		case 'REPEAT_WEEKLY':
			return evaluateRepeatWeeklyTimeSchedule(date, targetDate, specification as RepeatWeeklyBooleanTimeSeriesPayload);

		case 'SINGLE_DAY':
			return evaluateSingleDayTimeSchedule(date, targetDate, specification as SingleDayBooleanTimeSeriesPayload);

		case 'SINGLE_ON_TIME':
			return evaluateSingleOnTimeTimeSchedule(date, targetDate, specification as SingleOnTimeBooleanTimeSeriesPayload);

		default:
			throw new Error(`Unknown time schedule specification: ${(specification as any).type}`);
	}
}

function evaluateConjunctionTimeSchedule(
	date: Date,
	targetDate: Date,
	{terms}: ConjunctionTimeSeriesPayload,
): boolean
{
	return terms
		.every(term =>
			evaluateTimeSchedule(
				date,
				targetDate,
				term,
			),
		);
}

function evaluateDayWindowOffsetFromNowTimeSchedule(
	date: Date,
	targetDate: Date,
	{
		timeZone,
		fromInclusive,
		toExclusive,
	}: DayWindowOffsetFromNowTimeSeriesPayload,
): boolean
{
	const zonedDateTime = getDateInTimeZone(date, timeZone);
	const zonedTargetDateTime = getDateInTimeZone(targetDate, timeZone);
	const startDate = fromInclusive == null
		? undefined
		: startOfDay(addDays(zonedDateTime, fromInclusive));
	const endDate = toExclusive == null
		? undefined
		: startOfDay(addDays(zonedDateTime, toExclusive));

	return (startDate === undefined || zonedTargetDateTime >= startDate)
		&& (endDate === undefined || zonedTargetDateTime < endDate);
}

function evaluateDisjunctionTimeSchedule(
	date: Date,
	targetDate: Date,
	{terms}: DisjunctionTimeSeriesPayload,
): boolean
{
	return terms.some(
		term =>
			evaluateTimeSchedule(
				date,
				targetDate,
				term,
			),
	);
}

function evaluateExactWindowOffsetFromNowTimeSchedule(
	date: Date,
	targetDate: Date,
	{
		fromInclusive,
		toExclusive,
	}: ExactWindowOffsetFromNowTimeSeriesPayload,
): boolean
{
	const startDate = fromInclusive == null
		? undefined
		: addTo(parse(fromInclusive), date);
	const endDate = toExclusive == null
		? undefined
		: addTo(parse(toExclusive), date);

	return (startDate === undefined || targetDate >= startDate)
		&& (endDate === undefined || targetDate < endDate);
}

function evaluateNegationTimeTimeSchedule(
	date: Date,
	targetDate: Date,
	{term}: NegationBooleanTimeSeriesPayload,
): boolean
{
	return !evaluateTimeSchedule(date, targetDate, term);
}

function evaluateRepeatDailyTimeSchedule(
	_date: Date,
	targetDate: Date,
	{
		timeZone,
		endOnNextDay,
		start,
		end,
	}: RepeatDailyBooleanTimeSeriesPayload,
): boolean
{
	const zonedTime = format(getDateInTimeZone(targetDate, timeZone), 'HH:mm:ss');

	if (endOnNextDay)
	{
		return zonedTime >= start
			|| zonedTime < end;
	}
	else
	{
		return zonedTime >= start
			&& zonedTime < end;
	}
}

function evaluateRepeatWeeklyTimeSchedule(
	_date: Date,
	targetDate: Date,
	{
		timeZone,
		startDay,
		startTime,
		endDay,
		endTime,
		endTimeInNextWeek,
	}: RepeatWeeklyBooleanTimeSeriesPayload,
): boolean
{
	const zonedDayTime = format(getDateInTimeZone(targetDate, timeZone), 'i:HH:mm:ss');
	const startDayTime = `${getIsoDayOfWeek(startDay)}:${startTime}`;
	const endDayTime = `${getIsoDayOfWeek(endDay)}:${endTime}`;

	if (endTimeInNextWeek)
	{
		return zonedDayTime >= startDayTime
			|| zonedDayTime < endDayTime;
	}
	else
	{
		return zonedDayTime >= startDayTime
			&& zonedDayTime < endDayTime;
	}
}

function evaluateSingleDayTimeSchedule(
	_date: Date,
	targetDate: Date,
	{
		timeZone,
		date,
	}: SingleDayBooleanTimeSeriesPayload,
): boolean
{
	const zonedDate = getDateInTimeZone(targetDate, timeZone);
	const startOfDay = toDate(`${date}T00:00:00`, {timeZone});
	const endOfDay = toDate(`${date}T23:59:59`, {timeZone});

	return zonedDate >= startOfDay && zonedDate <= endOfDay;
}

function evaluateSingleOnTimeTimeSchedule(
	_date: Date,
	targetDate: Date,
	{
		start,
		end,
	}: SingleOnTimeBooleanTimeSeriesPayload,
): boolean
{
	const startDate = start == null
		? undefined
		: new Date(start);
	const endDate = end == null
		? undefined
		: new Date(end);

	return (startDate === undefined || targetDate >= startDate)
		&& (endDate === undefined || targetDate < endDate);
}

/**
 * Convert a local date to a zoned date in another time zone.
 */
export function getDateInTimeZone(
	localDate: Date,
	timeZone: string
)
{
	return utcToZonedTime(localDate, timeZone);
}
