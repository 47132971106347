export async function serializePromises<T>(generators: Array<() => Promise<T>>): Promise<T[]>
{
	const results: T[] = [];

	for (const generator of generators)
	{
		const result = await generator();

		results.push(result);
	}

	return results;
}