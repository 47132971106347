import UIElement from '@adyen/adyen-web/dist/types/components/UIElement';
import { useCallback } from 'react';
import { Order } from '../../../../Api/Order/Order';
import { OrderPaymentProcessingResult } from '../model/OrderPaymentProcessingResult';
import { useProcessAdyenCheckoutOrderPayment } from './useProcessAdyenCheckoutOrderPayment';
import { useProcessPayPalOrderPayment } from './useProcessPayPalOrderPayment';

type ProcessOrderPaymentHandler = (
	order: Order,
	component?: UIElement<any>,
) => Promise<OrderPaymentProcessingResult>

export function useProcessOrderPayment(): ProcessOrderPaymentHandler
{
	const processAdyenCheckoutOrderPayment = useProcessAdyenCheckoutOrderPayment();
	const processPayPalOrderPayment = useProcessPayPalOrderPayment();

	return useCallback(
		async (
			order: Order,
			component?: UIElement<any>,
		) =>
		{
			switch (order.paymentGateway)
			{
				case 'AdyenCheckout':
				{
					return processAdyenCheckoutOrderPayment(order, component);
				}
				case 'PayPal':
				{
					return processPayPalOrderPayment();
				}
				default:
				{
					if (order.paymentUrl === undefined)
					{
						return {
							state: 'Success',
						};
					}
					else
					{
						return {
							redirectUrl: order.paymentUrl,
							requiresExtraUserInteraction: false,
							state: 'Pending',
						};
					}
				}
			}
		},
		[processAdyenCheckoutOrderPayment, processPayPalOrderPayment],
	);
}
