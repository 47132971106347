export function getParagraphStylesWithoutTopAndBottomMargin()
{
	return {
		'& p:first-of-type': {
			marginTop: 0,
		},
		'& p:last-of-type': {
			marginBottom: 0,
		},
	};
}