import * as React from 'react';
import { FC, useMemo } from 'react';
import { ORDER_EVENT_LONG_VALUE_THRESHOLD, OrderEventComponent } from '../OrderEventComponent';
import { OrderEventProps } from '../OrderEventProps';
import { OrderEventStateTransition } from '../OrderEventStateTransition';

export const OrderProcessingEvent: FC<OrderEventProps> =
	(
		{
			orderEvent,
		},
	) =>
	{
		const hasFromState = useMemo(() => orderEvent.fromState !== undefined, [orderEvent.fromState]);
		const hasToState = useMemo(() => orderEvent.toState !== undefined, [orderEvent.toState]);
		const hasStates = useMemo(() => hasFromState || hasToState, [hasFromState, hasToState]);
		const isSameState = useMemo(() => orderEvent.fromState === orderEvent.toState, [orderEvent.fromState, orderEvent.toState]);

		const informationText = useMemo(
			() =>
				orderEvent.information !== undefined && orderEvent.information.length <= ORDER_EVENT_LONG_VALUE_THRESHOLD
					? orderEvent.information
					: undefined,
			[orderEvent.information],
		);

		const stateTransitionComponent = useMemo(
			() => <OrderEventStateTransition fromState={orderEvent.fromState} toState={orderEvent.toState} orderEventType={orderEvent.type} secondary />,
			[orderEvent.fromState, orderEvent.toState, orderEvent.type],
		);

		const summaryComponent = useMemo(() =>
			{
				const fallback = hasStates ? stateTransitionComponent : informationText;

				return isSameState
					? orderEvent.code ?? fallback
					: fallback;
			},
			[hasStates, informationText, isSameState, orderEvent.code, stateTransitionComponent],
		);

		return <OrderEventComponent
			orderEvent={orderEvent}
			summary={summaryComponent}
		/>;
	};