import { Box, makeStyles, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { FeatureConfiguration } from '../../../ProductConfiguration/FeatureConfiguration/FeatureConfiguration';
import { FeatureConfigurationStore } from '../../../ProductConfiguration/FeatureConfiguration/FeatureConfigurationStore';
import { ProductConfigurationStore } from '../../../ProductConfiguration/ProductConfigurationStore';

const useStyles =
	makeStyles(
		() => ({
			title: {
				fontSize: '1.8rem',
			},
			description: {
				color: 'rgba(0,0,0,0.45)',
				fontWeight: 500,
				fontSize: '1.3rem',
			},
		})
	);

interface ProductDrawerFeatureConfigurationProps
{
	configurationStore: ProductConfigurationStore;
	featureStore: FeatureConfigurationStore;
}

export const ProductDrawerFeatureConfiguration: FC<ProductDrawerFeatureConfigurationProps> =
	observer(
		(
			{
				configurationStore,
				featureStore,
			},
		) =>
		{
			const classes = useStyles();

			return <Box>
				<Typography
					variant="h6"
					align="center"
					className={classes.title}
				>
					{featureStore.productFeature.name}
					{
						featureStore.constraintMessages.length > 0
							? ` (${featureStore.constraintMessages.map(msg => msg.slice(0, 1).toLowerCase() + msg.slice(1)).join(' - ')})`
							: null
					}
				</Typography>
				{
					featureStore.productFeature.description !== undefined
						&& featureStore.productFeature.description.length > 0
						&&
						<Box
							paddingTop={1}
						>
								<Typography
									variant="body1"
									align="center"
									className={classes.description}
								>
									{featureStore.productFeature.description}
								</Typography>
						</Box>
				}
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
					}}
				>
					<Box
						sx={{
							width:
								featureStore.hasAnyImage
									? '100%'
									: featureStore.hasAnyPrice
										? 'min(1000px, 100%)'
										: undefined,
						}}
					>
						<FeatureConfiguration
							store={featureStore}
							productConfigurationStore={configurationStore}
							hideTitleAndDescription
						/>
					</Box>
				</Box>
			</Box>;
		}
	);
