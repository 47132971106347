import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import { CSSProperties, FC } from 'react';
import { OrderDescriptor } from '../../../../../../Api/Order/OrderDescriptor';
import { hasAvatars } from '../../../../../../Util/Orders/hasAvatars';
import { OrderLine } from '../item/OrderLine';

const useStyles = makeStyles(
    (theme: Theme) =>
        ({
            content:
                {
                    padding: theme.spacing(1),
                },
        }));

interface OrderInfoProps
{
    className?: string;
    order: OrderDescriptor;
    style?: CSSProperties;
}

export const OrderInfo: FC<OrderInfoProps> =
    ({
         className,
         order,
         style,
     }) =>
    {
        const classes = useStyles();

        return <div
            className={className}
            style={style}
        >
            <div
                className={classes.content}
            >
                {
                    order.orderLines &&
                    order.orderLines.length > 0 &&
                    <div>
                        {
                            order.orderLines.map(
                                orderLine =>
                                    <OrderLine
                                        alignWithAvatars={hasAvatars(order)}
                                        clickable
                                        key={orderLine.id}
                                        orderLine={orderLine}
                                        showPriceAsCurrency={order.currency}
                                    />,
                            )
                        }
                    </div>
                }
            </div>
        </div>;
    };