import * as React from 'react';
import { createContext, FC, useContext, useMemo, useState } from 'react';
import { ExternalBill } from '../../../../../../../Api/ExternalBill/ExternalBill';
import { ExternalBillPart } from '../../../../../../../Api/ExternalBill/ExternalBillPart';
import { ExternalBillPartLine } from '../../../../../../../Api/ExternalBill/ExternalBillPartLine';
import { fetchAny } from '../../../../../../../Util/Api';
import { useAsyncResult } from '../../../../../../../Util/async/useAsyncResult';

interface ExternalBillContext
{
	externalBill: ExternalBill;
	lines: ExternalBillPartLine[] | undefined;
	parts: ExternalBillPart[] | undefined;
}

const ExternalBillContextRef = createContext(undefined);

export function useExternalBillContext(): ExternalBillContext
{
	return useContext(ExternalBillContextRef);
}

interface ExternalBillContextProviderProps
{
	externalBill: ExternalBill;
}

export const ExternalBillContextProvider: FC<ExternalBillContextProviderProps> =
	({
		children,
		externalBill,
	}) =>
	{
		const [parts, setParts] = useState<ExternalBillPart[] | undefined>();

		const {result: lines} =
			useAsyncResult<ExternalBillPartLine[]>(
				async () =>
				{
					if (externalBill.state === 'Open')
					{
						const parts =
							await fetchAny<ExternalBillPart[]>(
								`/externalBills/${externalBill.id}/parts`,
								{},
							);

						setParts(parts);

						return (
							await Promise.all(
								parts.map(
									part =>
										fetchAny<ExternalBillPartLine[]>(
											`/externalBills/${externalBill.id}/parts/${part.id}/lines`,
											{},
										),
								),
							)
						).reduce(
							(a, b) => a.concat(b),
							[],
						);
					}
				},
				[
					externalBill,
				],
			);

		const contextValue = useMemo(
			() =>
				({
					externalBill,
					lines,
					parts,
				}),
			[externalBill, lines, parts],
		);

		return <ExternalBillContextRef.Provider
			value={contextValue}
		>
			{children}
		</ExternalBillContextRef.Provider>;
	};
