// @ts-nocheck
import { Card, IconButton, TextField, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { Pencil } from 'mdi-material-ui';
import { useObserver } from 'mobx-react-lite';
/* eslint-disable */
import * as React from 'react';
import { FC, useContext } from 'react';
import { CardBorderRadius } from '../../../../Util/Theme';
import { EntranceContextRef } from '../../Entrance/EntranceContext';
import { OrderCommentStore } from './OrderCommentStore';

interface OrderCommentFormProps
{
	inFocus: boolean;
	setCommentFormHasFocus: (hasFocus: boolean) => void;
	store: OrderCommentStore;
	showValidation: boolean;
}

export const OrderCommentForm: FC<OrderCommentFormProps> =
	(
		{
			inFocus,
			setCommentFormHasFocus,
			store,
			showValidation
		},
	) =>
	{
		const {
			localizer,
		} = useContext(EntranceContextRef);
		const commentsLabel = localizer.translate('Order-Comment');
		return useObserver(() => {
			const {
				inputValue,
				onChangeInputValue,
				validationErrors,
				charactersRemainingText,
				validationErrorsShownBelowInput,
				validationErrorsMessage
			} = store;
			return <Grid
				item
				xs={12}
				style={{
					flex: '0 0 auto',
					display: 'flex',
					flexDirection: 'column',
					overflowY: 'hidden',
				}}
			>
				<Card
					style={{
						paddingTop: 12,
						paddingBottom: 12,
						paddingLeft: 16,
						paddingRight: 16,
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'stretch',
						alignContent: 'start',
						alignItems: 'start',
						backgroundColor: showValidation && validationErrors.length > 0
							? '#f001'
							: '#fff8',
					}}
				>
					<div
						style={{
							flex: '1 1 auto',
						}}
					>
						<Typography
							color="textPrimary"
						>
							{commentsLabel}
						</Typography>
						{
							inFocus
								?
								<>
									<Typography
										color={
											showValidation && validationErrors.length > 0
												?
												'error'
												:
												'textSecondary'
										}
										style={{
											marginBottom: 8,
										}}
									>
										{charactersRemainingText}
									</Typography>
									<TextField
										value={inputValue}
										id="order-comment-input"
										onChange={e => onChangeInputValue(e.target.value)}
										variant="outlined"
										onKeyPress={(event: React.KeyboardEvent) =>
										{
											if (event.key === 'Enter' || event.key === 'Esc' || event.key === 'Escape')
											{
												setCommentFormHasFocus(false);
											}
										}}
										autoFocus
										fullWidth
										// onBlur={() => setCommentFormHasFocus(false)}
										error={showValidation && validationErrors.length > 0}
										helperText={
											showValidation && validationErrorsShownBelowInput.length > 0
												?
												validationErrorsMessage
												:
												undefined
										}
									/>
								</>
								:
								(
									inputValue && <Typography
										color="textSecondary"
										style={{
											wordBreak: 'break-word',
										}}
									>
										{inputValue}
									</Typography>
								)
						}
					</div>
					{
						!inFocus && <div
							style={{
								flex: '0 0 auto',
								display: 'flex',
								flexDirection: 'row',
								alignContent: 'middle',
								alignItems: 'middle',
								marginLeft: 6,
								marginTop: -5,
								marginRight: -9,
								marginBottom: -6,
							}}
						>
							<IconButton
								onClick={() => setCommentFormHasFocus(true)}
								size="medium"
								style={{
									borderRadius: CardBorderRadius,
									padding: 6,
								}}
							>
								<Pencil
									fontSize="inherit"
									style={{
										transform: 'scale(0.8)',
									}}
								/>
							</IconButton>
						</div>
					}
				</Card>
			</Grid>;
		});
	};