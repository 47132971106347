import { AddressDescriptor } from '../../Api/Util/address/AddressDescriptor';

export function getNameValue(address: AddressDescriptor): string | undefined
{
	if (address.name === undefined)
	{
		return address.companyName === undefined
			? undefined
			: address.companyName;
	}
	else
	{
		return address.companyName === undefined
			? address.name
			: `${address.name} (${address.companyName})`;
	}
}
