import { CardActions, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { FC, useCallback } from 'react';
import { Announcement } from '../../../../Api/Business/Announcement';
import { useTranslation } from '../../../../Bridge/Localization/useTranslation';
import { Screens } from '../../../../Constants/ScreenConstants';
import { useRootContext } from '../../../../RootContext';
import { BrandingService } from '../../../../Service/BrandingInformation/BrandingService';
import { ActionButton } from '../../../UI/action-button/ActionButton';
import { AnnouncementStore } from './AnnouncementStore';

interface StyleProps
{
	brandingService: BrandingService
	announcement: Announcement
}

export const useAnnouncementActionStyles = makeStyles<Theme, StyleProps>(theme => ({
	root: {
		marginLeft: -16,
		marginRight: -16,
		marginBottom: -16,
		flexDirection: 'row-reverse',
	},
}));

interface AnnouncementActionsProps
{
	announcement: Announcement
}

export const AnnouncementActions: FC<AnnouncementActionsProps> =
	(
		{
			announcement,
		},
	) =>
	{
		const {brandingService, navigator} = useRootContext(true);
		const classes = useAnnouncementActionStyles({brandingService, announcement});
		const {longContent} = announcement;
		const readMoreLabel = useTranslation('Generic-ReadMore');
		const readMoreCallback = useCallback(
			() => navigator.pushScreen(
				Screens.Announcement,
				new AnnouncementStore(announcement)
			),
			[announcement, navigator]
		);
		if (longContent === undefined)
			return null;
		else
		{
			return <CardActions className={classes.root}>
				<ActionButton
					onClick={readMoreCallback}
					size="small"
					variant="outlined"
					color={{
						linesAndContentColor: announcement.backgroundContrastTextColor ?? 'primary-contrast-text',
					}}
					text={`${readMoreLabel}...`}
				/>
			</CardActions>
		}
	};