import * as changeCase from 'change-case';
import { Duration } from 'date-fns';
import Decimal from 'decimal.js';
import { parse } from 'iso8601-duration';
import { action, isObservableArray, makeObservable, observable } from 'mobx';
import { Currency } from '../../Api/Other/Currency';
import { LanguageEntryTranslations } from '../../Api/Other/LanguageEntryTranslations';
import { IllegalStateException } from '../Exception/IllegalStateException';
import { SerializationConverter } from './SerializationConverter';

export interface Newable<T>
{
	new(...args: any[]): T;
}

export type KeyTransformer = (key: string) => string;
export type ValueTransformer = (value: any) => any;
export type InitializationCallback<T> = (instance: T) => void;
export type UnknownPropertyStrategy = 'include_as_is' | 'ignore';
export type DeserializationOptions = {
	unknownPropertyStrategy: UnknownPropertyStrategy
};
const deserializationDefaults: DeserializationOptions = {
	unknownPropertyStrategy: 'include_as_is',
};

export const ToCamelCaseKeyTransformer = (key: string): string =>
	changeCase.camelCase(key);

export const ISO8601ToDateTransformer = new SerializationConverter(
	(value: string): Date | undefined =>
		(value !== null && value !== undefined)
			?
			new Date(Date.parse(value))
			:
			undefined,
	(value: Date | null | undefined): string | null | undefined =>
		(value !== null && value !== undefined)
			?
			value.toISOString()
			:
			undefined
);

export const ISO4217ToCurrencyTransformer = new SerializationConverter<Currency | undefined, string | null | undefined>(
	value =>
		value == null ? undefined : new Currency(value),
	value => value?.code,
);

export const NumberToDateTransformer = new SerializationConverter(
	(value: any): Date | undefined =>
		(value !== null && value !== undefined)
			?
			new Date(value)
			:
			undefined,
	undefined,
);

export const NumberToDecimalTransformer = new SerializationConverter(
	(value: any): Decimal | undefined =>
		(value !== null && value !== undefined)
			?
			new Decimal(value)
			:
			undefined,
	undefined,
);

export const StringToDurationTransformer = new SerializationConverter(
	(stringValue: string | undefined | null): Duration | null | undefined =>
		stringValue === null ? null : (stringValue === undefined ? undefined : parse(stringValue)),
	undefined,
)

export const LanguageEntryTransformer = new SerializationConverter(
	(value: LanguageEntryTranslations | string): LanguageEntryTranslations | string | undefined => {
		if (value === undefined)
			return undefined;

		if (typeof value === 'string')
			return value;

		const {de, en, es, fr, it, nl, sv} = value;

		return new LanguageEntryTranslations(de, en, es, fr, it, nl, sv);
	},
	undefined,
)

export class SerializationProfile<T extends {}>
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	public readonly type: Newable<T>;
	private readonly subTypeDiscriminatorProperty: (keyof T & string) | undefined;
	public readonly keyTransformers = observable.array<KeyTransformer>();
	private supertypeSerializationProfile: SerializationProfile<any> | undefined;
	public readonly deserializationValueTransformers = observable.map<string, ValueTransformer>();
	public readonly serializationValueTransformers = observable.map<string, ValueTransformer>();
	public readonly rewrittenKeys = observable.map<string>();
	public readonly profiles = observable.map<keyof T & string, SerializationProfile<any>>();
	public readonly subTypeProfiles = observable.map<string, SerializationProfile<any>>();
	public readonly initializationCallbacks = observable.array<InitializationCallback<T>>();

	/*---------------------------------------------------------------*
	 *                 Constructor and initializers                  *
	 *---------------------------------------------------------------*/

	constructor(
		type: Newable<T>,
		subTypeDiscriminatorProperty?: keyof T & string,
		keyTransformers: KeyTransformer[] = [ToCamelCaseKeyTransformer],
		supertypeSerializationProfile?: SerializationProfile<any>
	)
	{
		makeObservable<SerializationProfile<T>, 'supertypeSerializationProfile' | 'deserializeSingularNonNullish' | 'deserializeSingularNonNullishToThisConcreteType' | 'findSubTypeProfile'>(
			this,
			{
				supertypeSerializationProfile: observable,
				rewrite: action.bound,
				transform: action.bound,
				profile: action.bound,
				initialize: action.bound,
				deserialize: action.bound,
				deserializeSingular: action.bound,
				deserializeArray: action.bound,
				deserializeJSArray: action.bound,
				deserializeSingularNonNullish: action.bound,
				deserializeSingularNonNullishToThisConcreteType: action.bound,
				findSubTypeProfile: action.bound,
			},
		);
		this.type = type;
		this.subTypeDiscriminatorProperty = subTypeDiscriminatorProperty;
		this.keyTransformers.replace(keyTransformers);
		this.supertypeSerializationProfile = supertypeSerializationProfile;
	}

	public rewrite(
		key: string,
		value: string
	): this
	{
		this.rewrittenKeys.set(key, value);

		return this;
	}

	public transform(
		key: keyof T & string,
		serializationConverter: SerializationConverter<any, any>,
	): this
	{
		const {deserializer, serializer} = serializationConverter;
		if (deserializer !== undefined)
			this.deserializationValueTransformers.set(
				key.toString(),
				deserializer
			);
		if (serializer !== undefined)
			this.serializationValueTransformers.set(
				key.toString(),
				serializer
			);

		return this;
	}

	public profile(
		key: keyof T & string,
		profile: SerializationProfile<any>
	): this
	{
		this.profiles.set(key, profile);

		return this;
	}

	public static create<T>(
		type: Newable<T>,
		subTypeDiscriminatorProperty?: keyof T & string,
	): SerializationProfile<T>
	{
		return new SerializationProfile(type, subTypeDiscriminatorProperty);
	}

	public initialize(callback: InitializationCallback<T>): this
	{
		this.initializationCallbacks.push(callback);

		return this;
	}

	public deserialize(
		value: any,
		options?: DeserializationOptions,
	): T[] | T | undefined
	{
		if (Array.isArray(value))
		{
			return this.deserializeArray(value, options);
		}
		else
		{
			return this.deserializeSingular(value, options);
		}
	}

	public deserializeSingular(
		value: any,
		options?: DeserializationOptions,
	): T | undefined
	{
		return value === undefined || value === null
			? undefined
			: this.deserializeSingularNonNullish(value, options);
	}

	public deserializeArray(
		value: any[],
		options?: DeserializationOptions,
	): T[]
	{
		return observable.array(
			value.map(value => this.deserialize(value, options) as T)
		);
	}

	public deserializeJSArray(
		value: any[],
		options?: DeserializationOptions,
	): T[]
	{
		return value.map(
			value => this.deserialize(value, options) as T
		);
	}

	private deserializeSingularNonNullish(
		value: any,
		options?: DeserializationOptions,
	): T | undefined
	{
		const subTypeProfile = this.findSubTypeProfile(value);
		return subTypeProfile !== undefined
			? subTypeProfile.deserializeSingularNonNullish(value, options)
			: this.deserializeSingularNonNullishToThisConcreteType(value, options);
	}

	private deserializeSingularNonNullishToThisConcreteType(
		value: any,
		{
			unknownPropertyStrategy = deserializationDefaults.unknownPropertyStrategy,
		}: DeserializationOptions = deserializationDefaults,
	): T | undefined
	{
		const deserializedValue = new this.type();

		Object.keys(value).forEach((key: string) => {
			let relatedValue = value[key];

			const transformedKey = this.transformAndRewriteKeyForDeserialization(key);

			const profile = this.profiles.get(transformedKey);
			if (profile)
			{
				relatedValue = profile.deserialize(relatedValue);
			}

			const valueTransformer = this.deserializationValueTransformers.get(transformedKey);
			if (valueTransformer)
			{
				relatedValue = valueTransformer(relatedValue);
			}

			const includeProperty = deserializedValue.hasOwnProperty(transformedKey)
				|| unknownPropertyStrategy === 'include_as_is'
			if (includeProperty)
				(deserializedValue as any)[transformedKey] = relatedValue;
		});

		this.initializationCallbacks.forEach(
			callback => callback(deserializedValue)
		);

		return deserializedValue;
	}

	/*---------------------------------------------------------------*
	 *                         Business Logic                        *
	 *---------------------------------------------------------------*/

	public serialize(value: T[] | T | undefined): any
	{
		if (Array.isArray(value) || isObservableArray(value))
		{
			return this.serializeArray(value);
		}
		else
		{
			return this.serializeSingular(value);
		}
	}

	private serializeSingular(object: T | undefined): any
	{
		if (object === null || object === undefined)
			return object;

		for (const subTypeProfile of Array.from(this.subTypeProfiles.values()))
		{
			if (object instanceof subTypeProfile.type)
			{
				return subTypeProfile.serializeSingular(object);
			}
		}

		const json: Record<string, any> = {};

		const subTypeDiscriminator = this.getSubtypeDescriminatorIfPresent();
		if (subTypeDiscriminator !== undefined)
			json[subTypeDiscriminator.key] = subTypeDiscriminator.value;

		for (const key in object)
		{
			if (object.hasOwnProperty(key))
			{
				const jsonPropertyValue = this.serializeProperty(object, key);
				const keyWhenSerialized = this.transformAndRewriteKeyForSerialization(key);
				json[keyWhenSerialized] = jsonPropertyValue;
			}
		}

		return json;
	}

	private getSubtypeDescriminatorIfPresent(): {key: string, value: string} | undefined
	{
		const parentProfile = this.supertypeSerializationProfile;
		if (parentProfile !== undefined)
		{
			const key = parentProfile.subTypeDiscriminatorProperty;
			if (key === undefined)
				throw new IllegalStateException();
			const value = Array.from(parentProfile.subTypeProfiles.keys())
				.find(discriminatorValue => this === parentProfile.subTypeProfiles.get(discriminatorValue));
			if (value === undefined)
				throw new IllegalStateException();
			return {
				key,
				value,
			};
		}
	}

	private findSubTypeProfile(valueToDeserialize: any): SerializationProfile<any> | undefined
	{
		if (this.subTypeDiscriminatorProperty !== undefined)
		{
			for (const key of Object.keys(valueToDeserialize))
			{
				const transformedKey = this.transformAndRewriteKeyForDeserialization(key);
				if (transformedKey === this.subTypeDiscriminatorProperty)
				{
					let discriminatorValue = valueToDeserialize[key];
					return this.subTypeProfiles.get(discriminatorValue);
				}
			}
		}
	}

	private serializeArray(value: T[]): any[]
	{
		return value.map(value => this.serialize(value));
	}

	private serializeProperty(object: T, key: string & keyof T): any
	{
		const deserializedValue = object[key];
		if (this.profiles.has(key))
			return this.profiles.get(key)!.serialize(deserializedValue);
		else if (this.serializationValueTransformers.has(key))
			return this.serializationValueTransformers.get(key)!(deserializedValue);
		else
			return deserializedValue;
	}

	private transformAndRewriteKeyForDeserialization(key: string): keyof T & string
	{
		this.keyTransformers.forEach(
			transformer =>
				key = transformer(key));

		return this.rewrittenKeys.has(key)
			? this.rewrittenKeys.get(key)
			: key as keyof T & string;
	}

	private transformAndRewriteKeyForSerialization(key: string): string
	{
		for (const keyWhenSerialized of Array.from(this.rewrittenKeys.keys()))
		{
			const keyWhenDeserialized = this.rewrittenKeys.get(keyWhenSerialized);
			if (keyWhenDeserialized === key)
				return keyWhenSerialized;
		}
		return key;
	}
}
