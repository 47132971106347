import { Divider, FormControl, FormControlLabel, FormGroup, makeStyles, Radio, Typography } from '@material-ui/core';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import clsx from 'clsx';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { useValidationContext } from '../../../validation/context/ValidationContext';
import { usePaymentIssuerContext } from '../context/PaymentIssuerContext';

const useStyles = makeStyles(theme => ({
	formControl: {
		paddingTop: theme.spacing(2),
	},
	formControlLabel: {
		flex: '1 1 auto',
	},
	formHelperText: {
		paddingLeft: theme.spacing(3),
	},
	issuerLabelText: {
	},
	issuerRadioButton: {
		paddingBottom: 0,
		paddingTop: 0,
	},
}));

interface PaymentIssuerSelectionFormProps
{
	className?: string;
}

export const PaymentIssuerSelectionForm: FC<PaymentIssuerSelectionFormProps> =
	({
		className,
	}) =>
	{
		const showValidation = useValidationContext();
		const {paymentIssuers, selectedPaymentIssuer, selectPaymentIssuer, validation} = usePaymentIssuerContext();

		const classes = useStyles();

		const hasError = useMemo(
			() => showValidation && validation.length > 0,
			[showValidation, validation.length],
		);

		const formControlClassName = clsx(classes.formControl, className);

		return <FormControl
			className={formControlClassName}
			error={hasError}
			fullWidth
		>
			<FormGroup>
				<List disablePadding>
					<Divider />
					{
						paymentIssuers.map(issuer =>
						{
							return <ListItem key={issuer.id} divider>
								<FormControlLabel
									className={classes.formControlLabel}
									control={
										<Radio
											checked={selectedPaymentIssuer?.id === issuer.id}
											className={classes.issuerRadioButton}
											color="primary"
											onClick={() => selectPaymentIssuer(issuer.id)}
										/>
									}
									label={
										<Typography className={classes.issuerLabelText} variant="body1">
											{issuer.name}
										</Typography>
									}

								/>
							</ListItem>;
						})
					}
				</List>
			</FormGroup>
		</FormControl>;
	};