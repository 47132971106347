import { action, computed, makeObservable, observable } from 'mobx';
import { NotificationOptions } from './NotificationOptions';

export abstract class Notification
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    arePushNotificationsAllowed: boolean = false;
    arePushNotificationsSupported: boolean = false;
    private _initialized: boolean;

    // ------------------------ Constructor -------------------------

    constructor()
    {
        makeObservable<Notification, '_initialized' | 'setInitialized'>(
            this,
            {
                arePushNotificationsAllowed: observable,
                arePushNotificationsSupported: observable,
                _initialized: observable,
                initialized: computed,
                setArePushNotificationsAllowed: action.bound,
                setArePushNotificationsSupported: action.bound,
                setInitialized: action.bound,
            },
        );
        this._initialized = false;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    public get initialized(): boolean
    {
        return this._initialized;
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    public abstract onDismissSnackbar(key?: string): void;

    setArePushNotificationsAllowed(allowed: boolean): void
    {
        this.arePushNotificationsAllowed = allowed;
    }

    setArePushNotificationsSupported(supported: boolean): void
    {
        this.arePushNotificationsSupported = supported;
    }

    abstract notify(notification: NotificationOptions): void;

    // ----------------------- Private logic ------------------------

    protected setInitialized(): void
    {
        this._initialized = true;
    }
}
