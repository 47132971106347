import { ArrowBack, Menu } from '@material-ui/icons';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { WebClient } from '../../../../Bridge/Client/WebClient';
import { useRootContext } from '../../../../RootContext';
import { PageTopBar } from '../../../UI/PageTopBar';
import { MenuStore } from './MenuStore';
import { MenuTopBarAppendix } from './TopBar/MenuTopBarAppendix';

interface MenuTopBarProps
{
	store: MenuStore;
	openNavigationMenu?: () => void;
}

export const MenuTopBar = (props: MenuTopBarProps) =>
{
	const {
		store,
		openNavigationMenu = () => {},
	} = props;
	const inTopLevelMenu = !store.parentMenuStore;

	const {lowPerformanceMode, navigator} = useRootContext(true);

	const containsDialog = useObserver(
		() =>
			navigator.currentDialogs.length > 0
			|| store.bridge.dialog.dialogs.length > 0,
	);

	const useScrollPositionStoredWithScreen = useObserver(
		() =>
			containsDialog
			&& navigator.currentContentScreen.scrollTop !== undefined,
	);

	const currentContentScreen = useObserver(() => navigator.currentContentScreen);
	const webClient = useObserver(() => store.bridge.client as WebClient);

	const topBarColor = useObserver(() =>
	{
		const base = store.brandingService.topBarColor;

		return lowPerformanceMode && inTopLevelMenu
			? base.withAlpha(0.0)
			: base;
	});

	return useObserver(() => <PageTopBar
		topBarColor={topBarColor}
		topBarContrastTextColor={store.brandingService.tintContrastTextColor}
		backgroundContrastTextColor={store.brandingService.backgroundContrastTextColor}
		icon={inTopLevelMenu ? Menu : ArrowBack}
		imageUrl={inTopLevelMenu ? store.brandingService.logoInlineImageUrl : undefined}
		onIconClick={inTopLevelMenu ? () => openNavigationMenu() : store.close}
		scrollYOffset={
			inTopLevelMenu && !lowPerformanceMode
				? (
					(
						!useScrollPositionStoredWithScreen
							? webClient.scrollYOffset
							: currentContentScreen.scrollTop
					) ?? 0
				)
				: undefined
		}
		title={inTopLevelMenu ? undefined : store.category.name}
		sidebarEnabled={!store.shoppingCartStore.isEmpty}
		appendix={
			<MenuTopBarAppendix
				store={store}
			/>
		}
	/>);
};
