import { useEffect, useState } from 'react';

export function useDebounce<T>(value: T, delay: number = 500): T
{
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() =>
	{
		const handler = setTimeout(
			() => setDebouncedValue(value),
			delay,
		);

		return () => clearTimeout(handler);
	}, [delay, value]);

	return debouncedValue;
}