import { SvgIconProps } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HistoryIcon from '@material-ui/icons/History';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { OrderDescriptor } from '../../../../../Api/Order/OrderDescriptor';
import { subtractFrom } from '../../../../../Api/Util/time/Duration';
import { useRootContext } from '../../../../../RootContext';
import { isPaymentTimingOut } from '../../../../../Util/Orders/isPaymentTimingOut';

interface CollapsedHistoryIconProps extends SvgIconProps
{
	mostRecentOrder: OrderDescriptor;
}

export const CollapsedHistoryIcon: FC<CollapsedHistoryIconProps> =
	({
		mostRecentOrder,
		...otherProps
	}) =>
	{
		const {clockService} = useRootContext(true);

		const currentMinute = useObserver(() => clockService.currentMinute);

		const paymentIsTimingOut = useMemo(
			() =>
				isPaymentTimingOut(
					mostRecentOrder,
					subtractFrom(
						{
							seconds: 60,
							nanos: 0,
						},
						currentMinute,
					),
				),
			[currentMinute, mostRecentOrder],
		);

		if (mostRecentOrder?.paymentState === 'failed' || paymentIsTimingOut)
		{
			return <ErrorOutlineIcon
				{...otherProps}
			/>;
		}
		else if (mostRecentOrder?.paymentState === 'negotiated')
		{
			return <TimelapseIcon
				{...otherProps}
			/>;
		}
		else
		{
			return <HistoryIcon
				{...otherProps}
			/>;
		}
	};
