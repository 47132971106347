import { UserRequest } from '../WebSocket/UserRequest';
import { UserRequestType } from '../WebSocket/UserRequestType';

export class ExternalOrdersOnPlaceUserRequest extends UserRequest
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	readonly placeId: number;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor(
		uuid: string,
		clientInstanceUuid,
		placeId: number,
	)
	{
		super(uuid, UserRequestType.EXTERNAL_ORDERS_ON_PLACE_USER_REQUEST, clientInstanceUuid);
		this.placeId = placeId;
	}
}