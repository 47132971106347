import { Box, Collapse, makeStyles, Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem/ListItem';
import { Plus } from 'mdi-material-ui';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { Product } from '../../../../../../Api/Product/Product';
import { ProductConfiguration } from '../../../../../../Api/Product/ProductConfiguration';
import { useRootContext } from '../../../../../../RootContext';
import { useCurrentPlaceService } from '../../../../../current-place-service/CurrentPlaceService';
import { ActionButton } from '../../../../../UI/action-button/ActionButton';
import { BusinessContextRef } from '../../../BusinessContext';
import { computePriceLabel } from '../../../Menu/Api/computePriceLabel';
import { ProductConfigurationStore } from '../../../Product/ProductConfiguration/ProductConfigurationStore';
import { ComoBasketRequirementsProductConfigurationEditor } from './ProductConfigurationEditor/ComoBasketRequirementsProductConfigurationEditor';

const useStyles = makeStyles(theme => ({
	addButton: {
		pointerEvents: 'none',
	},
	nameText: {
		flexGrow: 1,
	},
	root: {
		alignItems: 'center',
		display: 'flex',
		flexGrow: 1,
		gap: theme.spacing(2),
	},
}));

interface ComoBasketRequirementsItemProps
{
	onAdd: (productConfiguration: ProductConfiguration) => void;
	product: Product;
}

export const ComoBasketRequirementsItem: FC<ComoBasketRequirementsItemProps> =
	({
		onAdd,
		product,
	}) =>
	{
		const {bridge, localizer} = useRootContext(true);
		const {businessStore} = useContext(BusinessContextRef);
		const currentPlaceService = useCurrentPlaceService();

		const [isEditorOpen, setIsEditorOpen] = useState(false);
		const [productConfigurationStore, setProductConfigurationStore] = useState<ProductConfigurationStore | undefined>(undefined);

		const business = useObserver(() => businessStore.business);
		const place = useObserver(() => currentPlaceService.place);

		const priceLabel = useMemo(
			() =>
				product === undefined
					? undefined
					: computePriceLabel(product, business, place, localizer),
			[business, localizer, place, product],
		);

		const handleEditorClose = useCallback(
			() =>
				setIsEditorOpen(false),
			[],
		);

		const handleEditorExited = useCallback(
			() =>
				setProductConfigurationStore(undefined),
			[],
		);

		const handleEditorCommit = useCallback(
			(productConfiguration: ProductConfiguration) =>
			{
				onAdd(productConfiguration);
				handleEditorClose();
			},
			[handleEditorClose, onAdd],
		);

		const handleClick = useCallback(
			() =>
			{
				if (product.featureAssignments.length === 0)
				{
					onAdd(new ProductConfiguration(product, []));
				}
				else
				{
					setProductConfigurationStore(
						new ProductConfigurationStore(
							bridge,
							product,
							business.productCurrencyCode,
							async () => handleEditorClose(),
							false,
						),
					);

					setIsEditorOpen(true);
				}
			},
			[bridge, business.productCurrencyCode, handleEditorClose, onAdd, product],
		);
		const classes = useStyles();

		return <>
			<ListItem
				button
				onClick={handleClick}
			>
				<Box
					className={classes.root}
				>
					<Typography
						className={classes.nameText}
					>
						{product.name}
					</Typography>
					{
						priceLabel !== undefined &&
						<Typography
						>
							{priceLabel}
						</Typography>
					}
					<ActionButton
						className={classes.addButton}
						icon={Plus}
						variant="contained"
					/>
				</Box>
			</ListItem>
			<Collapse
				in={isEditorOpen}
				onExited={handleEditorExited}
				mountOnEnter
				unmountOnExit
			>
				<ComoBasketRequirementsProductConfigurationEditor
					productConfigurationStore={productConfigurationStore}
					onCommit={handleEditorCommit}
					onCancel={handleEditorClose}
				/>
			</Collapse>
		</>;
	};
