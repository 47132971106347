import { OrderDescriptor } from '../../../../../../Api/Order/OrderDescriptor';
import { OrderMutationEvent } from '../../../../../../lib/event/order/OrderMutationEvent';
import { useWebSocketQueryRxjs } from '../../../../Business/useWebSocketQueryRxjs';
import { ManagementQuery } from '../../../../Business/WebSocketService';

export function useOrderMutationEventQuery(businessId: number, reloadOrder: (order: OrderDescriptor) => void): void
{
	useWebSocketQueryRxjs<OrderMutationEvent>(
		new ManagementQuery(businessId),
		'OrderMutationEvent',
		notification =>
		{
			if (
				notification.order.paymentMethodId === 'traditional' ||
				notification.order.paymentState === 'paid'
			)
			{
				reloadOrder(notification.order);
			}
		},
	);
}
