import { postJsonAny } from '../../../../../../Util/Api';
import { PaymentSharingProvider } from '../PaymentSharingProviders';

export function postOrderPaymentSharingRequest(
	request: PostOrderPaymentSharingRequest,
): Promise<void>
{
	return postJsonAny(
		'/client/orderPaymentSharingRequest',
		undefined,
		request,
	);
}

interface PostOrderPaymentSharingRequest
{
	orderId: string;
	provider: PaymentSharingProvider;
	totalNumberOfPersons: number;
	url: string;
}
