import UIElement from '@adyen/adyen-web/dist/types/components/UIElement';
import Decimal from 'decimal.js';
import { Order } from '../../Api/Order/Order';
import { Currency } from '../../Api/Other/Currency';
import { Money } from '../../Api/Other/Money';

export function isValidAdyenCheckoutComponent(order: Order, component: UIElement<any>): boolean
{
	const payment = new Money(
		new Currency(order.paymentCurrencyCode),
		order.paymentPrice ?? new Decimal(0),
	);

	const isValidAmount = payment.amountMinor.toNumber() === component?.props.amount.value;
	const isValidCurrency = payment.currency.code === component?.props.amount.currency;

	return isValidAmount
		&& isValidCurrency;
}
