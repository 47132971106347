import { CircularProgress, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FC, useContext } from 'react';
import { ExternalBill } from '../../../../../Api/ExternalBill/ExternalBill';
import { PaymentIssuer } from '../../../../../Api/Payment/PaymentIssuer';
import { PaymentMethodDescriptor } from '../../../../../Api/Payment/PaymentMethodDescriptor';
import { AdyenCheckoutData } from '../../../../../lib/adyen/AdyenCheckoutData';
import { usePaymentPriceContext } from '../../../../UI/payment/price/context/PaymentPriceContext';
import { EntranceContextRef } from '../../../Entrance/EntranceContext';
import { useExternalOrdersContext } from './context/external-orders/ExternalOrdersContext';
import { EmailBillSettlerSegment } from './segments/email/EmailBillSettlerSegment';
import { ExternalBillSettlerSegment } from './segments/external-bill/ExternalBillSettlerSegment';
import { ExternalOrdersBillSettlerSegment } from './segments/external-orders/ExternalOrdersBillSettlerSegment';
import { PaymentMethodsBillSettlerSegment } from './segments/payment-methods/PaymentMethodsBillSettlerSegment';
import { TipBillSettlerSegment } from './segments/tip/TipBillSettlerSegment';

const useStyles = makeStyles((theme: Theme) => ({
	labelContainer: {
		alignItems: 'center',
		display: 'flex',
		marginBottom: theme.spacing(2),
	},
	labelLeft: {
		display: 'flex',
		flexDirection: 'row-reverse',
	},
	priceLabel: {
		marginLeft: `${theme.spacing(2)}px !important`,
		marginRight: `${theme.spacing(2)}px !important`,
	},
	loader: {
		alignSelf: 'center',
		justifySelf: 'center',
	},
}));

interface BillSettlerProps
{
	externalBill?: ExternalBill;
	isInPayment: boolean;
	onPaymentMethodChange: (paymentMethod?: PaymentMethodDescriptor, paymentIssuer?: PaymentIssuer, adyenCheckoutData?: AdyenCheckoutData) => void;
	onPaymentMethodHasErrorChange: (hasError: boolean) => void;
	paymentSessionHasError: boolean;
}

export const BillSettler: FC<BillSettlerProps> =
	({
		 externalBill,
		 isInPayment,
		 onPaymentMethodChange,
		 onPaymentMethodHasErrorChange,
		paymentSessionHasError,
	}) =>
	{
		const externalOrders = useExternalOrdersContext();
		const {total} = usePaymentPriceContext();
		const classes = useStyles();
		const {
			localizer,
		} = useContext(EntranceContextRef);

		if (externalBill?.state === 'Pending')
		{
			return <CircularProgress
				className={classes.loader}
			/>;
		}
		else if (externalBill?.state === 'Open'
			&& externalBill.remainingAmount === 0)
		{
			return <Typography>
				{localizer.translate('Bill-NoOpenBillFound')}
			</Typography>;
		}
		else if (externalBill?.state === 'Open' || (externalBill !== undefined && isInPayment))
		{
			return <>
				<ExternalBillSettlerSegment
					externalBill={externalBill}
				/>
				<EmailBillSettlerSegment />
				<PaymentMethodsBillSettlerSegment
					onChange={onPaymentMethodChange}
					onHasErrorChange={onPaymentMethodHasErrorChange}
					paymentSessionHasError={paymentSessionHasError}
				/>
				<TipBillSettlerSegment />
			</>;
		}
		else if (externalBill?.state === 'Invalid')
		{
			return <Typography>
				{localizer.translate('Bill-Failure')}
			</Typography>;
		}
		else if (externalBill?.state === 'Expired')
		{
			return <Typography>
				{localizer.translate('Bill-Expired')}
			</Typography>;
		}
		else if (externalBill?.state === 'Closed')
		{
			return <Typography>
				{localizer.translate('Bill-Closed')}
			</Typography>;
		}
		else if (!externalBill || externalBill?.state === 'Unsupported')
		{
			if (externalOrders === 'timeout' || externalOrders === 'error')
			{
				return <Typography>
					{localizer.translate('Bill-Failure')}
				</Typography>;
			}
			else if (total.lessThanOrEqualTo(0))
			{
				return <Typography>
					{localizer.translate('Bill-NoOpenBillFound')}
				</Typography>;
			}
			else if (externalOrders === undefined)
			{
				return <CircularProgress
					className={classes.loader}
				/>;
			}
			else
			{
				return <>
					<ExternalOrdersBillSettlerSegment />
					<EmailBillSettlerSegment />
					<PaymentMethodsBillSettlerSegment
						onChange={onPaymentMethodChange}
						onHasErrorChange={onPaymentMethodHasErrorChange}
						paymentSessionHasError={paymentSessionHasError}
					/>
					<TipBillSettlerSegment />
				</>;
			}
		}
	};
