import * as React from 'react';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useRootContext } from '../../../../../../RootContext';
import { useComputed } from '../../../../../../Util/Hooks/useComputed';
import { PageDialog } from '../../../../../UI/PageDialog';
import { BusinessContextRef } from '../../../BusinessContext';
import { ProductRecommendationLists } from '../ProductRecommendationLists';
import { ProductRecommendationStore } from '../ProductRecommendationStore';

interface ProductRecommendationDialogProps
{
	store: ProductRecommendationStore;
}

export const ProductRecommendationDialog: FC<ProductRecommendationDialogProps> =
	(
		{
			store,
		},
	) =>
	{
		const {navigator} = useRootContext(true);
		const {businessStore} = useContext(BusinessContextRef);

		const [open, setOpen] = useState(true);

		const close = useCallback(() => setOpen(false), []);

		const productRecommendationLists = useComputed(
			() => store.productRecommendationLists
				.filter(list => list.moment === 'AFTER_PRODUCT_ADD')
				.filter(list => businessStore.hasVisibleRecommendations(list)),
			[store.productRecommendationLists],
		);

		const dialogContent = useMemo(
			() => <ProductRecommendationLists productRecommendationLists={productRecommendationLists} />,
			[productRecommendationLists],
		);

		return <PageDialog
			fullWidth
			maxWidth="md"
			onClose={close}
			onExited={navigator.popScreen}
			open={open}
			forceScreenMode="modal"
			maxModalHeightCss="60%"
		>
			{dialogContent}
		</PageDialog>;
	};