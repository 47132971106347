import { FlattenedJWSInput, JWSHeaderParameters, JWTPayload, jwtVerify, KeyLike } from 'jose';

export async function validateToken(
	jwkSet: (protectedHeader?: JWSHeaderParameters, token?: FlattenedJWSInput) => Promise<KeyLike>,
	token: string,
): Promise<JWTPayload>
{
	const {payload} = await jwtVerify(
		token,
		jwkSet,
		{
			issuer: process.env.REACT_APP_AUTH_BASE_URL,
			audience: process.env.REACT_APP_AUTH_CLIENT_ID,
			clockTolerance: 60,
		},
	);

	return payload;
}
