import { Button, Icon, makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import * as React from 'react';
import { FC } from 'react';
import { ProductDrawerConfigurationStepForwardButtonHeight } from '../StepForwardButton/ProductDrawerConfigurationStepForwardButton';

const useStyles =
	makeStyles(
		theme => ({
			root: {
				paddingLeft: theme.spacing(6),
				paddingRight: theme.spacing(6),
				paddingTop: theme.spacing(1),
				paddingBottom: theme.spacing(1),
				borderRadius: theme.shape.borderRadius,
				height: ProductDrawerConfigurationStepForwardButtonHeight,
				width: '100%',
				backgroundColor: grey[300],
				color: grey[500],
			},
			label: {
				fontSize: '1.7rem',
				fontWeight: 500,
				display: 'flex',
				alignItems: 'center',
				paddingTop: 5,
				paddingBottom: 4,
			},
		})
	);

interface ProductDrawerConfigurationStepBackwardButtonProps
{
	onClick: () => void;
}

export const ProductDrawerConfigurationStepBackwardButton: FC<ProductDrawerConfigurationStepBackwardButtonProps> =
	(
		{
			onClick,
		},
	) =>
	{
		const classes = useStyles();

		return <Button
			onClick={onClick}
			variant="contained"
			className={classes.root}
		>
			<Typography
				variant="body1"
				className={classes.label}
			>
				<Icon>
					arrow_back
				</Icon>
			</Typography>
		</Button>;
	};
