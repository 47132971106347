import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { PlaceSession } from '../../../../../../Api/Business/PlaceSession';
import { PageDialog } from '../../../../../UI/PageDialog';
import { EntranceContextRef } from '../../../../Entrance/EntranceContext';
import { OpenMenu } from '../OpenMenu';
import { OpenMenuDialogActions } from './OpenMenuDialogActions';

const useStyles = makeStyles(
	() =>
		({
			content:
				{
					display: 'flex',
					flexDirection: 'column',
				},
		}));

export interface OpenMenuDialogProps
{
	onClose: () => void;
	openPlaceSession: (placeSession: PlaceSession) => Promise<void>;
	placeSession: PlaceSession;
}

export const OpenMenuDialog: FC<OpenMenuDialogProps> =
	({
		 onClose,
		 openPlaceSession,
		 placeSession,
	 }) =>
	{
		const classes = useStyles();

		const [open, setOpen] = useState(placeSession !== undefined);
		const [isPlaceSessionLocked, setIsPlaceSessionLocked] = useState();

		useEffect(() => setOpen(placeSession !== undefined), [placeSession]);

		const close = useCallback(() => setOpen(false), []);

		const {notification, openPlace} = useContext(EntranceContextRef);

		const onStart = useCallback(async () => {
			try
			{
				await openPlaceSession(placeSession);
				await openPlace(placeSession.place, isPlaceSessionLocked, false);
			}
			catch
			{
				notification.notify({
					content: 'Opening place session failed',
				});
			}
		}, [isPlaceSessionLocked, notification, openPlace, openPlaceSession, placeSession]);

		return <PageDialog
			classes={{
				content: classes.content,
			}}
			dialogActions={
				<OpenMenuDialogActions
					onStart={onStart}
				/>
			}
			maxWidth="sm"
			onClose={onClose}
			onExited={close}
			open={open}
			title="Open menu"
		>
			<OpenMenu
				onLocked={setIsPlaceSessionLocked}
			/>
		</PageDialog>;
	};
