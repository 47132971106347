import { Collapse, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { useRootContext } from '../../../../../RootContext';
import { useIsKioskMode } from '../../../../../Service/KioskService/Api/useIsKioskMode';
import { useSearchContext } from '../../../../UI/Search/SearchContext';
import { MenuStore } from '../MenuStore';
import { MenuSearchCard } from '../Search/MenuSearchCard';
import { MenuAnnouncements } from './MenuAnnouncements';
import { MenuBusinessInformation } from './MenuBusinessInformation';
import { MenuLogo } from './MenuLogo';
import { OrderWaiterButton } from './OrderWaiterButton';
import { RequestBillButton } from './RequestBillButton';

const useStyle = makeStyles(() => ({
	menuHeadGridContainer: {
		flex: '0 0 auto !important',
	},
}));

interface MenuTopContentProps
{
	onStartPayingBill: () => void;
	store: MenuStore;
}

export const MenuTopContent: FC<MenuTopContentProps> =
	(
		{
			onStartPayingBill,
			store,
		},
	) =>
	{
		const {brandingService} = useRootContext(true);
		const {isSearching, query} = useSearchContext();

		const hasSearchQuery = useMemo(() => isSearching && query !== undefined, [isSearching, query]);

		const announcementsForCategory = useObserver(() => store.announcementsForCategory);
		const inTopLevelMenu = useObserver(() => !store.parentMenuStore);
		const orderWaiter = useObserver(() => store.orderWaiter);
		const orderBillLegacy = useObserver(() => store.orderBill);
		const searchEnabled = useObserver(() => store.place.searchEnabled);
		const useOrderBillLegacy = useObserver(() => store.place.payExternalBillLegacyMode);
		const useSecondaryColor = useObserver(() => brandingService.shouldUseSecondaryColorOnMenu);
		const isOrderBillSupported = useObserver(() => store.place.isOrderBillSupported);
		const isOrderWaiterSupported = useObserver(() => store.place.isOrderWaiterSupported);

		const classes = useStyle();

		const logo = useMemo(() => <MenuLogo />, []);

		const businessInformation = useMemo(() => <MenuBusinessInformation store={store} />, [store]);

		const menuAnnouncements = useMemo(() => {
			if (announcementsForCategory.length === 0)
				return undefined;

			return <MenuAnnouncements store={store} />;
		}, [announcementsForCategory.length, store]);

		const menuSearchCard = useMemo(() => {
			if (!searchEnabled || isSearching)
				return undefined;

			return <MenuSearchCard useSecondaryColor={useSecondaryColor} />;
		}, [isSearching, searchEnabled, useSecondaryColor]);

		const orderWaiterButton = useMemo(() => isOrderWaiterSupported && <OrderWaiterButton item onClick={orderWaiter} />, [isOrderWaiterSupported, orderWaiter]);

		const requestBillButton = useMemo(() => {
			if (!isOrderBillSupported)
				return undefined;

			return <RequestBillButton
				item
				onClick={onStartPayingBill}
				onClickLegacy={orderBillLegacy}
				useLegacy={useOrderBillLegacy}
			/>;
		}, [isOrderBillSupported, onStartPayingBill, orderBillLegacy, useOrderBillLegacy]);

		const isKioskMode = useIsKioskMode();

		if (inTopLevelMenu)
			return <Grid
				item
				xs={12}
				classes={{
					item: classes.menuHeadGridContainer,
				}}
			>
				<Collapse in={!hasSearchQuery} timeout={200}>
					<Grid
						container
						spacing={
							isKioskMode
								? 2
								: 1
						}
					>
						{logo}
						{businessInformation}
						{menuAnnouncements}
						{menuSearchCard}
						{orderWaiterButton}
						{requestBillButton}
					</Grid>
				</Collapse>
			</Grid>;
		else if (menuAnnouncements !== undefined)
			return <Grid
				item
				xs={12}
				classes={{
					item: classes.menuHeadGridContainer,
				}}
			>
				<Grid
					container
					spacing={1}
				>
					{menuAnnouncements}
				</Grid>
			</Grid>;
		else
			return null;
	};
