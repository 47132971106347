import * as React from 'react';
import { createContext, FC, useContext } from 'react';

type Context = boolean;

const ContextRef = createContext<Context>(undefined as never);

interface ValidationContextProviderProps
{
	showValidation: boolean;
}

export const ValidationContextProvider: FC<ValidationContextProviderProps> =
	({
		children,
		showValidation,
	}) =>
	{
		return <ContextRef.Provider value={showValidation}>
			{children}
		</ContextRef.Provider>;
	};

export function useValidationContext(): Context
{
	return useContext(ContextRef);
}