import { Theme, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Decimal from 'decimal.js';
import CircleMultipleOutline from 'mdi-material-ui/CircleMultipleOutline';
import * as React from 'react';
import { FC, useContext, useMemo } from 'react';
import { EntranceContextRef } from '../../../../Entrance/EntranceContext';

const IconSize = 32;

const useStyles = makeStyles(
    (theme: Theme) =>
        ({
            description:
                {
                    alignItems: 'flex-start',
                    display: 'flex',
                },
            descriptionRoot:
                {
                    alignItems: 'center',
                    display: 'flex',
                    minHeight: IconSize,
                },
            descriptionText:
                {
                    marginLeft: theme.spacing(1),
                },
            icon:
                {
                    height: IconSize,
                    width: IconSize,
                },
            priceText:
                {
                    marginLeft: theme.spacing(1),
                },
            root:
                {
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: theme.spacing(0.5),
                },
        }));

export interface OrderListItemPaymentFeeProps
{
    paymentCurrency: string;
    paymentFee: number;
    showIcon?: boolean;
}

export const OrderPaymentFee: FC<OrderListItemPaymentFeeProps> =
    ({
         paymentCurrency,
         paymentFee,
         showIcon,
     }) =>
    {
        const classes = useStyles();

        const {
            localizer,
        } = useContext(EntranceContextRef);

        const paymentFeeDecimal = useMemo(
            () =>
                new Decimal(paymentFee),
            [paymentFee],
        );

        return <div
            className={classes.root}
        >
            <div
                className={classes.descriptionRoot}
            >
                {
                    showIcon &&
                    <CircleMultipleOutline
                        classes={{
                            root: classes.icon,
                        }}
                    />
                }
                <div
                    className={classes.description}
                >
                    <Typography
                        classes={{
                            root: classes.descriptionText,
                        }}
                    >
                        {localizer.translate('Order-Payment-Fee')}
                    </Typography>
                </div>
            </div>
            <Typography
                classes={{
                    root: classes.priceText,
                }}
            >
                {localizer.formatCurrency(paymentFeeDecimal, paymentCurrency)}
            </Typography>
        </div>;
    };