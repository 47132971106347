import { Grid } from '@material-ui/core';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { isTrueAtInCache } from '../../../../../Api/Util/time-series/BooleanTimeSeries/BooleanTimeSeriesCache';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { useRootContext } from '../../../../../RootContext';
import { useTimeSchedulesContext } from '../../../../context/time-schedule/TimeSchedulesContext';
import { useCurrentPlaceService } from '../../../../current-place-service/CurrentPlaceService';
import { useIsRoutingByIdLive } from '../../../../routing-state-provider/useIsRoutingByIdLive';
import { ActionButton } from '../../../../UI/action-button/ActionButton';

interface OrderWaiterButtonProps
{
	item?: boolean;
	onClick: () => void;
}

export const OrderWaiterButton: FC<OrderWaiterButtonProps> =
	(
		{
			item,
			onClick,
		},
	) =>
	{
		const {place} = useCurrentPlaceService();
		const {getTimeSchedule} = useTimeSchedulesContext();
		const {clockService} = useRootContext(true);
		const currentMinute = useObserver(() => clockService.currentMinute);
		const requestWaiterRoutingId = useObserver(() => place?.requestWaiterRoutingId);
		const isRoutingLive = useIsRoutingByIdLive(requestWaiterRoutingId);
		const isRoutingStatusIgnored = useObserver(() => place?.routingStatusIgnored);
		const hasNoRoutingOrRoutingIsLive = requestWaiterRoutingId === undefined || isRoutingLive || isRoutingStatusIgnored;
		const isVisibleInTimeSchedule =
			useMemo(
				() =>
				{
					if (place?.requestWaiterTimeScheduleId === undefined)
					{
						return true;
					}
					else
					{
						const timeSeries = getTimeSchedule(place.requestWaiterTimeScheduleId)?.booleanTimeSeries;

						if (timeSeries === undefined)
						{
							return true;
						}
						else
						{
							return isTrueAtInCache(timeSeries, currentMinute);
						}
					}
				},
				[currentMinute, getTimeSchedule, place.requestWaiterTimeScheduleId]
			);
		const show = hasNoRoutingOrRoutingIsLive && isVisibleInTimeSchedule;
		const translate = useTranslate();
		const button =
			useMemo(
				() =>
				{
					if (show)
					{
						return <ActionButton
							fullWidth
							icon={RoomServiceIcon}
							text={translate('Client-Order-CallWaiter')}
							onClick={onClick}
							variant="paper"
						/>;
					}
					else
					{
						return undefined;
					}
				},
				[onClick, show, translate]
			);

		return useMemo(
			() =>
			{
				if (button === undefined)
				{
					return null;
				}
				else
				{
					if (item)
					{
						return <Grid
							item
							xs={12}
						>
							{button}
						</Grid>;
					}
					else
					{
						return button;
					}
				}
			},
			[button, item]
		);
	};
