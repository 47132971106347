// @ts-nocheck
/* eslint-disable */
import { Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import BlockIcon from '@material-ui/icons/Block';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { useLocalizer } from '../../../../Bridge/Localization/useLocalizer';
import { UIColors } from '../../../../Constants/UIColors';
import { useManagerContext } from '../manager-context';

const useStyles = makeStyles((theme: Theme) => ({
	icon: {
		color: UIColors.negativeHighlight,
		height: 80,
		marginBottom: theme.spacing(1),
		width: 80,
	},
	root: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		justifyContent: 'center',
		padding: theme.spacing(2),
	},
	text: {
		margin: theme.spacing(1),
		textAlign: 'center',
	},
}));

export const ManagerBusinessAccessDenied: FC =
	() =>
	{
		const classes = useStyles();

		const localizer = useLocalizer();

		const {
			waiterService,
			businessWaiterWorksFor,
		} = useManagerContext();

		const role = useObserver(() => waiterService.role);
		const businessWaiterWorksForName = useObserver(() => businessWaiterWorksFor.name);

		if (businessWaiterWorksForName === undefined || role === undefined)
		{
			return <></>;
		}

		return <div
			className={classes.root}
		>
			<BlockIcon
				classes={{
					root: classes.icon,
				}}
			/>
			<Typography
				variant="h5"
				color="textSecondary"
				classes={{
					root: classes.text,
				}}
			>
				{localizer.translate('Backoffice-Page-No-Access')}
			</Typography>
			<Typography
				color="textSecondary"
				classes={{
					root: classes.text,
				}}
			>
				{localizer.translate('Backoffice-Page-No-Access-Hint', role, businessWaiterWorksForName)}
			</Typography>
		</div>;
	};