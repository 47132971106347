import { makeStyles } from '@material-ui/core/styles';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { PaymentState } from '../../../Api/Order/Order';
import { useIsKioskMode } from '../../../Service/KioskService/Api/useIsKioskMode';
import { AppPaymentState } from '../../../Util/application_interface/AppPaymentState';
import { setInApplicationInterface } from '../../../Util/application_interface/setInApplicationInterface';
import { KioskWrapper } from '../../UI/kiosk/KioskWrapper';
import { BusinessStore } from './BusinessStore';
import { MenuWrapper } from './Menu/MenuWrapper';
import { NoPlaceSession } from './NoPlaceSession/NoPlaceSession';
import { OrderPaymentInProgressPage } from './OrderPaymentInProgress/OrderPaymentInProgressPage';
import { useMostRecentOrder } from './OrderPaymentInProgress/useMostRecentOrder';
import { BillSettlerDialog } from './Payment/bill-settler/BillSettlerDialog';
import { ShoppingCartPulser } from './ShoppingCartPulser/ShoppingCartPulser';

const useStyle = makeStyles(() => ({
	menuRoot: {
		display: 'flex',
		flex: '1 0 auto',
		flexDirection: 'column',
	},
}));

interface BusinessProps
{
	store: BusinessStore;
}

export const Business: FC<BusinessProps> =
	(
		{
			store,
		},
	) =>
	{
		const mostRecentOrder = useMostRecentOrder();

		const mostRecentPaymentState = useObserver(() => mostRecentOrder?.paymentState);
		const shouldShowNoOpenPlaceSessionScreen = useObserver(() => store.shouldShowNoOpenPlaceSessionScreen);
		const shouldShowLockedForPaymentTerminalScreen = useObserver(() => store.shouldShowLockedForPaymentTerminalScreen);

		// TODO: Should probably update to more accurately describe the application state
		useEffect(
			() =>
			{
				const paymentState = translatePaymentState(mostRecentPaymentState);

				setInApplicationInterface(
					'paymentState',
					paymentState,
				);

				setInApplicationInterface(
					'appState',
					paymentState === 'Negotiated'
						? 'InPayment'
						: 'Ordering',
				);
			},
			[mostRecentPaymentState],
		);

		if (shouldShowNoOpenPlaceSessionScreen)
		{
			return <NoPlaceSession />;
		}
		else if (shouldShowLockedForPaymentTerminalScreen)
		{
			return <OrderPaymentInProgressPage />;
		}
		else
		{
			return <BusinessContent
				store={store}
			/>;
		}
	};

const BusinessContent: FC<BusinessProps> =
	(
		{
			store,
		},
	) =>
	{
		const classes = useStyle();
		const [showPayBillDialog, setShowPayBillDialog] = useState(false);
		const openPayBillDialog = useCallback(() => setShowPayBillDialog(true), []);
		const closePayBillDialog = useCallback(() => setShowPayBillDialog(false), []);
		const isKioskMode = useIsKioskMode();

		const payBillDialog = useMemo(
			() =>
				showPayBillDialog &&
				<BillSettlerDialog
					onClose={closePayBillDialog}
				/>,
			[closePayBillDialog, showPayBillDialog],
		);

		const menuStore = useObserver(() => store.menuStore);
		const openMenu = useObserver(() => store.openMenu);

		const menu = useMemo(
			() => menuStore !== undefined
				? <div className={classes.menuRoot}>
					<MenuWrapper
						onStartPayingBill={openPayBillDialog}
						openNavigationMenu={openMenu}
						store={menuStore}
					/>
				</div>
				: null,
			[classes.menuRoot, openPayBillDialog, openMenu, menuStore],
		);
		const shoppingCartPulser = <ShoppingCartPulser />;
		const content =
			<>
				{menu}
				{payBillDialog}
				{shoppingCartPulser}
			</>;

		if (menu === null)
		{
			return null;
		}
		else if (isKioskMode)
		{
			return <KioskWrapper>
				{content}
			</KioskWrapper>;
		}
		else
		{
			return content;
		}
	};

function translatePaymentState(orderPaymentState: PaymentState | undefined): AppPaymentState | undefined
{
	if (orderPaymentState === undefined)
	{
		return undefined;
	}
	else
	{
		switch (orderPaymentState)
		{
			case 'failed':
				return 'Failed';
			case 'negotiated':
				return 'Negotiated';
			case 'paid':
				return 'Paid';
			case 'created':
			case 'unpaid':
				return undefined;
		}
	}
}
