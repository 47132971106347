import { useContext } from 'react';
import { Product } from '../../../../../Api/Product/Product';
import { BusinessContextRef } from '../../BusinessContext';
import { BusinessStore } from '../../BusinessStore';

export function useDoesProductHaveConfigurationScreenBeforeAddingItToCart(
	product: Product
): boolean
{
	const {
		businessStore,
	} = useContext(BusinessContextRef);

	return doesProductHaveConfigurationScreenBeforeAddingItToCart(
		product,
		businessStore
	);
}

export function doesProductHaveConfigurationScreenBeforeAddingItToCart(
	product: Product,
	businessStore: BusinessStore
)
{
	const productIsConfigurable = product.isConfigurable;
	const productHasOptionsScreenProductRecommendations = businessStore.hasProductRecommendations(
		product.id,
		'AT_PRODUCT_OPTIONS',
	);

	return productIsConfigurable
		|| productHasOptionsScreenProductRecommendations;
}