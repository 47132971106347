import * as React from 'react';
import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { OrderDescriptor } from '../../../../../../Api/Order/OrderDescriptor';
import { Localizer } from '../../../../../../Bridge/Localization/Localizer';
import { useLocalizer } from '../../../../../../Bridge/Localization/useLocalizer';
import { IllegalStateException } from '../../../../../../Util/Exception/IllegalStateException';
import { showPushNotification } from '../../../../../ServiceWorker/showPushNotification';

interface Context
{
	isSet: true
	notify: (message: string) => void
	notifyOfOrder: (order: OrderDescriptor) => void
}

const ContextRef = createContext({} as Context);

interface OrderHandlerNotificationProviderProps
{
}

export const OrderHandlerNotificationProvider: FC<OrderHandlerNotificationProviderProps> =
	(
		{
			children,
		},
	) =>
	{
		const localizer = useLocalizer();
		const notify = useOrderHandlerDeviceNotificationCallback();
		const notifyOfOrder = useNewOrderDeviceNotificationCallback(localizer, notify);
		return <ContextRef.Provider
			value={useMemo(() => ({
				isSet: true,
				notify,
				notifyOfOrder,
			}), [notify, notifyOfOrder])}
		>
			{children}
		</ContextRef.Provider>;
	};

function useOrderHandlerDeviceNotificationCallback()
{
	return useCallback(
		(message: string) => showPushNotification({
			body: message,
			data: {
				url: window.location.origin + '/manager/order-handler',
			},
		}),
		[],
	);
}

function useNewOrderDeviceNotificationCallback(localizer: Localizer, notify: (message: string) => void)
{
	return useCallback(
		(order: OrderDescriptor) =>
			notify(
				localizer.translate(
					'Manager-Notification-New-Order-Products-Free',
					order.orderingLocationName,
				),
			),
		[localizer, notify],
	);
}

export function useOrderHandlerNotify()
{
	const {isSet, notify} = useContext(ContextRef);
	return useMemo(
		() => {
			if (!isSet)
				throw new IllegalStateException(
					'Attempting to use orders outside OrderHandlerOrderProvider'
				);
			return notify;
		},
		[isSet, notify]
	);
}

export function useOrderHandlerNotifyOfOrder()
{
	const {isSet, notifyOfOrder} = useContext(ContextRef);
	return useMemo(
		() => {
			if (!isSet)
				throw new IllegalStateException(
					'Attempting to use orders outside OrderHandlerOrderProvider'
				);
			return notifyOfOrder;
		},
		[isSet, notifyOfOrder]
	);
}
