import { makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { FC } from 'react';

const useStyles =
	makeStyles(
		() => ({
			root: {
				fontSize: '1.3rem',
				fontWeight: 600,
				color: 'rgba(0,0,0,0.45)',
			},
		})
	);

interface ProductDrawerPriceProps
{

}

export const ProductDrawerPrice: FC<ProductDrawerPriceProps> =
	(
		{
			children,
		},
	) =>
	{
		const classes = useStyles();

		return <Typography
			variant="body1"
			className={classes.root}
		>
			{children}
		</Typography>;
	};
