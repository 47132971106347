import { ButtonBase, Typography, WithStyles } from '@material-ui/core';
import * as React from 'react';
import { CSSProperties, FC, ReactNode } from 'react';
import { Color } from '../../../../Api/Other/Color';
import { ExtendedMenuEntryType } from '../../../../Api/Product/ExtendedMenuEntryType';
import { MediaButtonBadges } from '../../media_button/MediaButtonBadges';
import { MediaButtonV2 } from '../../media_button_v2/MediaButtonV2';
import { menuEntryStyles } from '../MenuEntry';

export interface TileWithoutImageProps
{
	primaryBadges?: ReactNode[];
	secondaryBadges?: ReactNode[];
	minHeight?: number | string;
	onClick?: () => void;
	price?: string;
	title: string;
	type?: ExtendedMenuEntryType;
	textHorizontallyCenteredWhenNoPictureAndTile?: boolean;
	textColor?: Color;
	backgroundColor: Color;
	disabled?: boolean;
}

const mediaButtonV2Style: CSSProperties = {
	flexGrow: 1,
};

export const TileWithoutImage: FC<TileWithoutImageProps & WithStyles<typeof menuEntryStyles>> =
	({
		minHeight = 0,
		onClick,
		primaryBadges,
		secondaryBadges = [],
		title,
		price,
		textHorizontallyCenteredWhenNoPictureAndTile,
		classes,
		textColor,
		backgroundColor,
		type,
		disabled,
	}) =>
	{
		if (type === 'TILE_V2')
		{
			return <MediaButtonV2
				primaryBadges={primaryBadges}
				secondaryBadges={secondaryBadges}
				imageBackgroundColor={backgroundColor}
				onClick={onClick}
				secondaryTitle={price}
				style={mediaButtonV2Style}
				title={title}
				textBackdropColor={backgroundColor}
				textColor={textColor}
				disabled={disabled}
			/>;
		}
		else
		{
			return <ButtonBase
				focusRipple
				className={classes.root}
				style={{
					minHeight: minHeight,
					backgroundColor: backgroundColor.css,
				}}
				onClick={onClick}
				disabled={disabled}
			>
				<span
					className={classes.textOnlyTile}
					style={{
						textAlign: textHorizontallyCenteredWhenNoPictureAndTile ? 'center' : 'left'
					}}
				>
					<MediaButtonBadges
						primaryBadges={primaryBadges ?? []}
						secondaryBadges={secondaryBadges ?? []}
					/>
					<Typography
						variant="h6"
						className={classes.title}
						style={{
							color: textColor.css,
							marginBottom: 4,
						}}
					>
						{title}
					</Typography>
					<Typography
						variant="body1"
						className={classes.price}
						style={{
							color: textColor.css,
						}}
					>
						{price}
					</Typography>
				</span>
			</ButtonBase>;
		}
	};
