import { OpenInNew } from '@material-ui/icons';
import * as React from 'react';
import { FC, useContext } from 'react';
import { OrderDescriptor } from '../../../../../../Api/Order/OrderDescriptor';
import { ActionButton } from '../../../../../UI/action-button/ActionButton';
import { EntranceContextRef } from '../../../../Entrance/EntranceContext';

interface ShowOrderTimelineButtonProps
{
	onOpenOrderEvents: (orderId: number) => void;
	order: OrderDescriptor;
}

export const ShowOrderTimelineButton: FC<ShowOrderTimelineButtonProps> =
	({
		onOpenOrderEvents,
		order,
	}) =>
	{
		const {localizer} = useContext(EntranceContextRef);

		return <ActionButton
			icon={OpenInNew}
			onClick={() => onOpenOrderEvents(order.id)}
			text={localizer.translate('Order-Open-Timeline')}
		/>;
	};