import * as React from 'react';
import { FC } from 'react';
import { Order } from '../../../../../Api/Order/Order';
import { PaymentDialogStartAdyenPaymentButton } from '../start_payment_button/PaymentDialogStartAdyenPaymentButton';
import { PaymentDialogStartPayPalPaymentButton } from '../start_payment_button/PaymentDialogStartPayPalPaymentButton';
import { PaymentDialogStartPaymentLabel } from '../start_payment_Label/PaymentDialogStartPaymentLabel';

interface PaymentDialogOrderContentProps
{
	order: Order;
}

export const PaymentDialogOrderContent: FC<PaymentDialogOrderContentProps> =
	({
		order,
	}) =>
	{
		return <>
			<PaymentDialogStartPaymentLabel
				order={order}
			/>
			{
				order.paymentMethodId === 'PayPal'
					?
					<PaymentDialogStartPayPalPaymentButton
						order={order}
					/>
					:
					order.paymentMethodId !== undefined &&
					<PaymentDialogStartAdyenPaymentButton
						order={order}
						paymentMethod={order.paymentMethodId}
					/>
			}
		</>;
	};
