import { Box, Grid, GridSize } from '@material-ui/core';
import * as React from 'react';
import { FC } from 'react';
import { MediaButtonV2BorderRadius, MediaButtonV2Shadow } from '../../../../../UI/media_button_v2/MediaButtonV2';
import { useSpacingBetweenFullscreenMenuGridColumns } from '../Api/useSpacingBetweenFullscreenMenuGridColumns';
import { MixedGridItem } from '../MixedGrid/MixedGridItem';

interface FixedGridProps
{
	nrOfColumns: number;
	items: MixedGridItem[];
}

export const FixedGrid: FC<FixedGridProps> =
	(
		{
			nrOfColumns,
			items,
		},
	) =>
	{
		const itemWidth = (12 / Math.min(nrOfColumns, 4)) as GridSize;
		const spacing = useSpacingBetweenFullscreenMenuGridColumns();

		return <Box
			paddingY={spacing / 2}
			margin={-(spacing / 2)}
		>
			<Grid
				container
				spacing={0}
			>
				{
					items.map(
						item =>
							<Grid
								key={item.key}
								item
								xs={itemWidth}
							>
								<Box
									padding={spacing / 2}
								>
									<div
										style={{
											boxShadow: MediaButtonV2Shadow,
											borderRadius: MediaButtonV2BorderRadius,
										}}
									>
										{
											React.createElement(item.node)
										}
									</div>
								</Box>
							</Grid>
					)
				}
			</Grid>
		</Box>;
	};
