import { BaseStore } from '@intentic/ts-foundation';

export class AgeVerificationVoucherStore extends BaseStore
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	readonly token: string;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor(token: string)
	{
		super();

		this.token = token;
	}
}
