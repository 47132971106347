import UIElement from '@adyen/adyen-web/dist/types/components/UIElement';
import { useCallback } from 'react';
import { Order } from '../../../../Api/Order/Order';
import { useTranslate } from '../../../../Bridge/Localization/useTranslate';
import { isPaymentData } from '../../../../lib/adyen/AdyenCheckoutData';
import { isValidAdyenCheckoutComponent } from '../../../../Util/payment/isValidAdyenCheckoutComponent';
import { ADYEN_CHECKOUT_APPLE_PAY_TYPE } from '../adyen/checkout/AdyenCheckoutContext';
import { OrderPaymentProcessingResult } from '../model/OrderPaymentProcessingResult';

type ProcessAdyenCheckoutOrderPaymentHandler = (
	order: Order,
	component?: UIElement<any>,
) => Promise<OrderPaymentProcessingResult>

export function useProcessAdyenCheckoutOrderPayment(): ProcessAdyenCheckoutOrderPaymentHandler
{
	const translate = useTranslate();

	return useCallback(
		async (
			order: Order,
			component?: UIElement<any>,
		) =>
		{
			if (component !== undefined)
			{
				if (isPaymentData(component.data) && component.data.paymentMethod.type === ADYEN_CHECKOUT_APPLE_PAY_TYPE)
				{
					return {
						component,
						requiresExtraUserInteraction: true,
						state: 'Pending',
					};
				}
				else if (isValidAdyenCheckoutComponent(order, component))
				{
					component.submit();

					return {
						component,
						requiresExtraUserInteraction: false,
						state: 'Pending',
					};
				}
				else
				{
					alert(translate('Client-Notification-Order-Payment-IsFailed'));

					throw new Error(
						`Failed to process Adyen Checkout payment: Invalid payment component with amount "${component?.props.amount.currency} ${component?.props.amount.value}" for order ${order.uuid}`,
					);
				}
			}
			else
			{
				throw new Error(
					`Failed to process Adyen Checkout payment: No active payment component`,
				);
			}
		},
		[translate],
	);
}
