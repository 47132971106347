import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { FC, useCallback } from 'react';
import { useTranslation } from '../../../../Bridge/Localization/useTranslation';
import { PageDialog } from '../../PageDialog';
import { useOrderPaymentContext } from '../context/OrderPaymentContext';
import { PaymentDialogActionContent } from './action_content/PaymentDialogActionContent';
import { PaymentDialogOrderContent } from './order_content/PaymentDialogOrderContent';

const useStyles = makeStyles({
	content: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		justifyContent: 'space-around',
		marginTop: '0px !important',
		minHeight: 200,
		padding: 20,
		textAlign: 'center',
	},
});

interface PaymentDialogProps
{
	onClose: () => void;
}

export const PaymentDialog: FC<PaymentDialogProps> =
	({
		onClose,
	}) =>
	{
		const {manualPaymentAction, orderInProgress, requiresExtraUserInteraction} = useOrderPaymentContext();

		const classes = useStyles();

		const handleClose = useCallback((reason?: 'backdropClick' | 'escapeKeyDown') =>
		{
			if (reason === undefined)
				return onClose();
		}, [onClose]);

		const title = useTranslation('Payment-Dialog-Title');

		return <PageDialog
			classes={classes}
			hideCloseButton
			title={title}
			onClose={(event, reason) => handleClose(reason)}
			open={requiresExtraUserInteraction}
		>
			{
				orderInProgress !== undefined &&
				manualPaymentAction === undefined &&
				<PaymentDialogOrderContent
					order={orderInProgress}
				/>
			}
			{
				manualPaymentAction !== undefined &&
				<PaymentDialogActionContent
					action={manualPaymentAction}
				/>
			}
		</PageDialog>;
	};