import { useMemo } from 'react';
import { Color } from '../../../../Api/Other/Color';

export function useEffectiveIsDarkTextColor(
	isDark: boolean | undefined,
	textColor: Color | undefined,
	textBackdropColor: Color | undefined
)
{
	return useMemo(
		() =>
		{
			if (isDark === undefined)
			{
				if (textColor === undefined)
				{
					if (textBackdropColor === undefined)
					{
						return true;
					}
					else
					{
						return !textBackdropColor.isDark;
					}
				}
				else
				{
					return textColor.isDark;
				}
			}
			else
			{
				return isDark;
			}
		},
		[isDark, textBackdropColor, textColor]
	);
}