import { makeStyles, Typography } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from '../../../../../Bridge/Localization/useTranslation';
import { useCurrentOrderService } from '../../BusinessContext';

const useStyles = makeStyles({
	label: {
		marginRight: 4,
	},
});

export const SchedulerSummaryLabel: FC =
	() =>
	{
		const currentOrderService = useCurrentOrderService(true);

		const classes = useStyles();

		const effectiveDestinationType = useObserver(() => currentOrderService.effectiveDestinationType);

		return <Typography
			className={classes.label}
		>
			{
				useTranslation(effectiveDestinationType === 'PICKUP_POINT'
					? 'Order-Pickup-Time'
					: 'Order-Delivery-Time',
				)
			}
		</Typography>;
	};
