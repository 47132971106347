import { FormControlLabel, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FC, useCallback } from 'react';
import { PaymentMethodDescriptor } from '../../../../../../../Api/Payment/PaymentMethodDescriptor';
import { PaymentMethodImage } from './PaymentMethodImage';
import { PaymentMethodLabel } from './PaymentMethodLabel';

const useStyles = makeStyles(() => ({
	control: {
		paddingBottom: 0,
		paddingTop: 0,
	},
	form: {
		flex: '1 1 auto',
	},
	formRoot: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'flex-start',
	},
	root: {
		alignItems: 'stretch',
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
}));

export interface PaymentMethodOptionProps
{
	descriptor: PaymentMethodDescriptor;
	onSelect: (descriptor: PaymentMethodDescriptor) => void;
	selected: boolean;
}

export const PaymentMethodOption: FC<PaymentMethodOptionProps> =
	({
		descriptor,
		onSelect,
		selected,
	}) =>
	{
		const classes = useStyles();

		const handleSelect = useCallback(() =>
		{
			onSelect?.(descriptor);
		}, [descriptor, onSelect]);

		return <div className={classes.root}>
			<div className={classes.formRoot}>
				<FormControlLabel
					className={classes.form}
					control={
						<Radio
							className={classes.control}
							checked={selected}
							color="primary"
							onChange={handleSelect}
						/>
					}
					label={<PaymentMethodLabel paymentMethod={descriptor} />}
				/>
				<PaymentMethodImage paymentMethod={descriptor} />
			</div>
		</div>;
	};