import { makeStyles } from '@material-ui/core';
import { formatISO } from 'date-fns';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useState } from 'react';
import { SchedulerDaySelect } from './day_select/SchedulerDaySelect';
import { SchedulerLabel } from './label/SchedulerLabel';
import { SchedulerPhoneNumber } from './phone_number/SchedulerPhoneNumber';
import { SchedulerStore } from './SchedulerStore';
import { SchedulerTimeSelect } from './time_select/SchedulerTimeSelect';
import { SchedulerTimeSelectOption } from './time_select/SchedulerTimeSelectOption';
import { TimeSlot, toTimeSlot } from './TimeSlot';
import { useAllowedOrderingDays } from './useAllowedOrderingDays';

const useStyles = makeStyles({
	root: {
		marginBottom: 8,
		marginLeft: 24,
		marginRight: 24,
		marginTop: 8,
	},
});

export interface SchedulerProps
{
	store: SchedulerStore;
	showValidation: boolean;
}

export const Scheduler: FC<SchedulerProps> =
	({
		showValidation,
		store,
	}) =>
	{
		const classes = useStyles();

		const availableDays = useAllowedOrderingDays();

		const [day, setDay] = useState<string>(
			() =>
				availableDays[0]
				?? formatISO(
					new Date(),
					{
						representation: 'date',
					},
				),
		);

		const [time, setTime] = useState<'ASAP' | TimeSlot | undefined>();

		const requirePhoneNumber = useObserver(() => store.requirePhoneNumber);
		const onChangeScheduledTime = useObserver(() => store.onChangeScheduledTime);

		const handleDayChange = useCallback(
			(day: string) =>
			{
				setDay(day);
				setTime(undefined);
			},
			[],
		);

		const handleTimeChange = useCallback(
			(value: SchedulerTimeSelectOption | undefined) =>
			{
				if (value === undefined)
				{
					setTime(undefined);
					onChangeScheduledTime(undefined);
				}
				else if (value === 'ASAP')
				{
					setTime('ASAP');
					onChangeScheduledTime('ASAP');
				}
				else
				{
					const timeSlot = toTimeSlot(value);

					setTime(timeSlot);
					onChangeScheduledTime(timeSlot.start);
				}
			},
			[onChangeScheduledTime],
		);

		return <div
			className={classes.root}
		>
			<SchedulerLabel />
			{
				availableDays.length > 1 &&
				<SchedulerDaySelect
					availableDays={availableDays}
					onChange={handleDayChange}
					value={day}
				/>
			}
			<SchedulerTimeSelect
				day={day}
				onChange={handleTimeChange}
				showValidation={showValidation}
				store={store}
				value={time}
			/>
			{
				requirePhoneNumber &&
				<SchedulerPhoneNumber
					showValidation={showValidation}
					store={store}
				/>
			}
		</div>;
	};
