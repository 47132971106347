import { toJS } from 'mobx';
import { useEffect, useMemo, useRef, useState } from 'react';
import { PaymentMethodDescriptor } from '../../../../../Api/Payment/PaymentMethodDescriptor';
import { PaymentTiming } from '../../model/PaymentTiming';
import { usePaymentPriceContext } from '../../price/context/PaymentPriceContext';
import { getPaymentMethods } from '../api/getPaymentMethods';

export function useRequestAvailablePaymentMethods(
	timing: PaymentTiming,
): PaymentMethodDescriptor[] | undefined
{
	const {base, discount, productFees, deliveryFee, serviceFee, total} = usePaymentPriceContext();

	const [paymentMethods, setPaymentMethods] = useState<PaymentMethodDescriptor[] | undefined>(undefined);

	const didUnmount = useRef(false);

	const baseAmount = useMemo(
		() =>
			base
				.sub(discount)
				.add(productFees)
				.add(deliveryFee)
				.toNumber(),
		[base, deliveryFee, discount, productFees],
	);

	const supplementaryAmount = useMemo(
		() => total
			.sub(baseAmount)
			.sub(serviceFee)
			.toNumber(),
		[baseAmount, serviceFee, total],
	);

	useEffect(() =>
	{
		didUnmount.current = false;

		return () =>
		{
			didUnmount.current = true;
		};
	}, []);

	useEffect(
		() =>
		{
			if (baseAmount > 0 || supplementaryAmount > 0)
			{
				getPaymentMethods(timing, baseAmount, supplementaryAmount)
					.then(newPaymentMethods =>
					{
						const sortedPaymentMethods = putPaymentMethodsWithIdFirst(toJS(newPaymentMethods), 'iDeal');

						if (!didUnmount.current)
							setPaymentMethods(sortedPaymentMethods);
					});
			}
		},
		[baseAmount, supplementaryAmount, timing],
	);

	return paymentMethods;
}

// TODO (BLR-2434): Should be replaced by some persisted ordering system, probably relating to a business
function putPaymentMethodsWithIdFirst(paymentMethods: PaymentMethodDescriptor[], paymentMethodId: string): PaymentMethodDescriptor[]
{
	const hasAnyPaymentMethodsWithId = paymentMethods.findIndex(peer => peer.id === paymentMethodId) !== -1;

	if (hasAnyPaymentMethodsWithId)
	{
		return [
			...paymentMethods.filter(paymentMethod => paymentMethod.id === paymentMethodId),
			...paymentMethods.filter(paymentMethod => paymentMethod.id !== paymentMethodId),
		];
	}
	else
	{
		return paymentMethods;
	}
}
