import getSymbolFromCurrency from 'currency-symbol-map';
import { differenceInCalendarDays, format, isSameDay, isSameYear } from 'date-fns';
import enUS from 'date-fns/locale/en-US/index.js';
import Decimal from 'decimal.js';
import { action, computed, makeObservable, observable } from 'mobx';
import { ReactElement } from 'react';
import { LanguageEntryTranslations } from '../../Api/Other/LanguageEntryTranslations';
import { capitalizeFirstLetterOfEachWord } from '../../Api/Util/capitalizeFirstLetterOfEachWord';
import { setDefaultApiHeader } from '../../Util/Api/setDefaultApiHeader';
import { IllegalArgumentException } from '../../Util/Exception/IllegalArgumentException';
import { Locale } from './Locale';

const supportedLanguages = ['da', 'de', 'en', 'es', 'fr', 'it', 'nl', 'pl', 'sv'];
const translationMap = new Map<string, { [s: string]: string }>();

translationMap.set(
    'da',
    require(`./Translation/da.json`));

translationMap.set(
    'de',
    require(`./Translation/de.json`));

translationMap.set(
    'en',
    require(`./Translation/en.json`));

translationMap.set(
    'es',
    require(`./Translation/es.json`));

translationMap.set(
    'fr',
    require(`./Translation/fr.json`));

translationMap.set(
    'it',
    require(`./Translation/it.json`));

translationMap.set(
    'nl',
    require(`./Translation/nl.json`));

translationMap.set(
    'sv',
    require(`./Translation/sv.json`));

translationMap.set(
    'pl',
    require(`./Translation/pl.json`));

export const fallbackLocale = new Locale('en-US', 'US', 'en', enUS);

export function substituteTranslationArgumentPlaceholders(translation: string, args: string[]): string
{
    if (translation !== undefined)
    {
        args.forEach(
            arg =>
            {
                // Replacement of %0, %1, %2, etc.
                translation = translation!
                    .replace(
                        /%@/,
                        arg
                            ? arg.replace(/\$/g, '$$$$')
                            : arg,
                    );
            });
    }
    return translation;
}

export abstract class Localizer
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    locale: Locale | undefined;
    private readonly fallbackLocale: Locale;
    numberFormatByCurrencyCode = observable.map<string, Intl.NumberFormat>();

    // ------------------------ Constructor -------------------------

    protected constructor()
    {
        makeObservable<Localizer, 'addNumberFormat' | 'languageMap' | 'fallbackLanguageMap' | 'fallbackLanguage'>(
            this,
            {
                locale: observable,
                numberFormatByCurrencyCode: observable,
                supportedLanguage: computed,
                capitalizeNouns: computed,
                formatCurrency: action.bound,
                formatDateTimeFamiliar: action.bound,
                formatShortTimeString: action.bound,
                addNumberFormat: action.bound,
                languageMap: computed,
                fallbackLanguageMap: computed,
                fallbackLanguage: computed,
                pickTranslation: action.bound,
                hasTranslation: action.bound,
                translate: action.bound,
                getLanguageThatWouldBeUsedFor: action.bound,
                translateWithComponents: action.bound,
                formatNumber: action.bound,
                getCurrencySymbol: action.bound,
                getDecimalSeparator: action.bound,
                getThousandSeparator: action.bound,
                formatDateFamiliar: action.bound,
                formatNumericDate: action.bound,
                formatNumericTimeDownToSeconds: action.bound,
                formatLocalTime: action.bound,
            },
        );
        this.fallbackLocale = fallbackLocale;
    }

    // ----------------------- Initialization -----------------------

    async initialize(): Promise<void>
    {
        try
        {
            const localeId = this.findLocaleId();

            if (localeId === undefined)
            {
                this.setLocale(fallbackLocale)
            }
            else
            {
                setApiHeaders(localeId);

                this.setLocale(
                    await this.resolveLocale(localeId)
                );
            }
        }
        catch (e)
        {
            this.setLocale(fallbackLocale);
        }
    }

    // -------------------------- Computed --------------------------

    /**
     * The effective language used, given the {@link locale} and {@link fallbackLocale}.
     */
    get supportedLanguage(): string
    {
        if (this.locale !== undefined
            && supportedLanguages.includes(this.locale.languageCode))
        {
            return this.locale.languageCode;
        }
        else
        {
            return this.fallbackLocale.languageCode;
        }
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    abstract setLocale(locale: Locale): void;

    // ------------------------ Public logic ------------------------

	/**
	 * Returns {@code true} if {@link supportedLanguage} is a language where nouns are capitalized.
	 */
	public get capitalizeNouns(): boolean
	{
		return this.supportedLanguage === 'de';
	}

	public pickTranslation(translations: LanguageEntryTranslations): string | undefined
    {
        return translations[this.supportedLanguage] ?? translations[this.fallbackLanguage];
    }

    public hasTranslation(translationKey: string): boolean
    {
        return this.languageMap[translationKey] !== undefined
            ? true
            : this.fallbackLanguageMap[translationKey] !== undefined;
    }

    public translate(translationKey: string, ...args: string[]): string
    {
        let translation = this.languageMap[translationKey] !== undefined
            ?
            this.languageMap[translationKey]
            :
            this.fallbackLanguageMap[translationKey];
        translation = substituteTranslationArgumentPlaceholders(translation, args);

        return translation || translationKey;
    }

    getLanguageThatWouldBeUsedFor(...translationKeys: string[]): string | undefined
    {
        function forAll<T>(items: T[], test: (item: T) => boolean)
        {
            for (const item of items)
            {
                if (!test(item))
                    return false;
            }
            return true;
        }
        if (forAll(translationKeys, translationKey => this.languageMap[translationKey] !== undefined))
            return this.supportedLanguage;
        else if (forAll(translationKeys, translationKey => this.fallbackLanguageMap[translationKey] !== undefined))
            return this.fallbackLanguage;
        else
            return undefined;
    }

    translateWithComponents(translationKey: string, ...args: ReactElement[]): (ReactElement | string)[]
    {
        let translation = this.languageMap[translationKey] !== undefined
            ?
            this.languageMap[translationKey]
            :
            this.fallbackLanguageMap[translationKey];
        if (translation !== undefined)
        {
            const translationStrings = translation.split('%@');
            if (translationStrings.length !== args.length + 1)
            {
                throw new IllegalArgumentException();
            }
            const result: (string | ReactElement)[] = [];
            for (let i = 0; i < args.length; i++)
            {
                result.push(translationStrings[i]);
                result.push(args[i]);
            }
            result.push(translationStrings[translationStrings.length - 1]);
            return result;
        }

        return [translationKey];
    }

    formatNumber(value: Decimal)
    {
        if (value == null)
        {
            return null;
        }
        else
        {
            return value.toNumber().toLocaleString();
        }
    }

    formatCurrency(value: Decimal | null | undefined,
                   currencyCode: string): string | null
    {
        return value == null
               ?
               null
               :
               this
                   .getNumberFormat(currencyCode)
                   .format(value.toNumber());
    }

    formatCurrencyWithoutSymbol(
        value: Decimal,
        currencyCode: string
    ): string | null
    {
        return value == null
            ?
            null
            :
            this
                .getNumberFormat(currencyCode)
                .formatToParts(value.toNumber())
                .filter(part => part.type !== 'currency')
                .reduce((result, part) => result + part.value, '')
                .trim();
    }

    /**
     * Get the currency symbol of the provided currency code
     *
     * @param currencyCode the currency code to get the symbol for
     * @return symbol as string iff found
     */
    getCurrencySymbol(currencyCode: string): string | undefined
    {
        try
        {
            return this.getNumberFormat(currencyCode)
                .formatToParts(new Decimal(0).toNumber())
                .find(parts => parts.type === 'currency')
                ?.value;
        }
        catch (e)
        {
            return getSymbolFromCurrency(currencyCode);
        }
    }

    /**
     * Get the number of fraction digits for a given currency symbol of the provided currency code
     *
     * @param currencyCode the currency code to get the fraction digits for
     * @return symbol as string iff found
     */
    getCurrencyFractionDigits(currencyCode: string): number
    {
        try
        {
            return this.getNumberFormat(currencyCode)
                .resolvedOptions().maximumFractionDigits;
        }
        catch (e)
        {
            return 0;
        }
    }

    /**
     * Get the decimal separator of the provided currency code
     *
     * @param currencyCode the currency code to get the symbol for
     * @return symbol as string iff found
     */
    getDecimalSeparator(currencyCode: string): string | undefined
    {
        try
        {
            return this.getNumberFormat(currencyCode)
                .formatToParts(new Decimal(0).toNumber())
                .find(parts => parts.type === 'decimal')
                ?.value;
        }
        catch (e)
        {
            return '.';
        }
    }

    /**
     * Get the thousand separator of the provided currency code
     *
     * @param currencyCode the currency code to get the symbol for
     * @return symbol as string iff found
     */
    getThousandSeparator(currencyCode: string): string | undefined
    {
        try
        {
            return this.getNumberFormat(currencyCode)
                .formatToParts(new Decimal(0).toNumber())
                .find(parts => parts.type === 'group')
                ?.value;
        }
        catch (e)
        {
            return '.';
        }
    }

    formatDateTimeFamiliar(date: Date): string
    {
        const now = new Date();

        if (isSameDay(now, date))
        {
            return capitalizeFirstLetterOfEachWord(
                format(
                    date,
                    'p',
                    {
                        locale: this.locale.dateFnsLocale,
                    },
                ),
            );
        }
        else if (Math.abs(differenceInCalendarDays(now, date)) < 7)
        {
            return capitalizeFirstLetterOfEachWord(
                format(
                    date,
                    'EEEE, p',
                    {
                        locale: this.locale.dateFnsLocale,
                    },
                ),
            );
        }
        else if (isSameYear(now, date))
        {
            return capitalizeFirstLetterOfEachWord(
                format(
                    date,
                    'EEEE, d MMMM, p',
                    {
                        locale: this.locale.dateFnsLocale,
                    },
                ),
            );
        }
        else
        {
            return capitalizeFirstLetterOfEachWord(
                format(
                    date,
                    'EEEE, d MMMM y, p',
                    {
                        locale: this.locale.dateFnsLocale,
                    },
                ),
            );
        }
    }

    formatLocalTime(date: Date): string
    {
        return format(
            date,
            'p',
            {
                locale: this.locale.dateFnsLocale,
            },
        );
    }

    formatDateFamiliar(date: Date): string
    {
        const now = new Date();

        if (isSameDay(now, date))
        {
            return this.translate('DateTime-Today');
        }
        else if (Math.abs(differenceInCalendarDays(now, date)) < 7)
        {
            return capitalizeFirstLetterOfEachWord(
                format(
                    date,
                    'iiii',
                    {
                        locale: this.locale.dateFnsLocale,
                    },
                ),
            );
        }
        else if (isSameYear(now, date))
        {
            return capitalizeFirstLetterOfEachWord(
                format(
                    date,
                    'EEEE, d MMMM',
                    {
                        locale: this.locale.dateFnsLocale,
                    },
                ),
            );
        }
        else
        {
            return capitalizeFirstLetterOfEachWord(
                format(
                    date,
                    'EEEE, d MMMM y',
                    {
                        locale: this.locale.dateFnsLocale,
                    },
                ),
            );
        }
    }

    formatNumericDate(date: Date): string
    {
        return format(
            date,
            'P',
            {
                locale: this.locale.dateFnsLocale,
            },
        );
    }

    formatNumericTimeDownToSeconds(date: Date): string
    {
        return format(
            date,
            'pp',
            {
                locale: this.locale.dateFnsLocale,
            },
        );
    }

    formatShortTimeString(seconds: number): string
    {
        let timeString = '';

        if (!isNaN(seconds))
        {
            // If within 1 minute
            if (seconds < 60)
            {
                timeString = `${seconds} ${this.translate('Time-Second-Short')}`;
            }
            // If within 1 hour
            else if (seconds < 3600)
            {
                timeString = `${Math.floor(seconds / 60)} ${this.translate('Time-Minute-Short')}`;
            }
            // If within 1 day
            else if (seconds < 86400)
            {
                const hours = Math.floor(seconds / 3600);
                timeString = `${hours} ${this.translate(`Time-Hour${hours > 1 ? 's' : ''}`)}`;
            }
            // If within 1 year
            else if (seconds < 31536000)
            {
                const days = Math.floor(seconds / 86400);
                timeString = `${days} ${this.translate(`Time-Day${days > 1 ? 's' : ''}`)}`;
            }
            // If over 1 year
            else
            {
                const years = Math.floor(seconds / 31536000);
                timeString = `${years} ${this.translate(`Time-Year${years > 1 ? 's' : ''}`)}`;
            }
        }

        return timeString;
    }

    abstract findLocaleId(): string | undefined;

    abstract resolveLocale(localeId: string): Promise<Locale>;

    // ----------------------- Private logic ------------------------

    private getNumberFormat(currencyCode: string): Intl.NumberFormat
    {
        if (!this.numberFormatByCurrencyCode.has(currencyCode))
        {
            this.addNumberFormat(currencyCode);
        }
        return this.numberFormatByCurrencyCode.get(currencyCode);
    }

    private addNumberFormat(currencyCode: string): void
    {
        this.numberFormatByCurrencyCode.set(
            currencyCode,
            this.computeNumberFormat(currencyCode),
        );
    }

    private computeNumberFormat(currencyCode: string): Intl.NumberFormat
    {
        if (currencyCode && currencyCode !== 'XXX')
        {
            return new Intl
                .NumberFormat(
                    this.locale.id,
                    {
                        currency: currencyCode,
                        style: 'currency',
                    },
                );
        }
        else
        {
            return new Intl
                .NumberFormat(
                    this.locale.id,
                    {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    },
                );
        }
    }

    private get languageMap(): {[translationKey: string]: string}
    {
        return translationMap.get(this.supportedLanguage);
    }

    private get fallbackLanguageMap(): {[translationKey: string]: string}
    {
        return translationMap.get(this.fallbackLanguage);
    }

    private get fallbackLanguage(): string
    {
        return this.fallbackLocale.languageCode;
    }
}

function setApiHeaders(localeId: string)
{
    setDefaultApiHeader('OS-ID', 'web');
    setDefaultApiHeader('Device-Language', localeId);
    setDefaultApiHeader('Version-ID', '4');
}