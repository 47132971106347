// @ts-nocheck
/* eslint-disable */
import axios, { AxiosError } from 'axios';
import { useCallback } from 'react';
import { Http } from '../../../Bridge/Http/Http';
import { encodeUrlParams } from '../encodeUrlParams';
import { onApiRequestError } from '../Handlers/onApiRequestError';
import { onApiResponseReceived } from '../Handlers/onApiResponseReceived';
import { getApiUrl } from '../Resources/getApiUrl';

/**
 * Hook that returns a callback which gets a file from the API
 *
 * @param resource resource to get
 * @param onComplete a callback that should execute whenever the file has been received
 * @param params optional HTTP parameters
 * @param onError a callback that should execute whenever an error occurs
 * @param timeout for whole download in ms. {@link Http#TIMEOUT_IN_MILLISECONDS} by default. If null, then request
 * can take infinite amount of time.
 * @return callback
 */
export function useGetFile(
	resource: string,
	onComplete: (blob: Blob, filename: string) => void,
	params?: Record<string, any>,
	onError?: (error: AxiosError) => void,
	timeout?: number | null
): () => Promise<void>
{
	return useCallback(
		() =>
			axios.get(
				getApiUrl(resource),
				{
					params: encodeUrlParams(params),
					responseType: 'blob',
					timeout: (() => {
						switch (timeout)
						{
							case undefined:
								return Http.TIMEOUT_IN_MILLISECONDS;
							case null:
								return 0;
							default:
								return timeout;
						}
					})(),
				},
			)
				.then(
					response =>
					{
						onApiResponseReceived(response);

						const filename = response.headers['content-disposition']?.match(/filename="(.+)"/)?.[1] ?? 'file';

						onComplete(response?.data, filename);
					},
				)
				.catch(onApiRequestError)
				.catch(error => onError !== undefined
					?
					onError(error)
					:
					Promise.reject(error),
				),
		[onComplete, onError, params, resource],
	);
}