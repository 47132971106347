// @ts-nocheck
/* eslint-disable */
import { AppBar, Button, Hidden, IconButton, Theme, Toolbar, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Menu from '@material-ui/icons/Menu';
import Cog from 'mdi-material-ui/Cog';
import * as React from 'react';
import { FC, useContext, useMemo } from 'react';
import { EntranceContextRef } from '../Entrance/EntranceContext';
import { BusinessSelector } from './BusinessSelector';
import { useManagerContext } from './manager-context';
import { ManagerLargeSidebarWidth, ManagerSmallSidebarWidth, ManagerToolbarHeight } from './ManagerStore';
import { ManagerView } from './ManagerView';

const useStyles = makeStyles(
	(theme: Theme) =>
		({
			appBar:
				{
					height: ManagerToolbarHeight,
					justifyContent: 'center',
					zIndex: theme.zIndex.drawer + 1,
				},
			businessNameText:
				{
					marginLeft: theme.spacing(2),
				},
			containerLeft:
				{
					alignItems: 'center',
					display: 'flex',
					flexGrow: 1,
				},
			containerRight:
				{
					alignItems: 'center',
					display: 'flex',
					justifyContent: 'center',
					paddingBottom: theme.spacing(1),
					paddingLeft: theme.spacing(2),
					paddingRight: theme.spacing(2),
					paddingTop: theme.spacing(1),
				},
			logo:
				{
					height: 30,
					marginLeft: theme.spacing(2),
				},
			menuButton:
				{
					alignSelf: 'stretch',
					minWidth: ManagerSmallSidebarWidth,
					padding: 0,
					transition: theme.transitions.create(
						'min-width',
						{
							easing: theme.transitions.easing.sharp,
							duration: theme.transitions.duration.enteringScreen,
						}),
				},
			menuButtonLarge:
				{
					[theme.breakpoints.up('sm')]:
						{
							minWidth: ManagerLargeSidebarWidth,
						},
					[theme.breakpoints.down('xs')]:
						{
							minWidth: ManagerSmallSidebarWidth,
						},
				},
			toolbar:
				{
					alignItems: 'stretch',
					flexGrow: 1,
					justifyContent: 'space-between',
					minHeight: 'auto',
				},
		}));

interface ManagerToolbarProps
{
	isSidebarExpanded: boolean;
	navigateTo: (view: ManagerView) => void;
	toggleSidebar: () => void;
}

export const ManagerToolbar: FC<ManagerToolbarProps> =
	({
		 isSidebarExpanded,
		 navigateTo,
		 toggleSidebar,
	 }) =>
	{
		const classes = useStyles();

		const {
			brandingService,
			business,
		} = useManagerContext();
		const {
			waiterService,
		} = useContext(EntranceContextRef);

		const leftContainer = useMemo(
			() =>
				<div
					className={classes.containerLeft}
				>
					<Hidden
						xsDown
					>
						<Button
							classes={{
								root: `${classes.menuButton} ${isSidebarExpanded ?
									classes.menuButtonLarge : ''}`,
							}}
							onClick={toggleSidebar}
							style={{
								color: brandingService.topBarContrastTextColor.css,
							}}
						>
							<Menu
							/>
						</Button>
					</Hidden>
					<img
						className={classes.logo}
						src={brandingService.logoInlineImageUrl}
					/>
					<Hidden
						xsDown
					>
						<Typography
							classes={{
								root: classes.businessNameText,
							}}
							noWrap
							style={{
								color: brandingService.topBarContrastTextColor.css,
							}}
						>
							{business ? business.name : 'No business found'}
						</Typography>
					</Hidden>
				</div>,
			[brandingService, business, classes, toggleSidebar]);

		const rightContainer = useMemo(
			() =>
				<div
					className={classes.containerRight}
				>
					<BusinessSelector
						value={business}
						onChange={newBusiness => waiterService.setBusiness(newBusiness)}
						style={{
							marginRight: 16,
						}}
					/>
					<IconButton
						onClick={
							() =>
								navigateTo('settings')
						}
                        size="small"
						style={{
							marginLeft: -4,
							marginRight: -4,
						}}
					>
						<Cog
							style={{
								color: brandingService.topBarContrastTextColor.css,
							}}
							id="cog-icon"
						/>
					</IconButton>
				</div>,
			[
				classes.containerRight,
				brandingService.topBarContrastTextColor.css,
				business,
				navigateTo,
				waiterService
			]);

		return <AppBar
			position="fixed"
			classes={{
				root: classes.appBar,
			}}
		>
			<Toolbar
				disableGutters
				classes={{
					root: classes.toolbar,
				}}
			>
				{leftContainer}
				{rightContainer}
			</Toolbar>
		</AppBar>;
	};
