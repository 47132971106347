import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import * as React from 'react';
import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { useValidationContext } from '../../../../validation/context/ValidationContext';
import { useAdyenCheckoutContext } from '../../../adyen/checkout/AdyenCheckoutContext';
import { usePaymentIssuerContext } from '../../../issuer/context/PaymentIssuerContext';
import { usePaymentMethodContext } from '../../context/PaymentMethodContext';
import { PaymentMethodEditor } from '../editor/PaymentMethodEditor';
import { PaymentMethodSummary } from '../summary/PaymentMethodSummary';

const useStyles = makeStyles(theme => ({
	accordion: {
		backgroundColor: '#fff8',
		display: 'flex',
		flex: '0 1 auto',
		flexDirection: 'column',
		overflowY: 'auto',
	},
	accordionDetails: {
		display: 'flex',
		flex: '0 1 auto',
		flexDirection: 'column',
		overflowY: 'hidden',
		padding: `${theme.spacing(1)}px 0px 0px 0px !important`,
	},
	accordionError: {
		backgroundColor: '#f001',
	},
	accordionSummary: {
		flex: '0 0 auto',
		overflow: 'hidden',
	},
}));

export interface PaymentMethodsAccordionProps
{
	className?: string;
	expanded?: boolean;
	onFocusChange: (hasFocus: boolean) => void;
	onInteraction?: () => void;
}

export const PaymentMethodsAccordion: FC<PaymentMethodsAccordionProps> =
	({
		className,
		expanded,
		onFocusChange,
		onInteraction,
	}) =>
	{
		const showValidation = useValidationContext();
		const {paymentSettingsHasError} = useAdyenCheckoutContext();
		const {validation: paymentMethodValidation} = usePaymentMethodContext();
		const {validation: paymentIssuerValidation} = usePaymentIssuerContext();

		const classes = useStyles();

		const allValidation = useMemo(
			() => [...paymentMethodValidation, ...paymentIssuerValidation],
			[paymentIssuerValidation, paymentMethodValidation],
		);

		const isError = useMemo(
			() => showValidation && (allValidation.length > 0 || paymentSettingsHasError),
			[allValidation.length, paymentSettingsHasError, showValidation],
		);

		const onExpansionChange = useCallback((event: ChangeEvent, expanded: boolean) =>
		{
			onFocusChange?.(expanded);
		}, [onFocusChange]);

		const accordionClassName = clsx(
			classes.accordion,
			className,
			{
				[classes.accordionError]: isError,
			},
		);

		return <Accordion
			className={accordionClassName}
			defaultExpanded
			expanded={expanded}
			onFocus={onInteraction}
			onChange={onExpansionChange}
		>
			<AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
				<PaymentMethodSummary />
			</AccordionSummary>
			<AccordionDetails className={classes.accordionDetails}>
				<PaymentMethodEditor onFocusChange={onFocusChange} />
			</AccordionDetails>
		</Accordion>;
	};