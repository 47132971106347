import { IllegalArgumentException } from '../../../Util/Exception/IllegalArgumentException';

export class HashNotMatchedException extends IllegalArgumentException
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	public name = 'HashNotMatchedException';

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	/*---------------------------------------------------------------*
	 *                         Business Logic                        *
	 *---------------------------------------------------------------*/

	/*---------------------------------------------------------------*
	 *                      Getters and setters                      *
	 *---------------------------------------------------------------*/

	/*---------------------------------------------------------------*
	 *                        Nested classes                         *
	 *---------------------------------------------------------------*/
}