import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useContext } from 'react';
import { Screens } from '../../../Constants/ScreenConstants';
import { useRootContext } from '../../../RootContext';
import { EntranceContextRef } from '../Entrance/EntranceContext';
import { NavigationMenu } from '../NavigationMenu/NavigationMenu';
import { BusinessContextRef } from './BusinessContext';
import { BusinessStore } from './BusinessStore';

export interface BusinessNavigationMenuProps
{
    store: BusinessStore
}

export const BusinessNavigationMenu: FC<BusinessNavigationMenuProps> =
    (
        {
            store,
        }
    ) =>
    {
        const {navigator} = useRootContext(true);
        const {entranceStore} = useContext(EntranceContextRef);
        const {businessStore} = useContext(BusinessContextRef);
        const openManagerPanel = useCallback(
            async () => {
                const entranceScreen = navigator.getOpenScreenInstances(Screens.Entrance)[0];
                const goalScreenInstanceReached = await navigator.popScreensUntil(entranceScreen);
                store.setMenuOpen(false);
                if (goalScreenInstanceReached) {
                    await store.entranceStore.attemptEnterManager();
                }
            },
            [navigator, store]
        );
        const contractingEntity = useObserver(() => businessStore.contractingEntity);
        const userProfileHidden = useObserver(() => entranceStore.userProfileHidden.value);
        const hideOrderHistory = useObserver(() => entranceStore.hideOrderHistory);
        const openHistory = useObserver(() => store.openHistory);
        const openProfile = useObserver(() => store.openProfile);
        const isMenuOpen = useObserver(() => store.isMenuOpen);
        const onCommandLeave = useObserver(() => store.onCommandLeave);

        const handleOpenHistory = useCallback(
            () =>
                openHistory(true),
            [openHistory],
        );

        return <NavigationMenu
            contractingEntity={contractingEntity}
            userProfileHidden={userProfileHidden}
            orderHistoryHidden={hideOrderHistory}
            openManagerPanel={openManagerPanel}
            openHistory={handleOpenHistory}
            openProfile={openProfile}
            isMenuOpen={isMenuOpen}
            onCommandLeave={onCommandLeave}
        />;
    }