import { useObserver } from 'mobx-react-lite';
import { useContext } from 'react';
import { MaxNumberOfOrdersPerPlaceSessionMetadata } from '../../../../../Api/Order/OrderRestriction/MaxNumberOfOrdersPerPlaceSessionMetadata';
import { MaxPlaceSessionDurationMetadata } from '../../../../../Api/Order/OrderRestriction/MaxPlaceSessionDurationMetadata';
import { AuthenticationContext } from '../../../../authentication-provider/AuthenticationContext';
import { MenuStore } from '../MenuStore';
import { MenuTopBarAppendixComoRewards } from './MenuTopBarAppendixComoRewards';
import { MenuTopBarAppendixMaxNumberOfOrdersPerPlaceSession } from './MenuTopBarAppendixMaxNumberOfOrdersPerPlaceSession';
import { MenuTopBarAppendixMaxPlaceSessionDuration } from './MenuTopBarAppendixMaxPlaceSessionDuration';
import { MenuTopBarAppendixProfileButton } from './MenuTopBarAppendixProfileButton';

interface MenuTopBarAppendixProps
{
	store: MenuStore;
}

export const MenuTopBarAppendix = ({store}: MenuTopBarAppendixProps) =>
{
	const {authenticationResult} = useContext(AuthenticationContext)
	const maxNumberOfOrdersPerPlaceSessionReport = useObserver(() => store.shoppingCartStore.currentOrderService.getOrderRestrictionReport('MaxNumberOfOrdersPerPlaceSession'));
	const maxPlaceSessionDurationReport = useObserver(() => store.shoppingCartStore.currentOrderService.getOrderRestrictionReport('MaxPlaceSessionDuration'));
	const loyaltyIntegration = useObserver(() => store.businessStore.loyaltyIntegration);

	return <>
		{
			maxNumberOfOrdersPerPlaceSessionReport !== undefined &&
			<MenuTopBarAppendixMaxNumberOfOrdersPerPlaceSession
				metadata={maxNumberOfOrdersPerPlaceSessionReport.metadata as MaxNumberOfOrdersPerPlaceSessionMetadata}
			/>
		}
		{
			maxPlaceSessionDurationReport !== undefined &&
			<MenuTopBarAppendixMaxPlaceSessionDuration
				metadata={maxPlaceSessionDurationReport.metadata as MaxPlaceSessionDurationMetadata}
			/>
		}
		{
			loyaltyIntegration === 'COMO' &&
			authenticationResult.version === 'V3' &&
			!authenticationResult.idTokenPayload.anonymous &&
			<MenuTopBarAppendixComoRewards
				store={store}
			/>
		}
		{
			authenticationResult.version === 'V3' &&
			<MenuTopBarAppendixProfileButton />
		}
	</>;
};
