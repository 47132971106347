import { makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useState } from 'react';
import { OrderLinesContextProvider } from './OrderLinesContext';

const useStyles = makeStyles({
	ShoppingCartList: {
		flex: '0 1 auto',
		padding: 0,
		width: '100%',
	},
});

export const OrderLinesContainer: FC =
	({
		children,
	}) =>
	{
		const [quantityLabelWidths, setQuantityLabelWidths] = useState<Record<string, number>>({});

		const classes = useStyles();

		const columnWidth = useObserver(
			() =>
				Object
					.values(quantityLabelWidths)
					.reduce((previousValue, currentValue) => Math.max(previousValue, currentValue), 0),
		);

		const setQuantityLabelWidth = useCallback((orderLineKey: string, width: number | undefined) =>
		{
			setQuantityLabelWidths(prev =>
			{
				if (width === undefined)
				{
					if (prev?.[orderLineKey] !== undefined)
					{
						const {
							[orderLineKey]: removed,
							...result
						} = prev;

						return result;
					}
					else
					{
						return prev;
					}
				}
				else
				{
					return {
						...prev ?? {},
						[orderLineKey]: width,
					};
				}
			});
		}, []);

		return <List
			className={classes.ShoppingCartList}
		>
			<OrderLinesContextProvider
				value={{
					quantityColumnWidth: columnWidth,
					setOrderLineQuantityLabelWidth: setQuantityLabelWidth,
				}}
			>
				{children}
			</OrderLinesContextProvider>
		</List>;
	};
