import { Box, useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslate } from '../../../../../../Bridge/Localization/useTranslate';
import { useIsKioskMode } from '../../../../../../Service/KioskService/Api/useIsKioskMode';
import { useAdyenCheckoutContext } from '../../../../../UI/payment/adyen/checkout/AdyenCheckoutContext';
import { usePaymentIssuerContext } from '../../../../../UI/payment/issuer/context/PaymentIssuerContext';
import { usePaymentMethodContext } from '../../../../../UI/payment/method/context/PaymentMethodContext';
import { PoweredByButlaroo } from '../../../../../UI/PoweredByButlaroo/PoweredByButlaroo';
import { useProcessingOrderContext } from '../../../../../UI/processing_order_context/ProcessingOrderContext';
import { Announcement } from '../../../Announcement/Announcement';
import { BusinessContextRef, useCurrentOrderService } from '../../../BusinessContext';
import { OrderBuilderStore } from '../../OrderBuilderStore';
import { OrderButton } from './OrderButton';
import { useCheckOrderWhenEffectiveScheduledTimeChanges } from './useCheckOrderWhenEffectiveScheduleTimeChanges';

interface OrderButtonOrderBuilderSegmentProps
{
	bottomLeftEdgeRadius?: number;
	store: OrderBuilderStore;
}

export const OrderButtonOrderBuilderSegment: FC<OrderButtonOrderBuilderSegmentProps> =
	(
		{
			bottomLeftEdgeRadius,
			store,
		},
	) =>
	{
		const translate = useTranslate();
		const {businessStore} = useContext(BusinessContextRef);
		const currentOrderService = useCurrentOrderService(true);
		const {activeComponent, isLoading: isAdyenCheckoutClientLoading} = useAdyenCheckoutContext();
		const {selectedPaymentMethod} = usePaymentMethodContext();
		const {selectedPaymentIssuer} = usePaymentIssuerContext();
		const {setProcessingOrder} = useProcessingOrderContext();

		useCheckOrderWhenEffectiveScheduledTimeChanges(store);

		const announcements = useObserver(() => currentOrderService.announcements);
		const isAllowedToOrder = useObserver(() => currentOrderService.isAllowedToOrder);
		const useLargeOrderButton = useMediaQuery('(min-height: 600px)');
		const buttonHeight = useMemo(() => useLargeOrderButton ? 70 : 49, [useLargeOrderButton]);
		const attemptPlaceOrder = useObserver(() => store.attemptPlaceOrder);
		const doAfterOrderComplete = useObserver(() => store.doAfterOrderComplete);
		const doAfterOrderPlacement = useObserver(() => store.doAfterOrderPlacement);
		const paymentSessionHasError = useObserver(() => store.paymentSessionHasError);
		const validateFormAndSendUserBackIfNecessary = useCallback(() => store.validateFormAndSendUserBackIfNecessary(), [store]);
		const showValidation = useObserver(() => store.showValidation);
		const hasValidationErrors = useObserver(() => store.hasValidationErrors);
		const isOrderProcessing = useObserver(() => currentOrderService.orderProcessing);
		const setOrderProcessing = useObserver(() => currentOrderService.setOrderProcessing);
		const initiatePayment = useObserver(() => businessStore.initiatePayment);
		const contractingEntity = useObserver(() => businessStore.contractingEntity);

		const error = useMemo(
			() =>
				(!isAdyenCheckoutClientLoading && paymentSessionHasError)
				||
				(showValidation && hasValidationErrors),
			[hasValidationErrors, isAdyenCheckoutClientLoading, paymentSessionHasError, showValidation],
		);

		const onAttemptSubmit = useCallback(
			async () =>
			{
				if (validateFormAndSendUserBackIfNecessary())
				{
					setOrderProcessing(true);

					try
					{
						const data = activeComponent?.data;

						const order = await attemptPlaceOrder(
							selectedPaymentMethod,
							selectedPaymentIssuer,
							data
						);

						setProcessingOrder(order);

						try
						{
							await initiatePayment?.(order, activeComponent);
						}
						catch (error)
						{
							console.error(error);
							alert(translate('Order-Payment-Failed'));
						}

						doAfterOrderPlacement(order);

						setOrderProcessing(false);
						setProcessingOrder(undefined);
					}
					catch (error)
					{
						setOrderProcessing(false);
						setProcessingOrder(undefined);

						console.error(error);
						alert(translate('Order-Placement-Failed'));
						return;
					}

					await doAfterOrderComplete();
				}
			},
			[
				activeComponent,
				attemptPlaceOrder,
				doAfterOrderComplete,
				doAfterOrderPlacement,
				initiatePayment,
				selectedPaymentIssuer,
				selectedPaymentMethod,
				setOrderProcessing,
				setProcessingOrder,
				translate,
				validateFormAndSendUserBackIfNecessary,
			],
		);

		const isLoading = useMemo(() =>
		{
			if (activeComponent === undefined)
				return isAdyenCheckoutClientLoading || isOrderProcessing;
			else
				return (isAdyenCheckoutClientLoading || isOrderProcessing) && !activeComponent.isValid;
		}, [activeComponent, isAdyenCheckoutClientLoading, isOrderProcessing]);

		const isKioskMode = useIsKioskMode();

		return <Grid
			item
			xs={12}
			style={{
				flex: '0 0 auto',
				marginTop: 'auto',
			}}
		>
			<Grid
				container
				spacing={2}
			>
				{
					announcements.map((announcement, idx) => <Grid
						key={idx}
						item
						xs={12}
					>
						<Announcement
							announcement={announcement}
						/>
					</Grid>)
				}
				{
					isAllowedToOrder &&
					<Grid
						item
						xs={12}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<OrderButton
								bottomLeftEdgeRadius={bottomLeftEdgeRadius}
								height={buttonHeight}
								loading={isLoading}
								error={error}
								onClick={onAttemptSubmit}
							/>
						</Box>
					</Grid>
				}
				<Grid
					item
					xs={12}
					style={{
						paddingTop:
							isKioskMode
								? 16
								: 8,
					}}
				>
					<PoweredByButlaroo
						contractingEntity={contractingEntity}
						justifyContent={
							isKioskMode
								? 'center'
								: 'end'
						}
					/>
				</Grid>
			</Grid>
		</Grid>;
	};