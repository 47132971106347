import { makeAutoObservable } from 'mobx';
import { SerializationProfile } from '../../Util/Serialization/Serialization';
import { LanguageEntryTranslations } from './LanguageEntryTranslations';
import { LanguageEntryTranslationsProfile } from './LanguageEntryTranslationsProfile';

export class LanguageEntry
{
    public id: string;
    public translations: LanguageEntryTranslations;

    constructor()
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
    }
}

export const LanguageEntryProfile = SerializationProfile
    .create(LanguageEntry)
    .profile('translations', LanguageEntryTranslationsProfile);

export class Language
{
    id: number;
    name: string;
    abbreviation: string;
    isEnabled: boolean;

    constructor()
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
    }
}

export const LanguageProfile =
    SerializationProfile.create(Language);
