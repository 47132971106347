import { BaseStore } from '@intentic/ts-foundation';
import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';
import * as React from 'react';
import { IllegalArgumentException } from '../../../Util/Exception/IllegalArgumentException';

export class PinCodeInputStore extends BaseStore
{
    /*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

    onSubmit: (pin: string) => Promise<boolean>;
    length: number;
    textInputs: IObservableArray<React.RefObject<any>>;
    values: IObservableArray<string>;
    inputValues: IObservableArray<string>;
    showValidation: boolean;
    isLastSubmittedValueCorrect: boolean;
    isAccepted: boolean;
    focussed: number|null;

    /*---------------------------------------------------------------*
	 *                          Constructor                          *
	 *---------------------------------------------------------------*/

    constructor(
        onSubmit: (pin: string) => Promise<boolean>,
        length: number,
    )
    {
        super();

        makeObservable<PinCodeInputStore, 'value' | 'isValueLegal'>(
            this,
            {
                onSubmit: observable,
                length: observable,
                textInputs: observable,
                values: observable,
                inputValues: observable,
                showValidation: observable,
                isLastSubmittedValueCorrect: observable,
                isAccepted: observable,
                focussed: observable,
                value: computed,
                isValueLegal: computed,
                attemptCompletion: action.bound,
                entersUI: action.bound,
                handleSubmitResponse: action.bound,
                onInputClick: action.bound,
                onInputFocus: action.bound,
                onInputBlur: action.bound,
                setInputValue: action.bound,
                setDigit: action.bound,
                setShowValidation: action.bound,
                setIsLastSubmittedValueCorrect: action.bound,
                setIsAccepted: action.bound,
                setFocussed: action.bound,
                changeDigit: action.bound,
            },
        );

        this.onSubmit = onSubmit;
        this.length = length;
        this.textInputs = observable.array<any>(Array.from(Array(length).keys()).map(aValue => React.createRef()));
        this.inputValues = observable.array<string>(Array.from(Array(length).keys()).map(aValue => ''));
        this.values = observable.array<string>(Array.from(Array(length).keys()).map(aValue => ''));
        this.showValidation = false;
        this.isAccepted = false;
        this.focussed = null;
    }

    /*---------------------------------------------------------------*
	 *                         Business Logic                        *
	 *---------------------------------------------------------------*/

    entersUI(isMounted: boolean): void
    {
        if (isMounted) {
            if (this.length > 0)
            {
                this.setFocusToInput(0);
            }
        }
    }

    private get value()
    {
        return this.values
            .map(v => v ? v : ' ')
            .join('');
    }

    private get isValueLegal()
    {
        return this.values
            .every((v, i) => this.isDigitValueLegal(v, i));
    }

    changeDigit(index: number, value: string)
    {
        const oldInputValue = this.inputValues[index];
        const isValid = this.isDigitValueLegal(value, index);
        const nrOfDigits = value.length; // for supporting slow focus change
        const shouldBlurOutAfter = index === this.length - nrOfDigits;
        this.setInputValue(index, value);
        if (isValid)
        {
            for (let i = 0; i < value.length; i++) {
                let digitValue = value[i];
                this.setDigit(index + i, digitValue);
            }
            if (shouldBlurOutAfter) {
                this.blurInput(index);
            } else {
                this.setFocussed(index + nrOfDigits);
            }
        } else {
            this.setInputValue(index, value);
            this.setInputValue(index, oldInputValue);
        }
    }

    handleSubmitResponse(wasPinCorrect: boolean)
    {
        if (wasPinCorrect)
        {
            this.setIsAccepted(true);
        }
        else
        {
            this.setShowValidation(true);
            this.setIsLastSubmittedValueCorrect(wasPinCorrect);
            if (this.length > 0)
            {
                this.setFocusToInput(0);
            }
        }
    }

    onInputClick(index: number)
    {
        this.setFocussed(index);
        this.inputValues[index] = '';
    }

    onInputFocus(index: number)
    {
        // this.setFocussed(index);
    }

    onInputBlur(index: number)
    {
        // this.inputValues[index] = '';
        this.setFocussed(null);
        this.inputValues[index] = '';
    }

    setInputValue(index: number, value: string)
    {
        this.inputValues[index] = value;
    }

    public attemptCompletion(): Promise<void>
    {
        return (() => this.isValueLegal ? Promise.resolve() : Promise.reject())()
            .then(() => this.onSubmit(this.value))
            .then(isPinAccepted =>
            {
                this.handleSubmitResponse(isPinAccepted);
                return isPinAccepted
                    ? Promise.resolve()
                    : Promise.reject();
            });
    }

    setFocusToInput(index: number)
    {
        if (index > this.length - 1)
        {
            throw new IllegalArgumentException(`Pin code input with index ${index} not found.`);
        }
        try{
            this.textInputs[index].current._root.focus();
        }
        catch (e)
        {

            this.textInputs[index].current.focus();
        }
    }

    private isDigitValueValid(digit: string)
    {
        return /^[0-9]$/.test(digit);
    }

    private isDigitValueLegal(digit: string, index: number)
    {
        const test = new RegExp(`^[0-9]{0,${this.length - index}}$`);
        return test.test(digit);
    }

    private blurInput(index: number)
    {
        if (index > this.length - 1)
        {
            throw new IllegalArgumentException(`Pin code input with index ${index} not found.`);
        }
        this.textInputs[index].current.blur();
        this.setFocussed(null);

        try{
            this.textInputs[index].current._root.blur();
        }
        catch (e)
        {

            this.textInputs[index].current.blur();
        }
    }

    setDigit(index: number, value: string)
    {
        this.values[index] = value;
    }

    setShowValidation(showValidation: boolean): void
    {
        this.showValidation = showValidation;
    }

    setIsLastSubmittedValueCorrect(isLastSubmittedValueCorrect: boolean): void
    {
        this.isLastSubmittedValueCorrect = isLastSubmittedValueCorrect;
    }

    setIsAccepted(isAccepted: boolean): void
    {
        this.isAccepted = isAccepted;
    }

    setFocussed(index: number|null)
    {
        this.focussed = index;
    }
}
