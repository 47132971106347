import { CircularProgress, makeStyles, MenuItem, Select, Theme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as React from 'react';
import { FC } from 'react';
import { SchedulerStore } from '../SchedulerStore';
import { TimeSlot } from '../TimeSlot';
import { InitializedSchedulerTimeSelect } from './initialized/InitializedSchedulerTimeSelect';
import { SchedulerTimeSelectOption } from './SchedulerTimeSelectOption';
import { useOrderingTimeSlots } from './useOrderingTimeSlots';

const useStyles = makeStyles((theme: Theme) => ({
	loadingSelect: {
		marginBottom: theme.spacing(1),
	},
	loadingSelectLoader: {
		height: `calc(${theme.typography.body1.fontSize} + ${theme.spacing(0.5)}px) !important`,
		width: `calc(${theme.typography.body1.fontSize} + ${theme.spacing(0.5)}px) !important`,
	},
	loadingSelectMenu: {
		textAlign: 'center',
	},
}));

export interface SchedulerTimeSelectProps
{
	day: string;
	onChange: (value: SchedulerTimeSelectOption | undefined) => void;
	showValidation: boolean;
	store: SchedulerStore;
	value: 'ASAP' | TimeSlot | undefined;
}

export const SchedulerTimeSelect: FC<SchedulerTimeSelectProps> =
	({
		day,
		...otherProps
	}) =>
	{
		const {error, isLoading, result} = useOrderingTimeSlots(day);

		const classes = useStyles();

		if (error !== undefined)
		{
			return <Alert>
				{error}
			</Alert>;
		}
		else if (result !== undefined && !isLoading)
		{
			return <InitializedSchedulerTimeSelect
				day={day}
				timeSlots={result}
				{...otherProps}
			/>;
		}
		else
		{
			return <Select
				className={classes.loadingSelect}
				classes={{
					selectMenu: classes.loadingSelectMenu,
				}}
				disabled
				fullWidth
				value="loader"
				variant="outlined"
			>
				<MenuItem
					value="loader"
				>
					<CircularProgress
						className={classes.loadingSelectLoader}
					/>
				</MenuItem>
			</Select>;
		}
	};
