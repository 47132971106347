import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { useRootContext } from '../../../../../RootContext';
import { Announcement } from '../../Announcement/Announcement';
import { ComoRewardsStore } from './ComoRewardsStore';

const useStyle = makeStyles(() => ({
	menuHeadGridContainer: {
		flex: '0 0 auto !important',
	},
}));

interface ComoPointShopBalanceProps
{
	store: ComoRewardsStore;
}

export const ComoPointShopBalance: FC<ComoPointShopBalanceProps> =
	({
		store,
	}) =>
	{
		const classes = useStyle();
		const {brandingService} = useRootContext(true);

		const announcement = useObserver(() => store.announcement);
		const shouldUseSecondaryColorOnMenu = useObserver(() => brandingService.shouldUseSecondaryColorOnMenu);

		return <Grid
			item
			xs={12}
			classes={{
				item: classes.menuHeadGridContainer,
			}}
		>
			<Announcement
				announcement={announcement}
				useSecondaryColor={shouldUseSecondaryColorOnMenu}
			/>
		</Grid>;
	};
