import makeStyles from '@material-ui/core/styles/makeStyles';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import * as React from 'react';
import { FC, useContext } from 'react';
import { PlaceSession } from '../../../../../../Api/Business/PlaceSession';
import { NegativeActionButton } from '../../../../../UI/action-button/type/NegativeActionButton';
import { PositiveActionButton } from '../../../../../UI/action-button/type/PositiveActionButton';
import { EntranceContextRef } from '../../../../Entrance/EntranceContext';

const useStyles = makeStyles(() => ({
	actionsRoot: {
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'space-between',
	},
	container: {
		display: 'flex',
		flexWrap: 'nowrap',
	},
	listItem: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: 8,
	},
}));

interface SessionEditorDialogActionsProps
{
	onOpenMenu: () => void;
	onSavePlaceSession: (endSession: boolean) => Promise<void>;
	placeSession: PlaceSession;
}

export const SessionEditorDialogActions: FC<SessionEditorDialogActionsProps> =
	(
		{
			onOpenMenu,
			onSavePlaceSession,
			placeSession,
		},
	) =>
	{
		const {localizer} = useContext(EntranceContextRef);

		const classes = useStyles();

		return <div
			className={classes.actionsRoot}
		>
			<NegativeActionButton
				icon={DeleteIcon}
				id="manager-place-session-stop-button"
				onClick={() => onSavePlaceSession(true)}
				text={localizer.translate(placeSession.id ? 'Place-Session-Stop' : 'Generic-Cancel')}
			/>
			<PositiveActionButton
				disabled={!placeSession.place.placeCodes || placeSession.place.placeCodes.length === 0}
				icon={PlayArrowIcon}
				onClick={onOpenMenu}
				text={localizer.translate('Place-Session-Open-Menu')}
			/>
			<PositiveActionButton
				icon={CheckIcon}
				id="manager-place-session-edit-button"
				onClick={() => onSavePlaceSession(false)}
				text={localizer.translate(placeSession.id ? 'Generic-Apply' : 'Generic-Start')}
			/>
		</div>;
	};
