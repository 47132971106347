import { useObserver } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { voidOrder } from '../../../../Service/OrderService/Api/Client/voidOrder';
import { useMostRecentOrder } from './useMostRecentOrder';

export function useOnClickCancelPayment(): [() => Promise<void>, boolean]
{
	const mostRecentOrder = useMostRecentOrder();
	const [uuidOfOrderThatIsCancelling, setUuidOfOrderThatIsCancelling] = useState<string>();
	const onClickCancelPayment = useCallback(
		async () => {
			if (mostRecentOrder)
			{
				setUuidOfOrderThatIsCancelling(mostRecentOrder.uuid);
				await voidOrder(mostRecentOrder);
			}
		},
		[mostRecentOrder],
	);
	const mostRecentOrderIsCancelling = useObserver(() => mostRecentOrder?.uuid === uuidOfOrderThatIsCancelling);
	return [onClickCancelPayment, mostRecentOrderIsCancelling]
}