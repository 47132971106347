import { FC } from 'react';
import { ComoItemsSelections } from './ComoItemsSelection/ComoItemsSelections';
import { ComoRewardStore } from './ComoRewardStore';

interface ComoRewardItemsSelectionsDialogContentProps
{
	store: ComoRewardStore;
}

export const ComoRewardItemsSelectionsDialogContent: FC<ComoRewardItemsSelectionsDialogContentProps> =
	({
		store,
	}) =>
	{
		if (store.reward.itemsSelection === undefined)
		{
			throw new Error('Failed to render item selections: Reward has no item selections');
		}
		else
		{
			return <ComoItemsSelections
				store={store}
			/>;
		}
	};
