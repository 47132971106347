import { ServiceWorkerConstants } from '../../Constants/ServiceWorkerConstants';

export function getServiceWorkerRegistration(): Promise<ServiceWorkerRegistration | undefined>
{
    if ('serviceWorker' in window.navigator)
    {
        return window.navigator.serviceWorker.getRegistration(ServiceWorkerConstants.OutputFile);
    }
    else
    {
        return Promise.resolve(undefined);
    }
}