import { useObserver } from 'mobx-react-lite';
import { useKioskService } from './useKioskService';

export function useIsKioskMode(): boolean
{
	const kioskService = useKioskService();

	return useObserver(
		() =>
			kioskService.isKioskMode.get()
	);
}