import { action, makeObservable, observable } from 'mobx';
import { WithSnackbarProps } from 'notistack';
import { Notification } from './Notification';
import { NotificationOptions } from './NotificationOptions';
import { NotificationSnackbarText } from './Snackbar/NotificationSnackbarText';

export class WebNotification extends Notification
{
	// ------------------------ Dependencies ------------------------

	// ------------------------- Properties -------------------------

	props?: WithSnackbarProps;

	// ------------------------ Constructor -------------------------

	constructor()
	{
		super();

		makeObservable(
			this,
			{
				props: observable,
				onDismissSnackbar: action.bound,
			},
		);
	}

	// ----------------------- Initialization -----------------------

	initialize(props: WithSnackbarProps)
	{
		this.props = props;
		this.setInitialized();
	}

	// -------------------------- Computed --------------------------

	// --------------------------- Stores ---------------------------

	// -------------------------- Actions ---------------------------

	onDismissSnackbar(key?: string): void
	{
		this.props && this.props.closeSnackbar(key);
	}

	// ------------------------ Public logic ------------------------

	notify(notificationOptions: NotificationOptions): void
	{
		if (this.props?.enqueueSnackbar)
		{
			const {content, ...otherNotificationProps} = notificationOptions;

			this.props.enqueueSnackbar(
				NotificationSnackbarText({
					text: content,
				}),
				otherNotificationProps,
			);

			if (!notificationOptions.disableVibration && typeof navigator.vibrate === 'function')
			{
				navigator.vibrate(300);
			}
		}
	}

	// ----------------------- Private logic ------------------------
}