import * as React from 'react';
import { FC, ReactNode, useEffect, useMemo } from 'react';
import { FormControl, PropTypes, TextField } from '@material-ui/core';
import { PostalCodeRestriction } from '../../../../Api/Other/PostalCodeRestriction';

interface PostalCodeFormProps
{
	label?: ReactNode;
	margin?: PropTypes.Margin,
	marginBottom?: boolean;
	marginTop?: boolean;
	onErrorUpdate?: (isError: boolean) => void;
	postalCodeRestrictions?: PostalCodeRestriction[];
	required?: boolean;
	requiredLabel?: string;
	restrictedLabel?: string;
	setValue: (value: string) => void;
	value: string;
	showValidation?: boolean;
}

export const PostalCodeForm: FC<PostalCodeFormProps> =
	(
		{
			label,
			margin,
			marginBottom,
			marginTop,
			onErrorUpdate,
			postalCodeRestrictions,
			required,
			requiredLabel,
			restrictedLabel,
			setValue,
			value,
			showValidation = true,
		},
	) =>
	{
		const isEmpty = useMemo(() => value === null || value === undefined || value === '', [value]);

		const postalCodeFallsWithinRestrictions = useMemo(
			() => PostalCodeRestriction.isPostalCodeValid(value, postalCodeRestrictions),
			[postalCodeRestrictions, value],
		);

		const error = useMemo(
			() =>
			{
				if (required && isEmpty)
				{
					return requiredLabel;
				}
				else if (!postalCodeFallsWithinRestrictions)
				{
					return restrictedLabel;
				}
			},
			[isEmpty, postalCodeFallsWithinRestrictions, required, requiredLabel, restrictedLabel],
		);

		const isError = useMemo(() => showValidation && error !== undefined, [error, showValidation]);

		useEffect(() => onErrorUpdate?.(error !== undefined), [error, onErrorUpdate]);

		return <FormControl>
			<TextField
				error={isError}
				helperText={showValidation ? error : undefined}
				label={label}
				margin={margin}
				onChange={event => setValue(event.target.value)}
				required={required}
				style={{
					marginBottom: marginBottom ? 8 : 0,
					marginTop: marginTop ? 8 : 0,
				}}
				value={value}
				variant="outlined"
			/>
		</FormControl>;
	};