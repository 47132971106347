import { isWidthUp } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { useRootContext } from '../../../../../RootContext';
import { useIsKioskMode } from '../../../../../Service/KioskService/Api/useIsKioskMode';
import { useScreenWidth } from '../../../../../Util/Hooks/useScreenWidth';
import BrandedPage from '../../../../UI/BrandedPage';
import { CenteredPageContent } from '../../../../UI/CenteredPageContent';
import { SessionStatusBar } from '../../../../UI/session-status-bar/SessionStatusBar';
import { drawerWidth } from '../../BusinessConstants';
import { ComoRewardsContent } from './ComoRewardsContent';
import { ComoRewardsStore } from './ComoRewardsStore';
import { ComoRewardsTopBar } from './ComoRewardsTopBar';

const useStyle = makeStyles<Theme, { isAtLeastMediumSizedWindow: boolean, shiftContent: boolean }>(theme => ({
	contentRoot: ({shiftContent}) => ({
		display: 'flex',
		flex: '1 0 auto',
		flexDirection: 'column',
		marginRight: shiftContent ? drawerWidth : 0,

		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	page: {
		display: 'flex',
		flex: '1 0 auto',
		flexDirection: 'column',
		justifyContent: 'center',
	},
}));

interface ComoRewardsProps
{
	store: ComoRewardsStore;
}

export const ComoRewards: FC<ComoRewardsProps> =
	({
		store,
	}) =>
	{
		const {brandingService} = useRootContext(true);

		const isKioskMode = useIsKioskMode();
		const cartNonEmpty = useObserver(() => !store.shoppingCartStore.isEmpty);

		const screenWidth = useScreenWidth();
		const isAtLeastMediumSizedWindow = useMemo(() => isWidthUp('md', screenWidth), [screenWidth]);

		const shiftContent = useMemo(
			() => !isKioskMode && cartNonEmpty && isAtLeastMediumSizedWindow,
			[cartNonEmpty, isAtLeastMediumSizedWindow, isKioskMode],
		);

		const classes = useStyle({isAtLeastMediumSizedWindow, shiftContent});

		return <>
			<BrandedPage
				brandingService={brandingService}
				className={classes.page}
			>
				{
					isAtLeastMediumSizedWindow
						? <div
							className={classes.contentRoot}
						>
							<CenteredPageContent
								stretchedHeight
								centeredContent
							>
								<ComoRewardsContent store={store} />
							</CenteredPageContent>
						</div>
						: <ComoRewardsContent store={store} />
				}
			</BrandedPage>
			<ComoRewardsTopBar shoppingCartStore={store.shoppingCartStore} />
			<SessionStatusBar/>
		</>;
	};