import { makeStyles, Typography } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from '../../../../../Bridge/Localization/useTranslation';
import { useCurrentOrderService } from '../../BusinessContext';

const useStyles = makeStyles({
	label: {
		marginBottom: 8,
	},
});

export const SchedulerLabel: FC =
	() =>
	{
		const currentOrderService = useCurrentOrderService(true);

		const classes = useStyles();

		const effectiveDestinationType = useObserver(() => currentOrderService.effectiveDestinationType);

		return <Typography
			className={classes.label}
			color="textSecondary"
		>
			{
				useTranslation(
					effectiveDestinationType === 'PICKUP_POINT'
						? 'Order-Pickup-Time-Explanation'
						: 'Order-Delivery-Time-Explanation',
				)
			}
		</Typography>;
	};
