export const StorageVars = {
    Account: 'account',
    Allergens: 'allergens',
    AppVersion: 'appVersion',
    NutritionFlags: 'nutritionFlags',
    ClearOrderOptionsAfterOrder: 'clearOrderOptionsAfterOrder',
    ClearShoppingCartAfterReturning: 'clearShoppingCartAfterReturning',
    ClientName: 'clientName',
    CustomerCard: 'customerCard',
    PaymentMethod: 'paymentMethod',
    PaymentIssuer: 'paymentIssuer',
    IsKioskMode: 'isKioskMode',
    LastPlaceHash: 'lastPlaceHash',
    LastPlaceVisitDate: 'lastPlaceVisitDate',
    LowPerformance: 'lowPerformance',
    KioskPlaceHash: 'kioskPlaceHash',
    HashOfLockedPlace: 'hashOfLockedPlace',
    HideTopBar: 'hideTopBar',
    InstanceUuid: 'instanceUuid',
    ManagerFilterRouting: 'managerFilterRouting',
    ManagerNotificationRecurrence: 'managerNotificationRecurrence',
    ManagerShowOpenBills: 'managerShowOpenBills',
    ManagerShowPaidHandledOrders: 'managerShowPaidHandledOrders',
    NavigateToBusinessRootAfterOrder: 'navigateToBusinessRootAfterOrder',
    OidcCodeVerifier: 'oidc.codeVerifier',
    OidcState: 'oidc.state',
    OidcStateUrl: 'oidc.state.url',
    OrderHistoryHidden: 'orderHistoryHidden',
    PlacePassword: 'placePassword',
    PlaceSessionRequired: 'placeSessionRequired',
    ClientInstanceUuid: 'clientInstanceUuid',
    reportsFromDate: 'reportsFromDate',
    reportsToDate: 'reportsToDate',
    UserAcknowledgedKeys: 'userAcknowledgedKeys',
    UserProfileHidden: 'userProfileHidden',
};
