import { BaseStore } from '@intentic/ts-foundation';
import Decimal from 'decimal.js';
import { action, computed, makeObservable, observable } from 'mobx';
import { LoyaltyDiscount } from '../../../../../Api/Order/Loyalty/LoyaltyDiscount';
import { CurrentOrderService } from '../../../../../Service/CurrentOrder/CurrentOrderService';
import { BusinessStore } from '../../BusinessStore';

export class AppliedRewardsStore extends BaseStore
{
	// ------------------------ Dependencies ------------------------

	businessStore: BusinessStore;
	currentOrderService: CurrentOrderService;
	onClose: () => Promise<void>;

	// ------------------------- Properties -------------------------

	// ------------------------ Constructor -------------------------

	constructor(
		businessStore: BusinessStore,
		currentOrderService: CurrentOrderService,
		onClose: () => Promise<void>,
	)
	{
		super();

		makeObservable<AppliedRewardsStore>(
			this,
			{
				businessStore: observable,
				currentOrderService: observable,
				onClose: observable,
				hasActiveLoyaltyRewards: computed,
				addedLoyaltyRewards: computed,
				addedLoyaltyDeals: computed,
				rewardDiscount: computed,
				removeReward: action.bound,
				openRewardsList: action.bound,
			},
		);

		this.businessStore = businessStore;
		this.currentOrderService = currentOrderService;
		this.onClose = onClose;
	}

	// ----------------------- Initialization -----------------------

	// -------------------------- Computed --------------------------

	get hasActiveLoyaltyRewards(): boolean
	{
		return this.currentOrderService.hasActiveLoyaltyDiscounts;
	}

	get addedLoyaltyRewards(): LoyaltyDiscount[]
	{
		return this.currentOrderService.addedLoyaltyRewards;
	}

	get addedLoyaltyDeals(): LoyaltyDiscount[]
	{
		return this.currentOrderService.addedLoyaltyDeals;
	}

	get rewardDiscount(): Decimal
	{
		return [
			...this.addedLoyaltyDeals,
			...this.addedLoyaltyRewards,
		]
			.reduce(
				(subTotal, reward) =>
				{
					return subTotal.add(reward.discount);
				},
				new Decimal(0),
			);
	}

	// --------------------------- Stores ---------------------------

	// -------------------------- Actions ---------------------------

	public removeReward(discount: LoyaltyDiscount): void
	{
		const newDiscounts = this.currentOrderService.activeLoyaltyDiscount.discounts.filter(d => d.externalId !== discount.externalId);

		this.currentOrderService.setActiveLoyaltyDiscount(
			{
				discounts: newDiscounts,
				lines: this.currentOrderService.activeLoyaltyDiscount.lines,
			},
			[
				discount.externalId,
			],
		);

		this.currentOrderService.checkOrderIfNecessary();
	}

	public openRewardsList(): Promise<unknown>
	{
		return this.businessStore.openComoRewardsPage();
	}

	// ------------------------ Public logic ------------------------

	// ----------------------- Private logic ------------------------
}
