import { Decimal } from 'decimal.js';
import { useMemo } from 'react';
import { useExternalOrdersContext } from '../../context/external-orders/ExternalOrdersContext';

export function useExternalOrdersBillSettlerPriceAmount(): Decimal | undefined
{
	const externalOrders = useExternalOrdersContext();

	return useMemo(() =>
	{
		if (externalOrders === 'error' || externalOrders === 'timeout' || externalOrders === undefined)
		{
			return new Decimal(0);
		}
		else
		{
			return externalOrders
				.map(order => order.paymentPrice.amount)
				.reduce(
					(result, current) =>
						result.add(current),
					new Decimal(0),
				);
		}
	}, [externalOrders]);
}
