import { ListItem, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Decimal from 'decimal.js';
import * as React from 'react';
import { CSSProperties, FC, useMemo } from 'react';
import { useLocalizer } from '../../../../../Bridge/Localization/useLocalizer';
import PriceTag from '../../../../UI/PriceTag/PriceTag';

interface StylesProps
{
	leftMargin: number
	rightMargin: number
}

const useStyles = makeStyles<Theme, StylesProps>(theme => ({
	shoppingCartConfiguration: ({leftMargin, rightMargin}: StylesProps) => ({
		width: '100% !important',
		zIndex: 1500,
		paddingTop: '0px !important',
		paddingBottom: '0px !important',
		paddingLeft: `${leftMargin}px !important`,
		paddingRight: `${rightMargin}px !important`,
	}),
	DescriptionContainer: {
		flex: '1 1 auto',
		verticalAlign: 'middle',
		paddingTop: 8,
		paddingBottom: 8,
		paddingRight: 8,
		// paddingLeft: 8,
	},
	OptionGridContainer: {},
	OptionGridItem: {},
	helperText: {
		color: theme.palette.error.main,
		fontSize: 'smaller !important',
	},
}));

interface BasicShoppingCartConfigurationProps
{
	style?: CSSProperties
	price: Decimal
	quantity?: number
	currencyCode: string
	labelText: string
	leftMargin?: number
	rightMargin?: number
	showFreeWhenZero?: boolean
}

export const BasicShoppingCartConfiguration: FC<BasicShoppingCartConfigurationProps> =
	(
		{
			style,
			price,
			quantity,
			currencyCode,
			labelText,
			leftMargin = 12,
			rightMargin = 12,
			showFreeWhenZero,
		},
	) =>
	{
		const classes = useStyles({leftMargin, rightMargin});
		const localizer = useLocalizer();

		const priceLabel = useMemo(
			() =>
				<PriceTag
					price={price.mul(quantity ?? 1)}
					localizer={localizer}
					currencyCode={currencyCode}
					showFreeWhenZero={showFreeWhenZero}
					style={{
						display: 'inline-block',
					}}
					color="textSecondary"
					component="span"
				/>,
			[currencyCode, localizer, price, quantity, showFreeWhenZero],
		);
		
		return <ListItem
			divider
			className={classes.shoppingCartConfiguration}
			style={{
				...style,
			}}
		>
			<div
				className={classes.DescriptionContainer}
			>
				<Typography
					variant="body1"
					color="textPrimary"
				>
					{
						quantity === undefined
							? labelText
							: `${quantity}× ${labelText}`
					}
				</Typography>
			</div>
			{priceLabel}
		</ListItem>;
	};