import { SerializationProfile } from '../../Util/Serialization/Serialization';

export class PlaceCode
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	readonly id: number;
	readonly hash: string;
	readonly placeId?: number;
	readonly businessId?: number;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor(
		id: number,
		hash: string,
		placeId?: number,
		businessId?: number,
	)
	{
		this.id = id;
		this.hash = hash;
		this.placeId = placeId;
		this.businessId = businessId;
	}
}

export const PlaceCodeProfile = SerializationProfile.create(PlaceCode);
