export function formatValidationMessagesToText(
    messages: string[],
    startFirstSentenceWithCapital: boolean = true,
    delimiter: string = '; '
): string | undefined
{
    if (messages.length === 0)
    {
        return undefined;
    }
    return messages.map((msg, i) => {
        let message = (i === 0 && startFirstSentenceWithCapital)
            ? msg.trim()[0].toUpperCase() + msg.trim().substr(1).replace(/\.$/, '')
            : msg.trim()[0].toLowerCase() + msg.trim().substr(1).replace(/\.$/, '');
        return message;
    }).join(delimiter) + '.';
}
