import { SvgIconProps } from '@material-ui/core';
import { makeAutoObservable } from 'mobx';
import { ComponentType } from 'react';
import { OrderDescriptor } from '../../../../Api/Order/OrderDescriptor';
import { ActionButtonVariant } from '../../../UI/action-button/ActionButtonVariant';

export class OrderAction
{
    // ------------------------- Properties -------------------------

    id: string;
    name: (order: OrderDescriptor) => string;
    isEnabled: (order: OrderDescriptor) => boolean;
    private readonly perform: ((order: OrderDescriptor) => void) | ((order: OrderDescriptor) => Promise<void>);
    importance: 'major' | 'minor';
    icon?: ComponentType<SvgIconProps> | string;
    className?: string;
    variant?: ActionButtonVariant;

    // ------------------------ Constructor -------------------------

    constructor(
        id: string,
        name: (order: OrderDescriptor) => string,
        isEnabled: (order: OrderDescriptor) => boolean,
        perform: ((order: OrderDescriptor) => void) | ((order: OrderDescriptor) => Promise<void>),
        importance: 'major' | 'minor',
        icon?: ComponentType<SvgIconProps> | string,
        className?: string,
        variant?: ActionButtonVariant,
    )
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });
        this.id = id;
        this.name = name;
        this.isEnabled = isEnabled;
        this.perform = perform;
        this.importance = importance;
        this.icon = icon;
        this.className = className;
        this.variant = variant;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    public execute(order: OrderDescriptor): Promise<void>
    {
        const result = this.perform(order);

        return result instanceof Promise
            ? result
            : Promise.resolve();
    }

    // ----------------------- Private logic ------------------------
}
