import { makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { FC } from 'react';
import { PaymentMethodDescriptor } from '../../../../../../../Api/Payment/PaymentMethodDescriptor';

const useStyles = makeStyles({
	text: {
	},
});

interface PaymentMethodLabelProps
{
	paymentMethod: PaymentMethodDescriptor;
}

export const PaymentMethodLabel: FC<PaymentMethodLabelProps> =
	({
		paymentMethod,
	}) =>
	{
		const classes = useStyles();

		return <Typography className={classes.text} variant="body1">
			{paymentMethod.name}
		</Typography>;
	};