// @ts-nocheck
/* eslint-disable */
import * as React from 'react';
import { FC, useCallback, useMemo } from 'react';
import { OrderEvent } from '../../../../../../../Api/Order/OrderEvent';
import { useLocalizer } from '../../../../../../../Bridge/Localization/useLocalizer';
import { getOrderEventPropertyLanguageEntry, getOrderEventTypeLanguageEntry, OrderEventProperty } from '../localization';
import { ORDER_EVENT_LONG_VALUE_THRESHOLD } from './OrderEventComponent';
import { OrderEventDetailsLine } from './OrderEventDetailsLine';

interface OrderEventDetailsProps
{
	orderEvent: OrderEvent
}

export const OrderEventDetails: FC<OrderEventDetailsProps> =
	(
		{
			orderEvent,
		},
	) =>
	{
		const localizer = useLocalizer();

		const hasFromState = useMemo(() => orderEvent.fromState !== undefined, [orderEvent.fromState]);
		const hasToState = useMemo(() => orderEvent.toState !== undefined, [orderEvent.toState]);
		const hasStates = useMemo(() => hasFromState || hasToState, [hasFromState, hasToState]);

		const translateProperty = useCallback((property: OrderEventProperty) => {
			const languageEntry = getOrderEventPropertyLanguageEntry(property);
			return localizer.translate(languageEntry);
		}, [localizer]);

		const typeComponent = useMemo(
			() =>
				<OrderEventDetailsLine
					inline label={translateProperty('type')}
					value={localizer.translate(getOrderEventTypeLanguageEntry(orderEvent.type))}
				/>,
			[localizer, orderEvent.type, translateProperty],
		);

		const isCodeLong = useMemo(() => orderEvent.code !== undefined && orderEvent.code.length > ORDER_EVENT_LONG_VALUE_THRESHOLD, [orderEvent.code]);

		const codeComponent = useMemo(
			() => <OrderEventDetailsLine inline={!isCodeLong} label={translateProperty('code')} value={orderEvent.code} />,
			[isCodeLong, orderEvent.code, translateProperty],
		);

		const refundRequestIdComponent = useMemo(
			() => <OrderEventDetailsLine inline label={translateProperty('refundRequest')} value={orderEvent.refundRequestId} />,
			[orderEvent.refundRequestId, translateProperty],
		);

		const isInformationLong = useMemo(() => orderEvent.information !== undefined && orderEvent.information.length > ORDER_EVENT_LONG_VALUE_THRESHOLD, [orderEvent.information]);

		const informationComponent = useMemo(
			() => <OrderEventDetailsLine inline={!isInformationLong} label={translateProperty('information')} value={orderEvent.data === undefined ? orderEvent.information : undefined} />,
			[isInformationLong, orderEvent.data, orderEvent.information, translateProperty],
		);

		const dataComponent = useMemo(
			() => <OrderEventDetailsLine inline={false} label={translateProperty('structuredInformation')} value={orderEvent.data !== undefined ? JSON.stringify(orderEvent.data) : undefined} />,
			[orderEvent.data, translateProperty],
		);

		return <>
			{typeComponent}
			{codeComponent}
			<OrderEventDetailsLine inline label={localizer.translate('Order-Event-Property-State-Before')} value={orderEvent.fromState} />
			<OrderEventDetailsLine inline label={localizer.translate('Order-Event-Property-State-After')} value={orderEvent.toState} />
			{refundRequestIdComponent}
			{informationComponent}
			{dataComponent}
		</>;
	};
