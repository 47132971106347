import * as React from 'react';
import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { PaymentMethodDescriptor } from '../../../../../Api/Payment/PaymentMethodDescriptor';
import { usePaymentMethodAvailabilityContext } from '../../availability/context/PaymentMethodAvailabilityContext';
import { PaymentTiming } from '../../model/PaymentTiming';
import { useIsPaymentMethodConfigurable } from './hooks/useIsPaymentMethodConfigurable';
import { useIsPaymentTimingBeforeOrder } from './hooks/useIsPaymentTimingBeforeOrder';
import { useMappedPaymentMethods } from './hooks/useMappedPaymentMethods';
import { usePaymentMethodValidation } from './hooks/usePaymentMethodValidation';
import { useSelectedPaymentMethod } from './hooks/useSelectedPaymentMethod';
import { useSelectInitialPaymentMethod } from './hooks/useSelectInitialPaymentMethod';
import { useSelectPaymentMethodIfOnlyOneExists } from './hooks/useSelectPaymentMethodIfOnlyOneExists';
import { useStoreSelectedPaymentMethod } from './hooks/useStoreSelectedPaymentMethod';

interface Context
{
	isBeforeOrder: boolean;
	isConfigurable: boolean;
	isInitialized: boolean;
	getPaymentMethodById: (id: string) => PaymentMethodDescriptor | undefined;
	selectedPaymentMethod?: PaymentMethodDescriptor;
	selectPaymentMethod: (paymentMethodId?: string) => void;
	validation: string[];
}

const ContextRef = createContext<Context>(undefined as never);

interface PaymentMethodContextProviderProps
{
	onChange?: (paymentMethod?: PaymentMethodDescriptor) => void;
	resetAfterPayment: boolean;
	timing: PaymentTiming;
}

export const PaymentMethodContextProvider: FC<PaymentMethodContextProviderProps> =
	({
		children,
		onChange,
		resetAfterPayment,
		timing,
	}) =>
	{
		const paymentMethods = usePaymentMethodAvailabilityContext();

		const arePaymentMethodsInitialized = useMemo(() => paymentMethods !== undefined, [paymentMethods]);

		const isBeforeOrder = useIsPaymentTimingBeforeOrder(timing);

		const paymentMethodById = useMappedPaymentMethods(paymentMethods);

		const [selectedPaymentMethod, setSelectedPaymentMethod] = useSelectedPaymentMethod(paymentMethodById, onChange);

		const validation = usePaymentMethodValidation(selectedPaymentMethod);

		const isConfigurable = useIsPaymentMethodConfigurable(paymentMethods, isBeforeOrder);

		const didSelectInitialPaymentMethod = useSelectInitialPaymentMethod(setSelectedPaymentMethod, arePaymentMethodsInitialized, resetAfterPayment);

		useSelectPaymentMethodIfOnlyOneExists(setSelectedPaymentMethod, paymentMethods, selectedPaymentMethod, didSelectInitialPaymentMethod);

		useStoreSelectedPaymentMethod(selectedPaymentMethod, didSelectInitialPaymentMethod);

		const getPaymentMethodById = useCallback((id: string) => paymentMethodById?.get(id), [paymentMethodById]);

		const contextValue = useMemo(
			() => ({isBeforeOrder, isConfigurable, isInitialized: didSelectInitialPaymentMethod, getPaymentMethodById, selectedPaymentMethod, selectPaymentMethod: setSelectedPaymentMethod, validation}),
			[didSelectInitialPaymentMethod, getPaymentMethodById, isBeforeOrder, isConfigurable, selectedPaymentMethod, setSelectedPaymentMethod, validation],
		);

		return <ContextRef.Provider value={contextValue}>
			{children}
		</ContextRef.Provider>;
	};

export function usePaymentMethodContext(): Context
{
	return useContext(ContextRef);
}