import { useMemo } from 'react';
import * as React from 'react';
import { useLocalizer } from '../../../../Bridge/Localization/useLocalizer';
import { useTranslate } from '../../../../Bridge/Localization/useTranslate';

export function useAllowedCharactersCheck(
	value: string | undefined,
	allowedCharacters: string[] | undefined,
	showValidation: boolean = false,
)
{
	const translate = useTranslate();
	const allowedCharactersSummationString = useMemo(
		() => {
			if (allowedCharacters !== undefined)
			{
				const summation: JSX.Element[] = [];
				let key = 0;
				for (let i = 0; i < allowedCharacters.length; i++)
				{
					const allowedCharacter = allowedCharacters[i];
					summation.push(<code key={key++}>{allowedCharacter}</code>);
					if (i < allowedCharacters.length - 2)
					{
						summation.push(<React.Fragment key={key++}>{', '}</React.Fragment>);
					}
					else if (i === allowedCharacters.length - 2)
					{
						summation.push(<React.Fragment key={key++}>{` ${translate('Generic-And')} `}</React.Fragment>);
					}
				}
				return summation;
			}
		},
		[allowedCharacters, translate]
	);
	const regex = useMemo(
		() => {
			if (allowedCharacters !== undefined)
			{
				let pattern = '^(';
				for (let i = 0; i < allowedCharacters.length; i++)
				{
					const allowedCharacter = allowedCharacters[i];
					pattern += `[${allowedCharacter}]`;
					if (i < allowedCharacters.length - 1)
						pattern += '|';
				}
				pattern += ')*$';
				return new RegExp(pattern);
			}
		},
		[allowedCharacters]
	);
	const localizer = useLocalizer();
	const text = useMemo(
		() => {
			if (allowedCharactersSummationString !== undefined)
				return localizer
					.translateWithComponents(
						'Generic-Allowed-Characters',
						<>{allowedCharactersSummationString}</>,
					)
					.map(value => typeof value === 'string'
						? <>{value}</>
						: value,
					);
		},
		[allowedCharactersSummationString, localizer]
	);
	const regexMatches = useMemo(
		() => {
			if (value !== undefined)
				return regex === undefined || regex.test(value);
		},
		[regex, value]
	);
	const allowedCharactersError = useMemo(
		() => {
			if (regexMatches === false)
				return text;
		},
		[regexMatches, text]
	)
	const allowedCharactersHelperText = useMemo(
		() => {
			if (text !== undefined && !(showValidation && allowedCharactersError !== undefined))
				return text;
		},
		[allowedCharactersError, showValidation, text]
	);
	return {
		allowedCharactersError,
		allowedCharactersHelperText,
	};
}