import { makeStyles, Typography } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { AppliedRewardCard } from './AppliedRewardCard';
import { AppliedRewardsStore } from './AppliedRewardsStore';

const useStyles = makeStyles(theme => ({
	list: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: theme.spacing(1),
		marginBottom: 10,
	},
}));

export interface AppliedRewardsProps
{
	store: AppliedRewardsStore;
}

export const AppliedRewards: FC<AppliedRewardsProps> =
	({
		store,
	}) =>
	{
		const classes = useStyles();

		const translate = useTranslate();

		const hasActiveLoyaltyRewards = useObserver(() => store.hasActiveLoyaltyRewards);
		const addedLoyaltyDeals = useObserver(() => store.addedLoyaltyDeals);
		const addedLoyaltyRewards = useObserver(() => store.addedLoyaltyRewards);

		return <div
			style={{
				alignItems: 'center',
				width: '100%',
			}}
		>
			{
				hasActiveLoyaltyRewards
					? <div
						className={classes.list}
					>
						{
							addedLoyaltyDeals
								.map((reward) =>
									<AppliedRewardCard
										key={reward.externalId}
										reward={reward}
									/>,
								)
						}
						{
							addedLoyaltyRewards
								.map((reward) =>
									<AppliedRewardCard
										key={reward.externalId}
										reward={reward}
										onRemove={() => store.removeReward(reward)}
									/>,
								)
						}
					</div>
					: <Typography
						variant="body1"
						color="textSecondary"
					>
						{
							translate('Client-Loyalty-Como-No-Rewards-Added')
						}
					</Typography>
			}
		</div>;
	};
