import { BaseStore } from '@intentic/ts-foundation';
import Decimal from 'decimal.js';
import { action, computed, makeObservable, observable } from 'mobx';
import { Announcement } from '../../../../../Api/Business/Announcement';
import { Membership } from '../../../../../Api/Order/Loyalty/Como/Membership';
import { Reward } from '../../../../../Api/Order/Loyalty/Como/Reward';
import { Screens } from '../../../../../Constants/ScreenConstants';
import { postJsonAny } from '../../../../../Util/Api';
import { getFileUrl } from '../../../../../Util/Api/Resources/getFileUrl';
import { BusinessStore } from '../../BusinessStore';
import { ShoppingCartStore } from '../../ShoppingCart/ShoppingCartStore';
import { ComoRewardStore } from '../Reward/ComoRewardStore';

export class ComoRewardsStore extends BaseStore
{
	// ------------------------ Dependencies ------------------------

	// ------------------------- Properties -------------------------

	public readonly shoppingCartStore: ShoppingCartStore;

	public readonly businessStore: BusinessStore;

	public tabIndex: number = 0;

	membership: Membership | undefined;

	// ------------------------ Constructor -------------------------

	constructor(
		businessStore: BusinessStore,
		shoppingCartStore: ShoppingCartStore,
	)
	{
		super();

		makeObservable(
			this,
			{
				tabIndex: observable,
				membership: observable,
				announcement: computed,
				rewards: computed,
				rewardsById: computed,
				refreshMembership: action.bound,
				setTabIndex: action.bound,
				setMembership: action.bound,
			},
		);

		this.businessStore = businessStore;
		this.shoppingCartStore = shoppingCartStore;
	}

	// ----------------------- Initialization -----------------------

	async refreshMembership(): Promise<void>
	{
		try
		{
			this.setMembership(await this.getMemberDetails());
		}
		catch (e)
		{
			console.error('Could not refresh membership, Como loyalty rewards are not activated', e);
		}
	}

	getMemberDetails(): Promise<Membership>
	{
		return postJsonAny<Membership>(
			'/client/business/loyalty/como/membership',
			{},
			{
				lines: this
					.shoppingCartStore
					.currentOrderService
					.configurations
					.map(configuration => ({
						type: 'product',
						product_id: configuration.product.id,
						quantity: this.shoppingCartStore
							.currentOrderService
							.writeAheadCartContents
							.get(configuration),
						product_variants: configuration
							.variantConfigurations
							.map(variant => ({
								product_feature_variant_id: variant.variant.id,
								product_feature_assignment_id: variant.assignment.id,
							})),
					})),
			},
		);
	}

	// -------------------------- Computed --------------------------

	get announcement()
	{
		if (this.membership === undefined)
		{
			const announcement = new Announcement(
				0,
				'standard',
				this.shoppingCartStore.bridge.localizer.translate('Exception-Message-Api-Generic'),
				true,
				false,
				true,
			);

			if (this.businessStore.loyaltyIntegrationLogoUrl !== undefined)
				(announcement as any).rightImageUrl = getFileUrl(this.businessStore.loyaltyIntegrationLogoUrl);

			return announcement;
		}
		else
		{
			const points = new Decimal(this.membership!.points).mul(new Decimal(10).pow(-this.shoppingCartStore.currentOrderService.currency.decimalPlaces)).toString();

			const announcement = new Announcement(
				0,
				'standard',
				this.shoppingCartStore.bridge.localizer.translate('Client-Loyalty-Como-Points-Balance-Description', points),
				true,
				false,
				true,
			);

			(announcement as any).title = this.shoppingCartStore.bridge.localizer.translate('Client-Loyalty-Como-Points-Balance');

			if (this.businessStore.loyaltyIntegrationLogoUrl !== undefined)
				(announcement as any).rightImageUrl = getFileUrl(this.businessStore.loyaltyIntegrationLogoUrl);

			return announcement;
		}
	}

	get rewards(): ReadonlyArray<Reward>
	{
		return this.membership!.rewards;
	}

	setTabIndex(tabIndex: number): void
	{
		this.tabIndex = tabIndex;
	}

	get rewardsById(): ReadonlyMap<string, Reward>
	{
		return this
			.rewards
			.reduce<Map<string, Reward>>((map, element) => map.set(element.id, element), observable.map());
	}

	setMembership(membership: Membership): void
	{
		this.membership = membership;
	}

	// --------------------------- Stores ---------------------------

	// -------------------------- Actions ---------------------------

	// ------------------------ Public logic ------------------------

	openOrderBuilder()
	{
		return this.businessStore.openOrderBuilder();
	}

	openReward(reward: Reward): Promise<ComoRewardStore>
	{
		const comoRewardStore = new ComoRewardStore(
			this,
			reward.id,
		);

		return this.businessStore.bridge.navigator.pushScreen(Screens.ComoReward, comoRewardStore)
			.then(
				() =>
					Promise.resolve(comoRewardStore),
			);
	}

	// ----------------------- Private logic ------------------------
}
