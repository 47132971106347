import { makeStyles, Typography } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import Decimal from 'decimal.js';
import { useObserver } from 'mobx-react-lite';
import { FC, useContext, useMemo } from 'react';
import { BasketRequirements } from '../../../../../../Api/Order/Loyalty/Como/BasketRequirements';
import { useRootContext } from '../../../../../../RootContext';
import { isDefined } from '../../../../../../Util/isDefined';
import { BusinessContextRef } from '../../../BusinessContext';
import { ComoRewardStore } from '../ComoRewardStore';

const useStyles = makeStyles(theme => ({
	typography: {
		transition: theme.transitions.create('color', {
			easing: theme.transitions.easing.easeInOut,
			duration: theme.transitions.duration.standard,
		}),
	},
}));

interface ComoBasketRequirementsTextProps
{
	basketRequirements: BasketRequirements;
	store: ComoRewardStore;
	totalOrdered: number;
	totalSpend: Decimal;
}

export const ComoBasketRequirementsText: FC<ComoBasketRequirementsTextProps> =
	({
		basketRequirements,
		store,
		totalOrdered,
		totalSpend,
	}) =>
	{
		const theme = useTheme();
		const {localizer} = useRootContext(true);
		const {businessStore} = useContext(BusinessContextRef);

		const currencyCode = useObserver(() => businessStore.business.productCurrencyCode);
		const hasError = useObserver(() => store.hasErrorByBasketRequirements.get(basketRequirements));
		const showValidation = useObserver(() => store.showValidation);

		const sanitizedText = useMemo(
			() =>
			{
				if (basketRequirements.text === undefined)
				{
					return undefined;
				}
				else
				{
					const trimmedText = basketRequirements.text.trim();

					return trimmedText.length > 0
						? trimmedText
						: undefined;
				}
			},
			[basketRequirements.text],
		);

		const minimumQuantityText = useMemo(
			() =>
			{
				if (basketRequirements.conditions?.quantity !== undefined)
				{
					const minimum = basketRequirements.conditions.quantity.minimum;

					return `(${Math.min(totalOrdered, minimum)}/${minimum})`;
				}
			},
			[basketRequirements.conditions.quantity, totalOrdered],
		);

		const minimumSpendText = useMemo(
			() =>
			{
				if (basketRequirements.conditions?.spend !== undefined)
				{
					const fractionalDigits = localizer.getCurrencyFractionDigits(currencyCode);
					const minimum = new Decimal(basketRequirements.conditions.spend.minimum)
						.mul(new Decimal(10).pow(-fractionalDigits));

					return `(${localizer.formatCurrency(totalSpend.clamp(0, minimum), currencyCode)}/${localizer.formatCurrency(minimum, currencyCode)})`;
				}
			},
			[basketRequirements.conditions.spend, currencyCode, localizer, totalSpend],
		);

		const compositeText = useMemo(
			() =>
				[
					sanitizedText,
					minimumQuantityText,
					minimumSpendText,
				]
					.filter(isDefined)
					.join(' '),
			[minimumQuantityText, minimumSpendText, sanitizedText],
		);

		const classes = useStyles();

		if (compositeText === undefined)
		{
			return null;
		}
		else
		{
			return <Typography
				className={classes.typography}
				variant="h6"
				style={{
					color: showValidation && hasError
						? theme.palette.error.main
						: undefined,
				}}
			>
				{compositeText}
			</Typography>;
		}
	};
