import { Collapse, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TRADITIONAL_PAYMENT_METHOD_ID } from '../../../../../../Api/Payment/PaymentMethodDescriptor';
import { usePaymentMethodAvailabilityContext } from '../../../availability/context/PaymentMethodAvailabilityContext';
import { PaymentTiming } from '../../../model/PaymentTiming';
import { usePaymentMethodContext } from '../../context/PaymentMethodContext';
import { PaymentMethodValidationText } from './PaymentMethodValidationText';
import { PaymentMethodSelection } from './selection/PaymentMethodSelection';
import { PaymentMethodSettings } from './settings/PaymentMethodSettings';
import { PaymentTimingSelector } from './timing/PaymentTimingSelector';

const useStyles = makeStyles(theme => ({
	bottomMargin: {
		marginBottom: theme.spacing(2),
	},
	contentMargins: {
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	lastChildHasBottomMargin: {
		'& > :last-child': {
			marginBottom: theme.spacing(2),
		},
	},
	topMargin: {
		marginTop: theme.spacing(2),
	},
}));

interface PaymentMethodEditorProps
{
	onFocusChange: (hasFocus: boolean) => void;
}

export const PaymentMethodEditor: FC<PaymentMethodEditorProps> =
	({
		onFocusChange,
	}) =>
	{
		const paymentMethods = usePaymentMethodAvailabilityContext();
		const {selectedPaymentMethod, selectPaymentMethod} = usePaymentMethodContext();

		const classes = useStyles();

		const [selectedPaymentTiming, setSelectedPaymentTiming] = useState<PaymentTiming | undefined>(() =>
		{
			if (selectedPaymentMethod === undefined)
				return undefined;
			else if (selectedPaymentMethod.id === TRADITIONAL_PAYMENT_METHOD_ID)
				return 'afterwards';
			else
				return 'beforehand';
		});

		const paymentMethodValidationTextClassName = clsx(classes.contentMargins, classes.bottomMargin);

		const showPaymentMethodConfiguration = useMemo(() =>
		{
			if (selectedPaymentTiming === 'afterwards')
				return false;
			else
				return paymentMethods?.some(paymentMethod => paymentMethod?.id !== TRADITIONAL_PAYMENT_METHOD_ID);
		}, [paymentMethods, selectedPaymentTiming]);

		const adyenRootClassName = clsx(classes.topMargin, classes.contentMargins, classes.lastChildHasBottomMargin);

		const handleSelectedPaymentTimingChange = useCallback((timing: PaymentTiming) =>
		{
			setSelectedPaymentTiming(timing);

			if (timing === 'afterwards')
				selectPaymentMethod(TRADITIONAL_PAYMENT_METHOD_ID);
		}, [selectPaymentMethod]);

		useEffect(() =>
		{
			if (selectedPaymentMethod !== undefined)
			{
				if (selectedPaymentMethod.id === TRADITIONAL_PAYMENT_METHOD_ID)
					setSelectedPaymentTiming('afterwards');
				else
					setSelectedPaymentTiming('beforehand');
			}
		}, [selectedPaymentMethod]);

		return <>
			<PaymentTimingSelector
				className={classes.contentMargins}
				onChange={handleSelectedPaymentTimingChange}
				onFocusChange={onFocusChange}
				value={selectedPaymentTiming}
			/>
			<Collapse in={showPaymentMethodConfiguration} unmountOnExit>
				<PaymentMethodSelection />
				<PaymentMethodSettings
					adyenRootClassName={adyenRootClassName}
					issuerRootClassName={classes.bottomMargin}
				/>
			</Collapse>
			<PaymentMethodValidationText className={paymentMethodValidationTextClassName} />
		</>;
	};