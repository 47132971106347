import { PaymentState } from '../../../../Api/Order/Order';
import { postAny } from '../../../../Util/Api';

export function updateOrderPaymentState(
	orderId: number | number[],
	state: PaymentState,
	businessId: number,
): Promise<void>
{
	let id: number | undefined;
	let ids: number[] | undefined;

	if (orderId instanceof Array)
	{
		ids = orderId;
	}
	else
	{
		id = orderId;
	}

	return postAny(
		'/waiter/business/order/payment-state/update',
		{
			order_id: id,
			order_ids: ids,
			payment_state: state,
			businessId: businessId,
		});
}