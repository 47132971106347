import { createStyles, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { ReactNode } from 'react';

const useStyles =
    makeStyles(
        () => createStyles({
            listItemBadgeWrapper: {
                marginRight: 5,
                marginTop: 5,
                display: 'inline-block',
                verticalAlign: 'middle',
            },
        })
    );

export interface ListItemSecondaryBadgesProps
{
    secondaryBadges?: ReactNode[];
}

export const ListItemSecondaryBadges: React.FC<ListItemSecondaryBadgesProps> =
    ({
        secondaryBadges,
    }) =>
    {
        const classes = useStyles();

        if ((secondaryBadges ?? []).length > 0)
        {
            return <div
                style={{
                    display: 'inline-block',
                    marginBottom: 5,
                }}
            >
                {secondaryBadges.map(
                    (badge, idx) =>
                        <div
                            key={idx}
                            className={classes.listItemBadgeWrapper}
                        >
                            {badge}
                        </div>)}
            </div>;
        }
        else
        {
            return null;
        }
    };
