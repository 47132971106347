import * as React from 'react';
import { createContext, FC, useContext, useMemo } from 'react';
import { useWebClient } from '../../Bridge/Client/WebClientProvider';
import { CurrentPlaceService } from '../../Service/CurrentPlace/CurrentPlaceService';
import { IllegalStateException } from '../../Util/Exception/IllegalStateException';
import { useLocationService } from '../location-service/LocationService';
import { useStorage } from '../Root/StorageContextProvider';

interface Context
{
	currentPlaceService: CurrentPlaceService
}

const ContextRef = createContext<Context | undefined>(undefined);

export const CurrentPlaceServiceProvider: FC =
	(
		{
			children,
		},
	) =>
	{
		const client = useWebClient(true);
		const storage = useStorage(true);
		const locationService = useLocationService();
		const currentPlaceService = useMemo(
			() => {
				const currentPlaceService = new CurrentPlaceService(storage, client, locationService);
				currentPlaceService.registerEventListeners();
				return currentPlaceService;
			},
			[client, locationService, storage]
		);
		return <ContextRef.Provider
			value={useMemo(() => ({
				currentPlaceService,
			}), [currentPlaceService])}
		>
			{children}
		</ContextRef.Provider>;
	};

export function useCurrentPlaceService(): CurrentPlaceService
{
	const context = useContext(ContextRef);
	return useMemo(
		() => {
			if (context === undefined)
				throw new IllegalStateException(
					'Attempting to use useCurrentPlaceService outside CurrentPlaceServiceProvider'
				);
			return context.currentPlaceService;
		},
		[context],
	);
}