import * as React from 'react';
import { ComponentType, createElement, CSSProperties, FC, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button as MuiButton, CircularProgress, makeStyles, SvgIconProps, Theme, Typography } from '@material-ui/core';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button/Button';
import { UIColors } from '../../../Constants/UIColors';

const useStyle = makeStyles<Theme, ButtonColorProps>((theme: Theme) => ({
	buttonIcon: {
		marginLeft: theme.spacing(2),
	},
	buttonLoader: {
		color: UIColors.greyDark,
		marginLeft: theme.spacing(2),
	},
	buttonRoot: ({backgroundColor, contentColor}) => ({
		backgroundColor: backgroundColor,
		color: contentColor,

		'&:disabled': {
			color: `${UIColors.greyDark} !important`,
		},
	}),
}));

interface ButtonColorProps
{
	backgroundColor?: string;
	contentColor?: string;
}

export interface ButtonProps extends MuiButtonProps
{
	icon?: ComponentType<SvgIconProps>;
	onClick: (() => void) | (() => Promise<void>);
	style?: CSSProperties;
	text?: string;
}

export const Button: FC<ButtonColorProps & ButtonProps> =
	(
		{
			backgroundColor,
			children,
			classes: classesProp,
			contentColor,
			disabled,
			icon,
			onClick,
			style,
			text,
			variant,
			...otherProps
		},
	) =>
	{
		const defaultClasses = useStyle({
			backgroundColor,
			contentColor,
		});

		const isUnmounted = useRef(false);

		useEffect(
			() =>
				() =>
				{
					isUnmounted.current = true;
				},
			[],
		);

		const classes = useMemo(
			() =>
			{
				if (classesProp)
				{
					const {root, ...otherClasses} = classesProp;

					return {
						...otherClasses,
						root: root ? `${defaultClasses.buttonRoot} ${root}` : defaultClasses.buttonRoot,
					};
				}
				else
				{
					return {
						root: defaultClasses.buttonRoot,
					};
				}
			},
			[classesProp, defaultClasses.buttonRoot],
		);

		const [isLoading, setIsLoading] = useState(false);

		const onClicked = useCallback(
			(event: MouseEvent<HTMLButtonElement>) =>
			{
				event.stopPropagation();

				const result = onClick();

				if (result instanceof Promise)
				{
					setIsLoading(true);
					result
						.then(() => !isUnmounted.current && setIsLoading(false))
						.catch(
							error => {
								!isUnmounted.current && setIsLoading(false);
								throw error;
							},
						);
				}
			},
			[onClick],
		);

		const loader = useMemo(() => <CircularProgress className={defaultClasses.buttonLoader} size={24} />, [defaultClasses.buttonLoader]);

		return <MuiButton
			classes={classes}
			disabled={disabled || isLoading}
			onClick={event => onClicked(event)}
			style={{
				...style,
				color: contentColor,
			}}
			variant={variant ?? 'contained'}
			{...otherProps}
		>
			{
				text &&
				<Typography>
					{text}
				</Typography>
			}
			{
				isLoading
					? loader
					: icon && createElement<SvgIconProps>(icon, {className: defaultClasses.buttonIcon})
			}
			{children}
		</MuiButton>;
	};
