import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { MediaButtonV2BorderRadius, MediaButtonV2BorderRadiusOnSmallScreen, MediaButtonV2Shadow } from '../../media_button_v2/MediaButtonV2';
import { SwiperControlDataItem } from '../Model/SwiperControlDataItem';

const useStyles =
	makeStyles(
		() => ({
			item: {
				boxShadow: MediaButtonV2Shadow,
				borderRadius: MediaButtonV2BorderRadius,
				overflow: 'hidden',
			},
			smallScreen: {
				borderRadius: MediaButtonV2BorderRadiusOnSmallScreen,
			},
			first: {

			},
			last: {

			}
		})
	);

interface SwiperControlItemProps
{
	item: SwiperControlDataItem<any>;
	first?: boolean;
	last?: boolean;
	smallScreen?: boolean;
}

export const SwiperControlItem: FC<SwiperControlItemProps> =
	(
		{
			item,
			first,
			last,
			smallScreen,
		},
	) =>
	{
		const classes = useStyles();
		const style =
			useObserver(
				() =>
					item.getStyle === undefined
						? undefined
						: item.getStyle(item.value),
			);
		const node =
			useObserver(
				() =>
					item.getNode(item.value)
			);

		return <div
			className={
				clsx(
					classes.item,
					first && classes.first,
					last && classes.last,
					smallScreen && classes.smallScreen,
				)
			}
			style={style}
		>
			{node}
		</div>;
	};
