import { PaymentData } from '@adyen/adyen-web/dist/types/components/types';
import { RiskData } from '@adyen/adyen-web/dist/types/core/RiskModule/RiskModule';

export type AdyenCheckoutData =
	(PaymentData | RiskData) &
	{
		details?: Record<string, string>
	};

export function isPaymentData(value: AdyenCheckoutData | undefined): value is PaymentData
{
	return typeof value !== 'boolean'
		&& typeof value !== 'string';
}
