import { action, makeAutoObservable } from 'mobx';
import { ClientAgeVerificationChallenge } from '../../Api/age_verification/ClientAgeVerificationChallenge';
import { ClientAgeVerificationChallengeFulfillmentRequest } from '../../Api/age_verification/ClientAgeVerificationChallengeFulfillmentRequest';
import { ClientAgeVerificationChallengeFulfillmentResponse } from '../../Api/age_verification/ClientAgeVerificationChallengeFulfillmentResponse';
import { Bridge } from '../../Bridge/Bridge';
import { Localizer } from '../../Bridge/Localization/Localizer';
import { Notification } from '../../Bridge/Notification/Notification';
import { ShoppingCartStore } from '../../Component/Page/Business/ShoppingCart/ShoppingCartStore';
import { postJsonAny } from '../../Util/Api';
import { ClockService } from '../../Util/clock/ClockService';
import { CurrentPlaceService } from '../CurrentPlace/CurrentPlaceService';

export class CurrentAgeVerificationService
{
	/*---------------------------------------------------------------*
	 *                         Dependencies                          *
	 *---------------------------------------------------------------*/

	private readonly clockService: ClockService;
	private readonly localizer: Localizer;
	private readonly currentPlaceService: CurrentPlaceService;
	private readonly shoppingCartStore: ShoppingCartStore;

	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	public challenge?: ClientAgeVerificationChallenge;
	public isLoading: boolean;

	/*---------------------------------------------------------------*
	 *                          Constructor                          *
	 *---------------------------------------------------------------*/

	constructor(
		bridge: Bridge,
		currentPlaceService: CurrentPlaceService,
		shoppingCartStore: ShoppingCartStore
	)
	{
		makeAutoObservable(
			this,
			undefined,
			{
				autoBind: true,
				deep: false,
			}
		);

		this.clockService = bridge.clockService;
		this.localizer = bridge.localizer;
		this.currentPlaceService = currentPlaceService;
		this.shoppingCartStore = shoppingCartStore;
		this.isLoading = false;
	}

	/*---------------------------------------------------------------*
	 *                        Initialization                         *
	 *---------------------------------------------------------------*/

	/*---------------------------------------------------------------*
	 *                           Computed                            *
	 *---------------------------------------------------------------*/

	get needsAgeVerification(): boolean
	{
		return this.currentPlaceService.place.isAgeVerificationRequiredForRestrictedProducts
			&& this.shoppingCartStore
				.configurations
				.some(
					configuration =>
						configuration.product.needsAgeVerification
				);
	}

	setChallenge(challenge: ClientAgeVerificationChallenge | undefined): void
	{
		this.challenge = challenge;
	}

	async scanVoucher(
		link: string,
		notification: Notification
	): Promise<boolean>
	{
		if (this.isAgeVerificationVoucherLink(link))
		{
			try
			{
				this.setLoading(true);
				const token = this.getTokenFromAgeVerificationVoucherLink(link);
				const challenge = await this.getOrCreateChallenge();
				const request: ClientAgeVerificationChallengeFulfillmentRequest = {
					type: 'Voucher',
					token,
				};
				const response =
					await postJsonAny<ClientAgeVerificationChallengeFulfillmentResponse>(
						`/clientAgeVerificationChallenges/${challenge.id}/fulfill`,
						undefined,
						request
					);
				this.setChallenge({
					...challenge,
					isPassed: response.isPassed,
				});

				if (response.isPassed)
				{
					notification.notify({
						content: this.localizer.translate('AgeVerification-Verified'),
						variant: 'success',
					});
				}
				else if (response.errorCode)
				{
					notification.notify({
						content: this.localizer.translate(`AgeVerification-Voucher-Error-${response.errorCode}`),
						variant: 'warning',
					});
				}

				return response.isPassed;
			}
			finally
			{
				this.setLoading(false);
			}
		}
		else
		{
			notification.notify({
				content: this.localizer.translate('AgeVerification-Voucher-Error-InvalidVoucher'),
				variant: 'warning',
			});

			return false;
		}
	}

	async getOrCreateChallenge()
	{
		if (this.challenge === undefined
			|| this.isChallengeExpired
			|| this.isChallengePassed)
		{
			const challenge =
				await postJsonAny<ClientAgeVerificationChallenge>(
					'/clientAgeVerificationChallenges',
					undefined,
					{
						type: 'Voucher',
					}
				);
			this.setChallenge(challenge);

			return challenge;
		}
		else
		{
			return this.challenge;
		}
	}

	get challengeExpirationDate(): Date
	{
		if (this.challenge)
		{
			return new Date(this.challenge.expirationDate);
		}
		else
		{
			return undefined;
		}
	}

	get isChallengeExpired(): boolean
	{
		if (this.challengeExpirationDate)
		{
			return this.clockService.currentSecond > this.challengeExpirationDate;
		}
		else
		{
			return false;
		}
	}

	get isChallengePassed(): boolean
	{
		if (this.isChallengeExpired)
		{
			return false;
		}
		else
		{
			if (this.challenge)
			{
				return this.challenge.isPassed;
			}
			else
			{
				return false;
			}
		}
	}

	/*---------------------------------------------------------------*
	 *                            Actions                            *
	 *---------------------------------------------------------------*/

	@action.bound
	setLoading(isLoading: boolean)
	{
		this.isLoading = isLoading;
	}

	/*---------------------------------------------------------------*
	 *                          Public logic                         *
	 *---------------------------------------------------------------*/

	isAgeVerificationVoucherLink(link: string): boolean
	{
		const isLink =
			link.startsWith(
				`${window.location.origin}/ageVerificationVoucher`
			);

		if (isLink)
		{
			const url = new URL(link);

			return url.searchParams.has('token');
		}
		else
		{
			return false;
		}
	}

	/*---------------------------------------------------------------*
	 *                         Private logic                         *
	 *---------------------------------------------------------------*/

	private getTokenFromAgeVerificationVoucherLink(link: string): string
	{
		const url = new URL(link);

		return url.searchParams.get('token');
	}
}