import { makeStyles } from '@material-ui/core';
import CardBulletedIcon from 'mdi-material-ui/CardBulleted';
import HandCoinOutlineIcon from 'mdi-material-ui/HandCoinOutline';
import * as React from 'react';
import { FC } from 'react';
import { PaymentMethodId } from '../../../../../Api/Payment/PaymentMethodId';

const useStyles = makeStyles(theme => ({
	icon: {
		color: theme.palette.primary.contrastText,
		fontSize: 100,
	},
	root: {
		marginBottom: theme.spacing(2),
		marginTop: 'auto',
	},
}));

interface OrderPaymentInProgressPageIconProps
{
	paymentMethod?: PaymentMethodId;
}

export const OrderPaymentInProgressPageIcon: FC<OrderPaymentInProgressPageIconProps> =
	({
		paymentMethod,
	}) =>
	{
		const classes = useStyles();

		if (paymentMethod === 'Cash')
		{
			return <div
				className={classes.root}
			>
				<HandCoinOutlineIcon
					className={classes.icon}
					// height={54}
					// width={54}
				/>
			</div>;
		}
		else
		{
			return <div
				className={classes.root}
			>
				<CardBulletedIcon
					className={classes.icon}
					// height={54}
					// width={54}
				/>
			</div>;
		}
	};
