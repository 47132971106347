import { Box, Tooltip, Zoom } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import * as React from 'react';
import { FC, useContext } from 'react';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { UIColors } from '../../../../../Constants/UIColors';
import { EntranceContextRef } from '../../../Entrance/EntranceContext';
import { ComoRedeemState } from './ComoRedeemState';

interface ComoRewardRedeemBadgeProps
{
	state: Omit<ComoRedeemState, 'Redeemable'>;
}

export const ComoRewardRedeemBadge: FC<ComoRewardRedeemBadgeProps> =
	({
		state,
	}) =>
	{
		const {brandingService} = useContext(EntranceContextRef);
		const translate = useTranslate();

		return <Zoom
			in
		>
			<Box
				sx={{
					borderRadius: '50%',
					// Solved in newer versions of Material-UI:
					// @ts-ignore
					backgroundColor: state === 'Redeemed'
						? brandingService.tintColor.css
						: UIColors.warning,
					color: state === 'Redeemed'
						? brandingService.tintContrastTextColor.css
						: 'white',
					height: 25,
					width: 25,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				{
					state === 'Redeemed'
						? <CheckIcon
							fontSize="small"
						/>
						: <Tooltip
							enterTouchDelay={0}
							leaveTouchDelay={3000}
							title={translate('Client-Loyalty-Como-RewardWillBeRedeemed')}
							onClick={
								event =>
								{
									event.stopPropagation();
								}
							}
						>
							<MoreHorizIcon
								fontSize="small"
							/>
						</Tooltip>
				}
			</Box>
		</Zoom>;
	};
