import { postAny } from '../../../../Util/Api';

export function voidOrders(orderId: number | number[], reputationRating?: number): Promise<any>
{
	let id: number | undefined;
	let ids: number[] | undefined;

	if (orderId instanceof Array)
	{
		ids = orderId;
	}
	else
	{
		id = orderId;
	}

	return postAny(
		'/waiter/business/order/void',
		{
			orderID: id,
			order_ids: ids,
			reputation_rating: reputationRating || 0,
		});
}