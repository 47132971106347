import { Exception } from './Exception';

export class UninitializedStorageVariableException extends Exception
{
    // ------------------------- Properties -------------------------

    readonly name = 'UninitializedStorageVariableException';

    // ------------------------ Constructor -------------------------

    constructor(storedVariableKey: string)
    {
        super(
            `Attempted to access the value of storage variable ${storedVariableKey} which has not yet been initialized.`
        );
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
