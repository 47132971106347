import { makeAutoObservable } from 'mobx';

export const LANGUAGE_ENTRY_LANGUAGE_CODE_VALUES: LanguageEntryLanguageCode[] = ['de', 'en', 'es', 'fr', 'it', 'nl', 'sv'];
export type LanguageEntryLanguageCode = 'de' | 'en' | 'es' | 'fr' | 'it' | 'nl' | 'sv';

export class LanguageEntryTranslations
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	public de?: string;
	public en?: string;
	public es?: string;
	public fr?: string;
	public it?: string;
	public nl?: string;
	public sv?: string;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor(
		de?: string,
		en?: string,
		es?: string,
		fr?: string,
		it?: string,
		nl?: string,
		sv?: string,
	)
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});

		this.de = de;
		this.en = en;
		this.es = es;
		this.fr = fr;
		this.it = it;
		this.nl = nl;
		this.sv = sv;
	}

	/*---------------------------------------------------------------*
	 *                         Business Logic                        *
	 *---------------------------------------------------------------*/

	/*---------------------------------------------------------------*
	 *                      Getters and setters                      *
	 *---------------------------------------------------------------*/

	public translate(
		languageCode: LanguageEntryLanguageCode,
		fallback: boolean = false
	): string | undefined
	{
		const result = this[languageCode];
		if (result !== undefined || !fallback)
			return result;

		if (this.en !== undefined)
			return this.en;
		else if (this.de !== undefined)
			return this.de;
		else if (this.fr !== undefined)
			return this.fr;
		else if (this.nl !== undefined)
			return this.nl;
		else if (this.it !== undefined)
			return this.it;
		else if (this.sv !== undefined)
			return this.sv;
		else
			return undefined;
	}

	public set(languageCode: LanguageEntryLanguageCode, translation?: string): void
	{
		this[languageCode] = translation;
	}

	/*---------------------------------------------------------------*
	 *                        Nested classes                         *
	 *---------------------------------------------------------------*/
}

export function equalLanguageEntryTranslations(a: LanguageEntryTranslations | null | undefined, b: LanguageEntryTranslations | null | undefined)
{
	if (a === null || a === undefined || b === null || b === undefined)
		return a === b;
	else
		return a.de === b.de
			&& a.en === b.en
			&& a.es === b.es
			&& a.fr === b.fr
			&& a.it === b.it
			&& a.nl === b.nl
			&& a.sv === b.sv;
}