import UIElement from '@adyen/adyen-web/dist/types/components/UIElement';
import { CheckoutSessionPaymentResponse } from '@adyen/adyen-web/dist/types/types';
import { isPaymentData } from '../../../../../../lib/adyen/AdyenCheckoutData';
import { AdyenCheckoutState } from '../../../../../../lib/adyen/AdyenCheckoutState';
import { postJsonAny } from '../../../../../../Util/Api';
import { useEventCallback } from '../../../../../../Util/Event/useEventCallback';
import { useProcessingOrderContext } from '../../../../processing_order_context/ProcessingOrderContext';
import { useProcessAdyenCheckoutPaymentResponse } from '../../../hooks/useProcessAdyenCheckoutPaymentResponse';

export function useClientOnSubmit(): (state: AdyenCheckoutState, component: UIElement<unknown>) => Promise<void>
{
	const {processingOrder} = useProcessingOrderContext();

	const processAdyenCheckoutPaymentResponse = useProcessAdyenCheckoutPaymentResponse();

	return useEventCallback(
		async (state, component) =>
		{
			if (isPaymentData(state.data))
			{
				const {
					browserInfo,
					paymentMethod,
					riskData,
				} = state.data;

				if (processingOrder === undefined)
				{
					throw new Error('No payment in progress');
				}
				else
				{
					const paymentResponse = await postJsonAny<CheckoutSessionPaymentResponse>(
						'/client/payments/ButlarooPay',
						undefined,
						{
							orderId: processingOrder.id,
							browserInfo,
							paymentMethod,
							riskData,
						},
					);

					return processAdyenCheckoutPaymentResponse(
						paymentResponse,
						component,
					);
				}
			}
			else
			{
				throw new Error(`Invalid payment data: ${state.data}`);
			}
		},
	);
}
