import { Theme, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { CSSProperties, FC, useContext, useMemo, useState } from 'react';
import { UIColors } from '../../../../../../../Constants/UIColors';
import { EntranceContextRef } from '../../../../../Entrance/EntranceContext';
import { getDifference } from '../../../getDifferenceInSeconds';
import { LocalizedPrice } from '../LocalizedPrice';

const useStyles = makeStyles(
    (theme: Theme) =>
        ({
            containerLeft:
                {
                    display: 'flex',
                    // marginLeft: theme.spacing(2),
                },
            elapsedTimeText:
                {
                    marginLeft: theme.spacing(1),
                },
            root:
                {
                    borderTopLeftRadius: theme.shape.borderRadius,
                    borderTopRightRadius: theme.shape.borderRadius,
                    display: 'flex',
                    justifyContent: 'space-between',
                    transition:
                        theme.transitions.create(
                            'background-color',
                            {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.leavingScreen,
                            }),
                    paddingBottom: theme.spacing(1),
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2),
                    paddingTop: theme.spacing(1),
                },
            priceText:
                {
                    // marginRight: theme.spacing(2),
                },
        }));

interface BillOrderItemHeaderProps
{
    cost?: LocalizedPrice;
    date?: Date;
    selected: boolean;
    style?: CSSProperties;
}

export const BillOrderItemHeader: FC<BillOrderItemHeaderProps> =
    observer(
        ({
            cost,
            date,
            selected,
            style,
        }) =>
        {
            const classes = useStyles();

            const {
                clockService,
                localizer,
            } = useContext(EntranceContextRef);

            const [elapsedTime, setElapsedTime] = useState(
                date
                ? localizer.formatShortTimeString(getDifference(clockService.currentSecond, date, 'seconds'))
                : '');

            useMemo(
                () =>
                {
                    if (date)
                    {
                        const differenceInSeconds = getDifference(clockService.currentSecond, date, 'seconds');

                        setElapsedTime(localizer.formatShortTimeString(differenceInSeconds));
                    }
                },
                [clockService.currentSecond, date, localizer]);


            return <div
                className={classes.root}
                style={{
                    backgroundColor: selected ? UIColors.positive : UIColors.negative,
                    ...style,
                }}
            >
                <div
                    className={classes.containerLeft}
                >
                    {
                        selected
                        ? <CheckBox />
                        : <CheckBoxOutlineBlank />
                    }
                    <Typography
                        classes={{
                            root: classes.elapsedTimeText,
                        }}
                    >
                        {elapsedTime}
                    </Typography>
                </div>
                {
                    cost &&
                    <Typography
                        classes={{
                            root: classes.priceText,
                        }}
                    >
                        {localizer.formatCurrency(cost.price, cost.currencyCode)}
                    </Typography>
                }
            </div>;
        });