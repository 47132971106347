import { DependencyList, useEffect } from 'react';

export function useAsyncEffect<T>(
	effect: () => {
		promise: Promise<T> | undefined,
		then: (value: T) => void,
		catch?: (reason: any) => void,
	},
	dependencies: DependencyList,
): void
{
	// Run effect, call then if parent component is still mounted
	useEffect(() => {
		let ignore = false;
		const {promise, then, catch: catchHandler} = effect();

		promise?.then(value =>
		{
			if (!ignore)
				then(value);
		}).catch(reason =>
		{
			if (!ignore)
				catchHandler?.(reason);
		});

		return () => {
			ignore = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, dependencies);
}