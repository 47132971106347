import { Box, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import * as React from 'react';
import { FC } from 'react';
import { useBrandingService } from '../../../../../../branding-service/BrandingServiceProvider';

const useStyles =
	makeStyles(
		theme => ({
			root: {
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'center',
				gap: theme.spacing(1),
			},
			dot: {
				width: 12,
				height: 12,
				borderRadius: '50%',
			},
		})
	);

interface ProductDrawerConfigurationStepStatusProps
{
	numberOfSteps: number;
	stepIdx: number;
}

export const ProductDrawerConfigurationStepStatus: FC<ProductDrawerConfigurationStepStatusProps> =
	(
		{
			numberOfSteps,
			stepIdx,
		},
	) =>
	{
		const classes = useStyles();
		const brandingService = useBrandingService();

		if (numberOfSteps > 0)
		{
			return <div
				className={classes.root}
			>
				{
					new Array(numberOfSteps)
						.fill(0)
						.map(
							(_, idx) =>
								<Box
									key={idx}
									className={classes.dot}
									style={{
										backgroundColor:
											idx <= stepIdx
												? brandingService.tintColor.css
												: grey[300],
									}}
								/>
						)
				}
			</div>;
		}
		else
		{
			return null;
		}
	};
