import { Checkbox, FormControlLabel, Radio, Typography } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { FeatureConfigurationStore } from '../FeatureConfigurationStore';
import { FeatureConfigurationVariantPrice } from '../VariantPrice/FeatureConfigurationVariantPrice';

interface FeatureConfigurationWithoutImagesProps
{
    store: FeatureConfigurationStore;
}

export const FeatureConfigurationWithoutImages: FC<FeatureConfigurationWithoutImagesProps> =
    (
        {
            store,
        }
    ) =>
    {
        return useObserver(
            () =>
            {
                const {
                    featureAssignment,
                    selectedVariants,
                    selectorType,
                } = store;
                const productFeature = featureAssignment.productFeature;
                return <>
                    {
                        productFeature.variants.map(
                            variant =>
                                <div
                                    key={variant.id}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <FormControlLabel
                                        control={
                                            selectorType === 'checkbox'
                                                ? <Checkbox
                                                    checked={selectedVariants.has(variant)}
                                                    onChange={() => store.toggleSelection(variant)}
                                                    color="primary"
                                                />
                                                : <Radio
                                                    checked={selectedVariants.has(variant)}
                                                    onChange={() => store.toggleSelection(variant)}
                                                    color="primary"
                                                />
                                        }
                                        label={
                                            <Typography
                                                variant="body1"
                                            >
                                                {variant.name}
                                            </Typography>
                                        }
                                        style={{
                                            flex: '1 1 auto'
                                        }}
                                    />
                                    <Typography
                                        variant="body1"
                                    >
                                        <FeatureConfigurationVariantPrice
                                            store={store}
                                            variant={variant}
                                        />
                                    </Typography>
                                </div>)
                    }
                </>;
            }
        );
    };
