import { useEffect, useState } from 'react';
import { ExternalBill } from '../../../../../../../Api/ExternalBill/ExternalBill';
import { postAny } from '../../../../../../../Util/Api';
import { useExternalBillUpdater } from './useExternalBillUpdater';

interface ExternalBillResult
{
	externalBill?: ExternalBill;
	isLoading: boolean;
}

export function useExternalBill(): ExternalBillResult
{
	const [ externalBill, setExternalBill ] = useState<ExternalBill | undefined>(undefined);
	const [ isLoading, setLoading ] = useState(true);

	useEffect(
		() =>
		{
			setLoading(true);
			postAny('/externalBills/request')
				.then(
					result =>
					{
						setExternalBill(result);
						setLoading(false);
					}
				);
		},
		[
			setLoading,
			setExternalBill,
		]
	);

	useExternalBillUpdater(
		externalBill,
		setExternalBill
	);

	return {
		externalBill,
		isLoading,
	};
}
