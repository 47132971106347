import { Theme } from '@material-ui/core/styles';
import { useMemo } from 'react';
import { ButtonAppearance } from '../../ButtonAppearance';
import { toCss } from '../../ButtonColor';
import { defaultSecondButtonColor } from '../../color/defaultSecondButtonColor';

export function usePaperButtonColors(error: boolean, disabled: boolean, color: ButtonAppearance<'paper'>, theme: Theme)
{
	return useMemo(
		() => {
			const backgroundColor = (() => {
				if (error)
					return '#f1e1e2';
				else
					return '#fff';
			})();
			const iconBackgroundColor = (() => {
				if (disabled)
					return !error
						? 'rgba(0, 0, 0, 0.27)'
						: 'rgb(250, 161, 155)';
				else
					return !error
						? color.iconColor ?? 'primary'
						: theme.palette.error.main;
			})();
			const iconColor = (() => {
				if (disabled)
					return !error
						? '#fff'
						: '#f1e1e2';
				else
					return defaultSecondButtonColor(iconBackgroundColor);
			})();
			const textColorCss = (() => {
				if (disabled)
					return 'rgba(0, 0, 0, 0.27)';
				else
					return theme.palette.text.primary;
			})();

			const backgroundColorCss = toCss(backgroundColor, theme);
			const iconBackgroundColorCss = toCss(iconBackgroundColor, theme);
			const iconColorCss = toCss(iconColor, theme);

			return {backgroundColorCss, textColorCss, iconColorCss, iconBackgroundColorCss};
		},
		[color.iconColor, disabled, error, theme],
	);
}