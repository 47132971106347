import { Theme, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import { CSSProperties, FC, useCallback, useMemo } from 'react';
import { OrderDescriptor } from '../../../../../../Api/Order/OrderDescriptor';
import { useLocalizer } from '../../../../../../Bridge/Localization/useLocalizer';
import { UIColors } from '../../../../../../Constants/UIColors';
import { getTotalPriceOfOrders } from '../../../../../../Util/Orders/getTotalPriceOfOrders';
import { useManagerContext } from '../../../manager-context';
import { PlaceIdentifier } from '../PlaceIdentifier';

const useStyles = makeStyles(
	(theme: Theme) =>
		({
			content:
				{
					alignItems: 'center',
					display: 'flex',
					justifyContent: 'space-between',
				},
			placeText:
				{
				},
			placeTextContainer:
				{
					background: theme.palette.primary.main,
					borderBottomLeftRadius: theme.shape.borderRadius,
					borderTopLeftRadius: theme.shape.borderRadius,
					padding: theme.spacing(1),
				},
			priceText:
				{
					color: theme.palette.common.white,
				},
			priceTextContainer:
				{
					background: UIColors.black,
					borderBottomRightRadius: theme.shape.borderRadius,
					borderTopRightRadius: theme.shape.borderRadius,
					padding: theme.spacing(1),
				},
			root:
				{
					display: 'flex',
				},
		}));

interface BillSummaryProps
{
	onClick?: (place: PlaceIdentifier) => void;
	orders: OrderDescriptor[];
	place: PlaceIdentifier;
	style?: CSSProperties;
}

export const BillSummary: FC<BillSummaryProps> =
	({
		 onClick,
		 orders,
		 place,
		 style,
	 }) =>
	{
		const classes = useStyles();

		const localizer = useLocalizer();

		const {
			brandingService,
		} = useManagerContext();

		const cost = useMemo(() => getTotalPriceOfOrders(orders)[0], [orders]);
		const onUserClick = useCallback(() => onClick && onClick(place), [onClick, place]);

		return <div
			className={classes.root}
			onClick={onUserClick}
			style={style}
		>
			<div
				className={classes.placeTextContainer}
			>
				<Typography
					align="center"
					classes={{
						root: classes.placeText,
					}}
					style={{
						color: brandingService.tintContrastTextColor.css,
					}}
				>
					{place.name}
				</Typography>
			</div>
			<div
				className={classes.priceTextContainer}
			>
				<Typography
					align="right"
					classes={{
						root: classes.priceText,
					}}
				>
					{localizer.formatCurrency(cost.price, cost.currencyCode)}
				</Typography>
			</div>
		</div>;
	};