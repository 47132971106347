import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { useLocalizer } from '../../../../../Bridge/Localization/useLocalizer';
import { useMemoizedDecimal } from '../../../../../Util/decimal/useMemoizedDecimal';
import { usePaymentPriceContext } from '../../../../UI/payment/price/context/PaymentPriceContext';
import PriceTag from '../../../../UI/PriceTag/PriceTag';
import { AppliedRewardsStore } from './AppliedRewardsStore';

export interface AppliedRewardsSummaryProps
{
	store: AppliedRewardsStore
}

export const AppliedRewardsSummary: FC<AppliedRewardsSummaryProps> =
	({
		store
	}) =>
	{
		const localizer = useLocalizer();
		const {discount, tip, total} = usePaymentPriceContext();
		const rewardDiscount = useObserver(() => store.rewardDiscount);

		// TODO: additive tax is based on the price - discount, so is this actually a total price excluding discount?
		const totalPriceExcludingDiscountAndTip = useMemoizedDecimal(
			total
				.add(discount)
				.sub(tip),
		);

		const effectiveDiscount = useMemo(
			() =>
				rewardDiscount
					.clamp(
						0,
						totalPriceExcludingDiscountAndTip.toNumber(),
					)
					.negated(),
			[rewardDiscount, totalPriceExcludingDiscountAndTip],
		);

		if (!effectiveDiscount.isZero())
		{
			return <PriceTag
				price={effectiveDiscount}
				localizer={localizer}
				currencyCode={store.businessStore.business.productCurrencyCode}
				component="span"
				variant={'body1'}
				color="textSecondary"
				style={{
					overflowX: 'hidden',
					textOverflow: 'ellipsis',
				}}
			/>;
		}
		else
		{
			return null;
		}
	};
