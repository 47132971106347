import { CircularProgress, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { FC } from 'react';

interface StyleProps
{
	marginLeft: number
	marginRight: number
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
	buttonLoaderContainer: ({marginLeft, marginRight}) => ({
		height: 24,
		marginLeft: marginLeft,
		marginRight: marginRight,
	}),
}))

interface ButtonLoadingIdicatorProps
{
	marginLeft: number
	marginRight: number
	color: string
}

export const ButtonLoadingIdicator: FC<ButtonLoadingIdicatorProps> =
	(
		{
			marginLeft,
			marginRight,
			color,
		},
	) =>
	{
		const classes = useStyles({marginLeft, marginRight});
		return <div
			className={classes.buttonLoaderContainer}
		>
			<CircularProgress
				size={24}
				style={{
					color: color,
				}}
				variant="indeterminate"
			/>
		</div>;
	};