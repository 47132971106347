import { makeStyles, MenuItem, Select, Typography } from '@material-ui/core';
import { startOfDay } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { ChangeEvent, FC, useCallback, useContext } from 'react';
import { capitalizeFirst } from '../../../../../Api/Util/capitalizeFirst';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { BusinessContextRef } from '../../BusinessContext';

const useStyles = makeStyles(theme => ({
	select: {
		marginBottom: theme.spacing(1),
	},
	noDayAvailableLabel: {
		fontStyle: 'italic',
		whiteSpace: 'normal',
	},
	noDayAvailableMenuItem: {
		pointerEvents: 'none',
	},
}));

export interface SchedulerDaySelectProps
{
	availableDays?: string[];
	onChange: (value: string) => void;
	value: string;
}

export const SchedulerDaySelect: FC<SchedulerDaySelectProps> =
	({
		availableDays = [],
		onChange,
		value,
	}) =>
	{
		const translate = useTranslate();
		const {localizer} = useContext(BusinessContextRef);

		const classes = useStyles();

		const formatDateFamiliar = useObserver(() => localizer.formatDateFamiliar);

		const handleChange = useCallback(
			(event: ChangeEvent<{ value: string }>) =>
				onChange(event.target.value),
			[onChange],
		);

		return <Select
			className={classes.select}
			fullWidth
			onChange={handleChange}
			value={value ?? ''}
			variant="outlined"
		>
			{
				availableDays.length > 0
					? availableDays
						.map(
							(day, idx) =>
								<MenuItem
									key={idx}
									value={day}
								>
									{
										capitalizeFirst(
											formatDateFamiliar(
												startOfDay(
													toDate(
														day,
														{
															timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
														}
													),
												),
											),
										)
									}
								</MenuItem>,
						)
					: <MenuItem
						className={classes.noDayAvailableMenuItem}
						value={''}
					>
						<Typography
							className={classes.noDayAvailableLabel}
							color="textSecondary"
						>
							{translate('Order-Scheduling-No-Times-Available')}
						</Typography>
					</MenuItem>
			}
		</Select>;
	};
