import { FormControl, MenuItem, TextField, Theme, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import { ChangeEvent, CSSProperties, FC, useCallback, useContext, useEffect, useState } from 'react';
import { StorageVars } from '../../../../../Constants/StorageConstants';
import { convertFromISO8601Duration } from '../../../../../Util/Duration/convertFromISO8601Duration';
import { convertToISO8601Duration } from '../../../../../Util/Duration/convertToISO8601Duration';
import { EntranceContextRef } from '../../../Entrance/EntranceContext';


const useStyles = makeStyles(
	(theme: Theme) =>
		({
			formInput:
				{
					width: 20,
				},
			formInputRoot:
				{
					display: 'flex',
					justifyContent: 'flex-end',
					minWidth: 140,
					whiteSpace: 'nowrap',
				},
			formPrefix:
				{
					[theme.breakpoints.down('xs')]:
						{
							marginRight: theme.spacing(1),
						},
					[theme.breakpoints.up('sm')]:
						{
							marginRight: theme.spacing(2),
						},
				},
			formSuffixInput:
				{
					paddingLeft: theme.spacing(1),
				},
			root:
				{
					[theme.breakpoints.down('xs')]:
						{
							padding: theme.spacing(1),
						},
					[theme.breakpoints.up('sm')]:
						{
							padding: theme.spacing(2),
						},
				},
		}));

interface ManagerNotificationRecurrenceSettingProps
{
	className?: string;
	style?: CSSProperties;
}

export const ManagerNotificationRecurrenceSetting: FC<ManagerNotificationRecurrenceSettingProps> =
	({
		 className,
		 style,
	 }) =>
	{
		const classes = useStyles();

		const {
			localizer,
			storage,
		} = useContext(EntranceContextRef);

		const [notificationInterval, setNotificationInterval] = useState();
		const [notificationIntervalUnit, setNotificationIntervalUnit] = useState(1);

		useEffect(
			() =>
			{
				const value = storage.get(StorageVars.ManagerNotificationRecurrence);

				if (value)
				{
					const duration = convertFromISO8601Duration(value);

					if (duration.hours > 0)
					{
						setNotificationInterval(duration.hours);
						setNotificationIntervalUnit(0);
					}
					else if (duration.minutes > 0)
					{
						setNotificationInterval(duration.minutes);
						setNotificationIntervalUnit(1);
					}
				}
				else
				{
					setNotificationInterval(0);
					setNotificationIntervalUnit(1);
				}
			},
			[storage],
		);

		useEffect(
			() =>
			{
				if (notificationInterval !== undefined && notificationIntervalUnit !== undefined)
				{
					let isoString: string | undefined;

					switch (notificationIntervalUnit)
					{
						case 0:
							isoString = convertToISO8601Duration(0, 0, notificationInterval);
							break;
						case 1:
							isoString = convertToISO8601Duration(0, notificationInterval, 0);
							break;
					}

					storage.set(StorageVars.ManagerNotificationRecurrence, isoString);
				}
			},
			[notificationInterval, notificationIntervalUnit, storage]);

		useEffect(
			() =>
			{
				if (notificationIntervalUnit === 0)
				{
					setNotificationInterval(Math.min(notificationInterval, 24));
				}
			},
			[notificationInterval, notificationIntervalUnit]);

		const onNotificationRecurrenceIntervalChange = useCallback(
			(event: ChangeEvent<HTMLInputElement>) =>
			{
				const numericValue = event.target.valueAsNumber;
				const clampedValue = numericValue === undefined ? 0 : Math.min(numericValue, notificationIntervalUnit === 0 ? 24 : 60) || 0;

				event.target.valueAsNumber = clampedValue;
				setNotificationInterval(clampedValue);
			},
			[notificationIntervalUnit]);

		const onNotificationRecurrenceIntervalUnitChange = useCallback(
			(event: ChangeEvent<HTMLInputElement>) =>
				setNotificationIntervalUnit(parseInt(event.target.value)),
			[]);

		if (notificationInterval !== undefined)
		{
			return <FormControl
				className={className}
				classes={{
					root: classes.root,
				}}
				style={style}
			>
				<Typography
					classes={{
						root: classes.formPrefix,
					}}
				>
					Notification interval:
				</Typography>
				<div
					className={classes.formInputRoot}
				>
					<TextField
						classes={{
							root: classes.formInput,
						}}
						onChange={onNotificationRecurrenceIntervalChange}
						type="number"
						value={notificationInterval}
					/>
					<TextField
						InputProps={{
							classes:
								{
									root: classes.formSuffixInput,
								},
						}}
						onChange={onNotificationRecurrenceIntervalUnitChange}
						select
						value={notificationIntervalUnit}
					>
						<MenuItem value={0}>{localizer.translate(`Time-Hour${notificationInterval > 1 ? 's' : ''}`)}</MenuItem>
						<MenuItem value={1}>{localizer.translate(`Time-Minute${notificationInterval > 1 ? 's' : ''}`)}</MenuItem>
					</TextField>
				</div>
			</FormControl>;
		}
		else
		{
			return null;
		}
	};