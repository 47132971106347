import { useCallback, useEffect, useState } from 'react';

const ClearInputTimeout = 3000;

export function useGlobalInputReader(
	onCommit: (value: string) => void,
): void
{
	const [input, setInput] = useState('');

	const handleKeyPress = useCallback(
		(
			event: KeyboardEvent,
		) =>
		{
			if (window.document.activeElement?.tagName !== 'INPUT' && window.document.activeElement?.tagName !== 'TEXTAREA')
			{
				if (event.key === 'Enter')
				{
					onCommit(input);
					setInput('');
				}
				else
				{
					setInput(
						prev =>
							`${prev}${event.key}`,
					);
				}
			}
		},
		[onCommit, input],
	);

	useEffect(
		() =>
		{
			if (input.length > 0)
			{
				const handle = setTimeout(
					() =>
						setInput(''),
					ClearInputTimeout,
				);

				return () =>
				{
					clearTimeout(handle);
				};
			}
		},
		[input.length],
	);

	useEffect(
		() =>
		{
			window.addEventListener('keypress', handleKeyPress);

			return () =>
			{
				window.removeEventListener('keypress', handleKeyPress);
			};
		},
		[handleKeyPress],
	);
}
