import * as React from 'react';
import { FC } from 'react';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { UIColors } from '../../../Constants/UIColors';

interface NotificationSnackbarDismissActionProps
{
	key?: number | string;
	onDismiss: (key?: number | string) => void;
}

export const NotificationSnackbarDismissAction: FC<NotificationSnackbarDismissActionProps> =
	({
		 key,
		 onDismiss,
	 }) =>
	{
		return <IconButton
			id="snackbar-close-button"
			onClick={() => onDismiss(key)}
			style={{
				color: UIColors.greyLight,
			}}
		>
			<CloseIcon />
		</IconButton>;
	};