import { Collapse, ListItem } from '@material-ui/core';
import * as React from 'react';
import { FC } from 'react';
import { Product } from '../../../../../Api/Product/Product';
import { ProductMenuEntry } from '../Grid/MenuEntry/product/ProductMenuEntry';

interface ProductListItemProps
{
	className?: string;
	onClick?: (product: Product) => void;
	product: Product;
	show?: boolean;
}

export const ProductListItem: FC<ProductListItemProps> =
	(
		{
			className,
			onClick,
			product,
			show = true,
		},
	) =>
	{
		return <Collapse in={show} timeout={100} unmountOnExit>
			<ListItem className={className}>
				<ProductMenuEntry
					onClick={onClick}
					product={product}
					forceGridItemType="LIST_ITEM"
				/>
			</ListItem>
		</Collapse>;
	};
