export function cancelFullScreen()
{
	const doc = window.document as any;

	const cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;
	const isCurrentlyNotFullScreen: boolean = !doc.fullscreenElement
		&& !doc.mozFullScreenElement
		&& !doc.webkitFullscreenElement
		&& !doc.msFullscreenElement;

	if (!isCurrentlyNotFullScreen)
	{
		return cancelFullScreen.call(doc);
	}
}