import { Grow, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import * as React from 'react';
import { FC, useState } from 'react';
import { LoyaltyDiscount } from '../../../../../Api/Order/Loyalty/LoyaltyDiscount';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';

const useStyles = makeStyles(theme => ({
	closeIcon: {},
	couponCard: {
		backgroundColor: theme.palette.primary.main,
		borderRadius: theme.shape.borderRadius,
		outlineStyle: 'dotted',
		outlineWidth: 1,
		outlineColor: 'rgba(255,255,255,0.8)',
		outlineOffset: -3,
		display: 'inline-block',
		paddingTop: 8,
		paddingBottom: 8,
		paddingLeft: 11,
		paddingRight: 25,
		position: 'relative',
	},
	couponCardDescription: {
		color: theme.palette.primary.contrastText,
		fontSize: 'smaller',
	},
}));

export interface LoyaltyCouponCardProps
{
	discount: LoyaltyDiscount;
	onRemove: (discount: LoyaltyDiscount) => void;
}

export const LoyaltyCouponCard: FC<LoyaltyCouponCardProps> =
	({
		discount,
		onRemove,
	}) =>
	{
		const classes = useStyles();
		const translate = useTranslate();
		const removeCardLabel = translate('Client-Coupon-RemoveFromOrder');

		const [contextMenuOpen, setContextMenuOpen] = useState(false);
		const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);

		const handleClick = (event: React.MouseEvent<HTMLDivElement>) =>
		{
			setContextMenuOpen(true);
			setAnchorEl(anchorEl ?? event.currentTarget);
		};

		const handleOnMenuClick = () =>
		{
			onRemove(discount);
		};

		return <>
			<Grow
				in
			>
				<div
					className={classes.couponCard}
					onClick={handleClick}
				>
					<Typography
						className={classes.couponCardDescription}
					>
						{discount.name}
					</Typography>
				</div>
			</Grow>
			<Menu
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				onClose={() => setContextMenuOpen(false)}
				open={contextMenuOpen}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<MenuItem
					onClick={handleOnMenuClick}
				>
					<Close
						className={classes.closeIcon}
					/>
					<Typography
					>
						{removeCardLabel}
					</Typography>
				</MenuItem>
			</Menu>
		</>;
	};
