import * as React from 'react';
import { createContext, FC, useContext, useMemo } from 'react';
import { PaymentIssuer } from '../../../../../Api/Payment/PaymentIssuer';
import { useAvailablePaymentIssuers } from './hooks/useAvailablePaymentIssuers';
import { useInvalidPaymentIssuerRemover } from './hooks/useInvalidPaymentIssuerRemover';
import { useMappedPaymentIssuers } from './hooks/useMappedPaymentIssuers';
import { usePaymentIssuerValidation } from './hooks/usePaymentIssuerValidation';
import { useSelectedPaymentIssuer } from './hooks/useSelectedPaymentIssuer';
import { useSelectInitialPaymentIssuer } from './hooks/useSelectInitialPaymentIssuer';
import { useStoreSelectedPaymentIssuer } from './hooks/useStoreSelectedPaymentIssuer';

interface Context
{
	isInitialized: boolean;
	paymentIssuers: PaymentIssuer[];
	selectedPaymentIssuer: PaymentIssuer | undefined;
	selectPaymentIssuer: (paymentIssuerId?: string) => void;
	validation: string[];
}

const ContextRef = createContext<Context>(undefined as never);

interface PaymentIssuerContextProviderProps
{
	onChange?: (paymentIssuer?: PaymentIssuer) => void;
	resetAfterPayment: boolean;
}

export const PaymentIssuerContextProvider: FC<PaymentIssuerContextProviderProps> =
	({
		children,
		onChange,
		resetAfterPayment,
	}) =>
	{
		const [paymentIssuers, arePaymentIssuersInitialized] = useAvailablePaymentIssuers();

		const paymentIssuerById = useMappedPaymentIssuers(paymentIssuers);

		const [selectedPaymentIssuer, selectPaymentIssuer] = useSelectedPaymentIssuer(paymentIssuerById, onChange);

		const validation = usePaymentIssuerValidation(selectedPaymentIssuer);

		const didSelectInitialPaymentIssuer = useSelectInitialPaymentIssuer(selectPaymentIssuer, arePaymentIssuersInitialized, resetAfterPayment);

		useInvalidPaymentIssuerRemover(selectedPaymentIssuer, selectPaymentIssuer);

		useStoreSelectedPaymentIssuer(selectedPaymentIssuer, didSelectInitialPaymentIssuer);

		const contextValue = useMemo(
			() => ({isInitialized: didSelectInitialPaymentIssuer, paymentIssuers, selectedPaymentIssuer, selectPaymentIssuer, validation}),
			[didSelectInitialPaymentIssuer, paymentIssuers, selectPaymentIssuer, selectedPaymentIssuer, validation],
		);

		return <ContextRef.Provider value={contextValue}>
			{children}
		</ContextRef.Provider>;
	};

export function usePaymentIssuerContext(): Context
{
	return useContext(ContextRef);
}