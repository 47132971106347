import chroma, { Color } from 'chroma-js';

export function computeContrastTextColor(
	color: Color
)
{
	const contrastWithBlack = chroma.contrast(chroma.mix('#000', color, 0.1), color);
	const contrastWithWhite = chroma.contrast(chroma.mix('#fff', color, 0.1), color);

	// mix two measures in decision
	if (contrastWithWhite >= 0.8 * contrastWithBlack) {
		return chroma.css(`rgba(255, 255, 255, 0.9)`);
	} else {
		return chroma.css(`rgba(0, 0, 0, 0.9)`);
	}
}