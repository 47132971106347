import { Typography, useTheme } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext } from 'react';
import { useTranslate } from '../../../../Bridge/Localization/useTranslate';
import { useBrandingService } from '../../../branding-service/BrandingServiceProvider';
import { Page } from '../../../UI/Page';
import { BusinessContextRef } from '../BusinessContext';

export const NoPlaceSession: FC =
	() =>
	{
		const {businessStore} = useContext(BusinessContextRef);
		const {logoImageUrl} = useBrandingService();
		const theme = useTheme();
		const translate = useTranslate();

		const businessName = useObserver(() => businessStore.business.name);

		return <Page
			backgroundOverlayColor={theme.palette.primary.main}
			style={{
				alignItems: 'center',
				bottom: 0,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				left: 0,
				margin: 0,
				padding: 0,
				position: 'absolute',
				right: 0,
				top: 0,
			}}
		>
			<div
				style={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
					width: '30%',
				}}
			>
				<div
					style={{
						backgroundImage: logoImageUrl
							? `url(${logoImageUrl})`
							: undefined,
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'contain',
						height: 70,
						width: '100%',
					}}
				/>
				<div>
					<Typography
						variant="body1"
						color="textPrimary"
						align="center"
					>
						{translate('Client-PlaceSession-WelcomeToBusiness', businessName)}
					</Typography>
				</div>
			</div>
		</Page>;
	};
