import { useMemo } from 'react';
import { useScreenWidth } from '../../../../../../Util/Hooks/useScreenWidth';

const DEFAULT_NUMBER_OF_COLUMNS = 2;

export function useNumberOfFullscreenMenuGridColumns(): number
{
	const screenWidth = useScreenWidth();

	return useMemo(() => {
		switch (screenWidth)
		{
			case 'xs':
				return 2;
			case 'sm':
				return 3;
			case 'md':
				return 4;
			case 'lg':
				return 4;
			case 'xl':
				return 4;
			default:
			{
				console.warn(`Unknown screen width breakpoint ${screenWidth}, defaulting to ${DEFAULT_NUMBER_OF_COLUMNS} menu grid columns.`);
				return DEFAULT_NUMBER_OF_COLUMNS;
			}
		}
	}, [screenWidth]);
}
