import { useObserver } from 'mobx-react-lite';
import { useCallback, useContext } from 'react';
import { BusinessContextRef } from '../../../../../Page/Business/BusinessContext';

export function useClientOnError(): (state: Error) => void
{
	const {businessStore} = useContext(BusinessContextRef);

	const handlePaymentProcessingResult = useObserver(() => businessStore.handlePaymentProcessingResult);

	return useCallback(
		(error) =>
			handlePaymentProcessingResult?.({
				error,
				state: 'Failed',
			}),
		[handlePaymentProcessingResult],
	);
}
