export const AppVersionValues = [
	'V2',
	'V3',
] as const;

export type AppVersion = typeof AppVersionValues[number];

export function isAppVersion(value: any): value is AppVersion
{
	return AppVersionValues.includes(value);
}
