import { TaxGroupSpecification } from '../TaxGroupSpecification';
import { VatGroup } from '../VatGroup';

export function extractRelevantTaxGroupSpecifications(taxGroup: VatGroup): TaxGroupSpecification[]
{
	if (taxGroup.subGroups === undefined)
	{
		return [taxGroup];
	}
	else
	{
		return taxGroup.subGroups;
	}

}