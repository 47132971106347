import { TimeScheduleSpecification } from '../time/TimeScheduleSpecification';

export class TimeSchedule
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	readonly id: number;
	readonly uuid: string;
	readonly name: string;
	readonly booleanTimeSeries: TimeScheduleSpecification;
}
