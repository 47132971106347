import { isLocalAccessDeniedError } from '../../../Util/browser/isLocalAccessDeniedError';

export function getFromLocalStorage<T>(key: string, transformer?: (value?: string) => T): T | undefined
{
	try
	{
		const item = window.localStorage.getItem(key) ?? undefined;

		if (item === undefined)
			return undefined;
		else if (transformer === undefined)
			return JSON.parse(item) as T;
		else
			return transformer(item);
	}
	catch (error)
	{
		if (!isLocalAccessDeniedError(error))
			throw error;
	}
}
