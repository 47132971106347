import { Box } from '@material-ui/core';
import { FC } from 'react';
import QRCode from 'react-qr-code';

interface QrCodeViewerProps
{
	value: string;
}

export const QrCodeViewer: FC<QrCodeViewerProps> =
	({
		value,
	}) =>
	{
		return <Box
			padding={4}
			style={{
				background: 'white',
			}}
		>
			<QRCode
				value={value}
			/>
		</Box>;
	};
