import { useMemo } from 'react';
import { getAvailableDaysFromTimestamp } from '../../../../Api/Util/time/getAvailableDaysFromTimestamp';
import { useCurrentMinute } from '../../../../Util/clock/useCurrentMinute';
import { useAllowedOrderingTimeSchedule } from './useAllowedOrderingTimeSchedule';

export function useAllowedOrderingDays(): string[]
{
	const currentMinute = useCurrentMinute();

	const allowedOrderingTimeSchedule = useAllowedOrderingTimeSchedule();

	return useMemo(
		() =>
			getAvailableDaysFromTimestamp(
				currentMinute,
				allowedOrderingTimeSchedule,
			),
		[allowedOrderingTimeSchedule, currentMinute],
	);
}
