import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import * as React from 'react';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { UIColors } from '../../Constants/UIColors';

const useStyles = makeStyles(
	() =>
		({
			details:
				{
					padding: 0,
				},
			root:
				{
					backgroundColor: 'transparent',
					boxShadow: '0px -1px 5px grey',
				},
			rootExpanded:
				{
					margin: '0px !important',
				},
			summaryContent:
				{
					alignItems: 'center',
					margin: '0px !important',
				},
			summaryExpanded:
				{
					minHeight: '48px !important',
				},
			summaryRoot:
				{
					backgroundColor: UIColors.greyLight,
				},
		}));

interface CollapsibleBottomBarProps
{
	details: ReactNode;
	disabled?: boolean;
	expandedHeightPercentage?: number;
	expandIcon?: ReactNode;
	id?: string;
	summary: ReactNode;
}

export const CollapsibleBottomBar: FC<CollapsibleBottomBarProps> =
	({
		 details,
		 disabled,
		 expandedHeightPercentage = 40,
		 expandIcon,
		 id,
		 summary,
	 }) =>
	{
		const classes = useStyles();

		const [expandedDetailsHeight, setExpandedDetailsHeight] = useState(expandedHeightPercentage / 100 * window.innerHeight);

		const updateExpandedDetailsHeight = useCallback(
			() => setExpandedDetailsHeight(expandedHeightPercentage / 100 * window.innerHeight),
			[expandedHeightPercentage]);

		useEffect(
			() =>
			{
				window.onresize = updateExpandedDetailsHeight;

				return () =>
				{
					window.onresize = null;
				};
			},
			[updateExpandedDetailsHeight]);

		return <Accordion
			classes={{
				expanded: classes.rootExpanded,
				root: classes.root,
			}}
			disabled={disabled}
			id={id}
			square
			TransitionProps={{
				unmountOnExit: true,
			}}
		>
			<AccordionSummary
				classes={{
					content: classes.summaryContent,
					expanded: classes.summaryExpanded,
					root: classes.summaryRoot,
				}}
				expandIcon={expandIcon || <ExpandMore id="expand-more-icon" />}
			>
				{summary}
			</AccordionSummary>
			<AccordionDetails
				classes={{
					root: classes.details,
				}}
				style={{
					height: expandedDetailsHeight,
				}}
			>
				{details}
			</AccordionDetails>
		</Accordion>;
	};