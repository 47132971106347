import { CSSProperties } from 'react';

export const ImageFitValues = [
	'Cover',
	'Contain',
] as const;

export type ImageFit = typeof ImageFitValues[number];

export function toObjectFitCss(fit: ImageFit | undefined): CSSProperties['objectFit'] | undefined
{
	if (fit !== undefined)
	{
		switch (fit)
		{
			case 'Contain':
				return 'contain';
			case 'Cover':
				return 'cover';
		}
	}
}
