import { action, computed, makeObservable, observable } from 'mobx';
import { Business, BusinessProfile } from '../../Api/Business/Business';
import { Waiter, WaiterProfile } from '../../Api/User/Waiter';
import { post, postAny } from '../../Util/Api';
import { IllegalStateException } from '../../Util/Exception/IllegalStateException';
import { UserService } from './UserService';

export class WaiterService extends UserService<Waiter>
{
	// ------------------------ Dependencies ------------------------

	// ------------------------- Properties -------------------------

	business: Business | undefined;
	private _businessWaiterWorksFor: Business | undefined;

	// ------------------------ Constructor -------------------------

	constructor()
	{
		super();

		makeObservable<WaiterService, '_businessWaiterWorksFor' | 'setBusinessWaiterWorksFor'>(
			this,
			{
				business: observable,
				_businessWaiterWorksFor: observable,
				role: computed,
				businessWaiterWorksFor: computed,
				setBusiness: action.bound,
				setBusinessWaiterWorksFor: action.bound,
			},
		);
	}

	// ----------------------- Initialization -----------------------

	// -------------------------- Computed --------------------------

	public get role(): 'User' | 'Waiter' | 'Manager' | 'Owner' | undefined
	{
		return this.user !== undefined
			?
			(this.user as Waiter).type
			:
			undefined;
	}

	public get businessWaiterWorksFor(): Business | undefined
	{
		return this._businessWaiterWorksFor;
	}

	// --------------------------- Stores ---------------------------

	// -------------------------- Actions ---------------------------

	setBusiness(business: Business): void
	{
		this.business = business;
	}

	private setBusinessWaiterWorksFor(businessWaiterWorksFor: Business): void
	{
		this._businessWaiterWorksFor = businessWaiterWorksFor;
	}

	// ------------------------ Public logic ------------------------

	enterBusiness(): Promise<Business | undefined>
	{
		return this.fetchWaiter()
			.then(
				() =>
				{
					if (this.user)
					{
						if (this.user.businessId)
						{
							return post(
								'/waiter/business/enter',
								{
									business_id: this.user.businessId,
								},
								BusinessProfile)
								.then(business =>
								{
									this.setBusiness(business);
									this.setBusinessWaiterWorksFor(business);

									return Promise.resolve(business);
								});
						}
						else
						{
							return Promise.resolve(undefined);
						}
					}
					else
					{
						throw new IllegalStateException('User is not a waiter.');
					}

				});
	}

	exitBusiness(): Promise<boolean>
	{
		return postAny(
			'/waiter/business/exit')
			.then(
				response =>
				{
					if (response)
					{
						this.setUser();
					}

					return Promise.resolve(response);
				});
	}

	// ----------------------- Private logic ------------------------

	private fetchWaiter(): Promise<any>
	{
		return this.getUser(WaiterProfile)
			.then(this.setUser);
	}

}
