import { Divider, Hidden, Theme, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalizer } from '../../../../Bridge/Localization/useLocalizer';
import { saveWaiterSettings, SaveWaiterSettingsRequest } from '../../../../Service/UserService/Api/Waiter/saveWaiterSettings';
import { BooleanForm } from '../../../UI/Form/BooleanForm/BooleanForm';
import { FormGroup } from '../../../UI/Form/FormGroup/FormGroup';
import { useManagerContext } from '../manager-context';
import { FilterRoutingSetting } from './FilterRouting/FilterRoutingSetting';
import { ManagerNotificationRecurrenceSetting } from './NotificationRecurrence/ManagerNotificationRecurrenceSetting';
import { ShowOpenBillsSetting } from './ShowOpenBills/ShowOpenBillsSetting';
import { ShowPaidHandledOrdersSetting } from './ShowPaidHandledOrders/ShowPaidHandledOrdersSetting';

const useStyles = makeStyles((theme: Theme) => ({
	checkbox: {
		padding: 4,
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
	form: {

		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		height: 46,
		justifyContent: 'space-between',
	},
	title: {
		[theme.breakpoints.down('xs')]: {
				alignSelf: 'center',
			},

		padding: theme.spacing(2),
	},
}));

export const ManagerSettings: FC =
	() =>
	{
		const classes = useStyles();

		const localizer = useLocalizer();

		const {
			waiterService,
		} = useManagerContext();

		const [didUpdateWaiterSettings, setDidUpdateWaiterSettings] = useState(false);

		const [orderHandlerSupportsOrderStateScheduled, setOrderHandlerSupportsOrderStateScheduled] = useState(waiterService.user?.orderHandlerSupportsOrderStateScheduled ?? false);
		const [orderHandlerSupportsOrderStatePrepared, setOrderHandlerSupportsOrderStatePrepared] = useState(waiterService.user?.orderHandlerSupportsOrderStatePrepared ?? false);
		const [orderHandlerSupportsOrderStatePickedUp, setOrderHandlerSupportsOrderStatePickedUp] = useState(waiterService.user?.orderHandlerSupportsOrderStatePickedUp ?? false);
		const [orderHandlerSupportsOrderStateDelivered, setOrderHandlerSupportsOrderStateDelivered] = useState(waiterService.user?.orderHandlerSupportsOrderStateDelivered ?? false);

		const saveWaiterSettingsRequest: SaveWaiterSettingsRequest | undefined = useMemo(
			() =>
				waiterService.user
					? {
						uuid: waiterService.user?.guid,
						orderHandlerSupportsOrderStateScheduled,
						orderHandlerSupportsOrderStatePrepared,
						orderHandlerSupportsOrderStatePickedUp,
						orderHandlerSupportsOrderStateDelivered,

					}
					: undefined,
			[orderHandlerSupportsOrderStateDelivered, orderHandlerSupportsOrderStatePickedUp, orderHandlerSupportsOrderStatePrepared, orderHandlerSupportsOrderStateScheduled, waiterService.user],
		);

		useEffect(
			() => {
				if (saveWaiterSettingsRequest !== undefined && didUpdateWaiterSettings)
				{
					saveWaiterSettings(saveWaiterSettingsRequest)
						.then(waiter => waiterService.setUser(waiter));

					setDidUpdateWaiterSettings(false);
				}
			},
			[didUpdateWaiterSettings, saveWaiterSettingsRequest, waiterService, waiterService.user],
		);

		const smallScreenDivider = useMemo(
			() =>
				<Hidden
					smUp
				>
					<Divider />
				</Hidden>,
			[]);

		const onUpdateWaiterSettings = useCallback(() => setDidUpdateWaiterSettings(true), []);

		return <div
			className={classes.container}
		>
			<Typography
				classes={{
					root: classes.title,
				}}
				variant="h5"
			>
				Settings
			</Typography>
			{smallScreenDivider}
			<ManagerNotificationRecurrenceSetting
				className={classes.form}
			/>
			{smallScreenDivider}
			<ShowOpenBillsSetting
				className={classes.form}
			/>
			{smallScreenDivider}
			<ShowPaidHandledOrdersSetting
				className={classes.form}
			/>
			{smallScreenDivider}
			<FilterRoutingSetting
				className={classes.form}
			/>
			<FormGroup
				defaultExpanded
				label={'Shown order states'}
			>
				<BooleanForm
					label={localizer.translate('Order-State-Scheduled')}
					setValue={value => {
						setOrderHandlerSupportsOrderStateScheduled(value);
						onUpdateWaiterSettings();
					}}
					value={orderHandlerSupportsOrderStateScheduled}
				/>
				<BooleanForm
					label={localizer.translate('Order-State-Prepared')}
					setValue={value => {
						setOrderHandlerSupportsOrderStatePrepared(value);
						onUpdateWaiterSettings();
					}}
					value={orderHandlerSupportsOrderStatePrepared}
				/>
				<BooleanForm
					label={localizer.translate('Order-State-PickedUp')}
					setValue={value => {
						setOrderHandlerSupportsOrderStatePickedUp(value);
						onUpdateWaiterSettings();
					}}
					value={orderHandlerSupportsOrderStatePickedUp}
				/>
				<BooleanForm
					label={localizer.translate('Order-State-Delivered')}
					setValue={value => {
						setOrderHandlerSupportsOrderStateDelivered(value);
						onUpdateWaiterSettings();
					}}
					value={orderHandlerSupportsOrderStateDelivered}
				/>
			</FormGroup>
		</div>;
	};
