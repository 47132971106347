import * as React from 'react';
import { FC, ReactNode } from 'react';
import { Color } from '../../../../Api/Other/Color';
import { ExtendedMenuEntryType } from '../../../../Api/Product/ExtendedMenuEntryType';
import { MediaButton } from '../../media_button/MediaButton';
import { MediaButtonV2 } from '../../media_button_v2/MediaButtonV2';
import { ImageProps } from '../MenuEntry';

export interface TileWithImageProps
{
	primaryBadges?: ReactNode[];
	secondaryBadges?: ReactNode[];
	image: ImageProps;
	backgroundColor: Color;
	minHeight?: number | string;
	minImageHeight?: number;
	minImageWidth?: number;
	onClick?: () => void;
	price?: string;
	title: string;
	description?: string;
	type?: ExtendedMenuEntryType;
	disabled?: boolean;
	showDescriptionOnTile?: boolean;
}

export const TileWithImage: FC<TileWithImageProps> =
	({
		backgroundColor,
		image,
		minHeight = 0,
		minImageHeight,
		minImageWidth,
		onClick,
		primaryBadges,
		secondaryBadges,
		title,
		description,
		price,
		type,
		disabled,
		showDescriptionOnTile
	}) =>
	{
		if (type === 'TILE_V2')
		{
			return <MediaButtonV2
				primaryBadges={primaryBadges}
				secondaryBadges={secondaryBadges}
				image={image.imageURL}
				imageFit={
					image.backgroundColor !== undefined && image.containImage
						? 'Contain'
						: 'Cover'
				}
				imageBackgroundColor={image.backgroundColor ?? backgroundColor}
				onClick={onClick}
				secondaryTitle={price}
				title={title}
				textBackdropColor={image.imageTextBackdropColor}
				textColor={image.imageTextColor}
				isTextContrastColorDark={image.imageIsTextContrastColorDark}
				disabled={disabled}
			/>;
		}
		else
		{
			return <MediaButton
				backgroundColor={image.backgroundColor ?? backgroundColor}
				primaryBadges={primaryBadges}
				secondaryBadges={secondaryBadges}
				captionHasShadow={image.backgroundColor === undefined || image.imageHasTextBackdrop}
				minHeight={minHeight}
				minImageHeight={minImageHeight}
				minImageWidth={minImageWidth}
				image={image.imageURL}
				imageFit={
					image.backgroundColor !== undefined && image.containImage
						? 'Contain'
						: 'Cover'
				}
				imageIsTextContrastColorDark={image.imageIsTextContrastColorDark}
				imageTextBackdropColor={image.imageTextBackdropColor}
				imageTextColor={image.imageTextColor}
				onClick={onClick}
				secondaryTitle={price}
				description={description}
				style={{
					flexGrow: 1,
				}}
				title={title}
				disabled={disabled}
				showDescriptionOnTile={showDescriptionOnTile}
			/>;
		}
	};
