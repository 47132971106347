import { Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext } from 'react';
import { useTranslate } from '../../../../../../Bridge/Localization/useTranslate';
import { AuthenticationContext } from '../../../../../authentication-provider/AuthenticationContext';
import { AppliedRewards } from '../../../Como/AppliedRewards/AppliedRewards';
import { AppliedRewardsSummary } from '../../../Como/AppliedRewards/AppliedRewardsSummary';
import { OrderBuilderStore } from '../../OrderBuilderStore';
import { ComoRewardOrderBuilderSegmentLoginSection } from './ComoRewardOrderBuilderSegmentLoginSection';

const useStyles = makeStyles(theme => ({
	accordion: {
		backgroundColor: '#fff8',
	},
	accordionContent: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
	},
	accordionContentRoot: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		gap: theme.spacing(3),
		width: '100%',
	},
	accordionDetails: {
		paddingBottom: 8,
	},
	rewardsContainer: {
		marginLeft: 'auto',
	},
}));

interface ComoRewardOrderBuilderSegmentProps
{
	store: OrderBuilderStore;
}

export const ComoRewardOrderBuilderSegment: FC<ComoRewardOrderBuilderSegmentProps> =
	({
		store,
	}) =>
	{
		const {authenticationResult} = useContext(AuthenticationContext);
		const translate = useTranslate();

		const appliedRewardsStore = useObserver(() => store.appliedRewardsStore);
		const comoRewardHasFocus = useObserver(() => store.comoRewardHasFocus);
		const setComoRewardHasFocus = useObserver(() => store.setComoRewardHasFocus);
		const showComoRewardSegment = useObserver(() => store.showComoRewardSegment);

		const classes = useStyles();

		if (showComoRewardSegment)
		{
			return <Grid
				item
				xs={12}
				style={{
					flex: '0 0 auto',
				}}
			>
				<Accordion
					className={classes.accordion}
					expanded={comoRewardHasFocus}
					onChange={
						(_, expanded) =>
							setComoRewardHasFocus(expanded)
					}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
					>
						<div
							className={classes.accordionContentRoot}
						>
							<div
								className={classes.accordionContent}
							>
								<Typography
									variant="body2"
								>
									{translate('Client-Loyalty-Como-Rewards')}
								</Typography>
								<div
									className={classes.rewardsContainer}
								>
									{
										appliedRewardsStore !== undefined &&
										<AppliedRewardsSummary store={appliedRewardsStore} />
									}
								</div>
							</div>
							{
								(authenticationResult.version !== 'V3' || authenticationResult.idTokenPayload.anonymous) &&
								<ComoRewardOrderBuilderSegmentLoginSection
									store={store}
								/>
							}
						</div>
					</AccordionSummary>
					<AccordionDetails
						className={classes.accordionDetails}
					>
						{
							appliedRewardsStore !== undefined &&
							<AppliedRewards store={appliedRewardsStore} />
						}
					</AccordionDetails>
				</Accordion>
			</Grid>;
		}
		else
		{
			return null;
		}
	};