import { makeStyles } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { useOrderListSortDirection } from '../context/sort-direction/OrderListSortDirectionProvider';

const useStyles = makeStyles(theme => ({
	icon: {
		color: theme.palette.common.white,
	},
}))

export const SortDirectionIndicator: FC =
	() =>
	{
		const sortDirection = useOrderListSortDirection();
		const Icon = useMemo(
			() => sortDirection === 'desc'
				?
				ArrowDropDown
				:
				ArrowDropUp,
			[sortDirection]
		);
		const classes = useStyles();
		return <Icon
			className={classes.icon}
		/>;
	};