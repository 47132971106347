import { Collapse, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { BaseCSSProperties } from '@material-ui/styles/withStyles/withStyles';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { useIsKioskMode } from '../../../../Service/KioskService/Api/useIsKioskMode';
import { getTopBarHeight } from '../../../UI/PageTopBar';
import { useSearchContext } from '../../../UI/Search/SearchContext';
import { SessionStatusBarPlaceholder } from '../../../UI/session-status-bar/SessionStatusBarPlaceholder';
import { MenuCarousel } from './Carousel/MenuCarousel';
import { useNumberOfFullscreenMenuGridColumns } from './Grid/Api/useNumberOfFullscreenMenuGridColumns';
import { MenuGrid } from './Grid/MenuGrid';
import { MenuStore } from './MenuStore';
import { SearchResultList } from './SearchResultList/SearchResultList';
import { MenuTopContent } from './TopContent/MenuTopContent';

const useStyle = makeStyles<Theme, { hasSearchQuery: boolean, inTopLevelMenu: boolean, isKioskMode: boolean }>(theme => ({
	container: ({inTopLevelMenu}) => ({
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		marginRight: 0, // to make menu's top bar appear under sidebar's round edges
		overflow: inTopLevelMenu ? undefined : 'hidden',
		paddingRight: 0, // to make menu's top bar appear under sidebar's round edges
	}),
	menuGrid: {
		display: 'flex',
		flex: '1 0 auto',
		flexDirection: 'column',
		flexWrap: 'nowrap',
	},
	menuGridListContainer: ({hasSearchQuery, inTopLevelMenu, isKioskMode}) => {
		const base: BaseCSSProperties = {
			display: hasSearchQuery ? 'none' : 'flex',
			flex: '1 0 auto',
			flexDirection: 'column',
		};

		const paddingValue = hasSearchQuery ? 0 : theme.spacing(1);

		const additional: BaseCSSProperties = inTopLevelMenu
			? {
				padding: paddingValue,
			}
			: {
				paddingLeft: paddingValue,
				paddingRight: paddingValue,
				paddingBottom: paddingValue,

				[theme.breakpoints.down('sm')]: {
					marginTop:
						getTopBarHeight(
							true,
							isKioskMode
						) + paddingValue,
				},
				[theme.breakpoints.up('md')]: {
					marginTop:
						getTopBarHeight(
							false,
							isKioskMode
						) + paddingValue,
				},
			};

		return {...base, ...additional};
	},
	searchResultList: {
		padding: theme.spacing(1),
		margin: -4,
	},
}));

interface MenuContentProps
{
	onStartPayingBill: () => void;
	store: MenuStore;
}

export const MenuContent: FC<MenuContentProps> =
	(
		{
			onStartPayingBill,
			store,
		},
	) =>
	{
		const {isSearching, query} = useSearchContext();
		const isKioskMode = useIsKioskMode();
		const hasSearchQuery = useMemo(() => isSearching && query !== undefined, [isSearching, query]);
		const inTopLevelMenu = useObserver(() => !store.parentMenuStore);
		const classes = useStyle({hasSearchQuery, inTopLevelMenu, isKioskMode});
		const numberOfGridColumns = useNumberOfFullscreenMenuGridColumns();
		const menuTopContent = useMemo(
			() => <MenuTopContent onStartPayingBill={onStartPayingBill} store={store} />,
			[onStartPayingBill, store],
		);
		const menuEntriesGrid =
			useMemo(
				() =>
				{
					if (hasSearchQuery)
					{
						return undefined;
					}

					const carouselMenuStores =
						store.childMenuStores.filter(
							menuStore =>
								menuStore.category.menuEntryType === 'CAROUSEL'
						);
					const nonCarouselMenuStores =
						store.childMenuStores.filter(
							menuStore =>
								menuStore.category.menuEntryType !== 'CAROUSEL'
						);

					if (isKioskMode && store.category.categories.length > 0)
					{
						return <MenuCarousel
							store={store}
							nrOfColumns={numberOfGridColumns}
							childMenuStores={store.childMenuStores}
						/>;
					}
					else
					{
						return <>
							{
								carouselMenuStores.length > 0 &&
								<MenuCarousel
									store={store}
									nrOfColumns={numberOfGridColumns}
									childMenuStores={carouselMenuStores}
									excludeProducts={nonCarouselMenuStores.length > 0}
								/>
							}
							<MenuGrid
								store={store}
								nrOfColumns={numberOfGridColumns}
								childMenuStores={nonCarouselMenuStores}
							/>
						</>;
					}
				},
				[
					hasSearchQuery,
					numberOfGridColumns,
					store,
					isKioskMode,
				]
			);
		const menuEntries = useMemo(() => {
			return <div className={classes.menuGridListContainer}>
				<Grid
					classes={{
						container: classes.menuGrid,
					}}
					container
					spacing={1}
				>
					{menuTopContent}
					{menuEntriesGrid}
				</Grid>
			</div>;
		}, [classes.menuGrid, classes.menuGridListContainer, menuEntriesGrid, menuTopContent]);
		const searchResultList = useMemo(() => {
			return <Collapse in={hasSearchQuery} unmountOnExit>
				<SearchResultList
					className={classes.searchResultList}
					onStartPayingBill={onStartPayingBill}
					searchQuery={query?.trim()}
					store={store}
				/>
			</Collapse>;
		}, [classes.searchResultList, hasSearchQuery, onStartPayingBill, query, store]);

		return <div className={classes.container}>
			<div className={classes.menuGrid}>
				{menuEntries}
				{searchResultList}
			</div>
			<SessionStatusBarPlaceholder />
		</div>;
	};
