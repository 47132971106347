import { OrderDescriptor } from '../../../../Api/Order/OrderDescriptor';
import { postAny } from '../../../../Util/Api';

export function voidOrder(order: OrderDescriptor): Promise<any>
{
	return postAny(
		'/client/business/order/void',
		{
			orderID: order.id,
		});
}