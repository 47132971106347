import { BaseStore } from '@intentic/ts-foundation';
import { action, computed, makeObservable, observable } from 'mobx';
import { Bridge } from '../../../Bridge/Bridge';
import { BrandingService } from '../../../Service/BrandingInformation/BrandingService';
import { OrderService } from '../../../Service/OrderService/OrderService';
import { ShoppingCartStore } from '../../Page/Business/ShoppingCart/ShoppingCartStore';
import { EntranceStore } from '../../Page/Entrance/EntranceStore';

export class SessionStatusBarStore extends BaseStore
{
    /*---------------------------------------------------------------*
     *                          Properties                           *
     *---------------------------------------------------------------*/

    readonly bridge: Bridge;
    readonly brandingService: BrandingService;
    readonly shoppingCartStore: ShoppingCartStore;
    readonly entranceStore: EntranceStore;
    readonly orderService: OrderService;
    readonly productCurrencyCode: string;
    readonly onOpenOpenOrders: () => void;
    readonly onOpenShoppingCart: () => void;

    /**
     * The viewport width in pixels
     */
    viewportWidth: number;

    /*---------------------------------------------------------------*
     *                          Constructor                          *
     *---------------------------------------------------------------*/

    constructor(
        bridge: Bridge,
        brandingService: BrandingService,
        shoppingCartStore: ShoppingCartStore,
        entranceStore: EntranceStore,
        orderService: OrderService,
        productCurrencyCode: string,
        onOpenOpenOrders: () => void,
        onOpenShoppingCart: () => void,
    )
    {
        super();

        makeObservable(
            this,
            {
                viewportWidth: observable,
                doShowCollapsedShoppingCart: computed,
                doShowCollapsedHistory: computed,
                updateViewportWidth: action.bound,
            },
        );

        this.bridge = bridge;
        this.brandingService = brandingService;
        this.shoppingCartStore = shoppingCartStore;
        this.entranceStore = entranceStore;
        this.orderService = orderService;
        this.productCurrencyCode = productCurrencyCode;
        this.onOpenOpenOrders = onOpenOpenOrders;
        this.onOpenShoppingCart = onOpenShoppingCart;

        this.updateViewportWidth();
    }


    /*---------------------------------------------------------------*
     *                           Computed                            *
     *---------------------------------------------------------------*/

    get doShowCollapsedShoppingCart(): boolean
    {
        return !this.shoppingCartStore.isEmpty;
    }

    get doShowCollapsedHistory(): boolean
    {
        return this.orderService.ordersCreatedToday.length > 0
            && !this.entranceStore.hideOrderHistory;
    }

    /*---------------------------------------------------------------*
     *                            Actions                            *
     *---------------------------------------------------------------*/

    updateViewportWidth(): void
    {
        this.viewportWidth = window.innerWidth;
    }
}
