import { useCallback, useEffect, useState } from 'react';

type ScreenOrientation = 'landscape' | 'portrait';

export function useScreenOrientation(): ScreenOrientation
{
	const [orientation, setOrientation] = useState<ScreenOrientation>(window.screen.orientation.angle === 90 ? 'landscape' : 'portrait');

	const onOrientationChange = useCallback(() => setOrientation(window.screen.orientation.angle === 90 ? 'landscape' : 'portrait'), []);

	useEffect(
		() =>
		{
			window.addEventListener('orientationchange', onOrientationChange);

			return () => window.removeEventListener('orientationchange', onOrientationChange);
		},
		[onOrientationChange]);

	return orientation;
}