import { Button } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Settings } from '@material-ui/icons';
import clsx from 'clsx';
import * as React from 'react';
import { CSSProperties, FC, useCallback, useMemo } from 'react';

const useStyles = makeStyles(theme => ({
	icon: {
		fontSize: 16,
		opacity: 0.5,
	},
	root: {
		borderBottomLeftRadius: theme.shape.borderRadius,
		borderBottomRightRadius: theme.shape.borderRadius,
		bottom: 0,
		height: 24,
		padding: 0,
		position: 'absolute',
		width: '100%',
	},
}));

interface PlaceButtonSettingsButtonProps
{
	className?: string
	onClick: () => void
	style?: CSSProperties
}

export const PlaceButtonSettingsButton: FC<PlaceButtonSettingsButtonProps> =
	(
		{
			className,
			onClick,
			style,
		},
	) =>
	{
		const classes = useStyles();

		const rootClassesString = useMemo(
			() => clsx(classes.root, className),
			[className, classes.root]
		);
		
		const callback = useCallback(
			event => {
				event.stopPropagation();
				onClick();
			},
			[onClick]
		);

		return <Button
			className={rootClassesString}
			onClick={callback}
			style={style}
		>
			<Settings
				className={clsx(classes.icon, 'settings-icon')}
			/>
		</Button>;
	};
