import { Button, Theme, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import ChevronDoubleRight from 'mdi-material-ui/ChevronDoubleRight';
import * as React from 'react';
import { FC, useContext, useMemo } from 'react';
import { OrderDescriptor } from '../../../../../../../Api/Order/OrderDescriptor';
import { UIColors } from '../../../../../../../Constants/UIColors';
import { EntranceContextRef } from '../../../../../Entrance/EntranceContext';
import { LocalizedPrice } from '../LocalizedPrice';

const useStyles = makeStyles(
	(theme: Theme) =>
		({
			actionsRoot:
				{
					display: 'flex',
					flexGrow: 1,
					justifyContent: 'space-between',
				},
			buttonIcon:
				{
					marginLeft: theme.spacing(2),
				},
			buttonNegative:
				{
					backgroundColor: UIColors.negative,
				},
			buttonPositive:
				{
					backgroundColor: UIColors.positive,
				},
			buttonLabel:
				{
					justifyContent: 'space-between',
				},
		}));

interface BillDialogActionsProps
{
	cost?: LocalizedPrice;
	onPaid: () => void;
	onVoid: () => void;
	selectedOrders?: OrderDescriptor[];
}

export const BillDialogActions: FC<BillDialogActionsProps> =
	({
		 cost,
		 onPaid,
		 onVoid,
		 selectedOrders,
	 }) =>
	{
		const classes = useStyles();

		const {
			localizer,
		} = useContext(EntranceContextRef);

		const hasSelectedOrders = useMemo(() => selectedOrders && selectedOrders.length > 0, [selectedOrders]);
		const isNonzeroPrice = useMemo(() => cost && cost.price.toNumber() > 0, [cost]);

		return <div
			className={classes.actionsRoot}
		>
			<Button
				classes={{
					root: classes.buttonNegative,
				}}
				disabled={!hasSelectedOrders}
				onClick={onVoid}
				variant="contained"
			>
				<Typography
					noWrap
				>
					{localizer.translate('Order-Void')}
				</Typography>
				<DeleteOutlined />
			</Button>
			<Button
				classes={{
					root: classes.buttonPositive,
				}}
				disabled={!isNonzeroPrice}
				onClick={onPaid}
				variant="contained"
			>
				{
					cost &&
					isNonzeroPrice &&
					<Typography>
						{localizer.formatCurrency(cost.price, cost.currencyCode)}
					</Typography>
				}
				<ChevronDoubleRight />
			</Button>
		</div>;
	};