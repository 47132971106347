import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';
import { PlacePasswordAccordion } from '../../../../../UI/password/place/form/accordion/PlacePasswordAccordion';
import { OrderBuilderSegment } from '../../OrderBuilderSegment';
import { OrderBuilderStore } from '../../OrderBuilderStore';

interface PasswordOrderBuilderSegmentProps
{
	store: OrderBuilderStore;
}

export const PasswordOrderBuilderSegment: FC<PasswordOrderBuilderSegmentProps> =
	({
		store,
	}) =>
	{
		const hasError = useObserver(() => store.passwordHasError);
		const hasFocus = useObserver(() => store.passwordFormHasFocus);
		const password = useObserver(() => store.password);

		const setHasFocus = useObserver(() => store.setPasswordFormHasFocus);
		const setPassword = useObserver(() => store.setPassword);

		const show = useObserver(() => store.place.passwordSupport !== 'DISALLOWED');

		if (show)
		{
			return <OrderBuilderSegment>
				<PlacePasswordAccordion
					hasError={hasError}
					hasFocus={hasFocus}
					onChange={setPassword}
					onFocusChange={setHasFocus}
					value={password ?? ''}
				/>
			</OrderBuilderSegment>;
		}
		else
		{
			return null;
		}
	};