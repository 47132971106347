import * as React from 'react';
import { createContext, FC, useContext, useMemo } from 'react';
import { BrandingService } from '../../Service/BrandingInformation/BrandingService';
import { IllegalStateException } from '../../Util/Exception/IllegalStateException';
import { useCurrentPlaceService } from '../current-place-service/CurrentPlaceService';

interface Context
{
	brandingService: BrandingService
}

const ContextRef = createContext<Context | undefined>(undefined);

export const BrandingServiceProvider: FC =
	(
		{
			children,
		},
	) =>
	{
		const currentPlaceService = useCurrentPlaceService();
		const brandingService = useMemo(() => new BrandingService(currentPlaceService), [currentPlaceService]);
		return <ContextRef.Provider
			value={useMemo(() => ({
				brandingService,
			}), [brandingService])}
		>
			{children}
		</ContextRef.Provider>;
	};

export function useBrandingService(): BrandingService
{
	const context = useContext(ContextRef);
	return useMemo(
		() => {
			if (context === undefined)
				throw new IllegalStateException(
					'Attempting to use useBrandingService outside BrandingServiceProvider'
				);
			return context.brandingService;
		},
		[context],
	);
}