import * as React from 'react';
import { Avatar, ListItem, ListItemText } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Localizer } from '../../../../../Bridge/Localization/Localizer';
import allergenImageCel from './Resource/allergen_cel.png';
import allergenImageCer from './Resource/allergen_cer.png';
import allergenImageCru from './Resource/allergen_cru.png';
import allergenImageEgg from './Resource/allergen_egg.png';
import allergenImageFis from './Resource/allergen_fis.png';
import allergenImageLup from './Resource/allergen_lup.png';
import allergenImageMil from './Resource/allergen_mil.png';
import allergenImageMol from './Resource/allergen_mol.png';
import allergenImageMus from './Resource/allergen_mus.png';
import allergenImageNut from './Resource/allergen_nut.png';
import allergenImagePea from './Resource/allergen_pea.png';
import allergenImageSes from './Resource/allergen_ses.png';
import allergenImageSoy from './Resource/allergen_soy.png';
import allergenImageSul from './Resource/allergen_sul.png';

export function getAllergenImage(allergen: string)
{
    switch (allergen)
    {
        case 'cel':
            return allergenImageCel;
        case 'cer':
            return allergenImageCer;
        case 'cru':
            return allergenImageCru;
        case 'egg':
            return allergenImageEgg;
        case 'fis':
            return allergenImageFis;
        case 'lup':
            return allergenImageLup;
        case 'mil':
            return allergenImageMil;
        case 'mol':
            return allergenImageMol;
        case 'mus':
            return allergenImageMus;
        case 'nut':
            return allergenImageNut;
        case 'pea':
            return allergenImagePea;
        case 'ses':
            return allergenImageSes;
        case 'soy':
            return allergenImageSoy;
        case 'sul':
            return allergenImageSul;
        default:
            return undefined;
    }
}

export interface AllergenProps
{
    localizer: Localizer;
    allergen: string;
}

@observer
export default class Allergen extends React.Component<AllergenProps>
{
    render(): React.ReactNode
    {
        return <ListItem
            style={{
                padding: 8,
            }}
        >
            <Avatar
                src={getAllergenImage(this.props.allergen)}
            />
            <ListItemText
                primary={this.props.localizer.translate(`Allergens-${this.props.allergen.charAt(0).toUpperCase().concat(this.props.allergen.substr(1))}`)}
                primaryTypographyProps={{
                    color: 'textSecondary'
                }}
                style={{
                    padding: '0px 16px',
                }}
            />
        </ListItem>;
    }
}
