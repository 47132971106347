import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { CSSProperties, FC } from 'react';
import { ScreenInstantiation } from '../../Bridge/Navigator/ScreenInstantiation';
import { useRootContext } from '../../RootContext';
import { useBrowserRootLayoutPolyfillerNavigationListener } from '../BrowserRootLayoutPolyfiller/useBrowserRootLayoutPolyfillerNavigationListener';
import { MultiProvider } from '../Root/MultiProvider';
import { ScrollRetainer } from '../Root/ScrollRetainer';
import { Dialog } from '../UI/Dialog';

const ScreenLoaderStyle: CSSProperties = {
	bottom: 0,
	left: 0,
	position: 'absolute',
	right: 0,
	top: 0,
};

export interface ScreenLayer
{
	screenInstantiation: ScreenInstantiation
	type: 'dialog' | 'screen' | 'leftdrawer' | 'rightdrawer'
}

export const ScreenContainer: FC =
	() =>
	{
		const {
			navigator,
			dialog,
		} = useRootContext(true);

		const containsDialog = useObserver(
			() => navigator.currentDialogs.length > 0
				|| dialog.dialogs.length > 0
		);
		const visibleScreenInstantiations = useObserver(
			() => {
				const screenInstantiations: Array<ScreenLayer> = [];

				if (navigator.currentScreenInstance)
				{
					screenInstantiations.push({
						screenInstantiation: navigator.currentContentScreen,
						type: 'screen',
					});

					screenInstantiations.push(
						...navigator.currentDialogs
							.map<ScreenLayer>(dialog => ({
								screenInstantiation: dialog,
								type: 'dialog',
							}))
							.reverse(),
					);

					if (navigator.currentRightDrawer)
					{
						screenInstantiations.push({
							screenInstantiation: navigator.currentRightDrawer,
							type: 'rightdrawer',
						});
					}

					if (navigator.currentLeftDrawer)
					{
						screenInstantiations.push({
							screenInstantiation: navigator.currentLeftDrawer,
							type: 'leftdrawer',
						});
					}
				}

				return screenInstantiations;
			}
		);

		const dialogs = useObserver(
			() => dialog.dialogs.map(
				dialog => <Dialog
					key={dialog.id}
					instance={dialog}
				/>,
			),
		);

		const screens = useObserver(
			() => {
				return visibleScreenInstantiations.map(
					(layer, index) => navigator.currentContentScreen === layer.screenInstantiation
						?
						<MultiProvider
							contexts={layer.screenInstantiation.inheritedContextProviders}
							key={index}
						>
							{
								wrapWithHookProviders(
									<ScrollRetainer
										key={index}
										layer={layer}
										containsDialog={containsDialog}
									>
										{
											React.createElement(
												layer.screenInstantiation.screen.component(),
												{
													store: layer.screenInstantiation.store,
													loaderStyle: ScreenLoaderStyle,
												},
											)
										}
									</ScrollRetainer>,
									layer.screenInstantiation,
									index
								)
							}
						</MultiProvider>
						:
						<MultiProvider
							contexts={layer.screenInstantiation.inheritedContextProviders}
							key={`${layer.screenInstantiation.screen.id}-${layer.screenInstantiation.store.uuid}`}
						>
							{React.createElement(
								layer.screenInstantiation.screen.component(),
								{
									store: layer.screenInstantiation.store,
									loaderStyle: ScreenLoaderStyle,
								},
							)}
						</MultiProvider>,
				);
			},
		);

		useBrowserRootLayoutPolyfillerNavigationListener(
			dialog,
			navigator
		);

		return <>
			{screens}
			{dialogs}
		</>;
	};

const wrapWithHookProviders = (children: React.ReactNode, screenInstantiation: ScreenInstantiation, key?: string | number): React.ReactNode =>
{
	const hooksProviderComponent = screenInstantiation.screen.hooksProviderComponent;
	const withHooksOfThisScreen = (() => {
		if (hooksProviderComponent !== undefined)
		{
			return React.createElement(
				hooksProviderComponent,
				{
					key: key
				},
				children
			)
		}
		return children;
	})();
	const parentScreenInstantiation = screenInstantiation.parentScreenInstantiation;
	if (parentScreenInstantiation !== undefined)
	{
		return wrapWithHookProviders(
			withHooksOfThisScreen,
			parentScreenInstantiation,
			key
		);
	}
	else
	{
		return withHooksOfThisScreen;
	}
}