import { useObserver } from 'mobx-react-lite';
import { useMemo } from 'react';
import { OrderDescriptor } from '../../Api/Order/OrderDescriptor';
import { OrderLineDescriptor } from '../../Api/Order/OrderLineDescriptor';
import { useWaiter } from '../../Service/UserService/useWaiter';
import { useRoutingWaiterMode } from '../routing-waiter-mode/useRoutingWaiterMode';

export function useOrderLinesThatWaiterCanSee(order: OrderDescriptor): OrderLineDescriptor[]
{
	const routingWaiterMode = useRoutingWaiterMode(true);
	const waiter = useWaiter(true);
	const waiterRoutingId = useObserver(() => waiter.routingId);

	return useMemo(
		() =>
			order
				.orderLines
				.filter(
					line =>
						!routingWaiterMode ||
						waiterRoutingId === line.routingId,
				),
		[order.orderLines, routingWaiterMode, waiterRoutingId],
	);
}