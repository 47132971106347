import { BaseStore } from '@intentic/ts-foundation';
import { action, makeObservable, observable } from 'mobx';
import { Place } from '../../../../Api/Business/Place';
import { OrderDescriptor } from '../../../../Api/Order/OrderDescriptor';
import { ProductOrderLineDescriptor } from '../../../../Api/Order/OrderLineDescriptor';
import { Product } from '../../../../Api/Product/Product';
import { ProductConfiguration } from '../../../../Api/Product/ProductConfiguration';
import { ProductFeatureAssignment } from '../../../../Api/Product/ProductFeatureAssignment';
import { ProductFeatureVariant } from '../../../../Api/Product/ProductFeatureVariant';
import { isTrueAtInCache } from '../../../../Api/Util/time-series/BooleanTimeSeries/BooleanTimeSeriesCache';
import { Bridge } from '../../../../Bridge/Bridge';
import { WebNavigator } from '../../../../Bridge/Navigator/WebNavigator';
import { BrandingService } from '../../../../Service/BrandingInformation/BrandingService';
import { OrderService } from '../../../../Service/OrderService/OrderService';
import { postAny } from '../../../../Util/Api';
import { confirm } from '../../../../Util/Dialog';
import { isProductOrderLine } from '../../../../Util/order_line/isProductOrderLine';
import { ProductStore } from '../Product/ProductStore';

export class HistoryStore extends BaseStore
{
    /*---------------------------------------------------------------*
     *                         Dependencies                          *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                          Properties                           *
     *---------------------------------------------------------------*/

    bridge: Bridge;
    place: Place | undefined;
    brandingService: BrandingService;
    orderService: OrderService;
    productById: Map<number, Product>;
    productFeatureVariantById: Map<number, ProductFeatureVariant> | undefined;
    productFeatureAssignmentById: Map<number, ProductFeatureAssignment> | undefined;
    onClose: () => Promise<any>;
    openOrderBuilder: (() => void) | undefined;
    openProduct: ((product: Product) => Promise<ProductStore>) | undefined;
    private readonly populateCurrentOrder: ((productConfiguration: ProductConfiguration, diffInQuantity: number, comment: string | undefined) => void) | undefined;
    push: (screenId: string, store: BaseStore) => Promise<any>;

    /*---------------------------------------------------------------*
     *                          Constructor                          *
     *---------------------------------------------------------------*/

    constructor(
        bridge: Bridge,
        place: Place | undefined,
        brandingService: BrandingService,
        orderService: OrderService,
        productFeatureVariantById: Map<number, ProductFeatureVariant> | undefined,
        productFeatureAssignmentById: Map<number, ProductFeatureAssignment> | undefined,
        onClose: () => Promise<any>,
        openOrderBuilder: (() => void) | undefined,
        productById: Map<number, Product> | undefined,
        openProduct: ((product: Product) => Promise<ProductStore>) | undefined,
        addToShoppingCart: ((productConfiguration: ProductConfiguration, diffInQuantity: number, comment: string | undefined) => void) | undefined,
        pop: () => Promise<any>,
        push: (screenId: string, store: BaseStore) => Promise<any>,
    )
    {
        super();

        makeObservable(
            this,
            {
                bridge: observable,
                place: observable,
                brandingService: observable,
                orderService: observable,
                productById: observable,
                productFeatureVariantById: observable,
                productFeatureAssignmentById: observable,
                onClose: observable,
                openOrderBuilder: observable,
                openProduct: observable,
                push: observable,
                canOrderAgain: action.bound,
                orderAgain: action.bound,
                shareOrder: action.bound,
                shareOrderOnService: action.bound,
                pop: action.bound,
                close: action.bound,
            },
        );

        this.bridge = bridge;
        this.place = place;
        this.brandingService = brandingService;
        this.orderService = orderService;
        this.productById = new Map<number, Product>(productById ?? []);
        this.onClose = onClose;
        this.openOrderBuilder = openOrderBuilder;
        this.openProduct = openProduct;
        this.populateCurrentOrder = addToShoppingCart;
        this.productFeatureVariantById = productFeatureVariantById;
        this.productFeatureAssignmentById = productFeatureAssignmentById;
        this.push = push;
    }

    /*---------------------------------------------------------------*
     *                        Initialization                         *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                           Computed                            *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                            Actions                            *
     *---------------------------------------------------------------*/

    canOrderAgain(order: OrderDescriptor): boolean
    {
        const now = this.bridge.clockService.currentMinute

        return (
                this.openOrderBuilder !== undefined &&
                this.populateCurrentOrder !== undefined &&
                this.productById !== undefined &&
                this.productFeatureVariantById !== undefined &&
                this.place?.isOrderProductSupported &&
                order.orderLines?.every(
                    orderLine =>
                    {
                        switch (orderLine.type)
                        {
                            case 'fee':
                            case 'product_fee':
                            {
                                return true;
                            }
                            case 'product':
                            {
                                const product = this.productById.get(orderLine.productId);

                                if (product === undefined)
                                {
                                    return false;
                                }
                                else
                                {
                                    const hasAllVariants = orderLine
                                        .variants
                                        .every(
                                            orderLineVariant =>
                                                this.productFeatureVariantById.has(
                                                    orderLineVariant.variantId,
                                                ),
                                        );

                                    return hasAllVariants
                                        && isTrueAtInCache(product.orderableAt, now);
                                }
                            }
                            default:
                            {
                                return false;
                            }
                        }
                    },
                )
            )
            ?? false;
    }

    orderAgain(order: OrderDescriptor): Promise<void>
    {
        const entries = order
            .orderLines
            .filter(
                (orderLine): orderLine is ProductOrderLineDescriptor =>
                    isProductOrderLine(orderLine) &&
                    this.productById.has(orderLine.productId),
            )
            .map(
                orderLine =>
                    ({
                        configuration: new ProductConfiguration(
                            this.productById.get(orderLine.productId),
                            orderLine
                                .variants
                                .filter(
                                    variant =>
                                        this.productFeatureVariantById.has(variant.variantId) &&
                                        this.productFeatureAssignmentById.has(variant.assignmentId),
                                )
                                .map(
                                    variant =>
                                        ({
                                            variant: this.productFeatureVariantById.get(variant.variantId),
                                            assignment: this.productFeatureAssignmentById.get(variant.assignmentId),
                                        }),
                                ),
                        ),
                        quantity: orderLine.quantity,
                    }),
            )
            .filter(
                entry =>
                entry.configuration.isValid
            );

        entries
            .forEach(
                entry =>
                    this.populateCurrentOrder(entry.configuration, entry.quantity, order.comment));

        const numberOfConfigurationsInOrder = order
            .orderLines
            .filter(isProductOrderLine)
            .length;

        const numberOfVariantsInOrder =
            order
                .orderLines
                .filter(isProductOrderLine)
                .reduce(
                    (result, orderLine) =>
                        result + orderLine.variants.length,
                    0,
                );

        const numberOfVariantsOrdered =
            entries
                .map(
                    entry =>
                        entry.configuration.variantConfigurations.length)
                .reduce((a, b) => a + b, 0);

        if (numberOfConfigurationsInOrder !== entries.length || numberOfVariantsInOrder !== numberOfVariantsOrdered)
        {
            this.bridge.notification.notify(
                {
                    content: this.bridge.localizer.translate('Client-OrderHistory-Order-Again-SomeNotAvailable'),
                });
        }

        if (entries.length > 0)
        {
            return this
                .pop()
                .then(() => {
                    this.openOrderBuilder()
                });
        }
        else
        {
            return Promise.resolve();
        }
    }

    shareOrder(order: OrderDescriptor)
    {
        return confirm(
            this.bridge,
            (this.bridge.navigator as WebNavigator).scrollTo,
            this.bridge.localizer.translate('Client-OrderHistory-ShareMessage'),
            undefined,
            undefined,
            null,
            undefined,
            [
                {
                    name: this.bridge.localizer.translate('Client-OrderHistory-ShareOnTwitter'),
                    perform: () => this.shareOrderOnService(order, 'Twitter'),
                },
                // {
                //     text: this.businessStore.bridge.localizer.translate('Client-OrderHistory-ShareOnFacebook'),
                //     perform: () => this.shareOrderOnService(order, 'Facebook')
                // }
            ]);
    }

    shareOrderOnService(order: OrderDescriptor,
                        service: string)
    {
        return postAny('/client/business/order/share', {social_network: service, order_id: order.id})
            .then(result =>
            {
                const shareMessage = result.share_message;
                let shareUrl: string | undefined;

                switch (service)
                {
                    case 'Twitter':
                        shareUrl = `https://twitter.com/intent/tweet?text=${encodeURI(shareMessage)}`;
                        break;

                    // case 'Facebook':
                    //     shareUrl = `https://twitter.com/intent/tweet?text=${encodeURI(shareMessage)}`;
                    //     break;
                }

                if (shareUrl !== undefined)
                {
                    return this.bridge.linking.canOpenUrl(shareUrl)
                        .then(
                            () =>
                                this.bridge.linking.openUrl(shareUrl));
                }
            });
    }

    pop(): Promise<void>
    {
        return this.bridge.navigator.popScreen()
            .then(() => {});
    }

    close(): Promise<any>
    {
        return this.onClose();
    }

    /*---------------------------------------------------------------*
     *                          Public logic                         *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                         Private logic                         *
     *---------------------------------------------------------------*/
}
