import * as React from 'react';
import { createContext, FC, useContext, useMemo } from 'react';
import { Routing } from '../../../../../Api/Business/Routing/Routing';
import { VatGroup } from '../../../../../Api/vat_group/VatGroup';

interface Context
{
	routingIds: Array<number | undefined>;
	vatGroup?: VatGroup;
}

const ContextRef = createContext<Context>(undefined!);

interface ServiceFeeContextProviderProps
{
	baseLineRoutingIds: number[];
	serviceFeeRouting?: Routing;
	serviceFeeVatGroup?: VatGroup;
}

export const ServiceFeeContextProvider: FC<ServiceFeeContextProviderProps> =
	({
		baseLineRoutingIds,
		serviceFeeRouting,
		serviceFeeVatGroup,
		children,
	}) =>
	{
		const contextValue = useMemo<Context>(
			() =>
				({
					routingIds: serviceFeeRouting === undefined
						? baseLineRoutingIds.length === 0
							? [undefined]
							: baseLineRoutingIds
						: [serviceFeeRouting.id],
					vatGroup: serviceFeeVatGroup,
				}),
			[baseLineRoutingIds, serviceFeeRouting, serviceFeeVatGroup],
		);

		return <ContextRef.Provider
			value={contextValue}
		>
			{children}
		</ContextRef.Provider>;
	};

export function useServiceFeeContext(): Context
{
	return useContext(ContextRef);
}
