import { useObserver } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useWaiter } from '../../Service/UserService/useWaiter';
import { useManagerPanelBusiness } from '../manager-panel-business/useManagerPanelBusiness';

export function useRoutingWaiterMode(require?: false): boolean | undefined
export function useRoutingWaiterMode(require: true): boolean
export function useRoutingWaiterMode(require: boolean | undefined): boolean | undefined
{
	const business = useManagerPanelBusiness(require);
	const waiter = useWaiter(require);
	const waiterRoutingId = useObserver(() => waiter?.routingId);
	const waiterType = useObserver(() => waiter?.type);
	const orderHandlerScopingEnabled = useObserver(() => business?.orderHandlerScopingEnabled);
	return useMemo(
		() => waiterRoutingId !== undefined
			&& orderHandlerScopingEnabled
			&& (waiterType === 'Manager' || waiterType === 'Owner' || waiterType === 'Waiter'),
		[orderHandlerScopingEnabled, waiterRoutingId, waiterType],
	);
}