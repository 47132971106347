import { Theme, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import * as React from 'react';
import { CSSProperties, FC, useContext, useMemo } from 'react';
import { EntranceContextRef } from '../../../../Entrance/EntranceContext';

const AvatarSize = 32;

const useStyles = makeStyles((theme: Theme) => ({
	descriptionText: {
		marginLeft: theme.spacing(1),
	},
	descriptionTextAligned: {
		marginLeft: AvatarSize + theme.spacing(1),
	},
	root: {
		alignItems: 'center',
		display: 'flex',
		minHeight: AvatarSize + theme.spacing(1),
		padding: theme.spacing(1),
	},
}));

export interface RequestBillOrderLineProps
{
	alignWithAvatars?: boolean;
	style?: CSSProperties;
}

export const RequestBillOrderLine: FC<RequestBillOrderLineProps> =
	(
		{
			alignWithAvatars,
			style,
		},
	) =>
	{
		const classes = useStyles();

		const {localizer} = useContext(EntranceContextRef);

		const descriptionTextClassString = clsx({
			[classes.descriptionTextAligned]: alignWithAvatars,
			[classes.descriptionText]: !alignWithAvatars,
		});

		const label = useMemo(() => localizer.translate('Order-BillRequest'), [localizer]);

		return <div
			className={classes.root}
			style={style}
		>
			<Typography
				className={descriptionTextClassString}
			>
				{label}
			</Typography>
		</div>;
	};