import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import { CSSProperties, FC, useCallback, useEffect, useState } from 'react';
import { OrderDescriptor } from '../../../../../../Api/Order/OrderDescriptor';
import { getTotalPriceOfOrders } from '../../../../../../Util/Orders/getTotalPriceOfOrders';
import { LocalizedPrice } from './LocalizedPrice';
import { BillOrderItem } from './OrderItem/BillOrderItem';

const useStyles = makeStyles(
	(theme: Theme) =>
		({
			item:
				{
					margin: theme.spacing(1),
				},
		}));

export interface BillProps
{
	className?: string;
	onCostUpdate?: (cost?: LocalizedPrice) => void;
	onSelectedOrdersUpdate?: (orders: OrderDescriptor[]) => void;
	orders?: OrderDescriptor[];
	style?: CSSProperties;
}

export const Bill: FC<BillProps> =
	({
		 className,
		 onCostUpdate,
		 onSelectedOrdersUpdate,
		 orders = [],
		 style,
	 }) =>
	{
		const classes = useStyles();

		const [selectedOrders, setSelectedOrders] = useState<OrderDescriptor[]>([]);

		const deselectOrder = useCallback(
			(order: OrderDescriptor) =>
				setSelectedOrders(
					prev =>
						prev.filter(
							peer =>
								peer.id !== order.id,
						),
				),
			[],
		);

		const selectOrder = useCallback(
			(order: OrderDescriptor) =>

				setSelectedOrders(
					prev =>
						[
							...prev,
							order,
						],
				),
			[],
		);

		useEffect(
			() =>
			{
				selectedOrders.forEach(order =>
					orders.findIndex(peerOrder => peerOrder.id === order.id) < 0 && deselectOrder(order),
				);
			},
			[deselectOrder, orders, selectedOrders]);

		useEffect(
			() =>
			{
				onSelectedOrdersUpdate && onSelectedOrdersUpdate(selectedOrders);

				if (selectedOrders)
				{
					const totalCost = getTotalPriceOfOrders(selectedOrders);

					if (onCostUpdate)
					{
						onCostUpdate(totalCost[0]);
					}
				}
			},
			[onCostUpdate, onSelectedOrdersUpdate, selectedOrders]);

		const renderBillOrderItem = useCallback(
			(order: OrderDescriptor) =>
				<BillOrderItem
					className={classes.item}
					key={order.id}
					onClick={
						(order, select) =>
							select ? selectOrder(order) : deselectOrder(order)
					}
					order={order}
					selected={selectedOrders.some(peerOrder => peerOrder.id === order.id)}
				/>,
			[classes.item, deselectOrder, selectedOrders, selectOrder]);

		return <div
			className={className}
			style={style}
		>
			{orders.map(renderBillOrderItem)}
		</div>;
	};