import * as React from 'react';
import { FC, useEffect, useMemo } from 'react';
import { PaymentIssuer } from '../../../../../Api/Payment/PaymentIssuer';
import { PaymentMethodDescriptor } from '../../../../../Api/Payment/PaymentMethodDescriptor';
import { AdyenCheckoutData, isPaymentData } from '../../../../../lib/adyen/AdyenCheckoutData';
import { useAdyenCheckoutContext } from '../../adyen/checkout/AdyenCheckoutContext';
import { usePaymentIssuerContext } from '../../issuer/context/PaymentIssuerContext';
import { usePaymentMethodContext } from '../context/PaymentMethodContext';
import { adyenCheckoutPaymentMethodRequiresUserInput } from './editor/settings/adyenCheckoutPaymentMethodRequiresUserInput';
import { PaymentMethodsLoadingCard } from './loading/PaymentMethodsLoadingCard';
import { PaymentMethodsAccordion } from './multiple/PaymentMethodsAccordion';
import { PaymentMethodCard } from './single/PaymentMethodCard';

interface PaymentMethodFormProps
{
	className?: string;
	hasFocus: boolean;
	onChange?: (paymentMethod?: PaymentMethodDescriptor, paymentIssuer?: PaymentIssuer, adyenCheckoutData?: AdyenCheckoutData) => void;
	onFocusChange: (hasFocus: boolean) => void;
	onHasErrorChange?: (hasError: boolean) => void;
	onInteraction?: () => void;
}

export const PaymentMethodForm: FC<PaymentMethodFormProps> =
	({
		className,
		hasFocus,
		onChange,
		onFocusChange,
		onHasErrorChange,
		onInteraction,
	}) =>
	{
		const {data: adyenCheckoutData, paymentSettingsHasError} = useAdyenCheckoutContext();
		const {isConfigurable, isInitialized: arePaymentMethodsInitialized, selectedPaymentMethod, validation: paymentMethodValidation} = usePaymentMethodContext();
		const {isInitialized: areIssuersInitialized, selectedPaymentIssuer, validation: paymentIssuerValidation} = usePaymentIssuerContext();

		const isInitialized = useMemo(
			() => arePaymentMethodsInitialized && areIssuersInitialized,
			[areIssuersInitialized, arePaymentMethodsInitialized],
		);

		useEffect(() =>
		{
			if (isInitialized && onChange !== undefined)
			{
				if (selectedPaymentMethod === undefined)
				{
					onChange();
				}
				else if (selectedPaymentMethod.butlarooPayPaymentMethod !== undefined)
				{
					if (adyenCheckoutData !== undefined && isPaymentData(adyenCheckoutData) && selectedPaymentIssuer !== undefined)
						adyenCheckoutData.paymentMethod.issuer = selectedPaymentIssuer.id;

					if (adyenCheckoutPaymentMethodRequiresUserInput(selectedPaymentMethod))
					{
						if (!paymentSettingsHasError)
						{
							onChange(selectedPaymentMethod, selectedPaymentIssuer, adyenCheckoutData);
						}
					}
					else if (selectedPaymentMethod.isIssuerRequired && selectedPaymentIssuer !== undefined)
					{
						onChange(selectedPaymentMethod, selectedPaymentIssuer, adyenCheckoutData);
					}
					else if (!selectedPaymentMethod.isIssuerRequired)
					{
						onChange(selectedPaymentMethod, selectedPaymentIssuer, adyenCheckoutData);
					}
				}
				else if (selectedPaymentMethod.isIssuerRequired && selectedPaymentIssuer !== undefined)
				{
					onChange(selectedPaymentMethod, selectedPaymentIssuer);
				}
				else if (!selectedPaymentMethod.isIssuerRequired)
				{
					onChange(selectedPaymentMethod, selectedPaymentIssuer);
				}
			}
		}, [adyenCheckoutData, isInitialized, onChange, paymentSettingsHasError, selectedPaymentIssuer, selectedPaymentMethod]);

		useEffect(() =>
		{
			onHasErrorChange?.(
				paymentMethodValidation.length > 0
				|| paymentIssuerValidation.length > 0
				|| paymentSettingsHasError,
			);
		}, [onHasErrorChange, paymentIssuerValidation.length, paymentMethodValidation.length, paymentSettingsHasError]);

		if (!isInitialized)
			return <PaymentMethodsLoadingCard className={className} />;
		else if (isConfigurable)
			return <PaymentMethodsAccordion
				className={className}
				expanded={hasFocus}
				onFocusChange={onFocusChange}
				onInteraction={onInteraction}
			/>;
		else
			return <PaymentMethodCard className={className} />;
	};