import { toJS } from 'mobx';
import * as React from 'react';
import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { Place } from '../../../../../Api/Business/Place';
import { PlaceCode, PlaceCodeProfile } from '../../../../../Api/Business/PlaceCode';
import { IllegalStateException } from '../../../../../Util/Exception/IllegalStateException';
import { useManagerPlacesContext, useSortedManagerPlaces } from '../place/ManagerPlacesContext';
import { getPlaceCodeByHash } from './getPlaceCodeByHash';
import { postAssignPlaceCode } from './postAssignPlaceCode';
import { postUnassignPlaceCode } from './postUnassignPlaceCode';

interface Context
{
	assignPlaceCode: (placeCode: PlaceCode, placeId: number) => Promise<PlaceCode>
	fetchPlaceCodeByHash: (hash: string) => Promise<PlaceCode | undefined>
	getAssignedPlace: (placeCodeId: number) => Place | undefined
	unassignPlaceCode: (placeCode: PlaceCode) => Promise<PlaceCode>
}

const ContextRef = createContext<Context>(undefined as never);

export const ManagerPlaceCodesContextProvider: FC =
	(
		{
			children,
		},
	) =>
	{
		const {getPlaceById, updatePlace} = useManagerPlacesContext();
		const places = useSortedManagerPlaces();

		const placeCodes = useMemo(() => {
			return places
				?.filter(place => place.placeCodes !== undefined)
				.flatMap(place => toJS(place.placeCodes))
				.filter((placeCode, idx, array) => array.indexOf(placeCode) === idx)
				.map(placeCode => PlaceCodeProfile.deserializeSingular(placeCode)!);
		}, [places]);

		const placeIdByPlaceCodeId = useMemo(() => {
			return placeCodes
				?.reduce<Record<number, number>>((result, placeCode) => {

					if (placeCode.placeId !== undefined)
						result[placeCode.id] = placeCode.placeId;

					return result;
				}, {});
		}, [placeCodes]);

		const getAssignedPlace = useCallback((placeCodeId: number) => {
			const placeId = placeIdByPlaceCodeId?.[placeCodeId];

			if (placeId === undefined)
				return undefined;
			else
				return getPlaceById(placeId);
		}, [getPlaceById, placeIdByPlaceCodeId]);

		const fetchPlaceCodeByHash = useCallback((hash: string) => getPlaceCodeByHash({hash}), []);

		const assignPlaceCode = useCallback(async (placeCode: PlaceCode, placeId: number) => {
			const response = await postAssignPlaceCode({place_code_id: placeCode.id, place_id: placeId});

			if (response)
			{
				const place = places?.find(peerPlace => peerPlace.id === placeId);

				if (place === undefined)
					throw new IllegalStateException(`Failed to assign place code ${placeCode.id}: Place ${placeId} not found.`);

				const updatedPlaceCode = new PlaceCode(
					placeCode.id,
					placeCode.hash,
					placeId,
					placeCode.businessId,
				);

				const placeCodeIdx = place.placeCodes.findIndex(peerPlaceCode => peerPlaceCode.id === updatedPlaceCode.id);

				if (placeCodeIdx > -1)
					place.placeCodes.splice(placeCodeIdx, 1, updatedPlaceCode);
				else
					place.placeCodes.push(updatedPlaceCode);

				updatePlace(place);
			}
			else
			{
				alert(response);
			}

			return placeCode;
		}, [places, updatePlace]);

		const unassignPlaceCode = useCallback(async (placeCode: PlaceCode) => {
			const response = await postUnassignPlaceCode({place_code_id: placeCode.id});

			if (response)
			{
				const place = places?.find(peerPlace => peerPlace.id === placeCode.placeId);

				if (place !== undefined)
				{
					const placeCodeIdx = place.placeCodes.findIndex(peerPlaceCode => peerPlaceCode.id === placeCode.id);

					if (placeCodeIdx > -1)
					{
						place.placeCodes.splice(placeCodeIdx, 1);
						updatePlace(place);
					}
				}
			}
			else
			{
				alert(response);
			}

			return placeCode;
		}, [places, updatePlace]);

		const contextValue = useMemo<Context>(
			() => ({assignPlaceCode, fetchPlaceCodeByHash, getAssignedPlace, unassignPlaceCode}),
			[assignPlaceCode, fetchPlaceCodeByHash, getAssignedPlace, unassignPlaceCode],
		);

		return <ContextRef.Provider value={contextValue}>
			{children}
		</ContextRef.Provider>;
	};

export function useManagerPlaceCodesContext(): Context
{
	return useContext(ContextRef);
}