import { FC, PropsWithChildren, useMemo, useState } from 'react';
import { getIsRoutingLive } from '../../Service/RoutingService/Api/getIsRoutingLive';
import { RoutingState } from './RoutingState';
import { RoutingStateContext } from './RoutingStateContext';
import { RoutingStateController } from './RoutingStateController';

interface RoutingStateProviderProps
{

}

export const RoutingStateProvider: FC<PropsWithChildren<RoutingStateProviderProps>> =
	({
		children,
	}) =>
	{
		const [cache, setCache] =
			useState(
				() =>
					new Map<number, RoutingState>()
			);
		const controller =
			useMemo<RoutingStateController>(
				() => ({
					cache,
					loadStateByRoutingId:
						async (routingId: number) =>
						{
							if (cache.has(routingId))
							{
								return cache.get(routingId);
							}
							else
							{
								const isLive = await getIsRoutingLive(routingId);
								const state = {
									isLive,
								};
								setCache(
									currentCache =>
									{
										const newCache = new Map(currentCache);
										newCache.set(
											routingId,
											state
										);

										return newCache;
									}
								);

								return state;
							}
						}
				}),
				[cache]
			);

		return <RoutingStateContext.Provider
			value={controller}
		>
			{children}
		</RoutingStateContext.Provider>;
	};