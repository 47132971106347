// https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
// Compatible with older browsers
export function getUrlParameterByName(
	name: string,
	url = window.location.href
): string | undefined
{
	const safeName = name.replace(/[[\]]/g, '\\$&');
	const regex = new RegExp('[?&]' + safeName + '(=([^&#]*)|&|#|$)');
	const results = regex.exec(url);
	if (!results) return undefined;
	if (!results[2]) return undefined;
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
