import { configureScope } from '@sentry/react';
import { AuthenticationResult } from './AuthenticationResult';

export function updateSentryScope(authenticationResult: AuthenticationResult): void
{
	configureScope(
		scope =>
		{
			scope.setUser({
				id: authenticationResult.version === 'V2'
					? authenticationResult.key
					: authenticationResult.idTokenPayload.sub,
			});
		},
	);
}
