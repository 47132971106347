import { OrderDescriptor } from '../../Api/Order/OrderDescriptor';

/**
 * {@link #resolve} will get called when an {@link OrderDescriptor} instance goes through {@link OrderService} that satisfies
 * the constraints posed by {@link #predicate} and {@link #orderId}.
 */
export class OrderStateListener
{
    /**
     * If {@link undefined}, no constraint on the order ID is implied.
     */
    orderId: number|undefined;

    /**
     * Has to return {@code true} for the predicate to hold
     */
    predicate: (order: OrderDescriptor) => boolean;
    resolve: (value?: any | PromiseLike<any>) => void;
    reject: (reason?: any) => void;

    constructor(orderId: number|undefined,
                predicate: (order: OrderDescriptor) => boolean = order => true,
                resolve: (value?: any | PromiseLike<void>) => void,
                reject: (reason?: any) => void)
    {
        this.orderId = orderId;
        this.predicate = predicate;
        this.resolve = resolve;
        this.reject = reject;
    }
}
