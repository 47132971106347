import { makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { Order } from '../../../../../Api/Order/Order';
import { useLocalizer } from '../../../../../Bridge/Localization/useLocalizer';
import { useTranslation } from '../../../../../Bridge/Localization/useTranslation';

const useStyles = makeStyles({
	text: {
		marginBottom: 32,
	},
});

interface PaymentDialogStartPaymentLabelProps
{
	order: Order;
}

export const PaymentDialogStartPaymentLabel: FC<PaymentDialogStartPaymentLabelProps> =
	({
		order,
	}) =>
	{
		const {locale} = useLocalizer();

		const classes = useStyles();

		const priceString = useMemo(() =>
		{
			const formatter = new Intl.NumberFormat(
				locale?.id,
				{
					style: 'currency',
					currency: order.paymentCurrencyCode,
				},
			);

			return `${formatter.format(order.paymentPrice!.toNumber())}`;
		}, [locale?.id, order.paymentCurrencyCode, order.paymentPrice]);

		const text = useTranslation('Payment-Start', priceString);

		return <Typography
			className={classes.text}
			variant="h6"
		>
			{text}
		</Typography>;
	};
