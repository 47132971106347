import { PlaceSession, PlaceSessionProfile } from '../../../../../Api/Business/PlaceSession';
import { postJson } from '../../../../../Util/Api';

export function postSavePlaceSession(params: PostSavePlaceSessionParams): Promise<PlaceSession>
{
	return postJson('/waiter/business/place/session/save', params, PlaceSessionProfile);
}

interface PostSavePlaceSessionParams
{
	id?: number;
	external_id?: string;
	place_id: number;
	start_date: number;
	end_date?: number;
	number_of_covers: number;
	menu_card_id?: number;
}