import { ButtonBase, FormControlLabel, Switch, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import { CSSProperties, FC, useCallback } from 'react';
import { UIColors } from '../../../../Constants/UIColors';
import { useFormLabel } from '../Core/useFormLabel';

const useStyles = makeStyles((theme: Theme) => ({
	label: {
		alignItems: 'center',
		display: 'flex',
	},
	labelRoot: {
		flexGrow: 1,
		justifyContent: 'space-between',
		marginRight: 0,
	},
	icon: {
		color: UIColors.greyDark,
		marginTop: -6,
	},
	root: {
		paddingBottom: theme.spacing(0.5),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(0.5),
		'&:hover': {
			backgroundColor: 'rgba(0,0,0,0.05)',
		},
	},
}));

interface BooleanFormProps
{
	className?: string
	disabled?: boolean
	label: string
	explanation?: string
	secondary?: boolean
	setValue: (value: boolean) => void
	style?: CSSProperties
	value: boolean
	classes?: {
		label?: string
		labelRoot?: string
	}
}

export const BooleanForm: FC<BooleanFormProps> =
	(
		{
			className,
			disabled,
			label,
			explanation,
			secondary,
			setValue,
			style,
			value,
			classes: classesProp,
		},
	) =>
	{
		const classes = useStyles();

		const toggleValue = useCallback(() => setValue(!value), [setValue, value]);

		return <ButtonBase
			classes={{
				root: classes.root,
			}}
			className={className}
			disabled={disabled}
			style={style}
		>
			<FormControlLabel
				classes={{
					label: `${classes.label} ${classesProp?.label ?? ''}`,
					root: `${classes.labelRoot} ${classesProp?.labelRoot ?? ''}`,
				}}
				control={
					<Switch
						checked={value}
						onChange={toggleValue}
						value={value}
					/>
				}
				disabled={disabled}
				label={useFormLabel(label, explanation, secondary)}
				labelPlacement="start"
			/>
		</ButtonBase>;
	};