import * as React from 'react';
import { createContext, FC, useContext, useEffect, useMemo } from 'react';
import { IllegalStateException } from '../../Util/Exception/IllegalStateException';
import { AuthenticationContext } from '../authentication-provider/AuthenticationContext';
import { useClientInstanceUuid } from '../client-instance-uuid/ClientInstanceUuid';
import { WebSocketService } from '../Page/Business/WebSocketService';

interface Context
{
	webSocketService: WebSocketService | undefined
}

const ContextRef = createContext<Context | undefined>(undefined);

export const WebSocketConnectionProvider: FC =
	(
		{
			children,
		},
	) =>
	{
		const {authenticationResult} = useContext(AuthenticationContext);
		const clientInstanceUuid = useClientInstanceUuid();

		const webSocketService = useMemo(
			() => {
				if (clientInstanceUuid !== undefined)
				{
					return new WebSocketService(
						clientInstanceUuid
					);
				}
			},
			[clientInstanceUuid]
		);

		useEffect(
			() =>
			{
				if (authenticationResult !== undefined && webSocketService !== undefined)
				{
					webSocketService.constructNewWebSocket(authenticationResult);
					webSocketService.register(authenticationResult);
				}
			},
			[authenticationResult, webSocketService],
		);

		return <ContextRef.Provider
			value={useMemo(() => ({
				webSocketService,
			}), [webSocketService])}
		>
			{children}
		</ContextRef.Provider>;
	};

export function useWebSocketConnection(require?: false): WebSocketService | undefined
export function useWebSocketConnection(require: true): WebSocketService
export function useWebSocketConnection(require: boolean = false): WebSocketService | undefined
{
	const context = useContext(ContextRef);
	return useMemo(
		() => {
			if (context === undefined)
				throw new IllegalStateException(
					'Attempting to use useWebSocketConnection outside WebSocketConnectionProvider'
				);
			if (require && context.webSocketService === undefined)
				throw new IllegalStateException(
					'WebSocket connection (WebSocketService) is required to be instantiated'
				);
			return context.webSocketService;
		},
		[context, require],
	);
}