import { Accordion, AccordionDetails, AccordionSummary, Box, Collapse, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider/Divider';
import Grid from '@material-ui/core/Grid/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as React from 'react';
import { FC, useState } from 'react';
import { useTranslate } from '../../../../../../../Bridge/Localization/useTranslate';
import { CardBorderRadius } from '../../../../../../../Util/Theme';
import { InitializedCartLine } from '../model/InitializedCartLine';
import { PeerShoppingCartLines } from './lines/PeerShoppingCartLines';
import { PeerShoppingCartLinesSummary } from './lines_summary/PeerShoppingCartLinesSummary';

interface PeerShoppingCartProps
{
	lines: InitializedCartLine[];
}

export const PeerShoppingCart: FC<PeerShoppingCartProps> =
	({
		lines,
	}) =>
	{
		const translate = useTranslate();
		const [isExpanded, setExpanded] = useState(false);

		return <Grid
			item
			style={{
				flexGrow: 0,
				flexShrink: 0,
				flexBasis: 'auto',
				overflow: 'hidden',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Accordion
				expanded={isExpanded}
				onChange={(e, expanded) => setExpanded(expanded)}
				style={{
					flex: '0 0 auto',
					display: 'flex',
					flexDirection: 'column',
					overflowY: 'auto',
					borderRadius: CardBorderRadius,
					backgroundColor: '#eee8',
				}}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					id="collapsed-peer-shopping-cart-in-order-builder"
					style={{
						flex: '0 0 auto',
						backgroundColor: '#fff8',
					}}
					IconButtonProps={{
						style: {
							width: 48,
							paddingLeft: 12,
							paddingRight: 12,
							overflow: 'hidden',
							transitionTimingFunction: 'ease',
							transition: 'width 300ms, padding-left 300ms, padding-right 300ms, opacity 300ms',
						},
					}}
				>
					<Box
						sx={{
							width: '100%',
						}}
					>
						<>
							<Typography
								variant="body2"
							>
								{translate('Client-PeerShoppingCart-Title')}
							</Typography>
							<Collapse
								in={!isExpanded}
							>
								<Box
									sx={{
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										display: '-webkit-box',
										WebkitBoxOrient: 'vertical',
										...{
											WebkitLineClamp: 2,
										} as any
									}}
								>
									<Typography
										variant="body1"
										color="textSecondary"
									>
										<PeerShoppingCartLinesSummary
											lines={lines}
										/>
									</Typography>
								</Box>
							</Collapse>
						</>
					</Box>
				</AccordionSummary>
				<Divider />
				<AccordionDetails
					style={{
						paddingTop: 0,
						paddingLeft: 0,
						paddingRight: 0,
						paddingBottom: 0,
						flex: '0 1 auto',
						display: 'flex',
						flexDirection: 'column',
						overflowY: 'hidden',
						backgroundColor: '#fff8',
					}}
				>
					<PeerShoppingCartLines
						lines={lines}
					/>
				</AccordionDetails>
			</Accordion>
		</Grid>;
	};