/**
 * The client-side storage. Works as a key-value store.
 */
export abstract class Storage
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    // ------------------------ Constructor -------------------------

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    abstract get(key: string): string | undefined;

    abstract set(key: string, value?: string | undefined): void;

    // ----------------------- Private logic ------------------------
}
