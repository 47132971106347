import Decimal from 'decimal.js';
import * as React from 'react';
import { createContext, FC, useContext, useMemo } from 'react';
import { Currency } from '../../../../../Api/Other/Currency';
import { useMemoizedDecimal } from '../../../../../Util/decimal/useMemoizedDecimal';
import { AdditiveTaxGroupKey } from './AdditiveTaxGroupKey';

interface Context
{
	currency: Currency;
	// the total price of the "non-supplementary" order lines
	base: Decimal;
	discount: Decimal;
	productFees: Decimal;
	deliveryFee: Decimal;
	serviceFee: Decimal;
	tip: Decimal;
	rawAdditiveTaxPerTaxGroupId: Map<AdditiveTaxGroupKey, Decimal>;
	total: Decimal;
}

const ContextRef = createContext<Context>(undefined!);

interface PaymentPriceContextProviderProps
{
	currencyCode: string;
	baseAmount: Decimal;
	discountAmount: Decimal | undefined;
	productFeesAmount: Decimal | undefined;
	deliveryFeeAmount: Decimal | undefined;
	serviceFeeAmount: Decimal | undefined;
	tipAmount: Decimal | undefined;
	rawAdditiveTaxAmountPerTaxGroupId: Map<AdditiveTaxGroupKey, Decimal>;
}

export const PaymentPriceContextProvider: FC<PaymentPriceContextProviderProps> =
	({
		currencyCode,
		baseAmount,
		discountAmount,
		productFeesAmount,
		deliveryFeeAmount,
		serviceFeeAmount,
		tipAmount,
		rawAdditiveTaxAmountPerTaxGroupId,
		children,
	}) =>
	{
		const currency = useMemo(
			() => new Currency(currencyCode),
			[currencyCode],
		);

		const base = useMemoizedDecimal(
			baseAmount.toDecimalPlaces(currency.decimalPlaces),
		);

		const discount = useMemoizedDecimal(
			discountAmount?.toDecimalPlaces(currency.decimalPlaces) ?? new Decimal(0),
		);

		const productFees = useMemoizedDecimal(
			productFeesAmount?.toDecimalPlaces(currency.decimalPlaces) ?? new Decimal(0),
		);

		const deliveryFee = useMemoizedDecimal(
			deliveryFeeAmount?.toDecimalPlaces(currency.decimalPlaces) ?? new Decimal(0),
		);

		const serviceFee = useMemoizedDecimal(
			serviceFeeAmount?.toDecimalPlaces(currency.decimalPlaces) ?? new Decimal(0),
		);

		const tip = useMemoizedDecimal(
			tipAmount?.toDecimalPlaces(currency.decimalPlaces) ?? new Decimal(0),
		);

		const rawAdditiveTaxPerTaxGroupId = useMemo(() =>
		{
			return rawAdditiveTaxAmountPerTaxGroupId
		}, [rawAdditiveTaxAmountPerTaxGroupId]);

		const total = useMemo(() =>
		{
			return Array
				.from(rawAdditiveTaxPerTaxGroupId.values())
				.reduce(
					(subTotal, taxAmount) =>
						subTotal.add(taxAmount.toDecimalPlaces(currency.decimalPlaces)),
					base
						.sub(discount)
						.add(productFees)
						.add(deliveryFee)
						.add(serviceFee)
						.add(tip),
				);
		}, [base, currency.decimalPlaces, deliveryFee, discount, productFees, rawAdditiveTaxPerTaxGroupId, serviceFee, tip]);

		const memoizedTotal = useMemoizedDecimal(
			total
		);

		const contextValue = useMemo(
			() =>
				({
					currency,
					base,
					discount,
					productFees,
					deliveryFee,
					serviceFee,
					tip,
					rawAdditiveTaxPerTaxGroupId,
					total: memoizedTotal,
				}),
			[base, currency, deliveryFee, discount, memoizedTotal, productFees, rawAdditiveTaxPerTaxGroupId, serviceFee, tip],
		);

		return <ContextRef.Provider
			value={contextValue}
		>
			{children}
		</ContextRef.Provider>;
	};

export function usePaymentPriceContext(): Context
{
	return useContext(ContextRef);
}
