import { makeStyles } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useMemo } from 'react';
import { ExternalBill } from '../../../../../../../Api/ExternalBill/ExternalBill';
import { useTranslate } from '../../../../../../../Bridge/Localization/useTranslate';
import { useAndRequirePlace } from '../../../../../../../Service/CurrentPlace/useAndRequirePlace';
import { isNumeric } from '../../../../../../../Util/isNumeric';
import { useFormFocus } from '../../../../../../UI/focus/context/FormFocusContext';
import { ExternalBillPanel } from './panel/ExternalBillPanel';

const useStyles = makeStyles({
	externalOrdersPanel: {
		overflow: 'hidden',
		width: '100%',
	},
});

interface ExternalBillSettlerSegmentProps
{
	externalBill: ExternalBill;
}

export const ExternalBillSettlerSegment: FC<ExternalBillSettlerSegmentProps> =
	({
		externalBill,
	 }) =>
	{
		const [isFocused, setIsFocused] = useFormFocus('external-orders');
		const classes = useStyles();
		const translate = useTranslate();
		const place = useAndRequirePlace();
		const onExpansion = useCallback(() => setIsFocused(true), [setIsFocused]);
		const placeName = useObserver(() => place.name);
		const title = useMemo(() =>
		{
			const nameLabel = isNumeric(placeName)
				? placeName
				: `"${placeName}"`;

			return translate('Bill-Open-Singular-On-Place-X', nameLabel);
		}, [placeName, translate]);

		return <ExternalBillPanel
			className={classes.externalOrdersPanel}
			expanded={isFocused}
			onExpansion={onExpansion}
			externalBill={externalBill}
			collapsible={false}
			title={title}
		/>;
	};
