import { AppBar, FormControl, FormControlLabel, Slide, Switch, TextField, Toolbar, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import { ChangeEvent, FC, useCallback, useContext, useState } from 'react';
import { UIColors } from '../../../../Constants/UIColors';
import { EntranceContextRef } from '../../Entrance/EntranceContext';

const useStyles = makeStyles(
	() =>
		({
			appBar:
				{
					backgroundColor: UIColors.greyLight,
					boxShadow: 'none',
				},
			textSwitch:
				{
				},
			toolbar:
				{
					justifyContent: 'space-between',
				},
		}));

interface ManagerPlacesToolbarProps
{
	onSearch: (searchTerm: string) => void;
	onShowDetails: (showDetails: boolean) => void;
}

export const ManagerPlacesToolbar: FC<ManagerPlacesToolbarProps> =
	({
		 onSearch,
		 onShowDetails,
	 }) =>
	{
		const {
			localizer,
		} = useContext(EntranceContextRef);

		const classes = useStyles();

		const [showDetails, setShowDetails] = useState();

		const onShowDetailsChange = useCallback(
			(show: boolean) =>
			{
				setShowDetails(show);
				onShowDetails(show);
			},
			[onShowDetails]);

		return <Slide
			in
			direction="down"
		>
			<AppBar
				classes={{
					root: classes.appBar,
				}}
				position="sticky"
			>
				<Toolbar
					classes={{
						root: classes.toolbar,
					}}
				>
					<FormControl>
						<FormControlLabel
							label={
								<Typography
									classes={{
										root: classes.textSwitch,
									}}
									color="textPrimary"
								>
									{localizer.translate('Generic-Details')}
								</Typography>
							}
							control={
								<Switch
									color="primary"
									onChange={
										(event: ChangeEvent<HTMLInputElement>, checked: boolean) =>
											onShowDetailsChange(checked)
									}
									value={showDetails}
								/>
							}
						/>
					</FormControl>
					<TextField
						placeholder={localizer.translate('Place-Search')}
						onChange={
							(event: React.ChangeEvent<HTMLInputElement>) =>
								onSearch(event.target.value)
						}
					/>
				</Toolbar>
			</AppBar>
		</Slide>;
	};
