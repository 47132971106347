import { useMemo } from 'react';
import { PaymentMethodDescriptor } from '../../../../../../Api/Payment/PaymentMethodDescriptor';

export function useMappedPaymentMethods(paymentMethods?: PaymentMethodDescriptor[]): Map<string, PaymentMethodDescriptor> | undefined
{
	return useMemo(() =>
	{
		return paymentMethods
			?.reduce((result, current) =>
			{
				result.set(current.id, current);

				return result;
			}, new Map<string, PaymentMethodDescriptor>());
	}, [paymentMethods]);
}