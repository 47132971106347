import { toJS } from 'mobx';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { ExternalOrder } from '../../../../../../../Api/ExternalOrders/ExternalOrder';
import { ExternalOrdersOnPlaceUserRequest } from '../../../../../../../Api/ExternalOrders/ExternalOrdersOnPlaceUserRequest';
import { ExternalOrdersOnPlaceUserResponse, ExternalOrdersOnPlaceUserResponseProfile } from '../../../../../../../Api/ExternalOrders/ExternalOrdersOnPlaceUserResponse';
import { useRootContext } from '../../../../../../../RootContext';
import { BusinessContextRef } from '../../../../BusinessContext';
import { useWebSocketQuery } from '../../../../useWebSocketQuery';
import { useWebSocketService } from '../../../../useWebSocketService';
import { BusinessQuery } from '../../../../WebSocketService';

export type ExternalOrderQueryResult = ExternalOrder[] | 'timeout' | 'error';

export function useExternalOrderQuery(): ExternalOrderQueryResult | undefined
{
	const {instanceUuid} = useRootContext(true);
	const webSocketService = useWebSocketService(true);
	const {currentPlaceService} = useContext(BusinessContextRef);

	const [orders, setOrders] = useState<ExternalOrder[] | 'timeout' | 'error'>();

	const request = useMemo(
		() => new ExternalOrdersOnPlaceUserRequest(
			uuid(),
			instanceUuid,
			currentPlaceService.place!.id,
		),
		[currentPlaceService.place, instanceUuid],
	);

	const onResponse = useCallback(
		(response: ExternalOrdersOnPlaceUserResponse) =>
		{
			const deserializedResponse = toJS(ExternalOrdersOnPlaceUserResponseProfile.deserializeSingular(response))!;

			if (deserializedResponse.requestUuid === request.uuid)
			{
				if (deserializedResponse.success)
				{
					setOrders(deserializedResponse.externalOrders ?? []);
				}
				else
				{
					if (deserializedResponse.failureReason === 'TIMEOUT')
						setOrders('timeout')
					else
						setOrders('error')
				}
			}
		},
		[request.uuid],
	);

	useEffect(
		() => webSocketService.sendUserRequest(request),
		[currentPlaceService.place, instanceUuid, request, webSocketService],
	);

	useWebSocketQuery<ExternalOrdersOnPlaceUserResponse>(
		new BusinessQuery(currentPlaceService.business!.id),
		'ExternalOrdersOnPlaceUserResponse',
		onResponse,
	);

	return orders;
}