import { addMilliseconds, addSeconds } from 'date-fns';

/**
 * A time-based amount of time, such as '34.5 seconds'.
 * <p>
 * This type models a quantity or amount of time in terms of seconds and nanoseconds.
 * It can be accessed using other duration-based units, such as minutes and hours.
 * In addition, the days unit can be used and is treated as
 * exactly equal to 24 hours, thus ignoring daylight savings effects.
 * See {@link Period} for the date-based equivalent to this class.
 * <p>
 * The duration is measured in "seconds", but these are not necessarily identical to
 * the scientific "SI second" definition based on atomic clocks.
 * This difference only impacts durations measured near a leap-second and should not affect
 * most applications.
 * <p>
 */
export interface Duration
{
	seconds: number;
	nanos: number;
}

const HOURS_PER_DAY = 24;
const MINUTES_PER_HOUR = 60;
const SECONDS_PER_MINUTE = 60;
const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * MINUTES_PER_HOUR;
const SECONDS_PER_DAY = SECONDS_PER_HOUR * HOURS_PER_DAY;
const MILLIS_PER_SECOND = 1000;
const NANOS_PER_MILLI = 1000_000;
const NANOS_PER_SECOND =  1000_000_000;

const PATTERN = /^([-+]?)P(?:([-+]?[0-9]+)D)?(T(?:([-+]?[0-9]+)H)?(?:([-+]?[0-9]+)M)?(?:([-+]?[0-9]+)(?:[.,]([0-9]{0,9}))?S)?)?$/;

/**
 * Obtains a {@code Duration} from a text string such as {@code PnDTnHnMn.nS}.
 * <p>
 * This will parse a textual representation of a duration, including the
 * string produced by {@code toString()}. The formats accepted are based
 * on the ISO-8601 duration format {@code PnDTnHnMn.nS} with days
 * considered to be exactly 24 hours.
 * <p>
 * The string starts with an optional sign, denoted by the ASCII negative
 * or positive symbol. If negative, the whole period is negated.
 * The ASCII letter "P" is next in upper or lower case.
 * There are then four sections, each consisting of a number and a suffix.
 * The sections have suffixes in ASCII of "D", "H", "M" and "S" for
 * days, hours, minutes and seconds, accepted in upper or lower case.
 * The suffixes must occur in order. The ASCII letter "T" must occur before
 * the first occurrence, if any, of an hour, minute or second section.
 * At least one of the four sections must be present, and if "T" is present
 * there must be at least one section after the "T".
 * The number part of each section must consist of one or more ASCII digits.
 * The number may be prefixed by the ASCII negative or positive symbol.
 * The number of days, hours and minutes must parse to a {@code long}.
 * The number of seconds must parse to a {@code long} with optional fraction.
 * The decimal point may be either a dot or a comma.
 * The fractional part may have from zero to 9 digits.
 * <p>
 * The leading plus/minus sign, and negative values for other units are
 * not part of the ISO-8601 standard.
 * <p>
 * Examples:
 * <pre>
 *    "PT20.345S" -- parses as "20.345 seconds"
 *    "PT15M"     -- parses as "15 minutes" (where a minute is 60 seconds)
 *    "PT10H"     -- parses as "10 hours" (where an hour is 3600 seconds)
 *    "P2D"       -- parses as "2 days" (where a day is 24 hours or 86400 seconds)
 *    "P2DT3H4M"  -- parses as "2 days, 3 hours and 4 minutes"
 *    "PT-6H3M"    -- parses as "-6 hours and +3 minutes"
 *    "-PT6H3M"    -- parses as "-6 hours and -3 minutes"
 *    "-PT-6H+3M"  -- parses as "+6 hours and -3 minutes"
 * </pre>
 *
 * @param text  the text to parse
 * @return the parsed duration
 * @throws if the text cannot be parsed to a duration
 */
export function parse(text: string): Duration
{
	const match = text.match(PATTERN);

	if (match !== null)
	{
		// check for letter T but no time sections
		if (match[3] !== 'T')
		{
			const negate = match[1] === '-';
			
			const day = match[2] ?? '';
			const hour = match[4] ?? '';
			const minute = match[5] ?? '';
			const second = match[6] ?? '';
			const fraction = match[7] ?? '';
			
			if (day.length > 0 || hour.length > 0 || minute.length > 0 || second.length > 0)
			{
				const daysAsSecs = parseNumber(day, SECONDS_PER_DAY, 'days');
				const hoursAsSecs = parseNumber(hour, SECONDS_PER_HOUR, 'hours');
				const minsAsSecs = parseNumber(minute, SECONDS_PER_MINUTE, 'minutes');
				const secs = parseNumber(second, 1, 'seconds');
				const negativeSecs = second.length > 0 && second.charAt(0) === '-';
				const nanos = parseFraction(fraction, negativeSecs ? -1 : 1);

				const seconds = daysAsSecs + hoursAsSecs + minsAsSecs + secs;
				if (negate)
					return ofSeconds(-seconds, -nanos);
				else
					return ofSeconds(seconds, nanos);
			}
		}
	}
	throw new Error(`DateTimeParseException: Text ${text} cannot be parsed to a Duration`);
}

/**
 * Obtains a {@code Duration} representing a number of standard 24 hour days.
 * <p>
 * The seconds are calculated based on the standard definition of a day,
 * where each day is 86400 seconds which implies a 24 hour day.
 * The nanosecond in second field is set to zero.
 *
 * @param days  the number of days, positive or negative
 * @return a {@code Duration}
 */
export function ofDays(days: number): Duration
{
	return ofSeconds(days * SECONDS_PER_DAY);
}

/**
 * Obtains a {@code Duration} representing a number of standard hours.
 * <p>
 * The seconds are calculated based on the standard definition of an hour,
 * where each hour is 3600 seconds.
 * The nanosecond in second field is set to zero.
 *
 * @param hours  the number of hours, positive or negative
 * @return a {@code Duration}
 */
export function ofHours(hours: number): Duration
{
	return ofSeconds(hours * SECONDS_PER_HOUR);
}

/**
 * Obtains a {@code Duration} representing a number of standard minutes.
 * <p>
 * The seconds are calculated based on the standard definition of a minute,
 * where each minute is 60 seconds.
 * The nanosecond in second field is set to zero.
 *
 * @param minutes  the number of minutes, positive or negative
 * @return a {@code Duration}
 */
export function ofMinutes(minutes: number): Duration
{
	return ofSeconds(minutes * SECONDS_PER_MINUTE);
}

/**
 * Obtains a {@code Duration} representing a number of seconds and an
 * adjustment in nanoseconds.
 * <p>
 * This method allows an arbitrary number of nanoseconds to be passed in.
 * The factory will alter the values of the second and nanosecond in order
 * to ensure that the stored nanosecond is in the range 0 to 999,999,999.
 * For example, the following will result in exactly the same duration:
 * <pre>
 *  Duration.ofSeconds(3, 1);
 *  Duration.ofSeconds(4, -999_999_999);
 *  Duration.ofSeconds(2, 1000_000_001);
 * </pre>
 *
 * @param seconds  the number of seconds, positive or negative
 * @param nanoAdjustment  the nanosecond adjustment to the number of seconds, positive or negative
 * @return a {@code Duration}
 */
export function ofSeconds(seconds: number, nanoAdjustment: number = 0): Duration
{
	let secs = seconds + Math.floor(nanoAdjustment / NANOS_PER_SECOND);
	let nos = Math.floor(nanoAdjustment % NANOS_PER_SECOND);

	return {
		// get rid of -0
		seconds: secs || 0,
		nanos: nos || 0,
	}
}

/**
 * Obtains a {@code Duration} representing a number of milliseconds.
 * <p>
 * The seconds and nanoseconds are extracted from the specified milliseconds.
 *
 * @param millis  the number of milliseconds, positive or negative
 * @return a {@code Duration}
 */
export function ofMillis(millis: number): Duration
{
	let secs = Math.trunc(millis / MILLIS_PER_SECOND);
	let mos = millis % MILLIS_PER_SECOND;
	if (mos < 0)
	{
		mos += MILLIS_PER_SECOND;
		secs--;
	}

	return ofSeconds(secs, mos * NANOS_PER_MILLI);
}

/**
 * Obtains a {@code Duration} representing a number of nanoseconds.
 * <p>
 * The seconds and nanoseconds are extracted from the specified nanoseconds.
 *
 * @param nanos  the number of nanoseconds, positive or negative
 * @return a {@code Duration}
 */
export function ofNanos(nanos: number): Duration
{
	let secs = Math.trunc(nanos / NANOS_PER_SECOND);
	let nos = (nanos % NANOS_PER_SECOND);
	if (nos < 0)
	{
		nos += NANOS_PER_SECOND;
		secs--;
	}

	return ofSeconds(secs, nos);
}

/**
 * Adds duration to the specified date.
 * <p>
 * This returns a date of the same observable type as the input
 * with duration added.
 * <p>
 * The calculation will add the seconds, then milli seconds.
 * The sub millisecond part is disregarded, since js Date do not support it.
 * Only non-zero amounts will be added.
 * <p>
 * Duration is immutable and unaffected by this method call.
 *
 * @param duration the duration that should be added to,
 * @param date  the date to adjust
 * @return a date with the adjustment made
 */
export function addTo(duration: Duration, date: Date): Date
{
	let result =  date;
	if (duration.seconds !== 0)
		result = addSeconds(result, duration.seconds);
	if (duration.nanos !== 0)
		result = addMilliseconds(result, Math.floor(duration.nanos / NANOS_PER_MILLI));

	return result;
}

/**
 * Subtracts duration from the specified date.
 * <p>
 * This returns a date of the same observable type as the input
 * with duration subtracted.
 * <p>
 * The calculation will subtract the seconds, then milli seconds.
 * The sub millisecond part is disregarded, since js Date do not support it.
 * Only non-zero amounts will be added.
 * <p>
 * Duration is immutable and unaffected by this method call.
 *
 * @param duration the duration that should be added to,
 * @param date  the date to adjust
 * @return a date with the adjustment made
 */
export function subtractFrom(duration: Duration, date: Date): Date
{
	let result =  date;
	if (duration.seconds !== 0)
		result = addSeconds(result, -duration.seconds);
	if (duration.nanos !== 0)
		result = addMilliseconds(result, -Math.floor(duration.nanos / NANOS_PER_MILLI));

	return result;
}

/**
 * Gets the number of hours in this duration.
 * <p>
 * The length of the duration is stored using two fields - seconds and nanoseconds.
 * The nanoseconds part is a value from 0 to 999,999,999 that is an adjustment to
 * the length in seconds.
 * The total duration is defined by calling {@link #getSeconds()} and {@link #getNanosFraction()}.
 * <p>
 * A {@code Duration} represents a directed distance between two points on the time-line.
 * A negative duration is expressed by the negative sign of the seconds part.
 * A duration of -1 nanosecond is stored as -1 seconds plus 999,999,999 nanoseconds.
 *
 * @return the hours of the length of the duration
 */
export function getHoursFraction(duration: Duration): number
{
	let effectiveTotalSecs = duration.seconds;
	if (duration.seconds < 0 && duration.nanos > 0)
		effectiveTotalSecs++;

	return Math.trunc(effectiveTotalSecs / SECONDS_PER_HOUR);
}

/**
 * Gets the number of minutes within the hour in this duration.
 * <p>
 * The length of the duration is stored using two fields - seconds and nanoseconds.
 * The nanoseconds part is a value from 0 to 999,999,999 that is an adjustment to
 * the length in seconds.
 * The total duration is defined by calling {@link #getSeconds()} and {@link #getNanosFraction()}.
 * <p>
 * A {@code Duration} represents a directed distance between two points on the time-line.
 * A negative duration is expressed by the negative sign of the seconds part.
 * A duration of -1 nanosecond is stored as -1 seconds plus 999,999,999 nanoseconds.
 *
 * @return the minutes within the hour part of the length of the duration, from -59 to 59
 */
export function getMinutesFraction(duration: Duration): number
{
	let effectiveTotalSecs = duration.seconds;
	if (duration.seconds < 0 && duration.nanos > 0)
		effectiveTotalSecs++;

	return Math.trunc((effectiveTotalSecs % SECONDS_PER_HOUR) / SECONDS_PER_MINUTE);
}

/**
 * Gets the number of seconds within the minute in this duration.
 * <p>
 * The length of the duration is stored using two fields - seconds and nanoseconds.
 * The nanoseconds part is a value from 0 to 999,999,999 that is an adjustment to
 * the length in seconds.
 * The total duration is defined by calling {@link #getSeconds()} and {@link #getNanosFraction()}.
 * <p>
 * A {@code Duration} represents a directed distance between two points on the time-line.
 * A negative duration is expressed by the negative sign of the seconds part.
 * A duration of -1 nanosecond is stored as -1 seconds plus 999,999,999 nanoseconds.
 *
 * @return the seconds within the minute part of the length of the duration, from 0 to 59
 */
export function getSecondsFraction(duration: Duration): number
{
	let effectiveTotalSecs = duration.seconds;
	if (duration.seconds < 0 && duration.nanos > 0)
		effectiveTotalSecs++;

	// get rid of -0
	return (effectiveTotalSecs % SECONDS_PER_MINUTE) || 0;
}

/**
 * Gets the number of seconds in this duration.
 * <p>
 * The length of the duration is stored using two fields - seconds and nanoseconds.
 * The nanoseconds part is a value from 0 to 999,999,999 that is an adjustment to
 * the length in seconds.
 * The total duration is defined by calling this method and {@link #getNanosFraction()}.
 * <p>
 * A {@code Duration} represents a directed distance between two points on the time-line.
 * A negative duration is expressed by the negative sign of the seconds part.
 * A duration of -1 nanosecond is stored as -1 seconds plus 999,999,999 nanoseconds.
 *
 * @return the whole seconds part of the length of the duration, positive or negative
 */
export function getSeconds(duration: Duration): number
{
	return duration.seconds;
}

/**
 * Gets the number of nanoseconds within the second in this duration.
 * <p>
 * The length of the duration is stored using two fields - seconds and nanoseconds.
 * The nanoseconds part is a value from 0 to 999,999,999 that is an adjustment to
 * the length in seconds.
 * The total duration is defined by calling this method and {@link #getSeconds()}.
 * <p>
 * A {@code Duration} represents a directed distance between two points on the time-line.
 * A negative duration is expressed by the negative sign of the seconds part.
 * A duration of -1 nanosecond is stored as -1 seconds plus 999,999,999 nanoseconds.
 *
 * @return the nanoseconds within the second part of the length of the duration, from 0 to 999,999,999
 */
export function getNanosFraction(duration: Duration): number
{
	return duration.nanos;
}

/**
 * A string representation of this duration using ISO-8601 seconds
 * based representation, such as {@code PT8H6M12.345S}.
 * <p>
 * The format of the returned string will be {@code PTnHnMnS}, where n is
 * the relevant hours, minutes or seconds part of the duration.
 * Any fractional seconds are placed after a decimal point in the seconds section.
 * If a section has a zero value, it is omitted.
 * The hours, minutes and seconds will all have the same sign.
 * <p>
 * Examples:
 * <pre>
 *    "20.345 seconds"                 -- "PT20.345S
 *    "15 minutes" (15 * 60 seconds)   -- "PT15M"
 *    "10 hours" (10 * 3600 seconds)   -- "PT10H"
 *    "2 days" (2 * 86400 seconds)     -- "PT48H"
 * </pre>
 * Note that multiples of 24 hours are not output as days to avoid confusion
 * with {@code Period}.
 *
 * @return an ISO-8601 representation of this duration
 */
export function format(duration: Duration): string
{
	let effectiveTotalSecs = duration.seconds;
	if (duration.seconds < 0 && duration.nanos > 0)
		effectiveTotalSecs++;

	const hours = Math.trunc(effectiveTotalSecs / SECONDS_PER_HOUR);
	const minutes = Math.trunc((effectiveTotalSecs % SECONDS_PER_HOUR) / SECONDS_PER_MINUTE);
	const secs = effectiveTotalSecs % SECONDS_PER_MINUTE;

	let buf = 'PT';
	if (hours !== 0)
		buf += `${hours}H`;
	if (minutes !== 0)
		buf += `${minutes}M`;
	if (secs === 0 && duration.nanos === 0 && buf.length > 2)
		return buf;
	if (duration.seconds < 0 && duration.nanos > 0)
	{
		if (secs === 0)
			buf += '-0';
		else
			buf += secs;
	}
	else
	{
		buf += secs;
	}
	if (duration.nanos > 0)
	{
		let fractionBuf;
		if (duration.seconds < 0)
			fractionBuf = 2 * NANOS_PER_SECOND - duration.nanos;
		else
			fractionBuf = duration.nanos + NANOS_PER_SECOND;

		fractionBuf = fractionBuf.toString().replace(/0+$/,'');

		buf += `.${fractionBuf.substring(1)}`
	}

	return buf + 'S';
}

function parseNumber(text: string, multiplier: number, errorText: string): number
{
	// regex limits to [-+]?[0-9]+
	if (text.length === 0)
		return 0;

	try
	{
		const val = parseInt(text, 10);
		return val * multiplier;
	}
	catch (e)
	{
		throw new Error(`DateTimeParseException: Text cannot be parsed to a Duration: ${errorText}`);
	}
}

function parseFraction(text: string, negate: number): number
{
	// regex limits to [0-9]{0,9}
	if (text.length === 0)
		return 0;

	try {
		let fraction = parseInt(text, 10);

		// for number strings smaller than 9 digits, interpret as if there
		// were trailing zeros
		for (let i = text.length; i < 9; i++)
		{
			fraction *= 10;
		}
		return fraction * negate;
	} catch (e)
	{
		throw new Error('DateTimeParseException: Text cannot be parsed to a Duration: fraction');
	}
}