import * as React from 'react';
import { FC, useCallback, useMemo, useState } from 'react';
import { isWidthDown, Tab, Tabs, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { NamedOrderList } from '../NamedOrderList';
import { OrderListGrid } from '../order-list-grid/OrderListGrid';
import { useScreenWidth } from '../../../../../../Util/Hooks/useScreenWidth';

const useStyles = makeStyles((theme: Theme) => ({
	tab: {
		color: theme.palette.common.white,
	},
	tabs: {
		backgroundColor: theme.palette.common.black,
	},
	tabScrollButtons: {
		color: theme.palette.common.white,
	},
}));

interface OrderContainerMultipleProps
{
	orderLists: NamedOrderList[];
}

export const OrderContainerMultiple: FC<OrderContainerMultipleProps> =
	(
		{
			orderLists,
		},
	) =>
	{
		const classes = useStyles();

		const [activeTabIdx, setActiveTabIdx] = useState(0);
		const screenWidth = useScreenWidth();

		const renderTab = useCallback(
			(idx: number, lists: NamedOrderList[]) => <Tab
				classes={{
					root: classes.tab,
				}}
				key={idx}
				label={lists.map(list => list.name).join(' / ')}
				value={idx}
			/>,
			[classes],
		);

		const listsPerTab = useMemo(
			() =>
				isWidthDown('md', screenWidth)
					? 3
					: isWidthDown('lg', screenWidth) ? 4 : 6,
			[screenWidth],
		);

		const tabLists = useMemo(
			() =>
				orderLists.reduce(
					(rows: NamedOrderList[][], list, idx) =>
						(idx % listsPerTab === 0
							? rows.push([list])
							: rows[rows.length - 1].push(list)) ? rows : undefined,
					[],
				),
			[listsPerTab, orderLists],
		);


		const showAll = useMemo(() => tabLists?.length === 1 || orderLists.length < listsPerTab, [listsPerTab, orderLists.length, tabLists]);

		return <>
			{
				!showAll &&
				<Tabs
					classes={{
						root: classes.tabs,
						scrollButtons: classes.tabScrollButtons,
					}}
					onChange={
						(event, value) =>
							setActiveTabIdx(value)
					}
					scrollButtons="on"
					value={activeTabIdx}
					variant="fullWidth"
				>
					{
						tabLists?.map((lists, idx) => renderTab(idx, lists))
					}
				</Tabs>
			}
			<OrderListGrid
				orderLists={
					showAll
						? orderLists
						: tabLists?.[activeTabIdx] ?? []
				}
			/>
		</>;
	};