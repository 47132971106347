import { createContext } from 'react';
import { RoutingStateController } from './RoutingStateController';

export const RoutingStateContext =
	createContext<RoutingStateController>({
		cache: new Map(),
		loadStateByRoutingId:
			() =>
				Promise.resolve({
					isLive: false
				}),
	});