import { Typography } from '@material-ui/core';
import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from '../../../../../../Bridge/Localization/useTranslation';

export const OrderEventsNone: FC =
	() =>
	{
		return <Typography color="textSecondary">
			{useTranslation('Order-Events-None')}
		</Typography>;
	};