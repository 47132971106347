import { useMemo } from 'react';
import { PaymentIssuer } from '../../../../../../Api/Payment/PaymentIssuer';
import { usePaymentMethodContext } from '../../../method/context/PaymentMethodContext';

export function usePaymentIssuerValidation(selectedPaymentIssuer?: PaymentIssuer): string[]
{
	const {selectedPaymentMethod} = usePaymentMethodContext();

	return useMemo(() =>
	{
		if (selectedPaymentMethod !== undefined)
		{
			if (selectedPaymentMethod.isIssuerRequired)
			{
				if (selectedPaymentIssuer === undefined)
					return ['You must select a payment issuer'];
			}
		}

		return [];
	}, [selectedPaymentIssuer, selectedPaymentMethod]);
}