import { useMemo } from 'react';
import { fetchAny } from '../../../../../Util/Api';
import { AsyncResult, useAsyncResult } from '../../../../../Util/async/useAsyncResult';
import { TimeSlot, TimeSlotDto, toTimeSlot } from '../TimeSlot';

export function useOrderingTimeSlots(day: string | undefined): AsyncResult<TimeSlot[]>
{
	const timeZone = useMemo(
		() =>
			Intl
				.DateTimeFormat()
				.resolvedOptions()
				.timeZone,
		[],
	);

	return useAsyncResult(
		async () =>
		{
			if (day !== undefined && day.trim().length > 0)
			{
				const response = await fetchAny<TimeSlotDto[]>(
					'client/timeSlots',
					{
						day,
						timeZone,
					},
				);
				return response
					.map(toTimeSlot);
			}
		},
		[day, timeZone],
	);
}