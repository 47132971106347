import { Hidden, makeStyles } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { useIsKioskMode } from '../../../Service/KioskService/Api/useIsKioskMode';
import { CollapsedHistory } from '../../Page/Business/Service/CollapsedHistory/CollapsedHistory';
import { CollapsedShoppingCart } from '../../Page/Business/Service/CollapsedShoppingCart/CollapsedShoppingCart';
import { useAndRequireSessionStatusBarStore } from './SessionStatusBarStoreProvider';
import { SessionStatusBarWrapper } from './SessionStatusBarWrapper';
import { useSessionStatusBarWindowResizeListener } from './useSessionStatusBarWindowResizeListener';

const useStyles = makeStyles(({
    root: {
        bottom: 0,
        left: 0,
        right: 0,
        position: 'fixed',
        zIndex: 1,
        boxShadow: 'rgb(0 0 0 / 35%) 0px 5px 15px',
    },
    StatusPart: {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'row',
    },
    VerticalDivider: {
        flex: '0 0 auto',
        width: 1,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 1,
        borderRightWidth: 0,
        borderLeftStyle: 'solid',
        marginTop: 10,
        marginBottom: 10,
    },
}));


export const SessionStatusBar: FC =
    () =>
    {
        const store = useAndRequireSessionStatusBarStore();
        
        useSessionStatusBarWindowResizeListener(store);

        const isKioskMode = useIsKioskMode();
        const doShowCollapsedHistory = useObserver(() => store.doShowCollapsedHistory);
        const doShowCollapsedShoppingCart = useObserver(() => store.doShowCollapsedShoppingCart);

        const content = useMemo(
            () => {
                if (doShowCollapsedHistory)
                    return <Inner/>;
                else if (doShowCollapsedShoppingCart)
                    return <Hidden
                        mdUp={!isKioskMode}
                    >
                        <Inner/>
                    </Hidden>;
                else
                    return null;
            },
            [doShowCollapsedHistory, doShowCollapsedShoppingCart, isKioskMode]
        );
        const classes = useStyles();
        return <div className={classes.root}>
            {content}
        </div>
    };

const Inner: FC =
    () =>
    {
        const store = useAndRequireSessionStatusBarStore();

        const classes = useStyles();

        const isKioskMode = useIsKioskMode();
        const doShowCollapsedHistory = useObserver(() => store.doShowCollapsedHistory);
        const doShowCollapsedShoppingCart = useObserver(() => store.doShowCollapsedShoppingCart);

        return <SessionStatusBarWrapper>
            {
                (doShowCollapsedHistory || doShowCollapsedShoppingCart) &&
                <div className={classes.StatusPart}>
                    {
                        doShowCollapsedHistory &&
                        <CollapsedHistory
                            compact={doShowCollapsedShoppingCart}
                        />
                    }
                    {
                        doShowCollapsedHistory && doShowCollapsedShoppingCart &&
                        <Hidden
                            mdUp={!isKioskMode}
                        >
                            <div
                                className={classes.VerticalDivider}
                                style={{
                                    borderLeftColor: store.brandingService.tintContrastTextColor.withAlpha(0.8).css,
                                }}
                            />
                        </Hidden>
                    }
                    {
                        doShowCollapsedShoppingCart &&
                        <Hidden
                            mdUp={!isKioskMode}
                        >
                            <CollapsedShoppingCart />
                        </Hidden>
                    }
                </div>
            }
        </SessionStatusBarWrapper>;
    }
