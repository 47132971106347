import { QrCodeScan } from '@intentic/qr-reader-web';
import { makeAutoObservable } from 'mobx';
import { mobileModel, mobileVendor } from 'react-device-detect';
import { Bridge } from '../../../Bridge/Bridge';
import { AppType } from '../../../Constants/AppType';
import { BrandingService } from '../../../Service/BrandingInformation/BrandingService';
import { DiagnosticsService } from '../../../Service/Diagnostics/DiagnosticsService';
import { ScannerDiagnostics } from '../../../Service/Diagnostics/Scanner/ScannerDiagnostics';
import { PlaceCodeScannerStore } from '../../UI/PlaceCodeScanner/PlaceCodeScannerStore';
import { HashNotMatchedException } from '../Entrance/HashNotMatchedException';
import { QrCodeValidity } from './QrCodeValidity';


export class ScannerStore<T>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    readonly bridge: Bridge;
    readonly brandingService: BrandingService;
    private readonly onScan: (hash?: string) => Promise<T | undefined>;
    readonly onFinish: (result: T) => Promise<T>;
    readonly diagnosticsService: DiagnosticsService<ScannerDiagnostics>;
    qrCodeValidity: QrCodeValidity;
    isReporting: boolean;
    placeCodeScannerLoaded: boolean;
    startTimestamp: number;
    lastReportedCode: string;
    /**
     * To limit the amount of traffic to the server, only log scan attempts for a part of all users.
     * Note that we want to log either none or all of a single User's scan attempts because we want
     * to know retried scan attempts.
     */
    shouldLogScanAttempt: boolean;

    // ------------------------ Constructor -------------------------

    constructor(
        bridge: Bridge,
        brandingService: BrandingService,
        onScan: (hash?: string) => Promise<T | undefined>,
        onFinish: (result: T) => Promise<T>,
    )
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });

        this.bridge = bridge;
        this.brandingService = brandingService;
        this.onScan = onScan;
        this.onFinish = onFinish;

        this.diagnosticsService = new DiagnosticsService<ScannerDiagnostics>('/log/scan');

        this.shouldLogScanAttempt = false;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    get placeCodeScannerStore(): PlaceCodeScannerStore<T>
    {
        return new PlaceCodeScannerStore<T>(
            this.bridge.localizer,
            this.bridge.client,
            (hash, scan) => {
                return this.onScan(hash)
                    .then(result => {
                        this.setQrCodeValidity('valid');
                        this.reportScan(true, scan, hash);
                        return Promise.resolve(result);
                    })
                    .catch(error => {
                        const message = error instanceof HashNotMatchedException
                            ?
                            'Invalid place code'
                            :
                            `${error.name}: ${error.message}`;
                        this.reportScan(false, scan, hash, message);
                        return Promise.reject(error);
                    });
            },
            this.onFinish,
            () =>
            {
                this.setPlaceCodeScannerLoaded(true);
            },
            (hash, scan) =>
            {
                this.reportScan(false, scan, hash, 'Invalid place code');
                this.setQrCodeValidity('invalid');
            },
            (url, scan) =>
            {
                this.reportScan(false, scan, undefined, 'Unknown place code');
                this.setQrCodeValidity('unknown');
            },
            undefined,
            (scan, e, hash) => {
                this.reportScan(false, scan, hash, e === undefined ? undefined : `${e.name}: ${e.message}`);
                this.setQrCodeValidity('error');
            }
        );
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    setShouldLogScanAttempt(shouldLogScanAttempt: boolean): void
    {
        this.shouldLogScanAttempt = shouldLogScanAttempt;
    }

    setLastReportedCode(code: string): void
    {
        this.lastReportedCode = code;
    }

    close(): Promise<boolean>
    {
        return this.bridge.navigator.popScreen();
    }

    setQrCodeValidity(validity: QrCodeValidity)
    {
        this.qrCodeValidity = validity;
    }

    setIsReporting(isReporting: boolean): void
    {
        this.isReporting = isReporting;
    }

    setPlaceCodeScannerLoaded(loaded: boolean): void
    {
        this.placeCodeScannerLoaded = loaded;
    }

    setStartTimestamp(timestamp?: number): void
    {
        this.startTimestamp = timestamp || new Date().getTime();
    }

    reportScan(success: boolean, scan?: QrCodeScan, hash?: string, message?: string): void
    {
        const code = scan && scan.code ? scan.code.data : undefined;

        if (!code || code !== this.lastReportedCode)
        {
            if (code)
            {
                this.setLastReportedCode(code);
            }

            if (this.shouldLogScanAttempt)
            {
                this.diagnosticsService.report({
                    code: code,
                    data:
                        {
                            device: mobileVendor === 'none' ? undefined : `${mobileVendor}${mobileModel === 'none' ? '' : ` ${mobileModel}`}`,
                            hash: hash,
                            os: this.bridge.client.operatingSystem,
                            message: message,
                            platform: this.bridge.client.type === AppType.Web
                                ? this.bridge.client.platform
                                : this.bridge.client.type,
                            route: this.bridge.navigator.route,
                            processingTime: scan && scan.processingTime,
                        },
                    end_timestamp: new Date().getTime(),
                    start_timestamp: this.startTimestamp,
                    success: success,
                    type: this.placeCodeScannerStore.type,
                });
            }
        }
    }
}
