import { Color } from '../../../../Api/Other/Color';
import { MediaButtonBarTextMode } from './MediaButtonBarTextMode';

const darkColor = new Color(0, 0, 0, 1);
const lightColor = new Color(255, 255, 255, 1);

export function useEffectiveTextColor(
	textMode: MediaButtonBarTextMode,
	textColor?: Color
): Color
{
	if (textColor === undefined)
	{
		if (textMode === 'dark')
		{
			return darkColor;
		}
		else
		{
			return lightColor;
		}
	}
	else
	{
		return textColor;
	}
}
