import { Grid, makeStyles } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useIsKioskMode } from '../../../../../Service/KioskService/Api/useIsKioskMode';
import { useComputed } from '../../../../../Util/Hooks/useComputed';
import { BusinessContextRef } from '../../BusinessContext';
import { shouldShowCategoryOfStoreOnMenu } from '../Api/shouldShowCategoryOfStoreOnMenu';
import { MenuStore } from '../MenuStore';
import { FixedGrid } from './FixedGrid/FixedGrid';
import { CategoryMenuEntry } from './MenuEntry/category/CategoryMenuEntry';
import { ProductMenuEntry } from './MenuEntry/product/ProductMenuEntry';
import { Mixedgrid } from './MixedGrid/MixedGrid';
import { MixedGridItem } from './MixedGrid/MixedGridItem';

export const MENU_TILE_MIN_HEIGHT = 180;
export const MENU_LIST_ITEM_MIN_HEIGHT = 49;

const useStyles = makeStyles(() => ({
	grid: {
		flex: '1 0 auto !important',
		display: 'flex',
		flexDirection: 'column',
	},
	fixedGrid: {
		flex: '0 0 auto',
	},
	mixedGrid: {
		flex: '1 0 auto',
	},
}));

interface MenuGridProps
{
	nrOfColumns: number;
	store: MenuStore;
	childMenuStores: MenuStore[];
}

export const MenuGrid: FC<MenuGridProps> =
	(
		{
			nrOfColumns,
			store,
			childMenuStores,
		},
	) =>
	{
		const classes = useStyles();
		const {currentPlaceService, clockService} = useContext(BusinessContextRef);

		const openMenu = useObserver(() => store.openMenu);
		const products = useObserver(() => store.category.products);

		const shownChildMenuStores = useComputed(() =>
		{
			return childMenuStores.filter(childMenuStore => shouldShowCategoryOfStoreOnMenu(childMenuStore));
		}, [childMenuStores]);

		const shownProducts = useMemo(() =>
		{
			return products.filter(product => product.isVisibleNow(currentPlaceService.place, clockService));
		}, [clockService, currentPlaceService.place, products]);

		const nrOfGridItems = shownProducts.length + shownChildMenuStores.length;

		const getMinImageWidth = useCallback((rowLength: number) => window.devicePixelRatio * window.innerWidth / rowLength, []);

		const rowHeight = useMemo(
			() => Math.max(
				MENU_TILE_MIN_HEIGHT,
				window.innerHeight / Math.ceil(nrOfGridItems / nrOfColumns),
			),
			[nrOfColumns, nrOfGridItems],
		);

		const minImageHeight = useMemo(() => window.devicePixelRatio * rowHeight, [rowHeight]);

		const childCategoryMixedGridItems = useMemo<MixedGridItem[]>(() => {
			return shownChildMenuStores.map(menuStore => ({
				type: 'TILE',
				order: 0,
				key: `Category-${menuStore.category.id}`,
				node: ({rowLength}) => <CategoryMenuEntry
					menuStore={menuStore}
					minImageHeight={minImageHeight}
					minImageWidth={getMinImageWidth(rowLength)}
					onClick={() => openMenu(menuStore)}
				/>,
			}));
		}, [getMinImageWidth, minImageHeight, openMenu, shownChildMenuStores]);

		const isKioskMode = useIsKioskMode();
		const shouldShowProductsAsV2Tile = store.hasCarouselAncestor || isKioskMode;
		const productMixedGridItems = useMemo<MixedGridItem[]>(() =>
		{
			return shownProducts.map(product => ({
				type:
					shouldShowProductsAsV2Tile && (product.menuEntryType ?? 'TILE') === 'TILE'
						? 'TILE_V2'
						: ((product.menuEntryType as 'LIST_ITEM' | 'TILE') ?? 'TILE'),
				order: 0,
				key: `Product-${product.id}`,
				node: ({rowLength}) => <ProductMenuEntry
					product={product}
					minImageHeight={minImageHeight}
					minImageWidth={getMinImageWidth(rowLength)}
					forceGridItemType={
						shouldShowProductsAsV2Tile && (product.menuEntryType ?? 'TILE') === 'TILE'
							? 'TILE_V2'
							: undefined
					}
				/>,
			}));
		}, [shownProducts, shouldShowProductsAsV2Tile, minImageHeight, getMinImageWidth]);

		const allGridItems = useMemo(() => [...childCategoryMixedGridItems, ...productMixedGridItems], [childCategoryMixedGridItems, productMixedGridItems]);
		const fixedGridItems =
			useMemo(
				() =>
					allGridItems.filter(
						item =>
							item.type === 'TILE_V2'
					),
				[allGridItems]
			);
		const mixedGridItems =
			useMemo(
				() =>
					allGridItems.filter(
						item =>
							item.type !== 'TILE_V2'
					),
				[allGridItems]
			);

		return <>
			{
				fixedGridItems.length > 0 &&
				<Grid
					item
					xs={12}
					classes={{
						item: classes.fixedGrid,
					}}
				>
					<FixedGrid
						nrOfColumns={nrOfColumns}
						items={fixedGridItems}
					/>
				</Grid>
			}
			{
				mixedGridItems.length > 0 &&
				<Grid
					item
					xs={12}
					classes={{
						item: classes.grid,
					}}
				>
					<Mixedgrid
						className={classes.mixedGrid}
						nrOfColumns={nrOfColumns}
						items={mixedGridItems}
						listItemHeight={MENU_LIST_ITEM_MIN_HEIGHT}
						minTileHeight={MENU_TILE_MIN_HEIGHT}
					/>
				</Grid>
			}
		</>;
	};
