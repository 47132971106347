import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { CSSProperties, FC } from 'react';
import { OrderDescriptor } from '../../../../../../../Api/Order/OrderDescriptor';
import { UIColors } from '../../../../../../../Constants/UIColors';
import { OrderLine } from '../../../order-list/item/OrderLine';
import { OrderPaymentFee } from '../../../order-list/item/OrderPaymentFee';

const useStyles = makeStyles(
	(theme: Theme) =>
		({
			root:
				{
					backgroundColor: UIColors.grey,
					borderBottomLeftRadius: theme.shape.borderRadius,
					borderBottomRightRadius: theme.shape.borderRadius,
					transition:
						theme.transitions.create(
							'background-color',
							{
								easing: theme.transitions.easing.sharp,
								duration: theme.transitions.duration.leavingScreen,
							}),
				},
		}));

interface BillOrderItemContentProps
{
	alignWithAvatars?: boolean;
	order: OrderDescriptor;
	selected?: boolean;
	style?: CSSProperties;
}

export const BillOrderItemContent: FC<BillOrderItemContentProps> =
	observer(
		({
			 alignWithAvatars,
			 order,
			 selected,
			 style,
		 }) =>
		{
			const classes = useStyles();

			return <div
				className={classes.root}
				style={{
					...style,
					backgroundColor: selected ? UIColors.positiveOverlay : UIColors.negativeOverlay,
				}}
			>
				{
					order.paymentFee > 0 &&
					<OrderPaymentFee
						paymentCurrency={order.currency}
						paymentFee={order.paymentFee}
						showIcon={alignWithAvatars}
					/>
				}
				{
					order.orderLines.map(
						line =>
							<OrderLine
								alignWithAvatars={alignWithAvatars}
								clickable
								key={line.id}
								orderLine={line}
								showPriceAsCurrency={order.currency}
							/>,
					)
				}
			</div>;
		});