import * as React from 'react';
import { FC } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from '../../../../Bridge/Localization/useTranslation';

const useStyles = makeStyles(theme => ({
	root: {
		margin: '4px 8px',
	},
}));

interface HistorySectionHeaderProps
{
	translationKey: string
}

export const HistorySectionHeader: FC<HistorySectionHeaderProps> =
	(
		{
			translationKey,
		},
	) =>
	{
		const classes = useStyles();
		const text = useTranslation(translationKey);
		return <Typography
			variant="h6"
			className={classes.root}
		>
			{text}
		</Typography>;
	};