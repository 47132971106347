import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext, useEffect } from 'react';
import { TimeSchedulesContextProvider, useTimeSchedulesContext } from '../../context/time-schedule/TimeSchedulesContext';
import { OrderPaymentContextProvider, useOrderPaymentContext } from '../../UI/payment/context/OrderPaymentContext';
import { SessionStatusBarStoreProvider } from '../../UI/session-status-bar/SessionStatusBarStoreProvider';
import { LoaderPage } from '../LoaderPage';
import { BusinessContextRef } from './BusinessContext';
import { useBusinessQuery } from './use-business-query/useBusinessQuery';

interface BusinessHooksProviderProps
{
}

export const BusinessHooksProvider: FC<BusinessHooksProviderProps> =
	(
		{
			children,
		},
	) =>
	{
		const querySubscriptionIsOpen = useBusinessQuery();

		const {currentOrderService, currentPlaceService} = useContext(BusinessContextRef);

		const businessId = useObserver(() => currentPlaceService.business?.id);

		const currentOrderServiceInitialized = useObserver(() => currentOrderService?.initialized ?? false);

		if (
			!querySubscriptionIsOpen ||
			!currentOrderServiceInitialized
		)
			return <LoaderPage />;

		return <SessionStatusBarStoreProvider>
			<TimeSchedulesContextProvider businessId={businessId} intent="client-visit">
				<BusinessTimeScheduleCopier>
					<OrderPaymentContextProvider>
						<BusinessPaymentSetupCopier>
							{children}
						</BusinessPaymentSetupCopier>
					</OrderPaymentContextProvider>
				</BusinessTimeScheduleCopier>
			</TimeSchedulesContextProvider>
		</SessionStatusBarStoreProvider>;
	};

const BusinessTimeScheduleCopier: FC =
	(
		{
			children,
		},
	) =>
	{
		const {businessStore} = useContext(BusinessContextRef);
		const {timeSchedules} = useTimeSchedulesContext();

		const setTimeSchedules = useObserver(() => businessStore.setTimeSchedules);

		useEffect(() => setTimeSchedules(timeSchedules), [setTimeSchedules, timeSchedules]);

		return <>
			{children}
		</>;
	};

const BusinessPaymentSetupCopier: FC =
	(
		{
			children,
		},
	) =>
	{
		const {businessStore} = useContext(BusinessContextRef);
		const {initiatePayment, onPaymentProcessingResult} = useOrderPaymentContext();

		const setHandlePaymentProcessingResult = useObserver(() => businessStore.setHandlePaymentProcessingResult);
		const setInitiatePayment = useObserver(() => businessStore.setInitiatePayment);

		// Pass react context methods used in child screens / drawers / dialogs to the store,
		//   since current navigation does not allow sharing contexts properly.
		useEffect(
			() =>
			{
				setHandlePaymentProcessingResult(onPaymentProcessingResult);
				setInitiatePayment(initiatePayment);
			},
			[initiatePayment, onPaymentProcessingResult, setHandlePaymentProcessingResult, setInitiatePayment],
		);

		return <>
			{children}
		</>;
	};
