import { makeAutoObservable } from 'mobx';
import { postAny } from '../../Util/Api';

export class DiagnosticsService<T>
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    reportEndpoint: string;
    startTime: number;

    // ------------------------ Constructor -------------------------

    constructor(reportEndpoint: string)
    {
        makeAutoObservable(this, undefined, {
            autoBind: true,
            deep: false,
        });

        this.reportEndpoint = reportEndpoint;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    report(diagnostics: T): void
    {
        postAny(
            this.reportEndpoint,
            diagnostics)
        // Exceptions with respect to reporting are non-vital, thus are caught without consequence
            .catch();
    }

    // ------------------------ Public logic ------------------------

    // ----------------------- Private logic ------------------------
}
