import { DialogInputSpec, DialogInputSpecType } from './DialogInputSpec';

export class PinCodeDialogInputSpec extends DialogInputSpec
{
    onSubmit: (pin: string) => Promise<boolean>;
    length: number;

    constructor(
        onSubmit: (pin: string) => Promise<boolean>,
        length: number,
    )
    {
        super(DialogInputSpecType.PIN);
        this.onSubmit = onSubmit;
        this.length = length;
    }
}
