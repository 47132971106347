import * as React from 'react';
import { useMemo } from 'react';
import { FormLabel } from './FormLabel';

export function useFormLabel(
	label: string,
	explanation: string | undefined,
	secondary: boolean = false
)
{
	return useMemo(
		() => <FormLabel
			label={label}
			explanation={explanation}
			secondary={secondary}
		/>,
		[explanation, label, secondary],
	);
}