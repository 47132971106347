// https://gist.github.com/jasimmk/44207f6754ebd0bc0057
const countryCodesWithoutPostalCode =
	new Set([
		'AO',
		'AG',
		'AW',
		'BS',
		'BZ',
		'BJ',
		'BM',
		'BO',
		'BQ',
		'BW',
		'BF',
		'BI',
		'CM',
		'CF',
		'TD',
		'KM',
		'CG',
		'CD',
		'CK',
		'CI',
		'CW',
		'DJ',
		'DM',
		'GQ',
		'ER',
		'FJ',
		'TF',
		'GA',
		'GM',
		'GH',
		'GD',
		'GY',
		'HM',
		'HK',
		'KI',
		'KP',
		'LY',
		'MO',
		'MW',
		'ML',
		'MR',
		'NR',
		'AN',
		'NU',
		'QA',
		'RW',
		'KN',
		'ST',
		'SC',
		'SL',
		'SX',
		'SB',
		'SO',
		'SR',
		'SY',
		'TL',
		'TG',
		'TK',
		'TO',
		'TT',
		'TV',
		'UG',
		'AE',
		'VU',
		'YE',
		'ZW'
	]);

export function doesCountryHavePostalCode(countryCode: string)
{
	return !countryCodesWithoutPostalCode.has(countryCode);
}