import * as React from 'react';
import { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';
import { IllegalStateException } from '../../../../Util/Exception/IllegalStateException';

interface Context
{
	focused?: string;
	setFocus: (form: string, isFocused: boolean) => void;
}

const ContextRef = createContext({} as Context);

interface FormFocusContextProviderProps
{
	defaultForm: string;
	forms: string[];
}

export const FormFocusContextProvider: FC<FormFocusContextProviderProps> =
	({
		children,
		defaultForm,
		forms,
	}) =>
	{
		const [focused, setFocused] = useState<string>(defaultForm);

		const setFocus = useCallback((form: string, isFocused: boolean) =>
		{
			if (forms.includes(form))
				setFocused(isFocused ? form : defaultForm);
			else
				throw new IllegalStateException(`Failed to focus form ${form}: Form not present in [${forms.join(', ')}]`);
		}, [defaultForm, forms]);

		const contextValue = useMemo(
			() => ({focused, setFocus}),
			[focused, setFocus],
		);

		return <ContextRef.Provider value={contextValue}>
			{children}
		</ContextRef.Provider>;
	};

export function useFormFocus(form: string): [boolean, (isFocused: boolean) => void]
{
	const {focused, setFocus} = useContext(ContextRef);

	const segmentIsFocussed = useMemo(
		() => focused === form,
		[focused, form],
	);

	const setFocussed = useCallback(
		(isFocused: boolean) => setFocus(form, isFocused),
		[form, setFocus],
	);

	return [segmentIsFocussed, setFocussed];
}
