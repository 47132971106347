import { BaseStore } from '@intentic/ts-foundation';
import { Announcement } from '../../../../Api/Business/Announcement';

export class AnnouncementStore extends BaseStore
{
	public readonly announcement: Announcement

	constructor(
		announcement: Announcement,
	)
	{
		super();
		this.announcement = announcement;
	}
}