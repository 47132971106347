import { useMemo } from 'react';
import { ProductOrderRequestLine } from '../../../../../../../Service/OrderService/Api/Client/placeProductOrder';
import { useExternalOrdersContext } from '../../context/external-orders/ExternalOrdersContext';

export function useExternalOrderLines(): ProductOrderRequestLine[]
{
	const externalOrders = useExternalOrdersContext();

	return useMemo(() =>
	{
		if (externalOrders === undefined || typeof externalOrders === 'string')
			return [];
		else
			return externalOrders
				?.flatMap(
					externalOrder =>
						externalOrder
							.orderLines
							.map(
								line =>
									({
										type: 'external_bill',
										external_order_line_uuid: line.uuid,
										price: line.price.amount.toNumber(),
										quantity: line.quantity,
										routing_id: externalOrder.routingId,
										pos_id: externalOrder.posId,
									}),
							),
				);
	}, [externalOrders]);
}
