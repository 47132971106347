import { Card, CardContent, ClickAwayListener, Grid, IconButton, isWidthUp, Theme, useTheme, Zoom } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import chroma from 'chroma-js';
import clsx from 'clsx';
import * as React from 'react';
import { FC, MouseEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { useRootContext } from '../../../../../RootContext';
import { BrandingService } from '../../../../../Service/BrandingInformation/BrandingService';
import { useScreenWidth } from '../../../../../Util/Hooks/useScreenWidth';
import { useSearchContext } from '../../../../UI/Search/SearchContext';
import { SearchInput } from '../../../../UI/Search/SearchInput';

interface StylesProps
{
	brandingService: BrandingService
	colorIntention: 'primary' | 'secondary'
	isAtLeastMediumSizedWindow: boolean
	isSearching: boolean
}

const useStyles = makeStyles<Theme, StylesProps>(theme => ({
	card: ({brandingService, colorIntention, isSearching}) => {

		const color = brandingService.getColor(colorIntention);

		const hoverColor = chroma.distance(color.chroma, chroma('white')) > chroma.distance(color.chroma, chroma('black'))
			? color.chroma.brighten(0.5)
			: color.chroma.darken(0.5);
		return {
			backgroundColor: theme.palette[colorIntention].main,
			transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)',

			'&:hover': isSearching
				?
				{
					cursor: 'text',
				}
				:
				{
					backgroundColor: hoverColor.css(),
					cursor: 'pointer',
				},
		};
	},
	closeButton: {
		marginLeft: -(theme.spacing(2) + 32),
		marginRight: theme.spacing(2),
		padding: 4,
	},
	content: ({colorIntention}) => {
		return {
			alignItems: 'center',
			display: 'flex',
			flexGrow: 'inherit',
			height: 'inherit',
			padding: `0 !important`,

			'& > *': {
				color: `${theme.palette[colorIntention].contrastText} !important`,
			},
		};
	},
	input: ({isSearching}) => {
		return {
			flexDirection: 'row',
			flexGrow: 1,
			pointerEvents: isSearching ? undefined : 'none',
			padding: 0,
			height: '100%',

			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.standard,
			}),
		};
	},
	inputRoot: {
		height: '100%',
		color: 'inherit',
		flexGrow: 'inherit',
	},
	inputInput: ({isSearching, isAtLeastMediumSizedWindow}) => {
		const spacing = theme.spacing(2);
		const verticalTextAlignmentShift = isSearching
			? isAtLeastMediumSizedWindow ? 4 : 2
			: 0;
		return {
			borderWidth: 0,
			fontSize: isSearching ? '1.7rem' : undefined,
			fontWeight: isSearching ? 300 : undefined,

			height: `calc(100% - ${verticalTextAlignmentShift}px)`,
			paddingTop: `0px !important`,
			paddingBottom: `${verticalTextAlignmentShift}px !important`,
			paddingLeft: spacing + 32 + spacing,
			paddingRight:  isSearching
				? spacing + 32 + spacing
				: spacing,

			'&::placeholder': {
				color: 'inherit',
				opacity: 1,
			},
		};
	},
	searchIcon: ({isSearching}) => {
		return {
			fontSize: '2rem',

			marginTop: isSearching ? undefined : theme.spacing(2),
			marginBottom: isSearching ? undefined : theme.spacing(2),
			marginRight: -(theme.spacing(2) + 32),
			marginLeft: theme.spacing(2),
		}
	},
}));

interface MenuSearchCardProps
{
	classes?: { card?: string, root?: string };
	useSecondaryColor?: boolean;
}

export const MenuSearchCard: FC<MenuSearchCardProps> =
	(
		{
			classes: classesProp,
			useSecondaryColor,
		},
	) =>
	{
		const {brandingService, localizer} = useRootContext(true);
		const {isSearching, query, setIsSearching} = useSearchContext();

		const inputRef = useRef<HTMLInputElement>(null);

		useEffect(() => {
			if (isSearching)
				inputRef.current?.focus();
		}, [isSearching]);

		const colorIntention = useMemo(() => useSecondaryColor ? 'secondary' : 'primary', [useSecondaryColor]);

		const screenWidth = useScreenWidth();
		const isAtLeastMediumSizedWindow = useMemo(() => isWidthUp('md', screenWidth), [screenWidth]);

		const classes = useStyles({brandingService, colorIntention, isAtLeastMediumSizedWindow, isSearching});

		const onStartSearching = useCallback(() => {
			if (!isSearching)
				setIsSearching(true);
		}, [isSearching, setIsSearching]);

		const onStopSearching = useCallback((event: MouseEvent) => {
			event.stopPropagation();
			if (isSearching)
				setIsSearching(false);
		}, [isSearching, setIsSearching]);

		const theme = useTheme();

		const searchIcon = useMemo(() => <SearchIcon className={classes.searchIcon} />, [classes.searchIcon]);

		const searchInput = useMemo(() => <SearchInput
			className={classes.input}
			inputClassName={classes.inputInput}
			inputRootClassName={classes.inputRoot}
			placeholder={`${localizer.translate('Menu-Search')}...`}
			inputRef={inputRef}
		/>, [classes.input, classes.inputInput, classes.inputRoot, localizer]);

		const closeButton = useMemo(() => {
			return <Zoom
				style={{
					transition: theme.transitions.create('width', {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.standard,
					}),
				}}
				in={isSearching}
				unmountOnExit
			>
				<IconButton className={classes.closeButton} onClick={onStopSearching}>
					<CloseIcon />
				</IconButton>
			</Zoom>;
		}, [classes.closeButton, isSearching, onStopSearching, theme.transitions]);

		const handleStop = useCallback((force?: boolean) => {
			if (force || (isSearching && query === undefined))
				setIsSearching(false);
		}, [isSearching, query, setIsSearching]);

		const handleClickAway = useCallback(() => handleStop(false), [handleStop]);

		const handleKeyDown = useCallback((event: KeyboardEvent) => {
			if (event.code === 'Escape')
				handleStop(true);
		}, [handleStop]);

		const handleBlur = useCallback(() => handleStop(false), [handleStop]);

		useEffect(() => {
			if (isSearching)
				window.addEventListener('keydown', handleKeyDown);

			return () => {
				if (isSearching)
					window.removeEventListener('keydown', handleKeyDown);
			};
		}, [handleKeyDown, isSearching]);

		useEffect(() => {
			const currentInput = inputRef.current;

			if (currentInput)
				currentInput.addEventListener('blur', handleBlur);

			return () => {
				if (currentInput)
					currentInput.removeEventListener('blur', handleBlur);
			};
		}, [handleBlur]);

		const cardClassName = useMemo(() => clsx(classes.card, classesProp?.card), [classes.card, classesProp]);

		return <ClickAwayListener onClickAway={handleClickAway}>
			<Grid className={classesProp?.root} item xs={12}>
				<Card className={cardClassName} onClick={onStartSearching}>
					<CardContent className={classes.content}>
						{searchIcon}
						{searchInput}
						{closeButton}
					</CardContent>
				</Card>
			</Grid>
		</ClickAwayListener>;
	};