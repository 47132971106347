import { useEffect, useState } from 'react';
import { StorageVars } from '../../../../../../Constants/StorageConstants';
import { useStorage } from '../../../../../Root/StorageContextProvider';

export function useSelectInitialPaymentIssuer(
	onSelect: (paymentIssuerId?: string) => void,
	isInitialized: boolean,
	reset: boolean,
): boolean
{
	const storage = useStorage(true);

	const [didSelectInitialPaymentIssuer, setDidSelectInitialPaymentIssuer] = useState(false);

	useEffect(() =>
	{
		if (!didSelectInitialPaymentIssuer && isInitialized)
		{
			if (reset)
			{
				onSelect(undefined);
				setDidSelectInitialPaymentIssuer(true);
			}
			else
			{
				const value = storage.get(StorageVars.PaymentIssuer);

				if (!didSelectInitialPaymentIssuer)
				{
					onSelect(value);
					setDidSelectInitialPaymentIssuer(true);
				}
			}
		}
	}, [didSelectInitialPaymentIssuer, isInitialized, onSelect, reset, storage]);

	return didSelectInitialPaymentIssuer;
}