import * as React from 'react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { PageDialog } from '../../../../../UI/PageDialog';
import { Place } from '../../../../../../Api/Business/Place';
import { PlaceCode } from '../../../../../../Api/Business/PlaceCode';
import { PlaceSelect } from '../PlaceSelect';
import { useTranslate } from '../../../../../../Bridge/Localization/useTranslate';

interface PlaceSelectDialogProps
{
	onClose: () => void
	onPlaceSelect: (place: Place) => Promise<void>
	placeCode?: PlaceCode
	show: boolean
}

export const PlaceSelectDialog: FC<PlaceSelectDialogProps> =
	(
		{
			onClose,
			onPlaceSelect,
			placeCode,
			show,
		},
	) =>
	{
		const translate = useTranslate();
		const title = useMemo(
			() => placeCode && translate('Place-Code-Assign-From-Hash', placeCode.hash),
			[placeCode, translate],
		);

		const [open, setOpen] = useState(show);

		useEffect(() => setOpen(show), [show]);

		const close = useCallback(() => setOpen(false), []);

		const selectPlace = useCallback(
			(place: Place) => onPlaceSelect(place).then(close),
			[close, onPlaceSelect]);

		return <PageDialog
			fullWidth
			maxWidth="sm"
			onClose={close}
			onExited={onClose}
			open={open}
			title={title}
		>
			<PlaceSelect
				onPlaceSelect={selectPlace}
			/>
		</PageDialog>;
	};
