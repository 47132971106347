import Decimal from 'decimal.js';
import { useMemo } from 'react';

export function useMemoizedDecimal<T extends Decimal | undefined>(value: T): T
{
	return useMemo(
		() => value,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[value.d[0], value.d[1]],
	);
}
