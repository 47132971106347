// @ts-nocheck
/* eslint-disable */
import * as React from 'react';
import { FC } from 'react';
import { DietaryFlag } from '../../../Api/Product/DietaryFlag';
import IsHalalSvg from '../Business/Product/Allergen/Resource/IsHalal.svg';
import IsKosherSvg from '../Business/Product/Allergen/Resource/IsKosher.svg';
import IsOrganicSvg from '../Business/Product/Allergen/Resource/isOrganic.svg';
import IsPregnancyFriendlySvg from '../Business/Product/Allergen/Resource/IsPregnancyFriendly.svg';
import IsVeganSvg from '../Business/Product/Allergen/Resource/IsVegan.svg';
import IsVegetarianSvg from '../Business/Product/Allergen/Resource/IsVegetarian.svg';
import IsNonAlcoholicSvg from '../Business/Product/Allergen/Resource/NonAlcoholic.svg';

const nutritionIcons = require.context('../Business/Product/Allergen/Resource', false);

export function dietaryFlagIcon(
	flag: DietaryFlag,
	size: number = 40,
	onClick: () => void = () => {},
)
{
	switch (flag)
	{
		case 'IsHalal':
			// return <IsHalalSvg
			//     width={size}
			//     height={size}
			//     viewBox="0 0 24 24"
			//     onClick={onClick}
			// />;
			return <img
				src={IsHalalSvg}
				width={size}
				height={size}
				onClick={onClick}
			/>;
		case 'IsKosher':
			return <img
				src={IsKosherSvg}
				width={size}
				height={size}
				onClick={onClick}
			/>;
		case 'IsPregnancyFriendly':
			return <img
				src={IsPregnancyFriendlySvg}
				width={size}
				height={size}
				onClick={onClick}
			/>;
		case 'IsOrganic':
			return <img
				src={IsOrganicSvg}
				width={size}
				height={size}
				onClick={onClick}
			/>;
		case 'IsVegan':
			return <img
				src={IsVeganSvg}
				width={size}
				height={size}
				onClick={onClick}
			/>;
		case 'IsVegetarian':
			return <img
				src={IsVegetarianSvg}
				width={size}
				height={size}
				onClick={onClick}
			/>;
		case 'Nonalcoholic':
			return <img
				src={IsNonAlcoholicSvg}
				width={size}
				height={size}
				onClick={onClick}
			/>;
	}
	return nutritionIcons(`./${flag}.svg`);
}

interface DietaryFlagBadgeProps
{
	flag: DietaryFlag;
	size?: number;
	onClick?: () => void;
}

export const DietaryFlagBadge: FC<DietaryFlagBadgeProps> =
	({
		flag,
		size = 40,
		onClick = () => {}
	}: DietaryFlagBadgeProps) => {
		return <div
			style={{
				display: 'flex',
				width: size,
				height: size,
			}}
		>
			{dietaryFlagIcon(flag, size, onClick)}
			<div
				style={{
					display: 'none',
				}}
			>
				{flag}
			</div>
		</div>
	};