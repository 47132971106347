import * as React from 'react';
import { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';

export type FormSegment = 'external-orders' | 'payment-method' | 'email';

interface Context
{
	isOpen: boolean;
	close: () => void;
	open: () => void;
	onExited: () => void;
}

const ContextRef = createContext<Context>(undefined as never);

interface DialogVisibilityContextProviderProps
{
	onExited: () => void;
}

export const DialogVisibilityContextProvider: FC<DialogVisibilityContextProviderProps> =
	({
		children,
		onExited,
	}) =>
	{
		const [isOpen, setIsOpen] = useState(true);

		const open = useCallback(() => setIsOpen(true), []);
		const close = useCallback(() => setIsOpen(false), []);

		const contextValue = useMemo(
			() => ({close, isOpen, onExited, open}),
			[close, isOpen, onExited, open],
		);

		return <ContextRef.Provider value={contextValue}>
			{children}
		</ContextRef.Provider>;
	};

export function useDialogVisibilityContext(): Context
{
	return useContext(ContextRef);
}