import * as React from 'react';
import { createContext, FC, useContext } from 'react';
import { ExternalOrderQueryResult, useExternalOrderQuery } from './useExternalOrderQuery';

type Context = ExternalOrderQueryResult | undefined;

const ContextRef = createContext(undefined);

export const ExternalOrdersContextProvider: FC =
	(
		{
			children,
		},
	) =>
	{
		const externalOrders = useExternalOrderQuery();

		return <ContextRef.Provider value={externalOrders}>
			{children}
		</ContextRef.Provider>;
	};

export function useExternalOrdersContext(): Context
{
	return useContext(ContextRef);
}
