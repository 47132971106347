import { useObserver } from 'mobx-react-lite';
import { FC, useCallback, useContext, useEffect } from 'react';
import { ProductConfiguration } from '../../../Api/Product/ProductConfiguration';
import { Screens } from '../../../Constants/ScreenConstants';
import { useRootContext } from '../../../RootContext';
import { setInApplicationInterface } from '../../../Util/application_interface/setInApplicationInterface';
import { BusinessContextRef } from '../../Page/Business/BusinessContext';
import { useGlobalInputReader } from '../global_input_reader/useGlobalInputReader';

export const KioskWrapper: FC =
	({
		children,
	}) =>
	{
		const {navigator, notification} = useRootContext(true);
		const {businessStore} = useContext(BusinessContextRef);

		const add = useObserver(() => businessStore.shoppingCartStore.add);
		const openScreens = useObserver(() => navigator.openScreens);
		const orderBuilderStore = useObserver(() => businessStore.orderBuilderStore);
		const productByScanCode = useObserver(() => businessStore.productByScanCode);
		const pushScreen = useObserver(() => navigator.pushScreen);

		const handleReadInput = useCallback(
			(
				input: string,
			) =>
			{
				if (input.length > 0)
				{
					if (productByScanCode.has(input))
					{
						const product = productByScanCode.get(input)!;

						if (product.featureAssignments.length === 0)
						{
							add(
								new ProductConfiguration(
									product,
									[],
								),
								1,
							);

							if (
								openScreens
									.find(
										screen =>
											screen.screen.id === Screens.OrderBuilder,
									) === undefined
							)
								return pushScreen(
									Screens.OrderBuilder,
									orderBuilderStore,
								);
						}
					}
					else if (orderBuilderStore.currentAgeVerificationService.isAgeVerificationVoucherLink(input)
						&& !orderBuilderStore.currentAgeVerificationService.isChallengePassed)
					{
						orderBuilderStore.currentAgeVerificationService
							.scanVoucher(
								input,
								notification
							);
					}
				}
			},
			[add, notification, openScreens, orderBuilderStore, productByScanCode, pushScreen],
		);

		useGlobalInputReader(handleReadInput);

		useEffect(
			() =>
			{
				setInApplicationInterface(
					'addProductByScanCode',
					handleReadInput,
				);

				return () =>
				{
					setInApplicationInterface(
						'addProductByScanCode',
						undefined,
					);
				};
			},
			[handleReadInput],
		);

		return <>
			{children}
		</>;
	};
