import { useObserver } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import { useTranslate } from '../../../../../../Bridge/Localization/useTranslate';
import { useCurrentPlaceService } from '../../../../../current-place-service/CurrentPlaceService';
import { GenericHistoricalItem } from '../GenericHistoricalItem';

interface HistoricalLocationItemProps
{
	orderingLocation: string;
	businessName: string;
	businessId: number;
}

export const HistoricalHeaderLocationItem: FC<HistoricalLocationItemProps> =
	({
		orderingLocation,
		businessName,
		businessId,
	}) =>
	{
		const translate = useTranslate();
		const currentPlaceService = useCurrentPlaceService();
		const currentBusinessId = useObserver(() => currentPlaceService.business?.id);

		const label = useMemo(
			() =>
				currentBusinessId === businessId
					? `${translate('Place')}: ${orderingLocation}`
					: businessName,
			[businessId, businessName, currentBusinessId, orderingLocation, translate],
		);

		return <GenericHistoricalItem
			description={label}
		/>;
	};
