import * as React from 'react';
import { useContext, useMemo } from 'react';
import { Localizer } from '../../../Bridge/Localization/Localizer';
import { CurrentAgeVerificationService } from '../../../Service/CurrentAgeVerification/CurrentAgeVerificationService';
import { CurrentOrderService } from '../../../Service/CurrentOrder/CurrentOrderService';
import { CurrentPlaceService } from '../../../Service/CurrentPlace/CurrentPlaceService';
import { ClockService } from '../../../Util/clock/ClockService';
import { IllegalStateException } from '../../../Util/Exception/IllegalStateException';
import { BusinessStore } from './BusinessStore';

export interface BusinessContext
{
    currentPlaceService: CurrentPlaceService;
    currentOrderService: CurrentOrderService | undefined;
    currentAgeVerificationService: CurrentAgeVerificationService | undefined;
    clockService: ClockService;
    localizer: Localizer;
    businessStore: BusinessStore;
}

export const BusinessContextRef = React
    .createContext<BusinessContext>({} as BusinessContext);

export const BusinessContextProvider = BusinessContextRef.Provider;
export const BusinessContextConsumer = BusinessContextRef.Consumer;

export function useCurrentOrderService(require?: false): CurrentOrderService | undefined
export function useCurrentOrderService(require: true): CurrentOrderService
export function useCurrentOrderService(require?: boolean): CurrentOrderService | undefined
export function useCurrentOrderService(require: boolean = false): CurrentOrderService | undefined
{
    const {currentOrderService} = useContext(BusinessContextRef);
    return useMemo(
        () => {
            if (require && currentOrderService === undefined)
                throw new IllegalStateException(
                    'CurrentOrderService should be defined here',
                );
            return currentOrderService;
        },
        [currentOrderService, require],
    );
}