import { Collapse, ListItem } from '@material-ui/core';
import * as React from 'react';
import { FC } from 'react';
import { OrderWaiterButton } from '../TopContent/OrderWaiterButton';

interface OrderWaiterButtonListItemProps
{
	className?: string;
	onClick: () => void;
	show?: boolean;
}

export const OrderWaiterButtonListItem: FC<OrderWaiterButtonListItemProps> =
	(
		{
			className,
			onClick,
			show = true,
		},
	) =>
	{
		return <Collapse in={show} timeout={100} unmountOnExit>
			<ListItem className={className}>
				<OrderWaiterButton onClick={onClick} />
			</ListItem>
		</Collapse>;
	};