import { Typography } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext } from 'react';
import { TRADITIONAL_PAYMENT_METHOD_ID } from '../../../../../../Api/Payment/PaymentMethodDescriptor';
import { useTranslation } from '../../../../../../Bridge/Localization/useTranslation';
import { useIsKioskMode } from '../../../../../../Service/KioskService/Api/useIsKioskMode';
import { CardBorderRadius } from '../../../../../../Util/Theme';
import { ActionButton } from '../../../../../UI/action-button/ActionButton';
import { usePaymentMethodContext } from '../../../../../UI/payment/method/context/PaymentMethodContext';
import { BusinessContextRef } from '../../../BusinessContext';

interface OrderButtonProps
{
	bottomLeftEdgeRadius: number | undefined;
	height: number;
	loading: boolean;
	error: boolean;
	onClick: (() => Promise<void>) | (() => void);
}

export const OrderButton: FC<OrderButtonProps> =
	(
		{
			bottomLeftEdgeRadius,
			height,
			loading,
			error,
			onClick,
		},
	) =>
	{
		const {businessStore} = useContext(BusinessContextRef);
		const isServicePause = useObserver(() => businessStore.business.isServicePaused);
		const isKioskMode = useIsKioskMode();
		const placeOrderTranslation = useTranslation('Payment-Method-PlaceOrder');
		const payOrderTranslation = useTranslation('Client-OrderHistory-Order-Pay');
		const {selectedPaymentMethod} = usePaymentMethodContext();
		const hasPayment = selectedPaymentMethod !== undefined && selectedPaymentMethod.id !== TRADITIONAL_PAYMENT_METHOD_ID;
		const label =
			hasPayment
				? payOrderTranslation
				: placeOrderTranslation;
		const isDisabled = isServicePause || loading;
		const buttonId = 'order-builder-order-button';

		if (isKioskMode)
		{
			return <ActionButton
				disabled={isDisabled}
				id={buttonId}
				text={
					<Typography
						variant="body1"
						style={{
							fontSize: '2.25rem',
							textTransform: 'initial',
							fontWeight: 500,
						}}
					>
						{label}
					</Typography>
				}
				onClick={onClick}
				style={{
					alignSelf: 'center',
					height: 105,
					paddingLeft: 146,
					paddingRight: 146,
					paddingTop: 12,
					paddingBottom: 12,
					borderRadius: 12,
				}}
				error={error}
				loading={loading}
				variant="contained"
			/>;
		}
		else
		{
			return <ActionButton
				disabled={isDisabled}
				fullWidth
				id={buttonId}
				text={placeOrderTranslation}
				onClick={onClick}
				style={{
					height,
					borderRadius: `${CardBorderRadius}px ${CardBorderRadius}px ${CardBorderRadius}px ${bottomLeftEdgeRadius ? bottomLeftEdgeRadius : CardBorderRadius}px`,
				}}
				error={error}
				loading={loading}
				textStyle={{
					marginLeft: 5,
				}}
				textAlign="left"
				variant="contained"
			/>;
		}
	};