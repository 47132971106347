import { PaymentIssuer, PaymentIssuerProfile } from '../../../../../Api/Payment/PaymentIssuer';
import { fetchList } from '../../../../../Util/Api';

export function getPaymentIssuers(params: GetPaymentIssuersParams): Promise<PaymentIssuer[]>
{
	return fetchList('/client/business/order/issuers', params, PaymentIssuerProfile);
}

interface GetPaymentIssuersParams
{
	payment_method: string;
}