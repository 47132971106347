import { v4 as uuid } from 'uuid';

export abstract class WindowEvent
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	readonly id: string;
	readonly type: string;
	readonly timestamp: Date;

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor(type: string)
	{
		this.id = uuid();
		this.type = type;
		this.timestamp = new Date();
	}
}
