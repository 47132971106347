// @ts-nocheck
/* eslint-disable */
import { Card, Checkbox, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { substituteTranslationArgumentPlaceholders } from '../../../../../../Bridge/Localization/Localizer';
import { useLocalizer } from '../../../../../../Bridge/Localization/useLocalizer';

const useStyles = makeStyles((theme) => ({
	consentText: {
		fontSize: '0.8rem',
		color: theme.palette.text.secondary,
	},
	legalLink: {
		'&:hover': {
			color: theme.palette.primary.main,
			transition: '0.4s'
		},
	},
}));

interface TrackerConsentProps
{
	showValidation: boolean
	legalConsent: boolean
	setLegalConsent: (legalConsent: boolean) => void
	beneficiaryName: string
	required?: boolean
	labelTemplate?: string
}

export const ShopperTrackerConsentForm: FC<TrackerConsentProps> =
	(
		{
			showValidation,
			legalConsent,
			setLegalConsent,
			beneficiaryName,
			labelTemplate,
			required = false
		},
	) =>
	{
		const localizer = useLocalizer();
		const {
			consentText,
			legalLink
		} = useStyles();
		const label = useMemo(
			() => {
				return labelTemplate === undefined
					?
					localizer.translate(
						'Legal-Agree-Order-Tracker',
						beneficiaryName,
					)
					:
					substituteTranslationArgumentPlaceholders(
						labelTemplate,
						[beneficiaryName]
					)
			},
			[beneficiaryName, labelTemplate, localizer]
		)

		return <Card
			style={{
				backgroundColor: (showValidation && required && !legalConsent) ? '#f001' : '#fff8',
				padding: '16px 16px',
				cursor: 'pointer',
				display: 'flex',
				flexDirection: 'column',
				justifyItems: 'stretch',
			}}
			onClick={e => setLegalConsent(!legalConsent)}
		>
			<div
				style={{
					flex: '0 0 auto',
					display: 'flex',
					flexDirection: 'row',
					justifyItems: 'stretch',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						lineHeight: 0.8,
						textAlign: 'justify',
						flex: '1 1 auto',
					}}
				>
					<Typography
						className={consentText}
						color="textSecondary"
					>
						{label}
					</Typography>
				</div>
				<Checkbox
					style={{
						marginLeft: 4,
						marginRight: -12,
					}}
					checked={legalConsent}
					onChange={(e, checked) => {
						setLegalConsent(!legalConsent);
					}}
				/>
			</div>
			{showValidation && required && !legalConsent && <Typography
				color="error"
				style={{
					fontSize: '0.75rem',
					lineHeight: 1.66,
				}}
			>
				{localizer.translate('Legal-Agree-Required')}
			</Typography>}
		</Card>;
	};