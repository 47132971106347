// @ts-ignore
import icon from '../Resources/Favicon/favicon_256.png';

export const ServiceWorkerConstants =
    {
        AppName: 'Butlaroo',
        DefaultOptions:
            {
                badge: icon,
                icon: icon,
                vibrate: [200, 100, 200],
            },
        OutputFile: '/service-worker.js',
    };