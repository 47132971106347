import * as React from 'react';
import { FC, useContext, useMemo } from 'react';
import { Storage } from '../../Bridge/Storage/Storage';
import { WebStorage } from '../../Bridge/Storage/WebStorage';
import { IllegalStateException } from '../../Util/Exception/IllegalStateException';

interface StorageContext
{
	storage: Storage;
}

const ContextRef = React.createContext<StorageContext | undefined>(undefined);

export const StorageContextProvider: FC =
	(
		{
			children,
		}
	) =>
	{
		const storage = useMemo(() => new WebStorage(), []);
		return <ContextRef.Provider
			value={useMemo(() => ({
				storage,
			}), [storage])}
		>
			{children}
		</ContextRef.Provider>
	};

export function useStorage(require?: false): WebStorage | undefined
export function useStorage(require: true): WebStorage
export function useStorage(require?: boolean): WebStorage | undefined
{
	const context = useContext(ContextRef);
	return useMemo(
		() => {
			if (require && context === undefined)
				throw new IllegalStateException(
					'Attempting to use useStorage outside of StorageContextProvider'
				);
			return context?.storage;
		},
		[context, require]
	);
}
