import Decimal from 'decimal.js';

export class ExternalOrderLineForDisplay
{
	public readonly uuid: string;
	public readonly quantity: number;
	public readonly description: string;
	public readonly descriptionSecondary?: string;
	public readonly unitPriceAmount: Decimal;

	constructor(
		uuid: string,
		quantity: number,
		description: string,
		descriptionSecondary: string | undefined,
		unitPriceAmount: Decimal
	)
	{
		this.uuid = uuid;
		this.quantity = quantity;
		this.description = description;
		this.descriptionSecondary = descriptionSecondary;
		this.unitPriceAmount = unitPriceAmount;
	}
}