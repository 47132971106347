// @ts-nocheck
/* eslint-disable */
import { Bridge } from '../Bridge/Bridge';
import { WebClient } from '../Bridge/Client/WebClient';
import { DialogInstance } from '../Bridge/Dialog/DialogInstance';
import { DialogAction } from '../Bridge/Dialog/DialogProps';
import { DialogInputSpec } from '../Bridge/Dialog/Input/DialogInputSpec';
import { WebNavigator } from '../Bridge/Navigator/WebNavigator';

export function confirm(
    bridge: Bridge,
    scrollTo: (scrollTop: number) => void,
    content: string | React.ReactElement,
    perform: () => void,
    title?: string,
    performOnCancel?: () => void,
    input?: DialogInputSpec,
    actions: DialogAction[] = [
        {
            name: bridge.localizer.translate('Generic-Confirm'),
            perform: perform,
        },
    ],
): Promise<DialogInstance>
{
    if (bridge.navigator instanceof WebNavigator && bridge.client instanceof WebClient)
    {
        // store what the last scroll position of the previous screen was
        bridge.navigator.currentScreenInstance.scrollTop = bridge.client.scrollYOffset;

        // reset the scroll position for the new screen
        scrollTo(0);
    }

    return bridge.dialog.present(
        {
            title: title || bridge.localizer.translate('Generic-Confirmation'),
            content: content,
            input: input,
            performOnCancel: performOnCancel,
            actions:
            [
                {
                    name: bridge.localizer.translate('Generic-Cancel'),
                    perform: performOnCancel ? performOnCancel : () => undefined,
                    isCancel: true
                },
                ... actions
            ]
        });
}
