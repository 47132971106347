import { OrderDescriptor } from '../../../../../../Api/Order/OrderDescriptor';
import { OrderLineStateMutation } from '../../../../../../lib/event/order_line/mutation/update/OrderLineStateMutation';
import { useWebSocketQueryRxjs } from '../../../../Business/useWebSocketQueryRxjs';
import { ManagementQuery } from '../../../../Business/WebSocketService';

export function useEventQueryForOrderLineStateMutations(
	businessId: number,
	mutateOrder: (uuid: string, mutationFunction: (order: OrderDescriptor) => OrderDescriptor) => void,
)
{
	useWebSocketQueryRxjs<OrderLineStateMutation>(
		new ManagementQuery(businessId),
		'OrderLineStateMutation',
		mutation =>
		{
			mutateOrder(
				mutation.orderId,
				order =>
					({
						...order,
						orderLines: order
							.orderLines
							.map(
								line =>
									line.uuid === mutation.orderLineId
										? {
											...line,
											state: mutation.state,
										}
										: line,
							),
					}),
			);
		},
	);
}
