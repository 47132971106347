import { ImageInput, QrCodeParser, QrReader } from '@intentic/qr-reader-web';
import { Loader } from '@intentic/ts-foundation';
import { Fab, makeStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { PhotoCamera, Replay } from '@material-ui/icons';
import Flashlight from 'mdi-material-ui/Flashlight';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { CSSProperties, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { WebClient } from '../../../Bridge/Client/WebClient';
import { useTranslate } from '../../../Bridge/Localization/useTranslate';
import { useTranslation } from '../../../Bridge/Localization/useTranslation';
import { ActionButton } from '../action-button/ActionButton';
import { HorizontalTextLine } from '../HorizontalTextLine';
import { PlaceCodeScannerMessage } from './message/PlaceCodeScannerMessage';
import { PlaceCodeScannerState } from './PlaceCodeScannerState';
import { PlaceCodeScannerStore } from './PlaceCodeScannerStore';

const useStyles = makeStyles({
    actionButtonContainer: {
        alignItems: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        width: 'fit-content',
    },
    imageInputRoot: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 50,
        paddingLeft: 20,
        paddingRight: 20,
    },
    imageInputLabel: {
        cursor: 'pointer',
    },
    imageInputComponent: {
        display: 'none',
    },
    root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        flexGrow: 1,
    },
    scanner: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
});

interface PlaceCodeScannerProps
{
    store: PlaceCodeScannerStore<any>
    buttonDividerColor?: string
    classes?: Partial<ClassNameMap<'root' | 'scanner'>>
    style?: CSSProperties
}

export const PlaceCodeScanner: FC<PlaceCodeScannerProps> =
    (
        {
            store,
            style,
            buttonDividerColor,
        }
    ) =>
    {
        const [videoStreamTrack, setVideoStreamTrack] = useState<MediaStreamTrack | undefined>();
        const [showTorchButton, setShowTorchButton] = useState<boolean | undefined>();
        const [torchEnabled, setTorchEnabled] = useState<boolean | undefined>();
        const [parser, setParser] = useState<QrCodeParser | undefined>();
        const [type] = useState(store.type);

        const webClient = useObserver(() => store.client as WebClient);
        const handleException = useObserver(() => store.handleException);
        const onScannerLoad = useObserver(() => store.onScannerLoad);
        const setScannerState = useObserver(() => store.setScannerState);
        const setUseFallback = useObserver(() => store.setUseFallback);
        const stopTimeoutTimer = useObserver(() => store.stopTimeoutTimer);

        const parserRef = useRef(parser);

        const updateParser = useCallback(
            (parser: QrCodeParser) =>
            {
                setParser(parser);
                parserRef.current = parser;
            },
            [],
        );

        const onVideoStreamLoad = useCallback((videoStream: MediaStream) =>
        {
            setVideoStreamTrack(videoStream.getVideoTracks()[0]);

            if (videoStreamTrack !== undefined)
            {
                onScannerLoad();

                webClient.torchSupport(videoStream)
                    .then(() => setShowTorchButton(true))
                    .catch(() => setShowTorchButton(false));
            }
        }, [onScannerLoad, videoStreamTrack, webClient]);
        
        const setTorch = useCallback((enabled: boolean) =>
        {
            return videoStreamTrack.applyConstraints({
                advanced: [
                    {
                        torch: enabled,
                        // TODO: Remove typing when Typescript DOM typings include torch
                    } as any,
                ],
            });
        }, [videoStreamTrack]);

        useEffect(() =>
        {
            if (torchEnabled !== undefined)
                setTorch(torchEnabled)
                    .catch((error: Error) =>
                    {
                        if (error.name === 'NotSupportedError')
                        {
                            // eslint-disable-next-line no-console
                            console.warn('Attempted to toggle torch mode, which is not supported on this device.');
                        }
                        else
                        {
                            handleException(error);
                        }
                    });
        }, [handleException, setTorch, torchEnabled]);

        useEffect(() =>
        {
            if (type === 'stream')
            {
                setScannerState(PlaceCodeScannerState.pendingAuthorization);
            }
            return () =>
            {
                setUseFallback(false);
                stopTimeoutTimer();
            };
        }, [setScannerState, setUseFallback, stopTimeoutTimer, type]);

        const classes = useStyles();

        return <div
            className={classes.root}
            style={style}
        >
            <Content
                store={store}
                parser={parserRef}
                onVideoStreamLoad={onVideoStreamLoad}
                setParser={updateParser}
                setTorchEnabled={setTorchEnabled}
                showTorchButton={showTorchButton!}
                torchEnabled={torchEnabled!}
                buttonDividerColor={buttonDividerColor}
            />
        </div>;
    };

interface ContentProps
{
    store: PlaceCodeScannerStore<any>
    parser: React.RefObject<QrCodeParser>
    setParser: (parser: QrCodeParser) => void
    torchEnabled: boolean
    onVideoStreamLoad: (videoStream: MediaStream) => void
    buttonDividerColor?: string
    showTorchButton: boolean
    setTorchEnabled: (enabled: boolean) => void
}

const Content: FC<ContentProps> =
    (
        {
            store,
            parser,
            setParser,
            torchEnabled,
            onVideoStreamLoad,
            buttonDividerColor,
            showTorchButton,
            setTorchEnabled,
        }
    ) =>
    {
        const classes = useStyles();
        const translate = useTranslate();
        const scannerState = useObserver(() => store.scannerState);

        const message: string = useMemo(
            () => {
                switch (scannerState)
                {
                    case PlaceCodeScannerState.error:
                        return 'Generic-Error-Occurred';
                    case PlaceCodeScannerState.invalidQrCode:
                        return 'Qr-Code-Invalid';
                    case PlaceCodeScannerState.noQrCode:
                        return 'Qr-Code-None';
                    case PlaceCodeScannerState.unknownQrCode:
                        return 'Qr-Code-Unknown';
                    case PlaceCodeScannerState.notSupported:
                    case PlaceCodeScannerState.timedOut:
                        return 'Scanner-Browser-Stream-Not-Available';
                    case PlaceCodeScannerState.notAuthorized:
                        return 'Scanner-Authorization-Denied';
                    default:
                        return 'Scanner-Photo-Instructions';
                }
            },
            [scannerState]
        );
        const firstTry: boolean = useMemo(
            () => {
                switch (scannerState)
                {
                    case PlaceCodeScannerState.error:
                    case PlaceCodeScannerState.invalidQrCode:
                    case PlaceCodeScannerState.noQrCode:
                    case PlaceCodeScannerState.unknownQrCode:
                        return false;
                    default:
                        return true;
                }
            },
            [scannerState]
        );

        const buttonText = useMemo(
            () => translate(
                firstTry ? 'Qr-Code-Photo' : 'Qr-Code-Photo-Again'
            ),
            [firstTry, translate]
        );
        const genericOrTranslation = useTranslation('Generic-Or');

        const handleException = useObserver(() => store.handleException);
        const onNoCode = useObserver(() => store.onNoCode);
        const onScan = useObserver(() => store.onScan);
        const onScannerInit = useObserver(() => store.onScannerInit);
        const canStreamVideo = useObserver(() => store.canStreamVideo);
        const reload = useObserver(() => store.reload);
        const type = useObserver(() => store.type);

        return <>
            <QrReader
                className={classes.scanner}
                loader={<Loader />}
                onError={handleException}
                onNoCode={onNoCode}
                onParse={onScan}
                onParserInit={setParser}
                onVideoStreamInit={onScannerInit}
                onVideoStreamLoad={onVideoStreamLoad}
                type={type}
            >
                <PlaceCodeScannerMessage
                    message={`${translate(message)}.`}
                    error={true}
                />
                <div
                    className={classes.imageInputRoot}
                >
                    {
                        canStreamVideo &&
                        <>
                            <ReloadButton reload={reload} />
                            {
                                <HorizontalTextLine
                                    color={buttonDividerColor}
                                    text={genericOrTranslation}
                                />
                            }
                        </>
                    }
                    <ImageInputSection
                        label={buttonText}
                        onImage={parser.current ? parser.current.parseImage : undefined}
                    />
                </div>
            </QrReader>
            {
                showTorchButton &&
                <div
                    style={{
                        bottom: 0,
                        position: 'absolute',
                    }}
                >
                    <Fab
                        style={{
                            marginBottom: 60,
                            backgroundColor: torchEnabled ? '#e0e0e0' : '#333',
                        }}
                        onClick={
                            () =>
                                setTorchEnabled(!torchEnabled)
                        }
                    >
                        <Flashlight
                            style={{
                                color: torchEnabled ? '#000' : 'white',
                            }}
                        />
                    </Fab>
                </div>
            }
        </>;
    };

interface ReloadButtonProps
{
    reload: () => void
}

const ReloadButton: FC<ReloadButtonProps> =
    (
        {
            reload,
        }
    ) =>
    {
        const text = useTranslation('Scanner-Reload');
        return <ActionButton
            icon={Replay}
            text={text}
            onClick={reload}
            style={{
                marginTop: 20,
            }}
        />;
    }

interface ImageInputProps
{
    label: string
    onImage?: (image: HTMLImageElement) => void
}

const ImageInputSection: FC<ImageInputProps> =
    (
        {
            label,
            onImage,
        }
    ) =>
    {
        const classes = useStyles();
        return <ImageInput
            className={classes.imageInputLabel}
            onChange={onImage}
        >
            <ActionButton
                icon={PhotoCamera}
                onClick={() => {}}
                text={label}
                style={{
                    pointerEvents: 'none',
                }}
                variant="contained"
            />
        </ImageInput>;
    };