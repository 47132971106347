import { IconButton, makeStyles } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Alert } from '@material-ui/lab';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useCallback, useContext } from 'react';
import { PaymentMethodDescriptor } from '../../../../../../Api/Payment/PaymentMethodDescriptor';
import { useTranslate } from '../../../../../../Bridge/Localization/useTranslate';
import { useAdyenCheckoutContext } from '../../../../../UI/payment/adyen/checkout/AdyenCheckoutContext';
import { PaymentMethodsLoadingCard } from '../../../../../UI/payment/method/form/loading/PaymentMethodsLoadingCard';
import { PaymentMethodForm } from '../../../../../UI/payment/method/form/PaymentMethodForm';
import { EntranceContextRef } from '../../../../Entrance/EntranceContext';
import { useCurrentOrderService } from '../../../BusinessContext';
import { OrderBuilderSegment } from '../../OrderBuilderSegment';
import { OrderBuilderStore } from '../../OrderBuilderStore';

const useStyles = makeStyles({
	alert: {
		alignItems: 'center',
	},
});

interface PaymentOrderBuilderSegmentProps
{
	onChange: (paymentMethod?: PaymentMethodDescriptor) => void;
	store: OrderBuilderStore;
}

export const PaymentOrderBuilderSegment: FC<PaymentOrderBuilderSegmentProps> =
	(
		{
			onChange,
			store,
		},
	) =>
	{
		const translate = useTranslate();
		const {notification} = useContext(EntranceContextRef);
		const currentOrderService = useCurrentOrderService();
		const {enabled, isLoading, refreshPaymentSession} = useAdyenCheckoutContext();

		const classes = useStyles();

		const needsPayment = useObserver(() => store.needsPayment);
		const paymentMethodHasFocus = useObserver(() => store.paymentMethodHasFocus);
		const paymentSessionHasError = useObserver(() => store.paymentSessionHasError);
		const setHasInteractedWithPaymentMethods = useObserver(() => store.setHasInteractedWithPaymentMethods);
		const setPaymentMethodHasFocus = useObserver(() => store.setPaymentMethodHasFocus);
		const setPaymentMethodHasError = useObserver(() => store.setPaymentMethodHasError);
		const setPaymentMethod = useObserver(() => currentOrderService?.setPaymentMethod);

		const handleChange = useCallback(
			(
				paymentMethod?: PaymentMethodDescriptor,
			) =>
			{
				onChange(paymentMethod);
				setPaymentMethod?.(paymentMethod);
				setPaymentMethodHasFocus(false);
			}, [onChange, setPaymentMethod, setPaymentMethodHasFocus]);

		const handleInteraction = useCallback(
			() =>
				setHasInteractedWithPaymentMethods(true),
			[setHasInteractedWithPaymentMethods],
		);

		const handleRetry = useCallback(
			async () =>
			{
				if (enabled)
				{
					try
					{
						await refreshPaymentSession();
					}
					catch (error)
					{
						notification.notify({
							content: translate('Payment-Session-Setup-Failed'),
							variant: 'error',
						});
					}
				}
			},
			[enabled, notification, refreshPaymentSession, translate],
		);

		if (needsPayment)
		{
			if (isLoading)
			{
				return <OrderBuilderSegment>
					<PaymentMethodsLoadingCard />
				</OrderBuilderSegment>;
			}
			else if (paymentSessionHasError)
			{
				return <OrderBuilderSegment>
					<Alert
						action={
							enabled &&
							<IconButton
								onClick={handleRetry}
							>
								<RefreshIcon />
							</IconButton>
						}
						className={classes.alert}
						severity="error"
					>
						{translate('Payment-Session-Setup-Failed')}
					</Alert>
				</OrderBuilderSegment>;
			}
			else
			{
				return <OrderBuilderSegment>
					<PaymentMethodForm
						hasFocus={paymentMethodHasFocus}
						onChange={handleChange}
						onFocusChange={setPaymentMethodHasFocus}
						onHasErrorChange={setPaymentMethodHasError}
						onInteraction={handleInteraction}
					/>
				</OrderBuilderSegment>;
			}
		}
		else
		{
			return null;
		}
	};