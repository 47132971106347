import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as  React from 'react';
import { FC } from 'react';

const useStyles = makeStyles({
	logo: {
		width: '100%',
	},
});

interface PaymentSharingDialogBannerProps
{
	className?: string;
	image?: any;
}

export const PaymentSharingDialogBanner: FC<PaymentSharingDialogBannerProps> =
	({
		className,
		image,
	}) =>
	{
		const classes = useStyles();

		return <img
			alt="logo"
			className={
				clsx(
					classes.logo,
					className,
				)
			}
			src={image}
		/>;
	};
