import { toJS } from 'mobx';
import * as React from 'react';
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { MenuCard } from '../../../../../Api/Business/MenuCard';
import { constructEntityWithIdRecordReducer } from '../../../../../Util/reducer/constructEntityWithIdRecordReducer';
import { ManagerView } from '../../ManagerView';
import { getMenuCards } from './getMenuCards';

interface Context
{
	getMenuCard: (id: number) => MenuCard | undefined
	menuCards: MenuCard[] | undefined
}

const ContextRef = createContext<Context>(undefined as never);

interface ManagerMenuCardsContextProviderProps
{
	businessId: number
	view: ManagerView
}

export const ManagerMenuCardsContextProvider: FC<ManagerMenuCardsContextProviderProps> =
	(
		{
			businessId,
			children,
			view,
		},
	) =>
	{
		const [menuCardById, menuCardDispatch] = useReducer(constructEntityWithIdRecordReducer<MenuCard>(), undefined);

		const [didInitialize, setDidInitialize] = useState(false);

		const menuCards = useMemo(() => {
			if (menuCardById === undefined)
				return undefined;
			else
				return Object.values(menuCardById);
		}, [menuCardById]);

		const viewRequiresMenuCards = useMemo(() => view === 'places', [view]);

		useEffect(() => {
			return () => menuCardDispatch({
				action: 'unload',
			});
		}, [businessId]);

		useEffect(() => {
			if (!didInitialize && viewRequiresMenuCards)
			{
				getMenuCards()
					.then(naturalSortMenuCardsByName)
					.then(menuCards => {
						menuCardDispatch({
							action: 'replace',
							objects: toJS(menuCards),
						});

						setDidInitialize(true);
					});
			}
		}, [businessId, didInitialize, viewRequiresMenuCards]);

		const getMenuCard = useCallback((id: number) => menuCardById?.[id], [menuCardById]);

		const contextValue = useMemo<Context>(
			() => ({getMenuCard, menuCards}),
			[getMenuCard, menuCards],
		);

		return <ContextRef.Provider value={contextValue}>
			{children}
		</ContextRef.Provider>;
	};

export function useManagerMenuCardsContext(): Context
{
	return useContext(ContextRef);
}

function naturalSortMenuCardsByName(menuCards: MenuCard[]): MenuCard[]
{
	return menuCards
		.slice()
		.sort(
			(a, b) =>
				a.name.localeCompare(
					b.name,
					undefined,
					{
						numeric: true,
						sensitivity: 'base',
					},
				),
		);
}