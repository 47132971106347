import { Box, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { usePriceLabel } from '../../../../../../Util/Hooks/usePriceLabel';
import { useResizeObserver } from '../../../../../../Util/Hooks/useResizeObserver';
import { Image } from '../../../../../UI/Image/Image';
import { MediaButtonV2BorderRadius } from '../../../../../UI/media_button_v2/MediaButtonV2';
import { ProductInformation } from '../../ProductInformation';
import { ProductStore } from '../../ProductStore';
import { ProductDrawerPrice } from '../Price/ProductDrawerPrice';
import { normalizeProductDrawerImageHeight } from './normalizeProductDrawerImageHeight';
import { normalizeProductDrawerImageWidth } from './normalizeProductDrawerImageWidth';

const useStyles =
	makeStyles(
		() => ({
			title: {
				fontSize: '3rem',
				lineHeight: 1.1,
			},
			information: {
				columnWidth: 400,
				columnGap: 20,
			},
			centeredInformation: {
				textAlign: 'center',
			},
			actions: {
				display: 'flex',
				flexDirection: 'row',
				padding: '0 15px 10px 15px',
				alignItems: 'flex-end',
				justifyContent: 'flex-end',
			},
		}),
	);

interface ProductDrawerInformationProps
{
	productStore: ProductStore;
}

export const ProductDrawerInformation: FC<ProductDrawerInformationProps> =
	(
		{
			productStore,
		},
	) =>
	{
		const classes = useStyles();
		const product = productStore.product;
		const priceLabel = usePriceLabel(product);
		const hasImage = product.imageUrl !== undefined;
		const [ref, element] = useResizeObserver();

		const normalizedWidth = useMemo(
			() =>
				element === undefined
					? undefined
					: normalizeProductDrawerImageWidth(element.contentRect.width),
			[element],
		);

		const normalizedHeight = useMemo(
			() =>
				element === undefined
					? undefined
					: normalizeProductDrawerImageHeight(element.contentRect.height),
			[element],
		);

		return <Box
			sx={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
			paddingBottom={1}
		>
			<Box
				paddingTop={3}
				paddingBottom={9}
				paddingX={9}
				sx={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
				}}
				style={{
					gap: 62,
				}}
			>
				{
					hasImage &&
					<Box
						sx={{
							flex: 1,
						}}
					>
						<div
							ref={ref}
							style={{
								height: '100%',
							}}
						>
							<Box
								sx={{
									position: 'relative',
									width: '100%',
									borderRadius: MediaButtonV2BorderRadius,
								}}
								style={{
									aspectRatio: '4 / 3',
									height: '100%',
								}}
							>
								{
									normalizedWidth !== undefined && normalizedHeight !== undefined &&
									<Image
										compression="None"
										path={product.imageUrl}
										fit={
											product.imageDoContain
												? 'Contain'
												: 'Cover'
										}
										width={normalizedWidth}
										height={normalizedHeight}
										style={{
											borderRadius: MediaButtonV2BorderRadius,
										}}
									/>
								}
							</Box>
						</div>
					</Box>
				}
				<Box
					paddingTop={1}
					sx={{
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						alignItems:
							hasImage
								? undefined
								: 'center',
						justifyContent: 'center',
					}}
				>
					<Box>
						<Typography
							variant="h6"
							className={classes.title}
						>
							{product.name}
						</Typography>
					</Box>
					{
						priceLabel !== undefined &&
						<Box
							paddingTop={1}
						>
							<ProductDrawerPrice>
								{priceLabel}
							</ProductDrawerPrice>
						</Box>
					}
					<Box
						paddingTop={2}
						className={clsx(classes.information, !hasImage && classes.centeredInformation)}
					>
						<ProductInformation
							store={productStore}
							hideTitle
						/>
					</Box>
				</Box>
			</Box>
		</Box>;
	};
