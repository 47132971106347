import { reaction } from 'mobx';
import { useEffect } from 'react';
import { useRootContext } from '../../../../../../RootContext';
import { ClockService } from '../../../../../../Util/clock/ClockService';
import { OrderBuilderStore } from '../../OrderBuilderStore';

export function useCheckOrderWhenEffectiveScheduledTimeChanges(store: OrderBuilderStore)
{
	const {
		clockService,
	} = useRootContext(true);
	useEffect(
		() => reaction(
			() => getEffectiveScheduledTime(store, clockService),
			() => store.currentOrderService.checkOrderIfNecessary(),
		),
		[clockService, store],
	);
}

function getEffectiveScheduledTime(store: OrderBuilderStore, clockService: ClockService): string | undefined
{
	const scheduledTime = store.currentOrderService.effectiveHandoverDateSpec;
	return scheduledTime !== undefined
		?
		(
			scheduledTime === 'ASAP'
				? clockService.currentMinute.toISOString()
				: scheduledTime.toISOString()
		)
		:
		undefined;
}