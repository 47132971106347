import * as React from 'react';
import { FC, useMemo } from 'react';
import { UIColors } from '../../../../Constants/UIColors';
import { ActionButton, ActionButtonProps } from '../ActionButton';

interface PositiveActionButtonProps extends Omit<ActionButtonProps, 'color'>
{
	dark?: boolean
}

export const PositiveActionButton: FC<PositiveActionButtonProps> =
	(
		{
			dark,
			...other
		},
	) =>
	{
		const color = useMemo(() =>
		{
			if (dark)
				return {
					buttonColor: UIColors.positiveDark,
					contentColor: 'white',
				};
			else
				return {
					buttonColor: UIColors.positive,
					contentColor: UIColors.greyLight,
				};
		}, [dark]);

		return <ActionButton
			color={color}
			{...other}
		/>;
	};