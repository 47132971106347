import { useEffect } from 'react';
import { PaymentIssuer } from '../../../../../../Api/Payment/PaymentIssuer';
import { usePaymentMethodContext } from '../../../method/context/PaymentMethodContext';

export function useInvalidPaymentIssuerRemover(selectedPaymentIssuer: PaymentIssuer | undefined, selectPaymentIssuer: (paymentIssuerId?: string) => void): void
{
	const {selectedPaymentMethod} = usePaymentMethodContext();

	useEffect(() =>
	{
		if (selectedPaymentIssuer !== undefined)
		{
			if (selectedPaymentMethod?.isIssuerRequired)
			{
				const isInvalidPaymentIssuerSelected = selectedPaymentMethod.issuers?.find(peer => peer.id === selectedPaymentIssuer?.id) === undefined;

				if (isInvalidPaymentIssuerSelected)
					selectPaymentIssuer(undefined);
			}
			else
			{
				selectPaymentIssuer(undefined);
			}
		}
	}, [selectPaymentIssuer, selectedPaymentIssuer, selectedPaymentMethod?.isIssuerRequired, selectedPaymentMethod?.issuers]);
}