import * as React from 'react';
import { FC } from 'react';
import { Typography } from '@material-ui/core';
import { UIColors } from '../../../Constants/UIColors';

interface NotificationSnackbarTextProps
{
	text: string;
}

export const NotificationSnackbarText: FC<NotificationSnackbarTextProps> =
	({
		 text,
	 }) =>
	{
		return <Typography
			style={{
				color: UIColors.greyLight,
				fontWeight: 'lighter',
			}}
		>
			{text}
		</Typography>;
	};