import Decimal from 'decimal.js';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext, useMemo } from 'react';
import { BasketRequirements } from '../../../../../Api/Order/Loyalty/Como/BasketRequirements';
import { ItemsSelection } from '../../../../../Api/Order/Loyalty/Como/ItemsSelection';
import { Reward } from '../../../../../Api/Order/Loyalty/Como/Reward';
import { Product } from '../../../../../Api/Product/Product';
import { useTranslate } from '../../../../../Bridge/Localization/useTranslate';
import { isDefined } from '../../../../../Util/isDefined';
import MenuEntry from '../../../../UI/menu_entry/MenuEntry';
import { BusinessContextRef } from '../../BusinessContext';
import { ComoRewardRedeemBadge } from './ComoRewardRedeemBadge';
import { ComoRewardsStore } from './ComoRewardsStore';

interface ComoRewardTileProps
{
	store: ComoRewardsStore;
	reward: Reward;
	minHeight?: number;
	minImageHeight?: number;
	minImageWidth?: number;
}

export const ComoRewardTile: FC<ComoRewardTileProps> =
	({
		store,
		reward,
		minHeight,
		minImageHeight,
		minImageWidth,
	}) =>
	{
		const translate = useTranslate();
		const {businessStore} = useContext(BusinessContextRef);

		const productByExternalId = useObserver(() => businessStore.productByExternalId);

		const label = useMemo(() =>
		{
			if (reward.points === undefined)
				return undefined;
			else
			{
				const points = new Decimal(reward.points).mul(new Decimal(10).pow(-store.shoppingCartStore.currentOrderService.currency.decimalPlaces)).toString();

				return `${points} ${translate('Client-Loyalty-Como-Points')}`;
			}
		}, [reward.points, store.shoppingCartStore.currentOrderService.currency.decimalPlaces, translate]);

		const unconfirmedAssetKeys = useObserver(() => store.shoppingCartStore.currentOrderService.unconfirmedAssetKeys);
		const confirmedAssetKeys = useObserver(() => store.shoppingCartStore.currentOrderService.confirmedAssetKeys);

		const redeemState = useMemo(
			() =>
				confirmedAssetKeys.includes(reward.id)
					? 'Redeemed'
					: unconfirmedAssetKeys.includes(reward.id)
						? 'AwaitingItems'
						: 'Redeemable',
			[confirmedAssetKeys, reward.id, unconfirmedAssetKeys],
		);

		const isDisabled = useMemo(
			() =>
			{
				if (reward.redeemable)
				{
					return false;
				}
				else
				{
					return reward.itemsSelection === undefined
						? true
						: !areItemsSelectionsSatisfiable(reward.itemsSelection, productByExternalId);
				}
			},
			[productByExternalId, reward.itemsSelection, reward.redeemable],
		);

		return <div
			style={{
				position: 'relative',
				display: 'flex',
				flexGrow: 1,
			}}
		>
			<MenuEntry
				image={{
					imageURL: reward.imageUrl,
				}}
				title={reward.name}
				description={reward.description}
				showDescriptionOnTile
				type="TILE"
				price={label}
				primaryBadges={
					redeemState === 'Redeemable'
						? []
						: [
							<ComoRewardRedeemBadge
								state={redeemState}
							/>,
						]
				}
				minHeight={minHeight}
				minImageHeight={minImageHeight}
				minImageWidth={minImageWidth}
				disabled={isDisabled}
				onClick={() =>
				{
					store.openReward(reward);
				}}
			/>
			{
				isDisabled &&
				<div
					style={{
						position: 'absolute',
						top: 0,
						bottom: 0,
						right: 0,
						left: 0,
						background: 'rgba(0, 0, 0, 0.26)',
					}}
				/>
			}
		</div>;
	};

function areBasketRequirementsSatisfiable(
	basketRequirements: BasketRequirements,
	productByExternalId: Map<string, Product>,
): boolean
{
	const externalIds = basketRequirements.ids ?? [];

	return externalIds.some(
		externalId =>
			productByExternalId.has(externalId),
	);
}

function areItemsSelectionsSatisfiable(
	itemsSelections: ItemsSelection,
	productByExternalId: Map<string, Product>,
): boolean
{
	const basketRequirements = itemsSelections
			.discounts
			?.filter(isDefined)
			?.flatMap(
				discount =>
					discount.basketRequirements ?? [],
			)
		?? [];

	return basketRequirements.every(
		basketRequirements =>
			areBasketRequirementsSatisfiable(basketRequirements, productByExternalId),
	);
}
