import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext } from 'react';
import { formatValidationMessagesToText } from '../../../../../Util/Validation';
import { PhoneNumberInput } from '../../../../UI/Form/PhoneNumberInput/PhoneNumberInput';
import { BusinessContextRef } from '../../BusinessContext';
import { SchedulerStore } from '../SchedulerStore';

export interface SchedulerPhoneNumberInputProps
{
	store: SchedulerStore;
	showValidation: boolean;
}

export const SchedulerPhoneNumberInput: FC<SchedulerPhoneNumberInputProps> =
	({
		showValidation,
		store,
	}) =>
	{
		const {businessStore} = useContext(BusinessContextRef);

		const businessCountry = useObserver(() => businessStore.business.address.country);
		const clientPhoneNumber = useObserver(() => store.clientPhoneNumber);
		const phoneValidationErrors = useObserver(() => store.phoneValidationErrors);
		const setClientPhoneNumber = useObserver(() => store.setClientPhoneNumber);

		return <PhoneNumberInput
			defaultCountry={businessCountry}
			error={showValidation && phoneValidationErrors.length > 0}
			helperText={
				showValidation && phoneValidationErrors.length > 0
					? formatValidationMessagesToText(phoneValidationErrors)
					: undefined
			}
			onChange={setClientPhoneNumber}
			style={{
				marginBottom: 8,
			}}
			value={clientPhoneNumber}
		/>;
	};
