import { reaction, runInAction } from 'mobx';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { KioskDrawer } from '../../../../UI/kiosk_drawer/KioskDrawer';
import { useDoesProductHaveConfigurationScreenBeforeAddingItToCart } from '../Dialog/useDoesProductHaveConfigurationScreenBeforeAddingItToCart';
import { ProductStore } from '../ProductStore';
import { ProductDrawerConfiguration } from './Configuration/ProductDrawerConfiguration';
import { ProductDrawerInformation } from './Information/ProductDrawerInformation';

interface ProductDrawerProps
{
	productStore: ProductStore;
	onClose: () => void;
}

export const ProductDrawer: FC<ProductDrawerProps> =
	(
		{
			productStore,
			onClose,
		},
	) =>
	{
		const isConfigurable = useDoesProductHaveConfigurationScreenBeforeAddingItToCart(productStore.product);
		const configurationStore =
			useObserver(
				() =>
					productStore.configurationStore,
			);

		useEffect(
			() =>
			{
				runInAction(
					() =>
						productStore.isInConfigurationMode = isConfigurable,
				);

				return reaction(
					() => productStore.isInConfigurationMode,
					newValue =>
					{
						if (!newValue)
						{
							productStore.close();
						}
					},
				);
			},
			[isConfigurable, productStore],
		);

		return <KioskDrawer
			topContent={
				() =>
					<ProductDrawerInformation
						productStore={productStore}
					/>
			}
			bottomContent={
				productStore.showOrderButton
					? close =>
						<ProductDrawerConfiguration
							productStore={productStore}
							onClose={close}
							configurationStore={configurationStore}
						/>
					: undefined
			}
			onClose={onClose}
		/>;
	};
