import { action, makeObservable, observable } from 'mobx';
import { fetch } from '../../Util/Api';
import { SerializationProfile } from '../../Util/Serialization/Serialization';

export class UserService<T>
{
    user?: T;

    constructor()
    {
        makeObservable(
            this,
            {
                user: observable,
                setUser: action.bound,
            },
        );
    }

    setUser(user?: T): void
    {
        this.user = user;
    }

    protected getUser<T>(userProfile: SerializationProfile<T>): Promise<T>
    {
        return fetch(
            '/user/me',
            {},
            userProfile
        );
    }
}
