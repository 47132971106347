import { Card, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';
import { AdyenCheckoutPaymentSettings } from '../../../adyen/checkout/settings/AdyenCheckoutPaymentSettings';
import { PaymentMethodSummary } from '../summary/PaymentMethodSummary';

const useStyles = makeStyles((theme: Theme) => ({
	card: {
		backgroundColor: '#fff8',
		padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px ${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
	},
	paymentSettings: {
		marginTop: theme.spacing(2),
	},
}));

export interface PaymentMethodCardProps
{
	className?: string;
}

export const PaymentMethodCard: FC<PaymentMethodCardProps> =
	({
		className,
	}) =>
	{
		const classes = useStyles();

		const cardClassName = clsx(classes.card, className);

		return <Card
			className={cardClassName}
		>
			<PaymentMethodSummary />
			<AdyenCheckoutPaymentSettings
				className={classes.paymentSettings}
			/>
		</Card>;
	};