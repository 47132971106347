import { OrderState } from '../../../../Api/Order/Order';
import { postJsonAny } from '../../../../Util/Api';

export async function updateOrderState(UpdateOrderStateRequest: UpdateOrderStateRequest): Promise<void>
{
	await postJsonAny('/waiter/business/order/state/update', {}, UpdateOrderStateRequest);
}

export interface UpdateOrderStateRequest
{
	uuid: string;
	state: OrderState;
}