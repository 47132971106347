import { BaseStore } from '@intentic/ts-foundation';
import { computed, makeObservable } from 'mobx';
import { Coupon } from '../../../../../Api/Order/Coupon/Coupon';
import { CouponType } from '../../../../../Api/Order/Coupon/CouponDescriptor';
import { Localizer } from '../../../../../Bridge/Localization/Localizer';

export class CouponBadgeStore extends BaseStore
{
    /*---------------------------------------------------------------*
     *                          Properties                           *
     *---------------------------------------------------------------*/

    private readonly localizer: Localizer;
    private readonly coupon: Coupon;
    private readonly discount: number;

    /*---------------------------------------------------------------*
     *                          Constructor                          *
     *---------------------------------------------------------------*/

    constructor(
        localizer: Localizer,
        coupon: Coupon,
        discount: number
    )
    {
        super();
        makeObservable(
            this,
            {
                couponType: computed,
                badgeLabel: computed,
            },
        );
        this.localizer = localizer;
        this.coupon = coupon;
        this.discount = discount;
    }

    /*---------------------------------------------------------------*
     *                        Initialization                         *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                           Computed                            *
     *---------------------------------------------------------------*/

    get couponType(): CouponType
    {
        return this.coupon.type;
    }

    get badgeLabel(): string
    {
        return this.coupon.effectDescriptionShort(
            this.localizer,
            this.discount
        );
    }
}
