import { HttpRequestException } from './HttpRequestException';
import { AxiosError } from 'axios';

export class ConflictHttpRequestException extends HttpRequestException
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	readonly name = 'ConflictHttpRequestException';

	/*---------------------------------------------------------------*
	 *                          Constructors                         *
	 *---------------------------------------------------------------*/

	constructor(cause: AxiosError)
	{
		super(
			cause,
			`HTTP request returned 409 Conflict`,
		);
	}

	/*---------------------------------------------------------------*
	 *                         Business Logic                        *
	 *---------------------------------------------------------------*/

	/*---------------------------------------------------------------*
	 *                      Getters and setters                      *
	 *---------------------------------------------------------------*/

	/*---------------------------------------------------------------*
	 *                        Nested classes                         *
	 *---------------------------------------------------------------*/
}