import { Divider, isWidthUp, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FC, ReactNode, useMemo } from 'react';
import { useScreenWidth } from '../../../../../../../Util/Hooks/useScreenWidth';

const formatXml = require('xml-formatter');

const useStyle = makeStyles((theme: Theme) => ({
	detailLine: {
	},
	detailLineLabel: {
		fontWeight: 'bold',
	},
	detailLineValue: {
		fontStyle: 'italic',
		overflowWrap: 'break-word',
		userSelect: 'auto',
		whiteSpace: 'pre-wrap',
		wordBreak: 'break-all',
	},
	detailLineValueSmall: {
		fontSize: '0.75rem',
		userSelect: 'auto',
	},
	dividerBottom: {
		marginTop: theme.spacing(),
	},
	dividerTop: {
		marginBottom: theme.spacing(),
	},
	inline: {
		alignItems: 'center',
		display: 'flex',
	},
}));

interface OrderEventDetailsLineProps
{
	inline: boolean;
	label: string;
	value?: ReactNode | number | string;
}

export const OrderEventDetailsLine: FC<OrderEventDetailsLineProps> =
	(
		{
			inline,
			label,
			value,
		},
	) =>
	{
		const classes = useStyle();

		const screenWidth = useScreenWidth();

		const isMediumScreen = useMemo(() => isWidthUp('md', screenWidth), [screenWidth]);

		const valueClassName = useMemo(
			() =>
				!isMediumScreen && !inline
					? `${classes.detailLineValue} ${classes.detailLineValueSmall}`
					: classes.detailLineValue,
			[classes.detailLineValue, classes.detailLineValueSmall, inline, isMediumScreen],
		);

		const Component = useMemo(() => {
			if (value === undefined)
				return undefined;

			const component = typeof value === 'number' || typeof value === 'string'
				? <Typography className={valueClassName}>{parseDataValue(value)}</Typography>
				: value;

			if (inline)
				return <div className={`${classes.detailLine} ${classes.inline}`}>
					<Typography className={classes.detailLineLabel}>{label}:&nbsp;</Typography>
					{component}
				</div>;
			else
				return <div className={classes.detailLine}>
					<Typography className={classes.detailLineLabel}>{label}</Typography>
					<Divider className={classes.dividerTop} />
					{component}
					<Divider className={classes.dividerBottom} />
				</div>;
		}, [classes.detailLine, classes.detailLineLabel, classes.dividerBottom, classes.dividerTop, classes.inline, inline, label, value, valueClassName]);

		return Component ?? null;
	};

function parseDataValue(information: number | string): number | string
{
	if (typeof information === 'number')
	{
		return information;
	}
	if (information.startsWith('{'))
	{
		try
		{
			return JSON.stringify(JSON.parse(information), null, 4);
		}
		catch
		{
			return information;
		}
	}
	else if (information.startsWith('<'))
	{
		try
		{
			return formatXml(
				information,
				{
					collapseContent: true,
					indentation: '    ',
					lineSeparator: '\n',
				},
			);
		}
		catch
		{
			return information;
		}
	}
	else
	{
		return information;
	}
}