import { useObserver } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useWaiter } from '../../../../Service/UserService/useWaiter';
import { ManagerView } from '../ManagerView';

export function useWhetherPageIsAvailableWhenVisitingOtherBusinessWithBusinessSelector(): (page: ManagerView) => boolean
{
	const waiter = useWaiter(false);

	const isRoot = useObserver(() => waiter?.isRoot ?? false);
	
	return useCallback(
		(pageName: ManagerView) => {
			switch (pageName)
			{
				case 'order-handler':
				case 'device-settings':
				case 'reports':
				case 'places':
				case 'routings':
				case 'menu-editor':
				case 'settings':
					return true;
				case 'card-payment-actions':
				case 'code-info':
				case 'payment-recipients':
					return isRoot;
				default:
					return false;
			}
		},
		[isRoot]
	);
}