import { makeStyles } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext, useEffect, useMemo } from 'react';
import { BrowserRootLayoutPolyfillContextRef } from '../BrowserRootLayoutPolyfiller/BrowserRootLayoutPolyfillContextProvider';
import { ScreenLayer } from '../screen-container/ScreenContainer';

const useStyles = makeStyles(() => ({
	scrollRetainerOuter: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1 0 auto',
		position: 'relative',
	},
	scrollRetainerInner: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1 0 auto',
	},
	preScrollMinPageHeightSpacer: {
		position: 'absolute',
		width: 0,
		pointerEvents: 'none',
		opacity: 0,
	},
}));

interface ScrollRetainerProps
{
	layer: ScreenLayer
	containsDialog: boolean
}

export const ScrollRetainer: FC<ScrollRetainerProps> =
	(
		{
			children,
			layer,
			containsDialog
		},
	) =>
	{
		const classes = useStyles();
		const {
			scrollTo
		} = useContext(BrowserRootLayoutPolyfillContextRef);
		const screenInstantiationScrolltop = useObserver(() => layer.screenInstantiation.scrollTop);
		const marginTopCss = useMemo(
			() => layer.type !== 'dialog' && screenInstantiationScrolltop !== undefined
				&& containsDialog
				?
				`${-screenInstantiationScrolltop}px`
				:
				undefined,
			[containsDialog, screenInstantiationScrolltop, layer.type]
		);
		useEffect(
			() => {
				if (layer.type !== 'dialog' && screenInstantiationScrolltop !== undefined && !containsDialog)
				{
					scrollTo(screenInstantiationScrolltop);
					setTimeout(() => scrollTo(screenInstantiationScrolltop), 0);
				}
			},
			[containsDialog, screenInstantiationScrolltop, layer.type, scrollTo]
		);
		return <div
			className={classes.scrollRetainerOuter}
			style={{
				overflow: layer.type !== 'dialog' && containsDialog
					?
					'hidden'
					:
					undefined,
			}}
		>
			<div
				className={classes.scrollRetainerInner}
				style={{
					marginTop: marginTopCss,
				}}
			>
				{children}
			</div>
		</div>;
	};