import * as React from 'react';
import { FC } from 'react';
import { OrderBuilderStore } from '../../OrderBuilderStore';
import Grid from '@material-ui/core/Grid/Grid';
import { ShopperTrackerConsentForm } from './ShopperTrackerConsentForm';
import { useObserver } from 'mobx-react-lite';

interface TrackerConsentOrderBuilderSegmentProps
{
	store: OrderBuilderStore
}

export const TrackerConsentOrderBuilderSegment: FC<TrackerConsentOrderBuilderSegmentProps> =
	(
		{
			store,
		},
	) =>
	{
		return useObserver(() => {
			if (!store.currentOrderService.showOrderTrackerConsentForm)
				return null;
			return <Grid
				item
				xs={12}
				style={{
					flex: '0 0 auto',
				}}
			>
				<ShopperTrackerConsentForm
					showValidation={store.showValidation}
					legalConsent={store.currentOrderService.explicitConsentForOrderTrackerUserInput === true}
					setLegalConsent={store.currentOrderService.setExplicitConsentForOrderTracker}
					beneficiaryName={store.business.orderTrackerBeneficiary ?? store.business.name}
					required={store.currentOrderService.requireOrderTrackerConsent}
					labelTemplate={store.currentPlaceService.place.explicitConsentOrderTrackerStatement}
				/>
			</Grid>;
		});
	};
