export function findQueryParameter(name: string): string | undefined
{
	let result: string | undefined;
	let tmp: string[] = [];

	window.location.search
		.substr(1)
		.split('&')
		.forEach(
			item =>
			{
				tmp = item.split('=');
				if (tmp[0] === name)
				{
					result = decodeURIComponent(tmp[1]);
				}
			});

	return result;
}