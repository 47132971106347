import { toJS } from 'mobx';
import * as React from 'react';
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { Place } from '../../../../../Api/Business/Place';
import { constructEntityWithIdRecordReducer } from '../../../../../Util/reducer/constructEntityWithIdRecordReducer';
import { ManagerView } from '../../ManagerView';
import { getPlaces } from './getPlaces';
import { IllegalStateException } from '../../../../../Util/Exception/IllegalStateException';

interface Context
{
	deletePlace: (id: number) => void
	getPlaceById: (id: number) => Place | undefined
	places: Place[] | undefined
	updatePlace: (place: Place) => void
}

const ContextRef = createContext<Context | undefined>(undefined);

interface ManagerPlacesContextProviderProps
{
	businessId: number
	view: ManagerView
}

export const ManagerPlacesContextProvider: FC<ManagerPlacesContextProviderProps> =
	(
		{
			businessId,
			children,
			view,
		},
	) =>
	{
		const [placeById, placeDispatch] = useReducer(constructEntityWithIdRecordReducer<Place>(), undefined);

		const [didInitialize, setDidInitialize] = useState<number>();

		const places = useMemo(() => {
			if (placeById === undefined)
				return undefined;
			else
				return Object.values(placeById);
		}, [placeById]);

		const viewRequiresPlaces = useMemo(
			() => view === 'code-info' || view === 'order-handler' || view === 'places',
			[view],
		);

		useEffect(() => {
			return () => placeDispatch({
				action: 'unload',
			});
		}, [businessId]);

		useEffect(() => {
			if (didInitialize !== businessId && viewRequiresPlaces)
			{
				getPlaces({businessId})
					.then(naturalSortPlacesByName)
					.then(places => {
						placeDispatch({
							action: 'replace',
							objects: toJS(places),
						});

						setDidInitialize(businessId);
					});
			}
		}, [businessId, didInitialize, viewRequiresPlaces]);

		const deletePlace = useCallback((id: number) => {
			placeDispatch({
				action: 'delete',
				object: {id} as Place,
			});
		}, []);

		const updatePlace = useCallback((place: Place) => {
			placeDispatch({
				action: 'update',
				object: place,
			});
		}, []);

		const getPlaceById = useCallback((id: number) => placeById?.[id], [placeById]);

		return <ContextRef.Provider value={useMemo<Context>(() => ({
				deletePlace,
				getPlaceById,
				places,
				updatePlace
			}), [deletePlace, getPlaceById, places, updatePlace],
		)}>
			{children}
		</ContextRef.Provider>;
	};

function useThisContext()
{
	const context = useContext(ContextRef);
	return useMemo(
		() => {
			if (context === undefined)
				throw new IllegalStateException(
					'Attempting to use ManagerPlacesContext outside of ManagerPlacesContextProvider'
				);
			return context;
		},
		[context]
	);
}

export function useManagerPlacesContext(): Context
{
	return useContext(ContextRef);
}

export function useSortedManagerPlaces(): Place[]
{
	const {places} = useThisContext();
	return useMemo(
		() => places !== undefined
			? naturalSortPlacesByName(places)
			: undefined,
		[places],
	);
}

const NaturalSortRegex = /\d+|\D+/g;

function NaturalSortComparator(lhs: string[], rhs: string[]): number
{
	const maxIndex = Math.min(lhs.length, rhs.length);

	for(let i = 0; i < maxIndex; i++)
	{
		const from = lhs[i];
		const to = rhs[i];

		if (from !== to)
		{
			const fromNumber = parseInt(from);
			const toNumber = parseInt(to);

			if (isNaN(fromNumber) || isNaN(toNumber))
				return from.localeCompare(to);
			else
				return fromNumber - toNumber;
		}
	}

	return lhs.length - rhs.length;
}


function naturalSortPlacesByName(places: Place[]): Place[]
{
	return places
		.map(place => ({
			place,
			sortKey: place.name.match(NaturalSortRegex) ?? [''],
		}))
		.sort(
			(a, b) =>
				NaturalSortComparator(a.sortKey, b.sortKey),
		)
		.map(r => r.place);
}
