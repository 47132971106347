import { Waiter, WaiterProfile } from '../../../../Api/User/Waiter';
import { postJson } from '../../../../Util/Api';

export function saveWaiterSettings(saveWaiterSettingsRequest: SaveWaiterSettingsRequest): Promise<Waiter>
{
	return postJson('/business/waiter/settings/save', {}, WaiterProfile, saveWaiterSettingsRequest);
}

export interface SaveWaiterSettingsRequest
{
	uuid: string;
	orderHandlerSupportsOrderStateScheduled: boolean;
	orderHandlerSupportsOrderStateDelivered: boolean;
	orderHandlerSupportsOrderStatePickedUp: boolean;
	orderHandlerSupportsOrderStatePrepared: boolean;
}