export function convertToISO8601Duration(seconds: number, minutes: number, hours: number): string | undefined
{
	if (hours === 0 && minutes === 0 && seconds === 0)
	{
		return undefined;
	}
	else
	{
		return `PT${hours > 0 ? `${hours}H` : ''}${minutes > 0 ? `${minutes}M` : ''}${seconds > 0 ? `${seconds}S` : ''}`;
	}
}