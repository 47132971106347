import { Menu } from '@material-ui/icons';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { FC, useContext } from 'react';
import { WebClient } from '../../../Bridge/Client/WebClient';
import { PageTopBar } from '../../UI/PageTopBar';
import { EntranceContextRef } from './EntranceContext';

interface EntranceTopBarProps
{
	onIconClick: () => void;
}

export const EntranceTopBar: FC<EntranceTopBarProps> =
	(
		{
			onIconClick,
		},
	) =>
	{
		const {
			client,
			brandingService,
		} = useContext(EntranceContextRef);

		const topBarColor = useObserver(() => brandingService.topBarColor);
		const tintContrastTextColor = useObserver(() => brandingService.tintContrastTextColor);
		const backgroundContrastTextColor = useObserver(() => brandingService.backgroundContrastTextColor);
		const logoInlineImageUrl = useObserver(() => brandingService.logoInlineImageUrl);
		const scrollYOffset = useObserver(() => (client as WebClient).scrollYOffset ?? 0);

		return <PageTopBar
			topBarColor={topBarColor}
			topBarContrastTextColor={tintContrastTextColor}
			backgroundContrastTextColor={backgroundContrastTextColor}
			icon={Menu}
			imageUrl={logoInlineImageUrl}
			onIconClick={onIconClick}
			scrollYOffset={scrollYOffset}
			title={undefined}
			sidebarEnabled={false}
		/>;
	};
