import * as React from 'react';
import { CSSProperties, FC, useMemo } from 'react';
import { OrderDescriptor } from '../../../../../../../Api/Order/OrderDescriptor';
import { getDateCreated } from '../../../../../../../Util/Orders/getDateCreated';
import { getTotalPriceOfOrder } from '../../../../../../../Util/Orders/getTotalPriceOfOrder';
import { hasAvatars } from '../../../../../../../Util/Orders/hasAvatars';
import { BillOrderItemContent } from './BillOrderItemContent';
import { BillOrderItemHeader } from './BillOrderItemHeader';

interface BillOrderItemProps
{
	className?: string;
	onClick?: (order: OrderDescriptor, select: boolean) => void;
	order: OrderDescriptor;
	selected: boolean;
	style?: CSSProperties;
}

export const BillOrderItem: FC<BillOrderItemProps> =
	({
		 className,
		 onClick,
		 order,
		 selected,
		 style,
	 }) =>
	{
		const dateCreated = useMemo(
			() =>
			{
				const date = getDateCreated(order);

				return date === undefined
					? undefined
					: new Date(date);
			},
			[order],
		);
		
		return <div
			className={className}
			onClick={() => onClick && onClick(order, !selected)}
			style={style}
		>
			<BillOrderItemHeader
				cost={getTotalPriceOfOrder(order)}
				date={dateCreated}
				selected={selected}
			/>
			<BillOrderItemContent
				alignWithAvatars={hasAvatars(order)}
				order={order}
				selected={selected}
				style={{
					padding: 8,
				}}
			/>
		</div>;
	};