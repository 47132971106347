import { ISO8601ToDateTransformer, SerializationProfile } from '../../Util/Serialization/Serialization';
import { OrderProcessingState, OrderState, PaymentState } from './Order';

export type OrderEventType = 'OrderState' | 'Payment' | 'Process' | 'RefundRequest';

export class OrderEvent
{
	/*---------------------------------------------------------------*
	 *                          Properties                           *
	 *---------------------------------------------------------------*/

	// OrderEvent
	readonly type: OrderEventType;
	readonly date: Date;
	readonly orderId: number;

	// OrderPaymentEvent
	readonly information: string;

	readonly data?: Object

	// OrderProcessingEvent
	readonly code: string;

	// OrderRefundRequestEvent
	readonly refundRequestId: number;

	// State
	readonly fromState: OrderProcessingState | OrderState | PaymentState;
	readonly toState: OrderProcessingState | OrderState | PaymentState;
}

export const OrderEventProfile = SerializationProfile
	.create(OrderEvent)
	.transform('date', ISO8601ToDateTransformer);