import { makeAutoObservable } from 'mobx';
import { SerializationProfile } from '../../Util/Serialization/Serialization';
import { OrderRestrictionConfiguration, OrderRestrictionConfigurationProfile } from './OrderRestrictionConfiguration';

export class OrderRestriction
{
	id: number;
	type: 'Throughput' | 'ScheduledOrderLimit';
	configuration: OrderRestrictionConfiguration;

	constructor()
	{
		makeAutoObservable(this, undefined, {
			autoBind: true,
			deep: false,
		});
	}
}

export const OrderRestrictionProfile = SerializationProfile.create(OrderRestriction)
	.profile('configuration', OrderRestrictionConfigurationProfile);