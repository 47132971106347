import { makeStyles } from '@material-ui/core';
import Decimal from 'decimal.js';
import * as  React from 'react';
import { FC } from 'react';
import { OrderDescriptor } from '../../../../../../Api/Order/OrderDescriptor';

// @ts-ignore
import TikkieBanner from '../../../../../../Resources/payment_sharing_provider/tikkie/banner.png';
import { PaymentSharingDialogBanner } from '../banner/PaymentSharingDialogBanner';
import { PaymentSharingDialogDownloadSection } from '../download/PaymentSharingDialogDownloadSection';
import { SharePaymentWithOthersLabel } from '../label/SharePaymentWithOthersLabel';
import { PaymentSharingDialogPersonInput } from '../person_input/PaymentSharingDialogPersonInput';

const useStyles = makeStyles(theme => ({
	banner: {
		marginBottom: theme.spacing(2),
	},
	content: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		height: '100%',
	},
	lowerContent: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		padding: theme.spacing(2),
	},
}));

interface TikkiePaymentSharingContentProps
{
	numberOfPersons: number;
	onNumberOfPersonsChange: (value: (((prevState: number) => number) | number)) => void;
	order?: OrderDescriptor;
}

export const TikkiePaymentSharingContent: FC<TikkiePaymentSharingContentProps> =
	({
		numberOfPersons,
		onNumberOfPersonsChange,
		order,
	}) =>
	{
		const classes = useStyles();

		return <>
			<PaymentSharingDialogBanner
				className={classes.banner}
				image={TikkieBanner}
			/>
			<div
				className={classes.lowerContent}
			>
				{
					order?.paymentCurrency &&
					order?.paymentPrice !== undefined &&
					<SharePaymentWithOthersLabel
						amount={new Decimal(order.paymentPrice)}
						currencyCode={order.paymentCurrency}
					/>
				}
				<PaymentSharingDialogPersonInput
					maximum={30}
					onChange={onNumberOfPersonsChange}
					value={numberOfPersons}
				/>
			</div>
			<PaymentSharingDialogDownloadSection
				appName="Tikkie"
				appleAppStoreUrl="https://apps.apple.com/app/tikkie/id1112935685"
				googlePlayStoreUrl="https://play.google.com/store/apps/details?id=com.abnamro.nl.tikkie"
			/>
		</>;
	};
