import { postJsonAny } from '../../Util/Api';

export async function prepareOrderLines(
	prepareOrderLinesRequest: PrepareOrderLinesRequest
): Promise<void>
{
	await postJsonAny(
		'/waiter/business/product-order-line/prepare',
		{},
		prepareOrderLinesRequest
	);
}

export interface PrepareOrderLinesRequest
{
	orderLineUuids: string[]
}