import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import * as  React from 'react';
import { FC, useCallback } from 'react';
import { OrderDescriptor } from '../../../../../../Api/Order/OrderDescriptor';
import { useTranslate } from '../../../../../../Bridge/Localization/useTranslate';
import { useRootContext } from '../../../../../../RootContext';
import { ActionButton } from '../../../../../UI/action-button/ActionButton';
import { PaymentSharingProvider } from '../PaymentSharingProviders';
import { getShareOrderPaymentUrl } from './getShareOrderPaymentUrl';
import { postOrderPaymentSharingRequest } from './postOrderPaymentSharingRequest';

interface PaymentSharingDialogActionsProps
{
	numberOfPersons: number;
	order: OrderDescriptor;
	provider?: PaymentSharingProvider;
}

export const PaymentSharingDialogActions: FC<PaymentSharingDialogActionsProps> =
	({
		numberOfPersons,
		order,
		provider,
	}) =>
	{
		const {notification} = useRootContext(true);
		const translate = useTranslate();

		const handleError = useCallback(
			() =>
				notification.notify({
					content: translate('Generic-Error-Occurred'),
					variant: 'error',
				}),
			[notification, translate],
		);

		const handleClick = useCallback(
			async () =>
			{
				try
				{
					const url = await getShareOrderPaymentUrl(
						order,
						provider,
						numberOfPersons,
					);

					if (url === undefined)
					{
						console.error(`Failed to created order payment sharing request for order ${order.uuid} with provider "${provider ?? '<none>'}"`);

						handleError();
					}
					else
					{
						await postOrderPaymentSharingRequest({
							orderId: order.uuid,
							provider,
							// Include self
							totalNumberOfPersons: numberOfPersons + 1,
							url,
						});

						const popup = window.open(url, '_self');

						// Invalid URL handling
						popup.onerror = handleError;
					}
				}
				catch (error)
				{
					handleError();

					throw error;
				}
			},
			[handleError, numberOfPersons, order, provider],
		);

		return <ActionButton
			disabled={provider === undefined || numberOfPersons === 0}
			icon={MobileFriendlyIcon}
			onClick={handleClick}
			text={translate('Client-OrderHistory-Share-Order-Payment')}
		/>;
	};
