import { FeeType } from '../../Api/Order/FeeType';
import { OrderLineDescriptor } from '../../Api/Order/OrderLineDescriptor';
import { OrderLineType } from '../../Api/Order/OrderLineType';

const OrderLineTypePriority: Record<OrderLineType, number> = {
	product: 0,
	external_bill: 1,
	product_fee: 2,
	request_bill: 3,
	request_waiter: 4,
	tip: 5,
	fee: 6,
	tax: 7,
};

const FeeOrderLineTypePriority: Record<FeeType, number> = {
	DELIVERY: 0,
	SERVICE_FEE: 1,
};

export function sortOrderLines(value: OrderLineDescriptor[]): OrderLineDescriptor[]
{
	return value.sort(
		(first, second) =>
		{
			const priorityDifference = OrderLineTypePriority[first.type] - OrderLineTypePriority[second.type];

			if (priorityDifference === 0)
			{
				if (first.type === 'fee' && second.type === 'fee')
				{
					const feePriorityDifference = FeeOrderLineTypePriority[first.feeType] - FeeOrderLineTypePriority[second.feeType];

					if (feePriorityDifference === 0)
						return first.id - second.id;
					else
						return feePriorityDifference;
				}
				else
				{
					return first.id - second.id;
				}
			}
			else
			{
				return priorityDifference;
			}
		},
	);
}
