import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

interface ButtonOnClickResponse
{
	onClickSynchronous: (event: MouseEvent<HTMLElement>) => void
	moreThanZeroFailedAttempts: boolean
	onClickIsResolving: boolean
}

export function useButtonOnClick(
	onClick: (event: MouseEvent<HTMLElement>) => void | Promise<void>,
	onCancel?: (event: MouseEvent<HTMLElement>) => void
): ButtonOnClickResponse
{
	const [failedAttempts, setFailedAttempts] = useState(0);
	const isUnmounted = useRef(false);
	const [onClickIsResolving, setOnCLickIsResolving] = useState(false);
	const onClickSynchronous = useCallback(
		async (event: MouseEvent<HTMLElement>) =>
		{
			event.stopPropagation();
			try
			{
				setOnCLickIsResolving(true);
				await onClick(event);
				if (!isUnmounted.current)
				{
					setOnCLickIsResolving(false);
					setFailedAttempts(0);
				}
			}
			catch (error)
			{
				if (!isUnmounted.current)
				{
					setOnCLickIsResolving(false);
					setFailedAttempts(failedAttempts + 1);
					throw error;
				}
			}
		},
		[failedAttempts, onClick],
	);

	useEffect(
		() =>
			() =>
			{
				isUnmounted.current = true;
			},
		[],
	);

	const moreThanZeroFailedAttempts = useMemo(() => failedAttempts > 0, [failedAttempts]);

	return {
		onClickSynchronous,
		moreThanZeroFailedAttempts,
		onClickIsResolving
	};
}