import { useResizeObserver } from './useResizeObserver';
import { useEffect, useRef, useState } from 'react';

export const useElementCanScroll = (): [(elementRef: HTMLElement) => void, (elementContentRef: HTMLElement) => void, number, number] => {
	const [elementRef, element] = useResizeObserver();
	const [contentRef] = useResizeObserver(); // only there to trigger re-render when content resizes
	const [canScrollUp, setCanScrollUp] = useState<number>(undefined);
	const [canScrollDown, setCanScrollDown] = useState<number>(undefined);
	const originalScrollListener = useRef(null);
	useEffect(
		() => {
			const scrollListener = () => {
				if (element !== undefined)
				{
					const top = element.target.scrollTop;
					const total = element.target.scrollHeight;
					const view = element.contentRect.height;
					setCanScrollDown(total - view - top);
					setCanScrollUp(top);
				}
			};
			scrollListener();
			if (element !== undefined)
			{
				originalScrollListener.current = (element.target as HTMLElement).onscroll;
				(element.target as HTMLElement).onscroll = () => {
					scrollListener();
					if (originalScrollListener.current !== null)
					{
						originalScrollListener.current();
					}
				};
			}
			return () => {
				if (element !== undefined)
				{
					(element.target as HTMLElement).onscroll = originalScrollListener.current;
				}
			}
		},
		[element]
	)
	return [
		elementRef,
		contentRef,
		canScrollDown,
		canScrollUp,
	];
}