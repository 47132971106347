import { useObserver } from 'mobx-react-lite';
import { useContext } from 'react';
import { Place } from '../../Api/Business/Place';
import { IllegalStateException } from '../../Util/Exception/IllegalStateException';
import { BusinessContextRef } from '../../Component/Page/Business/BusinessContext';

export function useAndRequirePlace(): Place
{
	const {currentPlaceService} = useContext(BusinessContextRef);
	return useObserver(
		() => {
			const place = currentPlaceService.place;
			if (place === undefined)
				throw new IllegalStateException(
					'Where useRequiredPlace hook is used, Place must be set on CurrentPlaceService'
				);
			return place;
		}
	)
}
