import * as React from 'react';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { SessionEditor } from '../SessionEditor';
import { PageDialog } from '../../../../../UI/PageDialog';
import { EntranceContextRef } from '../../../../Entrance/EntranceContext';
import { SessionEditorDialogActions } from './SessionEditorDialogActions';
import { PlaceSession } from '../../../../../../Api/Business/PlaceSession';
import { OpenMenuDialog } from '../../OpenMenu/Dialog/OpenMenuDialog';
import { observer } from 'mobx-react-lite';

interface SessionEditorDialogProps
{
	onClose: () => void;
	onSavePlaceSession: (placeSession: PlaceSession, endSession: boolean) => Promise<boolean>;
	placeSession?: PlaceSession;
}

export const SessionEditorDialog: FC<SessionEditorDialogProps> =
	observer(
		({
			 onClose,
			 onSavePlaceSession,
			 placeSession,
		 }) =>
		{
			const {localizer, notification} = useContext(EntranceContextRef);

			const [open, setOpen] = useState(placeSession !== undefined);
			const [openMenuSession, setOpenMenuSession] = useState();

			useEffect(() => setOpen(placeSession !== undefined), [placeSession]);

			const close = useCallback(() => setOpen(false), []);
			const onOpenMenu = useCallback(() => setOpenMenuSession(placeSession), [placeSession]);
			const onOpenMenuDialogClose = useCallback(() => setOpenMenuSession(undefined), []);

			const savePlaceSession = useCallback(async (placeSession: PlaceSession, endSession: boolean) => {
				try
				{
					await onSavePlaceSession(placeSession, endSession);
					close();
				}
				catch
				{
					notification.notify({
						content: 'Place session update failed',
						disableVibration: true,
					});
				}
			}, [close, notification, onSavePlaceSession]);

			return <>
				<OpenMenuDialog
					onClose={onOpenMenuDialogClose}
					openPlaceSession={placeSession => savePlaceSession(placeSession, false)}
					placeSession={openMenuSession}
				/>
				<PageDialog
					dialogActions={
						placeSession &&
						<SessionEditorDialogActions
							onOpenMenu={onOpenMenu}
							onSavePlaceSession={endSession => savePlaceSession(placeSession, endSession)}
							placeSession={placeSession}
						/>
					}
					fullWidth
					onClose={close}
					onExited={onClose}
					maxWidth="sm"
					open={open}
					title={localizer.translate(placeSession && placeSession.id ? 'Place-Session-Edit' : 'Place-Session-New')}
				>
					{
						placeSession &&
						<SessionEditor
							onSavePlaceSession={savePlaceSession}
							placeSession={placeSession}
						/>
					}
				</PageDialog>
			</>;
		});
